import { useEffect } from "react";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getListExpedition,
  getListPartner,
  getListPaymentMethod,
  getListProduct,
  getProvince,
} from "../../../../global/global.reducer";
import NonPelangganForm from "../components/nonPelangganForm";
import SalesOrderPelangganForm from "../components/pelangganForm";
import SalesOrderForm from "../components/salesOrderForm";

const SalesOrder = () => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  // const { number } = useParams<{ number: string }>();

  useEffect(() => {
    dispatch(getListProduct());
    dispatch(getListPartner());
    dispatch(getProvince());
    dispatch(getListExpedition());
    dispatch(getListPaymentMethod());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container className="content-body" fluid>
        <Row style={{ margin: 0 }}>
          <Col md={12}>
            <Tabs
              style={{
                backgroundColor: "white",
                padding: 10,
                border: "1px solid rgba(0,0,0,.125)",
                color: "black",
                borderRadius: ".50rem",
              }}
              variant="pills"
              defaultActiveKey="SOFORM"
              className="mb-3"
            >
              {/* <Tab eventKey="pelanggan" title="Pesanan Pelanggan">
                <SalesOrderPelangganForm />
              </Tab>

              <Tab eventKey="non" title="Pesanan Non Pelanggan">
                <NonPelangganForm />
              </Tab> */}

              <Tab eventKey="SOFORM" title="Sales Order Form">
                <SalesOrderForm returnId={id}/>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SalesOrder;
