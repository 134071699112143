import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import InvoiceApi from "./Invoice.api";
import { InvoiceModel, SalesOrderDetailModel, SalesOrderSingleDetailModel } from "./Invoice.model";

export interface InvoiceSlice {
    list?: InvoiceModel[];
    single?: InvoiceModel;
    salesOrderDetailList?: SalesOrderDetailModel[];
    salesOrderSingleDetail: SalesOrderSingleDetailModel;
    salesOrderList: any;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getInvoiceList = createAsyncThunk(
    'getInvoiceListState/getInvoiceList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await InvoiceApi.getInvoice();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getInvoiceBySONumber = createAsyncThunk(
    'getInvoiceByInvoiceNumberState/getInvoiceByInvoiceNumber',
    async (SONumber: string, { rejectWithValue }) => {
        try {
            return await InvoiceApi.getInvoiceBySONumber(SONumber);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getSalesOrderDetailList = createAsyncThunk(
    'getSalesOrderDetailListState/getSalesOrderDetailList',
    async (salesOrderId: string, { rejectWithValue }) => {
        try {
            return await InvoiceApi.getSalesOrderDetail(salesOrderId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getSalesOrderSingleDetail = createAsyncThunk(
    'getSalesOrderSingleDetailState/getSalesOrderSingleDetail',
    async (id: string, { rejectWithValue }) => {
        try {
            return await InvoiceApi.getSalesOrderSingleDetail(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getSalesOrderList = createAsyncThunk(
    'getSalesOrderListState/getSalesOrderList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await InvoiceApi.getSalesOrderList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const InvoiceSlice = createSlice({
    name: 'InvoiceState',
    initialState: {} as InvoiceSlice,
    reducers: {
        resetSingle: (state) => {
            state.single = {} as InvoiceModel
            state.status = ""
            state.salesOrderDetailList = []
        },
        resetStatus: (state) => {
            state.status = ""
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInvoiceList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getInvoiceList.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getInvoiceList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getInvoiceBySONumber.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getInvoiceBySONumber.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.isLoading = false;
        });
        builder.addCase(getInvoiceBySONumber.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSalesOrderDetailList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSalesOrderDetailList.fulfilled, (state, { payload }) => {
            state.salesOrderDetailList = payload;
            state.isLoading = false;
        });
        builder.addCase(getSalesOrderDetailList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSalesOrderSingleDetail.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSalesOrderSingleDetail.fulfilled, (state, { payload }) => {
            state.salesOrderSingleDetail = payload;
            state.isLoading = false;
        });
        builder.addCase(getSalesOrderSingleDetail.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSalesOrderList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSalesOrderList.fulfilled, (state, { payload }) => {
            state.salesOrderList = payload;
            state.isLoading = false;
        });
        builder.addCase(getSalesOrderList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    }
})

export const { resetSingle, resetStatus } = InvoiceSlice.actions;
export default InvoiceSlice.reducer;