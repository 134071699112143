import { ApiErrorResponse } from "../../..";
import { ExpeditionReportModel, FilterModel, UpdateExpeditionReportRequestModel } from "./laporanEkspedisi.model";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "./laporanEkspedisi.api";


export interface ExpeditionReportSlice{
  list? : ExpeditionReportModel[];
  totalRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getExpeditionReport = createAsyncThunk(
  'ExpeditionReportListState/ExpeditionReportList',
  async ( model : FilterModel, {rejectWithValue}) => {
      try {
          return await Api.getListWithPaging(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
);

export const updateExpeditionReport = createAsyncThunk(
  'ExpeditionReportUpdateState/ExpeditionReportUpdate',
  async ( model : UpdateExpeditionReportRequestModel, {rejectWithValue}) => {
      try {
          return await Api.updateExpeditionReport(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
);

export const exportToExcel = createAsyncThunk(
  'ExpeditionReportExportToExcelState/ExpeditionReportExportToExcel',
  async ( model : FilterModel, {rejectWithValue}) => {
      try {
          return await Api.exportToExcel(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
);

const ExpeditionReportSlice = createSlice({
  name: "ExpeditionReportState",
  initialState: {} as ExpeditionReportSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExpeditionReport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExpeditionReport.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getExpeditionReport.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateExpeditionReport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateExpeditionReport.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.isLoading = false;
    });
    builder.addCase(updateExpeditionReport.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed"
    });
}
});

export const { resetStatus } = ExpeditionReportSlice.actions;
export default ExpeditionReportSlice.reducer;