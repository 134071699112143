import { createSlice } from '@reduxjs/toolkit'
import { createHowToOrder, deleteHowToOrder, getHowToOrder, getHowToOrderDetail, updateHowToOrder } from './howToOrder.api'


export interface HowToOrderState {
  loading : boolean,
  listHowToOrder : any,
}
const initialState: HowToOrderState = {
  loading : false,
  listHowToOrder : []
}

export const howToOrderSlice = createSlice({
    name: 'how to order',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(getHowToOrder.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getHowToOrder.fulfilled, (state, action) => {
            state.loading = false
            console.log(action.payload.data)
            state.listHowToOrder = action.payload.data
        })
        builder.addCase(getHowToOrder.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        builder.addCase(createHowToOrder.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(createHowToOrder.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createHowToOrder.rejected, (state, action) => {
            state.loading = false
        })
        //delete
        builder.addCase(deleteHowToOrder.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(deleteHowToOrder.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteHowToOrder.rejected, (state, action) => {
            state.loading = false
        })
        //detail
        builder.addCase(getHowToOrderDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getHowToOrderDetail.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getHowToOrderDetail.rejected, (state, action) => {
            state.loading = false
        })
        //detail
        builder.addCase(updateHowToOrder.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateHowToOrder.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateHowToOrder.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default howToOrderSlice.reducer