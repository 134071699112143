import { createSlice } from '@reduxjs/toolkit'
import { getAllUserFilterPerubahanStock, postExportPerubahanStock, postLaporanPerubahanStock } from './laporanPerubahanStock.api'


export interface LaporanPerubahanStockState {
  loading : boolean,
  listUserFilterPerubahanStock : any,
}
const initialState: LaporanPerubahanStockState = {
  loading : false,
  listUserFilterPerubahanStock : []
}

export const laporanPerubahanStockSlice = createSlice({
    name: 'laporan perubahan stock',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(getAllUserFilterPerubahanStock.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getAllUserFilterPerubahanStock.fulfilled, (state, action) => {
            state.loading = false
            // console.log(action.payload.data)
            state.listUserFilterPerubahanStock = action.payload.data
        })
        builder.addCase(getAllUserFilterPerubahanStock.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        builder.addCase(postExportPerubahanStock.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postExportPerubahanStock.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postExportPerubahanStock.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        builder.addCase(postLaporanPerubahanStock.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postLaporanPerubahanStock.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postLaporanPerubahanStock.rejected, (state, action) => {
            state.loading = false
        })
        // //delete
        // builder.addCase(deleteSlideShow.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(deleteSlideShow.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(deleteSlideShow.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //detail
        // builder.addCase(getSlideShowDetail.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(getSlideShowDetail.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(getSlideShowDetail.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //update
        // builder.addCase(updateSlideShow.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(updateSlideShow.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(updateSlideShow.rejected, (state, action) => {
        //     state.loading = false
        // })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default laporanPerubahanStockSlice.reducer