import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import Api from "./role.api";
import { CreateRoleModel, MenuModel, RoleModel } from "./role.model";

export interface RoleSlice {
  name?: string;
  list?: RoleModel[];
  single?: RoleModel;
  menuList?: MenuModel[];
  menuAccessList?: MenuModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const createData = createAsyncThunk(
  "createState/create",
  async (Model: CreateRoleModel, { rejectWithValue }) => {
    try {
      const response = await Api.createData(Model.roleData);
      if (response.status === true) {
        let ProductCategoryModelData = Model.menuAccessData;
        ProductCategoryModelData.roleId = response.data.id;
        return await Api.createMenuAccess(ProductCategoryModelData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "getListState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getMenuList = createAsyncThunk(
  "getMenuListState/getMenuList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getMenuList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getMenuAccessList = createAsyncThunk(
  "getMenuAccessListState/getMenuAccessList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getMenuAccsessByRole(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItemRole = createAsyncThunk(
  "SingleItemState/SingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

//  export const updateData = createAsyncThunk(
//      'State/update',
//      async (model: RoleModel, {rejectWithValue}) => {
//          try {
//              return await Api.updateData(model);
//          } catch (e) {
//              return rejectWithValue(e as ApiErrorResponse<any>);
//          }
//      }
// )

export const updateData = createAsyncThunk(
  "updateState/update",
  async (Model: CreateRoleModel, { rejectWithValue }) => {
    try {
      const response = await Api.updateData(Model.roleData);
      if (response.status === true) {
        let ProductCategoryModelData = Model.menuAccessData;
        ProductCategoryModelData.roleId = Model.roleData.roleId;
        return await Api.createMenuAccess(ProductCategoryModelData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "deleteRoleState/deleteRole",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const RoleSlice = createSlice({
  name: "SupplierState",
  initialState: {} as RoleSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as RoleModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
    resetMenuAccessList: (state) => {
      state.menuAccessList = [] as MenuModel[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItemRole.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItemRole.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItemRole.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status = "success";
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      // state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getMenuList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMenuList.fulfilled, (state, { payload }) => {
      state.menuList = payload;
      state.isLoading = false;
    });
    builder.addCase(getMenuList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getMenuAccessList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMenuAccessList.fulfilled, (state, { payload }) => {
      state.menuAccessList = payload;
      state.isLoading = false;
    });
    builder.addCase(getMenuAccessList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setFilter, resetSingle, resetStatus, resetMenuAccessList } = RoleSlice.actions;
export default RoleSlice.reducer;
