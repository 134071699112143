import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getAllProductFilterAsset = createAsyncThunk('get/product/All', async () => {
    const response = await axios.get(`${endpoint}/api/Product/GetList`)
    return response.data
})

export const getAllCategoryFilterAsset = createAsyncThunk('get/category/All', async () => {
    const response = await axios.get(`${endpoint}/api/ProductCategory/GetList?isActive=true`)
    return response.data
})

export const postExportAssetProduct = createAsyncThunk('post/postExportAssetProduct', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Report/ReportAssetProductExportToExcel`,
            params: body,
            method: 'post',
            responseType: 'blob',
        })
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postAssetProductReport = createAsyncThunk('post/postAssetProductReport', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportAssetProduct?filterProduct=` + body.filterProduct + `&skip=` + body.skip + `&take=` + body.take)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postExportAssetCategory = createAsyncThunk('post/postExportAssetCategory', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Report/ReportAssetCategoryExportToExcel`,
            params: body,
            method: 'post',
            responseType: 'blob',
        })
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postAssetCategoryReport = createAsyncThunk('post/postAssetCategoryReport', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportAssetCategory?categoryName=` + body.categoryName + `&skip=` + body.skip + `&take=` + body.take)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})