import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import { SalesOrderModel, ShipmentModel } from "../customer.model";
import ListOrderDetail from "../components/ListOrderDetail";
import { getSalesOrderDetailList, getShipmentBySalesOrder, getSingleSalesOrder } from "../customer.reducer";
const CustomerSODetailPage = () => {

    const dispatch = useDispatch();

    let { id } = useParams<{ id: string }>();

    const salesOrderState = useSelector(
        (state: RootState) =>
            state.customer.singleSalesOrder ?? ({} as SalesOrderModel)
    );

    const shipmentBySalesOrderState = useSelector(
        (state: RootState) =>
            state.customer.singleShipment ?? ({} as ShipmentModel)
    );

    const salesOrderDetailListState = useSelector(
        (state: RootState) => state.customer.listSalesOrderDetail ?? []    
    )

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleSalesOrder(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (
            salesOrderState &&
            Object.keys(salesOrderState).length !== 0 &&
            salesOrderState.constructor === Object
        ) {
            // dispatch(getShipmentBySalesOrder(salesOrderState.id))
            // dispatch(getSalesOrderDetailList(salesOrderState.id))
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesOrderState]);


    return (
        <div className="m-3">
            <ListOrderDetail  
            SOData={salesOrderState} 
            // ShipmentData={shipmentBySalesOrderState} 
            // SalesOrderDetailList={salesOrderDetailListState}
            />
        </div>
    )
}

export default CustomerSODetailPage
