import { Container, Form } from "react-bootstrap";
import PesananPiutangTable from "../components/pesananPiutangTable";

/*
 * created by : dikembe mutombo
 * created date : 28/oct/2021
 */

const PesananPiutangIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan Piutang</Form.Label>
      </Form.Group>
      <PesananPiutangTable />
    </Container>
  );
};

export default PesananPiutangIndex;
