import "./App.css";
import "react-dragswitch/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import EmailConfirmationPage from "./features/emailConfirmation/containers/EmailConfirmationPage";
import ConfirmPasswordPage from "./features/confirmPassword/containers/ConfirmPasswordPage";
import ForgotPasswordPage from "./features/forgotPassword/containers/ForgotPasswordPage";
import AdminPanelPage from "./features/adminPanel/containers/AdminPanelPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserLoginPage from "./features/login/containers/UserLoginPage";

import PrintAutoCancelReport from "./features/report/autoCancelReport/components/PrintAutoCancelReport";
import PrintAutoCancelMasterReport from "./features/report/autoCancelMasterReport/components/PrintAutoCancelMasterReport";
import PrintAccountReceivableReport from "./features/report/accountReceivableReport/components/PrintAccountReceivableReport";
import PrintAssetProductReport from "./features/report/assetReport/components/PrintAssetProductReport";
import PrintAssetCategoryReport from "./features/report/assetReport/components/PrintAssetCategoryReport";
import InvoicePrint from "./features/invoice/components/InvoicePrint";
import ShippmentPrint from "./features/customer/components/ShippmentPrint";
import SalesOrderPrint from "./features/customer/components/SalesOrderPrint";
import WarehouseFormatPrint from "./features/customer/components/WarehouseFormatPrint";
import ExpeditionPrint from "./features/report/laporanEkspedisi/components/ExpeditionPrint";
import KartuStockPrint from "./features/report/laporanKartuStock/components/KartuStockPrint";
import StockOpnamePrint from "./features/Inventory/stockOpname/components/StockOpnamePrint";
import TravelDocumentPrint from "./features/customer/components/TravelDocumentPrint";
import BarcodeImagePrint from "./features/customer/components/BarcodeImagePrint";
import InvoicePrintCashless from "./features/invoice/components/InvoicePrintCashless";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/login">
            <UserLoginPage />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route path="/email-confirmation">
            <EmailConfirmationPage />
          </Route>
          <Route path="/confirm-password">
            <ConfirmPasswordPage />
          </Route>
          <Route path="/print/autocancelreport">
            <PrintAutoCancelReport />
          </Route>
          <Route path="/print/autocancelmasterreport">
            <PrintAutoCancelMasterReport />
          </Route>
          <Route path="/print/accountreceivablereport">
            <PrintAccountReceivableReport />
          </Route>
          <Route path="/print/assetproductreport">
            <PrintAssetProductReport />
          </Route>
          <Route path="/print/assetcategoryreport">
            <PrintAssetCategoryReport />
          </Route>
          <Route path="/invoice/print/:number">
            <InvoicePrint />
          </Route>
          <Route path="/invoice-cashless/print/:number">
            <InvoicePrintCashless />
          </Route>
          <Route path="/barcode-image/print/:id">
            <BarcodeImagePrint />
          </Route>
          <Route path="/shipment/print/:id">
            <ShippmentPrint/>
          </Route>
          <Route path="/sales-order/print/:id">
            <SalesOrderPrint/>
          </Route>
          <Route path="/warehouse-format/print/:id">
            <WarehouseFormatPrint/>
          </Route>
          <Route path="/report-expedition/print">
            <ExpeditionPrint/>
          </Route>
          <Route path="/report-kartu-stock/print">
            <KartuStockPrint/>
          </Route>
          <Route path="/stock-opname/print/:id">
            <StockOpnamePrint/>
          </Route>
          <Route path="/travel-document/print/:id">
            <TravelDocumentPrint/>
          </Route>
          <Route path="/">
            <AdminPanelPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
