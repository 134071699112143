import { Card, Form, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import edit from "./../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import { SalesOrderModel } from "../pesananLunas.model";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "../pesananLunas.reducer";
import { RootState } from "../../../../app/store";
import { BsXCircle } from "react-icons/bs";
import { FaRegCheckCircle } from "react-icons/fa";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

/*
 * created by : dikembe mutombo
 * created date : 26/oct/2021
 */

const PesananLunasTable = () => {
  const dispatch = useDispatch();

  const listState = useSelector((state: RootState) => state.pesananLunas.list ?? []);

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState<SalesOrderModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  //filter
  const [soNumber, setSoNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("1");

  const totalRowState = useSelector(
    (state: RootState) => state.pesananLunas.totalRow ?? 0
  );

  // const onFilterClick = () => {
  //   fetchData(0, perPage);
  // };

  const onFilterResetClick = () => {
    // setFilterName("");
    // setFilterInventorySKU("");
    // setFilterProductType(null);
    // setFilterTempProductType(2);
    // setFilterStartDate("");
    // setFilterEndDate("");

    // dispatch(
    //   getListWithPaging({
    //     name: "",
    //     inventorySKU: "",
    //     productType: null,
    //     startDate: "",
    //     endDate: "",
    //     skip: 0,
    //     take: perPage,
    //   })
    // );
  };

  

  useEffect(() => {
    setLoading(true);

    dispatch(
      getList({
        soNumber: "",
        customerName: "",
        orderDate: "",
        salesOrderStatus: "",
        paymentStatus: "1",
        skip: totalRows,
        take: perPage,
      })
    );

    setList(listState);

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setList(listState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listState]);

  useEffect(() => {
    setLoading(true);

    dispatch(
      getList({
        soNumber: soNumber,
        customerName: customerName,
        orderDate: "",
        salesOrderStatus: "",
        paymentStatus: "1",
        skip: totalRows,
        take: perPage,
      })
    );

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soNumber, customerName, orderDate, paymentStatus]);

  const columns = [
    {
      name: "SO Number",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "NAMA PELANGGAN",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "TANGGAL PESANAN",
      selector: (d: { orderDate: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.orderDate.slice(0, 10)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "TANGGAL PELUNASAN",
      selector: (d: { paymentConfirmationDate: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.paymentConfirmationDate !== "" && d.paymentConfirmationDate.slice(0, 10)}
          </p>
        </>
      ),
      sortable: true,
    },
    // {
    //   name: "Tipe Produk",
    //   selector: (d: { productType: number }) => (
    //     <p style={{ margin: 10 }}>
    //       {d.productType === 0 && <span>Ready</span>}
    //       {d.productType === 1 && <span>Pre Order</span>}
    //       {d.productType >= 2 && <span>-</span>}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "TOTAL",
      selector: (d: { grandTotal: any }) => (
        <>
          <NumberFormat
            value={d.grandTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS PEMBAYARAN",
      selector: (d: { paymentStatus: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.paymentStatus === 0 ? "UNPAID" : "PAID"}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS PENGIRIMAN",
      selector: (d: { shippingStatus: any }) => (
        <>
          {d.shippingStatus === 0 && <p style={{ paddingTop: 15 }}>Pending</p>}
          {d.shippingStatus === 1 && <p style={{ paddingTop: 15 }}>Print Request</p>}
          {d.shippingStatus === 2 && <p style={{ paddingTop: 15 }}>On Progress</p>}
          {d.shippingStatus === 3 && <p style={{ paddingTop: 15 }}>On Delivery</p>}
          {d.shippingStatus === 4 && <p style={{ paddingTop: 15 }}>Delivered</p>}
          {d.shippingStatus === 5 && <p style={{ paddingTop: 15 }}>Completed</p>}

   
        </>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          {/* <Link
            to={{
              pathname: `/order-detail/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link> */}

          <Link
            to={{
              pathname: `/customer-so-detail/${d.id}`,
            }}>
            <img src={edit}></img>
          </Link>
        </>
      ),
    },
  ];
  // end of datatable components

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getList({
        soNumber: soNumber,
        customerName: customerName,
        orderDate: orderDate.toString(),
        salesOrderStatus: "",
        paymentStatus: "1",
        skip: page,
        take: perPage,
      })
    );

    setLoading(false);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);

    dispatch(
      getList({
        soNumber: soNumber,
        customerName: "",
        orderDate: "",
        salesOrderStatus: "",
        paymentStatus: "1",
        skip: page,
        take: newPerPage,
      })
    );

    setPerPage(newPerPage);
    setLoading(false);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    SO Number
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      value={soNumber}
                      onChange={(e) => setSoNumber(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Pembeli
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Tanggal Pesanan
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="date"
                      // value={new Date(orderDate).toISOString().substr(0, 10)}
                      onChange={(e) => setOrderDate(new Date(e.target.value).toISOString())}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Status Pembayaran
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      as="select"
                      value={paymentStatus}
                      disabled
                      onChange={(e) => setPaymentStatus(e.target.value)}
                    >
                      <option value={1}>PAID</option>
                      <option value={0}>UNPAID</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <DataTable
            columns={columns}
            data={listState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default PesananLunasTable;
