import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { updateOrderDetail } from "../keepBelumLunas.reducer";
import { SalesOrderModel } from "../keepBelumLunas.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { message } from "antd";

/*
 * created by : dikembe mutombo
 * created date : 26/oct/2021
 */

const OrderDetail = ({
  data = {} as SalesOrderModel,
  isEdit = false,
  ...props
}: OrderDetailProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const paymentMethodState = useSelector(
    (state: RootState) => state.global.listPaymentMethod ?? []
  );

  const statusState = useSelector((state: RootState) => state.keepBelumalunas.status ?? "");

  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [statusOrder, setStatusOrder] = useState(0);
  const [orderType, setOrderType] = useState(0);
  const [dateFormater, setDateFormater] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  const [partnerName, setPartnerName] = useState("");

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setStatusOrder(data.paymentStatus);
      setPaymentMethodId(data.paymentMethodId);
      setOrderType(data.orderType);
      setDateFormater(changeDateFormat(data.orderDate));
      setPartnerEmail(data.partnerEmail);
      setPartnerName(data.partnerName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (statusState === "success") {
      message.success("Data Berhasil di Update");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  const changeDateFormat = (date: Date) => {
    var hari = ["Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu", "Minggu"];
    var strArray = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    let asd = new Date(date);
    let d = asd.getDate();
    let day = hari[asd.getDay()];
    let m = strArray[asd.getMonth()];
    let y = asd.getFullYear();
    console.log(day);
    return day + "," + (d <= 9 ? "0" + d : d) + " " + m + " " + y;
  };

  const onClickUpdate = () => {
    dispatch(
      updateOrderDetail({
        id: id,
        orderDate: data.orderDate,
        paymentMethodId,
        statusOrder,
        shipmentCost: data.shipmentCost,
        discount: data.discount,
        discountPercentage: data.discountPercentage,
        discountType: data.discountType,
        note: data.note,
        soNumber: data.soNumber,
        transactionStatus: data.transactionStatus,
        subTotal: data.subTotal,
        grandTotal: data.grandTotal,
        qtyTotal: data.qtyTotal,
        totalTax: data.totalTax,
        taxPercentage: data.taxPercentage,
        taxStatus: data.taxStatus,
        partnerId: data.partnerId,
        orderType: data.orderType,
        adminFee: data.adminFee,
        paymentStatus: statusOrder
      } as SalesOrderModel)
    );
  };

  return (
    <Grid textAlign="center">
      <Card>
        <Card.Body>
          <h5>Order Data</h5>
          <hr />
          <Row>
            <Col md={6}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Order ID
                </Form.Label>
                <Form.Label column sm={1}>
                  :
                </Form.Label>
                <Form.Label column sm={4}>
                  {data.id !== undefined && data.id.slice(0, 6).toUpperCase()}
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Order Type
                </Form.Label>
                <Form.Label column sm={1}>
                  :
                </Form.Label>
                <Form.Label column sm={4}>
                  {orderType === 0 && "Pesanan Dalam Proses"}
                  {orderType === 1 && "Pesanan Bayar Di tempat (Lunas)"}
                  {orderType === 2 && "Pesanan Belum Lunas (Rekap / COD)"}
                  {orderType === 3 && "Pesanan Bayar Cash (Lunas)"}
                  {orderType === 4 && "Pesanan Dropship (Belum Lunas)"}
                  {orderType === 5 && "Pesanan Dropship (Lunas)"}
                  {orderType === 6 && "Pesanan Piutang (Belum Lunas)"}
                  {orderType === 7 && "Pesanan Tanpa Konfirmasi Pembayarans"}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <hr style={{ margin: 0 }} />

          <Row>
            <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Customer
                </Form.Label>
                <Form.Label column sm={1}>
                  :
                </Form.Label>
                <Form.Label column sm={4}>
                  {partnerName}
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Payment Status
                </Form.Label>
                <Form.Label column sm={1}>
                  :
                </Form.Label>
                <Form.Label column sm={6}>
                  {statusOrder === 1 ? "Paid" : "Unpaid"}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <hr style={{ margin: 0 }} />

          <Row>
            <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Email
                </Form.Label>
                <Form.Label column sm={1}>
                  :
                </Form.Label>
                <Form.Label column sm={6}>
                  {partnerEmail}
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Payment Method
                </Form.Label>
                <Col sm={1}>:</Col>
                <Col sm={6}>
                  <Form.Control
                    as="select"
                    value={paymentMethodId}
                    onChange={(e) => {
                      setPaymentMethodId(e.target.value);
                    }}
                  >
                    <option>select</option>
                    {paymentMethodState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <hr style={{ margin: 0 }} />

          <Row>
            <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Order Date
                </Form.Label>
                <Form.Label column sm={1}>
                  :
                </Form.Label>
                <Form.Label column sm={6}>
                  {dateFormater}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <h5>Action</h5>
          <hr />
          <Row>
            <Col md={2}>
              <Form.Check
                label="Paid"
                name="group1"
                type="radio"
                style={{ paddingBottom: 10 }}
                checked={statusOrder === 1}
                value={1}
                onChange={() => setStatusOrder(1)}
              />
              <br />
              <Form.Check
                label="Unpaid"
                name="group1"
                type="radio"
                style={{ paddingBottom: 10 }}
                checked={statusOrder === 0}
                value={0}
                onChange={() => setStatusOrder(0)}
              />
              <br />
              <Button
                style={{ width: 126, height: 35, fontSize: 14 }}
                variant="info"
                onClick={onClickUpdate}
              >
                Change Status
              </Button>
            </Col>
            <Col md={7}>
              <Button style={{ fontSize: 14, width: 202, height: 40 }} variant="outline-info">
                Print Purhcase Order
              </Button>{" "}
              <Button style={{ fontSize: 14, width: 191, height: 40 }} variant="outline-info">
                Print Shipping Data
              </Button>{" "}
              <Button style={{ fontSize: 14, width: 154, height: 40 }} variant="outline-info">
                Export To pdf
              </Button>{" "}
              <Row>
                <Col md={2}>
                  <Card.Subtitle
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: 12,
                      lineHeight: 5,
                      width: 110,
                    }}
                    className="mb-3 text-muted "
                  >
                    Action Description :{" "}
                  </Card.Subtitle>
                </Col>
                <Col style={{ marginTop: 15, marginLeft: 7 }}>
                  <div
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: 12,
                      color: "#00A8CC",
                    }}
                    className=""
                  >
                    * Already printed purchase order
                  </div>
                  <div
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: 12,
                      color: "#00A8CC",
                    }}
                    className=""
                  >
                    * Already printed shipping data
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button style={{ fontSize: 14, width: 154, height: 40 }} variant="danger">
                Cancel Order
              </Button>{" "}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Grid>
  );
};

export interface OrderDetailProps {
  data: SalesOrderModel;
  isEdit: boolean;
}

export default OrderDetail;
