import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { ApiErrorResponse } from '../../..';
import Api from './collectionTag.api';
import { CollectionTagModel, CreateCollectionModel } from './collectionTag.model';

export interface CollectionTagSlice {
    name? :string;
    list?: CollectionTagModel[];
    single?: CollectionTagModel;
    isLoading?: boolean;    
    error?: ApiErrorResponse<any>;
    status?: string;
    dateModified? :string;
    parentList? : [];
}

export const createData = createAsyncThunk(
    'CollectionTagCreateStateCollectionTagCreate',
    async (Model: CreateCollectionModel, {rejectWithValue}) => {
        try {
            //return await Api.createData(Model);
            // const response = await Api.uploadImage(Model.UploadImageModel);
            // if (response.status === 200){
                // let collectionModelData = Model.CollectionTagModel
                // collectionModelData.banner = response.data.imagepath
                return await Api.createData(Model.CollectionTagModel);       
            // }  
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getList = createAsyncThunk(
     'CollectionTagGetListStateCollectionTagGetList',
     async ( _:string | undefined = undefined, {rejectWithValue}) => {
         try {
             return await Api.getList();
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 );

export const getSingleItem = createAsyncThunk(
     'CollectionTagSingleItemState/CollectionTagSingleItem',
    async (customerId: string, {rejectWithValue}) => {
         try {
             return await Api.getSingleItem(customerId);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 )

 export const updateData = createAsyncThunk(
     'CollectionTagState/CollectionTagUpdate',
     async (model: CollectionTagModel, {rejectWithValue}) => {
         try {
             return await Api.updateData(model);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
)

export const updateImage = createAsyncThunk(
    'CollectionTagState/CollectionTagUpdate',
    async (Model: CreateCollectionModel, {rejectWithValue}) => {
        try {
            const response = await Api.uploadImage(Model.UploadImageModel);
            if (response.status === 200){
                let CollectionTagModelData = Model.CollectionTagModel
                CollectionTagModelData.banner = response.data.imagepath
                return await Api.updateData(CollectionTagModelData);       
            }  
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteData = createAsyncThunk(
    'CollectionTagState/CollectionTagDelete',
    async (id: string, {rejectWithValue}) =>{
        try {
            return await Api.deleteData(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)


const CollectionTagSlice = createSlice({
    name: 'CollectionTagState',
    initialState: {} as CollectionTagSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        reset: (state) => {
            state.single = {} as CollectionTagModel;
        }, 
        resetStatus: (state) => {
            state.status = "";
        }, 
    },
    extraReducers: (builder) => {
        builder.addCase(createData.pending, (state) => {
          state.isLoading = true;
          state.status = "";
        });
        builder.addCase(createData.fulfilled, (state, { payload }) => {
          state.name = new Date().toISOString();
          state.isLoading = false;
          state.status = "success";
        });
        builder.addCase(createData.rejected, (state, { payload }) => {
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
          state.status = "failed";
        });
        builder.addCase(getSingleItem.pending, (state) =>{
            state.single = undefined;
            state.isLoading = true;
            
        });
        builder.addCase(getSingleItem.fulfilled, (state, {payload})=>{
            state.single = payload;
            state.isLoading = false;
        })
        builder.addCase(getSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })
        builder.addCase(getList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload})=> {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateData.fulfilled, (state, {payload})=> {
            state.isLoading = false;
            state.name = new Date().toISOString();
            state.status = "success";
        })
        builder.addCase(updateData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.status = "failed";
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteData.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.dateModified = new Date().toISOString();
        })
        builder.addCase(deleteData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
      },
})

export const { setFilter, reset, resetStatus } = CollectionTagSlice.actions;
export default CollectionTagSlice.reducer;