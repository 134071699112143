import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { postAccountReceivableReport } from "../AccountReceivableReport.api"
import { unwrapResult } from '@reduxjs/toolkit';
import moment from "moment";

export default function PrintAccountReceivableReport() {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        reportType: new URLSearchParams(window.location.search).get('reportType'),
        filterMonthly: new URLSearchParams(window.location.search).get('filterMonthly'),
        startFilterDate: new URLSearchParams(window.location.search).get('startFilterDate'),
        endFilterDate: new URLSearchParams(window.location.search).get('endFilterDate'),
        customerName: new URLSearchParams(window.location.search).get('customerName'),
        soNumber: new URLSearchParams(window.location.search).get('soNumber'),
        productName: new URLSearchParams(window.location.search).get('productName'),
        paymentStatus: new URLSearchParams(window.location.search).get('paymentStatus'),
        skip: new URLSearchParams(window.location.search).get('skip'),
        take: new URLSearchParams(window.location.search).get('take')
    })

    const [list, setList] = useState<any>([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        const data = {
            reportType: parseInt(values.reportType),
            filterMonthly: values.filterMonthly.length !== 0 ? new Date(values.filterMonthly).toISOString() : new Date().toISOString(),
            startFilterDate: values.startFilterDate === "" ? new Date().toISOString() : new Date(values.startFilterDate).toISOString(),
            endFilterDate: values.endFilterDate === "" ? new Date().toISOString() : new Date(values.endFilterDate).toISOString(),
            customerName: values.customerName,
            soNumber: values.soNumber,
            productName: values.productName,
            paymentStatus: values.paymentStatus === 'null' ? null : parseInt(values.paymentStatus),
            skip: values.skip,
            take: parseInt(values.take)
        }
        dispatch<any>(postAccountReceivableReport(data))
            .then(unwrapResult)
            .then((res: any) => {
                setList(res.data.list)
                setTimeout(() => {
                    window.print()
                }, 1000)
            })
            .catch((err: any) => console.log(err))
    }

    const moneyParser = (value: any) => {
        let valueString = value.toString()
        let valueList = valueString.split('')
        let resultList: any = []
        valueList.reverse().map((c: any, i: number) => {
            resultList.push(c)
            if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
                resultList.push('.')
            }
        })
        let result = resultList.reverse().join('')
        return "Rp" + result + ",00"
    }

    return (
        <table className="table">
            <thead className="">
                <tr>
                    <th scope="col">No</th>
                    <th scope="col">Tanggal Pesanan</th>
                    <th scope="col">ID Pesanan</th>
                    <th scope="col">Nama Pelaggan</th>
                    <th scope="col">Kode Produk</th>
                    <th scope="col">Nama Produk</th>
                    <th scope="col">QTY</th>
                    <th scope="col">Total</th>
                    <th scope="col">Status Pesanan</th>
                    <th scope="col">User</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map((res: any, i: number) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{moment(new Date(res.orderDate)).format('DD/MM/YYYY HH:mm')}</td>
                            <td>{res.soNumber}</td>
                            <td>{res.customerName}</td>
                            <td>{res.productCode}</td>
                            <td>{res.productName}</td>
                            <td>{res.qty}</td>
                            <td>{moneyParser(res.total)}</td>
                            <td>{res.paymentStatusName}</td>
                            <td>{res.user}</td>
                        </tr>
                    ))
                }

            </tbody>
        </table>
    )
}