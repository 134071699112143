import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import CustomerOrderDetail from "../components/CustomerOrderDetail";
import { CustomerModel, SalesOrderCountModel } from "../customer.model";
import { getCountSalesOrderDetail, getSalesOrderByPartner } from "../customer.reducer";

const CustomerOrderPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const salesOrderCountState = useSelector(
    (state: RootState) =>
      state.customer.SalesOrderCount ?? ({} as SalesOrderCountModel)
  );

  const salesOrderList = useSelector(
      (state : RootState) => state.customer.SalesOrderList ?? []
  )

  useEffect(() => {
    console.log("CustomerID : "+id)
    if (id !== undefined) {
        
      dispatch(getCountSalesOrderDetail(id));
      dispatch(getSalesOrderByPartner(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <CustomerOrderDetail data={salesOrderCountState} SOList={salesOrderList} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerOrderPage;
