import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Container } from "react-bootstrap";
import uploadImage from '../../../../assets/vector/upload.png'

import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { getHowToOrderDetail } from '../howToOrder.api';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export default function ViewHowToOrder() {
    const { id } = useParams<any>();
    const dispatch = useDispatch()

    const handleEditorChange = (value:any) => {
        console.log('handleEditorChange', value.html, value.text);
        console.log(encodeURIComponent(value.text), 'url encode')
    }
    const mdParser = new MarkdownIt(/* Markdown-it options */);
    const [values, setValues] = useState<any>({
        "type": 0,
        "titleHowToOrder": "",
        "contentHowToOrder": "",
        "imageSetting": 0,
        "titleBroadcastTheme": "",
        "toBroadcastTheme": "",
        "messageBroadcastTheme": "",
        "linkIOSStockist": "",
        "linkAndroidStockist": "",
        "linkIOSReseller": "",
        "linkAndroidReseller": "",
        "linkIOSEndCustomer": ""
    })
    
    useEffect(() => {
        dispatch<any>(getHowToOrderDetail(id))
        .then(unwrapResult)
        .then((res:any)=>{
            console.log(res)
            setValues({
                ...values,
                ...res
            })
        })
    }, [])
      

    return (
        <Container className="content-body addHowToOrderForm" fluid>
            <div className="page-title mb-4 title-link">
                <Link to='/setting-how-order'>
               <FiChevronLeft /> 
               </Link>
               View How to Order
            </div>
            <div className="box">
                <div className="titbox">View How to Order</div>
                <form >
                    <Row className='forms'>
                        <Col md={3}><p>Title</p> </Col>
                        <Col md={9}>
                        <input type="text" name="infoStore" placeholder='Title' value={values.titleHowToOrder} readOnly />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p className='mb-3'>Content</p> </Col>
                        {/* <Col md={9}> */}
                            <MdEditor 
                            className='mx-3'
                            readOnly
                            value={decodeURIComponent(values.contentHowToOrder)}
                            style={{ height: '175px' }}
                            renderHTML={text => mdParser.render(text)} 
                            onChange={handleEditorChange} 
                            />
                        {/* </Col> */}
                    </Row>
                </form>
            </div>
        </Container>
    )
}
