import axios from "axios";
import { endpoint } from "../../constants";
import {CreateAdminStockistModel, GetListSalesOrderByPartnerRequest} from "./adminStockist.model";

export default class AdminStockistApi {

    static getAdminStockistList() {
        return axios
            .get<any>(endpoint + "​/api/AdminStockist/GetList")
            .then((res) => res.data.data);
    }

    static createAdminStockist(adminStockistModel: CreateAdminStockistModel) {
        return axios
            .post<any>(
                endpoint + "/api/AdminStockist/Create",
                adminStockistModel
            )
            .then((res) => res.data.data);
    }

    static updateAdminStockist(adminStockistModel: CreateAdminStockistModel) {
        return axios
            .post<any>(
                endpoint + "/api/AdminStockist/Update",
                adminStockistModel
            )
            .then((res) => res.data.data);
    }

    static getStockistList() {
        return axios
            .get<any>(endpoint + "/api/Partner/GetListByType?type=1")
            .then((res) => res.data.data);
    }

    static getAdminStockistSingleItem(adminStockistId: string) {
        return axios
            .get<any>(endpoint + "/api/AdminStockist/GetSingleItem?id=" + adminStockistId
            )
            .then((res) => res.data.data);
    }

    static deleteAdminStockist(id: string) {
        return axios
            .post<any>(endpoint + "/api/AdminStockist/Delete?Id=" + id)
            .then((res) => [
                { response: res.data.data },
            ]);
    }

    static getCountSalesOrder(id: string) {
        return axios
            .get<any>(endpoint + "/api/SalesOrder/CountSalesOrderDetailByPartner?partnerId=" + id)
            .then((res) => res.data.data)
    }

    static getListSalesOrderByPartner(id: string) {
        return axios
            .get<any>(endpoint + "/api/SalesOrder/GetListByPartner?partnerId=" + id)
            .then((res) => res.data.data)
    }

    // static getListSalesOrderByPartner(args: GetListSalesOrderByPartnerRequest) {
    //     return axios
    //         .get<any>(endpoint + "/api/SalesOrder/GetListByPartner", {
    //             params: {
    //                 partnerId: args.partnerId,
    //                 skip: args.skip,
    //                 take: args.take
    //             }
    //         })
    //         .then((res) => res.data.data)
    // }

    static getDetailSalesOrder(id: string) {
        return axios
            .get<any>(endpoint + "​/api/SalesOrder/GetSingleWithDetail?id=" + id)
            .then((res) => res.data.data)
    }



}