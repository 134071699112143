import { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { getListProduct, getListProductCategory, getListUOM, getListWarehouse } from "../../../../global/global.reducer";
import PenerimaanInventoryForm from "../components/penerimaanInventoryForm";
import ProductTableComponent from "../components/productTableComponent";
import {
  CreateItemReceiveModel,
  ItemReceiveDetailModel,
  ItemReceiveModel,
} from "../penerimaanInventory.model";
import {
  createData,
  getItemReceiveDetailList,
  getSingleItem,
  updateData,
} from "../penerimaanInventory.reducer";

const PenerimaanInventoryPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.penerimaanInventory.single ?? ({} as ItemReceiveModel)
  );

  const listInventoryMoveDetailState = useSelector(
    (state: RootState) => state.penerimaanInventory.listItemReceiveDetail ?? []
  );

  const [isEdit, setIsEdit] = useState(false);
  const [itemReceiveDetail, setItemReceive] = useState<ItemReceiveDetailModel[]>([]);
  const [itemReceiveDetailData, setItemReceiveDetailData] = useState({
    itemReceiveId: "",
    itemReceiveDetailItem: itemReceiveDetail,
  });

  const onItemReceiveDetailSubmit = (inventoryMoveDetail: ItemReceiveDetailModel[]) => {
    setItemReceive(inventoryMoveDetail);
    var temp = {
      itemReceiveId: "",
      itemReceiveDetailItem: inventoryMoveDetail,
    };
    setItemReceiveDetailData(temp);
  };

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
      dispatch(getItemReceiveDetailList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(getListWarehouse());
    dispatch(getListProduct());
    dispatch(getListUOM());
    dispatch(getListProductCategory());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (
    receiveDate: Date,
    userId: string,
    warehouseId: string,
    receiveNumber: string,
    note: string,
    from: string
  ) => {

    console.log("SUBMIT !!!")

    if (isEdit) {
      dispatch(
        updateData({
          ItemReceive: {
            id,
            receiveDate,
            userId,
            warehouseId,
            receiveNumber,
            note,
            from,
          },
          ItemReceiveDetail: itemReceiveDetailData,
        } as CreateItemReceiveModel)
      );
    } else {
      dispatch(
        createData({
          ItemReceive: {
            receiveDate,
            userId,
            warehouseId,
            receiveNumber,
            note,
            from,
          },
          ItemReceiveDetail: itemReceiveDetailData,
        } as CreateItemReceiveModel)
      );
    }
  };

  console.log("asdasd", listInventoryMoveDetailState);
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <PenerimaanInventoryForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
          <section>
            <ProductTableComponent onSubmitData={onItemReceiveDetailSubmit} isEdit={isEdit} />
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default PenerimaanInventoryPage;
