import { createSlice } from '@reduxjs/toolkit'
import { getUserContactAll, getUserContactCustomer, getUserContactReseller, getUserContactStockist, postImageUploadChat, postLastMessage, sendMessageChatPage } from './chatPage.api'


export interface ChatPageState {
  loading : boolean,
  chatLoading : boolean,
  imageLoading : boolean,
  listContactAll : any,
  listContactReseller : any,
  listContactStockist : any,
  listContactCustomer : any,
}
const initialState: ChatPageState = {
  loading : false,
  chatLoading : false,
  imageLoading : false,
  listContactAll : [],
  listContactReseller : [],
  listContactStockist : [],
  listContactCustomer : [],
}

export const chatPageSlice = createSlice({
    name: 'chat page',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(getUserContactAll.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getUserContactAll.fulfilled, (state, action) => {
            state.loading = false
            console.log(action.payload.data)
            state.listContactAll = action.payload.data
        })
        builder.addCase(getUserContactAll.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(getUserContactReseller.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getUserContactReseller.fulfilled, (state, action) => {
            state.loading = false
            console.log(action.payload.data)
            state.listContactReseller = action.payload.data
        })
        builder.addCase(getUserContactReseller.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(getUserContactStockist.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getUserContactStockist.fulfilled, (state, action) => {
            state.loading = false
            console.log(action.payload.data)
            state.listContactStockist = action.payload.data
        })
        builder.addCase(getUserContactStockist.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(getUserContactCustomer.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getUserContactCustomer.fulfilled, (state, action) => {
            state.loading = false
            console.log(action.payload.data)
            state.listContactCustomer = action.payload.data
        })
        builder.addCase(getUserContactCustomer.rejected, (state, action) => {
            state.loading = false
        })
        // send message
        builder.addCase(sendMessageChatPage.pending, (state, action) => {
          state.chatLoading = true
        })
        builder.addCase(sendMessageChatPage.fulfilled, (state, action) => {
            state.chatLoading = false
        })
        builder.addCase(sendMessageChatPage.rejected, (state, action) => {
            state.chatLoading = false
        })
        // post
        builder.addCase(postImageUploadChat.pending, (state, action) => {
          state.imageLoading = true
        })
        builder.addCase(postImageUploadChat.fulfilled, (state, action) => {
            state.imageLoading = false
        })
        builder.addCase(postImageUploadChat.rejected, (state, action) => {
            state.imageLoading = false
        })
        //post last message
        builder.addCase(postLastMessage.pending, (state, action) => {
          state.imageLoading = true
        })
        builder.addCase(postLastMessage.fulfilled, (state, action) => {
            state.imageLoading = false
        })
        builder.addCase(postLastMessage.rejected, (state, action) => {
            state.imageLoading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { handleLiveChat} = chatPageSlice.actions

export default chatPageSlice.reducer