import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getAllCustomerFilterAutoCancel = createAsyncThunk('get/customer/All', async () => {
    const response = await axios.get(`${endpoint}/api/Partner/GetList`)
    return response.data
})

export const postExportAutoCancel = createAsyncThunk('post/postExportAutoCancel', async (body:any) => {
    try {
        const response = await axios({
            url : `${endpoint}/api/Report/ReportAutoCancelExportToExcel`, 
            data: body,
            method:'post',
            responseType: 'blob', 
        })
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const postAutoCancelReport= createAsyncThunk('post/postAutoCancelReport', async (body:any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportAutoCancel`, body)
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})