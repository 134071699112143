import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";
import { createCustomer, getCustomerSingleItem, updateCustomer } from "../customer.reducer";
import { createCustomerModel, CustomerModel } from "../customer.model";
import { useHistory, useParams } from "react-router-dom";
import CustomerCreate from "../components/CustomerCreate";
import { RootState } from "../../../app/store";
import { FaChevronLeft } from "react-icons/fa";
import {UserModel} from "../../user/models";

const CustomerPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();
  let history = useHistory();

  const userLoginState = useSelector((state: RootState) => state.user ?? {});

  const [customerUserId, setCustomerUserId] = useState("");

  const singleDataState = useSelector(
    (state: RootState) => state.customer.single ?? ({} as CustomerModel)
  );
  const singleCustomerData = useSelector(
      (state: RootState) => state.customer.singleCustomerData ?? ({} as CustomerModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
      setCustomerUserId(singleDataState.userId??"")
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    console.log(id)
    if (id !== undefined) {
      dispatch(getCustomerSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const onSubmit = (
    fullName: string,
    phoneNumber: string,
    email: string,
    gender: number,
    password: string,
    confirmPassword: string,
    address: string,
    countryId: string,
    provinceId: string,
    cityId: string,
    districtId: string,
    postalCode: string,
    isActive: boolean
  ) => {
    if (isEdit) {
      console.log(password + " - " + confirmPassword);
      dispatch(
        updateCustomer({
          customerData: {
            id,
            fullName,
            phoneNumber,
            email,
            gender,
            password,
            confirmPassword,
            address,
            countryId,
            provinceId,
            cityId,
            districtId,
            postalCode,
            isActive,
            stockistMaxToken: singleCustomerData.stockistMaxToken,
          },
          checkPasswordData: {
            userId: customerUserId,
            password: password,
          },
        } as createCustomerModel)
      );
    } else {
      dispatch(
        createCustomer({
          customerData: {
            fullName,
            phoneNumber,
            email,
            gender,
            password,
            confirmPassword,
            address,
            countryId,
            provinceId,
            cityId,
            districtId,
            postalCode,
          },
          checkPasswordData: {
            userId: customerUserId,
            password: password,
          },
        } as createCustomerModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Form.Group>
            <div onClick={() => history.goBack()} className="form-title">
              <FaChevronLeft />
              {"            "}
              {isEdit ? "Edit Data Customer" : "Add Data Customer"}
            </div>
          </Form.Group>
          <CustomerCreate onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerPage;
