import { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../app/store";
import InventoryReceiveTable from "../components/inventoryReceiveTable";
import { getList, resetSingle } from "../inventoryReceive.reducer";

const InventoryReceiveIndex = () => {
  const dispatch = useDispatch();

  const modifiedDateState = useSelector(
    (state: RootState) => state.inventoryReceive.modifiedDate ?? ""
  );

  useEffect(() => {
    // dispatch(getList());j
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDateState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Inventory Receive</Form.Label>
      </Form.Group>

      <InventoryReceiveTable />
    </Container>
  );
};

export default InventoryReceiveIndex;
