import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";

import RegisterResellerApi from "./registerReseller.api";
import {
    ResellerModel,
    ProvinceModel,
    CityModel,
    DistrictModel,
    UploadImageModel,
    StoreTypeEnum,
    PartnerRegisterModel,
    PartnerStore,
    PartnerRequest,
    ResellerSocialMediaModel,
    RejectionModel,
    FilterModel
} from "./registerReseller.model";

export interface RegisterResellerSlice {
    name?: string;
    list?: ResellerModel[];
    single?: PartnerRegisterModel;
    userId?: string;
    partnerId?: string;
    listProvince?: ProvinceModel[];
    listCity?: CityModel[];
    listDistrict?: DistrictModel[];
    listStoreProvince?: ProvinceModel[];
    listStoreCity?: CityModel[];
    listStoreDistrict?: DistrictModel[];
    imageSelfPath?: string;
    imageKtpPath?: string;
    imageSelfKtpPath?: string;
    imageNpwpPath?: string;
    storeList?: PartnerStore[];
    storeTypeEnumList?: StoreTypeEnum[];
    socialMediaList?: ResellerSocialMediaModel[];
    totalRow? : number;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getSingleResellerData = createAsyncThunk(
    'GetSingleResellerDataState/GetSingleResellerData',
    async (partnerId: string, {rejectWithValue}) => {
        try {
            return await RegisterResellerApi.getSingleResellerData(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const registerReseller = createAsyncThunk(
    'registerResellerState/registerReseller',
    async (model: PartnerRegisterModel, {rejectWithValue}) => {
        try {
            return await RegisterResellerApi.registerReseller(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>)
        }
    }
)

export const getResellerList = createAsyncThunk(
    'getResellerListState/getResellertList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getResellerList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getResellerListWithPaging = createAsyncThunk(
    'getResellerListWithPagingState/getResellerListWithPaging',
    async (model: FilterModel, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getCustomerListWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getProvince = createAsyncThunk(
    'ProvinceState/Province',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getProvince();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getCity = createAsyncThunk(
    'CityState/City',
    async (provinceId: string, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getCity(provinceId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getDistrict = createAsyncThunk(
    'DistrictState/District',
    async (cityId: string, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getDistrict(cityId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreProvince = createAsyncThunk(
    'StoreProvinceState/StoreProvince',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getProvince();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreCity = createAsyncThunk(
    'StoreCityState/StoreCity',
    async (provinceId: string, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getCity(provinceId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreDistrict = createAsyncThunk(
    'StoreDistrictState/StoreDistrict',
    async (cityId: string, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getDistrict(cityId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreTypeEnumList = createAsyncThunk(
    'StoreTypeEnumState/StoreTypeEnum',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getStoreTypeEnumList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getSocialMediaEnumList = createAsyncThunk(
    'SocialMediaEnumState/SocialMediaEnum',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getSocialMediaEnumList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const uploadPhoto = createAsyncThunk(
    'UploadPhotoKtpResellerState/UploadPhotoKtpReseller',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const uploadPhotoSelf = createAsyncThunk(
    'UploadPhotoSelfResellerState/UploadPhotoSelfReseller',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)


export const uploadPhotoSelfKtp = createAsyncThunk(
    'UploadPhotoSelfKtpResellerState/UploadPhotoSelfKtpReseller',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)


export const uploadPhotoNpwp = createAsyncThunk(
    'UploadPhotoNpwpResellerState/UploadPhotoNpwpReseller',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getPartnerStore = createAsyncThunk(
    'PartnerStoreState/PartnerStore',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterResellerApi.getPartnerStoreList(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const approveReseller = createAsyncThunk(
    'ApproveResellerState/ApproveReseller',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterResellerApi.approveReseller(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const rejectReseller = createAsyncThunk(
    'RejectResellerState/RejectReseller',
    async (rejectionModel:RejectionModel, { rejectWithValue }) => {
        try {
            const response = await RegisterResellerApi.rejectReseller(rejectionModel);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteReseller = createAsyncThunk(
    'DeleteResellerState/DeleteReseller',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterResellerApi.deleteReseller(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deletePartnerStore = createAsyncThunk(
    'DeletePartnerStoreState/DeletePartnerStore',
    async (storeId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterResellerApi.deletePartnerStore(storeId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updatePartner = createAsyncThunk(
    'UpdatePartnerState/UpdatePartner',
    async (model: PartnerRequest, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.updatePartner(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const createPartnerStore = createAsyncThunk(
    'CreatePartnerStoreState/CreatePartnerStore',
    async (model: PartnerStore, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.createPartnerStore(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updatePartnerStore = createAsyncThunk(
    'UpdatePartnerStoreState/UpdatePartnerStore',
    async (model: PartnerStore, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.updatePartnerStore(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getPartnerStoreListByPartner = createAsyncThunk(
    'GetPartnerStoreListByPartnerState/GetPartnerStoreListByPartner',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            return await RegisterResellerApi.getPartnerStoreByPartnerId(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const RegisterResellerSlice = createSlice({
    name: 'RegisterResellerState',
    initialState: {} as RegisterResellerSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        // setRegisterUser: (state, action) => {
        //     state.registerUserModel = action.payload;
        // },
        resetSingle: (state) => {
            state.single = {} as PartnerRegisterModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        },
        setStoreList: (state, action) => {
            state.storeList = action.payload;
        },
        resetStoreAddress: (state) => {
            state.listStoreProvince = [] 
            state.listStoreCity = []
            state.listStoreDistrict = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(registerReseller.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(registerReseller.fulfilled, (state, { payload }) => {
            // state.partnerId = payload.data.id;
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(registerReseller.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(getResellerList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getResellerList.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getResellerList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getResellerListWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getResellerListWithPaging.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getResellerListWithPaging.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSingleResellerData.pending, (state) => {
            state.single = undefined;
            state.isLoading = true;
        });
        builder.addCase(getSingleResellerData.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.isLoading = false;
            state.single = payload.data;
        });
        builder.addCase(getSingleResellerData.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getProvince.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getProvince.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listProvince = payload;
        })
        builder.addCase(getProvince.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getCity.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getCity.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listCity = payload;
        })
        builder.addCase(getCity.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getDistrict.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getDistrict.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listDistrict = payload;
        })
        builder.addCase(getDistrict.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreProvince.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreProvince.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listStoreProvince = payload;
        })
        builder.addCase(getStoreProvince.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreCity.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreCity.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listStoreCity = payload;
        })
        builder.addCase(getStoreCity.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreDistrict.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreDistrict.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listStoreDistrict = payload;
        })
        builder.addCase(getStoreDistrict.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhoto.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhoto.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageKtpPath = payload.data.imagepath;
        })
        builder.addCase(uploadPhoto.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhotoSelfKtp.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhotoSelfKtp.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageSelfKtpPath = payload.data.imagepath;
        })
        builder.addCase(uploadPhotoSelfKtp.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhotoSelf.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhotoSelf.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageSelfPath = payload.data.imagepath;
        })
        builder.addCase(uploadPhotoSelf.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhotoNpwp.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhotoNpwp.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageNpwpPath = payload.data.imagepath;
        })
        builder.addCase(uploadPhotoNpwp.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreTypeEnumList.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreTypeEnumList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.storeTypeEnumList = payload;
        })
        builder.addCase(getStoreTypeEnumList.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getSocialMediaEnumList.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getSocialMediaEnumList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.socialMediaList = payload;
        })
        builder.addCase(getSocialMediaEnumList.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(approveReseller.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(approveReseller.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-approve";
        })
        builder.addCase(approveReseller.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(rejectReseller.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(rejectReseller.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-reject";
        })
        builder.addCase(rejectReseller.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteReseller.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteReseller.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-delete";
        })
        builder.addCase(deleteReseller.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(updatePartner.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updatePartner.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-update";
        })
        builder.addCase(updatePartner.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(updatePartnerStore.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updatePartnerStore.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-update-store";
        })
        builder.addCase(updatePartnerStore.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(createPartnerStore.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(createPartnerStore.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-add-store";
        })
        builder.addCase(createPartnerStore.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(deletePartnerStore.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deletePartnerStore.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-delete-store";
        })
        builder.addCase(deletePartnerStore.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getPartnerStoreListByPartner.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPartnerStoreListByPartner.fulfilled, (state, { payload }) => {
            state.storeList = payload;
            state.isLoading = false;
        });
        builder.addCase(getPartnerStoreListByPartner.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    }
})

export const { setFilter, resetSingle, resetStatus, setStoreList, resetStoreAddress } = RegisterResellerSlice.actions;
export default RegisterResellerSlice.reducer;