import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import logo from "../../../assets/logo.png";
import moment from "moment";
import {
  SalesOrderDetailModel,
  SalesOrderWithDetailModel,
} from "../customer.model";
import { getSingleSalesOrderWithDetail } from "../customer.reducer";
import "../../../assets/styles/style-print.css";

const ShippmentPrint = () => {
  const dispatch = useDispatch();

  const [total, setTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const salesOrderSingleDetail = useSelector(
    (state: RootState) =>
      state.customer.singleSalesOrderWithDetail ??
      ({} as SalesOrderWithDetailModel)
  );

  const countTotal = () => {
    let subTotalPerItem = 0;
    let total = 0;

    salesOrderSingleDetail?.salesOrderDetail?.map((item) => {
      subTotalPerItem += item.price * item.quantity;
      total += subTotalPerItem;

      setTotal(total);
    });
  };

  // const countGrantTotal = () => {
  //   setGrandTotal(
  //     total + SalesOrderWithDetailState?.shipment?.totalShippingCost
  //   );
  // };

  useEffect(() => {
    if (salesOrderSingleDetail?.salesOrderDetail?.length !== 0) {
      countTotal();
      setShippingCost(salesOrderSingleDetail?.shipment?.totalShippingCost);
    }
  }, [salesOrderSingleDetail]);

  useEffect(() => {
    setGrandTotal(total + shippingCost);
  }, [total, shippingCost]);

  useEffect(() => {
    let path = window.location.pathname;
    let id = path.split("/")[3];
    dispatch(getSingleSalesOrderWithDetail(id));

    setTimeout(() => {
      window.print();
    }, 2000);
  }, []);

  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp " + result + ",-";
    }
    return value;
  };

  return (
    <div className="print-container">
      {/* <img src={logo} />
        <br />
        <br /> */}
      <Table size="sm">
        <tr>
          <td>Printed By</td>
          <td>:</td>
          <td>
            {JSON.parse(localStorage.getItem("__emoryuser") || "{}").username}
          </td>
        </tr>
        <tr>
          <td>
            <b>Gudang</b>
          </td>
          <td>:</td>
          <td>
            {salesOrderSingleDetail.salesOrderDetail
              ? salesOrderSingleDetail.salesOrderDetail[0].warehouseName
              : "-"}
          </td>
        </tr>
        <tr>
          <td>Tanggal</td>
          <td>:</td>
          <td>
            {moment(
              new Date(salesOrderSingleDetail?.salesOrder?.orderDate)
            ).format("DD/MM/YYYY HH:mm")}
          </td>
        </tr>
        <tr>
          <td>Nama Pelanggan</td>
          <td>:</td>
          <td>{salesOrderSingleDetail?.salesOrder?.customerName}</td>
        </tr>
        <tr>
          <td>No. Nota</td>
          <td>:</td>
          <td>{salesOrderSingleDetail?.salesOrder?.soNumber}</td>
        </tr>
        <tr>
          <td>Gudang/QC/Packing</td>
          <td>:</td>
          <td>
            {salesOrderSingleDetail?.salesOrder?.adminGudang === ""
              ? "-"
              : salesOrderSingleDetail?.salesOrder?.adminGudang}{" "}
            /{" "}
            {salesOrderSingleDetail?.salesOrder?.adminQC === ""
              ? "-"
              : salesOrderSingleDetail?.salesOrder?.adminQC}{" "}
            /{" "}
            {salesOrderSingleDetail?.salesOrder?.adminPacking === ""
              ? "-"
              : salesOrderSingleDetail?.salesOrder?.adminPacking}
          </td>
        </tr>
        <tr>
          <td>Ekspedisi</td>
          <td>:</td>
          <td> {salesOrderSingleDetail?.shipment?.expeditionName}</td>
        </tr>
      </Table>
      <br />
      <h4>
        <b>Data Belanja</b>
      </h4>

      <Table className="m-0" size="sm" bordered>
        <thead>
          <tr>
            <th>SKU</th>
            <th>ITEM NAME</th>
            <th>QTY</th>
          </tr>
        </thead>
        <tbody>
          {salesOrderSingleDetail?.salesOrderDetail?.map((item: any) => (
            <tr>
              <td>{item?.inventorySKU}</td>
              <td>
                {item?.productName} - {item?.variationName}
              </td>
              <td>{item?.quantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* <Table className="m-0" size="sm" bordered>
          <tr>
            <td>
              <b>Diskon</b>
            </td>
            <td align="right">
              <b>
                {moneyParser(
                  salesOrderSingleDetail?.salesOrder?.discount
                )}
              </b>
            </td>
          </tr>
        </Table> */}

      <br />

      <h4>
        <b>Data Shipping</b>
      </h4>
      <Table className="m-0" size="sm">
        <tr>
          <td>
            <b>No. SO</b>
          </td>
          <td>:</td>
          <td>
            <b>{salesOrderSingleDetail?.salesOrder?.soNumber}</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>Dari</b>
          </td>
          <td>:</td>
          <td>
            <b>
              {salesOrderSingleDetail?.shipment?.senderName === "" ||
              salesOrderSingleDetail?.shipment?.senderName === undefined ||
              salesOrderSingleDetail?.shipment?.senderName === null
                ? "Emory Petite Style"
                : salesOrderSingleDetail?.shipment?.senderName}
            </b>
          </td>
        </tr>
        <tr>
          <td>Kepada</td>
          <td>:</td>
          <td>{salesOrderSingleDetail?.shipment?.recipientName}</td>
        </tr>
        <tr>
          <td>No. Telp Penerima</td>
          <td>:</td>
          <td>{salesOrderSingleDetail?.shipment?.recipientPhone}</td>
        </tr>
        <tr>
          <td>Alamat Penerima</td>
          <td>:</td>
          <td>
            {" "}
            <tr> {salesOrderSingleDetail?.shipment?.recipientAddress}, </tr>
            <tr>{salesOrderSingleDetail?.shipment?.recipientDistrictName}, </tr>
            <tr>{salesOrderSingleDetail?.shipment?.recipientCityName}, </tr>
            <tr>{salesOrderSingleDetail?.shipment?.recipientProvinceName}</tr>
          </td>
        </tr>
        <tr>
          <td>Kode Pos</td>
          <td>:</td>
          <td>{salesOrderSingleDetail?.shipment?.recipientPostalCode}</td>
        </tr>
        <tr>
          <td>Third Party</td>
          <td>:</td>
          <td>{salesOrderSingleDetail?.salesOrder?.thirdPartyName}</td>
        </tr>
        <tr>
          <td>Ekspedisi</td>
          <td>:</td>
          <td>{salesOrderSingleDetail?.shipment?.expeditionName}</td>
        </tr>
        <tr>
          <td>
            <b>Nomor Resi</b>
          </td>
          <td>:</td>
          <td>
            <b>{salesOrderSingleDetail?.shipment?.resiNumber}</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>Ongkos Kirim</b>
          </td>
          <td>:</td>
          <td>
            <b>
              {moneyParser(salesOrderSingleDetail?.shipment?.totalShippingCost)}
            </b>
          </td>
        </tr>
      </Table>
    </div>
  );
};

export default ShippmentPrint;
