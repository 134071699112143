import { useEffect, useRef, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import "../product.css";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setPublishTempForm } from "../product.reducer";
import axios from "axios";
import { endpoint } from "../../../../constants";
import { useParams } from "react-router";
import moment from "moment";
import { RootState } from "../../../../app/store";
import {isValidDate, TZ_OFFSET} from "../../../../helper/dateFormat";
import {ProductModel} from "../product.model";
import CircleLoader from "../../../../components/Loading/CircleLoader";

const PublishForm = ({ isEdit = false, id = "", ...props }: PublishProps) => {
  const dispatch = useDispatch();

  // let { id } = useParams<{ id: string }>();

  let keepRef = useRef<any>(null);

  const [status, setStatus] = useState(0);
  const [isStatusEdit, setIsStatusEdit] = useState(false);
  const [visibility, setVisibility] = useState(0);
  const [isVisibilityEdit, setIsVisibilityEdit] = useState(false);
  const [publishDateCustomer, setPublishDateCustomer] = useState(new Date());
  const [resellerDate, setResellerDate] = useState(new Date());
  const [stockistDate, setStockistDate] = useState(new Date());
  const [isActive, setIsActive] = useState(true);
  const [isPublishEndCustomer, setIsPublishEndCustomer] = useState(false);
  const [isPublishReseller, setIsPublishReseller] = useState(false);
  const [isPublishStockist, setIsPublishStockist] = useState(false);
  const [productId, setProductId] = useState("");

  const [isPublishEdit, setIsPublishEdit] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const isSingleProductLoading = useSelector(
        (state: RootState) => state.product.isSingleProductLoading
  );

  useEffect(() => {
    if (isEdit) {
      axios
        .get<any>(
          endpoint + "/api/ProductPublish/GetSingleByProduct?productId=" + id
        )
        .then((res) => {
          // setStatus(res.data.data.status);
          // setVisibility(res.data.data.visibility);
            console.log(res.data.data.stockistDate);
          setPublishDateCustomer(res.data.data.endCustomerDate);
          setResellerDate(res.data.data.resellerDate);
          setStockistDate(res.data.data.stockistDate);
          setIsPublishEndCustomer(
            res.data.data.isPublishImmediatelyEndCustomer
          );
          setIsPublishReseller(res.data.data.isPublishImmediatelyReseller);
          setIsPublishStockist(res.data.data.isPublishImmediatelyStockist);
          setProductId(res.data.data.productId);
          setIsActive(res.data.data.isActive);
          setStatus(res.data.data.isActive ? 1 : 0);

          dispatch(
            setPublishTempForm({
              isPublishImmediatelyEndCustomer:
                res.data.data.isPublishImmediatelyEndCustomer,
              isPublishImmediatelyReseller:
                res.data.data.isPublishImmediatelyReseller,
              isPublishImmediatelyStockist:
                res.data.data.isPublishImmediatelyStockist,
              productId: id,
              endCustomerDate: res.data.data.endCustomerDate,
              resellerDate: res.data.data.resellerDate,
              stockistDate: res.data.data.stockistDate,
              isActive: res.data.data.isActive,
            })
          );
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const toggleStatus = () => {
    setIsStatusEdit(!isStatusEdit);

    // onSubmitForm();
  };

  const toggleVisibility = () => {
    setIsVisibilityEdit(!isVisibilityEdit);
  };

  const togglePublish = () => {
    setIsPublishEdit(!isPublishEdit);
  };

  const onPublishDateCustomerChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setPublishDateCustomer(date);
    }
  };

  const onResellerDateChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setResellerDate(date);
    }
  };

  const onStockistDateChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setStockistDate(date);
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onClearDataPublish = () => {
    // setPublishDateCustomer(new Date("0001-01-01 00:00:00.0000000"));
    setPublishDateCustomer(new Date("0001-01-01"));
    setResellerDate(new Date("0001-01-01"));
    setStockistDate(new Date("0001-01-01"));

    setIsPublishEndCustomer(false);
    setIsPublishReseller(false);
    setIsPublishStockist(false);

    dispatch(
      setPublishTempForm({
        isPublishImmediatelyEndCustomer: false,
        isPublishImmediatelyReseller: false,
        isPublishImmediatelyStockist: false,
        productId: id,
        endCustomerDate: new Date("0001-01-01"),
        resellerDate: new Date("0001-01-01"),
        stockistDate: new Date("0001-01-01"),
        isActive: isActive,
      })
    );
  };

  const onSubmitForm = (isActiveInput : boolean) => {
    console.log(isPublishReseller);
    console.log(isPublishStockist);
    console.log(isPublishEndCustomer);
    dispatch(
      setPublishTempForm({
        isPublishImmediatelyEndCustomer: isPublishEndCustomer,
        isPublishImmediatelyReseller: isPublishReseller,
        isPublishImmediatelyStockist: isPublishStockist,
        productId: id,
        endCustomerDate: publishDateCustomer,
        resellerDate: resellerDate,
        stockistDate: stockistDate,
        isActive: isActiveInput,
      })
    );
  };

  return (
    <div>
      <CircleLoader loading={isSingleProductLoading}>
      <Card>
        <Card.Body>
          <Form.Group as={Row}>
            <Form.Label
              style={{
                fontWeight: 610,
                fontSize: 17,
                paddingTop: 0,
                paddingBottom: 0,
              }}
              column
              sm={4}
            >
              Publish
            </Form.Label>
          </Form.Group>
          <hr />
          <Form>
            <section>
              <Form.Label className="card-subtitle">
                Status:
                <span
                  style={{
                    fontWeight: "bolder",
                    color: "black",
                    paddingLeft: 5,
                  }}
                >
                  {isActive === true ? "Active" : "Inactive"}
                </span>
              </Form.Label>
              <span
                style={{
                  fontWeight: "bolder",
                  color: "#00A8CC",
                  cursor: "pointer",
                }}
                className="float-right"
                onClick={toggleStatus}
              >
                edit
              </span>
              {isStatusEdit ? (
                <>
                  <div style={{ paddingTop: 15 }}>
                    <Form.Check
                      label="Active"
                      type="radio"
                      style={{ paddingBottom: 10 }}
                      checked={isActive === true}
                      value={0}
                      onChange={() => {
                        setIsActive(true);
                        onSubmitForm(true);
                      }}
                    />

                    <Form.Check
                      label="Inactive"
                      type="radio"
                      checked={isActive === false}
                      value={1}
                      onChange={() => {
                        setIsActive(false);
                        onSubmitForm(false);
                      }}
                    />
                  </div>

                  {/* <div style={{paddingTop:15}}>
                                <Button onClick={toggleStatus} variant="info" style={{width:50, height:27, textAlign:'center', padding: 0}} type="submit">ok</Button>{'  '}
                            </div>  */}
                </>
              ) : (
                <></>
              )}
            </section>
            <hr />
            <section>
              {/* <Form.Label className="card-subtitle">
                Visibility:
                <span style={{ fontWeight: "bolder", color: "black", paddingLeft: 5 }}>
                  {visibility === 0 && "Public"}
                  {visibility === 1 && "Password Protected"}
                  {visibility === 2 && "Private"}
                </span>
              </Form.Label>
              <span
                style={{ fontWeight: "bolder", color: "#00A8CC", cursor: "pointer" }}
                className="float-right"
                onClick={toggleVisibility}
              >
                edit
              </span> */}
              {isVisibilityEdit ? (
                <>
                  <div style={{ paddingTop: 15 }}>
                    <Form.Check
                      label="Public"
                      type="radio"
                      style={{ paddingBottom: 10 }}
                      checked={visibility === 0}
                      value={0}
                      onChange={() => setVisibility(0)}
                    />

                    <Form.Check
                      label="Password Protected"
                      type="radio"
                      style={{ paddingBottom: 10 }}
                      checked={visibility === 1}
                      value={1}
                      onChange={() => setVisibility(1)}
                    />

                    <Form.Check
                      label="Private"
                      type="radio"
                      checked={visibility === 2}
                      value={2}
                      onChange={() => setVisibility(2)}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </section>
            {/* <hr /> */}
            <section>
              <Form.Label className="card-subtitle">
                Publish:{" "}
                <span style={{ fontWeight: "bolder", color: "black" }}>
                  Immediately
                </span>
              </Form.Label>
              <span
                style={{
                  fontWeight: "bolder",
                  color: "#00A8CC",
                  cursor: "pointer",
                }}
                onClick={togglePublish}
                className="float-right"
              >
                edit
              </span>
              {isPublishEdit ? (
                <>
                  <div style={{ paddingTop: 15 }}>
                    <Form.Group
                      as={Row}
                      // style={{textAlign: "center", verticalAlign: "middle"}}
                    >
                      <Col sm={6} style={{ verticalAlign: "middle" }}>
                        <Form.Check
                          className="radio-button"
                          label="Reseller"
                          name="isFreeShipment"
                          style={{ margin: "auto" }}
                          checked={isPublishReseller}
                          onChange={(e) => {
                            setIsPublishReseller(e.target.checked);
                            dispatch(
                              setPublishTempForm({
                                isPublishImmediatelyEndCustomer:
                                  isPublishEndCustomer,
                                isPublishImmediatelyReseller: e.target.checked,
                                isPublishImmediatelyStockist: isPublishStockist,
                                productId: id,
                                endCustomerDate: publishDateCustomer,
                                resellerDate: resellerDate,
                                stockistDate: stockistDate,
                                isActive: isActive,
                              })
                            );
                          }}
                        />
                      </Col>

                      <Col sm={6}>
                        <Form.Control
                          type="datetime-local"
                          value={moment(resellerDate).format("yyyy-MM-DDTHH:mm")}
                          onChange={(e) => {
                            setResellerDate(new Date(e.target.value));
                            dispatch(
                              setPublishTempForm({
                                isPublishImmediatelyEndCustomer:
                                  isPublishEndCustomer,
                                isPublishImmediatelyReseller: isPublishReseller,
                                isPublishImmediatelyStockist: isPublishStockist,
                                productId: id,
                                endCustomerDate: publishDateCustomer,
                                resellerDate: new Date(new Date(e.target.value).getTime() - TZ_OFFSET),
                                stockistDate: stockistDate,
                                isActive: isActive,
                              })
                            );
                            // onSubmitForm()
                          }}
                        />
                        {/* <DatePicker
                          style={{ width: "100%" }}
                          showTime={{ format: "HH:mm" }}
                          format="YYYY-MM-DD HH:mm"
                          onChange={onResellerDateChange}
                        /> */}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col sm={6} style={{ verticalAlign: "middle" }}>
                        <Form.Check
                          className="radio-button"
                          label="Stockist"
                          name="isFreeShipment"
                          style={{ margin: "auto" }}
                          checked={isPublishStockist}
                          onChange={(e) => {
                            setIsPublishStockist(e.target.checked);
                            dispatch(
                              setPublishTempForm({
                                isPublishImmediatelyEndCustomer:
                                  isPublishEndCustomer,
                                isPublishImmediatelyReseller: isPublishReseller,
                                isPublishImmediatelyStockist: e.target.checked,
                                productId: id,
                                endCustomerDate: publishDateCustomer,
                                resellerDate: resellerDate,
                                stockistDate: stockistDate,
                                isActive: isActive,
                              })
                            );
                            // onSubmitForm()
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          type="datetime-local"
                          value={moment(stockistDate).format("yyyy-MM-DDTHH:mm")}
                          onChange={(e) => {
                            setStockistDate(new Date(e.target.value));
                            dispatch(
                              setPublishTempForm({
                                isPublishImmediatelyEndCustomer:
                                  isPublishEndCustomer,
                                isPublishImmediatelyReseller: isPublishReseller,
                                isPublishImmediatelyStockist: isPublishStockist,
                                productId: id,
                                endCustomerDate: publishDateCustomer,
                                resellerDate: resellerDate,
                                stockistDate: new Date(new Date(e.target.value).getTime() - TZ_OFFSET),
                                isActive: isActive,
                              })
                            );
                            // onSubmitForm()
                          }}
                        />
                        {/* <DatePicker
                          defaultValue={moment("2021-11-05", stockistDate)}
                          style={{ width: "100%" }}
                          showTime={{ format: "HH:mm" }}
                          format="YYYY-MM-DD HH:mm"
                          onChange={onStockistDateChange}
                        /> */}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col sm={6} style={{ verticalAlign: "middle" }}>
                        <Form.Check
                          className="radio-button"
                          label="End Customer"
                          name="isFreeShipment"
                          style={{ margin: "auto" }}
                          checked={isPublishEndCustomer}
                          onChange={(e) => {
                            setIsPublishEndCustomer(e.target.checked);
                            dispatch(
                              setPublishTempForm({
                                isPublishImmediatelyEndCustomer:
                                  e.target.checked,
                                isPublishImmediatelyReseller: isPublishReseller,
                                isPublishImmediatelyStockist: isPublishStockist,
                                productId: id,
                                endCustomerDate: publishDateCustomer,
                                resellerDate: resellerDate,
                                stockistDate: stockistDate,
                                isActive: isActive,
                              })
                            );
                            // onSubmitForm()
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          type="datetime-local"
                          value={moment(publishDateCustomer).format("yyyy-MM-DDTHH:mm")}
                          // .toISOString()
                          // .substr(0, 10)
                          // }
                          onChange={(e) => {
                            setPublishDateCustomer(new Date(e.target.value));
                            dispatch(
                              setPublishTempForm({
                                isPublishImmediatelyEndCustomer:
                                  isPublishEndCustomer,
                                isPublishImmediatelyReseller: isPublishReseller,
                                isPublishImmediatelyStockist: isPublishStockist,
                                productId: id,
                                endCustomerDate: new Date(new Date(e.target.value).getTime() - TZ_OFFSET),
                                resellerDate: resellerDate,
                                stockistDate: stockistDate,
                                isActive: isActive,
                              })
                            );
                            // onSubmitForm()
                          }}
                        />
                        {/* <DatePicker
                          style={{ width: "100%" }}
                          showTime={{ format: "HH:mm" }}
                          format="YYYY-MM-DD HH:mm"
                          onChange={onPublishDateCustomerChange}
                        /> */}
                      </Col>
                    </Form.Group>
                  </div>

                  <Button
                    onClick={onClearDataPublish}
                    variant="info"
                    style={{
                      width: 180,
                      height: 40,
                      textAlign: "center",
                      padding: 0,
                    }}
                  >
                    Clear Data Publish
                  </Button>
                </>
              ) : (
                <></>
              )}
            </section>
            <hr />
          </Form>
          {/* <div className="btn-footer-group">
            <Button
              onClick={onSubmitForm}
              variant="info"
              style={{ width: 50, height: 27, textAlign: "center", padding: 0 }}
            >
              Save
            </Button>
            {"  "}
          </div> */}
        </Card.Body>
      </Card>
      </CircleLoader>
    </div>
  );
};

export interface PublishProps {
  isEdit: Boolean;
  id: string;
}

export default PublishForm;
