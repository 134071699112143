import axios from 'axios'
import {endpoint} from '../../../constants'
import {PartnerByTypeRequest, StockistDataPesananRequest} from "./stockistOrderDataReport.models";

export default class StockistOrderDataReportApi {
    static getStockistDataPesanan(args: StockistDataPesananRequest) {
        return axios
            .post<any>(endpoint + "/api/Report/ReportDataPesananStockist", args)
            .then((res) => res.data.data);
    }

    static getPartnerByType(args: PartnerByTypeRequest) {
        return axios
            .get<any>(endpoint + "/api/Partner/GetListByType", {
                params: args
            })
            .then((res) => res.data.data);
    }
}
