import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { postAssetProductReport } from "../AssetReport.api"
import { unwrapResult } from '@reduxjs/toolkit';

export default function PrintAssetProductReport() {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        filterProduct: new URLSearchParams(window.location.search).get('filterProduct'),
        skip: new URLSearchParams(window.location.search).get('skip'),
        take: new URLSearchParams(window.location.search).get('take')
    })

    const [list, setList] = useState<any>([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        const data = {
            "filterProduct": values.filterProduct,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(postAssetProductReport(data))
            .then(unwrapResult)
            .then((res: any) => {
                setList(res.data.list)
                setTimeout(() => {
                    window.print()
                }, 1000)
            })
            .catch((err: any) => console.log(err))
    }

    const moneyParser = (value: any) => {
        let valueString = value.toString()
        let valueList = valueString.split('')
        let resultList: any = []
        valueList.reverse().map((c: any, i: number) => {
            resultList.push(c)
            if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
                resultList.push('.')
            }
        })
        let result = resultList.reverse().join('')
        return "Rp" + result + ",00"
    }

    return (
        <table className="table">
            <thead className="">
                <tr>
                    <th scope="col">No</th>
                    <th scope="col">Nama Produk</th>
                    <th scope="col">Jumlah QTY</th>
                    <th scope="col">Asset / Nominal Reseller</th>
                    <th scope="col">Asset / Nominal Stockist</th>
                    <th scope="col">Asset / Nominal End Customer</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map((res: any, i: number) => (
                        <>
                            <tr key={i} style={{ fontWeight: "bold" }}>
                                <td>{i + 1}</td>
                                <td>{res.productName}</td>
                                <td>{res.totalQty}</td>
                                <td>{moneyParser(res.totalAssetReseller)}</td>
                                <td>{moneyParser(res.totalAssetStockist)}</td>
                                <td>{moneyParser(res.totalAssetEndCustomer)}</td>
                            </tr>
                            {res.variant.map((v: any, i: number) => (
                                <tr key={i}>
                                    <td></td>
                                    <td>- {v.variantName}</td>
                                    <td>{v.qty}</td>
                                    <td>{moneyParser(v.totalReseller)}</td>
                                    <td>{moneyParser(v.totalStockist)}</td>
                                    <td>{moneyParser(v.totalEndCustomer)}</td>
                                </tr>
                            ))}
                        </>
                    ))
                }

            </tbody>
        </table>
    )
}