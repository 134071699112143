import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";

import { createData, getSingleItem, updateData, updateImage } from "../productCategory.reducer";
import { CreateProductCategoryModel, ProductCategoryModel } from "../productCategory.model";
import ProductCategoryForm from "../components/ProductCategoryForm";

const ProductCategoryPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.productCategory.single ?? ({} as ProductCategoryModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
     if (id !== undefined) {
       dispatch(getSingleItem(id));
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

  const onSubmit = (
    code: string,
    name: string,
    slug: string,
    description: string,
    parent: string,
    image: string,
    fileFormat: string,
    imagePath: string,
    isActive: boolean
    ) => {
    if (isEdit) {
      if (image === ""){
        dispatch(
          updateData({
            id, code, name, slug, description, parent, image: imagePath, isActive
          } as ProductCategoryModel)
          );
      } else {
        dispatch(
          updateImage({
            ProductCategoryData: {
              id,
              code, 
              name, 
              slug, 
              description, 
              parent, 
              image,
              isActive
            },
            UploadImageModel: {
              file_name: "file_name." + fileFormat,
              base64url: image.split(",")[1],
              imagepath: "productCategory",
              location: "productCategory"
            }
            //code, name, slug, description, parent, image
          } as CreateProductCategoryModel))
      }
    } else {
      dispatch(
        createData({
          ProductCategoryData: {
            code, 
            name, 
            slug, 
            description, 
            parent, 
            image
          },
          UploadImageModel: {
            file_name: "file_name." + fileFormat,
            base64url: image.split(",")[1],
            imagepath: "productCategory",
            location: "productCategory"
          }
          //code, name, slug, description, parent, image
        } as CreateProductCategoryModel)
      );
    }
  };
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <ProductCategoryForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
               />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductCategoryPage;
