import React from 'react';
import { Form, Col, Jumbotron, Row } from 'react-bootstrap';
import './style.css';
import mail from './../../../assets/mail2.png';
import loginPanel from './../../../assets/login-panel.png';
import { Link } from 'react-router-dom';

const EmailConfirmationForm = ({ isLoading = false, ...props }) => {

  return (
    <Form>
      <Row>
        <Col className="background-login">
          <div className="center-image" >
            <img src={loginPanel} alt="image" style={{ width:"60vh" }}></img>   
          </div> 
        </Col>
        <Col className="center" style={{textAlign: 'center', marginTop:'10%'}}>
          <div>
            
            <img src={mail} alt="Logo" className="mail-icon" style={{marginBottom:30}} />

            <Form.Group>
              <Form.Label className="title-text">Check Your Email</Form.Label>
            </Form.Group> 

            <Form.Group>
              <Form.Label className="subtitle-text">We have sent a password recover <br/> instructions to your email.</Form.Label>
            </Form.Group>  

            <Form.Group>
              <Link to={"/login"}>
                <a href="#">Skip, I’ll confirm later</a>
              </Link>
            </Form.Group> 
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default EmailConfirmationForm;
