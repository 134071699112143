import React, { useEffect, useState, useRef } from 'react'
import '../autoCancelKeep.scss'
import { Container, Form, Button } from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import { getAutoCancelKeepList } from '../autoCancelKeep.api';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { DatePicker, Space } from 'antd';
import {RootState} from "../../../../app/store";
import {PartnerTypeEnum} from "../models";
const { RangePicker } = DatePicker;

export default function AutoCancelKeep() {
    const dispatch = useDispatch()
    const { list, total } = useSelector((state: RootState) => state.autoCancelKeep)
    const tableRef = useRef(null);

    const initialValues = {
        "partnerType": PartnerTypeEnum.All,
        "productName": "",
        "partnerName": "",
        "productSKU": "",
        "startKeepDate": '',
        "endKeepDate": '',
        "startAutocancelDate": '',
        "endAutocancelDate": '',
        "skip": 0,
        "take": '10',
        page: 1
    }

    const [values, setValues] = useState({
        "partnerType": PartnerTypeEnum.All,
        "productName": "",
        "partnerName": "",
        "productSKU": "",
        "startKeepDate": '',
        "endKeepDate": '',
        "startAutocancelDate": '',
        "endAutocancelDate": '',
        "skip": 0,
        "take": '10',
        page: 1
    })

    const getListPesanan = () => {
        const data = {
            "partnerType": values.partnerType === PartnerTypeEnum.All ? null : values.partnerType,
            "productName": values.productName,
            "partnerName": values.partnerName,
            "productSKU": values.productSKU,
            "startKeepDate":  values.startKeepDate === "" ? "" : new Date(values.startKeepDate).toISOString(),
            "endKeepDate": values.endKeepDate === "" ? "" : new Date(values.endKeepDate).toISOString(),
            "startAutocancelDate":  values.startAutocancelDate === "" ? "" : new Date(values.startAutocancelDate).toISOString(),
            "endAutocancelDate":  values.endAutocancelDate === "" ? "" : new Date(values.endAutocancelDate).toISOString(),
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(getAutoCancelKeepList(data))
    }

    useEffect(() => {
        getListPesanan()
    }, [values.take, values.skip, values.page])

    const handlePagination = (page: any) => {
        setValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        })
    }

    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    const handleRadioChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: parseInt(e.target.value),
        })
    }
    const handlePerPage = (e: any) => {
        setValues({
            ...values,
            take: e.target.value,
            skip: 0,
            page: 1
        })
    }

    const handleRangeKeepPicker = (event: any, picker: any) => {
        setValues({
            ...values,
            startKeepDate: picker[0],
            endKeepDate: picker[1],
        })
    }

    const handleRangeCancelPicker = (event: any, picker: any) => {
        setValues({
            ...values,
            startAutocancelDate: picker[0],
            endAutocancelDate: picker[1],
        })
    }

    const handleSubmit = (e: any) => {
        const data = {
            "partnerType": values.partnerType === PartnerTypeEnum.All ? null : values.partnerType,
            "productName": values.productName,
            "partnerName": values.partnerName,
            "productSKU": values.productSKU,
            "startKeepDate":  values.startKeepDate === "" ? "" : new Date(values.startKeepDate).toISOString(),
            "endKeepDate": values.endKeepDate === "" ? "" : new Date(values.endKeepDate).toISOString(),
            "startAutocancelDate":  values.startAutocancelDate === "" ? "" : new Date(values.startAutocancelDate).toISOString(),
            "endAutocancelDate":  values.endAutocancelDate === "" ? "" : new Date(values.endAutocancelDate).toISOString(),
            "skip": 0,
            "take": parseInt(values.take)
        }
        setValues({
            ...values,
            page: 1,
        })

        dispatch<any>(getAutoCancelKeepList(data))
        e.preventDefault()
    }

    const [isReset, setisReset] = useState(false)
    useEffect(() => {
        getListPesanan()
    }, [isReset])

    const handleReset = () => {
        setValues(initialValues)
        setisReset(!isReset)
    }

    return (
        <Container className="content-body orderDataReport" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                <span style={{fontSize: '24px'}}>
                    Laporan Pembatalan Otomatis Keep Barang
                </span>
            </div>
            <div className="box">
                <div className="head w-100">
                    <form onSubmit={handleSubmit}>
                        <div className="row w-100">
                            <div className="col-md-5">
                                <div className="wrap-inp align-items-start">
                                    <label htmlFor="title">Type Partner</label>
                                    <div className='wrap-radios'>
                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='partner-all'
                                            label='Semua Partner'
                                            name='partnerType'
                                            checked={values.partnerType === PartnerTypeEnum.All}
                                            value={PartnerTypeEnum.All}
                                            onClick={handleRadioChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='partner-customer'
                                            label='Customer'
                                            name='partnerType'
                                            checked={values.partnerType === PartnerTypeEnum.Customer}
                                            value={PartnerTypeEnum.Customer}
                                            onClick={handleRadioChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='partner-stockist'
                                            label='Stockist'
                                            name='partnerType'
                                            checked={values.partnerType === PartnerTypeEnum.Stockist}
                                            value={PartnerTypeEnum.Stockist}
                                            onClick={handleRadioChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='partner-reseller'
                                            label='Reseller'
                                            name='partnerType'
                                            checked={values.partnerType === PartnerTypeEnum.Reseller}
                                            value={PartnerTypeEnum.Reseller}
                                            onClick={handleRadioChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='partner-admin'
                                            label='Admin'
                                            name='partnerType'
                                            checked={values.partnerType === PartnerTypeEnum.Admin}
                                            value={PartnerTypeEnum.Admin}
                                            onClick={handleRadioChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='partner-admin-stockist'
                                            label='AdminStockist'
                                            name='partnerType'
                                            checked={values.partnerType === PartnerTypeEnum.AdminStockist}
                                            value={PartnerTypeEnum.AdminStockist}
                                            onClick={handleRadioChange}
                                        />

                                    </div>
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Tanggal Keep</label>
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            className='custext'
                                            onChange={handleRangeKeepPicker}
                                            value={values.startKeepDate !== "" && values.endKeepDate !== "" ? [moment(values.startKeepDate, 'YYYY-MM-DD'), moment(values.endKeepDate, 'YYYY-MM-DD')] : undefined}
                                            format="YYYY-MM-DD"
                                        />
                                    </Space>
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Tanggal Auto Cancel</label>
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            className='custext'
                                            onChange={handleRangeCancelPicker}
                                            value={values.startAutocancelDate !== "" && values.endAutocancelDate !== "" ? [moment(values.startAutocancelDate, 'YYYY-MM-DD'), moment(values.endAutocancelDate, 'YYYY-MM-DD')] : undefined}
                                            format="YYYY-MM-DD"
                                        />
                                    </Space>
                                </div>
                            </div>

                            <div className="col-md-5 mr-auto">
                                <div className="wrap-inp">
                                    <label htmlFor="title">Nama Produk</label>
                                    <input className='custext' type="text" placeholder='Nama Produk' value={values.productName} name="productName" onChange={handleChange} id="" />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Nama Partner</label>
                                    <input className='custext' type="text" placeholder='Nama Partner' value={values.partnerName} name="partnerName" onChange={handleChange} id="" />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">SKU</label>
                                    <input className='custext' type="text" placeholder='SKU' value={values.productSKU} name="productSKU" onChange={handleChange} id="" />
                                </div>
                            </div>
                            <div className="wrapbutfil col-md-7">
                                <Button className='addor' onClick={handleReset}>Reset</Button>
                                <Button className='addorrev' type='submit'>Filter</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="con" >
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing {list.length} of {total} results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span>
                            <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage} >
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>

                    <div style={{ overflowX: 'scroll' }} >
                        <table id='table' className='table'>

                            {/* <thead style={{ display: "none" }}>
                                    <tr className='mb-3' >
                                        <th colSpan={100} >
                                            <h4 >Laporan Data Pesanan</h4>
                                        </th>
                                    </tr>
                                </thead> */}

                            <thead className="">
                                <tr>
                                    {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                    <th scope="col">No</th>
                                    <th scope="col">Tanggal Auto Cancel</th>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Nama produk</th>
                                    <th scope="col">Nama Partner</th>
                                    <th scope="col">Type Partner</th>
                                    <th scope="col">Tanggal Keep</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((res: any, i: number) => (
                                        <tr key={i}>
                                            {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                            <td>
                                                {
                                                    values.skip === 0 ?
                                                        i + 1
                                                        :
                                                        i + 1 + (values.page - 1) * parseInt(values.take)
                                                }
                                            </td>
                                            <td>{moment(res?.autoCancelDate).format('DD/MM/YYYY')}</td>
                                            <td>{res?.productCode}</td>
                                            <td>{res?.productName}</td>
                                            <td>{res?.partnerName}</td>
                                            <td>
                                                {res?.type === PartnerTypeEnum.Customer && <span>Customer</span> }
                                                {res?.type === PartnerTypeEnum.Stockist && <span>Stockist</span> }
                                                {res?.type === PartnerTypeEnum.Reseller && <span>Reseller</span> }
                                                {res?.type === PartnerTypeEnum.Admin && <span>Admin</span> }
                                                {res?.type === PartnerTypeEnum.AdminStockist && <span>AdminStockist</span> }
                                            </td>
                                            <td>{moment(res?.keepDate).format('DD/MM/YYYY')}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>

                        <div style={{ display: "none" }}>
                            <table id='table' className='table' ref={tableRef}>
                                <thead>
                                    <tr className='mb-3' >
                                        <th colSpan={100} >
                                            <h4 >Laporan Data Pesanan</h4>
                                        </th>
                                    </tr>
                                </thead>

                                <thead className="">
                                    <tr>
                                        {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                        <th scope="col">No</th>
                                        <th scope="col">Tanggal pesanan</th>
                                        <th scope="col">Pembeli</th>
                                        <th scope="col">Type Customer</th>
                                        <th scope="col">SO Number</th>
                                        <th scope="col">Kode produk</th>
                                        <th scope="col">Produk</th>
                                        <th scope="col">Varian</th>
                                        <th scope="col">QTY</th>
                                        <th scope="col">Subtotal</th>
                                        <th scope="col">Status Pesanan</th>
                                        <th scope="col">Status Bayar</th>
                                        <th scope="col">Catatan SO</th>
                                        <th scope="col">nama pengirim</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((res: any, i: number) => (
                                            <tr key={i}>
                                                {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                                <td>
                                                    {
                                                        values.skip === 0 ?
                                                            i + 1
                                                            :
                                                            i + 1 + (values.page - 1) * parseInt(values.take)
                                                    }
                                                </td>
                                                <td>{moment(res.orderDate).format('DD/MM/YYYY')}</td>
                                                <td>{res?.customerName}</td>
                                                <td>
                                                    {
                                                        res?.type === 1 ? 'Stockist' :
                                                            res?.type === 2 ? 'Reseller' :
                                                                res?.type === 3 ? 'Admin' :
                                                                    res?.type === 4 ? 'Admin Stockist' :
                                                                        res?.type === 0 ? 'End Customer' : null
                                                    }
                                                </td>
                                                <td>{res?.soNumber}</td>
                                                <td>{res?.productCode}</td>
                                                <td>{res?.productName}</td>
                                                <td>{res?.variationName}</td>
                                                <td>{res?.quantity}</td>
                                                <td>Rp{res?.subTotal}</td>
                                                <td>{res?.statusOrder === 0 ? 'Keep' : 'Dropship'}</td>
                                                <td>{res?.paymentStatus === 0 ? 'Belum Lunas' : 'Lunas'}</td>
                                                <td>{res?.note}</td>
                                                <td>{res?.senderName}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="d-flex justify-content-end">
                        {/* <Pagination current={values.page} onChange={handlePagination} total={total} /> */}
                        <Pagination
                            activePage={values.page}
                            itemClass='item'
                            itemsCountPerPage={parseInt(values.take)}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePagination}
                            prevPageText='⟨ PREVIOUS'
                            itemClassPrev='prev'
                            itemClassNext='next'
                            nextPageText='NEXT ⟩'
                            hideFirstLastPages
                        />
                    </div>
                </div>
            </div>
        </Container>
    )
}
