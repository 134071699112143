export interface KeepBelumLunasModel {
    id: string;
    salesOrderId: string;
    partnerId: string;
    orderId: string;
    salesOrderDetailId: string;
    customerName: string;
    variationId: string;
    variationName: string;
    productId: string;
    productName: string;
    productCode: string;
    quantity: number;
    orderDate: string;
    subTotal: number;
    grandTotal: number;
    qtyTotal: number;
    totalTax: number;
    adminFee: number;
    note: string;
    discountId: string;
    soNumber: string;
    statusOrder: number;
    productType: number;
    discount: number;
    price: number;
    contractStockistApproval: number;
    partnerType: number;
    paymentStatus: number;
    shippingStatus: number;
    inventorySKU: string;
    limitApprovalStatus: number;
}

export enum PartnerTypeEnum
{
    Customer,
    Stockist,
    Reseller,
    Admin,
    AdminStockist,
    None
}

export interface KeepBarangModel {
    id: string;
    productCode: string | null;
    productName: string;
    variantName: string;
    cartId: string;
    variantId: string;
    productId: string;
    note: string | null;
    qty: number;
    partnerId: string;
    partnerName: string;
    partnerType: PartnerTypeEnum;
    createdDate: string;
}

export interface KeepBarangRequest {
    partnerType: PartnerTypeEnum | null;
    partnerName: string;
    startDate: string;
    endDate: string;
    productSKU: string;
    skip: number;
    take: number;
}

