import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../app/store";
import {
  getCityByProvince,
  getDistrictByCity,
  getProvince,
} from "../../../../global/global.reducer";
import { AddressModel } from "../address.model";
import {
  getEmoryAddress,
  resetSingle,
  resetStatus,
  updateEmoryAddress,
} from "../address.reducer";
import AlertMessage from "../../../../components/alertMessage";

const SettingAddressForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const EmoryAddressState = useSelector(
    (state: RootState) =>
      state.settingAddress.singleData ?? ({} as AddressModel)
  );

  const EmoryAddressStatusState = useSelector(
    (state: RootState) => state.settingAddress.status ?? ""
  );

  const provinceState = useSelector(
    (state: RootState) => state.registerReseller.listProvince ?? []
  );

  const cityState = useSelector(
    (state: RootState) => state.global.listCityByProvince ?? []
  );

  const districtState = useSelector(
    (state: RootState) => state.global.listDistrictByCity ?? []
  );

  const [addressId, setAddressId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [sender, setSender] = useState("");
  const [description, setDescription] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [cityId, setCityId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [countryId, setCountryId] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const onAddressChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFullAddress(e.target.value);
  };

  const onPostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostalCode(e.target.value);
  };

  const onProvinceChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setProvinceId(e.target.value);
    dispatch(getCityByProvince(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCityId(e.target.value);
    dispatch(getDistrictByCity(e.target.value));
  };

  const onDistrictChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDistrictId(e.target.value);
  };

  const onAlertClose = () => {
    setShowAlert(false);
    dispatch(resetStatus());
  };

  const onCancelClick = () => {
    history.goBack();
  };

  const onSaveClick = () => {
    dispatch(
      updateEmoryAddress({
        id: addressId,
        name: name,
        phone: phone,
        postalCode: postalCode,
        fullAddress: fullAddress,
        sender: sender,
        description: description,
        isDefault: isDefault,
        partnerId: partnerId,
        provinceId: provinceId,
        cityId: cityId,
        districtId: districtId,
        countryId: countryId,
      })
    );
  };

  useEffect(() => {
    dispatch(getProvince());
    dispatch(getEmoryAddress());
  }, []);

  useEffect(() => {
    if (EmoryAddressState.id !== "") {
      setAddressId(EmoryAddressState.id);
      setName(EmoryAddressState.name);
      setPhone(EmoryAddressState.phone);
      setFullAddress(EmoryAddressState.fullAddress);
      setPostalCode(EmoryAddressState.postalCode);
      setProvinceId(EmoryAddressState.provinceId);
      setCityId(EmoryAddressState.cityId);
      setDistrictId(EmoryAddressState.districtId);
      setCountryId(EmoryAddressState.countryId);
      setDescription(EmoryAddressState.description);
      setSender(EmoryAddressState.sender);
      setIsDefault(EmoryAddressState.isDefault);
      setPartnerId(EmoryAddressState.partnerId);
      dispatch(getCityByProvince(EmoryAddressState.provinceId));
      dispatch(getDistrictByCity(EmoryAddressState.cityId));
    }
  }, [EmoryAddressState]);

  useEffect(() => {
    if (EmoryAddressStatusState !== "") {
      if (EmoryAddressStatusState === "success") {
        setAlertProps({
          variant: "success",
          title: "Berhasil Meng-update data alamat Emory",
          content: "",
        });
        setShowAlert(true);
      } else if (EmoryAddressStatusState === "failed") {
        setAlertProps({
          variant: "Failed",
          title: "Gagal Meng-update data alamat Emory",
          content: "Silahkan periksa kelengkapan form alamat yang tersedia.",
        });
        setShowAlert(true);
      }
    }
  }, [EmoryAddressStatusState]);

  return (
    <div>
      <br />

      <Card>
        <Card.Body>
          <b>Setting Alamat Emory</b>
          <br />
          <br />
          <br />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Nama Alamat
            </Form.Label>

            <Col>
              <Form>
                <Row className="fee-input">
                  <Form.Control
                    placeholder="Kantor Emory"
                    type="text"
                    value={name}
                    onChange={onNameChange}
                  />
                </Row>
              </Form>
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Telepon
            </Form.Label>

            <Col>
              <Form>
                <Row className="fee-input">
                  <Form.Control
                    placeholder="08XXXXXXXXX"
                    type="text"
                    value={phone}
                    onChange={onPhoneChange}
                  />
                </Row>
              </Form>
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Alamat Lengkap
            </Form.Label>

            <Col>
              <Form>
                <Row className="fee-input">
                  <Form.Control
                    placeholder="Nama Jalan / Patokan Alamat Lengkap"
                    as="textarea"
                    rows={3}
                    value={fullAddress}
                    onChange={onAddressChange}
                  />
                </Row>
              </Form>
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Provinsi
            </Form.Label>
            <Col sm={4} className="p-0">
              <Form.Control
                as="select"
                onChange={onProvinceChange}
                value={provinceId}
              >
                <option>select</option>
                {provinceState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Kota
            </Form.Label>
            <Col sm={4} className="p-0">
              <Form.Control as="select" onChange={onCityChange} value={cityId}>
                <option>select</option>
                {cityState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Kecamatan
            </Form.Label>
            <Col sm={4} className="p-0">
              <Form.Control
                as="select"
                onChange={onDistrictChange}
                value={districtId}
              >
                <option>select</option>
                {districtState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Kode Pos
            </Form.Label>

            <Col sm={4}>
              <Form>
                <Row className="fee-input">
                  <Form.Control
                    placeholder="4XXXX"
                    type="text"
                    value={postalCode}
                    onChange={onPostalCodeChange}
                  />
                </Row>
              </Form>
            </Col>
          </Form.Group>

          <hr />

          <div className="btn-footer-group">
            <Button
              variant="outline-info"
              style={{ width: 130, height: 45 }}
              onClick={onCancelClick}
            >
              Cancel
            </Button>
            {"   "}
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              type="submit"
              form="faqForm"
              onClick={onSaveClick}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export default SettingAddressForm;
