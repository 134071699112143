export const convertBase64 = (
  file: Blob | undefined,
  cb: { (result: any): void; (arg0: string | ArrayBuffer | null): void }
) => {
  let reader = new FileReader();
  if (file !== undefined) {
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
};
