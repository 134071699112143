import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
  Button,
} from "react-bootstrap";
import SettingImageForm from "../components/SettingImageForm";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../../app/store";
import AlertMessage from '../../../../components/alertMessage';
import { getImageSettingList, resetSingle, updateImageSetting } from '../SettingImage.reducer';
import { ImageSettingModel } from '../SettingImage.model';


const SettingImage = () => {

  const dispatch = useDispatch();

  const ImageSettingStatusState = useSelector(
    (state: RootState) => state.settingImage.status ?? ""
  )

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () =>{
    setShowAlert(false)
    if(ImageSettingStatusState === "success"){
      dispatch(resetSingle())
    }
  }

  const onUpdate = (
    id: string,
    type: number,
    titleHowToOrder: string,
    imageSetting: number,
    titleBroadcastTheme: string,
    toBroadcastTheme: string,
    messageBroadcastTheme: string,
    linkIOSStockist: string,
    linkAndroidStockist: string,
    linkIOSReseller: string,
    linkAndroidReseller: string,
    linkIOSEndCustomer: string
  ) =>{
    dispatch(updateImageSetting({
      id,
      type,
      titleHowToOrder,
      imageSetting,
      titleBroadcastTheme,
      toBroadcastTheme,
      messageBroadcastTheme,
      linkIOSStockist,
      linkAndroidStockist,
      linkIOSReseller,
      linkAndroidReseller,
      linkIOSEndCustomer
    } as ImageSettingModel))
  }

  useEffect(() => {
   dispatch(getImageSettingList())
  }, [])

  useEffect(() => {
    if(ImageSettingStatusState === "success"){
      dispatch(getImageSettingList())
      setAlertProps({
        variant: "success",
        title: "Berhasil Meng-update Setting Image",
        content: "",
      });
      setShowAlert(true);
    }
  }, [ImageSettingStatusState])

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label className="title">Image</Form.Label>
              </Form.Group>
              <Card>
                <Card.Body>
                  <Tabs>
                    <Tab eventKey="reseller" title="Reseller">
                      <TabContent>
                        <SettingImageForm type={1} onUpdate={onUpdate}></SettingImageForm>
                      </TabContent>
                    </Tab>

                    <Tab eventKey="stockist" title="Stockist">
                      <TabContent>
                        <SettingImageForm type={0} onUpdate={onUpdate}></SettingImageForm>
                      </TabContent>
                    </Tab>

                    <Tab eventKey="customer" title="End Customer">
                      <TabContent>
                        <SettingImageForm type={2} onUpdate={onUpdate}></SettingImageForm>{" "}
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    </Container>
  );
};

export default SettingImage;
