import React, { useEffect } from "react";
import { } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getInternalUseList } from "../internalUse.reducer";
import IntenalUseTable from "../components/IntenalUseTable";

const InternalUseTablePage = () => {
    const dispatch = useDispatch();

    const statusState = useSelector(
        (state: RootState) => state.internalUse.name
    );

    const listDataState = useSelector(
        (state: RootState) => state.internalUse.list
    );

    useEffect(() => {
        dispatch(getInternalUseList());
    }, [statusState])


    return (
        <div>
            <IntenalUseTable />
        </div>
    );
};

export default InternalUseTablePage;
