import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

// export const getUserBroadcastAll = createAsyncThunk('get/partner/get/list', async () => {
//     const response = await axios.get(`${endpoint}​/api/TemplateBroadcast/GetListPartner`,{
//         // type 0 for Customer,
//         // type 1 for Stockist,
//         // type 2 for Reseller
//         // params: {
//         //     type : 2
//         // }
//     })
//     return response.data
// })

export const getUserBroadcastAll = createAsyncThunk('get/Partner/GetList', async () => {
    const response = await axios.get(`${endpoint}​/api/TemplateBroadcast/GetListPartner`, {
        // type 0 for Customer,
        // type 1 for Stockist,
        // type 2 for Reseller
        // params: {
        //     type : 2
        // }
    })
    return response.data
})

export const getBroadcastList = createAsyncThunk('get/TemplateBroadcast', async () => {
    const response = await axios.get(`${endpoint}​/api/TemplateBroadcast/GetList`)
    return response.data
})

export const getBroadcastByPartner = createAsyncThunk('get/getBroadcastByPartner', async (id: string) => {
    try {
        const response = await axios.get(`${endpoint}/api/TemplateBroadcast/GetListByPartnerDetail`, {
            params: {
                partnerId: id
            }
        })
        console.log(response.data.data, 'detail')
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postBroadcast = createAsyncThunk('api/post/TemplateBroadcast', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/TemplateBroadcast/Create`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postImageUpload = createAsyncThunk('upload/imagesConvert/broadcast', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/web/api/Upload/UploadImage`, body)
        return response
    }
    catch (error: any) {
        return error.response
    }
})
// export const updateHowToOrder = createAsyncThunk('api/update/howToOrder', async (body:any) => {
//     try {
//         const response = await axios.post(`${endpoint}/api/WebsitePageSetting/Update`, body)
//         return response.data
//     } 
//     catch (error:any) {
//         return error.response
//     }
// })

// export const deleteHowToOrder = createAsyncThunk('delete/howToOrder', async (id:any) => {
//     try {
//         const response = await axios.post(`${endpoint}/api/WebsitePageSetting/Delete`, {},{
//             params : {
//                 id : id
//             }
//         })
//         console.log(response)
//         return response
//     } 
//     catch (error:any) {
//         return error.response
//     }
// })