import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { createData, getListProduct, getSingleItem, updateData } from "../UOM.reducer";
import { UOMModel } from "../UOM.model";
import UOMForm from "../components/UOMForm";

const UOMPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector((state: RootState) => state.UOM.single ?? ({} as UOMModel));

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(getListProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (
    name: string,
    productCode: string,
    variant: string,
    code: string,
    unit: string,
    symbol: string,
    note: string,
    isActive: boolean
  ) => {
    if (isEdit) {
      dispatch(
        updateData({
          id,
          variant: variant.toString(),
          code,
          name,
          unit,
          symbol,
          note,
          isActive,
        } as UOMModel)
      );
    } else {
      dispatch(
        createData({
          variant: variant.toString(),
          code,
          name,
          unit,
          symbol,
          note,
        } as UOMModel)
      );
    }
  };
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <UOMForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default UOMPage;
