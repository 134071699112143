import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel } from "./laporanKartuStock.model";

export default class LaporanKartuStockApi {

 static getListWithPaging(model: FilterModel) {
  return axios
  .post<any>(endpoint + "/api/SalesOrder/ReportStock", model)
  .then((res) => res.data.data);
}

static exportToExcel(model: FilterModel){
  return axios
  .post<any>(endpoint+ "/api/SalesOrder/ReportStockExportToExcel", model)
            .then((res: any) => {
                console.log(res)
                const url = URL.createObjectURL(new Blob([res]));
                console.log("url : " + url)
                const link = document.createElement('a');                
                link.href = url;
                // link.setAttribute('download', `Laporan Pelunasan - ${model.reportType === 0 ? 'harian' : 'bulanan'} ${moment(new Date(model.filterMonthly)).format('DD-MMM-YYYY')}.xlsx`);
                document.body.appendChild(link);

                console.log("link : " + link)
           
                link.click();
            })
            .catch((err: any) => console.log(err))
}

}