import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { variationModel } from "../../master/product/product.model";
import Api from "./penerimaanInventory.api";
import {
  CreateItemReceiveModel,
  FilterModel,
  ItemReceiveDetailModel,
  ItemReceiveModel,
} from "./penerimaanInventory.model";

export interface ItemReceiveSlice {
  name?: string;
  list?: ItemReceiveModel[];
  single?: ItemReceiveModel;
  listItemReceiveDetail?: ItemReceiveDetailModel[];
  listVariationByProduct?: variationModel[];
  totalRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const createData = createAsyncThunk(
  "ItemReceiveDetailModelReceiveState/createItemReceive",
  async (Model: CreateItemReceiveModel, { rejectWithValue }) => {
    try {
      const response = await Api.createData(Model.ItemReceive);
      if (response.status === true) {
        let itemReceiveDetailData = Object.assign({}, Model.ItemReceiveDetail);
        itemReceiveDetailData.itemReceiveId = response.data.id;
        return await Api.createDetailData(itemReceiveDetailData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "ItemReceiveState/getItemReceiveList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWithPaging = createAsyncThunk(
  "ItemReceiveState/getItemReceiveListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);


export const getSingleItem = createAsyncThunk(
  "ItemReceiveState/ItemReceiveSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "ItemReceiveState/updateItemReceive",
  async (Model: CreateItemReceiveModel, { rejectWithValue }) => {
    try {
      const response = await Api.updateData(Model.ItemReceive);
      if (response.status === true) {
        let itemReceiveDetailData = Object.assign({}, Model.ItemReceiveDetail);
        itemReceiveDetailData.itemReceiveId = response.data.id;
        return await Api.createDetailData(itemReceiveDetailData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const approvalItemReceive = createAsyncThunk(
  "ItemReceiveState/approvalItemReceive",
  async (Model: ItemReceiveModel, { rejectWithValue }) => {
    try {
      return await Api.updateData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "ItemReceiveState/deleteItemReceive",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const changeVoidStatus = createAsyncThunk(
  "changeVoidStatusState/changeVoidStatus",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.changeVoidStatus(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getItemReceiveDetailList = createAsyncThunk(
  "ItemReceiveState/getItemReceiveDetailList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getItemReceiveDetailList(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationByProduct = createAsyncThunk(
  "ItemReceiveState/getVariationByproductList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getListVariationByProduct(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const ItemReceiveSlice = createSlice({
  name: "ItemReceiveState",
  initialState: {} as ItemReceiveSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as ItemReceiveModel;
      state.listItemReceiveDetail = [];
    },
    resetStatus: (state) => {
      state.status = "";
    },
    setItemReceiveDetail: (state, action) => {
      state.listItemReceiveDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(approvalItemReceive.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(approvalItemReceive.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(approvalItemReceive.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getItemReceiveDetailList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getItemReceiveDetailList.fulfilled, (state, { payload }) => {
      state.listItemReceiveDetail = payload;
      state.isLoading = false;
    });
    builder.addCase(getItemReceiveDetailList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(changeVoidStatus.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(changeVoidStatus.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-void";
      state.name = new Date().toISOString();
    });
    builder.addCase(changeVoidStatus.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed-void";
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getListVariationByProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListVariationByProduct.fulfilled, (state, { payload }) => {
      state.listVariationByProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setFilter, resetSingle, resetStatus, setItemReceiveDetail } =
  ItemReceiveSlice.actions;
export default ItemReceiveSlice.reducer;
