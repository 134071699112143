import { Card, Form, Col, Row } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import edit from "./../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import {SalesOrderModel, SalesOrderPaymentTypeEnum} from "../pesananEndCustomer.model";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "../pesananEndCustomer.reducer";
import { RootState } from "../../../../app/store";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const PesananEndCustomerTable = () => {
  const dispatch = useDispatch();

  const listState = useSelector((state: RootState) => state.pesananEndCustomer.list ?? []);

  const totalRowState = useSelector((state: RootState) => state.pesananEndCustomer.totalRow ?? 0);

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState<SalesOrderModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  //filter
  const [soNumber, setSoNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("0");
  const [timer, setTimer] = useState(setTimeout(() => {}, 1000));

  useEffect(() => {
    setLoading(true);

    dispatch(
      getList({
        soNumber: "",
        customerName: "",
        orderDate: "",
        salesOrderStatus: "",
        paymentStatus: "",
        paymentType : SalesOrderPaymentTypeEnum.Cashless,
        skip: 0,
        take: perPage,
      })
    );

    setList(listState);
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setList(listState);
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listState]);

  // useEffect(() => {
  //   setLoading(true);
  //   fetchData(0,perPage);
  //   setLoading(false);
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [soNumber, customerName, orderDate, paymentStatus]);

  const columns = [
    {
      name: "SO NUMBER",
      // selector: "soNumber",
      cell: (row: any) => (
        <Fragment>
          <Link
            to={{
              pathname: `/customer-so-detail/${row.id}`,
            }}
          >
            {row.soNumber}
          </Link>
        </Fragment>
      ),
      sortable: true,
    },
    {
      name: "JATUH TEMPO",
      selector: "billDueDate",
      sortable: true,
    },
    {
      name: "NAMA PELANGGAN",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "TANGGAL PESANAN",
      selector: (d: { orderDate: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.orderDate.slice(0, 10)} - {d.orderDate !== undefined && d.orderDate.substring(12,16)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "TOTAL",
      selector: (d: { grandTotal: any }) => (
        <>
          <NumberFormat
            value={d.grandTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS PEMBAYARAN",
      selector: (d: { paymentStatus: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.paymentStatus === 0 ? "UNPAID" : "PAID"}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS PENGIRIMAN",
      selector: (d: { shippingStatus: any }) => (
        <>
          {d.shippingStatus === 0 && <p style={{ paddingTop: 15 }}>On Progress</p>}
          {d.shippingStatus === 1 && <p style={{ paddingTop: 15 }}>Delivered</p>}

          {d.shippingStatus === 2 && <p style={{ paddingTop: 15 }}>Not Delivered</p>}
          {d.shippingStatus === 3 && <span>On Delivery</span>}
          {d.shippingStatus === 4 && <span>Delivered</span>}
          {d.shippingStatus === 5 && <span>Completed</span>}
          {d.shippingStatus === 6 && <span>Print A Request</span>}
          {d.shippingStatus === 7 && <span>Print B Request</span>}
          {d.shippingStatus === 8 && <span>Waiting For Pickup</span>}
        </>
      ),
      sortable: true,
    },

    {
      name: "STATUS TRANSAKSI",
      selector: (d: { transactionStatus: number }) => (
        <p style={{ margin: 10 }}>
          {d.transactionStatus === 0 && <span>Waiting For Payment</span>}
          {d.transactionStatus === 1 && <span>Payment Confirmed</span>}
          {d.transactionStatus === 2 && <span>Payment Received</span>}
          {d.transactionStatus === 3 && <span>Process</span>}
          {d.transactionStatus === 4 && <span>Delivery</span>}
          {d.transactionStatus === 5 && <span>Received</span>}
          {d.transactionStatus === 6 && <span>Delivered</span>}
          {d.transactionStatus === 11 && <span>Complain</span>}
          {d.transactionStatus === 12 && <span>Cancel</span>}
          {d.transactionStatus === 13 && <span>Completed</span>}
          {d.transactionStatus === 14 && <span>Rejected</span>}
          {d.transactionStatus === 15 && <span>Complain Process</span>}
          {d.transactionStatus === 16 && <span>Complain Reject</span>}
          {d.transactionStatus === 17 && <span>Complain Complete</span>}
          {d.transactionStatus === 18 && <span>Retur Process</span>}
          {d.transactionStatus === 19 && <span>Retur Complete</span>}
          {d.transactionStatus === 20 && <span>Retur Reject</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },

    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          {/* <Link
            to={{
              pathname: `/order-detail/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link> */}

          <Link
            to={{
              pathname: `/customer-so-detail/${d.id}`,
            }}>
            <img src={edit}></img>
          </Link>
        </>
      ),
    },
  ];
  // end of datatable components
  const fetchData = (page: number, perPage: number, _soNumber: string, _customerName: string, _orderDate: string) => {
    setLoading(true);

    dispatch(
      getList({
        soNumber: _soNumber,
        customerName: _customerName,
        orderDate: _orderDate,
        salesOrderStatus: "",
        paymentStatus: "",
        paymentType : SalesOrderPaymentTypeEnum.Cashless,
        skip: page,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    dispatch(
      getList({
        soNumber: soNumber,
        customerName:customerName,
        orderDate: "",
        salesOrderStatus: "",
        paymentStatus: "",
        paymentType : SalesOrderPaymentTypeEnum.Cashless,
        skip: page,
        take: newPerPage,
      })
    );
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage, soNumber, customerName, orderDate);
  };

  const onSONumberChange = (e: any) => {
      let inputValue = e.target.value;
      setSoNumber(inputValue);
      clearTimeout(timer);
      setTimer(
          setTimeout(() => {
              fetchData(0, perPage, inputValue, customerName, orderDate);
          }, 1000)
      );
  }

  const onCustomerNameChange = (e: any) => {
        let inputValue = e.target.value;
        setCustomerName(inputValue)
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                fetchData(0, perPage, soNumber, inputValue, orderDate);
            }, 1000)
        );
  }

  const onOrderDateChange = (e: any) => {
      let inputValue = e.target.value !== "" ? new Date(e.target.value).toISOString() : "";
      setOrderDate(inputValue);
      clearTimeout(timer);
      setTimer(
          setTimeout(() => {
              fetchData(0, perPage, soNumber, customerName, inputValue);
          }, 1000)
      );
  }

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    SO Number
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      value={soNumber}
                      onChange={onSONumberChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Pembeli
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      value={customerName}
                      onChange={onCustomerNameChange}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Tanggal Pesanan
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="date"
                      // value={new Date(orderDate).toISOString().substr(0, 10)}
                      onChange={onOrderDateChange}
                    />
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Status Pembayaran
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      as="select"
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                    >
                      <option value={0}>PAID</option>
                      <option value={1}>UNPAID</option>
                    </Form.Control>
                  </Col>
                </Form.Group> */}
              </Col>
            </Row>
          </Card.Body>
          <DataTable
            columns={columns}
            data={list}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default PesananEndCustomerTable;
