import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { ApiErrorResponse } from '../../..';
import DistrictApi from './district.api';
import { CityModel, DistrictModel, ProvinceModel } from './district.model';

export interface DistrictSlice {
    name? :string;
    list?: DistrictModel[];
    single?: DistrictModel;
    listProvince?: ProvinceModel[];
    listCity?: CityModel[];
    isLoading?: boolean;    
    error?: ApiErrorResponse<any>;
    status?: string;
    dateChanges?: string
}

export const createData = createAsyncThunk(
    'createState/create',
    async (Model: DistrictModel, {rejectWithValue}) => {
        try {
            return await DistrictApi.createData(Model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getList = createAsyncThunk(
     'getListState/getList',
     async ( _:string | undefined = undefined, {rejectWithValue}) => {
         try {
             return await DistrictApi.getList();
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 );

export const getSingleItem = createAsyncThunk(
     'SingleItemState/SingleItem',
    async (customerId: string, {rejectWithValue}) => {
         try {
             return await DistrictApi.getSingleItem(customerId);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 )

 export const updateData = createAsyncThunk(
     'State/update',
     async (model: DistrictModel, {rejectWithValue}) => {
         try {
             return await DistrictApi.updateData(model);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
)

export const deleteData = createAsyncThunk(
    'DeleteDistrictState/deleteDistrict',
    async (id: string, {rejectWithValue}) =>{
        try {
            return await DistrictApi.deleteData(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getProvince = createAsyncThunk(
    'ProvinceState/Province',
    async (_:string | undefined = undefined, {rejectWithValue}) =>{
        try {
            return await DistrictApi.getProvince();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getCity = createAsyncThunk(
    'CityState/City',
    async (provinceId: string, {rejectWithValue}) =>{
        try {
            return await DistrictApi.getCity(provinceId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const DistrictSlice = createSlice({
    name: 'DistrictState',
    initialState: {} as DistrictSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        resetSingle: (state) => {
            state.single = {} as DistrictModel
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createData.pending, (state) => {
          state.isLoading = true;
          state.status = "create";
        });
        builder.addCase(createData.fulfilled, (state, { payload }) => {
          state.dateChanges = new Date().toISOString();
          state.isLoading = false;
          state.status = "";
        });
        builder.addCase(createData.rejected, (state, { payload }) => {
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
          state.status = "";
        });
        builder.addCase(getSingleItem.pending, (state) =>{
            state.single = undefined;
            state.isLoading = true;
        });
        builder.addCase(getSingleItem.fulfilled, (state, {payload})=>{
            state.single = payload;
            state.isLoading = false;
        })
        builder.addCase(getSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })
        builder.addCase(getList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload})=> {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateData.fulfilled, (state, {payload})=> {
            state.isLoading = false;
            state.dateChanges = new Date().toISOString();
        })
        builder.addCase(updateData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteData.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.dateChanges = new Date().toISOString();
        })
        builder.addCase(deleteData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(getProvince.pending, (state) => {
            state.isLoading = true;
         })
        builder.addCase(getProvince.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.listProvince = payload;
         })
        builder.addCase(getProvince.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(getCity.pending, (state) => {
            state.isLoading = true;
         })
        builder.addCase(getCity.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.listCity = payload;
         })
        builder.addCase(getCity.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
      },
})

export const { setFilter, resetSingle } =DistrictSlice.actions;
export default DistrictSlice.reducer;