import axios from "axios";
import { endpoint } from "../../../constants";
import { ExpeditionModel, UploadImageModel } from "./Expedition.model";


export default class ExpeditionApi {

    static getExpeditionList() {
        return axios
            .get<any>(endpoint + "​/api/Expedition/GetList")
            .then((res) => res.data.data);
    }

    static getSingleExpedition(expeditionId: string) {
        return axios
            .get<any>(endpoint + "/api/Expedition/GetSingleItem?id=" + expeditionId
            )
            .then((res) => res.data.data);
    }

    static createExpedition(model: ExpeditionModel) {
        return axios
            .post<any>(
                endpoint + "/api/Expedition/Create",
                model
            )
            .then((res) => res.data.data);
    }

    static updateExpedition(model: ExpeditionModel) {
        return axios
            .post<any>(
                endpoint + "/api/Expedition/Update",
                model
            )
            .then((res) => res.data.data);
    }

    static deleteExpedition(expeditionId: string) {
        return axios
            .post<any>(endpoint + "/api/Expedition/Delete?Id=" + expeditionId)
            .then((res) => [
                { response: res.data.data },
            ]);
    }

    static uploadImage(model: UploadImageModel) {
        return axios
            .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
            .then((res) => res);
    }

}