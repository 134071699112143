import {Card, Form, Col, Row, Button, Modal} from "react-bootstrap";
import {ChangeEvent, Fragment, useEffect, useState} from "react";
import {Checkbox, Grid} from "semantic-ui-react";
import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import noImage from "../../../../assets/no-image.png";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../../constants";
import Viewer from "react-viewer";
import AlertMessage from "../../../../components/alertMessage";
import {
    getListWarehouse,
    getPendingNotification,
} from "../../../../global/global.reducer";
import {SalesOrderModel} from "../../paymentConfirm/paymentConfirm.model";
import {
    changeMultiplePaidStatus,
    changePaidStatus,
    getListWithPaging,
    resetStatus, updateMultipleStatus,
    updateStatus
} from "../../paymentConfirm/paymentConfirm.reducer";
import {ApiErrorResponse} from "../../../../index";
import Swal from "sweetalert2";

const PaymentConfirmEndCustomerTable = () => {
    const dispatch = useDispatch();

    const userState = useSelector(
        (state: RootState) => state.user.username ?? ""
    );

    const listState = useSelector(
        (state: RootState) => state.paymentConfirm.list ?? []
    );

    const loadingState = useSelector(
        (state: RootState) => state.paymentConfirm.isLoading ?? ""
    );

    const statusState = useSelector(
        (state: RootState) => state.paymentConfirm.status ?? ""
    );

    const error = useSelector(
        (state: RootState) => state.paymentConfirm.error ?? {} as ApiErrorResponse<any>
    );

    const modifiedDate = useSelector(
        (state: RootState) => state.paymentConfirm.modifiedDate ?? ""
    );

    const TotalRowState = useSelector(
        (state: RootState) => state.paymentConfirm.totalRow ?? 0
    );

    const warehouseListState = useSelector(
        (state: RootState) => state.global.listWarehouse ?? []
    );

    const [list, setList] = useState<SalesOrderModel[]>([]);

    //pagination
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    //filter
    const [filterSoNumber, setFilterSoNumber] = useState("");
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");
    const [filterPartnerType, setFilterPartnerType] = useState<number | null>(
        101
    );
    const [filterTempPartnerType, setFilterTempPartnerType] = useState(3);
    const [filterNoRek, setFilterNoRek] = useState("");
    const [filterCustomerName, setFilterCustomerName] = useState("");
    const [filterPaymentStatus, setfilterPaymentStatus] = useState<number | null>(
        null
    );
    const [filterTempPaymentStatus, setFilterTempPaymentStatus] = useState(2);
    const [filterStatus, setFilterStatus] = useState<number | null>(null);
    const [filterTempStatus, setFilterTempStatus] = useState(5);
    const [filterTransactionStatus, setFilterTransactionStatus] = useState<
        number | null
    >(null);
    const [filterTempTransactionStatus, setFilterTempTransactionStatus] =
        useState(99);
    const [filterWarehouseId, setFilterWarehouseId] = useState("");

    const [filterShipmentStatus, setFilterShipmentStatus] = useState<
        number | null
    >(null);

    const [filterNote, setFilterNote] = useState("")
    const [filterTempShipmentStatus, setFilterTempShipmentStatus] = useState(9);

    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedSalesOrderId, setSelectedSalesOrderId] = useState("");

    const [visible, setVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        variant: "",
        title: "",
        content: "",
    });

    const [showModal, setShowModal] = useState(false);
    const [selection, setSelection] = useState<Array<string>>([]);
    const [approveSelection, setApproveSelection] = useState<Array<string>>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [listIds, setListIds] = useState<string[]>([]);
    const [listApproveIds, setListApproveIds] = useState<string[]>([]);

    const handleClose = () => {
        setShowModal(false);
    };

    const onFilterWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterWarehouseId(e.target.value);
    };

    const changeToPaid = (id: string) => {
        Swal.fire({
            title: 'Apakah kamu yakin?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#f5b95d',
            icon: 'warning'
        }).then((result) => {
            dispatch(
                changePaidStatus({
                    id: id,
                    paymentStatus: 1,
                })
            );
        });
    };

    const changeToPaidSelected = () => {
        Swal.fire({
            title: 'Apakah kamu yakin?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#f5b95d',
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    changeMultiplePaidStatus({
                        id: selection,
                        paymentStatus: 1,
                    })
                );
            }
        });
    };

    const changeToApproveSelected = () => {
        let _data = approveSelection.map((item) => {
            return {
                status: 1,
                salesOrderId: item,
                approveBy: userState,
                approveDate: new Date().toISOString(),
                printRequest: 6,
            }
        })
        Swal.fire({
            title: 'Apakah kamu yakin?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#f5b95d',
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    updateMultipleStatus(_data)
                );
            }
        });
    };

    const onAlertClose = () => {
        setShowAlert(false);

        // dispatch(
        //   getList({
        //     soNumber: "",
        //     customerName: "",
        //     orderDate: "",
        //     accountNo: "",
        //     accountName: "",
        //     salesOrderStatus: "",
        //     paymentStatus: paymentStatus,
        //     skip: totalRows,
        //     take: perPage,
        //   })
        // );
        // dispatch(resetStatus());
        fetchData(0, perPage);
        dispatch(getPendingNotification());
    };

    const onFilterClick = () => {
        fetchData(0, perPage);
    };

    const onFilterResetClick = () => {
        setFilterSoNumber("");
        setFilterStartDate("");
        setFilterEndDate("");
        setFilterPartnerType(0);
        setFilterNoRek("");
        setFilterCustomerName("");
        setfilterPaymentStatus(null);
        setFilterStatus(null);
        setFilterTempPartnerType(3);
        setFilterTempPaymentStatus(2);
        setFilterTempStatus(5);
        setFilterTransactionStatus(null);
        setFilterTempTransactionStatus(99);
        setFilterWarehouseId("");
        setFilterTempShipmentStatus(9);
        setFilterShipmentStatus(null);
        setFilterNote("");

        dispatch(
            getListWithPaging({
                soNumber: "",
                startDate: "",
                endDate: "",
                partnerType: 0,
                noRek: "",
                customerName: "",
                paymentStatus: null,
                transactionStatus: null,
                shippingStatus: null,
                status: null,
                warehouseId: filterWarehouseId === "" ? null : filterWarehouseId,
                note: "",
                skip: 0,
                take: perPage,
            })
        );
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setLoading(true);
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    const handlePageChange = (page: any) => {
        fetchData((page - 1) * perPage, perPage);
    };

    const fetchData = (page: number, perPage: number) => {
        setLoading(true);

        dispatch(
            getListWithPaging({
                soNumber: filterSoNumber,
                startDate: filterStartDate,
                endDate: filterEndDate,
                partnerType: 0,
                noRek: filterNoRek,
                customerName: filterCustomerName,
                paymentStatus: filterPaymentStatus,
                transactionStatus: filterTransactionStatus,
                shippingStatus: filterShipmentStatus,
                status: filterStatus,
                warehouseId: filterWarehouseId === "" ? null : filterWarehouseId,
                note: filterNote,
                skip: page,
                take: perPage,
            })
        );
    };

    useEffect(() => {
        setLoading(false);
        if (listState.length > 0) {
            const ids = listState.filter((item) => (item.transactionStatus < 13 && item.paymentStatus === 0)).map((item) => item.id);
            const approveIds = listState.filter((item) => (item.status === 4 || item.status === 0 || item.status === 2) && item.transactionStatus !== 12).map((item) => item.salesOrderId);
            setListIds(ids);
            setListApproveIds(approveIds);
        }
    }, [listState]);

    useEffect(() => {
        fetchData(0, perPage);
        dispatch(getListWarehouse());
    }, []);

    useEffect(() => {
        if (
            statusState === "success-update" ||
            statusState === "success-update-paid-status"
        ) {
            // console.log("UPDATE SUCCESS !!!")

            setAlertProps({
                variant: "success",
                title: "Berhasil Mengupdate Data",
                content: " ",
            });
            setShowAlert(true);
            dispatch(resetStatus());
            handleClose();
            setSelectedSalesOrderId("");
            setSelection([]);
            setApproveSelection([]);
        } else if (statusState === "failed-update") {
            setAlertProps({
                variant: "failed",
                title: "Gagal Mengupdate Data",
                content: error?.data?.message,
            });
            setShowAlert(true);
            dispatch(resetStatus());
            handleClose();
            setSelectedSalesOrderId("");
            setSelection([]);
            setApproveSelection([]);
        }
    }, [statusState, error]);


    const isSelected = (key: string) => {
        return selection.includes(key);
    };

    const isApprovalSelected = (key: string) => {
        return approveSelection.includes(key);
    };

    const toggleAll = () => {
        const _selectAll = !selectAll;
        setSelection(_selectAll ? listIds : []);
        setApproveSelection(_selectAll ? listApproveIds : []);
        setSelectAll(_selectAll);
    };

    const toggleSelection = (key: string, salesOrderId: string, row: any) => {
        if (row.transactionStatus < 13 && row.paymentStatus === 0) {
            // start off with the existing state
            let _selection = [...selection];
            const keyIndex = selection.indexOf(key);
            // check to see if the key exists
            if (keyIndex >= 0) {
                // it does exist so we will remove it using destructing
                _selection = [
                    ...selection.slice(0, keyIndex),
                    ...selection.slice(keyIndex + 1),
                ];
            } else {
                // it does not exist so add it
                _selection.push(key);
            }
            // update the state
            setSelection(_selection);
        }

        if ((row.status === 4 || row.status === 0 || row.status === 2) && row.transactionStatus !== 12) {
            // start off with the existing state
            let _approveSelection = [...approveSelection];
            const keyIndex = approveSelection.indexOf(salesOrderId);
            // check to see if the key exists
            if (keyIndex >= 0) {
                // it does exist so we will remove it using destructing
                _approveSelection = [
                    ...approveSelection.slice(0, keyIndex),
                    ...approveSelection.slice(keyIndex + 1),
                ];
            } else {
                // it does not exist so add it
                _approveSelection.push(salesOrderId);
            }
            // update the state
            setApproveSelection(_approveSelection);
        }

    };

    const columns = [
        {
            name: <Checkbox
                id="select_all"
                checked={selectAll}
                onChange={toggleAll}
            />,
            width: "30px",
            cell: (row: any) => (
                <>
                    {((row.transactionStatus < 13 && row.paymentStatus === 0) || ((row.status === 4 || row.status === 0 || row.status === 2) && row.transactionStatus !== 12)) && (
                        <Checkbox id={row.id}
                                  checked={isSelected(row.id) || isApprovalSelected(row.salesOrderId)}
                                  onChange={() => toggleSelection(row.id, row.salesOrderId, row)}
                        />
                    )}
                </>
            )
        },
        {
            name: "SO NUMBER",
            // selector: "soNumber",
            cell: (row: any) => (
                <Fragment>
                    <Link
                        to={{
                            pathname: `/customer-so-detail/${row.salesOrderId}`,
                        }}
                    >
                        {row.soNumber}
                    </Link>
                </Fragment>
            ),
            sortable: true,
        },
        {
            name: "TANGGAL",
            selector: (d: { createdDate: string }) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d.createdDate !== undefined && d.createdDate.substring(0, 10)} - {d.createdDate !== undefined && d.createdDate.substring(12, 16)}
                    </p>
                </>
            ),
            sortable: true,
        },
        {
            name: "CUSTOMER",
            selector: "partnerName",
            sortable: true,
        },
        // {
        //   name: "TIPE CUSTOMER",
        //   selector: (d: { partnerType: number }) => (
        //     <>
        //       <p style={{ paddingTop: 15 }}>
        //         {d.partnerType === 0 && "End Customer"}
        //         {/* {d.partnerType === 1 && "Stockist"}
        //         {d.partnerType === 2 && "Reseller"} */}
        //         {/* {d.partnerType === 4 && "Admin Stockist"} */}
        //       </p>
        //     </>
        //   ),
        //   sortable: true,
        // },
        {
            name: "THIRD PARTY",
            selector: "thirdParty",
            sortable: true,
        },

        {
            name: "PAYMENT METHOD",
            selector: "paymentMethod",
            sortable: true,
        },
        {
            name: "NO. REKENING",
            selector: "accountNo",
            sortable: true,
        },
        {
            name: "BANK ACCOUNT",
            selector: "accountName",
            sortable: true,
        },
        {
            name: "GUDANG",
            selector: (d: { warehouseName: string | null }) => (
                <>
                    {d.warehouseName === null || d.warehouseName === undefined ? (
                        <>-</>
                    ) : (
                        <>
                            <p style={{paddingTop: 15}}>{d.warehouseName}</p>
                        </>
                    )}
                </>
            ),
            sortable: true,
        },
        {
            name: "TOTAL",
            selector: (d: { grandTotal: number }) => (
                <>
                    <NumberFormat
                        value={d.grandTotal}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="Rp. "
                    />
                </>
            ),
            sortable: true,
        },
        {
            name: "ATTACHMENT",
            selector: (d: { image: string }) => (
                <p style={{margin: 5}}>
                    {d.image !== "" && d.image !== null ? (
                        <img
                            src={d.image !== "" ? endpoint + d.image : "none"}
                            style={{width: 50}}
                            onClick={() => {
                                setSelectedImage(endpoint + d.image);
                                setVisible(true);
                            }}
                        />
                    ) : (
                        <b>-</b>
                        // <img src={noImage} style={{ width: 50 }} />
                    )}
                </p>
            ),
            sortable: true,
        },
        {
            name: "PAYMENT STATUS",
            selector: (d: { paymentStatus: number }) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d.paymentStatus === 0 ? "UNPAID" : "PAID"}
                    </p>
                </>
            ),
            sortable: true,
        },

        {
            name: "Delivery Status",
            selector: (d: { shippingStatus: number }) => (
                <p style={{margin: 10}}>
                    {d.shippingStatus === 0 && (
                        <span>
                {" "}
                            <p style={{color: "red"}}>Pending</p>
              </span>
                    )}
                    {/* {d.shippingStatus === 1 && <span>Print Request</span>} */}
                    {d.shippingStatus === 2 && <span>On Progress</span>}
                    {d.shippingStatus === 3 && <span>On Delivery</span>}
                    {d.shippingStatus === 4 && <span>Delivered</span>}
                    {d.shippingStatus === 5 && <span>Completed</span>}
                    {d.shippingStatus === 6 && <span>Print A Request</span>}
                    {d.shippingStatus === 7 && <span>Print B Request</span>}
                    {d.shippingStatus === 8 && <span>Waiting For Pickup</span>}
                </p>
            ),
            sortable: true,
        },

        {
            name: "TRANSACTION STATUS",
            selector: (d: { transactionStatus: number }) => (
                <p style={{margin: 10}}>
                    {d.transactionStatus === 0 && <span>Waiting For Payment</span>}
                    {d.transactionStatus === 1 && <span>Payment Confirmed</span>}
                    {d.transactionStatus === 2 && <span>Payment Received</span>}
                    {d.transactionStatus === 3 && <span>Process</span>}
                    {d.transactionStatus === 4 && <span>Delivery</span>}
                    {d.transactionStatus === 5 && <span>Received</span>}
                    {d.transactionStatus === 6 && <span>Delivered</span>}
                    {d.transactionStatus === 11 && <span>Complain</span>}
                    {d.transactionStatus === 12 && <span>Cancel</span>}
                    {d.transactionStatus === 13 && <span>Completed</span>}
                    {d.transactionStatus === 14 && <span>Rejected</span>}
                    {d.transactionStatus === 15 && <span>Complain Process</span>}
                    {d.transactionStatus === 16 && <span>Complain Reject</span>}
                    {d.transactionStatus === 17 && <span>Complain Complete</span>}
                    {d.transactionStatus === 18 && <span>Retur Process</span>}
                    {d.transactionStatus === 19 && <span>Retur Complete</span>}
                    {d.transactionStatus === 20 && <span>Retur Reject</span>}
                </p>
            ),
            sortable: true,
        },

        {
            name: "STATUS",
            selector: (d: { status: number }) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d.status === 0 && "Pending"}
                        {d.status === 1 && "Approve"}
                        {d.status === 2 && "Reject"}
                        {d.status === 3 && "Complete"}
                        {d.status === 4 && "Waiting"}
                    </p>
                </>
            ),
            sortable: true,
        },
        {
            name: "NOTE",
            selector: "note",
            sortable: true,
        },
        {
            name: "SET PAID",
            selector: (d: { id: string; paymentStatus: number; transactionStatus: number }) => (
                <>
                    {d.transactionStatus < 13 && d.paymentStatus === 0 ? (
                        <>
                            <button
                                style={{
                                    margin: 3,
                                    width: 70,
                                    height: 23,
                                    background: "#0000FF",
                                    borderRadius: 5,
                                    color: "white",
                                }}
                                onClick={() => {
                                    changeToPaid(d.id);
                                }}
                            >
                                SET PAID
                            </button>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            ),
        },
        {
            name: "ACTION",
            selector: (d: {
                salesOrderId: string;
                paymentStatus: number;
                status: number;
                transactionStatus: number
            }) => (
                <>
                    {/*{(d.status === 4 || d.status === 0) && d.transactionStatus !== 12 && (*/}
                    {/*  <>*/}
                    {/*    <button*/}
                    {/*      style={{*/}
                    {/*        margin: 3,*/}
                    {/*        width: 70,*/}
                    {/*        height: 23,*/}
                    {/*        background: "#F52B3D",*/}
                    {/*        borderRadius: 5,*/}
                    {/*        color: "white",*/}
                    {/*      }}*/}
                    {/*      // disabled={d.paymentStatus !== 0 && true}*/}
                    {/*      onClick={() => {*/}
                    {/*        handleChangeStatus(2, d.salesOrderId, 0);*/}
                    {/*        // setShowModal(true)*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Reject*/}
                    {/*    </button>*/}
                    {/*      <br />*/}
                    {/*      </>*/}
                    {/*)}*/}
                    {(d.status === 4 || d.status === 0 || d.status === 2) && d.transactionStatus !== 12 && (
                        <>
                            <button
                                style={{
                                    margin: 3,
                                    width: 70,
                                    height: 23,
                                    background: "#2ECC71",
                                    borderRadius: 5,
                                    color: "white",
                                }}
                                // disabled={d.paymentStatus !== 0 && true}
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedSalesOrderId(d.salesOrderId);
                                    // handleChangeStatus(1, d.salesOrderId)
                                }}
                            >
                                Approve
                            </button>
                        </>
                    )}
                    {/* <br />
            <button
              style={{
                margin: 3,
                width: 70,
                height: 23,
                background: "#12A1FD",
                borderRadius: 5,
                color: "white",
              }}
              disabled={d.paymentStatus !== 0 && true}
              onClick={() => handleChangeStatus(0, d.salesOrderId)}
            >
              Lunas
            </button> */}
                </>
            ),
        },
    ];
    // end of datatable components

    const handleChangeStatus = (
        status: number,
        salesOrderId: string,
        printRequest: number
    ) => {
        dispatch(
            updateStatus({
                status: status,
                salesOrderId: salesOrderId,
                approveBy: userState,
                approveDate: new Date().toISOString(),
                printRequest: printRequest,
            })
        );
    };

    const onSaveUpdateClick = (printRequestStatus: number) => {
        dispatch(
            updateStatus({
                status: 1,
                salesOrderId: selectedSalesOrderId,
                approveBy: userState,
                approveDate: new Date().toISOString(),
                printRequest: printRequestStatus,
            })
        );
    };

    return (
        <div>
            <Grid textAlign="center" style={{height: "100vh"}}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        SO Number
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            placeholder="Search"
                                            type="text"
                                            value={filterSoNumber}
                                            onChange={(e) => {
                                                setFilterSoNumber(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Tanggal
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control
                                            type="date"
                                            // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                                            onChange={(e) =>
                                                setFilterStartDate(
                                                    new Date(e.target.value).toISOString()
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Control
                                            type="date"
                                            // value={filterEndDate.substr(0, 10)}
                                            onChange={(e) =>
                                                setFilterEndDate(new Date(e.target.value).toISOString())
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Customer Name
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            placeholder="Search"
                                            type="text"
                                            value={filterCustomerName}
                                            onChange={(e) => {
                                                setFilterCustomerName(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Gudang
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            onChange={onFilterWarehouseChange}
                                            value={filterWarehouseId}
                                        >
                                            <option value="">Select</option>
                                            {warehouseListState.map((Item) => {
                                                return (
                                                    <option value={Item.id}>
                                                        {Item.code} - {Item.name} - {Item.city}
                                                    </option>
                                                );
                                            })}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>
                            {/* <Col md={6}>
                  <Form.Group as={Row} className="input-padding m-1">
                    <Form.Label column sm={4}>
                      No. Rekening
                    </Form.Label>
                    <Col>
                      <Form.Control
                        placeholder="Search"
                        type="text"
                        value={filterNoRek}
                        onChange={(e) => {
                          setFilterNoRek(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col> */}
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Partner Type
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={filterTempPartnerType}
                                            onChange={(e) => {
                                                if (e.target.value.toString() === "3") {
                                                    setFilterPartnerType(null);
                                                    setFilterTempPartnerType(parseInt(e.target.value));
                                                } else {
                                                    setFilterTempPartnerType(parseInt(e.target.value));
                                                    setFilterPartnerType(parseInt(e.target.value));
                                                }
                                            }}
                                        >
                                            <option value={0}>End Customer</option>
                                            {/* <option value={1}>Stockist</option>
                        <option value={2}>Reseller</option> */}
                                            {/* <option value={4}>Admin Stockist</option> */}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Payment Status
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={filterTempPaymentStatus}
                                            onChange={(e) => {
                                                if (e.target.value.toString() === "2") {
                                                    setfilterPaymentStatus(null);
                                                    setFilterTempPaymentStatus(parseInt(e.target.value));
                                                } else {
                                                    setFilterTempPaymentStatus(parseInt(e.target.value));
                                                    setfilterPaymentStatus(parseInt(e.target.value));
                                                }
                                            }}
                                        >
                                            <option value={2}>All</option>
                                            <option value={0}>UNPAID</option>
                                            <option value={1}>PAID</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Status
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={filterTempStatus}
                                            onChange={(e) => {
                                                if (e.target.value.toString() === "3") {
                                                    setFilterStatus(null);
                                                    setFilterTempStatus(parseInt(e.target.value));
                                                } else {
                                                    setFilterTempStatus(parseInt(e.target.value));
                                                    setFilterStatus(parseInt(e.target.value));
                                                }
                                            }}
                                        >
                                            <option value={5}>All</option>
                                            <option value={0}>Pending</option>
                                            <option value={1}>Approved</option>
                                            <option value={2}>Rejected</option>
                                            <option value={3}>Complete</option>
                                            <option value={4}>waiting</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Transaction Status
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={filterTempTransactionStatus}
                                            onChange={(e) => {
                                                if (e.target.value.toString() === "99") {
                                                    setFilterTransactionStatus(null);
                                                    setFilterTempTransactionStatus(
                                                        parseInt(e.target.value)
                                                    );
                                                } else {
                                                    setFilterTempTransactionStatus(
                                                        parseInt(e.target.value)
                                                    );
                                                    setFilterTransactionStatus(parseInt(e.target.value));
                                                }
                                            }}
                                        >
                                            <option value={99}>All</option>
                                            <option value={0}>Waiting For Payment</option>
                                            <option value={1}>Payment Confirmed</option>
                                            <option value={2}>Payment Received</option>
                                            <option value={3}>Process</option>
                                            <option value={4}>Delivery</option>
                                            <option value={5}>Received</option>
                                            <option value={6}>Delivered</option>
                                            <option value={11}>Complain</option>
                                            <option value={15}>Complain Process</option>
                                            <option value={16}>Complain Reject</option>
                                            <option value={17}>Complain Completed</option>
                                            <option value={12}>Cancel</option>
                                            <option value={13}>Completed</option>
                                            <option value={14}>Rejected</option>
                                            <option value={18}>Retur Process</option>
                                            <option value={19}>Retur Reject</option>
                                            <option value={20}>Retur Completed</option>


                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Delivery Status
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={filterTempShipmentStatus}
                                            onChange={(e) => {
                                                if (e.target.value.toString() === "9") {
                                                    setFilterShipmentStatus(null);
                                                    setFilterTempShipmentStatus(parseInt(e.target.value));
                                                } else {
                                                    setFilterShipmentStatus(parseInt(e.target.value));
                                                    setFilterTempShipmentStatus(parseInt(e.target.value));
                                                }
                                            }}
                                        >
                                            <option value={"9"}>All</option>
                                            <option value={"0"}>Pending</option>
                                            <option value={"1"}>Print Request</option>
                                            <option value={"2"}>On Progress</option>
                                            <option value={"8"}>Waiting For Pickup</option>
                                            <option value={"3"}>On Delivery</option>
                                            <option value={"4"}>Delivered</option>
                                            <option value={"5"}>Completed</option>
                                            <option value={"6"}>Print A Request</option>
                                            <option value={"7"}>Print B Request</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group as={Row} className="input-padding m-1">
                                    <Form.Label column sm={4}>
                                        Note
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            placeholder="Search"
                                            type="text"
                                            value={filterNote}
                                            onChange={(e) => {
                                                setFilterNote(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={10}>
                                <Button
                                    variant="outline-info"
                                    style={{width: 130, height: 45, marginLeft: 10}}
                                    type="submit"
                                    onClick={onFilterResetClick}
                                >
                                    Reset Filter
                                </Button>
                                <Button
                                    variant="info"
                                    style={{width: 130, height: 45, marginLeft: 10}}
                                    type="submit"
                                    onClick={onFilterClick}
                                >
                                    Filter
                                </Button>
                                <Button
                                    style={{
                                        marginLeft: 10,
                                        // width: 130,
                                        height: 45,
                                        background: "#0000FF",
                                        borderRadius: 5,
                                        color: "white",
                                    }}
                                    onClick={() => {
                                        changeToPaidSelected();
                                    }}
                                    disabled={selection.length === 0}
                                >
                                    {
                                        selection.length === 0 ? "Select Item First" : `Set Paid ${selection.length} Items`
                                    }
                                </Button>
                                <Button
                                    style={{
                                        marginLeft: 10,
                                        // width: 130,
                                        height: 45,
                                        background: "#2ECC71",
                                        borderRadius: 5,
                                        color: "white",
                                    }}
                                    onClick={() => {
                                        changeToApproveSelected();
                                    }}
                                    disabled={approveSelection.length === 0}
                                >
                                    {
                                        approveSelection.length === 0 ? "Select Item First" : `Approve ${approveSelection.length} Items`
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>

                    <hr/>

                    <DataTable
                        columns={columns}
                        data={listState}
                        pagination
                        paginationServer
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle}
                        noHeader
                        paginationTotalRows={TotalRowState}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        style={{}}
                        paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                    />
                </Card>
            </Grid>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <div style={{fontWeight: "bold"}}>Request Print</div>
                </Modal.Header>

                <Modal.Body>
                    <div className="btn-footer-group">
                        <Row>
                            <Col className="text-center">
                                <Button
                                    variant="info"
                                    style={{width: 140, height: 45}}
                                    onClick={() => onSaveUpdateClick(6)}
                                >
                                    PRINT A
                                </Button>
                            </Col>
                            <Col className="text-center">
                                <Button
                                    variant="info"
                                    style={{width: 180, height: 45}}
                                    onClick={() => onSaveUpdateClick(7)}
                                >
                                    PRINT SURAT JALAN
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Viewer
                visible={visible}
                onClose={() => {
                    setVisible(false);
                }}
                images={[{src: selectedImage, alt: selectedImage}]}
            />

            <AlertMessage
                {...alertProps}
                show={showAlert}
                onAlertCloseAction={onAlertClose}
            ></AlertMessage>
        </div>
    );
};

export default PaymentConfirmEndCustomerTable