import axios from "axios";
import {endpoint} from "../../../constants";
import {TemporaryShipmentRequest} from "./temporaryShipment.model";

export default class TemporaryShipmentApi {
    static getTemporaryShipment(args: TemporaryShipmentRequest) {
        return axios
            .get<any>(endpoint + "/api/TemporaryShipment/GetListWithPaging", {
                params: args
            })
            .then((res) => res.data.data);
    }
    static removeTemporaryShipment(id: string) {
        return axios
            .post<any>(endpoint + `/api/TemporaryShipment/Delete?Id=${id}`)
            .then((res) => res.data.data);
    }
}