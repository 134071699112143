import React, { useEffect } from 'react'

const SalesOrderPrint = () => {

 useEffect(() => {
  setTimeout(() => {
   window.print()
 }, 1000)
 }, [])

  return (
    <div>SalesOrderPrint</div>
  )
}

export default SalesOrderPrint