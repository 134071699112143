import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel } from "./pesananDropship.model";

export default class Api {
  static getList(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/GetListDropshipWithPaging", model)
      .then((res) => res.data.data.list);
  }
}
