import {
  JatuhTempoDropshipModel,
  ShipmentModel,
  SalesOrderModel,
  UpdateStatusModel,
  FilterModel,
  PartnerTypeEnumModel,
} from "./jatuhTempoDropship.model";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../../..";
import Api from "./jatuhTempoDropship.api";
import { PaymentConfirmationModel } from "../model";

export interface JatuhTempoDropshipSlice {
  name?: string;
  single?: ShipmentModel;
  salesOrderSingle?: SalesOrderModel;
  list?: JatuhTempoDropshipModel[];
  totalRow? : number;
  listRekap?: JatuhTempoDropshipModel[];
  partnerTypeEnum? : PartnerTypeEnumModel[];
  paymentConfirmationSingle?: PaymentConfirmationModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getList = createAsyncThunk(
  "JatuhTempoDropshipState/getJatuhTempoDropshipList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getJatuhTempoDropshipList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPartnerTypeEnum = createAsyncThunk(
  "JatuhTempoDropshipGetPartnerTypeEnum/list",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getPartnerTypeEnum();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWithPaging = createAsyncThunk(
  "JatuhTempoDropshipGetListWithPagingState/getJatuhTempoDropshipGetListWithPaging",
  async (model : FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListRekap = createAsyncThunk(
  "JatuhTempoDropshipState/getJatuhTempoRekapList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getJatuhTempoRekapList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getJatuhTempoDropshipSingleItem = createAsyncThunk(
  "JatuhTempoDropshipState/JatuhTempoDropshipSingle",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getJatuhTempoDropshipSingle(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSalesOrderSingle = createAsyncThunk(
  "JatuhTempoDropshipState/salesOrderSingle",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSalesOrderDetail(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPaymentConfirmationSingle = createAsyncThunk(
  "JatuhTempoDropshipState/paymentConfirmSingle",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSinglePaymentConfirmation(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateStatusPayment = createAsyncThunk(
  "JatuhTempoDropshipState/updatePayment",
  async (model: UpdateStatusModel, { rejectWithValue }) => {
    try {
      return await Api.updatePayment(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const JatuhTempoDropshipSlice = createSlice({
  name: "KeepBelumLunasState",
  initialState: {} as JatuhTempoDropshipSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetStatusJatuhTempoDropship: (state) => {
      state.status = "";
    },
    resetSingleJatuhTempoDropship: (state) => {
      state.single = {} as ShipmentModel;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListRekap.pending, (state) => {
      state.listRekap = undefined;
      state.isLoading = true;
    });
    builder.addCase(getListRekap.fulfilled, (state, { payload }) => {
      state.listRekap = payload;
      state.isLoading = false;
    });
    builder.addCase(getListRekap.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getList.pending, (state) => {
      state.list = undefined;
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListWithPaging.pending, (state) => {
      state.list = undefined;
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getJatuhTempoDropshipSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getJatuhTempoDropshipSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getJatuhTempoDropshipSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getSalesOrderSingle.pending, (state) => {
      state.salesOrderSingle = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSalesOrderSingle.fulfilled, (state, { payload }) => {
      state.salesOrderSingle = payload;
      state.isLoading = false;
    });
    builder.addCase(getSalesOrderSingle.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getPaymentConfirmationSingle.pending, (state) => {
      state.salesOrderSingle = undefined;
      state.isLoading = true;
    });
    builder.addCase(getPaymentConfirmationSingle.fulfilled, (state, { payload }) => {
      state.paymentConfirmationSingle = payload;
      state.isLoading = false;
    });
    builder.addCase(getPaymentConfirmationSingle.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getPartnerTypeEnum.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPartnerTypeEnum.fulfilled, (state, { payload }) => {
      state.partnerTypeEnum = payload;
      state.isLoading = false;
    });
    builder.addCase(getPartnerTypeEnum.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setFilter, resetSingleJatuhTempoDropship, resetStatusJatuhTempoDropship } =
  JatuhTempoDropshipSlice.actions;
export default JatuhTempoDropshipSlice.reducer;
