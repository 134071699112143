import React from 'react'
import ExpeditionTable from '../components/ExpeditionTable'

const ExpeditionTablePage = () => {
    return (
        <div>
            <ExpeditionTable/>
        </div>
    )
}

export default ExpeditionTablePage
