import { Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../../../app/store";
import { getPendingNotification } from "../../../../global/global.reducer";
import KeepBarangTable from "../components/KeepBarangTable";

/*
 * created by : kuy
 * created date : 16/nov/2022
 */

const KeepBarangIndex = () => {
  const dispatch = useDispatch();

  const statusState = useSelector((state: RootState) => state.account.name);

  useEffect(() => {
      if (statusState !== undefined) {
          dispatch(getPendingNotification());
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  return (
      <Container className="content-body" fluid>
        <Form.Group>
          <Form.Label className="page-title">Keep Barang</Form.Label>
        </Form.Group>
        <KeepBarangTable />
      </Container>
  );
};

export default KeepBarangIndex;
