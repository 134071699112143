import {
  Card,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import {PartnerTypeEnum} from "../keepBarang.model";
import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import DeleteMessage from "../../../../components/DeleteMessage";
import hapus from "../../../../assets/vector/delete.png";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getPendingNotification } from "../../../../global/global.reducer";
import {getKeepBarangList, removeKeepBarang, resetStatus} from "../keepBarang.reducer";
import AlertMessage from "../../../../components/alertMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

/*
 * created by : kuy
 * created date : 16/nov/2022
 */

const KeepBarangTable = () => {
  const dispatch = useDispatch();

  const totalRowsState = useSelector(
    (state: RootState) => state.keepBarang.totalRow ?? 0
  );
  const keepBarangList = useSelector(
    (state: RootState) => state.keepBarang.list ?? []
  );
  const keepBarangStatus = useSelector(
      (state: RootState) => state.keepBarang.status
  );

  const [selectedId, setSelectedId] = useState("");

  const [filterName, setFilterName] = useState("");
  const [filterSKU, setFilterSKU] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterPartnerType, setFilterPartnerType] = useState(PartnerTypeEnum.None);

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertStatus, setShowAlertStatus] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onDelete = () => {
    dispatch(removeKeepBarang(selectedId));
    setShowAlert(false);
  };

  const onAlertClose = () => setShowAlert(false);

  const onAlertStatusClose = () => {
    onFilterResetClick();
    dispatch(resetStatus());
    setShowAlertStatus(false);
  }

  useEffect(() => {
    if (keepBarangStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "Data keep berhasil dihapus",
      });
      setShowAlertStatus(true);
    } else if (keepBarangStatus === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "Data keep gagal dihapus",
      });
      setShowAlertStatus(true);
    }
  }, [keepBarangStatus]);

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getKeepBarangList({
        partnerType: filterPartnerType === PartnerTypeEnum.None ? null : filterPartnerType,
        partnerName: filterName,
        startDate: filterStartDate,
        endDate: filterEndDate,
        productSKU: filterSKU,
        skip: page,
        take: perPage,
      })
    );
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterSKU("");
    setFilterPartnerType(PartnerTypeEnum.None);

    dispatch(
        getKeepBarangList({
          partnerType: null,
          partnerName: "",
          startDate: "",
          endDate: "",
          productSKU: "",
          skip: 0,
          take: perPage,
        })
    );
  };

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  useEffect(() => {
    setLoading(false);
    if (keepBarangList.length !== 0) {
      dispatch(getPendingNotification());
    }

  }, [keepBarangList]);

  const columns = [
    {
      name: "SKU",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "Nama Produk",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Varian",
      selector: "variantName",
      sortable: true,
    },
    {
      name: "Qty",
      selector: "qty",
      sortable: true,
    },
    {
      name: "Nama Parner",
      selector: "partnerName",
      sortable: true,
    },
    {
      name: "Tipe Partner",
      selector: (d: { partnerType: number }) => (
        <p style={{ margin: 10 }}>
          {d.partnerType === 0 && <span>End Customer</span>}
          {d.partnerType === 1 && <span>Stockist</span>}
          {d.partnerType === 2 && <span>Reseller</span>}
          {d.partnerType === 4 && <span>Admin Stockist</span>}
        </p>
      ),
      sortable: true,
    },

    {
      name: "Catatan",
      selector: "note",
      sortable: true,
    },
    {
      name: "Tanggal Keep",
      selector: (d: { createdDate: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.createdDate.slice(0, 10)} - {d.createdDate.substring(11,16)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any}) => (
        <>
          <span
            onClick={() => {
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Keep",
                content: "Apakah kamu yakin akan menghapus keep ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card className="pt-3">
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Nama Partner
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal Keep
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Tipe Partner
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                      as="select"
                      onChange={(e) => {
                        setFilterPartnerType(parseInt(e.target.value));
                      }}
                      value={filterPartnerType}
                  >
                    <option value={PartnerTypeEnum.None}>None</option>
                    <option value={PartnerTypeEnum.Customer}>Customer</option>
                    <option value={PartnerTypeEnum.Stockist}>Stockist</option>
                    <option value={PartnerTypeEnum.Reseller}>Reseller</option>
                    <option value={PartnerTypeEnum.Admin}>Admin</option>
                    <option value={PartnerTypeEnum.AdminStockist}>AdminStockist</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  SKU
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterSKU}
                    onChange={(e) => {
                      setFilterSKU(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

          </Row>

          <Row>
            <Col md={4}>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          <hr />

          <DataTable
            columns={columns}
            data={keepBarangList}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowsState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
s
      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>

      <AlertMessage {...alertProps} show={showAlertStatus} onAlertCloseAction={onAlertStatusClose} />
    </div>
  );
};

export default KeepBarangTable;
