import { createUser, getUserSingleItem, updateUser } from "../user.reducers";
import { createUserModel, UserModel } from "../models";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import UserCreate from "../components/UserCreate";
import { RootState } from "../../../app/store";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const UserPage = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.account.single ?? ({} as UserModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getUserSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    name: string,
    username: string,
    email: string,
    pinCode: string,
    password: string,
    confirmPassword: string,
    roleName: string,
    isActive: boolean,
    userId: string
  ) => {
    if (isEdit) {
      dispatch(
        updateUser({
          userData: {
            id,
            name,
            username,
            email,
            pinCode,
            password,
            confirmPassword,
            isActive,
            roleName,
          },
          assignRoleData: {
            userId,
            roleName: roleName,
          },
        } as createUserModel)
      );
    } else {
      dispatch(
        createUser({
          userData: {
            name,
            username,
            email,
            pinCode,
            password,
            confirmPassword,
            isActive,
            roleName,
          },
          assignRoleData: {
            userId,
            roleName: roleName,
          },
        } as createUserModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <div onClick={() => history.goBack()} className="form-title">
        <FaChevronLeft />
        {"            "}
        {isEdit ? "Edit Data User" : "Add Data User"}
      </div>
      <Row>
        <Col md={12}>
          <UserCreate onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
        </Col>
      </Row>
    </Container>
  );
};

export default UserPage;
