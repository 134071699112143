import { createSlice } from '@reduxjs/toolkit'
import { postExportPelunasan, postLaporanPelunasan } from './laporanPelunasan.api'


export interface LaporanPelunasanState {
  loading : boolean,
  // listMembership : any,
}
const initialState: LaporanPelunasanState = {
  loading : false,
  // listMembership : []
}

export const laporanPelunasanSlice = createSlice({
    name: 'laporan pelunasan',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(postLaporanPelunasan.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postLaporanPelunasan.fulfilled, (state, action) => {
            state.loading = false

        })
        builder.addCase(postLaporanPelunasan.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        builder.addCase(postExportPelunasan.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postExportPelunasan.fulfilled, (state, action) => {
            state.loading = false

        })
        builder.addCase(postExportPelunasan.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        // builder.addCase(postImageUpload.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(postImageUpload.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(postImageUpload.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //delete
        // builder.addCase(deleteSlideShow.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(deleteSlideShow.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(deleteSlideShow.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //detail
        // builder.addCase(getSlideShowDetail.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(getSlideShowDetail.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(getSlideShowDetail.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //update
        // builder.addCase(updateSlideShow.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(updateSlideShow.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(updateSlideShow.rejected, (state, action) => {
        //     state.loading = false
        // })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default laporanPelunasanSlice.reducer