import React, { useEffect, useState, useRef } from 'react'
import '../orderDataReport.scss'
import { Container, Form, Button } from "react-bootstrap";
import { FiEdit, FiPlus, FiPrinter, FiTrash } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { deleteSlideShow, getSlideShow } from '../slideShow.api';
// import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaFileExport } from 'react-icons/fa';
import { postDataPesanan, postExportOrderData } from '../orderDataReport.api';
import moment from 'moment';
import Pagination from "react-js-pagination";
import ReactToPrint from 'react-to-print';

import { RootState } from '../../../../app/store';
import { DatePicker, Space } from 'antd';
import {transactionStatus} from "../constants";
const { RangePicker } = DatePicker;



export default function OrderDataReport() {
    // const history = useHistory()
    const dispatch = useDispatch()
    // const { list, total } = useSelector((state: RootState) => state.orderDataReport)
    const [list, setListData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const tableRef = useRef(null);

    const initialValues = {
        "reportType": 2,
        "partnerType": 'null',
        "productName": "",
        "partnerName": "",
        "soNumber": "",
        "productSKU": "",
        "startFilterDate": '',
        "endFilterDate": '',
        "paymentStatus": 'null',
        "statusOrder": 'null',
        "transactionStatus": 'null',
        "skip": 0,
        "take": '10',
        page: 1
    }

    const [values, setValues] = useState({
        "reportType": 2,
        "partnerType": 'null',
        "productName": "",
        "partnerName": "",
        "soNumber": "",
        "productSKU": "",
        "startFilterDate": '',
        "endFilterDate": '',
        "paymentStatus": 'null',
        "statusOrder": 'null',
        "transactionStatus": 'null',
        "skip": 0,
        "take": '10',
        page: 1
    })

    const getListPesanan = () => {
        setIsLoading(true);
        const data = {
            "reportType": values.reportType,
            "partnerType": values.partnerType === 'null' ? null : parseInt(values.partnerType),
            "productName": values.productName,
            "partnerName": values.partnerName,
            "soNumber": values.soNumber,
            "productSKU": values.productSKU,
            "startFilterDate": values.startFilterDate === "" ? "" : new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? "" : new Date(values.endFilterDate).toISOString(),
            "paymentStatus": values.paymentStatus === 'null' ? null : parseInt(values.paymentStatus),
            "statusOrder": values.statusOrder === 'null' ? null : parseInt(values.statusOrder),
            "transactionStatus": values.transactionStatus === 'null' ? null : parseInt(values.transactionStatus),
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(postDataPesanan(data))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res.list);
                setListData(res.list);
                setTotal(res.total);
                setIsLoading(false);
            })
            .catch((err: any) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getListPesanan()
    }, [values.take, values.skip, values.page])

    const handlePagination = (page: any) => {
        setValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        })
    }


    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
        console.log(e.target.name)
        console.log(e.target.value)
    }

    const handlePerPage = (e: any) => {
        setValues({
            ...values,
            take: e.target.value,
            skip: 0,
            page: 1
        })
    }

    const handleRangePicker = (event: any, picker: any) => {
        setValues({
            ...values,
            reportType: 0,
            startFilterDate: picker[0],
            endFilterDate: picker[1],
        })
    }



    const handleExportExcel = (e: any) => {
        setIsLoading(true);
        const data = {
            "reportType": values.startFilterDate === "" ? values.reportType : 0,
            "partnerType": values.partnerType === 'null' ? null : parseInt(values.partnerType),
            "productName": values.productName,
            "partnerName": values.partnerName,
            "soNumber": values.soNumber,
            "productSKU": values.productSKU,
            "startFilterDate": values.startFilterDate === "" ? "" : new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? "" : new Date(values.endFilterDate).toISOString(),
            "paymentStatus": values.paymentStatus === 'null' ? null : parseInt(values.paymentStatus),
            "statusOrder": values.statusOrder === 'null' ? null : parseInt(values.statusOrder),
            "transactionStatus": values.transactionStatus === 'null' ? null : parseInt(values.transactionStatus),
            "skip": values.skip,
            "take": parseInt(values.take)
        }

        dispatch<any>(postExportOrderData(data))
            .then(unwrapResult)
            .then((res: any) => {
                setIsLoading(false);
                const url = URL.createObjectURL(new Blob([res]));
                console.log(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Laporan Data Pesanan - ${moment(new Date(values.startFilterDate)).format('DD-MMM-YYYY')} - ${moment(new Date(values.endFilterDate)).format('DD-MMM-YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err: any) => {
                setIsLoading(false);
                console.log(err)
            })
    }

    const handleSubmit = (e: any) => {
        setIsLoading(true);
        const data = {
            "reportType": values.startFilterDate === "" ? values.reportType : 0,
            "partnerType": values.partnerType === 'null' ? null : parseInt(values.partnerType),
            "productName": values.productName,
            "partnerName": values.partnerName,
            "soNumber": values.soNumber,
            "productSKU": values.productSKU,
            "startFilterDate": values.startFilterDate === "" ? "" : new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? "" : new Date(values.endFilterDate).toISOString(),
            "paymentStatus": values.paymentStatus === 'null' ? null : parseInt(values.paymentStatus),
            "statusOrder": values.statusOrder === 'null' ? null : parseInt(values.statusOrder),
            "transactionStatus": values.transactionStatus === 'null' ? null : parseInt(values.transactionStatus),
            "skip": 0,
            "take": parseInt(values.take)
        }
        setValues({
            ...values,
            page: 1,
        })
        console.log(values)

        // dispatch<any>(postDataPesanan(data))

        //Revision By: MulyoDev
        dispatch<any>(postDataPesanan(data))
            .then(unwrapResult)
            .then((res: any) => {
                setIsLoading(false);
                // console.log(res.list)
                setListData(res.list)
                setTotal(res.total)
            })
            .catch((err: any) => {
                setIsLoading(false);
            })
        e.preventDefault()
    }

    const [isReset, setisReset] = useState(false)
    useEffect(() => {
        getListPesanan()
    }, [isReset])

    const handleReset = () => {
        setValues(initialValues)
        setisReset(!isReset)
    }

    const [displayHeader, setdisplayHeader] = useState(false)

    return (
        <Container className="content-body orderDataReport" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                <span style={{fontSize: '24px'}}>
                    Laporan Data Pesanan
                </span>
                <div>
                    <Button className='addor' onClick={handleExportExcel}><FaFileExport /> Export to Excel</Button>
                    <ReactToPrint
                        trigger={() => <Button className='addor'><FiPrinter /> Print</Button>}
                        // onBeforeGetContent ={()=> setdisplayHeader(true)}
                        content={() => {
                            // setdisplayHeader(true)
                            return tableRef.current
                        }}
                        onAfterPrint={() => setdisplayHeader(false)}
                        documentTitle='Laporan Data Pesanan'
                    />
                </div>
            </div>
            <div className="box">
                <div className="head w-100">
                    <form onSubmit={handleSubmit}>
                        <div className="row w-100">
                            <div className="col-md-5">
                                <div className="wrap-inp align-items-start">
                                    <label htmlFor="title">Jenis Pembeli</label>
                                    <div className='wrap-radios'>
                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='user4'
                                            label='Semua Pelanggan'
                                            name='partnerType'
                                            checked={values.partnerType === 'null'}
                                            value={'null'}
                                            onClick={handleChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='user4'
                                            label='Reseller'
                                            name='partnerType'
                                            checked={values.partnerType === '2'}
                                            value={'2'}
                                            onClick={handleChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='user4'
                                            label='Stockist'
                                            name='partnerType'
                                            checked={values.partnerType === '1'}
                                            value={'1'}
                                            onClick={handleChange}
                                        />

                                        <Form.Check
                                            className='radiocustom ml-3 w-100'
                                            style={{ marginBottom: 13 }}
                                            inline
                                            type='radio'
                                            id='user4'
                                            label='End Customer'
                                            name='partnerType'
                                            checked={values.partnerType === '0'}
                                            value={'0'}
                                            onClick={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Tanggal pesanan</label>
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            className='custext'
                                            onChange={handleRangePicker}
                                            value={values.startFilterDate !== "" && values.endFilterDate !== "" ? [moment(values.startFilterDate, 'YYYY-MM-DD'), moment(values.endFilterDate, 'YYYY-MM-DD')] : undefined}
                                            format="YYYY-MM-DD"
                                        />
                                    </Space>
                                </div>
                            </div>

                            <div className="col-md-5 mr-auto">
                                <div className="wrap-inp">
                                    <label htmlFor="title">SO Number</label>
                                    <input className='custext' type="text" placeholder='SO Number' name="soNumber" value={values.soNumber} onChange={handleChange} />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Nama Produk</label>
                                    <input className='custext' type="text" placeholder='Nama Produk' value={values.productName} name="productName" onChange={handleChange} id="" />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Nama Pembeli</label>
                                    <input className='custext' type="text" placeholder='Nama Pembeli' value={values.partnerName} name="partnerName" onChange={handleChange} id="" />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">SKU</label>
                                    <input className='custext' type="text" placeholder='SKU' value={values.productSKU} name="productSKU" onChange={handleChange} id="" />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title mr-5">Status Pesanan</label>
                                    <select className=" custom-select cus-sel left" placeholder='Select' name='statusOrder' value={values.statusOrder} onChange={handleChange} >
                                        <option value="null">Select</option>
                                        <option value="0">Keep</option>
                                        <option value="1">Dropship</option>
                                    </select>
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title mr-5">Status Pembayaran</label>
                                    <select className=" custom-select cus-sel left" name='paymentStatus' value={values.paymentStatus} onChange={handleChange} placeholder='Select'>
                                        <option value="null">Select</option>
                                        <option value="0">Belum Lunas</option>
                                        <option value="1">Lunas</option>
                                    </select>
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title mr-5">Status Transaksi</label>
                                    <select className=" custom-select cus-sel left" name='transactionStatus' value={values.transactionStatus} onChange={handleChange} placeholder='Select'>
                                        {
                                            transactionStatus.map((item, index) => (
                                                <option value={item.value}>{item.label}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="wrapbutfil col-md-7">
                                <Button className='addor' onClick={handleReset}>Reset</Button>
                                <Button className='addorrev' type='submit'>Filter</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="con" >
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing {list.length} of {total} results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span>
                            <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage} >
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>

                    <div style={{ overflowX: 'scroll' }} >
                        <table id='table' className='table'>

                            {/* <thead style={{ display: "none" }}>
                                    <tr className='mb-3' >
                                        <th colSpan={100} >
                                            <h4 >Laporan Data Pesanan</h4>
                                        </th>
                                    </tr>
                                </thead> */}

                            <thead className="">
                                <tr>
                                    {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                    <th scope="col">No</th>
                                    <th scope="col">Tanggal pesanan</th>
                                    <th scope="col">Pembeli</th>
                                    <th scope="col">Type Customer</th>
                                    <th scope="col">SO Number</th>
                                    <th scope="col">Kode produk</th>
                                    <th scope="col">Produk</th>
                                    <th scope="col">Varian</th>
                                    <th scope="col">QTY</th>
                                    <th scope="col">Subtotal</th>
                                    <th scope="col">Status Pesanan</th>
                                    <th scope="col">Status Bayar</th>
                                    <th scope="col">Status Transaksi</th>
                                    <th scope="col">Catatan SO</th>
                                    <th scope="col">nama pengirim</th>
                                </tr>
                            </thead>
                            {
                                isLoading ?
                                    <tbody>
                                    <tr>
                                        <td colSpan={14}>
                                            <h4 className="text-center">Loading...</h4>
                                        </td>
                                    </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                    {
                                        list.map((res: any, i: number) => (
                                            <tr key={i}>
                                                {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                                <td>
                                                    {
                                                        values.skip === 0 ?
                                                            i + 1
                                                            :
                                                            i + 1 + (values.page - 1) * parseInt(values.take)
                                                    }
                                                </td>
                                                <td>{moment(res?.orderDate).format('DD/MM/YYYY')}</td>
                                                <td>{res?.customerName}</td>
                                                <td>
                                                    {
                                                        res?.type === 1 ? 'Stockist' :
                                                            res?.type === 2 ? 'Reseller' :
                                                                res?.type === 3 ? 'Admin' :
                                                                    res?.type === 4 ? 'Admin Stokist' :
                                                                        res?.type === 0 ? 'End Customer' : null
                                                    }
                                                </td>
                                                <td>{res?.soNumber}</td>
                                                <td>{res?.productCode}</td>
                                                <td>{res?.productName}</td>
                                                <td>{res?.variationName}</td>
                                                <td>{res?.quantity}</td>
                                                <td>Rp{res?.subTotal}</td>
                                                <td>{res?.statusOrder === 0 ? 'Keep' : 'Dropship'}</td>
                                                <td>{res?.paymentStatus === 0 ? 'Belum Lunas' : 'Lunas'}</td>
                                                <td>{transactionStatus.find(item => item.value === res?.transactionStatus.toString())?.label}</td>
                                                <td>{res?.note}</td>
                                                <td>{res?.senderName}</td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>
                            }
                        </table>

                        <div style={{ display: "none" }}>
                            <table id='table' className='table' ref={tableRef}>
                                <thead>
                                    <tr className='mb-3' >
                                        <th colSpan={100} >
                                            <h4 >Laporan Data Pesanan</h4>
                                        </th>
                                    </tr>
                                </thead>

                                <thead className="">
                                    <tr>
                                        {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                        <th scope="col">No</th>
                                        <th scope="col">Tanggal pesanan</th>
                                        <th scope="col">Pembeli</th>
                                        <th scope="col">Type Customer</th>
                                        <th scope="col">SO Number</th>
                                        <th scope="col">Kode produk</th>
                                        <th scope="col">Produk</th>
                                        <th scope="col">Varian</th>
                                        <th scope="col">QTY</th>
                                        <th scope="col">Subtotal</th>
                                        <th scope="col">Status Pesanan</th>
                                        <th scope="col">Status Bayar</th>
                                        <th scope="col">Status Transaksi</th>
                                        <th scope="col">Catatan SO</th>
                                        <th scope="col">nama pengirim</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((res: any, i: number) => (
                                            <tr key={i}>
                                                {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                                <td>
                                                    {
                                                        values.skip === 0 ?
                                                            i + 1
                                                            :
                                                            i + 1 + (values.page - 1) * parseInt(values.take)
                                                    }
                                                </td>
                                                <td>{moment(res.orderDate).format('DD/MM/YYYY')}</td>
                                                <td>{res?.customerName}</td>
                                                <td>
                                                    {
                                                        res?.type === 1 ? 'Stockist' :
                                                            res?.type === 2 ? 'Reseller' :
                                                                res?.type === 3 ? 'Admin' :
                                                                    res?.type === 4 ? 'Admin Stockist' :
                                                                        res?.type === 0 ? 'End Customer' : null
                                                    }
                                                </td>
                                                <td>{res?.soNumber}</td>
                                                <td>{res?.productCode}</td>
                                                <td>{res?.productName}</td>
                                                <td>{res?.variationName}</td>
                                                <td>{res?.quantity}</td>
                                                <td>Rp{res?.subTotal}</td>
                                                <td>{res?.statusOrder === 0 ? 'Keep' : 'Dropship'}</td>
                                                <td>{res?.paymentStatus === 0 ? 'Belum Lunas' : 'Lunas'}</td>
                                                <td>{transactionStatus.find(item => item.value === res?.transactionStatus.toString())?.label}</td>
                                                <td>{res?.note}</td>
                                                <td>{res?.senderName}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="d-flex justify-content-end">
                        {/* <Pagination current={values.page} onChange={handlePagination} total={total} /> */}
                        <Pagination
                            activePage={values.page}
                            itemClass='item'
                            itemsCountPerPage={parseInt(values.take)}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePagination}
                            prevPageText='⟨ PREVIOUS'
                            itemClassPrev='prev'
                            itemClassNext='next'
                            nextPageText='NEXT ⟩'
                            hideFirstLastPages
                        />
                    </div>

                </div>
            </div>
        </Container>
    )
}
