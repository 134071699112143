import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { AdminListModel, CreateWarehouseSettingAdminModel, UpdateWarehouseSettingModel, WarehouseSettingAdminModel, WarehouseSettingModel } from "./settingWarehouse.model";
import SettingWarehouseApi from "./settingWarehouse.api";


export interface SettingWarehouseSlice {
 resellerSettingList? : WarehouseSettingModel[];
 stockistSettingList? : WarehouseSettingModel[];
 customerSettingList? : WarehouseSettingModel[];
 adminSettingList? : WarehouseSettingAdminModel[];
 adminList? : AdminListModel[];
 isLoading?: boolean;
 error?: ApiErrorResponse<any>;
 status?: string;
}

export const getResellerWarehouseSetting = createAsyncThunk(
 "getResellerWarehouseSettingState/getResellerWarehouseSetting",
 async (_: string | undefined = undefined,{ rejectWithValue }) => {
   try {
     return await SettingWarehouseApi.getResellerExpeditionSettingList();
   } catch (e) {
     return rejectWithValue(e as ApiErrorResponse<any>);
   }
 }
);

export const getStockistWarehouseSetting = createAsyncThunk(
  "getStockistWarehouseSettingState/getStockistWarehouseSetting",
  async (_: string | undefined = undefined,{ rejectWithValue }) => {
    try {
      return await SettingWarehouseApi.getStockistExpeditionSettingList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

 export const getEndCustomerWarehouseSetting = createAsyncThunk(
  "getEndCustomerWarehouseSettingState/getEndCustomerWarehouseSetting",
  async (_: string | undefined = undefined,{ rejectWithValue }) => {
    try {
      return await SettingWarehouseApi.getEndCustomerExpeditionSettingList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

 export const getAdminWarehouseSetting = createAsyncThunk(
  "getAdminWarehouseSettingState/getAdminWarehouseSetting",
  async (_: string | undefined = undefined,{ rejectWithValue }) => {
    try {
      return await SettingWarehouseApi.getAdminSettingList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

 export const getAdminList = createAsyncThunk(
  "getAdminListState/getAdminList",
  async (_: string | undefined = undefined,{ rejectWithValue }) => {
    try {
      return await SettingWarehouseApi.getAdminList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

export const updatePartnerWarehouseSetting = createAsyncThunk(
  "updatePartnerWarehouseSettingState/updatePartnerWarehouseSetting",
  async (model : UpdateWarehouseSettingModel,{ rejectWithValue }) => {
    try {
      return await SettingWarehouseApi.updatePartnerWarehouseSetting(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

 export const deleteAdminWarehouseSetting = createAsyncThunk(
  "deleteAdminWarehouseSettingState/deleteAdminWarehouseSetting",
  async (id : string,{ rejectWithValue }) => {
    try {
      return await SettingWarehouseApi.deleteAdminWarehouseSetting(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

 export const createAdminWarehouseSetting = createAsyncThunk(
  "createAdminWarehouseSettingSettingState/createAdminWarehouseSetting",
  async (model : CreateWarehouseSettingAdminModel,{ rejectWithValue }) => {
    try {
      return await SettingWarehouseApi.createAdminWarehouseSetting(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

const SettingWarehouseSlice = createSlice({
 name: "SettingWarehouseState",
 initialState: {} as SettingWarehouseSlice,
 reducers: {
   resetStatus: (state) => {
     state.status = "";
   },
 },
 extraReducers: (builder) => {

  builder.addCase(getResellerWarehouseSetting.pending, (state) => {
    state.isLoading = true;
    state.status = "";
  });
  builder.addCase(getResellerWarehouseSetting.fulfilled,(state, { payload }) => {
    state.isLoading = false;
    state.resellerSettingList = payload;
  });
  builder.addCase(getResellerWarehouseSetting.rejected,(state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed";
  });

  builder.addCase(getStockistWarehouseSetting.pending, (state) => {
    state.isLoading = true;
    state.status = "";
  });
  builder.addCase(getStockistWarehouseSetting.fulfilled,(state, { payload }) => {
    state.isLoading = false;
    state.stockistSettingList = payload;
  });
  builder.addCase(getStockistWarehouseSetting.rejected,(state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed";
  });

  builder.addCase(getEndCustomerWarehouseSetting.pending, (state) => {
    state.isLoading = true;
    state.status = "";
  });
  builder.addCase(getEndCustomerWarehouseSetting.fulfilled,(state, { payload }) => {
    state.isLoading = false;
    state.customerSettingList = payload;
  });
  builder.addCase(getEndCustomerWarehouseSetting.rejected,(state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed";
  });

  builder.addCase(getAdminList.pending, (state) => {
    state.isLoading = true;
    state.status = "";
  });
  builder.addCase(getAdminList.fulfilled,(state, { payload }) => {
    state.isLoading = false;
    state.adminList = payload;
  });
  builder.addCase(getAdminList.rejected,(state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed-get-admin-list";
  });

  builder.addCase(getAdminWarehouseSetting.pending, (state) => {
    state.isLoading = true;
    state.status = "";
  });
  builder.addCase(getAdminWarehouseSetting.fulfilled,(state, { payload }) => {
    state.isLoading = false;
    state.adminSettingList = payload;
  });
  builder.addCase(getAdminWarehouseSetting.rejected,(state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed";
  });

  builder.addCase(deleteAdminWarehouseSetting.pending, (state) => {
    state.isLoading = true;
    state.status = "";
  });
  builder.addCase(deleteAdminWarehouseSetting.fulfilled,(state, { payload }) => {
    state.isLoading = false;
    state.status = "success-delete-admin";
  });
  builder.addCase(deleteAdminWarehouseSetting.rejected,(state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed-delete-admin";
  });

  builder.addCase(createAdminWarehouseSetting.pending, (state) => {
    state.isLoading = true;
    state.status = "";
  });
  builder.addCase(createAdminWarehouseSetting.fulfilled,(state, { payload }) => {
    state.isLoading = false;
    state.status = "success-create-admin-setting";
  });
  builder.addCase(createAdminWarehouseSetting.rejected,(state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed-create-admin-setting";
  });

 builder.addCase(updatePartnerWarehouseSetting.pending, (state) => {
  state.isLoading = true;
  state.status = "";
});
builder.addCase(
  updatePartnerWarehouseSetting.fulfilled,
  (state, { payload }) => {
    state.isLoading = false;
    state.status = "success-update";
  }
);
builder.addCase(
  updatePartnerWarehouseSetting.rejected,
  (state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed-update";
  }
);
 },
});

export const { resetStatus } = SettingWarehouseSlice.actions;
export default SettingWarehouseSlice.reducer;
