import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsClipboard, BsEyeFill } from "react-icons/bs";
import { FaFileExport, FaPrint, FaSearch } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../app/store";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { getCountSalesOrder, getListSalesOrderByPartner } from "../adminStockist.reducer";
import { CountSalesOrder, ListSalesOrder } from "../adminStockist.model";
import moment from "moment";
import {MAX_PAGING_OPTIONS} from "../../../constants";

export default function AdminStockistDetail() {
  let { id } = useParams<{ id: string }>();

  const history = useHistory()
  const dispatch = useDispatch()

  const countSalesOrder = useSelector(
    (state: RootState) => state.adminStockist.countSalesOrder ?? ({} as CountSalesOrder)
  );

  const listSalesOrder = useSelector(
    (state: RootState) => state.adminStockist.listSalesOrder as ListSalesOrder[]
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredListSalesOrder, setFilteredListSalesOrder] = useState<ListSalesOrder[]>([]);
  // const [isListHistoryLoading, setIsListHistoryLoading] = useState(false);
  // const [listHistoryTotal, setListHistoryTotal] = useState<number>(0);
  // const [listHistoryPerPage, setListHistoryPerPage] = useState<number>(10);


  useEffect(() => {
    if (listSalesOrder) {
      setFilteredListSalesOrder(
        listSalesOrder.filter((item) => (
          item.soNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.deliveryName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.recipientName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.paymentDate.toLowerCase().includes(searchValue.toLowerCase())
        ))
      )
    }
  }, [listSalesOrder, searchValue])

  useEffect(() => {
    // let id = window.location.pathname.split('/')[2]
    dispatch(getCountSalesOrder(id))
    dispatch(getListSalesOrderByPartner(id))
  }, [])

  // const handleListHistoryPerRowsChange = (newPerPage: number, page: number) => {
  //   setIsListHistoryLoading(true);
  //   setListHistoryPerPage(newPerPage);
  //   dispatch(getListSalesOrderByPartner({
  //     partnerId: id,
  //     skip: page,
  //     take: newPerPage,
  //   }))
  // };
  //
  // const handleListHistoryPageChange = (page: any) => {
  //   // fetchData((page - 1) * perPage, perPage);
  //   dispatch(getListSalesOrderByPartner({
  //     partnerId: id,
  //     skip: (page - 1) * listHistoryPerPage,
  //     take: listHistoryPerPage,
  //   }))
  // };

  const moneyParser = (value: any) => {
    let valueString = value.toString()
    let valueList = valueString.split('')
    let resultList: any = []
    valueList.reverse().map((c: any, i: number) => {
      resultList.push(c)
      if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
        resultList.push('.')
      }
    })
    let result = resultList.reverse().join('')
    return "Rp" + result + ",00"
  }

  return (
    <div>
      <Button
        className="float-right"
        style={{ width: 130, marginLeft: 10 }}
        variant="outline-info"
        onClick={() => {
          history.goBack()
        }}>
        Cancel
      </Button>{" "}
      <Form.Group>
        <Form.Label className="title">Admin Stockist Details</Form.Label>
      </Form.Group>

      <Card>
        <Card.Body>
          <div>
            <span className="float-right">
              Total Order: <b>{countSalesOrder.totalUnPaid + countSalesOrder.totalPaid}</b>
            </span>
          </div>
          <br />
          <hr />
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <b>Unpaid Order</b>
                  <br />
                  <br />
                  <div className="detail-value">
                    <FiShoppingCart />
                    <div>
                      <span>Keep</span>
                      <span className="float-right">{countSalesOrder.unPaidKeep}</span>
                    </div>
                  </div>
                  <div className="detail-value">
                    <BsClipboard />
                    <div>
                      <span>Dropship</span>
                      <span className="float-right">{countSalesOrder.unPaidDropship}</span>
                    </div>
                  </div>
                  <br />
                  <div>
                    <span>Total Unpaid Order</span>
                    <span className="float-right">{countSalesOrder.totalUnPaid}</span>
                  </div>
                  <hr />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <b>Paid Order</b>
                  <br />
                  <br />
                  <div className="detail-value">
                    <FiShoppingCart />
                    <div>
                      <span>Keep</span>
                      <span className="float-right">{countSalesOrder.paidKeep}</span>
                    </div>
                  </div>
                  <div className="detail-value">
                    <BsClipboard />
                    <div>
                      <span>Dropship</span>
                      <span className="float-right">{countSalesOrder.paidDropship}</span>
                    </div>
                  </div>
                  <br />
                  <div>
                    <span>Total Paid Order</span>
                    <span className="float-right">{countSalesOrder.totalPaid}</span>
                  </div>
                  <hr />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div className="detail-info-text">
                <span>
                  * Calculation of the order amount based on the total order quantity
                </span>
                <span>
                  * If you want to see details of the order quantity, please click the available order quantity
                </span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <span>
            All Data Orders
          </span>
          <hr />
          <div className="table-head">
            <div>
              <InputGroup style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputGroup>
            </div>
          </div>
          <hr />
          <DataTable
            columns={[
              {
                name: "ORDER ID",
                selector: "soNumber",
                sortable: true,
              },
              {
                name: "CUSTOMER ID",
                selector: "customerId",
                sortable: true,
              },
              {
                name: "ORDER DATE",
                selector: (d: any) => (
                  <span>{moment(new Date(d.orderDate)).format('DD/MM/YYYY HH:mm')}</span>
                ),
                sortable: true,
              },
              {
                name: "ORDER TYPE",
                selector: "statusOrderName",
                sortable: true,
              },
              {
                name: "FROM",
                selector: (d: any) => (
                  <span>{d.deliveryName ? d.deliveryName : "Emory"}</span>
                ),
                sortable: true,
              },
              {
                name: "TO",
                selector: "recipientName",
                sortable: true,
              },
              {
                name: "TOTAL",
                selector: (d: any) => (
                  <span>{moneyParser(d.grandTotal)}</span>
                ),
                sortable: true,
              },
              {
                name: "PAYMENT STATUS",
                selector: (d: ListSalesOrder) => (
                  <p style={{ margin: 10 }}>
                    {d.paymentStatus === 0 && <span className={"custom-badge-danger"}>Unpaid</span>}
                    {d.paymentStatus === 1 && <span className={"custom-badge-success"}>Paid</span>}
                  </p>
                ),
                sortable: true,
              },
              {
                name: "PAYMENT DATE",
                selector: (d: any) => (
                  <span>{d.paymentDate === "0001-01-01 00:00:00.0000000" ? "-" : moment(new Date(d.paymentDate)).format('DD/MM/YYYY HH:mm')}</span>
                ),
                sortable: true,
              },
              {
                name: "ACTION",
                selector: (d: ListSalesOrder) => (
                  <Link
                    to={{
                      pathname: `/customer-so-detail/${d.salesOrderId}`,
                    }}>
                    <Button className="detail-button" size="sm">Detail</Button>
                  </Link>
                ),
                sortable: true,
              }
            ]}
            data={filteredListSalesOrder}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            customStyles={TableCustomStyles}
          />
        </Card.Body>
      </Card>
    </div>
  )
}