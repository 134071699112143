import axios from "axios";
import { endpoint } from "../../constants";
import { ChangeWalletStatusModel, CreateRequestWalletModel, FilterModel, WalletModel } from "./wallet.model";

export default class WalletApi {

 static getWalletRequestListWithPaging(model: FilterModel) {
  return axios
    .post<any>(endpoint + "/api/WalletRequest/GetListWithFilter", model)
    .then((res) => res.data.data);
}

static requestWallet(model: CreateRequestWalletModel) {
 return axios
   .post<any>(
     endpoint + "/api/WalletRequest/Create",
     model
   )
   .then((res) => res.data.data);
}

static changeStatusWallet(model: ChangeWalletStatusModel) {
  return axios
    .post<any>(endpoint + "/api/WalletRequest/changeStatus", model)
    .then((res) => res.data.data);
 }

static updateWallet(model: WalletModel) {
 return axios
   .post<any>(endpoint + "/api/WalletRequest/update", model)
   .then((res) => res.data.data);
}

static deleteWallet(id: string) {
 return axios
   .post<any>(endpoint + "/api/WalletRequest/Delete?Id=" + id)
   .then((res) => [
     { response: res.data.data },
   ]);
}

} 