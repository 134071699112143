import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";
import "../paymentMethod.css";

import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../../app/store";
import { convertBase64 } from "../../../../components/Library/Base64";

import {
  deleteData,
  getBankList,
  getList,
  getListWithPaging,
  getRoleList,
  getSingleItem,
  updateData,
  uploadPhoto,
} from "../paymentMethod.reducer";
import { PaymentMethodModel, UserLevelModel } from "../paymentMethod.model";
import { DragSwitch } from "react-dragswitch";
import Select from "antd/lib/select";
import Dropzone from "react-dropzone";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../../constants";
import upload from "./../../../../assets/vector/upload.png";  
import { setId } from "../../../login/user.reducer";
import DeleteMessage from "../../../../components/DeleteMessage";

const PaymentMethodTable = ({
  data = {} as PaymentMethodModel,
  isEdit = false,
  ...props
}: PaymentMethodProps) => {
  const dispatch = useDispatch();

  const roleListState = useSelector(
    (state: RootState) => state.paymentMethod.roleList ?? []
  );

  const PaymentMethodState = useSelector(
    (state: RootState) => state.paymentMethod.list ?? []
  );

  const ImagePathState = useSelector(
    (state: RootState) => state.paymentMethod.imagePath ?? ""
  );

  const PaymentMethodSingleDataState = useSelector(
    (state: RootState) =>
      state.paymentMethod.single ?? ({} as PaymentMethodModel)
  );

  const modifiedDateState = useSelector(
    (state: RootState) => state.paymentMethod.modifiedDate ?? ""
  );

  const BankListState = useSelector(
    (state: RootState) => state.paymentMethod.bankList ?? []
  );

  const statusState = useSelector(
    (state:RootState) => state.paymentMethod.status ?? ""
  )

  const totalRowState = useSelector(
    (state: RootState) => state.paymentMethod.totalRow ?? 0 
  )

  useEffect(() => {
    dispatch(getRoleList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [id, setId] = useState("")
  const [name, setName] = useState("");
  const [noRek, setnoRek] = useState("");
  const [ownerName, setOwnerName] = useState("");

  const [type, setType] = useState<Array<string>>([]);
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);
  const [bankId, setBankId] = useState("");
  const [image, setImage] = useState("");

  const [fileFormat, setFileFormat] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const [filterName, setFilterName] = useState("")
  const [filterNoRek, setFilterNoRek] = useState("")
  const [filterOwner, setFilterOwner] = useState("")
  const [filterIsActive, setFilterIsActive] = useState<boolean | null>(null);
  const [filterTempIsActive, setFilterTempIsActive] = useState(2);

  const [selectedId, setSelectedId] = useState("")

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const filteredItems = PaymentMethodState.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const onAddPaymentMethod = () => {
    handleShow();
    setName("");
    setnoRek("");
    setOwnerName("");
    setBankId("");
    setImage("");
    setType([] as string[]);
  };

  const onResetData = () => {
    handleClose();
    setId("")
    setName("");
    setnoRek("");
    setOwnerName("");
    setBankId("");
    setImage("");
    setType([] as string[]);
  }

  const handleImage = (files: any) => {
    setFileFormat("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImage(result);
      });
    } else {
      console.log("error");
    }
  };

  const onStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "0") {
      setFilterIsActive(true);
      setFilterTempIsActive(0);
    } else if (e.target.value === "1") {
      setFilterIsActive(false);
      setFilterTempIsActive(1);
    } else {
      setFilterIsActive(null);
      setFilterTempIsActive(2);
    }
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("")
    setFilterNoRek("")
    setFilterOwner("")
    setFilterIsActive(null)
    setFilterTempIsActive(2)

    dispatch(
      getListWithPaging({
        name: "",
        noRek:"",
        ownerName: "",
        isActive: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        name: filterName,
        noRek:filterNoRek,
        ownerName: filterOwner,
        isActive: filterIsActive,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [PaymentMethodState]);

  useEffect(() => {
    fetchData(0, perPage);
    // dispatch(getListCity());
  }, []);


  const columns = [
    {
      name: "PAYMENT METHOD",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "BANK NAME",
    //   selector: "name",
    //   sortable: true,
    // },
    {
      name: "NO. REKENING",
      selector: "noRek",
      sortable: true,
    },
    {
      name: "ATAS NAMA",
      selector: "ownerName",
      sortable: true,
    },
    {
      name: "IMAGE",
      selector: (d: { image: string }) => (
        <p style={{ margin: 5 }}>
          {d.image !== "" && d.image !== null ? (
            <img
              src={d.image !== "" ? d.image : "none"}
              style={{ width: 50 }}
              // onClick={() => {
              //   setSelectedImage(endpoint + d.image);
              //   setVisible(true);
              // }}
            />
          ) : (
            <b>-</b>
            // <img src={noImage} style={{ width: 50 }} />
          )}
        </p>
      ),
      sortable: true,
    },
    // {
    //   name: "USER LEVEL",
    //   selector: (d: { role: UserLevelModel[] }) => (
    //     <p style={{ margin: 10 }}>
    //       {d.role.map((value, index) => {
    //         return (
    //           <>
    //             <p>
    //               {/* {value === "0" && "Customer"}
    //               {value === "1" && "Stocksit"}
    //               {value === "2" && "customer"} */}
    //             </p>
    //           </>
    //         );
    //       })}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && (
            <span className={"custom-badge-success"}>Active</span>
          )}
          {d.isActive === false && (
            <span className={"custom-badge-secondary"}>Inactive</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any; name: any }) => (
        <>
          <span
            onClick={() => {
              setShow(true);
              onClickEditbutton(d.id);
            }}
          >
            <img src={edit}></img>
          </span>
          {"    "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onDelete(d.id);

              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setId(data.id)
      setName(data.name);
      setIsActive(data.isActive);
      setnoRek(data.noRek);
      setOwnerName(data.ownerName);
      setType(data.role);
      setBankId(data.bankId + "&" + data.name);
      setImage(data.image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (image != null && image != "") {
      dispatch(
        uploadPhoto({
          file_name: name + "." + fileFormat,
          base64url: image.split(",")[1],
          imagepath: "paymentMethod",
          location: "paymentMethod/",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, fileFormat]);

  useEffect(() => {
    // dispatch(getList());
    dispatch(getBankList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDateState]);

  useEffect(() => {
    if(statusState !== ""){

      if(statusState === "success"){
        onResetData();
      }

    }
  }, [statusState])
  

  const onEdit = () => {
    dispatch(
      updateData({
        // id: PaymentMethodSingleDataState.id,
        id,
        name: name,
        noRek,
        ownerName,
        type,
        isActive,
        bankId: bankId.split("&")[0],
        image
      } as PaymentMethodModel)
    );
  };

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
      setShowAlert(false);
      fetchData(0, perPage);
    }
  };

  const onClickEditbutton = (id: string) => {
    dispatch(getSingleItem(id));
  };

  const handleChange = (e: any) => {
    setType(e);
  };

  const handleBankChange = (e: any) => {
    setBankId(e);
    setName(e.toString().split("&")[1]);
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">
              Master Payment Method
            </Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="float-right"
            variant="info"
            onClick={onAddPaymentMethod}
            style={{ width: 200 }}
          >
            + Add Payment Method
          </Button>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  No. Rekening
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterNoRek}
                    onChange={(e) => {
                      setFilterNoRek(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Owner Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterOwner}
                    onChange={(e) => {
                      setFilterOwner(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onStatusChange}
                    value={filterTempIsActive}
                  >
                    <option value={2}>All</option>
                    <option value={0}>Active</option>
                    <option value={1}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>


        <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>


          {/* <Row>
            <Col md={4}>
              <InputGroup
                className="mb-3 padding-filter"
                style={{ width: 330 }}
              >
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row> */}

<hr/>
          {/* <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          />     */}
          <DataTable
            columns={columns}
            data={PaymentMethodState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />


          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onResetData}
          >
            <Modal.Header closeButton>
              <div style={{ fontWeight: "bold" }}>Add Payment Method</div>
            </Modal.Header>
            <Modal.Body>
              <Form
                id="paymentMethodForm"
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    e.preventDefault();
                    if (id === undefined || id === "") {
                      props.onSubmit(
                        name,
                        noRek,
                        ownerName,
                        type,
                        isActive,
                        bankId.split("&")[0],
                        image
                      );
                      setName("");
                      setType([]);
                    } else {
                      onEdit();
                      setName("");
                      setType([]);
                    }
                  }
                  setValidated(true);
                }}
              >
                {/* <Form.Group className="input-padding">
                    <Form.Label>Payment Method Name</Form.Label>
                    <Form.Control type="text" required onChange={onNameChange} value={name} />
                  </Form.Group> */}

                <Form.Group className="input-padding">
                  <Form.Label>Bank</Form.Label>
                  <Select
                    value={bankId}
                    onChange={handleBankChange}
                    style={{ width: "100%" }}
                  >
                    {BankListState.map((item) => (
                      <Select.Option value={item.id + "&" + item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>No. Rekening</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    onChange={(e) => setnoRek(e.target.value)}
                    value={noRek}
                  />
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>Atas Nama</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    onChange={(e) => setOwnerName(e.target.value)}
                    value={ownerName}
                  />
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>User Level</Form.Label>
                  <Select
                    mode="multiple"
                    value={type}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  >
                    <Select.Option value={"0"}>Customer</Select.Option>
                    <Select.Option value={"1"}>Stockist</Select.Option>
                    <Select.Option value={"2"}>Reseller</Select.Option>
                    <Select.Option value={"3"}>Admin</Select.Option>

                  </Select>
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>Image</Form.Label>
                  <Col>
                    <Dropzone
                      onDrop={(acceptedFiles) => handleImage(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone file-upload-frame w-100">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="text-center file-upload-label">
                              <div>
                                {(image !== "" ) && (
                                  <img
                                    src={
                                      image !== ""
                                        ? image
                                        : endpoint + data.image
                                    }
                                    className="file-upload-image mb3"
                                  />
                                )}

                                {image === ""  && (
                                    <>
                                      <div
                                        className="file-upload-image mb3"
                                        style={{ paddingTop: 50 }}
                                      >
                                        <img src={upload} alt="upload" />
                                        <br />
                                        <span style={{ color: "black" }}>
                                          Select File
                                        </span>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Form.Group>

                {isEdit ? (
                  <>
                    <Form.Group className="input-padding">
                      <Form.Label>Active Status</Form.Label>
                      <br />
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Form.Group>
                  </>
                ) : (
                  <></>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-info"
                style={{ width: 100 }}
                onClick={onResetData}
              >
                Cancel
              </Button> 
              <Button
                form="paymentMethodForm"
                variant="info"
                type="submit"
                style={{ width: 100 }}
                onClick={handleClose}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export interface PaymentMethodProps {
  onSubmit: (
    name: string,
    noRek: string,
    ownerName: string,
    type: string[],
    isActive: boolean,
    bankId: string,
    image: string,
  ) => void;
  data: PaymentMethodModel;
  isEdit: boolean;
}

export default PaymentMethodTable;
