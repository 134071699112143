import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import StockOpnameApi from "./stockOpname.api";
import { CreateStockOpnameModel, CreateStockOpnameRequestModel, FilterModel, ProductModel, SingleStockOpnameModel, StockModel, StockOpnameFilterModel, StockOpnameModel, WarehouseModel, WarehouseStockFilterModel } from "./stockOpname.model";

export interface StockOpnameSlice {
    list? : StockOpnameModel[]
    single? : SingleStockOpnameModel;
    warehouseList? : WarehouseModel[]
    productList? : ProductModel[]
    warehouseStockList? : StockModel[]
    totalRow? : number;
    totalWarehouseStockRow? : number;
    exportResponse? : string;
    isLoading? : boolean
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getStockOpnamelistWithPaging =  createAsyncThunk(
    'getStockOpnamelistWithPagingState/getStockOpnamelistWithPaging',
    async (filter: StockOpnameFilterModel, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.getListWithPagination(filter);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleDataStockOpname =  createAsyncThunk(
    'getSingleDataStockOpnameState/getSingleDataStockOpname',
    async (id:string, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.getSingleDataStockOpname(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getWarehouse =  createAsyncThunk(
    'getWarehouseState/getWarehouse',
    async (_:string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.getWarehouseList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getProductByWarehouse =  createAsyncThunk(
    'getProductByWarehouseState/getProductByWarehouse',
    async (warehouseId:string, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.getProductByWarehouse(warehouseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getWarehouseStockByWarehouse =  createAsyncThunk(
    'getWarehouseStockByWarehouseState/getWarehouseStockByWarehouse',
    async (warehouseId:string, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.getWarehouseStockByWarehouse(warehouseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getWarehouseStockByWarehouseWithPaging =  createAsyncThunk(
    'getWarehouseStockByWarehouseWithPagingState/getWarehouseStockByWarehouseWithPaging',
    async (model:WarehouseStockFilterModel, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.getWarehouseStockByWarehouseWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const createStockOpname =  createAsyncThunk(
    'createStockOpnameState/createStockOpname',
    async (model:CreateStockOpnameModel, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.createStockopname(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const exportToExcel =  createAsyncThunk(
    'stockOpnameExportToExcelState/stockOpnameExportToExcel',
    async (model:FilterModel, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.ExportToExcel(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const createWithProduct =  createAsyncThunk(
    'stockOpnameCreateState/stockOpnameCreate',
    async (model:CreateStockOpnameRequestModel, { rejectWithValue }) => {
        try {
            return await StockOpnameApi.createWithProduct(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const StockOpnameSlice = createSlice({
    name: 'StockOpnameState',
    initialState: {} as StockOpnameSlice,
    reducers: {
        resetSingle: (state) => {
            state.single = {} as SingleStockOpnameModel
            state.status = ""
            state.warehouseStockList = []
            state.exportResponse = ""
        },
        resetStatus: (state) => {
            state.status = ""
        },
        resetExportLink: (state) => {
            state.exportResponse = ""
        },
        resetProductList : (state) => {
            state.warehouseStockList = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getStockOpnamelistWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStockOpnamelistWithPaging.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getStockOpnamelistWithPaging.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSingleDataStockOpname.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSingleDataStockOpname.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getSingleDataStockOpname.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getWarehouse.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWarehouse.fulfilled, (state, { payload }) => {
            state.warehouseList = payload;
            state.isLoading = false;
        });
        builder.addCase(getWarehouse.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getProductByWarehouse.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProductByWarehouse.fulfilled, (state, { payload }) => {
            state.productList = payload;
            state.isLoading = false;
        });
        builder.addCase(getProductByWarehouse.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getWarehouseStockByWarehouse.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWarehouseStockByWarehouse.fulfilled, (state, { payload }) => {
            state.warehouseStockList = payload;
            state.isLoading = false;
        });
        builder.addCase(getWarehouseStockByWarehouse.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getWarehouseStockByWarehouseWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWarehouseStockByWarehouseWithPaging.fulfilled, (state, { payload }) => {
            state.warehouseStockList = payload.list;
            state.totalWarehouseStockRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getWarehouseStockByWarehouseWithPaging.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(createStockOpname.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createStockOpname.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status="success"
        });
        builder.addCase(createStockOpname.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(createWithProduct.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createWithProduct.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status="success"
        });
        builder.addCase(createWithProduct.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status="failed"
        });

        builder.addCase(exportToExcel.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(exportToExcel.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            // state.exportResponse = payload
            state.status="success-export"
        });
        builder.addCase(exportToExcel.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    }
})

export const { resetSingle, resetStatus,resetProductList,resetExportLink } = StockOpnameSlice.actions;
export default StockOpnameSlice.reducer;