import WarehouseTable from "../components/WarehouseTable";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { RootState } from "../../../../app/store";
import { getList } from "../warehouse.reducer";
import { useEffect } from "react";

const WarehouseTablePage = () => {
  const dispatch = useDispatch();
  const State = useSelector((state: RootState) => state.warehouse.name);

  useEffect(() => {
    // dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  return (
    <Container className="content-body" fluid>
      <WarehouseTable />
    </Container>
  );
};

export default WarehouseTablePage;
