import axios from "axios";
import { endpoint } from "../../../constants";
import { UOMModel } from "./UOM.model";

export default class UOMApi {
  static createData(model: UOMModel) {
    return axios.post<any>(endpoint + "/api/UOM/Create", model).then((res) => res.data.data);
  }

  static getList() {
    return axios.get<any>(endpoint + "/api/UOM/GetList").then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/UOM/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: UOMModel) {
    return axios.post<any>(endpoint + "/api/UOM/update", model).then((res) => res.data.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/UOM/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static getListProduct() {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListWithProduct")
      .then((res) => res.data.data);
  }
}
