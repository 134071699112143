import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getBroadcastByPartner, getBroadcastList, getUserBroadcastAll, postBroadcast, postImageUpload } from './templateBroadcast.api'


export interface TemplateBroadcastState {
  listBroadcast : any,
  listUser : any,
  loading : boolean,
  loadingImage : boolean,
  listBroadcastByPartner : any
}
const initialState: TemplateBroadcastState = {
  listBroadcast : [],
  listUser : [],
  loading : false,
  loadingImage : false,
  listBroadcastByPartner : []
}

export const templateBroadcastSlice = createSlice({
    name: 'template broadcast',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
        // },
        // decrement: (state) => {
        // state.value -= 1
        // },
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        // state.value += action.payload
        // },
    },
    extraReducers : (builder) => {
        // get user all
        builder.addCase(getUserBroadcastAll.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getUserBroadcastAll.fulfilled, (state, action) => {
            state.loading = false
            state.listUser = action.payload.data
        })
        builder.addCase(getUserBroadcastAll.rejected, (state, action) => {
            state.loading = false
        })
        //get list broadcast
        builder.addCase(getBroadcastList.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getBroadcastList.fulfilled, (state, action) => {
            state.loading = false
            state.listBroadcast = action.payload.data
        })
        builder.addCase(getBroadcastList.rejected, (state, action) => {
            state.loading = false
        })
        //list broadcast by partner
        builder.addCase(getBroadcastByPartner.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getBroadcastByPartner.fulfilled, (state, action) => {
            state.loading = false
            state.listBroadcastByPartner = action.payload
        })
        builder.addCase(getBroadcastByPartner.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        builder.addCase(postBroadcast.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postBroadcast.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postBroadcast.rejected, (state, action) => {
            state.loading = false
        })

        // //post
        builder.addCase(postImageUpload.pending, (state, action) => {
          state.loadingImage = true
        })
        builder.addCase(postImageUpload.fulfilled, (state, action) => {
            state.loadingImage = false
        })
        builder.addCase(postImageUpload.rejected, (state, action) => {
            state.loadingImage = false
        })
        // //delete
        // builder.addCase(deleteCompany.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(deleteCompany.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(deleteCompany.rejected, (state, action) => {
        //     state.loading = false
        // })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default templateBroadcastSlice.reducer