import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Container, Form } from "react-bootstrap";
import uploadImage from '../../../../assets/vector/upload.png'
import { Link, useParams, useHistory } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
// import { getSlideShowDetail, postImageUpload, postSlideShow, updateSlideShow } from '../formatNota.api';
import Swal from 'sweetalert2';
import { convertBase64 } from '../../../../components/Library/Base64';
import {endpoint} from '../../../../constants'
import { RootState } from '../../../../app/store';
import Switch from "react-switch";
import { getFormatNotaDetail, postImageUpload, updateFormatNota } from '../formatNota.api';


export default function FormFormatNota(props: any) {
    const history = useHistory();
    const { id } = useParams<any>();
    const isEdit = id
    const dispatch = useDispatch()
    // const {loading} = useSelector((state:RootState) => state.slideShow)

    const [values, setValues] = useState<any>({
        "id": id,
        "type": 0,
        "noteFormatName": "",
        "isShowShippingData": false,
        "isShowOrderId": false,
        "isShowEstimatedShipping": false,
        "isShowLogo": false,
        "noteLogo": "",
        "headerNote": "",
        "footerNote": "",
        "isActive": false
      })


    useEffect(() => {
        if (isEdit) {
            getDetail()
        }
    }, [])

    const getDetail = ()=>{
        dispatch<any>(getFormatNotaDetail(id))
        .then(unwrapResult)
        .then((res:any)=>{
            console.log(res)
            setValues({
                ...values,
                ...res
            })
        })
    }
    const handleCancel = ()=> getDetail();

    const handleSwitch = (value:boolean,e:any, id:any)=>{
        setValues({
            ...values,
            [id] : value
        })
    }
    
    const handleChange = (e:any)=>{
        setValues({
            ...values,
            [e.target.name] : e.target.value
        })
    }

    const handleImage = (e:any)=>{
        convertBase64(e.target.files[0], (result: any) => {
            const imageData = result.split(',')
            const base64Final = imageData[1]
            const data = {
                "file_name": e.target.files[0].name,
                "base64url": base64Final,
                "imagepath": "",
                "location": ""
            }
            dispatch<any>(postImageUpload(data))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status === 200) {
                    setValues({
                        ...values,
                        "noteLogo" : endpoint+res.data.imagepath
                    })
                    Swal.fire({
                        icon: 'success',
                        text: 'Image uploaded',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'Error uploading image!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Error uploading image!',
                })
            })
        });
    }

    const handleSubmit = (e:any)=>{
        console.log(values)
        e.preventDefault()
            dispatch<any>(updateFormatNota(values))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Format nota updated',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    .then(()=>{
                        history.push('/setting-format-nota')
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'Format nota cannot updated!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Format nota cannot cannot updated!',
                })
            })
    }
    return (
        <Container className="content-body formFormatNota" fluid>
            <div className="page-title mb-4 title-link">
                <Link to='/setting-format-nota'>
               <FiChevronLeft /> 
               </Link>
               {isEdit? 'Edit':'Add'} Format Nota
            </div>
            <div className="box">
                <div className="titbox">{isEdit? 'Edit':'Add'} Membership</div>
                <form onSubmit={handleSubmit}>
                    <Row className='forms'>
                        <Col md={3}><p>User</p> </Col>
                        <Col md={9}>
                            <Form.Check 
                                className='radiocustom'
                                checked={values.type === 3}
                                inline
                                type='radio'
                                id='user1'
                                label='Admin'
                                value={3}
                            />
                            <br />
                            <Form.Check 
                                className='radiocustom'
                                checked={values.type === 2}
                                inline
                                type='radio'
                                id='user2'
                                value={2}
                                label='Reseller'
                            />
                            <br />
                            <Form.Check 
                                className='radiocustom'
                                checked={values.type === 1}
                                inline
                                type='radio'
                                id='user3'
                                value={1}
                                label='Stockist'
                            />
                            <br />
                            <Form.Check 
                                className='radiocustom'
                                checked={values.type === 0}
                                inline
                                type='radio'
                                id='user4'
                                label='End Customer'
                                value={0}
                            />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Nota Format Name</p> </Col>
                        <Col md={9}>
                            <input type="text" name="noteFormatName" onChange={handleChange} value={values.noteFormatName} placeholder='Nota Format Name' />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Show Data Shipping</p> </Col>
                        <Col md={9}>
                            <Switch onChange={handleSwitch} id='isShowShippingData' checked={values.isShowShippingData} />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Show Order ID</p> </Col>
                        <Col md={9}>
                            <Switch onChange={handleSwitch} id='isShowOrderId' checked={values.isShowOrderId} />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Show Estimation Delivery</p> </Col>
                        <Col md={9}>
                            <Switch onChange={handleSwitch} id='isShowEstimatedShipping' checked={values.isShowEstimatedShipping} />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Show Logo on Nota</p> </Col>
                        <Col md={9}>
                            <Switch onChange={handleSwitch} id='isShowLogo' checked={values.isShowLogo} />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Nota Logo</p> </Col>
                        <Col md={9}>
                            <input type="file" name="" style={{display : 'none'}} id="bgImage" onChange={handleImage} />
                            {
                                false ?
                                'Loading...'
                                :
                                <label htmlFor='bgImage' className="uploadfilebox overflow-hidden">
                                    {
                                        values.noteLogo.length !== 0 ?
                                        <img src={values.noteLogo} alt="upload" className='img-fluid' />
                                        :
                                        <>
                                        <img src={uploadImage} alt="upload" />
                                        Select File
                                        </>
                                    }
                                </label>
                            }
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Header Struk/Nota</p> </Col>
                        <Col md={9}>
                        <textarea rows={3} name="headerNote" value={values.headerNote} onChange={handleChange} />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Footer Struk/Nota</p> </Col>
                        <Col md={9}>
                        <textarea rows={3} name="footerNote" value={values.footerNote} onChange={handleChange} />
                        </Col>
                    </Row>
                    
                    {/* <Row className='forms'>
                        <Col md={3}><p>Description</p> </Col>
                        <Col md={9}>
                        <textarea rows={3} name="link" value={values.link} onChange={handleChange} />
                        </Col>
                    </Row> */}
                    <Row className='forms'>
                        <Col md={3}><p>Is Active</p> </Col>
                        <Col md={9}>
                            <Switch onChange={handleSwitch} id='isActive' checked={values.isActive} />
                        </Col>
                    </Row>
                    <div className="wrap-buttons">
                        <Button type='button' onClick={handleCancel}>Cancel</Button>
                        <Button type='submit'>Save</Button>
                    </div>
                </form>
            </div>
        </Container>
    )
}
