import { createSlice } from '@reduxjs/toolkit'
import { postExportTotalPenjualan, postLaporanPenjualan } from './laporanPenjualan.api'


export interface LaporanPenjualanState {
  loading : boolean,
  // listMembership : any,
}
const initialState: LaporanPenjualanState = {
  loading : false,
  // listMembership : []
}

export const laporanPenjualanSlice = createSlice({
    name: 'laporan penjualan',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        // get for post 
        builder.addCase(postLaporanPenjualan.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postLaporanPenjualan.fulfilled, (state, action) => {
            state.loading = false
            // console.log(action.payload.data)
            // state.listMembership = action.payload.data
        })
        builder.addCase(postLaporanPenjualan.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        builder.addCase(postExportTotalPenjualan.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postExportTotalPenjualan.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postExportTotalPenjualan.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        // builder.addCase(postImageUpload.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(postImageUpload.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(postImageUpload.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //delete
        // builder.addCase(deleteSlideShow.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(deleteSlideShow.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(deleteSlideShow.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //detail
        // builder.addCase(getSlideShowDetail.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(getSlideShowDetail.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(getSlideShowDetail.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //update
        // builder.addCase(updateSlideShow.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(updateSlideShow.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(updateSlideShow.rejected, (state, action) => {
        //     state.loading = false
        // })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default laporanPenjualanSlice.reducer