import axios from "axios";
import { endpoint } from "../../../constants";
import {
  AvailableDayModel,
  DiscountFreeModel,
  UploadImageModel,
  VoucherModel,
} from "./voucher.model";

export default class VoucherApi {
  static create(model: VoucherModel) {
    return axios
      .post<any>(endpoint + "/api/Discount/Create", model)
      .then((res) => res.data.data);
  }

  static getList() {
    return axios
      .get<any>(endpoint + "/api/Discount/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/Discount/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static uploadImage(model: UploadImageModel) {
    return axios
        .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
        .then((res) => res.data);
}

  static update(model: VoucherModel) {
    return axios
      .post<any>(endpoint + "/api/Discount/update", model)
      .then((res) => res.data.data);
  }

  static delete(id: string) {
    return axios
      .post<any>(endpoint + "/api/Discount/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static createAvailableDay(model: AvailableDayModel) {
    return axios
      .post<any>(endpoint + "/api/DiscountAvailableDay/Create", model)
      .then((res) => res.data.data);
  }

  static getAvailableDay(id: string) {
    return axios
      .get<any>(
        endpoint + "​/api/DiscountAvailableDay/GetList?discountId=" + id
      )
      .then((res) => res.data.data);
  }

  static createDiscountFree(model: DiscountFreeModel) {
    return axios
      .post<any>(endpoint + "/api/DiscountAvailableDay/Create", model)
      .then((res) => res.data.data);
  }

  static getListProduct() {
    return axios
      .get<any>(endpoint + "/api/Product/GetList")
      .then((res) => res.data.data);
  }

  static getListCollectionTag() {
    return axios
      .get<any>(endpoint + "/api/ProductCollectionTag/GetList")
      .then((res) => res.data.data);
  }

  static getListUserRoleType() {
    return axios
      .get<any>(endpoint + "/api​/User​/GetAllRoleList")
      .then((res) => res.data.data);
  }

  static getPartnerTypeEnum() {
    return axios
      .get<any>(endpoint + "/api/Partner/GetPartnerTypeEnumList")
      .then((res) => res.data.data);
  }

  static getListVariationByProduct(id: string) {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
      .then((res) => res.data.data);
  }
}
