import axios from 'axios';
import React from 'react'
import { endpoint } from '../../constants';
import { NotificationFilterModel } from './adminPanel.model';

export default class NotificationApi {

    static getAllAdminNotification() {
        return axios
            .get<any>(endpoint + "​/api/Notification/GetAllAdminNotif")
            .then((res) => res.data.data);
    }

    static getAllAdminNotificationWithPaging(model : NotificationFilterModel) {
        return axios
            .get<any>(endpoint + "​/api/Notification/GetAllAdminNotifWithPaging?type="+model.type+"&take="+model.take+"&skip="+model.skip)
            .then((res) => res.data.data);
    }

    static checkUserToken(token : string) {
        return axios
            .post<any>(endpoint + "​/api/Notification/GetAllAdminNotif?token="+token)
            .then((res) => res.data.data);
    }


}