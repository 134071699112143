import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Container } from "react-bootstrap";
import uploadImage from '../../../../assets/vector/upload.png'

import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useParams, useHistory } from 'react-router-dom';
import { createHowToOrder, getHowToOrderDetail, updateHowToOrder } from '../howToOrder.api';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';


export default function FormHowToOrder() {
    const { id } = useParams<any>();
    const history = useHistory();
    const isEdit = id
    const dispatch = useDispatch()

    const mdParser = new MarkdownIt(/* Markdown-it options */);
    
    useEffect(() => {
        if (isEdit) {
            dispatch<any>(getHowToOrderDetail(isEdit))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                setValues({
                    ...values,
                    ...res,
                    // contentHowToOrder : res.contentHowToOrder === null ? '' : ''
                })
            })
        }
    }, [])
    
    
    const [values, setValues] = useState<any>({
        "type": 0,
        "titleHowToOrder": "",
        "contentHowToOrder": "",
        "imageSetting": 0,
        "titleBroadcastTheme": "",
        "toBroadcastTheme": "",
        "messageBroadcastTheme": "",
        "linkIOSStockist": "",
        "linkAndroidStockist": "",
        "linkIOSReseller": "",
        "linkAndroidReseller": "",
        "linkIOSEndCustomer": ""
    })
    
    const handleChange = (e:any)=>{
        setValues({
            ...values,
            [e.target.name] : e.target.value
        })
    }

    const handleEditorChange = (value:any) => {
        console.log('handleEditorChange', value.html, value.text);
        console.log(encodeURIComponent(value.text), 'url encode')
        setValues({
            ...values,
            contentHowToOrder : encodeURIComponent(value.text)
        })
    }
    
    const handleSubmit = (e:any)=>{
        e.preventDefault()
        if (isEdit) {
            dispatch<any>(updateHowToOrder(values))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'How to order updated',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    .then(()=>{
                        history.push('/setting-how-order')
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'How to order cannot updated!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'How to order cannot cannot updated!',
                })
            })
        }else{
            dispatch<any>(createHowToOrder(values))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'How to order saved',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    .then(()=>{
                        history.push('/setting-how-order')
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'How to order cannot saved!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'How to order cannot cannot saved!',
                })
            })
        }
    }

    return (
        <Container className="content-body addHowToOrderForm" fluid>
            <div className="page-title mb-4 title-link">
                <Link to='/setting-how-order'>
               <FiChevronLeft /> 
               </Link>
                {isEdit? 'Edit':'Add'} How to Order
            </div>
            <div className="box">
                <div className="titbox"> {isEdit? 'Edit':'Add'} How to Order</div>
                <form onSubmit={handleSubmit}>
                    <Row className='forms'>
                        <Col md={3}><p>Title</p> </Col>
                        <Col md={9}>
                            <input type="text" 
                            required 
                            placeholder='Title' 
                            name='titleHowToOrder' 
                            value={values.titleHowToOrder} 
                            onChange={handleChange}  />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p className='mb-3'>Content</p> </Col>
                        <MdEditor 
                            className='mx-3'
                            value={decodeURIComponent(values.contentHowToOrder)}
                            style={{ height: '175px' }}
                            renderHTML={text => mdParser.render(text)} 
                            onChange={handleEditorChange} 
                        />
                    </Row>
                    {/* <Row className='forms'>
                        <Col md={3}><p>Image</p> </Col>
                        <Col md={9}>
                            <input type="file" name="" style={{display : 'none'}} 
                            id="bgImage" 
                            onClick={(e:any) => (e.target.value = null)} 
                            onChange={handleFile} />
                            <label htmlFor='bgImage' className="uploadfilebox">
                                {
                                    preview.length === 0 ?
                                    <>
                                        <img src={uploadImage} alt="upload" />
                                        Select File
                                    </>
                                    :
                                    <img src={preview} alt="preview" className='img-fluid' />
                                }
                            </label>
                        </Col>
                    </Row> */}
                    <div className="wrap-buttons">
                        <Button type='button' onClick={()=> history.push('/setting-how-order')}>Cancel</Button>
                        <Button type='submit'>Save</Button>
                    </div>
                </form>
            </div>
        </Container>
    )
}
