import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button, Spinner,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";
import note from "./../../../../assets/vector/note.png";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {
  changeVoidStatus,
  deleteData,
  getList,
  getListWithPaging,
  resetStatus,
} from "../penerimaanInventory.reducer";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const PenerimaanInventoryTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const penerimaanInventoryListState = useSelector(
    (state: RootState) => state.penerimaanInventory.list ?? []
  );

  const penerimaanInventoryStatusState = useSelector(
    (state: RootState) => state.penerimaanInventory.status ?? ""
  );

  const totalRowState = useSelector(
    (state: RootState) => state.penerimaanInventory.totalRow ?? 0
  );

  const isLoading = useSelector(
      (state: RootState) => state.penerimaanInventory.isLoading
  );

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filterCode, setFilterCode] = useState("");
  const [filterWarehouseName, setFilterWarehouseName] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterNote, setFilterNote] = useState("");

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const filteredItems = penerimaanInventoryListState.filter(
    (item) => item.note && item.note
  );

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onChangeVoidStatus = (id: string) => {
    dispatch(changeVoidStatus(id));
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterCode("");
    setFilterWarehouseName("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterNote("");

    dispatch(
      getListWithPaging({
        code: "",
        startDate: "",
        endDate: "",
        warehouseName: "",
        note: "",
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        code: filterCode,
        startDate: filterStartDate,
        endDate: filterEndDate,
        warehouseName: filterWarehouseName,
        note: filterNote,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [penerimaanInventoryListState]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  useEffect(() => {
    if (penerimaanInventoryStatusState !== "") {
      if (penerimaanInventoryStatusState === "success-void") {
        dispatch(resetStatus());
        onFilterResetClick();
      }
    }
  }, [penerimaanInventoryStatusState]);

  const columns = [
    {
      name: "CODE",
      selector: "inventoryNumber",
      sortable: true,
    },
    {
      name: "DATE",
      //selector: "receiveDate",
      selector: (d: { receiveDate: Date }) => (
        <p style={{ margin: 10 }}>
          {d.receiveDate !== undefined && d.receiveDate.toString().slice(0, 10)}
        </p>
      ),
      sortable: true,
    },
    {
      name: "WAREHOUSE",
      selector: "warehouseName",
      sortable: true,
    },
    // {
    //   name: "RECEIVE FROM",
    //   selector: "from",
    //   sortable: true,
    // },
    {
      name: "NOTE PENERIMAAN",
      selector: "note",
      sortable: true,
    },
    {
      name: "NAMA PENERIMA",
      selector: "username",
      sortable: true,
    },
    {
      name: "ROLE PENERIMA",
      selector: "role",
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isVoid: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isVoid && <span>VOID</span>}
          {!d.isVoid && <span>-</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any; isVoid: boolean }) => (
        <>
          {/* <Link
            to={{
              pathname: `/edit-penerimaan-inventory/${d.id}`,
            }}>
            <img src={edit}></img>
          </Link> */}

          <button
            style={{
              margin: 3,
              width: 50,
              height: 23,
              background: "#0000fe",
              borderRadius: 5,
              color: "white",
            }}
            onClick={() => {
              history.push("/edit-penerimaan-inventory/" + d.id);
            }}
          >
            DETAIL
          </button>
          {"    "}

          {!d.isVoid ? (
            <>
              <button
                style={{
                  margin: 3,
                  width: 50,
                  height: 23,
                  background: "#F52B3D",
                  borderRadius: 5,
                  color: "white",
                }}
                onClick={() => {
                  if (window.confirm("Apakah kamu yakin akan VOID item ini?"))
                    onChangeVoidStatus(d.id);
                }}
                disabled={isLoading}
              >
                {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "VOID"}
              </button>
            </>
          ) : (
            <></>
          )}

          {/* {"        "}
          <span
            onClick={() => {
              if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span> */}
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Code
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterCode}
                    onChange={(e) => {
                      setFilterCode(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Warehouse Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterWarehouseName}
                    onChange={(e) => {
                      setFilterWarehouseName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterNote}
                    onChange={(e) => {
                      setFilterNote(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <InputGroup
                className="mb-3 padding-filter"
                style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row> */}

          <hr />

          <DataTable
            columns={columns}
            data={penerimaanInventoryListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>
    </div>
  );
};

export default PenerimaanInventoryTable;
