import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {Card, Form, Row, Col, Button, Spinner} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { DatePicker } from "antd";
import { InventoryMoveModel } from "../inventoryMove.model";
import {
  resetSingle,
  resetStatus,
  setSelectedWarehouseId,
} from "../inventoryMove.reducer";
import moment from "moment";
import { StringPropertyNames } from "react-bootstrap-typeahead";

const InventoryMoveForm = ({
  data = {} as InventoryMoveModel,
  isEdit = false,
  ...props
}: InventoryMoveProps) => {
  let history = useHistory();

  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const WarehouseListState = useSelector(
    (state: RootState) => state.global.listWarehouse ?? []
  );

  const InventoryMoveStatusState = useSelector(
    (state: RootState) => state.inventoryMove.status ?? ""
  );

  const createStatus = useSelector(
    (state: RootState) => state.inventoryMove.status ?? ""
  );

  const isLoading = useSelector(
      (state: RootState) => state.inventoryMove.isLoading
  );

  const userState = useSelector((state: RootState) => state.user ?? "");

  const errorMessage = useSelector(
    (state: RootState) => state.inventoryMove.error ?? {} as any
  );

  const userIdState = useSelector((state: RootState) => state.user.id ?? "");

  const [inventoryNumber, setInventoryNumber] = useState("");
  const [status, setStatus] = useState(0);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");
  const [userId, setUserId] = useState("");
  const [inventoryMoveDate, setInventoryMoveDate] = useState(new Date());
  const [note, setNote] = useState("");
  const [inventoryReceiveId, setInventoryReceiveId] = useState("");
  const [approveBy, setApproveBy] = useState("");
  const [rejectedBy, setRejectedBy] = useState("");
  const [approvedDate, setApprovedDate] = useState(new Date());
  const [rejectedDate, setRejectedDate] = useState(new Date());
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = "";

      if (message.message === "error") {
        xhr = message.data[0].description;
      } else {
        xhr = parse.data.title;
      }

      console.log(errorMessage);
      
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content:
        errorMessage?.message?.toString() === ""
            ? "Terjadi kesalahan pada sistem."
            : errorMessage?.message?.toString(),
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setInventoryNumber(data.inventoryNumber);
      setStatus(data.status);
      setFromWarehouse(data.fromWarehouse);
      setToWarehouse(data.toWarehouse);
      setNote(data.note);
      setInventoryMoveDate(data.inventoryMoveDate);
      setInventoryReceiveId(data.inventoryReceiveId);
      setApproveBy(data.approveBy);
      setRejectedBy(data.rejectedBy);
      setApprovedDate(data.approvedDate);
      setRejectedDate(data.rejectedDate);
      dispatch(setSelectedWarehouseId(data.fromWarehouse));
    } else {
      setInventoryNumber("");
      setStatus(0);
      setFromWarehouse("");
      setToWarehouse("");
      setNote("");
      setInventoryMoveDate(new Date());
      setInventoryReceiveId("");
      setApproveBy(userIdState);
      setRejectedBy("");
      setApprovedDate(new Date());
      setRejectedDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onInventoryMoveChange = (value: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setInventoryMoveDate(date);
    }
  };

  const onWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFromWarehouse(e.target.value);
    dispatch(setSelectedWarehouseId(e.target.value));
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/inventory-move/index");
    } else {
      dispatch(resetStatus());
    }
  };

  return (
    <div>
      <Grid textAlign="center">
        <Form.Group>
          <Form.Label>
            <h4>{isEdit ? "Edit Inventory Move" : "Add Inventory Move"}</h4>
          </Form.Label>
          <div className="btn-footer-group float-right">
            <Link to={{ pathname: `/inventory-move/index` }}>
              <Button className="btn-cancel" variant="light">
                Cancel
              </Button>
              {"  "}
            </Link>

            {!isEdit ? (
              <>
                <Button
                  style={{ width: 115, height: 46 }}
                  form="inventoryMove"
                  className="btn"
                  variant="info"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Save"}
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
        </Form.Group>

        <Card>
          <Card.Body>
            <div className="profile-setting-subtitle">Inventory Move</div>
            <Form
              id="inventoryMove"
              noValidate
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    inventoryNumber,
                    inventoryMoveDate,
                    status,
                    userState.id ?? "",
                    fromWarehouse,
                    toWarehouse,
                    note,
                    inventoryReceiveId,
                    approveBy,
                    rejectedBy,
                    approvedDate,
                    rejectedDate
                  );
                }
                setValidated(true);
              }}
            >
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Inventory Number
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setInventoryNumber(e.target.value)}
                        value={inventoryNumber}
                        required
                        disabled
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}

              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Date Inventory Move
                </Form.Label>
                <Col sm={4}>
                  <DatePicker
                    defaultValue={moment(inventoryMoveDate, "YYYY-MM-DD HH:mm")}
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onInventoryMoveChange}
                    disabled
                  />
                </Col>
              </Form.Group>

              {!isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Warehouse
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={onWarehouseChange}
                        value={fromWarehouse}
                      >
                        <option>select</option>
                        {WarehouseListState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Warehouse Tujuan
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={(e) => setToWarehouse(e.target.value)}
                        value={toWarehouse}
                      >
                        <option>select</option>
                        {WarehouseListState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Note
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Note"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Warehouse
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={onWarehouseChange}
                        value={fromWarehouse}
                        disabled
                      >
                        <option>select</option>
                        {WarehouseListState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Warehouse Tujuan
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={(e) => setToWarehouse(e.target.value)}
                        value={toWarehouse}
                        disabled
                      >
                        <option>select</option>
                        {WarehouseListState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Note
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Note"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </>
              )}
            </Form>
          </Card.Body>
        </Card>
        <br />

        <AlertMessage
          {...alertProps}
          show={showAlert}
          onAlertCloseAction={onAlertClose}
        />
      </Grid>
    </div>
  );
};

export interface InventoryMoveProps {
  onSubmit: (
    inventoryNumber: string,
    inventoryMoveDate: Date,
    status: number,
    userId: string,
    fromWarehouse: string,
    toWarehouse: string,
    note: string,
    inventoryReceiveId: string,
    approveBy: string,
    rejectedBy: string,
    approvedDate: Date,
    rejectedDate: Date
  ) => void;
  data: InventoryMoveModel;
  isEdit: boolean;
}

export default InventoryMoveForm;
