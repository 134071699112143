import { createSlice } from '@reduxjs/toolkit'
import { getAllCustomerFilterAccountReceivable, getAllProductFilterAccountReceivable, postAccountReceivableReport, postExportAccountReceivable } from './AccountReceivableReport.api'

export interface AccountReceivableReportState {
  loading : boolean,
  listCustomerFilterAccountReceivable : any,
  listProductFilterAccountReceivable : any
}

const initialState: AccountReceivableReportState = {
  loading : false,
  listCustomerFilterAccountReceivable : [],
  listProductFilterAccountReceivable : []
}

export const AccountReceivableReportSlice = createSlice({
    name: 'laporan piutang',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {

        builder.addCase(getAllCustomerFilterAccountReceivable.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getAllCustomerFilterAccountReceivable.fulfilled, (state, action) => {
            state.loading = false
            state.listCustomerFilterAccountReceivable = action.payload.data
        })
        builder.addCase(getAllCustomerFilterAccountReceivable.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getAllProductFilterAccountReceivable.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getAllProductFilterAccountReceivable.fulfilled, (state, action) => {
            state.loading = false
            state.listProductFilterAccountReceivable = action.payload.data
        })
        builder.addCase(getAllProductFilterAccountReceivable.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(postExportAccountReceivable.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postExportAccountReceivable.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postExportAccountReceivable.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(postAccountReceivableReport.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postAccountReceivableReport.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postAccountReceivableReport.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default AccountReceivableReportSlice.reducer