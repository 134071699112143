import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {endpoint} from '../../../constants'
import {CreateRecommendedRequest} from "./model";

export const getRecommendedList = createAsyncThunk('recommended/getList', async () => {
    const response = await axios.get(`${endpoint}/api/AdminRecommended/GetList`)
    console.log(response)
    return response.data
})

export const getRecommendedDetail = createAsyncThunk('recommended/getDetail', async (id: string) => {
    try {
        const response = await axios.get(`${endpoint}/api/AdminRecommended/GetSingleItem`, {
            params: {
                id: id
            }
        })
        // console.log(response.data.data, 'detail')
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

export const createRecommended = createAsyncThunk('recommended/create', async (args: CreateRecommendedRequest) => {
    try {
        const response = await axios.post(`${endpoint}/api/AdminRecommended/Create`, null, {
            params: {
                ...args
            }
        })
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const uploadImage = createAsyncThunk('upload/imagesConvert', async (body: any) => {
    try {
        return await axios.post(`${endpoint}/web/api/Upload/UploadImage`, body)
    }
    catch (error: any) {
        return error.response
    }
})

export const updateRecommended = createAsyncThunk('recommended/update', async (args: CreateRecommendedRequest) => {
    try {
        const response = await axios.post(`${endpoint}/api/AdminRecommended/Update`, null, {
            params: {
                ...args
            }
        })
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const deleteRecommended = createAsyncThunk('recommended/delete', async (id: string) => {
    try {
        return await axios.post(`${endpoint}/api/AdminRecommended/Delete`, null, {
            params: {
                Id: id
            }
        })
    }
    catch (error: any) {
        return error.response
    }
})