import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import Api from "./paymentConfirm.api";
import {
    FilterModel,
    PaymentConfirmModel,
    SalesOrderModel,
    UpdateMultiplePaidStatusRequest,
    UpdatePaidStatusRequest,
    UpdateStatusModel
} from "./paymentConfirm.model";

export interface PaymentConfirmSlice {
  name?: string;
  list?: PaymentConfirmModel[];
  totalRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const getList = createAsyncThunk(
  "PaymentConfirmState/getPaymentConfirmList",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getList(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWithPaging = createAsyncThunk(
  'PaymentConfirmGetListWithPagingState/PaymentConfirmGetListWithPaging',
  async ( model : FilterModel, {rejectWithValue}) => {
      try {
          return await Api.getListWithPaging(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
);

export const updateStatus = createAsyncThunk(
  "PaymentConfirmUpdateState/updateStatus",
  async (model: UpdateStatusModel, { rejectWithValue }) => {
    try {
      return await Api.updateStatus(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const changePaidStatus = createAsyncThunk(
  "ChangePaidStatusState/changePaidStatus",
  async (model: UpdatePaidStatusRequest, { rejectWithValue }) => {
    try {
      return await Api.changePaidStatus(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const changeMultiplePaidStatus = createAsyncThunk(
    "ChangePaidStatusState/changeMultiplePaidStatus",
    async (model: UpdateMultiplePaidStatusRequest, { rejectWithValue }) => {
        try {
            return await Api.changeMultiplePaidStatus(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateMultipleStatus = createAsyncThunk(
    "ChangePaidStatusState/updateMultipleStatus",
    async (model: UpdateStatusModel[], { rejectWithValue }) => {
        try {
            return await Api.updateMultipleStatus(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const PaymentConfirmSlice = createSlice({
  name: "PaymentConfirmState",
  initialState: {} as PaymentConfirmSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
      state.error = {} as ApiErrorResponse<any>;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
      state.status = "success-update";
      state.modifiedDate = new Date().toString();
      state.isLoading = false;
    });
    builder.addCase(updateStatus.rejected, (state, { payload }) => {
      state.status = "failed-update";
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
      builder.addCase(updateMultipleStatus.pending, (state) => {
          state.isLoading = true;
      });
      builder.addCase(updateMultipleStatus.fulfilled, (state, { payload }) => {
          state.status = "success-update";
          state.modifiedDate = new Date().toString();
          state.isLoading = false;
      });
      builder.addCase(updateMultipleStatus.rejected, (state, { payload }) => {
          state.status = "failed-update";
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
      });
    builder.addCase(changePaidStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePaidStatus.fulfilled, (state, { payload }) => {
      state.status = "success-update-paid-status";
      state.modifiedDate = new Date().toString();
      state.isLoading = false;
    });
    builder.addCase(changePaidStatus.rejected, (state, { payload }) => {
      state.status = "failed-update-paid-status";
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(changeMultiplePaidStatus.pending, (state) => {
          state.isLoading = true;
      });
    builder.addCase(changeMultiplePaidStatus.fulfilled, (state, { payload }) => {
          state.status = "success-update-paid-status";
          state.modifiedDate = new Date().toString();
          state.isLoading = false;
      });
    builder.addCase(changeMultiplePaidStatus.rejected, (state, { payload }) => {
          state.status = "failed-update-paid-status";
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
      });
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, {payload})=> {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, {payload}) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { resetStatus } = PaymentConfirmSlice.actions;
export default PaymentConfirmSlice.reducer;
