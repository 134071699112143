import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import Select from "react-select";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";
import { Link } from "react-router-dom";
import DeleteMessage from "../../../../components/DeleteMessage";
import NumberFormat from "react-number-format";
import { getListWithPaging } from "../paymentAllocation.reducer";
import { PartnerOptionModel } from "../../paymentIn/paymentIn.model";
import { isNullOrUndefined } from "util";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const PaymentAllocationTable = () => {
  const dispatch = useDispatch();

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [filterDocumentNumber, setFilterDocumentNumber] = useState("");
  const [filterStatus, setFilterStatus] = useState(5);
  const [filterPartnerId, setFilterPartnerId] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [selectedPartner, setSelectedPartner] = useState<PartnerOptionModel>({} as PartnerOptionModel)
  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);



  const PaymentAllocationListState = useSelector(
    (state: RootState) => state.paymentAllocation.list ?? []
  );

  const partnerListState = useSelector(
    (state: RootState) => state.global.listPartner ?? []
  );

  const onDeleteData = () => {
    // if (selectedId !== undefined) {
    //   dispatch(deleteData(selectedId));
    //   setShowAlert(false);
    // }
  };

  const onSelectedPartnerChange = (data: any) => {
    setFilterPartnerId(data.value);
    setSelectedPartner(partnerList.filter((item) => item.value == data.value)[0])
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };


  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {

    setFilterStatus(5);
    setFilterDocumentNumber("");
    setFilterStartDate("");
    setFilterEndDate("");
    setSelectedPartner({} as PartnerOptionModel)


    dispatch(
      getListWithPaging({
        documentNumber: "",
        documentStatus: null,
        customerId: null,
        startDate: null,
        endDate: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        documentNumber: filterDocumentNumber,
        documentStatus: filterStatus == 5 ? null : filterStatus,
        customerId: filterPartnerId == "" ? null : filterPartnerId,
        startDate: filterStartDate == "" ? null : filterStartDate,
        endDate: filterEndDate == "" ? null : filterEndDate,
        skip: 0,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  useEffect(() => {
    if (partnerListState.length !== 0) {
      setPartnerList([]);

      partnerListState.map((item) => {
        var partnerTypeString =
          item.type == 0
            ? "Customer"
            : item.type == 1
            ? "Stockist"
            : "Reseller";

        setPartnerList((prevState) => [
          ...prevState,
          {
            value: item.id,
            label:
              item.fullName + " - " + item.email + " - " + partnerTypeString,
            type: item.type,
          },
        ]);
      });
    }
  }, [partnerListState]);

  const columns = [
    {
      name: "ALLOCATION NO.",
      selector: "allocationNumber",
      sortable: true,
    },
    {
      name: "TANGGAL",
      selector: (d: { date: string }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.date !== undefined && d.date.substring(0, 10)}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "CUSTOMER",
      selector: "partnerName",
      sortable: true,
    },
    {
      name: "STATUS",
      selector: "statusName",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d : { id : string}) => (
        <>
          <Link
            to={{
              // pathname: `/payment-allocation/${d.id}`,
              pathname: `/payment-allocation-edit/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              // setSelectedId(d.id!);
              // setAlertProps({
              //   variant: "delete",
              //   title: "Menghapus Data",
              //   content: "Apakah kamu yakin akan menghapus data ini?",
              // });
              // setShowAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <div>
      <Grid>


        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Allocation Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterDocumentNumber}
                    onChange={(e) => {
                      setFilterDocumentNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Tanggal
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    value={filterStartDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setFilterStatus(parseInt(e.target.value))
                    }}
                    value={filterStatus}
                  >
                    <option value="5">ALL</option>
                    <option value="0">DRAFT</option>
                    <option value="1">COMPLETE</option>
                    <option value="2">VOID</option>
                    <option value="3">CLOSE</option>
                    <option value="4">INVALID</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Customer
                </Form.Label>
                <Col sm={8}>
                  <Select
                    onChange={onSelectedPartnerChange}
                    value={selectedPartner}
                    options={partnerList}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          <br />
          <br />

          <DataTable
            columns={columns}
            data={PaymentAllocationListState}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default PaymentAllocationTable;
