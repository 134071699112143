import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getProductCategorySelect = createAsyncThunk('get/getProductCategorySelect', async () => {
    const response = await axios.get(`${endpoint}/api/ProductCategory/GetList`)
    console.log(response)
    return response.data
})

export const getPartnerSelect = createAsyncThunk('get/getPartnerSelect', async () => {
    const response = await axios.get(`${endpoint}/api/Partner/GetList`)
    console.log(response)
    return response.data
})

export const postExportOmset = createAsyncThunk('post/postExportOmset', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Report/ReportOmsetExportToExcel`,
            data: body,
            method: 'post',
            responseType: 'blob',
        })
        console.log(response)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postLaporanOmset = createAsyncThunk('post/postLaporanOmset', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportOmset`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})
