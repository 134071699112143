import { Card, Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { deleteData } from "../bank.reducer";
import { FaSearch } from "react-icons/fa";
import { BankModel } from "../bank.model";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import DeleteMessage from "../../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const BankTable = () => {
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const userState = useSelector((state: RootState) => state.bank.list ?? []);

  const [selectedId, setSelectedId] = useState("")

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
  };


  const filteredItems = userState.filter(
    (item) =>
      (item.code && item.code.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) 
      // (item.accountNumber && item.accountNumber.toLowerCase().includes(filterText.toLowerCase())) ||
      // (item.accountName && item.accountName.toLowerCase().includes(filterText.toLowerCase()))
  );

  const columns = [
    // {
    //   name: "ID",
    //   selector: "code",
    //   sortable: true,
    // },
    {
      name: "BANK NAME",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "ACCOUNT NUMBER",
    //   selector: "accountNumber",
    //   sortable: true,
    // },
    // {
    //   name: "NAME",
    //   selector: "accountName",
    //   sortable: true,
    // },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && <span className={"custom-badge-success"}>Active</span>}
          {d.isActive === false && <span className={"custom-badge-secondary"}>Inactive</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-bank/${d.id}`,
            }}
          >
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
              
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    dispatch(
      deleteData({
        id: id,
      } as BankModel)
    );
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData({
        id:selectedId} as BankModel));
      setShowAlert(false);
    }
  };


  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master bank</Form.Label>
          </Form.Group>
        </Col>
        <Link
          to={{
            pathname: "/bank/create",
          }}
        >
          <Button className="button-create-customer float-right" style={{ width: 160 }}>
            + Add Bank
          </Button>
          {"  "}
        </Link>
        <Button
          className="float-right"
          style={{ width: 130, marginLeft: 10 }}
          variant="outline-info"
        >
          Print
        </Button>{" "}
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="outline-info"
        >
          Export to excel
        </Button>{" "}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text style={{ backgroundColor: "#fff", color: "#F5B95D", border: 0 }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default BankTable;
