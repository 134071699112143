import { AttributeModel, ConfigureTermsModel } from "./attribute.model";
import { endpoint } from "../../../constants";
import axios from "axios";

export default class AttributeApi {
  static createAttribute(createAttribute: AttributeModel) {
    return axios
      .post<any>(endpoint + "/api/ProductAttribute/Create", createAttribute)
      .then((res) => res.data.data);
  }

  static getAttributeList(isActive: boolean) {
    return axios
      .get<any>(endpoint + "/api/ProductAttribute/GetList?isActive=" + isActive)
      .then((res) => res.data.data);
  }

  static getSingleItem(attributeId: string) {
    return axios
      .get<any>(endpoint + "/api/ProductAttribute/GetSingleItem?id=" + attributeId)
      .then((res) => res.data.data);
  }

  static updateAttribute(attribute: AttributeModel) {
    return axios
      .post<any>(endpoint + "/api/ProductAttribute/update", attribute)
      .then((res) => res.data.data);
  }

  static deleteAttribute(attributeId: string) {
    return axios
      .post<any>(endpoint + "/api/ProductAttribute/Delete?id=" + attributeId)
      .then((res) => [{ response: res.data.data }]);
  }

  static createConfigureTerm(Configureterm: ConfigureTermsModel) {
    return axios
      .post<any>(endpoint + "/api/ConfigurationAttribute/Create", Configureterm)
      .then((res) => res.data.data);
  }

  static getConfiguretermList(Id: string) {
    return axios
      .get<any>(
        endpoint + "/api/ConfigurationAttribute/GetListByProductAttribute?productAttributeId=" + Id
      )
      .then((res) => res.data.data);
  }

  static getConfiguretermSingleItem(ConfigureTermsId: string) {
    return axios
      .get<any>(endpoint + "/api/ConfigurationAttribute/GetSingleItem?id=" + ConfigureTermsId)
      .then((res) => res.data.data);
  }

  static updateConfigureTerms(ConfigureTerms: ConfigureTermsModel) {
    return axios
      .post<any>(endpoint + "/api/ConfigurationAttribute/update", ConfigureTerms)
      .then((res) => res.data.data);
  }

  static deleteConfigureTerms(configureTermsId: string) {
    return axios
      .post<any>(endpoint + "/api/ConfigurationAttribute/Delete?id=" + configureTermsId)
      .then((res) => [{ response: res.data.data }]);
  }

  static getConfigureTermsParent(Id: string) {
    return axios
      .get<any>(endpoint + "/api/ConfigurationAttribute/GetListForDropDown?productAttribute=" + Id)
      .then((res) => res.data.data);
  }
}
