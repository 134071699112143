/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { AdminListModel } from "../warehouse/settingWarehouse.model";
import Api from "./contract.api";
import { AdminModel, AdminStockistModel, ContractApprovalRequestModel, ContractModel, FilterModel, formValuesModel, ListContractCategoryModel } from "./contract.model";

export interface ContractSlice {
  name?: string;
  list?: ContractModel[];
  totalRow?: number;
  single?: ContractModel;
  listContractDiscountSatuan?: formValuesModel[];
  listContractDiscountSerian?: formValuesModel[];
  listContractCategory?: ListContractCategoryModel[];
  adminStocksitList? : AdminStockistModel[];
  adminList? : AdminModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const createData = createAsyncThunk(
  "contractState/createContract",
  async (Model: ContractModel, { rejectWithValue }) => {
    try {
      const response = await Api.createData(Model);
      if (response.status === true) {
        //save contract category
        if (Model.ContractCategory.productCategoryList.length !== 0) {
          let ContractCategoryData = Model.ContractCategory;
          ContractCategoryData.contractStockistId = response.data.id;

          await Api.createContractCategory(ContractCategoryData);
        }

        //save contract discount {
        if (Model.contractDiscount.length !== 0) {
          let contractDiscountData = Model.contractDiscount;

          contractDiscountData.map((item) => {
            item.contractStockistId = response.data.id;
          });

          await Api.createContractDiscout(contractDiscountData);
        }
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const contractApprovalRequest = createAsyncThunk(
  'contractApprovalRequestState/contractApprovalRequest',
  async (model: ContractApprovalRequestModel, {rejectWithValue}) => {
      try {
          return await Api.contractApprovalRequset(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>)
      }
  }
)

export const getListContract = createAsyncThunk(
  "ContractState/getContractList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListContractWithPaging = createAsyncThunk(
  "ContractState/getContractListWithPaging",
  async (model : FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleContract = createAsyncThunk(
  "ContractState/ContractSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAdminStockistListByStokist = createAsyncThunk(
  "AdminStokistState/AdminStokist",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getAdminListByStockist(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAdminList = createAsyncThunk(
  "AdminState/Admin",
  async (i_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getAdminList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "ContractState/updateContract",
  async (Model: ContractModel, { rejectWithValue }) => {
    try {
      const response = await Api.updateData(Model);
      if (response.status === true) {
        //save contract category
        if (Model.ContractCategory.productCategoryList.length !== 0) {
          let ContractCategoryData = Model.ContractCategory;
          ContractCategoryData.contractStockistId = response.data.id;

          await Api.createContractCategory(ContractCategoryData);
        }

        //save contract discount {
        if (Model.contractDiscount.length !== 0) {
          let contractDiscountData = Model.contractDiscount;

          contractDiscountData.map((item) => {
            if (item.contractStockistId !== response.data.id) {
              item.contractStockistId = response.data.id;
            }
          });

          await Api.createContractDiscout(contractDiscountData);
        }
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "ContractState/deleteContract",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListContractDiscountSatuan = createAsyncThunk(
  "ContractState/getContractDiscountListSatuan",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getListContractDiscountSatuan(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListContractDiscountSerian = createAsyncThunk(
  "ContractState/getContractDiscountListSerian",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getListContractDiscountSerian(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListContractCategory = createAsyncThunk(
  "ContractState/getContractCategoryList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getListContractCategory(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const ContractSlice = createSlice({
  name: "ContractState",
  initialState: {} as ContractSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as ContractModel;
      state.listContractDiscountSatuan = [];
      state.listContractDiscountSerian = [];
      state.listContractCategory = [];
    },
    resetStatus: (state) => {
      state.status = "";
    },
    resetTotalRow: (state) => {
      state.totalRow = 0;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleContract.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleContract.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleContract.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getAdminStockistListByStokist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdminStockistListByStokist.fulfilled, (state, { payload }) => {
      state.adminStocksitList = payload;
      state.isLoading = false;
    });
    builder.addCase(getAdminStockistListByStokist.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getAdminList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdminList.fulfilled, (state, { payload }) => {
      state.adminList = payload;
      state.isLoading = false;
    });
    builder.addCase(getAdminList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListContract.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListContract.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getListContract.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListContractWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListContractWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListContractWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-delete";
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getListContractDiscountSatuan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListContractDiscountSatuan.fulfilled, (state, { payload }) => {
      state.listContractDiscountSatuan = payload;
      state.isLoading = false;
    });
    builder.addCase(getListContractDiscountSatuan.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListContractDiscountSerian.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListContractDiscountSerian.fulfilled, (state, { payload }) => {
      state.listContractDiscountSerian = payload;
      state.isLoading = false;
    });
    builder.addCase(getListContractDiscountSerian.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListContractCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListContractCategory.fulfilled, (state, { payload }) => {
      state.listContractCategory = payload;
      state.isLoading = false;
    });
    builder.addCase(getListContractCategory.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(contractApprovalRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(contractApprovalRequest.fulfilled, (state, { payload }) => {
      state.status = "success-approval-change";
      state.isLoading = false;
    });
    builder.addCase(contractApprovalRequest.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = ContractSlice.actions;
export default ContractSlice.reducer;
