import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { ElementPropModel } from "../../../components/DataTable/models";
import { Link } from "react-router-dom";
import { Grid, Input } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../assets/vector/edit.png";
import hapus from "./../../../assets/vector/delete.png";
import DataTable from "react-data-table-component";
import AlertMessage from "../../../components/alertMessage";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {
  deleteInternalUse,
  getInternalUseList,
  getInternalUseListWithPaging,
  resetStatus,
} from "../internalUse.reducer";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const IntenalUseTable = () => {
  const dispatch = useDispatch();

  const internalUseState = useSelector(
    (state: RootState) => state.internalUse.list ?? []
  );

  const internalUseStateStatus = useSelector(
    (state: RootState) => state.internalUse.status ?? ""
  );

  const totalRowState = useSelector(
    (state: RootState) => state.internalUse.totalRow ?? 0
  );

  const [filterText, setFilterText] = useState("");
  // const [filterStatus, setFilterStatus] = useState("1");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [filterInternalUseNumber, setFilterInternalUseNumber] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [filterNote, setFilterNote] = useState("");
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const [filterTempStatus, setFilterTempStatus] = useState(3);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  //   const filteredItems = internalUseState.filter(
  //     (item) =>
  //       item.internalUserNumber &&
  //       item.status.toString().includes(filterStatus) &&
  //       (item.internalUserNumber
  //         .toLowerCase()
  //         .includes(filterText.toLowerCase()) ||
  //         item.internalUseDate.substr(0, 10).includes(filterText.toLowerCase()))
  //   );

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterInternalUseNumber("");
    setFilterProductName("");
    setFilterNote("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterStatus(null);
    setFilterTempStatus(3);

    dispatch(
      getInternalUseListWithPaging({
        internalUseNumber: "",
        productName: "",
        note: "",
        startDate: "",
        endDate: "",
        status: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getInternalUseListWithPaging({
        internalUseNumber: filterInternalUseNumber,
        productName: filterProductName,
        note: filterNote,
        startDate: filterStartDate,
        endDate: filterEndDate,
        status: filterStatus,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [internalUseState]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  const elementProps = {
    placeholder: "",
    buttonName: "",
    createUrl: "",
    subHeaderLine: true,
  } as ElementPropModel;

  const columns = [
    {
      name: "INTERNAL USE NUMBER",
      selector: "internalUserNumber",
      sortable: true,
    },
    {
      name: "PRODUCT",
      selector: "productName",
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "DATE",
      // selector: "internalUseDate",
      selector: (d: { internalUseDate: string }) => (
        <span>{d.internalUseDate.substr(0, 10)}</span>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { status: number }) => (
        <p style={{ margin: 10 }}>
          {d.status === 0 && (
            <span className={"custom-badge-secondary"}>Request</span>
          )}
          {d.status === 1 && (
            <span className={"custom-badge-success"}>Approved</span>
          )}
          {d.status === 2 && (
            <span className={"custom-badge-danger"}>Rejected</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "APPROVE BY",
      selector: (d: { approveName: string }) => <span>{d.approveName}</span>,
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any; status: number }) => (
        <>
          <Link
            to={{
              pathname: `/edit-internal-use/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}

          {d.status === 0 && (
            <span
              onClick={() => {
                if (
                  window.confirm("Apakah kamu yakin akan menghapus item ini?")
                )
                  onDelete(d.id);
              }}
            >
              <img src={hapus}></img>
            </span>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (internalUseStateStatus !== "failed-delete") {
      dispatch(getInternalUseList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (internalUseStateStatus === "success-delete") {
      setAlertProps({
        variant: "Success",
        title: "Internal Use",
        content: "Data Internal Use sudah dihapus.",
      });
      dispatch(getInternalUseList());
      setShowAlert(true);
    } else if (internalUseStateStatus === "failed-delete") {
      setAlertProps({
        variant: "Failed",
        title: "Internal Use",
        content: "Daftar Internal Use gagal dihapus.",
      });
      setShowAlert(true);
    }
  }, [internalUseStateStatus]);

  const onAlertClose = () => {
    setShowAlert(false);
    dispatch(resetStatus());
  };

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteInternalUse(id));
    }
  };

  //   const onFilterReset = () => {
  //     setFilterText("");
  //     setFilterStatus("");
  //   };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Internal Use</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="float-right"
            style={{ width: 130, marginLeft: 10 }}
            variant="outline-info"
          >
            Print
          </Button>{" "}
          <Button
            className="float-right"
            style={{ width: 160, marginLeft: 10 }}
            variant="outline-info"
          >
            Export to excel
          </Button>{" "}
          <Link to={{ pathname: "/create-internal-use" }}>
            <Button
              className="float-right ml-2"
              variant="info"
              style={{ width: 185 }}
            >
              + Add Internal Use
            </Button>
          </Link>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Internal Use Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterInternalUseNumber}
                    onChange={(e) => {
                      setFilterInternalUseNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Product
                </Form.Label>
                <Col>
                  <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterProductName}
                      onChange={(e) => {
                        setFilterProductName(e.target.value);
                      }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6} className={"pl-3 pr-5"}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col>
                  <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterNote}
                      onChange={(e) => {
                        setFilterNote(e.target.value);
                      }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterTempStatus}
                    onChange={(e) => {
                      if (e.target.value.toString() === "3") {
                        setFilterStatus(null);
                        setFilterTempStatus(parseInt(e.target.value));
                      } else {
                        setFilterTempStatus(parseInt(e.target.value));
                        setFilterStatus(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={3}>All</option>
                    <option value={0}>Request</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          <hr />

          <DataTable
            columns={columns}
            data={internalUseState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          <AlertMessage
            {...alertProps}
            show={showAlert}
            onAlertCloseAction={onAlertClose}
          ></AlertMessage>
        </Card>
      </Grid>
    </div>
  );
};

export default IntenalUseTable;
