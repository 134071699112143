export enum AdditionalPageSettingEnum
{
    Service,
    AboutUs,
    FindUs,
    ContactUs,
    Help,
    Privacy,
    TermAndCondition,
    CompanyInfo,
    Return,
    GetInTouch,
    Address,
    ReturnPolicy,
    SizeGuide,
    SiteMap,
    Status
}

export type AdditionalPageSetting = {
    id?: string;
    type: AdditionalPageSettingEnum;
    contentTitle: string;
    content: string;
}