import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {Card, Form, Row, Col, Button, Modal, Spinner} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import Select from "react-select";
import hapus from "./../../../../assets/vector/delete.png";
import {
  createStockOpname,
  createWithProduct,
  exportToExcel,
  getProductByWarehouse,
  getWarehouse,
  getWarehouseStockByWarehouse,
  getWarehouseStockByWarehouseWithPaging,
  resetProductList,
  resetSingle,
  resetStatus,
} from "../stockOpname.reducer";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import NumberFormat from "react-number-format";
import edit from ".//../../../../assets/vector/edit.png";
import {
  CreateStockOpnameModel,
  StockOpnameModel,
  SingleStockOpnameModel,
  StockModel,
  ProductOptionModel,
  VariantOptionModel,
  StockOpnameDetail,
} from "../stockOpname.model";
import "../StockOpname.css";
import { getListActiveProduct, getListProduct } from "../../../../global/global.reducer";
import { getListVariationByProductAndUser } from "../../../salesOrder/salesOrder/salesOrder.reducer";
import { SalesOrderDetailModel } from "../../../salesOrder/salesOrder/salesOrder.model";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import DeleteMessage from "../../../../components/DeleteMessage";
import { setSelectedWarehouseId } from "../../InventoryMove/inventoryMove.reducer";
import AsyncSelect from "react-select/async";
import useAsyncProductOption from "../../../../hooks/product/useAsyncProductOption";

const StockOpnameForm = ({
  data = {} as SingleStockOpnameModel,
  isEdit = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userState = useSelector((state: RootState) => state.user ?? "");

  const warehouseListState = useSelector(
    (state: RootState) => state.stockOpname.warehouseList ?? []
  );

  const productListState = useSelector(
    (state: RootState) => state.stockOpname.productList ?? []
  );

  const productState = useSelector(
    (state: RootState) => state.global.listActiveProduct ?? []
  );

  const variationState = useSelector(
    (state: RootState) => state.salesOrder.listVariationByProduct ?? []
  );

  const warehouseStockState = useSelector(
    (state: RootState) => state.stockOpname.warehouseStockList ?? []
  );

  const statusState = useSelector(
    (state: RootState) => state.stockOpname.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.stockOpname.error ?? ({} as any)
  );

  const isLoading = useSelector(
      (state: RootState) => state.stockOpname.isLoading
  );

  const totalWarehouseStockRowState = useSelector(
    (state: RootState) => state.stockOpname.totalWarehouseStockRow ?? 0
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");
  const [status, setStatus] = useState(0);
  const [productStock, setProductStock] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [selectedProductNote, setSelectedProductNote] = useState("");
  const [selectedStock, setSelectedStock] = useState(0);
  const [selectedStockSystem, setSelectedStockSystem] = useState(0);
  const [selectedUOM, setselectedUOM] = useState("");
  const [selectedLastUpdateName, setselectedLastUpdateName] = useState("");
  const [selectedProductSku, setSelectedProductSku] = useState("");

  const [stockOpnameDate, setStockOpnameDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [productList, setProductList] = useState<ProductOptionModel[]>([]);
  const [variationList, setVariationList] = useState<VariantOptionModel[]>([]);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [salesOrderDetailTemp, setSalesOrderDetailTemp] = useState<
    SalesOrderDetailModel[]
  >([]);
  const [variantSystemStock, setVariantSystemStock] = useState(0);
  const [variantActualStock, setVariantActualStock] = useState(0);
  const [stockNote, setStockNote] = useState("");
  const [stockOpnameDetail, setStockOpnameDetail] = useState<
    StockOpnameDetail[]
  >([]);
  const [stockOpnameNumber, setStockOpnameNumber] = useState("");
  const [stockOpnameCreateBy, setStockOpnameCreateBy] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [stockOpnameNote, setStockOpnameNote] = useState("");

  // filter
  const [filterVariantName, setFilterVariantName] = useState("");
  const [filterInventorySKU, setFilterInventorySKU] = useState("");

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const [singleDataStockOpname, setSingleDataStockOpname] = useState(
    {} as SingleStockOpnameModel
  );

  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleteAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [timer, setTimer] = useState(setTimeout(() => {
  }, 1000));

  const {getProductOptions} = useAsyncProductOption();

  const onDeleteAlertClose = () => setShowDeleteAlert(false);

  const initialVariant = {
    id: "",
    label: "",
    systemStock: 0,
  } as VariantOptionModel;
  const [selectedVariant, setSelectedVariant] =
    useState<VariantOptionModel>(initialVariant);

  const handleAddProductClose = () => {
    setShowAddProductModal(false);
    // setSalesOrderDetailTemp([]);
  };

  const onWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWarehouseId(e.target.value);
    dispatch(getProductByWarehouse(e.target.value));
  };

  const onSelectedProductChange = (product: any) => {
    setSelectedVariant(initialVariant);
    console.log(product);
    setSelectedProductId(product.id);
    setSelectedProductName(product.productName);
    setSelectedProductSku(product.sku);

    dispatch(
      getListVariationByProductAndUser({
        productId: product.id,
        partnerType: 3,
        userId: userState.id === undefined ? "" : userState.id,
      })
    );
  };

  const onSelectedVariantChange = (variant: any) => {
    setSelectedVariant(variant);
    setSelectedVariantId(variant.id);
    setVariantSystemStock(variant.systemStock);
  };

  const onConfirmAddProduct = () => {
    setStockOpnameDetail((prevState) => [
      ...prevState,
      {
        productId: selectedProductId,
        variationId: selectedVariantId,
        qty: variantActualStock,
        note: stockNote,
        productName: selectedProductName,
        productSKU: selectedProductSku,
        productVariantName: selectedVariant.label,
        productVariationSystemStock: variantSystemStock,
      },
    ]);

    handleAddProductClose();
  };

  const onDelete = () => {
    let newList = [...stockOpnameDetail];
    newList.splice(selectedIndex, 1);
    setStockOpnameDetail(newList);
    setShowDeleteAlert(false);
  };

  const onEditItemClicked = (
    productVariationId: string,
    productName: string,
    productCode: string,
    productStock: number,
    productStockSystem: number,
    uom: string,
    name: string,
    note: string
  ) => {
    setSelectedProductId(productVariationId);
    setSelectedProductName(productName);
    setSelectedProductCode(productCode);
    setSelectedStock(productStockSystem);
    setSelectedStockSystem(productStock);
    setselectedUOM(uom);
    setselectedLastUpdateName(name);
    setSelectedProductNote(note);
    setShowModal(true);
  };

  const onNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedProductNote(e.target.value);
  };

  const onSaveProductClick = () => {
    console.log(warehouseId);
    dispatch(
      createStockOpname({
        status: status,
        warehouseId: warehouseId,
        variationId: selectedProductId,
        updateStock: selectedStock,
        note: selectedProductNote,
        userId: userState.id,
      } as CreateStockOpnameModel)
    );
  };

  const onCancelClick = () => {
    dispatch(resetStatus());
    dispatch(resetProductList());
    dispatch(resetSingle());
    history.replace("/stock-opname/index");
  };

  const onSaveClick = () => {
    dispatch(
      createWithProduct({
        warehouseId: warehouseId,
        note: stockOpnameNote,
        userId: userState.id!,
        detail: stockOpnameDetail,
      })
    );
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  const onAlertClose = () => {
    if (statusState === "success") {
      dispatch(resetStatus());
      dispatch(resetProductList());
      dispatch(resetSingle());
      history.replace("/stock-opname/index");
    } else {
      dispatch(resetStatus());
    }
    setShowAlert(false);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterInventorySKU("");
    setFilterVariantName("");

    dispatch(
      getWarehouseStockByWarehouseWithPaging({
        warehouseId: warehouseId,
        variantName: filterVariantName,
        inventorySKU: filterInventorySKU,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getWarehouseStockByWarehouseWithPaging({
        warehouseId: warehouseId,
        variantName: filterVariantName,
        inventorySKU: filterInventorySKU,
        skip: page,
        take: perPage,
      })
    );
  };

  const onExportClick = () => {
    dispatch(
      exportToExcel({
        stockOpnameNumber: "",
        warehouseId: "",
        status: "",
        startDate: "",
        endDate: "",
        skip: 0,
        take: 100,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [warehouseStockState]);

  useEffect(() => {
    dispatch(getListActiveProduct());

    if (!isEdit) {
      setWarehouseId("");
      dispatch(resetStatus());
      dispatch(resetProductList());
      dispatch(resetSingle());
    }

    dispatch(resetProductList());
    dispatch(getWarehouse());
  }, []);

  // useEffect(() => {
  //   if (warehouseId !== "") {
  //     // dispatch(getWarehouseStockByWarehouse(warehouseId));
  //     fetchData(0, perPage);
  //   }
  // }, [warehouseId]);

  useEffect(() => {
    if (statusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil menyimpan data Stock Opname",
        content: " ",
      });
      setShowAlert(true);
      // dispatch(resetStatus());
      // handleClose();
      // if (!isEdit) {
      //   fetchData(0, perPage);
      //   // dispatch(getWarehouseStockByWarehouse(warehouseId));
      // }
    } else if (statusState === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal menyimpan data Stock Opname",
        content:
          errorMessage.data.message === undefined
            ? ""
            : errorMessage.data.message.toString(),
      });
      setShowAlert(true);
    }
  }, [statusState]);

  useEffect(() => {
    if (data && data.constructor == Object && isEdit) {
      setWarehouseId(data.warehouseId);
      setStockOpnameNumber(data.stockOpnameNumber);
      setStockOpnameNote(data.note);
      setStockOpnameCreateBy(data.name);
      setSingleDataStockOpname(data);

      setStockOpnameDetail([])
      data.list?.map((item) => {
        setStockOpnameDetail((prevState) => [
          ...prevState,{
            id : item.id,
            productSKU : item.code,
            productId : "",
            productName : item.productName,
            variationId : item.variationId,
            productVariantName : item.variationName,
            qty : item.updateStock,
            productVariationSystemStock : item.remainingStock,
            note : item.note
          }
        ])
      })

    } else {
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (productState.length !== 0) {
      setProductList([]);
      productState.map((product) => {
        setProductList((prevState) => [
          ...prevState,
          {
            id: product.id,
            sku: product.inventorySKU,
            productName: product.name,
            label: product.inventorySKU + " - " + product.name,
            uom: product.uom,
          },
        ]);
      });
    }
  }, [productState]);

  useEffect(() => {
    if (variationState.length !== 0) {
      setVariationList([]);
      variationState.map((variant) => {
        setVariationList((prevState) => [
          ...prevState,
          {
            id: variant.id,
            label: variant.name,
            systemStock: variant.stockQty,
          },
        ]);
      });

      setSalesOrderDetailTemp([]);
      variationState.map((item) => {
        let variationName = "";
        if (item.customProductAttributeList !== null) {
          if (item.customProductAttributeList.length === 1) {
            variationName += item.customProductAttributeList[0].name;
          } else if (item.customProductAttributeList.length === 2) {
            variationName +=
                item.customProductAttributeList[0].name +
                "-" +
                item.customProductAttributeList[1].name;
          } else if (item.customProductAttributeList.length === 3) {
            variationName +=
                item.customProductAttributeList[0].name +
                "-" +
                item.customProductAttributeList[1].name +
                "-" +
                item.customProductAttributeList[2].name;
          }
        }

        setSalesOrderDetailTemp((prevState) => [
          ...prevState,
          {
            id: "",
            salesOrderId: "",
            productId: item.productId,
            productName: selectedProductSku + " - " + selectedProductName,
            variationId: item.id,
            variationName: variationName,
            price: item.regulerPriceEndCustomer,
            quantity: 0,
            priceSatuan: 0,
            note: "",
            stockQty: item.stockQty,
            weight: 0,
          },
        ]);
      });
    }
  }, [variationState]);

  const columns = [
    {
      name: "SKU",
      selector: "productSKU",
      sortable: true,
    },
    {
      name: "PRODUCT",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIANT",
      selector: "productVariantName",
      sortable: true,
    },
    {
      name: "Stock System",
      selector: "productVariationSystemStock",
      sortable: true,
    },
    {
      name: "Stock Aktual",
      selector: "qty",
      sortable: true,
    },
    {
      name: "Note",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (
        d: {
          id?: string;
        },
        index: number
      ) => (
        <>
        {
          !isEdit ? <>
          <span
            onClick={() => {
              setSelectedIndex(index);
              setDeleteAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowDeleteAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
          </> : <>-</>
        }
          
        </>
      ),
    },
  ];

  const promiseOptions = (inputValue: string) =>
      new Promise<ProductOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getProductOptions(inputValue));
            }, 1000)
        );
      });

  return (
    <div>
      {!isEdit ? (
        <>
          <Button
            className="float-right"
            style={{ width: 130, marginLeft: 10 }}
            variant="info"
            onClick={() => {
              onSaveClick();
            }}
            disabled={isLoading}
          >
            {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Save"}
          </Button>{" "}
        </>
      ) : (
        <></>
      )}
      <Button
        className="float-right"
        style={{ width: 130, marginLeft: 10 }}
        variant="outline-info"
        onClick={() => {
          onCancelClick();
        }}
      >
        Cancel
      </Button>{" "}
      {/* {isEdit ? (
        <>
          <a
            href={window.location.origin + "/stock-opname/print/" + warehouseId}
            target="_blank"
          >
            <Button
              style={{ fontSize: 14, width: 202, height: 40 }}
              variant="outline-info"
              className="float-right"
              onClick={() => {
                // dispatch(countSOPrint(salesOrderID));
              }}
            >
              Print
            </Button>
          </a>{" "}
        </>
      ) : (
        <></>
      )} */}
      <Form.Group>
        <Form.Label className="title">Stock Opname</Form.Label>
      </Form.Group>
      <Grid>
        <Card>
          <Card.Body>
            <b>
              {isEdit ? "Detail Data Stock Opname" : "Add Data Stock Opname"}
            </b>

            <Form>
              <br />

              {isEdit ? (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Number
                    </Form.Label>
                    <Col sm={5}>
                      <Form.Control
                        disabled
                        // defaultValue={internalUseDate.toISOString().substr(0, 10)}
                        value={stockOpnameNumber}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <hr />
                </>
              ) : (
                <></>
              )}

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Date
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="Date"
                    disabled
                    // defaultValue={internalUseDate.toISOString().substr(0, 10)}
                    value={stockOpnameDate}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse
                </Form.Label>
                <Col sm={5}>
                  {!isEdit ? (
                    <>
                      <Form.Control
                        as="select"
                        onChange={onWarehouseChange}
                        value={warehouseId}
                      >
                        <option>Select</option>
                        {warehouseListState.map((Item) => {
                          return (
                            <option value={Item.id}>
                              {Item.code} - {Item.name} - {Item.city}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </>
                  ) : (
                    <>
                      <Form.Control
                        as="select"
                        disabled
                        onChange={onWarehouseChange}
                        value={warehouseId}
                      >
                        <option>Select</option>
                        {warehouseListState.map((Item) => {
                          return (
                            <option value={Item.id}>
                              {Item.code} - {Item.name} - {Item.city}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </>
                  )}
                </Col>
              </Form.Group>

              <hr />

              {
                isEdit ? 
                <>
                <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    disabled
                    value={stockOpnameNote}
                  />
                </Col>
              </Form.Group></> : <>
                <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    onChange={(e) => setStockOpnameNote(e.target.value)}
                    value={stockOpnameNote}
                  />
                </Col>
              </Form.Group></>

              }

              

              <hr />

              {isEdit ? (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Create By
                    </Form.Label>
                    <Col sm={5}>
                      <Form.Control
                        disabled
                        value={stockOpnameCreateBy}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <hr />
                </>
              ) : (
                <></>
              )}

              <div className="btn-footer-group"></div>
            </Form>
          </Card.Body>
        </Card>
      </Grid>
      <Grid style={{ marginTop: "20px" }}>
        <Card>
          <Card.Body>
            {/* <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    SKU
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterInventorySKU}
                      onChange={(e) => {
                        setFilterInventorySKU(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Variant Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterVariantName}
                      onChange={(e) => {
                        setFilterVariantName(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <br />

            <Row>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Row> */}

            {!isEdit ? (
              <>
                {productList.length !== 0 ? (
                  <>
                    <Row>
                      <Button
                        variant="info"
                        style={{ width: 130, height: 45, marginLeft: 10 }}
                        type="submit"
                        onClick={() => {
                          setSelectedProductId("");
                          setSelectedVariantId("");
                          setVariantSystemStock(0);
                          setVariantActualStock(0);
                          setStockNote("");
                          setShowAddProductModal(true);
                        }}
                      >
                        Add Product
                      </Button>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Button
                        variant="info"
                        style={{ width: 180, height: 45, marginLeft: 10 }}
                        type="submit"
                        disabled
                      >
                        Loading Product List..
                      </Button>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            <hr />
            <DataTable columns={columns} data={stockOpnameDetail} noHeader />
          </Card.Body>
        </Card>
      </Grid>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAddProductModal}
        onHide={handleAddProductClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Product</Form.Label>
              {/*<Select*/}
              {/*  onChange={onSelectedProductChange}*/}
              {/*  options={productList}*/}
              {/*/>*/}
              <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                           onChange={onSelectedProductChange} />
            </Form.Group>

            {selectedProductId !== "" ? (
              <>
                <Form.Group>
                  <Form.Label>Variant</Form.Label>
                  <Select
                    onChange={onSelectedVariantChange}
                    options={variationList}
                    value={selectedVariant}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>System Stock</Form.Label>
                  <NumberFormat
                    placeholder="1"
                    thousandSeparator={true}
                    className="bootstrap-input"
                    inputmode="numeric"
                    value={variantSystemStock}
                    disabled
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Actual Stock</Form.Label>
                  <NumberFormat
                    placeholder=""
                    thousandSeparator={true}
                    className="bootstrap-input"
                    inputmode="numeric"
                    value={variantActualStock}
                    onChange={(e) =>
                      setVariantActualStock(parseInt(e.target.value))
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={stockNote}
                    onChange={(e) => setStockNote(e.target.value)}
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={handleAddProductClose}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={onConfirmAddProduct}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Product Stock</div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="input-padding">
            <Form.Label>Product</Form.Label>

            <Form.Control
              type="text"
              required
              disabled
              value={selectedProductName}
            />
          </Form.Group>

          <Form.Group className="input-padding">
            <Form.Label>UOM</Form.Label>

            <Form.Control type="text" required disabled value={selectedUOM} />
          </Form.Group>

          {/* <Form.Group className="input-padding">
            <Form.Label>Updated By</Form.Label>

            <Form.Control 
            type="text" 
            required 
            disabled 
            value={selectedLastUpdateName} />
          </Form.Group> */}

          {/* <Form.Label>
            {selectedProductCode}-{selectedProductName}
          </Form.Label> */}

          <Form.Group className="input-padding">
            <Form.Label>Stock System</Form.Label>

            {/* <Form.Control
                type="number"
                onChange={onQtyChange}
                value={productQty}
              /> */}

            <NumberFormat
              placeholder="1"
              thousandSeparator={true}
              className="bootstrap-input"
              inputmode="numeric"
              disabled
              value={selectedStockSystem}
              onValueChange={({ floatValue }) => {
                let val = floatValue ?? 0;
                setSelectedStockSystem(val);
              }}
            />
          </Form.Group>

          <Form.Group className="input-padding">
            <Form.Label>Stock Gudang</Form.Label>

            {/* <Form.Control
                type="number"
                onChange={onQtyChange}
                value={productQty}
              /> */}

            <NumberFormat
              placeholder="1"
              thousandSeparator={true}
              className="bootstrap-input"
              inputmode="numeric"
              value={selectedStock}
              onValueChange={({ floatValue }) => {
                let val = floatValue ?? 0;
                setSelectedStock(val);
              }}
            />
          </Form.Group>

          <Form.Group className="input-padding">
            <Form.Label>Note</Form.Label>

            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Note"
              onChange={onNoteChange}
              value={selectedProductNote}
            />
          </Form.Group>

          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onSaveProductClick}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDelete}
      />
      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export default StockOpnameForm;
