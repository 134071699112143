export const TableCustomStyles = {
  subHeader: {
    style: {
      paddingLeft: "4px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#FEFAF6",
      color: "",
    },
  },
};
