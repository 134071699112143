
import axios from "axios";
import { endpoint } from "../../../constants";
import { FaqModel } from "./settingFaq.model";

export default class SettingFaqApi {

    static getFaqList() {
        return axios
            .get<any>(endpoint + "​/api/FAQ/GetList")
            .then((res) => res.data);
    }

    static getFaqListByPartner(partnerType: number){
        return axios
            .get<any>(endpoint + "/api/FAQ/GetListByPartnerType?type=" + partnerType)
            .then((res) => res.data);
    }

    static createFaq(model: FaqModel) {
        return axios
            .post<any>(
                endpoint + "/api/FAQ/Create",
                model
            )
            .then((res) => res.data);
    }

    static updateFaq(model: FaqModel) {
        return axios
            .post<any>(
                endpoint + "/api/FAQ/Update",
                model
            )
            .then((res) => res.data);
    }


    static deleteFaq(faqId: string) {
        return axios
            .post<any>(endpoint + "/api/FAQ/Delete?Id=" + faqId)
            .then((res) => [
                { response: res.data.data },
            ]);
    }
}