import axios from "axios";
import moment from "moment";
import { ModalContent } from "semantic-ui-react";
import { endpoint } from "../../../constants";
import {
  CreateStockOpnameModel,
  CreateStockOpnameRequestModel,
  FilterModel,
  StockOpnameFilterModel,
  WarehouseStockFilterModel,
} from "./stockOpname.model";

export default class StockOpnameApi {

  static createWithProduct(filter: CreateStockOpnameRequestModel) {
    return axios
      .post<any>(endpoint + "/api/StockOpname/CreateWithProduct", filter)
      .then((res) => res.data.data);
  }

  static getListWithPagination(filter: StockOpnameFilterModel) {
    return axios
      .post<any>(endpoint + "/api/StockOpname/GetListMaster", filter)
      .then((res) => res.data.data);
  }

  static getSingleDataStockOpname(id: string) {
    return axios
      .get<any>(endpoint + "/api/StockOpname/GetSingleByMaster?stockOpnameMasterId=" + id)
      .then((res) => res.data.data);
  }

  static getWarehouseList() {
    return axios
      .get<any>(endpoint + "​/api/Warehouse/GetList")
      .then((res) => res.data.data);
  }

  static getProductByWarehouse(warehouseId: string) {
    return axios
      .get<any>(
        endpoint + "/api/Product/GetListByWarehouse?warehouseId=" + warehouseId
      )
      .then((res) => res.data.data);
  }

  static getWarehouseStockByWarehouse(warehouseId: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/WarehouseStock/GetListByWarehouse?warehouseId=" +
          warehouseId
      )
      .then((res) => res.data.data);
  }

  static getWarehouseStockByWarehouseWithPaging(
    model: WarehouseStockFilterModel
  ) {
    return axios
      .post<any>(
        endpoint + "/api/WarehouseStock/GetListByWarehouseWithPaging",
        model
      )
      .then((res) => res.data.data);
  }

  static createStockopname(model: CreateStockOpnameModel) {
    return axios
      .post<any>(endpoint + "/api/StockOpname/Create", model)
      .then((res) => res.data.data);
  }

  // static ExportToExcel(model : FilterModel){
  //     return axios
  //     .post<any>(
  //         endpoint + "/api/StockOpname/StockOpnameExportToExcel",
  //         model
  //     )
  //     .then((res) => res.data.data);
  // }

  static async ExportToExcel(model: FilterModel) {
    return axios
      .post<any>(
        endpoint + "/api/StockOpname/StockOpnameExportToExcel",
        model,
        { responseType: "blob" }
      )
      .then((res: any) => {
        console.log(res);

        const url = URL.createObjectURL(new Blob([res.data]));

        console.log("url : " + url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Laporan Stock Opname - ${moment(new Date()).format(
            "DD/MM/YYYY hh:mm:ss A"
          )}.xlsx`
        );

        document.body.appendChild(link);

        console.log("link : " + link);

        link.click();
      })
      .catch((err: any) => console.log(err));
  }
}
