import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { ApiErrorResponse } from '../../..';
import WarehouseApi from './warehouse.api';
import { CityModel, WarehouseModel, DistrictModel, ProvinceModel, FilterModel } from './warehouse.model';

export interface WarehouseSlice {
    name? :string;
    list?: WarehouseModel[];
    single?: WarehouseModel;
    listProvince?: ProvinceModel[];
    listCity?: CityModel[];
    listDistrict?: DistrictModel[];
    totalRow? : number;
    isLoading?: boolean;    
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const createWarehouse = createAsyncThunk(
    'createCustomerState/createCustomer',
    async (Model: WarehouseModel, {rejectWithValue}) => {
        try {
            return await WarehouseApi.createWarehouse(Model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getList = createAsyncThunk(
     'getCustomerListState/getCustomerList',
     async ( _:string | undefined = undefined, {rejectWithValue}) => {
         try {
             return await WarehouseApi.getWarehouseList();
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 );

 export const getListWithPaging = createAsyncThunk(
    'getWarehouseListWithPagingState/getWarehouseListWithPaging',
    async ( model: FilterModel, {rejectWithValue}) => {
        try {
            return await WarehouseApi.getWarehouseListWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleItem = createAsyncThunk(
     'CustomerSingleItemState/CustomerSingleItem',
    async (customerId: string, {rejectWithValue}) => {
         try {
             return await WarehouseApi.getSingleItem(customerId);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 )

 export const updateWarehouse = createAsyncThunk(
     'CustomerState/updateCustomer',
     async (model: WarehouseModel, {rejectWithValue}) => {
         try {
             return await WarehouseApi.updateWarehouse(model);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
)

export const deleteWarehouse = createAsyncThunk(
    'warehouseState/deleteWarehouse',
    async (warehouseId: string, {rejectWithValue}) =>{
        try {
            return await WarehouseApi.deleteWarehouse(warehouseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getProvince = createAsyncThunk(
    'ProvinceState/Province',
    async (_:string | undefined = undefined, {rejectWithValue}) =>{
        try {
            return await WarehouseApi.getProvince();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getCity = createAsyncThunk(
    'CityState/City',
    async (provinceId: string, {rejectWithValue}) =>{
        try {
            return await WarehouseApi.getCity(provinceId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getDistrict = createAsyncThunk(
    'DistrictState/District',
    async (cityId: string, {rejectWithValue}) =>{
        try {
            return await WarehouseApi.getDistrict(cityId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)


const WarehouseSlice = createSlice({
    name: 'WarehouseState',
    initialState: {} as WarehouseSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        resetSingle: (state) => {
            state.single = {} as WarehouseModel
        },
        resetStatus: (state) => {
            state.status = "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createWarehouse.pending, (state) => {
          state.isLoading = true;
          state.status = "create";
        });
        builder.addCase(createWarehouse.fulfilled, (state, { payload }) => {
          state.name = new Date().toISOString();
          state.isLoading = false;
          state.status = "success";
        });
        builder.addCase(createWarehouse.rejected, (state, { payload }) => {
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
          state.status = "failed";
        });
        builder.addCase(getSingleItem.pending, (state) =>{
            state.single = undefined;
            state.isLoading = true;
        });
        builder.addCase(getSingleItem.fulfilled, (state, {payload})=>{
            state.single = payload;
            state.isLoading = false;
        })
        builder.addCase(getSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })
        builder.addCase(getList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload})=> {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getListWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListWithPaging.fulfilled, (state, {payload})=> {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getListWithPaging.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateWarehouse.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        })
        builder.addCase(updateWarehouse.fulfilled, (state, {payload})=> {
            state.isLoading = false;
            state.status = "success";
            state.name = new Date().toISOString();
        })
        builder.addCase(updateWarehouse.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.status = "failed";
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteWarehouse.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteWarehouse.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.status = "success-delete";
        })
        builder.addCase(deleteWarehouse.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(getProvince.pending, (state) => {
            state.isLoading = true;
         })
        builder.addCase(getProvince.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.listProvince = payload;
         })
        builder.addCase(getProvince.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(getCity.pending, (state) => {
            state.isLoading = true;
         })
        builder.addCase(getCity.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.listCity = payload;
         })
        builder.addCase(getCity.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(getDistrict.pending, (state) => {
             state.isLoading = true;
        })
        builder.addCase(getDistrict.fulfilled, (state, {payload}) => {
             state.isLoading = false;
             state.listDistrict = payload;
        })
        builder.addCase(getDistrict.rejected, (state, {payload}) => {
             state.isLoading = false;
             state.error = payload as ApiErrorResponse<any>;
        })
      },
})

export const { setFilter, resetSingle, resetStatus } = WarehouseSlice.actions;
export default WarehouseSlice.reducer;