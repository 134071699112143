import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { NotificationFilterModel, NotificationModel } from "./adminPanel.model";
import NotificationApi from "./notification.api";

export interface NotificationSlice {
    list? : NotificationModel[],
    keepBarangList? : NotificationModel[],
    keepList? : NotificationModel[],
    codList? : NotificationModel[],
    piutangList? : NotificationModel[],
    dropshipList? : NotificationModel[],
    paymentConfirmList? : NotificationModel[],
    tokenStatus? : boolean;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getAllAdminNotification = createAsyncThunk(
    'getAllAdminNotificationState/getAllAdminNotification',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotification();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAllAdminNotificationByType = createAsyncThunk(
    'getAllAdminNotificationByTypeState/getAllAdminNotificationByType',
    async (model : NotificationFilterModel, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotificationWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAllAdminKeepBarangNotificationByType = createAsyncThunk(
    'getAllAdminKeepBarangNotificationByTypeState/getAllAdminKeepBarangNotificationByType',
    async (model : NotificationFilterModel, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotificationWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAllAdminKeepNotificationByType = createAsyncThunk(
    'getAllAdminKeepNotificationByTypeState/getAllAdminKeepNotificationByType',
    async (model : NotificationFilterModel, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotificationWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAllAdminCODNotificationByType = createAsyncThunk(
    'getAllAdminCODNotificationByTypeState/getAllAdminCODNotificationByType',
    async (model : NotificationFilterModel, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotificationWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAllAdminPiutangNotificationByType = createAsyncThunk(
    'getAllAdminPiutangNotificationByTypeState/getAllAdminPiutangNotificationByType',
    async (model : NotificationFilterModel, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotificationWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAllAdminDropshipNotificationByType = createAsyncThunk(
    'getAllAdminDropshipNotificationByTypeState/getAllAdminDropshipNotificationByType',
    async (model : NotificationFilterModel, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotificationWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAllAdminPaymentConfirmNotificationByType = createAsyncThunk(
    'getAllAdminPaymentConfirmNotificationByTypeState/getAllAdminPaymentConfirmNotificationByType',
    async (model : NotificationFilterModel, { rejectWithValue }) => {
        try {
            return await NotificationApi.getAllAdminNotificationWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const checkTokenStatus = createAsyncThunk(
    'checkTokenStatusState/checkTokenStatus',
    async (token : string, { rejectWithValue }) => {
        try {
            return await NotificationApi.checkUserToken(token);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);


const NotificationSlice = createSlice({
    name: 'NotificationState',
    initialState: {} as NotificationSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllAdminNotification.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminNotification.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminNotification.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getAllAdminNotificationByType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminNotificationByType.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminNotificationByType.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getAllAdminKeepBarangNotificationByType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminKeepBarangNotificationByType.fulfilled, (state, { payload }) => {
            state.keepBarangList = payload.list;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminKeepBarangNotificationByType.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });


        builder.addCase(getAllAdminKeepNotificationByType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminKeepNotificationByType.fulfilled, (state, { payload }) => {
            state.keepList = payload.list;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminKeepNotificationByType.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getAllAdminCODNotificationByType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminCODNotificationByType.fulfilled, (state, { payload }) => {
            state.codList = payload.list;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminCODNotificationByType.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getAllAdminPiutangNotificationByType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminPiutangNotificationByType.fulfilled, (state, { payload }) => {
            state.piutangList = payload.list;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminPiutangNotificationByType.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getAllAdminDropshipNotificationByType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminDropshipNotificationByType.fulfilled, (state, { payload }) => {
            state.dropshipList = payload.list;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminDropshipNotificationByType.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getAllAdminPaymentConfirmNotificationByType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAdminPaymentConfirmNotificationByType.fulfilled, (state, { payload }) => {
            state.paymentConfirmList = payload.list;
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(getAllAdminPaymentConfirmNotificationByType.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(checkTokenStatus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(checkTokenStatus.fulfilled, (state, { payload }) => {
            state.tokenStatus = payload;
            state.status = "success-check-token";
            state.isLoading = false;
        });
        builder.addCase(checkTokenStatus.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    }
})

export const { resetStatus } = NotificationSlice.actions;
export default NotificationSlice.reducer;