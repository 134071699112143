import React from 'react'
import { Container, Form } from "react-bootstrap";
import WalletTable from '../components/WalletTable';

const WalletPage = () => {
  return (
    <Container className="content-body" fluid>
      {/* <Form.Group>
        <Form.Label className="page-title">Wallet</Form.Label>
      </Form.Group> */}
      <WalletTable/>
    </Container>
  )
}

export default WalletPage