import React from "react";
import { Modal, Button } from "react-bootstrap";
import cross from "../../assets/vector/cross.png";
import trashIcon from "../../assets/vector/trash.png";

import "./alert.css";

const DeleteMessage = ({
  show = false,
  variant = "",
  title = "",
  content = "",
  ...props
}: AlertMessageProps) => {
  return (
    <>
      <Modal
        show={show}
        dialogClassName="my-modal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <img
            className="float-right"
            style={{ width: 25 }}
            src={cross}
            onClick={props.onAlertCloseAction}
          />
          <div style={{ paddingTop: 30 }} className="text-center">
            {variant === "delete" && <img src={trashIcon} width="64" />}

            <p className="text">{title}</p>
            <p>{content}</p>
          </div>
        </Modal.Body>
        <div
          className="text-center"
          style={{ paddingBottom: 30, paddingTop: 20 }}
        >
          <Button
            variant="outline-info"
            style={{ width: 123 }}
            onClick={props.onAlertCloseAction}
          >
            Tidak
          </Button>
          {"   "}

          <Button
            variant="info"
            style={{ width: 123 }}
            onClick={props.onDeleteAction}
          >
            Hapus
          </Button>
        </div>
      </Modal>
    </>
  );
};

export interface AlertMessageProps {
  show: boolean;
  variant?: string;
  title?: string;
  content?: string;
  onAlertCloseAction: () => void;
  onDeleteAction: () => void;
}

export default DeleteMessage;
