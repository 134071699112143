import axios from "axios";
import { endpoint } from "../../../constants";
import { CreateWarehouseSettingAdminModel, UpdateWarehouseSettingModel } from "./settingWarehouse.model";

export default class WarehouseSettingApi {

 static getResellerExpeditionSettingList() {
  return axios
      .get<any>(endpoint + "​/api/WarehouseSetting/GetListByPartner?partnerType="+2)
      .then((res) => res.data.data);
}

static getStockistExpeditionSettingList() {
    return axios
        .get<any>(endpoint + "​/api/WarehouseSetting/GetListByPartner?partnerType="+1)
        .then((res) => res.data.data);
  }

  static getEndCustomerExpeditionSettingList() {
    return axios
        .get<any>(endpoint + "​/api/WarehouseSetting/GetListByPartner?partnerType="+0)
        .then((res) => res.data.data);
  }

  static getAdminSettingList() {
    return axios
        .get<any>(endpoint + "​/api/WarehouseSetting/GetListAdmin")
        // .get<any>(endpoint + "/api/WarehouseSetting/GetListAdmin")

        .then((res) => res.data.data);
  }

  static deleteAdminWarehouseSetting(id: string) {
    return axios
        .post<any>(
            endpoint + "/api/WarehouseSetting/DeleteSettingAdminByUser?userId="+id,
        )
        .then((res) => res.data.data);
}

static updatePartnerWarehouseSetting(model: UpdateWarehouseSettingModel) {
    return axios
        .post<any>(
            endpoint + "/api/WarehouseSetting/Update",
            model
        )
        .then((res) => res.data.data);
}

static getAdminList() {
    return axios
        // .get<any>(endpoint + "​/api/user/getuserbyrole?roleid=0a2cd835-af78-42c5-877b-1448cc4d5172")
        
        .get<any>(endpoint + "/api/User/GetUserAdmin")
        .then((res) => res.data.data);
}

static createAdminWarehouseSetting(model: CreateWarehouseSettingAdminModel) {
    return axios
        .post<any>(
            endpoint + "/api/WarehouseSetting/CreateAdmin",
            model
        )
        .then((res) => res.data.data);
}

}