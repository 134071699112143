import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button, Container } from "react-bootstrap";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getAllWarehouse, getReturList, deleteRetur } from "../Retur.api";
import moment from "moment";
import {
  FaBook,
  FaCheckSquare,
  FaEdit,
  FaNewspaper,
  FaPaperclip,
  FaPaperPlane,
  FaPlusSquare,
  FaRegAddressBook,
  FaStickyNote,
  FaTrash,
} from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { BsEyeFill } from "react-icons/bs";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const ReturTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { returList, warehouseList } = useSelector(
    (state: RootState) => state.retur
  );

  const initialValue = {
    number: "",
    soNumber: "",
    startDate: "",
    endDate: "",
    warehouseId: null,
    statusApproval: null,
    skip: 0,
    take: 10,
    isActive: true,
  };

  const [filterData, setFilterData] = useState<any>(initialValue);

  useEffect(() => {
    dispatch(getAllWarehouse());
    dispatch(getReturList(filterData));
  }, []);

  const changeFilter = (e: any) => {
    let { name, value } = e.target;
    if (name === "statusAproval") {
      value = parseInt(value);
    } else if (name === "isActive") {
      value = value === 1 ? true : false;
    } else if (name === "warehouseId") {
      value = value === "0" ? null : value;
    } else if (name === "status") {
      value = parseInt(value);
    }
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (JSON.stringify(filterData) === JSON.stringify(initialValue)) {
      dispatch(getReturList(filterData));
    }
  }, [filterData]);

  const handleResetFilter = () => {
    setFilterData(initialValue);
  };

  const handleDelete = (d: any) => {
    dispatch<any>(deleteRetur(d.id))
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(getReturList(filterData));
      });
  };

  const columns = [
    {
      name: "SO NUMBER",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "RETUR NUMBER",
      selector: "number",
      sortable: true,
    },
    {
      name: "SO NUMBER (NEW)",
      selector: "newSONumber",
      sortable: true,
    },
    {
      name: "DATE",
      selector: (d: any) => (
        <span>
          {moment(new Date(d.createdDate)).format("DD/MM/YYYY HH:mm")}
        </span>
      ),
      sortable: true,
    },
    {
      name: "WAREHOUSE",
      selector: "warehouseName",
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: any) => <span>{d.isActive ? "Aktif" : "Tidak Aktif"}</span>,
      sortable: true,
    },
    {
      name: "CUSTOMER TYPE",
      selector: "customerType",
      sortable: true,
    },
    {
      name: "CUSTOMER NAME",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "APPROVAL STATUS",
      selector: "statusName",
      sortable: true,
    },
    {
      name: "ACTION",
      width: "280px",
      selector: (d: any) => (
        <div className="retur-table-button">
          {d.status === 1 && d.newSONumber === "-" ? (
            <>
              <FaPlusSquare
                onClick={() => history.push(`/sales-order/${d.id}`)}
              />{" "}
              {"   "}
            </>
          ) : (
            <></>
          )}
          {d.status === 0 && (
            <FaEdit onClick={() => history.push(`/retur/edit/${d.id}`)} />
          )}

          {d.complaintId !== null && d.complaintId !== "" && d.complaintId !== undefined ? (
            <>
              <Link to={{ pathname: "/complain-detail/" + d.complaintId }}>
                <BsEyeFill />
              </Link>
            </>
          ) : (
            <></>
          )}

          {"   "}
          {d.status === 0 ? (
            <>
              <Button
                className="approval-button"
                size="sm"
                onClick={() => history.push(`/retur/approval/${d.id}`)}
              >
                Approval
              </Button>{" "}
              {"   "}
              <FaTrash color="red" onClick={() => handleDelete(d)} /> {"   "}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Retur</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="float-right"
            style={{ width: 130, marginLeft: 10 }}
            variant="outline-info"
          >
            Print
          </Button>{" "}
          <Button
            className="float-right"
            style={{ width: 160, marginLeft: 10 }}
            variant="outline-info"
          >
            Export to excel
          </Button>{" "}
        </Col>
      </Row>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Retur Number</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    placeholder="Retur Number"
                    type="text"
                    name="number"
                    value={filterData.number}
                    onChange={changeFilter}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Date</Form.Label>
                <Col sm={6}>
                  <Form.Control placeholder="Cust. Name" type="date" />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>SO Number</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    placeholder="SO Number"
                    type="text"
                    name="soNumber"
                    value={filterData.soNumber}
                    onChange={changeFilter}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Retur Status</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    placeholder="No. Resi"
                    as="select"
                    name="isActive"
                    value={filterData.isActive ? 1 : 0}
                    onChange={changeFilter}
                  >
                    <option value={1}>Aktif</option>
                    <option value={0}>Tidak Aktif</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Warehouse</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    placeholder="Warehouse"
                    as="select"
                    name="warehouseId"
                    value={filterData.warehouseId}
                    onChange={changeFilter}
                  >
                    <option value={0}>All</option>
                    {warehouseList.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.code} - {item.name} - {item.city}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Status Approval</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="select"
                    name="status"
                    value={filterData.status}
                    onChange={changeFilter}
                  >
                    <option value={0}>Pending</option>
                    <option value={1}>Approve</option>
                    <option value={2}>Reject</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ width: 130, margin: 10 }}
                variant="outline-info"
                onClick={handleResetFilter}
              >
                Reset
              </Button>{" "}
              <Button
                style={{ width: 160, margin: 10 }}
                variant="info"
                onClick={() => dispatch(getReturList(filterData))}
              >
                Filter
              </Button>{" "}
            </Col>
          </Row>
          <br />
          <DataTable
            noHeader
            customStyles={TableCustomStyles}
            columns={columns}
            data={returList.list}
            pagination
            paginationServer
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
            // onChangeRowsPerPage={handlePerRowChange}
            // onChangePage={handlePageChange}
            // paginationTotalRows={totalRowState}
          />
        </Card>
      </Grid>
    </Container>
  );
};

export default ReturTable;
