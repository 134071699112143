import React from 'react'
import { Container } from 'react-bootstrap'
import LaporanKartuStock from '../components/LaporanKartuStock'

const LaporanKartuStockPage = () => {
  return (
    <Container className="content-body" fluid>
      <LaporanKartuStock/>
    </Container>
  )
}

export default LaporanKartuStockPage