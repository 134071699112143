import React, { ChangeEvent, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Grid } from "semantic-ui-react";
import { Select } from "antd";

import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  Tabs,
  Tab,
  TabContent,
  Modal,
} from "react-bootstrap";
import edit from "./../../../../assets/vector/edit.png";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeliveryReceiptListWithPaging,
  getUserAdminGudangList,
  getUserAdminQCList,
  getUserAdminPackingList,
  getUserAdminEkspedisiList,
  updateDeliveryReceipt,
  resetStatus,
  updatePickUpDateBatch,
} from "../receiptDelivery.reducer";
import { Delivery } from "../receiptDelivery.model";
import AlertMessage from "../../../../components/alertMessage";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import locale from "antd/lib/date-picker/locale/id_ID";
import {MAX_PAGING_OPTIONS} from "../../../../constants";
import {transactionStatus} from "../../../report/orderDataReport/constants";

const ReceiptDeliveryTable = () => {
  const dispatch = useDispatch();

  const receiptDeliveryList = useSelector(
    (state: RootState) => state.receiptDelivery.list ?? []
  );

  const adminGudangListState = useSelector(
    (state: RootState) => state.receiptDelivery.userAdminGudangList ?? []
  );

  const adminQCListState = useSelector(
    (state: RootState) => state.receiptDelivery.userAdminQCList ?? []
  );

  const adminPackingListState = useSelector(
    (state: RootState) => state.receiptDelivery.userAdminPackingList ?? []
  );

  const adminEkspedisiListState = useSelector(
    (state: RootState) => state.receiptDelivery.userAdminEkspedisiList ?? []
  );

  const deliveryReceiptStatusState = useSelector(
    (state: RootState) => state.receiptDelivery.status ?? ""
  );

  const totalRowState = useSelector(
    (state: RootState) => state.receiptDelivery.totalRow ?? 0
  );

  const [filterIvoiceNumber, setFilterIvoiceNumber] = useState("");
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterExpeditionNumber, setFilterExpeditionNumber] = useState("");
  const [filterSenderName, setFilterSenderName] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterShipmentStatus, setFilterShipmentStatus] = useState<
    number | null
  >(null);
  const [filterRecipientName, setFilterRecipientName] = useState("");

  const [filterTempShipmentStatus, setFilterTempShipmentStatus] = useState(9);
  const [filterExpeditionName, setfilterExpeditionName] = useState("");
  const [filterNote, setFilterNote] = useState("");
  const [filterTransactionStatus, setFilterTransactionStatus] = useState<number | null>(null);

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const filteredItems = receiptDeliveryList;

  const [showModal, setShowModal] = useState(false);
  const [showSetPickUpDateModal, setShowSetPickUpDateModal] = useState(false);
  const [showResetPickUpDateModal, setShowResetPickUpDateModal] =
    useState(false);

  const [selectedData, setSelectedData] = useState({} as Delivery);

  const [selectedId, setSelectedId] = useState("");
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
  const [selectedSOId, setSelectedSOId] = useState("");
  const [selectedSONumber, setSelectedSONumber] = useState("");
  const [selectedReceiptNumber, setSelectedReceiptNumber] = useState("");
  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [selectedPartnerName, setSelectedPartnerName] = useState("");
  const [selectedOrderNameFromSO, setSelectedOrderNameFromSO] = useState("");
  const [selectedNameSentTo, setSelectedNameSentTo] = useState("");
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedProviceId, setSelectedProviceId] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");
  const [selectedSubDistrictId, setSelectedSubDistrictId] = useState("");
  const [selectedPostalCode, setSelectedPostalCode] = useState("");
  const [selectedPickUpDate, setSelectedPickUpDate] = useState("");
  const [selectedUserAdminGudangId, setSelectedUserAdminGudangId] =
    useState("");
  const [selectedUserAdminGudangName, setSelectedUserAdminGudangName] =
    useState("");
  const [selectedUserAdminQCId, setSelectedUserAdminQCId] = useState("");
  const [selectedUserAdminQCName, setSelectedUserAdminQCName] = useState("");
  const [selectedUserAdminPackingId, setSelectedUserAdminPackingId] =
    useState("");
  const [selectedUserAdminPackingName, setSelectedUserAdminPackingName] =
    useState("");
  const [selectedUserAdminEkspedisiId, setSelectedUserAdminEkspedisiId] =
    useState("");
  const [selectedUserAdminEkspedisiName, setSelectedUserAdminEkspedisiName] =
    useState("");
  const [selectedShippingStatusEnum, setSelectedShippingStatusEnum] =
    useState(0);
  const [selectedExpeditionName, setSelectedExpeditionName] = useState("");
  const [selectedCreateDate, setSelectedCreateDate] = useState("");

  const [isPickUpDateEmpty, setisPickUpDateEmpty] = useState(true);

  const [UpdatePickUpDateBatch, setUpdatePickUpDateBatch] = useState("");

  const [isSelectingRows, setIsSelectingRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onSelectedSONumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedSONumber(e.target.value);
  };

  const onSelectedReceiptNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedReceiptNumber(e.target.value);
  };

  const onSelectedExpeditionNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedExpeditionName(e.target.value);
  };

  const onSelectedPartnerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPartnerName(e.target.value);
  };

  const onSelectedAdminGudangIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedUserAdminGudangId(e.target.value);
    setSelectedUserAdminGudangName(e.target.value.split("&")[1]);
  };

  const onSelectedAdminQCIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedUserAdminQCId(e.target.value);
    setSelectedUserAdminQCName(e.target.value.split("&")[1]);
  };

  const onSelectedAdminPackingIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedUserAdminPackingId(e.target.value);
    setSelectedUserAdminPackingName(e.target.value.split("&")[1]);
  };

  const onSelectedAdminEkspedisiIdChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedUserAdminEkspedisiName(e.target.value.split("&")[1]);
    setSelectedUserAdminEkspedisiId(e.target.value);
  };

  const onSaveClick = () => {
    dispatch(
      updateDeliveryReceipt({
        id: selectedId,
        invoiceId: selectedInvoiceId,
        salesOrderId: selectedSOId,
        soNumber: selectedSONumber,
        resiNumber: selectedReceiptNumber,
        customerName: selectedPartnerName,
        orderNameFromSO: selectedOrderNameFromSO,
        adminGudang: selectedUserAdminGudangName,
        adminGudangId: selectedUserAdminGudangId.split("&")[0],
        adminQC: selectedUserAdminQCName,
        adminQCId: selectedUserAdminQCId.split("&")[0],
        adminEkspedisi: selectedUserAdminEkspedisiName,
        adminEkspedisiId: selectedUserAdminEkspedisiId.split("&")[0],
        adminPacking: selectedUserAdminPackingName,
        adminPackingId: selectedUserAdminPackingId.split("&")[0],
        shippingStatus: selectedShippingStatusEnum,
        nameSentTo: selectedNameSentTo,
        address: selectedAddress,
        phoneNumber: selectedPhoneNumber,
        countryId: selectedCountryId,
        provinceId: selectedProviceId,
        cityId: selectedCityId,
        subDistrictId: selectedSubDistrictId,
        postalCode: selectedPostalCode,
        pickupDate: selectedPickUpDate === "" ? null : selectedPickUpDate,
        createdDate: selectedCreateDate,
      } as Delivery)
    );
    setShowModal(false);
  };

  const onSaveSetPickUpDate = () => {
    dispatch(
      updatePickUpDateBatch({
        idList: selectedRows,
        isAllDelivery: false,
        isPickupDateNull: false,
        date: UpdatePickUpDateBatch,
      })
    );
  };

  const onSaveResetPickUpDate = () => {
    dispatch(
      updatePickUpDateBatch({
        idList: selectedRows,
        isAllDelivery: false,
        isPickupDateNull: true,
        date: null,
      })
    );
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSetPickUpClose = () => {
    setUpdatePickUpDateBatch("");
    setShowSetPickUpDateModal(false);
  };

  const handleResetPickUpClose = () => {
    setShowResetPickUpDateModal(false);
  };

  const onResetClick = () => {
    setFilterCustomerName("");
    setFilterExpeditionNumber("");
    setFilterIvoiceNumber("");
    setFilterSenderName("");
    setFilterShipmentStatus(null);
    setFilterTempShipmentStatus(8);
    setFilterStartDate("");
    setFilterEndDate("");
    setfilterExpeditionName("");
    setFilterRecipientName("");
    setFilterTransactionStatus(null);
    setFilterNote("");
    onFilterClick();
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onSetPickupDate = () => {
    setShowSetPickUpDateModal(true);
  };

  const onResetPickUpDate = () => {
    setShowResetPickUpDateModal(true);
  };

  const handleSelectedRows = (e: any) => {
    setSelectedRows(
      e.selectedRows.map((item: any) => {
        return item.id;
      })
    );
    if (e.selectedCount >= 1) {
      setIsSelectingRows(true);
    } else {
      setIsSelectingRows(false);
    }
    console.log(e);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);
    dispatch(
      getDeliveryReceiptListWithPaging({
        soNumber: filterIvoiceNumber,
        resiNumber: filterExpeditionNumber,
        customerName: filterCustomerName,
        senderName: filterSenderName,
        expeditionName: filterExpeditionName,
        ReceiverName: filterRecipientName,
        shippingStatus: filterShipmentStatus,
        startDate: filterStartDate,
        endDate: filterEndDate,
        note: filterNote,
        transactionStatus: filterTransactionStatus,
        skip: page,
        take: perPage,
      })
    );
  };

  const onEditItemClicked = (
    id: string,
    invoiceId: string,
    salesOrderId: string,
    soNumber: string,
    resiNumber: string,
    customerName: string,
    orderNameFromSO: string,
    adminGudang: string,
    adminGudangId: string,
    adminQC: string,
    adminQCId: string,
    adminEkspedisi: string,
    adminEkspedisiId: string,
    adminPacking: string,
    adminPackingId: string,
    shippingStatus: number,
    nameSentTo: string,
    address: string,
    phoneNumber: string,
    countryId: string,
    provinceId: string,
    cityId: string,
    subDistrictId: string,
    postalCode: string,
    createdDate: string,
    expeditionName: string,
    pickUpDate: string
  ) => {
    setSelectedId(id);
    setSelectedInvoiceId(invoiceId);
    setSelectedSOId(salesOrderId);
    setSelectedSONumber(soNumber);
    setSelectedPartnerName(customerName);
    setSelectedOrderNameFromSO(orderNameFromSO);
    setSelectedAddress(address);
    setSelectedCreateDate(createdDate);
    setSelectedReceiptNumber(resiNumber);
    setSelectedShippingStatusEnum(shippingStatus);
    setSelectedNameSentTo(nameSentTo);
    setSelectedPhoneNumber(phoneNumber);
    setSelectedCountryId(countryId);
    setSelectedProviceId(provinceId);
    setSelectedCityId(cityId);
    setSelectedSubDistrictId(subDistrictId);
    setSelectedPostalCode(postalCode);

    setSelectedUserAdminGudangId(adminGudangId + "&" + adminGudang);
    setSelectedUserAdminQCId(adminQCId + "&" + adminQC);
    setSelectedUserAdminPackingId(adminPackingId + "&" + adminPacking);
    setSelectedUserAdminEkspedisiId(adminEkspedisiId + "&" + adminEkspedisi);

    setSelectedUserAdminGudangName(adminGudang);
    setSelectedUserAdminQCName(adminQC);
    setSelectedUserAdminPackingName(adminPacking);
    setSelectedUserAdminEkspedisiName(adminEkspedisi);
    setSelectedExpeditionName(expeditionName);
    setSelectedPickUpDate(pickUpDate);
    
    if(pickUpDate === null || pickUpDate === undefined || pickUpDate === ""){
      setisPickUpDateEmpty(true)
    } else setisPickUpDateEmpty(false)

    dispatch(getUserAdminGudangList());
    dispatch(getUserAdminQCList());
    dispatch(getUserAdminPackingList());
    dispatch(getUserAdminEkspedisiList());
  };

  useEffect(() => {
    if (deliveryReceiptStatusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil Update Data",
        content: " ",
      });
      setShowAlert(true);
      setShowResetPickUpDateModal(false);
      setShowSetPickUpDateModal(false);
      setUpdatePickUpDateBatch("");
      fetchData(0, perPage);
      dispatch(resetStatus());
    } else if (deliveryReceiptStatusState === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal Update Data",
        content: " ",
      });
      setShowAlert(true);
      dispatch(resetStatus());
    }
  }, [deliveryReceiptStatusState]);

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getUserAdminGudangList());
    dispatch(getUserAdminQCList());
    dispatch(getUserAdminPackingList());
    dispatch(getUserAdminEkspedisiList());
  }, []);

  const onPickUpDateChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      console.log(value._d.toISOString());
      setSelectedPickUpDate(date.toISOString());
    }
  };

  const onPickUpDateBatchChange = (value: any, dateString: any) => {
    var date = value._d;
    date.setHours(date.getHours() + 7);
    setUpdatePickUpDateBatch(date.toISOString());
  }

  const onSetTimeClick = () => {
    setisPickUpDateEmpty(false)
  }

  // console.log("Admin Packing : " + selectedUserAdminPackingId)

  const columns = [
    {
      name: "Nomor SO",
      selector: (d: {
        soNumber: string;
        resiNumber: string;
        shippingStatus: number;
        salesOrderId: string;
      }) => (
        <span>
          {d.resiNumber === "" ||
          d.resiNumber === undefined ||
          d.resiNumber === null ||
          d.shippingStatus === 0 ? (
            <>
              <Link
                to={{
                  pathname: `/customer-so-detail/${d.salesOrderId}`,
                }}
              >
                <p style={{ color: "red" }}>{d.soNumber}</p>
              </Link>
            </>
          ) : (
            <>
              {/* <p>{d.soNumber}</p> */}
              <Link
                to={{
                  pathname: `/customer-so-detail/${d.salesOrderId}`,
                }}
              >
                <p>{d.soNumber}</p>
              </Link>
            </>
          )}
        </span>
      ),
      sortable: true,
    },
    // {
    //   name: "Status Order",
    //   selector: (d: { shippingStatus: number }) => (
    //     <p style={{ margin: 10 }}>
    //       {d.shippingStatus === 0 && <span>Unpaid</span>}
    //       {d.shippingStatus === 1 && <span>Paid</span>}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Nomor Resi",
      selector: "resiNumber",
      sortable: true,
    },
    {
      name: "Nama Pembeli",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "Delivery",
      selector: "address",
      sortable: true,
    },
    {
      name: "Nama Pengirim",
      selector: "senderName",
      sortable: true,
    },
    {
      name: "Nama Penerima",
      selector: (d: { nameSentTo: string; address: string }) => (
        <p>
          <span>
            {d.nameSentTo} , {d.address}
          </span>
        </p>
      ),
      sortable: true,
    },
    {
      name: "Admin Gudang",
      selector: "adminGudang",
      sortable: true,
    },
    {
      name: "Admin QC",
      selector: "adminQC",
      sortable: true,
    },
    {
      name: "Admin Packing",
      selector: "adminPacking",
      sortable: true,
    },
    {
      name: "Admin Ekspedisi",
      selector: "adminEkspedisi",
      sortable: true,
    },
    {
      name: "Ekspedisi",
      selector: "expeditionName",
      sortable: true,
    },
    {
      name: "Berat",
      selector: (d: { totalWeight: number }) => (
        <p>{d.totalWeight !== undefined && <span>{d.totalWeight} Kg</span>}</p>
      ),
      sortable: true,
    },
    {
      name: "Date Order",
      selector: (d: { createdDate: string }) => (
        <p>
          {d.createdDate !== undefined && (
            <span>
              {d.createdDate.substr(8, 2)}/{d.createdDate.substr(5, 2)}/
              {d.createdDate.substr(0, 4)} - {d.createdDate.substr(11, 5)}
            </span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Pick Up Date",
      selector: (d: { pickupDate: string }) => (
        <p>
          {d.pickupDate !== null && (
            <span>
              {d.pickupDate.substr(8, 2)}/{d.pickupDate.substr(5, 2)}/
              {d.pickupDate.substr(0, 4)} - {d.pickupDate.substr(11, 5)}
            </span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Transaction Status",
      selector: (d: { transactionStatus: number }) => (
        <p style={{ margin: 10 }}>
         {d.transactionStatus === 0 && <span>Waiting For Payment</span>}
          {d.transactionStatus === 1 && <span>Payment Confirmed</span>}
          {d.transactionStatus === 2 && <span>Payment Received</span>}
          {d.transactionStatus === 3 && <span>Process</span>}
          {d.transactionStatus === 4 && <span>Delivery</span>}
          {d.transactionStatus === 5 && <span>Received</span>}
          {d.transactionStatus === 6 && <span>Delivered</span>}
          {d.transactionStatus === 11 && <span>Complain</span>}
          {d.transactionStatus === 12 && <span>Cancel</span>}
          {d.transactionStatus === 13 && <span>Completed</span>}
          {d.transactionStatus === 14 && <span>Rejected</span>}
          {d.transactionStatus === 15 && <span>Complain Process</span>}
          {d.transactionStatus === 16 && <span>Complain Reject</span>}
          {d.transactionStatus === 17 && <span>Complain Complete</span>}
          {d.transactionStatus === 18 && <span>Retur Process</span>}
          {d.transactionStatus === 19 && <span>Retur Complete</span>}
          {d.transactionStatus === 20 && <span>Retur Reject</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Delivery Status",
      selector: (d: { shippingStatus: number }) => (
        <p style={{ margin: 10 }}>
          {d.shippingStatus === 0 && (
            <span>
              {" "}
              <p style={{ color: "red" }}>Pending</p>
            </span>
          )}
          {/* {d.shippingStatus === 1 && <span>Print Request</span>} */}
          {d.shippingStatus === 2 && <span>On Progress</span>}
          {d.shippingStatus === 3 && <span>On Delivery</span>}
          {d.shippingStatus === 4 && <span>Delivered</span>}
          {d.shippingStatus === 5 && <span>Completed</span>}
          {d.shippingStatus === 6 && <span>Print A Request</span>}
          {d.shippingStatus === 7 && <span>Print B Request</span>}
          {d.shippingStatus === 8 && <span>Waiting For Pickup</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Note",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (
        d: {
          id: string;
          invoiceId: string;
          salesOrderId: string;
          soNumber: string;
          resiNumber: string;
          customerName: string;
          orderNameFromSO: string;
          adminGudang: string;
          adminGudangId: string;
          adminQC: string;
          adminQCId: string;
          adminEkspedisi: string;
          adminEkspedisiId: string;
          adminPacking: string;
          adminPackingId: string;
          shippingStatus: number;
          nameSentTo: string;
          address: string;
          phoneNumber: string;
          countryId: string;
          provinceId: string;
          cityId: string;
          subDistrictId: string;
          postalCode: string;
          createdDate: string;
          expeditionName: string;
          pickupDate: string;
        },
        index: number
      ) => (
        <>
          {/* <img src={edit} onClick={() => onEditItemClicked(  )}></img> */}
          {"    "}
          <span
            onClick={() => {
              // setSelectedData(d)

              onEditItemClicked(
                d.id,
                d.invoiceId,
                d.salesOrderId,
                d.soNumber,
                d.resiNumber,
                d.customerName,
                d.orderNameFromSO,
                d.adminGudang,
                d.adminGudangId,
                d.adminQC,
                d.adminQCId,
                d.adminEkspedisi,
                d.adminEkspedisiId,
                d.adminPacking,
                d.adminPackingId,
                d.shippingStatus,
                d.nameSentTo,
                d.address,
                d.phoneNumber,
                d.countryId,
                d.provinceId,
                d.cityId,
                d.subDistrictId,
                d.postalCode,
                d.createdDate,
                d.expeditionName,
                d.pickupDate
              );
              setShowModal(true);
            }}
          >
            <img src={edit}></img>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Resi dan Pengiriman</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="float-right"
            style={{ width: 130, marginLeft: 10 }}
            variant="outline-info"
          >
            Print
          </Button>{" "}
          <Button
            className="float-right"
            style={{ width: 160, marginLeft: 10 }}
            variant="outline-info"
          >
            Export to excel
          </Button>{" "}
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Nomor SO</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="SO Number"
                    type="text"
                    value={filterIvoiceNumber}
                    onChange={(e) => {
                      setFilterIvoiceNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Customer Name</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="Cust. Name"
                    type="text"
                    value={filterCustomerName}
                    onChange={(e) => {
                      setFilterCustomerName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Expedition Number</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="No. Resi"
                    type="text"
                    value={filterExpeditionNumber}
                    onChange={(e) => {
                      setFilterExpeditionNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Sender Name</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="Pengirim"
                    type="text"
                    value={filterSenderName}
                    onChange={(e) => {
                      setFilterSenderName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Nama Ekspedisi</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="Ekspedisi"
                    type="text"
                    value={filterExpeditionName}
                    onChange={(e) => {
                      setfilterExpeditionName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column md={3}>
                  Tanggal Pick Up
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Nama Penerima</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="Nama Penerima"
                    type="text"
                    value={filterRecipientName}
                    onChange={(e) => {
                      setFilterRecipientName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Delivery Status</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    value={filterTempShipmentStatus}
                    onChange={(e) => {
                      if (e.target.value.toString() === "9") {
                        setFilterShipmentStatus(null);
                        setFilterTempShipmentStatus(parseInt(e.target.value));
                      } else {
                        setFilterShipmentStatus(parseInt(e.target.value));
                        setFilterTempShipmentStatus(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={"9"}>All</option>
                    <option value={"0"}>Pending</option>
                    <option value={"1"}>Print Request</option>
                    <option value={"2"}>On Progress</option>
                    <option value={"8"}>Waiting For Pickup</option>
                    <option value={"3"}>On Delivery</option>
                    <option value={"4"}>Delivered</option>
                    <option value={"5"}>Completed</option>
                    <option value={"6"}>Print A Request</option>
                    <option value={"7"}>Print B Request</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Note</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="Note"
                    type="text"
                    value={filterNote}
                    onChange={(e) => {
                      setFilterNote(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Status Transaksi</Form.Label>
                <Col sm={9}>
                  <select className="custom-select cus-sel left" name='transactionStatus' value={filterTransactionStatus === null ? 'null' : filterTransactionStatus.toString() } onChange={(e) => setFilterTransactionStatus(e.target.value === 'null' ? null : parseInt(e.target.value))} placeholder='Select'>
                    {
                      transactionStatus.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                      ))
                    }
                  </select>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Row>
                <Col>
                  <Button
                    style={{ width: 130, margin: 10 }}
                    variant="outline-info"
                    onClick={onResetClick}
                  >
                    Reset
                  </Button>{" "}
                  <Button
                    style={{ width: 160, margin: 10 }}
                    variant="info"
                    onClick={onFilterClick}
                  >
                    Filter
                  </Button>{" "}
                </Col>
              </Row>
            </Col>

            {isSelectingRows ? (
              <>
                <Col md={6}>
                  <Row>
                    <Col>
                      <Button
                        className="float-right"
                        style={{ width: 200, margin: 10 }}
                        variant="outline-info"
                        onClick={onSetPickupDate}
                      >
                        Set Pick Up Date
                      </Button>{" "}
                      <Button
                        className="float-right"
                        style={{ width: 200, margin: 10 }}
                        variant="outline-info"
                        onClick={onResetPickUpDate}
                      >
                        Clear Pick Up Date
                      </Button>{" "}
                      {/* <Button
                   className="float-right"
                    style={{ width: 160, margin: 10 }}
                    variant="info"
                    onClick={onFilterClick}
                  >
                    Filter
                  </Button>{" "} */}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>

          <hr />

          <DataTable
            noHeader
            customStyles={TableCustomStyles}
            columns={columns}
            data={filteredItems}
            pagination
            paginationServer
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRowState}
            selectableRows
            onSelectedRowsChange={handleSelectedRows}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Edit Delivery Data</div>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="DeliveryForm"
            onSubmit={(e) => {
              const form = e.currentTarget;
              if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
              } else {
                e.preventDefault();
                // if (PaymentMethodSingleDataState.id === undefined) {
                //     props.onSubmit(name, roleId, isActive);
                //     setName("");
                //     setRoleId([])
                // } else {
                //     onEdit();
                //     setName("");
                //     setRoleId([])
                // }
              }
              // setValidated(true);
            }}
          >
            <Form.Group className="input-padding">
              <Form.Label>SO Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="-"
                value={selectedSONumber}
                onChange={onSelectedSONumberChange}
                disabled
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="-"
                value={selectedPartnerName}
                onChange={onSelectedPartnerNameChange}
                disabled
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Alamat Kirim</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="-"
                value={selectedAddress}
                disabled
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Order Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="-"
                value={selectedCreateDate.substring(0, 10)}
                disabled
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Admin Gudang</Form.Label>
              <Form.Control
                as="select"
                onChange={onSelectedAdminGudangIdChange}
                value={selectedUserAdminGudangId}
              >
                <option>Pilih Admin</option>
                {adminGudangListState.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name}>
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Admin QC</Form.Label>
              <Form.Control
                as="select"
                onChange={onSelectedAdminQCIdChange}
                value={selectedUserAdminQCId}
              >
                <option>Pilih Admin </option>
                {adminQCListState.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name}>
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Admin Packing</Form.Label>
              <Form.Control
                as="select"
                onChange={onSelectedAdminPackingIdChange}
                value={selectedUserAdminPackingId}
              >
                <option>Pilih Admin </option>
                {adminPackingListState.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name}>
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Admin Ekspedisi</Form.Label>
              <Form.Control
                as="select"
                onChange={onSelectedAdminEkspedisiIdChange}
                value={selectedUserAdminEkspedisiId}
              >
                <option>Pilih Admin </option>
                {adminEkspedisiListState.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name}>
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Ekspedisi</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={selectedExpeditionName}
                onChange={onSelectedExpeditionNameChange}
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>No. Resi</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={selectedReceiptNumber}
                onChange={onSelectedReceiptNumberChange}
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Pick Up Date</Form.Label>
              {/* <Form.Control
                type="date"
                placeholder="-"
                value={
                  selectedPickUpDate === null
                    ? "-"
                    : selectedPickUpDate.substring(0, 10)
                }
                onChange={(e) => setSelectedPickUpDate(e.target.value)}
                // disabled
              /> */}

              {isPickUpDateEmpty ? (
                <>
                  <Row>
                    <Col sm={8}>
                      <Form.Control type="text" placeholder="-" disabled />
                    </Col>
                    <Col sm={4}>
                      <Button style={{ width: 100, height: 32 }} onClick={onSetTimeClick}>
                        Set Time
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <DatePicker
                    // defaultValue={moment(new Date(), "YYYY-MM-DD HH:mm")}
                    // defaultValue={moment("", "")}
                    value={
                      selectedPickUpDate === null ||
                      selectedPickUpDate === undefined
                        ? null
                        : moment(selectedPickUpDate, "YYYY-MM-DD HH:mm")
                    }
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onPickUpDateChange}
                  />
                </>
              )}
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label column>Status Pengiriman</Form.Label>
              <Form.Control
                as="select"
                value={selectedShippingStatusEnum}
                disabled
                onChange={(e) => {
                  setSelectedShippingStatusEnum(parseInt(e.target.value));
                }}
              >
                <option value={0}>Pending</option>
                <option value={1}>Print Request</option>
                <option value={2}>On Progress</option>
                <option value={3}>On Delivery</option>
                <option value={4}>Delivered</option>
                <option value={5}>Completed</option>
                <option value={6}>Print A Request</option>
                <option value={7}>Print B Request</option>
              </Form.Control>
            </Form.Group>

            <div className="btn-footer-group float-right">
              <Button
                variant="info"
                style={{ width: 130, height: 45 }}
                onClick={onSaveClick}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSetPickUpDateModal}
        onHide={handleSetPickUpClose}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Set Pick Up Date</div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="input-padding">
            <Form.Label>Pick Up Date</Form.Label>
            {/* <Form.Control
              type="date"
              placeholder="-"
              value={
                UpdatePickUpDateBatch === null
                  ? "-"
                  : UpdatePickUpDateBatch.substring(0, 10)
              }
              onChange={(e) => setUpdatePickUpDateBatch(e.target.value)}
              // disabled
            /> */}

             <DatePicker
                    // defaultValue={moment(new Date(), "YYYY-MM-DD HH:mm")}
                    value={
                      UpdatePickUpDateBatch === null ||
                      UpdatePickUpDateBatch === undefined || UpdatePickUpDateBatch === ""
                        ? null
                        : moment(UpdatePickUpDateBatch, "YYYY-MM-DD HH:mm")
                    }
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onPickUpDateBatchChange}
                  />
                
          </Form.Group>

          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onSaveSetPickUpDate}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showResetPickUpDateModal}
        onHide={handleResetPickUpClose}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Reset Pick Up Date</div>
        </Modal.Header>

        <Modal.Body>
          <p>Kosongkan Tanggal Pick Up ?</p>

          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onSaveResetPickUpDate}
            >
              Kosongkan
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </div>
  );
};

export default ReceiptDeliveryTable;
