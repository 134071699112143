import React, {useState} from 'react';
import {AppDispatch} from "../../app/store";
import {useDispatch} from "react-redux";
import {getProductDropdown} from "../../global/global.reducer";
import {unwrapResult} from "@reduxjs/toolkit";
import {ProductOptionModel} from "../../features/report/laporanKartuStock/laporanKartuStock.model";

const useAsyncProductOption = () => {
    const dispatch : AppDispatch = useDispatch();

    const getProductOptions = async (inputValue: string) => {
        const resultAction = await dispatch(getProductDropdown({
            name: inputValue.toLowerCase() === "" ? "e" : inputValue.toLowerCase(),
            skip: 0,
            take: 150,
        }));

        const originalPromiseResult = unwrapResult(resultAction);
        let _option = originalPromiseResult?.map((item: any) => {
            return {
                value: item.id,
                label: item.inventorySKU + " - " + item.name,
                sku: item.inventorySKU,
                productName: item.name,
                id: item.id,
            }
        });
        return _option as ProductOptionModel[];
    };

    return {getProductOptions} as const;
};

export default useAsyncProductOption;
