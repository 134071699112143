import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const postLaporanPelunasan = createAsyncThunk('post/postLaporanPelunasan', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportRepayment`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postExportPelunasan = createAsyncThunk('post/postExportPelunasan', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Report/ReportRepaymentExportToExcel`,
            data: body,
            method: 'post',
            responseType: 'blob',
        })
        console.log(response)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})