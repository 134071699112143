import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
  Container,
  Button,
  Modal,
} from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../../app/store";
import {
  AdminListModel,
  Warehouse,
  WarehouseSettingAdminModel,
} from "../settingWarehouse.model";
import {
  createAdminWarehouseSetting,
  deleteAdminWarehouseSetting,
  getAdminList,
  getAdminWarehouseSetting,
} from "../settingWarehouse.reducer";
import DeleteMessage from "../../../../components/DeleteMessage";
import hapus from "./../../../../assets/vector/delete.png";
import { Select } from "antd";
import { getListWarehouse } from "../../../../global/global.reducer";

const SettingWarehouseAdmin = () => {
  const dispatch = useDispatch();

  const AdminWarehouseSettingState = useSelector(
    (state: RootState) =>
      state.settingWarehouse.adminSettingList ??
      ([] as WarehouseSettingAdminModel[])
  );

  const WarehouseSettingStatusState = useSelector(
    (state: RootState) => state.settingWarehouse.status ?? ""
  );

  const WarehouseListState = useSelector(
    (state: RootState) => state.global.listWarehouse ?? []
  );

  const AdminListState = useSelector(
    (state: RootState) =>
      state.settingWarehouse.adminList ?? ([] as AdminListModel[])
  );

  const [selectedId, setSelectedId] = useState("");
  // const [selectedAdmin, setSelectedAdmin] = useState<Array<string>>([]);
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState<Array<string>>([]);

  const [showModal, setShowModal] = useState(false);


const { Option } = Select;

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onDeleteData = () => {
    console.log(selectedId)
    if (selectedId !== undefined) {
      dispatch(deleteAdminWarehouseSetting(selectedId));
      setShowAlert(false);
    }
  };

  const onSave = () => {
   dispatch(createAdminWarehouseSetting({
     userId : selectedAdmin,
     warehouseId : selectedWarehouse
   }))
  }

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedAdmin("")
    setSelectedWarehouse([])
  };

  const handleChange = (e: any) => {
    console.log(e)
    setSelectedAdmin(e);
  };

  const handleWarehouseChange = (e: any) => {
    setSelectedWarehouse(e);
  };

  useEffect(() => {
    dispatch(getAdminWarehouseSetting());
    dispatch(getAdminList());
    dispatch(getListWarehouse());
  }, []);

  useEffect(() => {
    if(WarehouseSettingStatusState === "success-create-admin-setting"){
      setSelectedAdmin("")
      dispatch(getAdminWarehouseSetting());
      handleModalClose()
    } else if (WarehouseSettingStatusState === "failed-create-admin-setting"){
      dispatch(getAdminWarehouseSetting());
    } else if (WarehouseSettingStatusState === "success-delete-admin"){
      dispatch(getAdminWarehouseSetting());
    }
  }, [WarehouseSettingStatusState])
  

  const DummyData = [
    {
      test: "-",
    },
    {
      test: "-",
    },
    {
      test: "-",
    },
    {
      test: "-",
    },
  ];

  const column = [
    {
      name: "ADMIN",
      selector: "username",
      sortable: true,
    },
    {
      name: "WAREHOUSE",
      selector: (d: { warehouseList: Warehouse[] }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.warehouseList?.map((item) => (
              <>{item.warehouseName}, </>
            ))}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { userId: any }) => (
        <>
          {/* <Link
            to={{
              pathname: `/edit-voucher/${d.id}`,
            }}
          >
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"        "} */}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onDelete(d.id);
              setSelectedId(d.userId);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <Grid>
        <hr />
        <br />

        <Button
          variant="outline-info"
          style={{ width: 130, height: 45 }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add
        </Button>
        <hr />

        <DataTable
          columns={column}
          data={AdminWarehouseSettingState}
          // pagination
          // paginationServer
          noHeader
          // progressPending={loading}
          // paginationResetDefaultPage={resetPaginationToggle}
          // paginationTotalRows={TotalRowState}
          // onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
        />
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleModalClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Add Admin Warehouse Setting</div>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="input-padding">
              <Form.Label>Admin</Form.Label>
              <Select
                // mode="multiple"
                showSearch
                placeholder="Select Admin.."
                value={selectedAdmin}
                onChange={handleChange}
                optionFilterProp="children"
                style={{ width: "100%" }}
                
              >
                {AdminListState.map((item) => (
                  <Select.Option key={item.name} value={item.id}>
                    {item.username} - {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Group>
            <Form.Group className="input-padding">
              <Form.Label>Warehouse</Form.Label>
              <Select
                mode="multiple"
                placeholder="Select Warehouse.."
                optionFilterProp="children"
                value={selectedWarehouse}
                onChange={handleWarehouseChange}
                style={{ width: "100%" }}
              >
                {WarehouseListState.map((item) => (
                  <Select.Option key={item.name} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Group>

            <Button
              variant="outline-info"
              style={{ width: 130, height: 45 }}
              onClick={() => {
                onSave()
              }}
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default SettingWarehouseAdmin;
