import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../../app/store";
import { deleteData, getList } from "../voucher.reducer";
import DeleteMessage from "../../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const VoucherTable = () => {
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedId, setSelectedId] = useState("")

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
  };



  const listVoucherState = useSelector(
    (state: RootState) => state.voucher.list ?? []
  );

  const filteredItems = listVoucherState.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.code && item.code.toLowerCase().includes(filterText.toLowerCase()))
  );

  const columns = [
    {
      name: "CODE",
      selector: "code",
      sortable: true,
    },
    {
      name: "DISCOUNT NAME",
      selector: "name",
      sortable: true,
    },
    {
      name: "TYPE",
      selector: (d: { type: number }) => (
        <p style={{ margin: 10 }}>
            {d.type === 0 && (
                <span className={"custom-badge-secondary"} >Percentage</span>
            )}
            {d.type === 1 &&(
                <span className={"custom-badge-secondary"} >Fix Amount</span>
            )}
            {d.type === 2 &&(
                <span className={"custom-badge-secondary"} >Free Shipping</span>
            )}
            {d.type === 3 &&(
                <span className={"custom-badge-secondary"} >Buy X Get Y</span>
            )}
            {d.type === 4 &&(
                <span className={"custom-badge-secondary"} >Cash Back</span>
            )}
            
        </p>
        ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
            {d.isActive && (
                <span className={"custom-badge-success"} >Active</span>
            )}
            {!d.isActive && (
                <span className={"custom-badge-secondary"} >Inactive</span>
            )}
            
        </p>
    ),
      sortable: true,
    },
    {
      name: "HIDDEN",
      selector: (d: { isHidden: boolean }) => (
          <p style={{ margin: 10 }}>
                <span className={d.isHidden ? "custom-badge-warning" : "custom-badge-secondary"} >{d.isHidden ? "Yes" : "No"}</span>
          </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-voucher/${d.id}`,
            }}
          >
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onDelete(d.id);
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
      setShowAlert(false);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">
              Master Voucher Discount
            </Form.Label>
          </Form.Group>
        </Col>
        <Link
          to={{
            pathname: "/voucher/create",
          }}
        >
          <Button
            className="button-create-customer float-right"
            style={{ width: 160 }}
          >
            + Add Voucher
          </Button>
          {"  "}
        </Link>
        <Button
          className="float-right"
          style={{ width: 130, marginLeft: 10 }}
          variant="outline-info"
        >
          Print
        </Button>{" "}
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="outline-info"
        >
          Export to excel
        </Button>{" "}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup
                className="mb-3 padding-filter"
                style={{ width: 330 }}
              >
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search for an (ID, From, etc)"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default VoucherTable;
