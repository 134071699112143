import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import { Card } from "react-bootstrap";
import { endpoint } from "../../../../../constants";
import { useEffect, useState } from "react";
import { ShipmentModel } from "../../model";
import { Grid } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import DeleteMessage from "../../../../../components/DeleteMessage";
import edit from "./../../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import axios from "axios";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

const DataShipment = () => {
  let { id } = useParams<{ id: string }>();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState<ShipmentModel[]>([]);
  const [selectedId, setSelectedId] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const columns = [
    {
      name: "Nomor Resi",
      selector: "resiNumber",
      sortable: true,
    },
    {
      name: "Nama Pengirim",
      selector: "senderName",
      sortable: true,
    },
    {
      name: "Nama Penerima",
      selector: "recipientName",
      sortable: true,
    },
    {
      name: "Alamat Penerima",
      selector: "recipientAddress",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/shipment/edit/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {/* {"    "}
          <span
            onClick={() => {
              setSelectedId(d.salesOrderId);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span> */}
        </>
      ),
    },
  ];
  // end of datatable components

  useEffect(() => {
    axios
      .get<any>(endpoint + "/api/Shipment/GetSingleBySalesOrder?salesOrderId=" + id)
      .then((res) => {
        let list = [];
        list.push(res.data.data);
        setList(list);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = () => setShowAlert(false);

  const onAlertClose = () => setShowAlert(false);

  return (
    <div>
      <Grid textAlign="center">
        <Card>
          <Card.Body>
            <h5>Data Shipping</h5>
            <hr />
            <DataTable
              columns={columns}
              data={list}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              noHeader  
              customStyles={TableCustomStyles}
            />
          </Card.Body>
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default DataShipment;
