import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../assets/vector/edit.png";
import hapus from "./../../../assets/vector/delete.png";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { RootState } from "../../../app/store";

import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {
  deleteStockist,
  getStockistList,
  getStockistListWithPaging,
  resetStatus,
} from "../registerStockist.reducer";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const RegisterStockistTable = () => {
  const dispatch = useDispatch();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  // const [filterStatus, setFilterStatus] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const registerStockistState = useSelector(
    (state: RootState) => state.registerStockist.list ?? []
  );

  const registerStockistStatus = useSelector(
    (state: RootState) => state.registerStockist.status ?? ""
  );

  const totalRowState = useSelector(
    (state: RootState) => state.registerStockist.totalRow ?? 0
  );

  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterStartProcessDate, setFilterStartProcessDate] = useState("");
  const [filterEndProcessDate, setFilterEndProcessDate] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterPartnerType, setFilterPartnerType] = useState<number | null>(1);
  const [filterTempPartnerType, setFilterTempPartnerType] = useState(1);
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const [filterTempStatus, setFilterTempStatus] = useState(3);

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const filteredItems = registerStockistState.filter(
    (item) =>
      item.isActive == true &&
      // item.status.toString().includes(filterStatus) &&
      (item.fullName.toLowerCase().includes(filterText) ||
        item.email.toLowerCase().includes(filterText) ||
        item.phoneNumber.toLowerCase().includes(filterText))
  );

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteStockist(id));
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
    dispatch(resetStatus());
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("");
    setFilterEmail("");
    setFilterPhone("");
    setFilterId("");
    setFilterPartnerType(1);
    setFilterTempPartnerType(1);
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterStartProcessDate("");
    setFilterEndProcessDate("");
    setFilterStatus(null);
    setFilterTempStatus(3);

    dispatch(
      getStockistListWithPaging({
        id: "",
        name: "",
        email: "",
        phone: "",
        startDate: "",
        endDate: "",
        startProcessDate: "",
        endProcessDate: "",
        partnerType: 1,
        status: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getStockistListWithPaging({
        id: filterId,
        name: filterName,
        email: filterEmail,
        phone: filterPhone,
        startDate: filterStartDate,
        endDate: filterEndDate,
        startProcessDate: filterStartProcessDate,
        endProcessDate: filterEndProcessDate,
        partnerType: filterPartnerType,
        status: filterStatus,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    // if(registerStockistState.length === 0){
      fetchData(0, perPage);
    // }
    
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [registerStockistState]);

  useEffect(() => {
    console.log(registerStockistStatus);
    if (registerStockistStatus === "success-delete") {
      setAlertProps({
        variant: "Failed",
        title: "Hapus Data Registrasi Stockist",
        content: "Data berhasil dihapus.",
      });
      setShowAlert(true);
      // dispatch(getStockistList());
      fetchData(0,perPage)

    }
  }, [registerStockistStatus]);

  const columns = [
    {
      name: "REGISTER DATE",
      selector: (d: { createdDate: string }) => (
        <span>{d.createdDate.substr(0, 10)}</span>
      ),
      sortable: true,
    },
    {
      name: "NAME",
      selector: "fullName",
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: "email",
      sortable: true,
    },
    {
      name: "ADDRESS",
      selector: "address",
      sortable: true,
    },
    {
      name: "PHONE NUMBER",
      selector: "phoneNumber",
      sortable: true,
    },
    {
      name: "STATUS PROCESS",
      selector: (d: { status: number }) => (
        <p style={{ margin: 10 }}>
          {d.status === 0 && (
            <span className={"custom-badge-secondary"}>Request</span>
          )}
          {d.status === 1 && (
            <span className={"custom-badge-success"}>Approved</span>
          )}
          {d.status === 2 && (
            <span className={"custom-badge-danger"}>Rejected</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "PROCESS DATE",
      selector: (d: { processDate: string; status: number }) => (
        <>
          {d.status === 0 && <span>-</span>}
          {d.status >= 1 && <span>{d.processDate.substr(0, 10)}</span>}
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && (
            <span className={"custom-badge-success"}>Active</span>
          )}
          {d.isActive === false && (
            <span className={"custom-badge-secondary"}>Inactive</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-register-stockist/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
          onClick={() => {
              if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                  onDelete(d.id);
          }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* Page Title */}

      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">
              List Register Stockist
            </Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link to={{ pathname: "/create-register-stockist" }}>
            <Button
              className="float-right ml-2"
              variant="outline-info"
              style={{ width: 160 }}
            >
              Add New Stockist
            </Button>
          </Link>
        </Col>
      </Row>

      {/* Filter */}

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Tanggal Register
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Email
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterEmail}
                    onChange={(e) => {
                      setFilterEmail(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Tanggal Proses
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartProcessDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndProcessDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>

            
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Customer ID
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterId}
                    onChange={(e) => {
                      setFilterId(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterTempStatus}
                    onChange={(e) => {
                      if (e.target.value.toString() === "3") {
                        setFilterStatus(null);
                        setFilterTempStatus(parseInt(e.target.value));
                      } else {
                        setFilterTempStatus(parseInt(e.target.value));
                        setFilterStatus(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={3}>All</option>
                    <option value={0}>Request</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Phone
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterPhone}
                    onChange={(e) => {
                      setFilterPhone(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          {/* <Row> */}
          {/* <Col sm={5}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Search Data
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search Name, Email, Address or Phone"
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterStatus}
                    onChange={(e) => {
                      setFilterStatus(e.target.value);
                    }}>
                    <option value={""}>All</option>
                    <option value={"0"}>Request</option>
                    <option value={"1"}>Approved</option>
                    <option value={"2"}>Rejected</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col> */}

          {/* <Col sm={5}>
                            <Form.Group as={Row} className="input-padding m-3">
                                <Form.Label column sm={4}>
                                    Date Register
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        type="Date">
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="input-padding m-3">
                                <Form.Label column sm={4}>
                                    Date Progress
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        type="Date">
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                        </Col> */}
          {/* </Row> */}

          {/* <div className="btn-footer-group m-4">
         
            <Button
              className="mr-2"
              variant="outline-info"
              style={{ width: 130, height: 45 }}
              type="submit"
              onClick={onFilterReset}>
              Reset
            </Button>
          
          </div> */}

          <hr className="mb-0" />

          {/* Stockist Table */}

          <DataTable
            columns={columns}
            data={registerStockistState}
            progressPending={loading}
            pagination
            paginationServer
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            selectableRows
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>
    </div>
  );
};

export default RegisterStockistTable;
