import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";

const SettingStockForm = ({ type = 0, ...props }) => {
  const [settingType, setSettingType] = useState(0);
  const [limitStockSetting, setLimitStockSetting] = useState("");

  const StockSettingStatusState = useSelector(
    (state: RootState) => state.settingStock.status ?? ""
  );

  const StockSettingListState = useSelector(
    (state: RootState) => state.settingStock.StockSettingList ?? []
  );

  const StockSettingData = StockSettingListState.filter(
    (item) => item.type === type
  )[0];

  const onSaveClick = () => {
    props.onUpdate(StockSettingData.id, type, settingType, limitStockSetting);
  };

  useEffect(() => {
    if (
      StockSettingData &&
      Object.keys(StockSettingData).length !== 0 &&
      StockSettingData.constructor == Object
    ) {
      setSettingType(StockSettingData.settingType);

      if (
        StockSettingData.limitStockSetting == undefined ||
        StockSettingData.limitStockSetting == null ||
        StockSettingData.limitStockSetting == ""
      ) {
        setLimitStockSetting("");
      } else {
        setLimitStockSetting(StockSettingData.limitStockSetting);
      }
    }
  }, [StockSettingListState]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              {/* <Form.Group>
                <Form.Label className="title">St</Form.Label>
              </Form.Group> */}

              <br />
              <br />

              <b>Stock Information Setting</b>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={4}>
                  Stock Setting
                </Form.Label>
                <Col>
                  <Form>
                    {/* <Form.Row> */}

                    <Row className="fee-input">
                      <Form.Check
                        label="Show Stock"
                        name="stockSettingType"
                        type="radio"
                        value={0}
                        checked={settingType === 0}
                        onChange={() => setSettingType(0)}
                      />
                    </Row>

                    <Row className="fee-input">
                      <Form.Check
                        label="Hide Stock in Applcation"
                        name="stockSettingType"
                        type="radio"
                        value={0}
                        checked={settingType === 1}
                        onChange={() => setSettingType(1)}
                      />
                    </Row>

                    {/* <Row className="fee-input">
                      <Form.Check
                        label="Disable Stock Feature"
                        name="stockSettingType"
                        type="radio"
                        value={0}
                        checked={settingType === 2}
                        onChange={() => setSettingType(2)}
                      />
                    </Row> */}

                    {/* </Form.Row> */}
                  </Form>
                </Col>
              </Form.Group>

              <hr />

              {/* <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={4}>
                  Limited Stock Setting
                </Form.Label>
                <Col sm={4}>
                  <Form>
                    

                    <Row className="fee-input">
                      <Form.Control
                        placeholder="Limited Stock"
                        type="text"
                        value={limitStockSetting}
                        onChange={(e) => {
                          setLimitStockSetting(e.target.value);
                        }}
                      />
                    </Row>

              
                  </Form>
                </Col>
              </Form.Group>

              <hr /> */}

              <div className="btn-footer-group">
                <Button
                  variant="outline-info"
                  style={{ width: 130, height: 45 }}
                  // onClick={}
                >
                  Cancel
                </Button>
                {"   "}
                <Button
                  variant="info"
                  style={{ width: 130, height: 45 }}
                  type="submit"
                  form="faqForm"
                  onClick={onSaveClick}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export interface SettingStockFormProps {
  onUpdate: (
    id: string,
    type: number,
    settingType: number,
    limitStockSetting: string
  ) => void;
  type: number;
}

export default SettingStockForm;
