import { createSlice } from '@reduxjs/toolkit'
import {StaffPerformanceModel} from "./models";
import {getLaporanPerfomaStaff} from "./reportStaffPerformance.api";

export interface StaffPerformanceState {
    loading: boolean,
    list: StaffPerformanceModel[],
    totalDenda: number,
    total: number
}
const initialState: StaffPerformanceState = {
    loading: false,
    list: [],
    totalDenda: 0,
    total: 0
}

export const staffPerformance = createSlice({
    name: 'staffPerformanceSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // post data filter
        builder.addCase(getLaporanPerfomaStaff.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getLaporanPerfomaStaff.fulfilled, (state, { payload }) => {
            state.list = payload?.list ?? []
            state.total = payload?.total ?? 0
            state.totalDenda = payload?.totalDenda ?? 0
            state.loading = false
        })
        builder.addCase(getLaporanPerfomaStaff.rejected, (state) => {
            state.loading = false
        })
    },
})

export default staffPerformance.reducer