import {CSSProperties, useEffect, useRef, useState} from "react";
import {useHistory, NavLink, Link} from "react-router-dom";
import {Image, ModalContent} from "semantic-ui-react";
import {Header, Layout, Sider} from "../../../components/Layout";
import {UserPanel} from "../../../components/UserPanel";
import SidebarMenu from "../../../components/SidebarMenu/SidebarMenu";
import logo from "../../../assets/logo.png";
import requiredAuth from "../../requiredAuth/requiredAuth";
import {
    FaBars,
    FaBell,
    FaBook,
    FaBox,
    FaBoxOpen,
    FaCartArrowDown,
    FaCircle,
    FaList,
    FaListAlt,
} from "react-icons/fa";
import {checkTokenStatus, logoutUser} from "../../login/user.reducer";
import {useDispatch, useSelector} from "react-redux";
import PageLink from "../components/pageLink";
import {SidebarMenuItem} from "../../../components/SidebarMenu";
import {SidebarMenuTree} from "../../../components/SidebarMenuTree";
import {RootState} from "../../../app/store";
import axios from "axios";
import {endpoint} from "../../../constants";
import {getMessaging} from "firebase/messaging";
import {onBackgroundMessage} from "firebase/messaging/sw";
// import { fetchToken, onMessageListener } from '../../../app/firebaseInit';

import {firebaseCloudMessaging} from "../../../utils/webPush";
import firebase from "firebase/compat/app";
import {Card, Modal, Nav, Row} from "react-bootstrap";
import {NotificationModel} from "../adminPanel.model";
import "../AdminPanel.css";
import {
    getAllAdminCODNotificationByType,
    getAllAdminDropshipNotificationByType,
    getAllAdminKeepBarangNotificationByType,
    getAllAdminKeepNotificationByType,
    getAllAdminPaymentConfirmNotificationByType,
} from "../notification.reducer";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {MdImportantDevices} from "react-icons/md";
import AlertMessage from "../../../components/alertMessage";

const sidebarMenuTitleStyle: CSSProperties = {
    textTransform: "uppercase",
    fontWeight: 700,
};

const AdminPanelPage = () => {
    const dispatch = useDispatch();

    const history = useHistory();

    const {roleId, username, tokenJWT, tokenStatus, id} = useSelector(
        (state: RootState) => state.user
    );

    // const ChatNotificationListState = useSelector(
    //   (state: RootState) => state.notification.list ?? ([] as NotificationModel[])
    // );
    const {
        keepBarangList,
        keepList,
        codList,
        dropshipList,
        paymentConfirmList,
    } = useSelector((state: RootState) => state.notification);
    // const CodNotificationListState = useSelector(
    //   (state: RootState) =>
    //     state.notification.codList ?? ([] as NotificationModel[])
    // );
    // const PiutangNotificationListState = useSelector(
    //   (state: RootState) =>
    //     state.notification.piutangList ?? ([] as NotificationModel[])
    // );
    // const DropshipNotificationListState = useSelector(
    //   (state: RootState) =>
    //     state.notification.dropshipList ?? ([] as NotificationModel[])
    // );
    // const PaymentConfirmNotificationListState = useSelector(
    //   (state: RootState) =>
    //     state.notification.paymentConfirmList ?? ([] as NotificationModel[])
    // );

    // console.log(CodNotificationListState)

    const [compact, setCompact] = useState(false);
    const [treeOpen, setTreeOpen] = useState("");
    const [allowMenuList, setAllowMenuList] = useState<any>([]);

    const [isPaymentConfirmationNotifOn, setIsPaymentConfirmationNotifOn] =
        useState(false);
    const [isSalesOrderNotifOn, setIsSalesOrderNotifOn] = useState(false);
    const [isComplainNotifOn, setIsComplainNotifOn] = useState(false);

    const [isTokenFound, setTokenFound] = useState(false);

    const messaging = getMessaging();

    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const [showNotificationList, setShowNotificationList] = useState(false);
    const [showPaymentConfirmNotification, setShowPaymentConfirmNotification] =
        useState(false);
    const [showKeepNotification, setShowKeepNotification] = useState(false);
    const [showKeepBarangNotification, setShowKeepBarangNotification] =
        useState(false);
    const [showCODNotification, setShowCODNotification] = useState(false);
    const [showPiutangNotification, setShowPiutangNotification] = useState(false);
    const [showDropshipNotification, setShowDropshipNotification] =
        useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        variant: "",
        title: "",
        content: "",
    });

    const [isHoveringMenu, setIsHoveringMenu] = useState(false);

    const notificationRef = useRef<HTMLDivElement>(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // if (tokenJWT !== "" && id !== "" && tokenJWT && id) {
        //   dispatch(
        //     checkTokenStatus({
        //       userId: id,
        //       token: tokenJWT,
        //     })
        //   );
        // }

        getMenuList();

        axios
            .get(endpoint + "/api/Notification/GetListPendingNotif")
            .then((res) => {
                setIsPaymentConfirmationNotifOn(res.data.data.paymentConfirmation);
                setIsSalesOrderNotifOn(res.data.data.salesOrder);
                setIsComplainNotifOn(res.data.data.complaint);
            });

        const setToken = async () => {
            try {
                const token = await firebaseCloudMessaging.init();
                if (token) {
                    getMessage();
                    // setFCMToken(token as string);
                }
            } catch (error) {
                console.log(error);
            }
        };

        setToken();

        const getMessage = () => {
            const messaging = firebase.messaging();
            messaging.onMessage((message) => {
                // console.log(message);
                // console.log("THIS IS ME");
            });
        };

        document.addEventListener("click", handleClickOutside, true);
    }, []);

    useEffect(() => {
        if (!tokenStatus) {
            setAlertProps({
                variant: "Failed",
                title: "Log Out",
                content: "Akun ini telah Login di tempat lain.",
            });
            setShowAlert(true);
        }
    }, [tokenStatus]);

    const onAlertClose = () => {
        setShowAlert(false);
        logoutAction();
    };

    const getMenuList = () => {
        axios
            .get(endpoint + "/api/Menu/GetListMenuAccessByRole?roleId=" + roleId)
            .then((res) => {
                setAllowMenuList(res.data.data);
            });
    };

    const logoutAction = () => {
        dispatch(logoutUser());
        history.push("/login");
    };

    const onNotificationClick = () => {
        setShowNotificationList(true);
    };

    const onNotifDetailClick = (id: string) => {
    };

    const onKeepBarangNotificationClick = () => {
        if (tokenJWT !== "" && id !== "" && tokenJWT && id) {
            dispatch(
                checkTokenStatus({
                    userId: id,
                    token: tokenJWT,
                })
            );
        }
        dispatch(
            getAllAdminKeepBarangNotificationByType({
                type: 5,
                take: 20,
                skip: 0,
            })
        );
        setShowKeepBarangNotification(true);
    };

    const onKeepNotificationClick = () => {
        if (tokenJWT !== "" && id !== "" && tokenJWT && id) {
            dispatch(
                checkTokenStatus({
                    userId: id,
                    token: tokenJWT,
                })
            );
        }
        dispatch(
            getAllAdminKeepNotificationByType({
                type: 1,
                take: 20,
                skip: 0,
            })
        );
        setShowKeepNotification(true);
    };

    const onCODNotificationClick = () => {
        dispatch(
            getAllAdminCODNotificationByType({
                type: 3,
                take: 20,
                skip: 0,
            })
        );
        setShowCODNotification(true);
    };
    const onPiutangNotificationClick = () => {
        dispatch(
            getAllAdminKeepNotificationByType({
                type: 0,
                take: 20,
                skip: 0,
            })
        );
        setShowPiutangNotification(true);
    };
    const onDropshipNotificationClick = () => {
        dispatch(
            getAllAdminDropshipNotificationByType({
                type: 2,
                take: 20,
                skip: 0,
            })
        );
        setShowDropshipNotification(true);
    };
    const onPaymentConfirmNotificationClick = () => {
        dispatch(
            getAllAdminPaymentConfirmNotificationByType({
                type: 4,
                take: 20,
                skip: 0,
            })
        );
        setShowPaymentConfirmNotification(true);
    };

    const handleClickOutside = (event: Event) => {
        // if (tokenJWT !== "" && id !== "" && tokenJWT && id) {
        //   dispatch(
        //     checkTokenStatus({
        //       userId: id,
        //       token: tokenJWT,
        //     })
        //   );
        // }

        if (
            notificationRef.current &&
            !notificationRef.current.contains(event.target as Node)
        ) {
            setShowNotificationList(false);
            setShowKeepNotification(false);
            setShowKeepBarangNotification(false);
            setShowCODNotification(false);
            setShowPiutangNotification(false);
            setShowDropshipNotification(false);
            setShowPaymentConfirmNotification(false);
        }
    };

    const moneyParser = (value: any) => {
        if (value) {
            let valueString = value.toString();
            let valueList = valueString.split("");
            let resultList: any = [];
            valueList.reverse().map((c: any, i: number) => {
                resultList.push(c);
                if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
                    resultList.push(".");
                }
            });
            let result = resultList.reverse().join("");
            return "Rp " + result + ",-";
        }
        return value;
    };

    const checkToken = () => {
        if (tokenJWT !== "" && id !== "" && tokenJWT && id) {
            dispatch(
                checkTokenStatus({
                    userId: id,
                    token: tokenJWT,
                })
            );
        }
    };

    const menuList = [
        {
            name: "Dashboard",
            url: "/",
            icon: "fa-th",
        },
        {
            name: "Setting Profile",
            url: "/setting-profile",
            icon: "fa-user-circle",
        },
        {
            name: "Admin Stockist",
            url: "/admin-stockist/index",
            icon: "fa-user-circle",
        },
        {
            name: "Complain (Pengaduan)",
            url: "/complain/index",
            icon: "fa-user-circle",
        },
        {
            name: "Master",
            icon: "fa-institution",
            child: [
                {
                    name: "User",
                    url: "/user/index",
                },
                {
                    name: "Attribute",
                    url: "/attribute/index",
                },
                {
                    name: "Supplier",
                    url: "/supplier/index",
                },
                {
                    name: "Warehouse",
                    url: "/warehouse/index",
                },
                {
                    name: "Province",
                    url: "/Province/index",
                },
                {
                    name: "City",
                    url: "/City/index",
                },
                {
                    name: "District",
                    url: "/District/index",
                },
                {
                    name: "Collection Tag",
                    url: "/collection-tag/index",
                },
                {
                    name: "Product Category",
                    url: "/product-category/index",
                },
                {
                    name: "Product",
                    url: "/product/index",
                },
                {
                    name: "Bank",
                    url: "/bank/index",
                },
                {
                    name: "Payment Method",
                    url: "/payment-method/index",
                },
                {
                    name: "Template Broadcast",
                    url: "/template-broadcast",
                },
                {
                    name: "Role",
                    url: "/role/index",
                },
                {
                    name: "Voucher",
                    url: "/voucher/index",
                },
            ],
        },
        {
            name: "Inventory",
            icon: "fa-archive",
            child: [
                {
                    name: "Inventory Move",
                    url: "/inventory-move/index",
                },
                {
                    name: "Inventory Receive",
                    url: "/inventory-receive/index",
                },
                {
                    name: "Penerimaan barang ke gudang",
                    url: "/penerimaan-inventory/index",
                },
                {
                    name: "Stock Opname",
                    url: "/stock-opname/index",
                },
                {
                    name: "Internal Use",
                    url: "/internal-use/index",
                },
            ],
        },
        {
            name: "Setting",
            icon: "fa-cogs",
            child: [
                {
                    name: "Pengaturan Format Nota",
                    url: "/setting-format-nota",
                },
                {
                    name: "Pengaturan Aplikasi",
                    url: "/setting-aplication",
                },
                {
                    name: "Setting Contract",
                    url: "/contract-setting/index",
                },
                {
                    name: "Setting Membership",
                    url: "/setting-membership/index",
                },
                {
                    name: "Setting Ekspedisi",
                    url: "/setting-expedition/index",
                },
                {
                    name: "Setting Point Reward",
                    url: "/setting-point-reward/index",
                },
                {
                    name: "Setting Image",
                    url: "/setting-image/index",
                },
                {
                    name: "Setting Link Aplikasi",
                    url: "/setting-link-application/index",
                },
                {
                    name: "Setting Stock",
                    url: "/setting-stock/index",
                },
                {
                    name: "Setting Gudang",
                    url: "/setting-warehouse/index",
                },
                {
                    name: "Setting Alamat",
                    url: "/setting-address/index",
                },
            ],
        },
        {
            name: "Web Setting",
            icon: "fa-sliders",
            child: [
                {
                    name: "Information store",
                    url: "/information-store",
                },
                {
                    name: "Pengaturan Slide Show",
                    url: "/setting-slide",
                },
                {
                    name: "Recommended Slide",
                    url: "/recommended-slide",
                },
                {
                    name: "Footer",
                    url: "/web-setting/footer"
                },
                {
                    name: "Page Setting",
                    url: "/web-setting/page-setting",
                },
                {
                    name: "Setting FAQ",
                    url: "/setting-faq/index",
                },
            ]
        },
        {
            name: "Sales Order",
            icon: "fa-cart-plus",
            child: [
                {
                    name: "Create Sales Order",
                    url: "/sales-order/index",
                },
                {
                    name: "Pesanan Dalam Proses",
                    url: "/pesanan-dalam-proses/index",
                },
                {
                    name: "Scan Pesanan",
                    url: "/scan-pesanan/index",
                },
                {
                    name: "Pesanan Dropship",
                    url: "/pesanan-dropship/index",
                },
                {
                    name: "Pesanan Piutang",
                    url: "/pesanan-Piutang/index",
                },
                {
                    name: "Pesanan Cashless",
                    url: "/pesanan-cashless/index",
                },
                {
                    name: "Pesanan Non Cashless",
                    url: "/pesanan-non-cashless/index",
                },
                {
                    name: "Pesanan Lunas",
                    url: "/pesanan-Lunas/index",
                },
                {
                    name: "Pesanan End Customer",
                    url: "/pesanan-end-customer/index",
                },
                {
                    name: "Keep Barang",
                    url: "/keep-barang/index",
                },
                {
                    name: "Keep Barang Form",
                    url: "/keep-barang-form/index",
                },
                {
                    name: "Temporary Shipment",
                    url: "/temporary-shipment/index",
                },
                // {
                //   name: "Pesanan Rekap",
                //   url: "/pesanan-Rekap/index"
                // },
            ],
        },
        {
            name: "Payment",
            icon: "fa-credit-card",
            child: [
                {
                    name: "Deposit",
                    url: "/payment-in/index",
                },
                {
                    name: "Payment Allocation",
                    url: "/payment-allocation/index",
                },
            ],
        },
        {
            name: "Customer",
            url: "/customer/index",
            icon: "fa-user-circle",
        },
        {
            name: "Invoice",
            url: "/invoice/index",
            icon: "fa-user-circle",
        },
        {
            name: "Retur",
            url: "/retur/index",
            icon: "fa-user-circle",
        },
        {
            name: "Membership",
            url: "/membership",
            icon: "fa-user-circle",
        },
        {
            name: "Subscriber",
            url: "/subscriber",
            icon: "fa-user-circle",
        },
        {
            name: "Wallet",
            url: "/wallet/index",
            icon: "fa-money",
        },
        {
            name: "Point",
            url: "/point/index",
            icon: "fa-bullseye",
        },
        {
            name: "Register",
            icon: "fa-cart-plus",
            child: [
                {
                    name: "Register Stockist",
                    url: "/register-stockist/index",
                },
                {
                    name: "Register Reseller",
                    url: "/register-reseller/index",
                },
            ],
        },
        {
            name: "Konfirmasi & Resi",
            icon: "fa-cart-plus",
            child: [
                {
                    name: "Konfirmasi Pembayaran",
                    url: "/payment-confirm/index",
                },
                {
                    name: "Konfirmasi Pembayaran EC",
                    url: "/payment-confirm-end-customer/index",
                },
                {
                    name: "Resi dan Pengiriman",
                    url: "/receipt-delivery/index",
                },
            ],
        },
        {
            name: "Print List",
            url: "/print-list/index",
            icon: "fa-archive",
        },
        {
            name: "Chat",
            icon: "fa-comment",
            child: [
                {
                    name: "Chat",
                    url: "/chat",
                },
                {
                    name: "Chat Product",
                    url: "/chat-product",
                },
            ],
        },
        {
            name: "Laporan",
            icon: "fa-bar-chart",
            child: [
                {
                    name: "Laporan Data Pesanan",
                    url: "/report-order-data",
                },
                {
                    name: "Laporan Order & Keep",
                    url: "/report-order-stockist",
                },
                {
                    name: "Laporan Biaya Pesanan Harian - Bulanan",
                    url: "/report-daily-monthly-order",
                },
                {
                    name: "Laporan Penjualan",
                    url: "/report-penjualan",
                },
                {
                    name: "Laporan Pelunasan",
                    url: "/report-pelunasan",
                },
                {
                    name: "Laporan Omset",
                    url: "/report-omset",
                },
                {
                    name: "Laporan Perubahan Stock",
                    url: "/report-perubahan-stock",
                },
                {
                    name: "Laporan Auto Cancel",
                    url: "/report-autocancel",
                },
                {
                    name: "Laporan Master Auto Cancel",
                    url: "/report-autocancel-master",
                },
                {
                    name: "Laporan Keep Barang Auto Cancel",
                    url: "/report-autocancel-keep",
                },
                {
                    name: "Laporan Piutang",
                    url: "/report-account-receivable",
                },
                {
                    name: "Laporan Aset",
                    url: "/report-asset",
                },
                {
                    name: "Laporan Ekspedisi",
                    url: "/report-expedition",
                },
                {
                    name: "Laporan Kartu Stock",
                    url: "/report-kartu-stock",
                },
                {
                    name: "Laporan Denda dan Pengaduan",
                    url: "/report-staff-performance",
                },
                {
                    name: "Laporan Total Performa Staff",
                    url: "/report-total-staff-performance",
                },
            ],
        },
    ];

    return (
        <Layout>
            <Header>
                <FaBars
                    style={{margin: 15, fontSize: 20}}
                    onClick={() => setCompact((state) => !state)}
                ></FaBars>

                {/* <a
          href=""
          className="float-right"
          style={{
            margin: 15,
            marginRight: 40,
            fontSize: 15,
            textDecoration: "none",
          }}
          onClick={logoutAction}
        >
          Log out
        </a> */}

                <a
                    href=""
                    className="float-right"
                    style={{
                        margin: 15,
                        marginRight: 40,
                        fontSize: 15,
                        textDecoration: "none",
                    }}
                    onMouseEnter={() => {
                        setShowAccountMenu(true);
                    }}
                    // onMouseLeave={() => {
                    //   if (!isHoveringMenu) {
                    //     setTimeout(() => {
                    //       setShowAccountMenu(false);
                    //     }, 500);
                    //   }
                    // }}
                    onBlur={() => {
                        setShowAccountMenu(true);
                    }}
                >
                    {username}
                </a>

                <OverlayTrigger
                    key={"0"}
                    placement={"bottom"}
                    overlay={
                        <Tooltip id={`tooltip-bottom`}>Konfirmasi Pembayaran</Tooltip>
                    }
                >
                    <FaBox
                        className="float-right"
                        style={{
                            margin: 15,
                            marginRight: 20,
                            color: "#F5B95D",
                        }}
                        onClick={onPaymentConfirmNotificationClick}
                    ></FaBox>
                </OverlayTrigger>

                <OverlayTrigger
                    key={"1"}
                    placement={"bottom"}
                    overlay={<Tooltip id={`tooltip-bottom`}>Pesanan Dropship</Tooltip>}
                >
                    <FaListAlt
                        className="float-right"
                        style={{
                            margin: 15,
                            marginRight: 20,
                            color: "#F5B95D",
                        }}
                        onClick={onDropshipNotificationClick}
                    ></FaListAlt>
                </OverlayTrigger>

                {/* <OverlayTrigger
          key={"bottom"}
          placement={"bottom"}
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Pesanan Belum Lunas - Piutang
            </Tooltip>
          }
        >
          <FaBook
            className="float-right"
            style={{
              margin: 15,
              marginRight: 15,
              color: "#F5B95D",
            }}
            onClick={onPiutangNotificationClick}
          ></FaBook>
        </OverlayTrigger> */}

                <OverlayTrigger
                    key={"2"}
                    placement={"bottom"}
                    overlay={<Tooltip id={`tooltip-bottom`}>Pesanan Walkin</Tooltip>}
                >
                    <FaList
                        className="float-right"
                        style={{
                            margin: 15,
                            marginRight: 20,
                            color: "#F5B95D",
                        }}
                        onClick={onCODNotificationClick}
                    ></FaList>
                </OverlayTrigger>

                <OverlayTrigger
                    key={"3"}
                    placement={"bottom"}
                    overlay={<Tooltip id={`tooltip-bottom`}>Pesanan Checkout</Tooltip>}
                >
                    <FaCartArrowDown
                        className="float-right"
                        style={{
                            margin: 15,
                            marginRight: 15,
                            color: "#F5B95D",
                        }}
                        onClick={onKeepNotificationClick}
                    ></FaCartArrowDown>
                </OverlayTrigger>

                <OverlayTrigger
                    key={"4"}
                    placement={"bottom"}
                    overlay={<Tooltip id={`tooltip-bottom`}>Keep Barang</Tooltip>}
                >
                    <FaBoxOpen
                        className="float-right"
                        style={{
                            margin: 15,
                            marginRight: 15,
                            color: "#F5B95D",
                        }}
                        onClick={onKeepBarangNotificationClick}
                    ></FaBoxOpen>
                </OverlayTrigger>

                {/* <OverlayTrigger
          key={"bottom"}
          placement={"bottom"}
          overlay={<Tooltip id={`tooltip-bottom`}>Notifikasi Chat</Tooltip>}
        >
          <FaBell
            className="float-right"
            style={{
              margin: 15,
              marginRight: 15,
              color: "#F5B95D",
            }}
            onClick={onNotificationClick}
          ></FaBell>
        </OverlayTrigger> */}
            </Header>
            <Sider
                isCompact={compact}
                logo={<Image src={logo} size="mini" circular/>}
                brandName="Emory"
            >
                <UserPanel imgUrl="https://react.semantic-ui.com/images/wireframe/square-image.png"/>
                {menuList.map((menu, i) => {
                    if (menu.url) {
                        if (
                            allowMenuList.filter((item: any) => item.url === menu.url)
                                .length > 0
                        ) {
                            return (
                                <SidebarMenu menuTitleStyle={sidebarMenuTitleStyle}>
                                    <SidebarMenuItem>
                                        {(className: string, labelClassName: string) => {
                                            return (
                                                <NavLink exact to={menu.url} onClick={checkToken}>
                                                    <i className={`fa ${menu.icon} ${className}`}></i>
                                                    <span className={labelClassName}>{menu.name}</span>

                                                    {/* <i className={`fa fa-bell fa-xs`}></i>    */}

                                                    {menu.url === "/complain/index" &&
                                                    isComplainNotifOn ? (
                                                        <svg
                                                            className="sidebar-menu-tree__icon"
                                                            width="12"
                                                            height="12"
                                                        >
                                                            <circle cx="6" cy="6" r="3" fill="red"/>
                                                        </svg>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {menu.url === "/payment-confirm/index" &&
                                                    isPaymentConfirmationNotifOn ? (
                                                        <svg
                                                            className="sidebar-menu-tree__icon"
                                                            width="12"
                                                            height="12"
                                                        >
                                                            <circle cx="6" cy="6" r="3" fill="red"/>
                                                        </svg>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {menu.url === "/pesanan-dalam-proses/index" &&
                                                    isSalesOrderNotifOn ? (
                                                        <svg
                                                            className="sidebar-menu-tree__icon"
                                                            width="12"
                                                            height="12"
                                                        >
                                                            <circle cx="6" cy="6" r="3" fill="red"/>
                                                        </svg>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </NavLink>
                                            );
                                        }}
                                    </SidebarMenuItem>
                                </SidebarMenu>
                            );
                        }
                    } else if (menu.child) {
                        let isShowThisHeader = false;
                        menu.child.map((child, i) => {
                            if (
                                allowMenuList.filter((item: any) => item.url === child.url)
                                    .length > 0
                            ) {
                                isShowThisHeader = true;
                            }
                        });
                        if (isShowThisHeader) {
                            return (
                                <SidebarMenu menuTitleStyle={sidebarMenuTitleStyle}>
                                    <SidebarMenuTree
                                        faIconClass={`fa ${menu.icon}`}
                                        title={menu.name}
                                        treeOpen={treeOpen}
                                        setTreeOpen={setTreeOpen}
                                        isNotificationOn={
                                            menu.name === "Konfirmasi & Resi" &&
                                            isPaymentConfirmationNotifOn
                                                ? true
                                                : menu.name === "Sales Order" && isSalesOrderNotifOn
                                                    ? true
                                                    : menu.name === "Complain (Pengaduan)" &&
                                                    isComplainNotifOn
                                                        ? true
                                                        : false
                                        }
                                    >
                                        {menu.child.map((child, i) => {
                                            if (
                                                allowMenuList.filter(
                                                    (item: any) => item.url === child.url
                                                ).length > 0
                                            ) {
                                                return (
                                                    <SidebarMenuItem>
                                                        {(className: string, labelClassName: string) => {
                                                            return (
                                                                <NavLink
                                                                    exact
                                                                    to={child.url}
                                                                    activeClassName="active"
                                                                >
                                  <span className={labelClassName}>
                                    {child.name}
                                  </span>
                                                                    {/* <i className={`fa fa-bell fa-xs`}></i>    */}

                                                                    {child.url === "/payment-confirm/index" &&
                                                                    isPaymentConfirmationNotifOn ? (
                                                                        <svg
                                                                            className="sidebar-menu-tree__icon"
                                                                            width="12"
                                                                            height="12"
                                                                        >
                                                                            <circle cx="6" cy="6" r="3" fill="red"/>
                                                                        </svg>
                                                                    ) : (
                                                                        <></>
                                                                    )}

                                                                    {child.url ===
                                                                    "/pesanan-dalam-proses/index" &&
                                                                    isSalesOrderNotifOn ? (
                                                                        <svg
                                                                            className="sidebar-menu-tree__icon"
                                                                            width="12"
                                                                            height="12"
                                                                        >
                                                                            <circle cx="6" cy="6" r="3" fill="red"/>
                                                                        </svg>
                                                                    ) : (
                                                                        <></>
                                                                    )}

                                                                    {child.url === "/complain/index" &&
                                                                    isComplainNotifOn ? (
                                                                        <svg
                                                                            className="sidebar-menu-tree__icon"
                                                                            width="12"
                                                                            height="12"
                                                                        >
                                                                            <circle cx="6" cy="6" r="3" fill="red"/>
                                                                        </svg>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </NavLink>
                                                            );
                                                        }}
                                                    </SidebarMenuItem>
                                                );
                                            }
                                        })}
                                    </SidebarMenuTree>
                                </SidebarMenu>
                            );
                        }
                    }
                })}
            </Sider>

            {showAccountMenu && (
                <>
                    <Card
                        style={{
                            width: 150,
                            right: 5,
                            top: 5,
                            position: "absolute",
                            zIndex: 10,
                        }}
                        onMouseEnter={() => {
                            if (!showAccountMenu) {
                                setShowAccountMenu(true);
                                // setIsHoveringMenu(true);
                            }
                        }}
                        onMouseOver={() => {
                            // setShowAccountMenu(true);
                            // setIsHoveringMenu(true);
                            console.log("HOVERING MENU");
                        }}
                        onMouseLeave={() => {
                            setShowAccountMenu(false);
                            // setIsHoveringMenu(false);
                        }}
                    >
                        <Card.Body style={{padding: 10}}>
                            <a
                                href={window.location.origin + "/setting-profile"}
                                style={{top: 10, bottom: 10}}
                            >
                                Profile
                            </a>
                            <hr/>
                            <a href="" style={{top: 10, bottom: 10}} onClick={logoutAction}>
                                Log Out
                            </a>
                        </Card.Body>
                    </Card>
                </>
            )}

            {showPaymentConfirmNotification ? (
                <>
                    <Card
                        ref={notificationRef}
                        style={{
                            width: 280,
                            height: 350,
                            right: 170,
                            top: 55,
                            position: "fixed",
                            zIndex: 10,
                            overflow: "auto",
                        }}
                        border="warning"
                    >
                        <Card.Body
                            style={{
                                padding: 10,
                            }}
                        >
                            <b>KONFIRMASI PEMBAYARAN</b>

                            <hr/>

                            {paymentConfirmList ? (
                                <>
                                    {paymentConfirmList?.map((item) => (
                                        <>
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                }}
                                            >
                                                <Row>
                                                    <b>{item.customer}</b>
                                                </Row>
                                                <Row>
                                                    {" "}
                                                    <Link
                                                        to={{
                                                            pathname: `/customer-so-detail/${item.soId}`,
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Link>
                                                </Row>
                                                <Row>{moneyParser(item.grandTotal)}</Row>
                                            </div>
                                            <hr/>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                        }}
                                    >
                                        <p>Tidak ada notifikasi baru saat ini.</p>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <></>
            )}

            {showDropshipNotification ? (
                <>
                    <Card
                        ref={notificationRef}
                        style={{
                            width: 280,
                            height: 350,
                            right: 220,
                            top: 55,
                            position: "fixed",
                            zIndex: 10,
                            overflow: "auto",
                        }}
                        border="warning"
                    >
                        <Card.Body
                            style={{
                                padding: 10,
                            }}
                        >
                            <b>PESANAN DROPSHIP</b>

                            <hr/>

                            {dropshipList ? (
                                <>
                                    {dropshipList?.map((item) => (
                                        <>
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                }}
                                            >
                                                <Row>
                                                    <b>{item.customer}</b>
                                                </Row>
                                                <Row>
                                                    {" "}
                                                    <Link
                                                        to={{
                                                            pathname: `/customer-so-detail/${item.soId}`,
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Link>
                                                </Row>
                                                <Row>{moneyParser(item.grandTotal)}</Row>
                                            </div>
                                            <hr/>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                        }}
                                    >
                                        <p>Tidak ada notifikasi baru saat ini.</p>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <></>
            )}

            {/* {showPiutangNotification ? (
        <>
          <Card
            ref={notificationRef}
            style={{
              width: 280,
              height: 350,
              right: 265,
              top: 5,
              position: "absolute",
              zIndex: 10,
              overflow: "auto",
            }}
            border="warning"
          >
            <Card.Body
              style={{
                padding: 10,
              }}
            >
              <b>PESANAN BELUM LUNAS - PIUTANG</b>

              <hr />

              {PiutangNotificationListState.length > 0 ? (
                <>
                  {PiutangNotificationListState.map((item) => (
                    <>
                      <div
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        <Row>
                          <b>{item.customer}</b>
                        </Row>
                        <Row>{item.description}</Row>
                        <Row>{moneyParser(item.grandTotal)}</Row>
                      </div>
                      <hr />
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                  >
                    <p>Tidak ada notifikasi baru saat ini.</p>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <></>
      )} */}

            {showCODNotification ? (
                <>
                    <Card
                        ref={notificationRef}
                        style={{
                            width: 280,
                            height: 350,
                            right: 265,
                            top: 55,
                            position: "fixed",
                            zIndex: 10,
                            overflow: "auto",
                        }}
                        border="warning"
                    >
                        <Card.Body
                            style={{
                                padding: 10,
                            }}
                        >
                            <b>PESANAN WALKIN</b>

                            <hr/>

                            {codList ? (
                                <>
                                    {codList?.map((item) => (
                                        <>
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                }}
                                            >
                                                <Row>
                                                    <b>{item.customer}</b>
                                                </Row>
                                                <Row>
                                                    {" "}
                                                    <Link
                                                        to={{
                                                            pathname: `/customer-so-detail/${item.soId}`,
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Link>
                                                </Row>
                                                <Row>{moneyParser(item.grandTotal)}</Row>
                                            </div>
                                            <hr/>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                        }}
                                    >
                                        <p>Tidak ada notifikasi baru saat ini.</p>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <></>
            )}

            {showKeepNotification ? (
                <>
                    <Card
                        ref={notificationRef}
                        style={{
                            width: 280,
                            height: 350,
                            right: 315,
                            top: 55,
                            position: "fixed",
                            zIndex: 10,
                            overflow: "auto",
                        }}
                        border="warning"
                    >
                        <Card.Body
                            style={{
                                padding: 10,
                            }}
                        >
                            <b>PESANAN CHECKOUT</b>

                            <hr/>

                            {keepList ? (
                                <>
                                    {keepList?.map((item) => (
                                        <>
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                }}
                                            >
                                                <Row>
                                                    <b>{item.customer}</b>
                                                </Row>
                                                <Row>
                                                    <Link
                                                        to={{
                                                            pathname: `/customer-so-detail/${item.soId}`,
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Link>
                                                </Row>
                                                <Row>{moneyParser(item.grandTotal)}</Row>
                                            </div>
                                            <hr/>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                        }}
                                    >
                                        <p>Tidak ada notifikasi baru saat ini.</p>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <></>
            )}

            {showKeepBarangNotification ? (
                <>
                    <Card
                        ref={notificationRef}
                        style={{
                            width: 280,
                            height: 350,
                            right: 345,
                            top: 55,
                            position: "fixed",
                            zIndex: 10,
                            overflow: "auto",
                        }}
                        border="warning"
                    >
                        <Card.Body
                            style={{
                                padding: 10,
                            }}
                        >
                            <b>KEEP BARANG</b>

                            <hr/>

                            {keepBarangList ? (
                                <>
                                    {keepBarangList?.map((item) => (
                                        <>
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                }}
                                            >
                                                {/* <Row>
                          <b>{item.customer}</b>
                        </Row> */}
                                                <Row>
                                                    <Link
                                                        to={{
                                                            pathname: `/keep-barang/index`,
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Link>
                                                </Row>
                                                {/* <Row>{moneyParser(item.grandTotal)}</Row> */}
                                            </div>
                                            <hr/>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                        }}
                                    >
                                        <p>Tidak ada notifikasi baru saat ini.</p>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <></>
            )}

            {/* {showNotificationList ? (
        <>
          <Card
            ref={notificationRef}
            style={{
              width: 280,
              height: 350,
              right: 360,
              top: 5,
              position: "absolute",
              zIndex: 10,
              overflow: "auto",
            }}
            border="warning"
          >
            <Card.Body
              style={{
                padding: 10,
              }}
            >
              <b>CHAT</b>

              <hr />

              {ChatNotificationListState.length > 0 ? (
                <>
                  {ChatNotificationListState.map((item) => (
                    <>
                      <div
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        <Row>
                          <b>{item.customer}</b>
                        </Row>
                        <Row>{item.description}</Row>
                        <Row>{moneyParser(item.grandTotal)}</Row>
                      </div>
                      <hr />
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                  >
                    <p>Tidak ada notifikasi baru saat ini.</p>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <></>
      )} */}

            <PageLink/>

            <AlertMessage
                {...alertProps}
                show={showAlert}
                onAlertCloseAction={onAlertClose}
            ></AlertMessage>
        </Layout>
    );
};

export default requiredAuth(AdminPanelPage);
