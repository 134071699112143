import React from "react";
import { Container, Form } from "react-bootstrap";
import SettingAddressForm from "../components/SettingAddressForm";

const SettingAddressPage = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="menu-title">Setting Alamat</Form.Label>
      </Form.Group>

      <SettingAddressForm />
    </Container>
  );
};

export default SettingAddressPage;
