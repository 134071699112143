import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {Card, Form, Row, Col, Button, Modal, Spinner} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import Select from "react-select";

import {
  ChangeStatusModel,
  CreateInternalUseProductModel,
  InternalUseModel,
  ProductOptionModel,
  VariantModel,
} from "../internalUse.model";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  getWarehouseList,
  getGeneratedInternalUseNumber,
  resetStatus,
  resetSingle,
  getProductListByWarehouse,
  changeStatusInternalUse,
  getListInternalUseProductByInternalUse,
  resetInternalUseProductItem,
  createInternalUseProduct,
  deleteInternalUseProduct,
  createInternalUseWithDetail,
} from "../internalUse.reducer";
import { StringDecoder } from "string_decoder";
import AlertMessage from "../../../components/alertMessage";
import InternalUseItemTable from "./InternalUseItemTable";
import { DragSwitch } from "react-dragswitch";
import DataTable from "react-data-table-component";
import hapus from "./../../../assets/vector/delete.png";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import NumberFormat from "react-number-format";
import AsyncSelect from "react-select/async";
import useAsyncProductOption from "../../../hooks/product/useAsyncProductOption";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const InternalUseCreate = ({
  data = {} as InternalUseModel,
  isEdit = false,
  ...props
}: InternalUseFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { id } = useParams<{ id: string }>();

  const generatedInternalUseNumberState = useSelector(
    (state: RootState) => state.internalUse.generatedInternalUseNumber ?? ""
  );

  const createInternalUseStatus = useSelector(
    (state: RootState) => state.internalUse.status ?? ""
  );

  const internalUseIdState = useSelector(
    (state: RootState) => state.internalUse.internalUseId ?? ""
  );

  const warehouseState = useSelector(
    (state: RootState) => state.internalUse.warehouseList ?? []
  );

  const internalUseItemList = useSelector(
    (state: RootState) => state.internalUse.internalUseItemList ?? []
  );

  const productByWarehouseState = useSelector(
    (state: RootState) => state.internalUse.productByWarehouseList ?? []
  );

  const isLoading = useSelector(
      (state: RootState) => state.internalUse.isLoading
  );

  const errorMessage = useSelector(
    (state: RootState) => state.customer.error ?? ""
  );

  const userId = useSelector((state: RootState) => state.user.id ?? "");

  const userRole = useSelector((state: RootState) => state.user.role ?? "");

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isCreateSuccess, setIsCreateSuccess] = useState(false);

  const [validated, setValidated] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");
  const [internalUseNumber, setInternalUseNumber] = useState("");
  const [internalUseDate, setInternalUseDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [note, setNote] = useState("");

  const [internalUseDetail, setInternalUseDetail] = useState<
    CreateInternalUseProductModel[]
  >([]);

  const [productList, setProductList] = useState<ProductOptionModel[]>([]);

  const [productId, setProductId] = useState("");
  const [productVariantId, setProductVariantId] = useState("");
  const [productQty, setProductQty] = useState(0);
  const [productNote, setProductNote] = useState("");
  const [productName, setProductName] = useState("");
  const [variantName, setVariantName] = useState("");
  const [productCode, setProductCode] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [status, setStatus] = useState(0);

  const [selectedProductVariantList, setSelectedProductVariantList] = useState<
    VariantModel[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [timer, setTimer] = useState(setTimeout(() => {
  }, 1000));

  const {getProductOptions} = useAsyncProductOption();

  const handleClose = () => {
    setProductQty(0);
    setNote("");
    setShowModal(false);
    // history.goBack();
  };
  const handleShow = () => setShowModal(true);

  const onAlertClose = () => {
    setShowAlert(false);
    if (createInternalUseStatus === "success") {
      dispatch(resetStatus());
      dispatch(resetInternalUseProductItem());
      setInternalUseDetail([]);
      setIsCreateSuccess(true);
      history.replace("/internal-use/index");
    } else {
      dispatch(resetStatus());
      dispatch(resetInternalUseProductItem());
    }
  };

  const onWarehouseIDChange = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log("W : " + e.target.value);
    setWarehouseId(e.target.value);

    if (e.target.value !== "") {
      dispatch(getProductListByWarehouse(e.target.value));
      setInternalUseDetail([]);
    }
  };

  const onNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const onProductNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductNote(e.target.value);
  };

  const onQtyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductQty(+e.target.value);
  };

  const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value.split("&")[0]);
    setProductId(e.target.value);
    setProductName(e.target.value.split("&")[1]);
    setProductCode(e.target.value.split("&")[2]);

    setSelectedProductVariantList(
      productByWarehouseState.filter(
        (item) => item.id === e.target.value.split("&")[0]
      )[0].variantList ?? []
    );
  };

  const onSelectedProductChange = (product: any) => {
    setProductId(product.id);
    setProductCode(product.sku);
    setProductName(product.productName);

    setSelectedProductVariantList(
      productByWarehouseState.filter(
        (item) => item.id === product.id
      )[0]?.variantList ?? []
    );
    // dispatch(getListVariationByProduct(product.id));
  };

  const onProductVariantChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductVariantId(e.target.value);
    setVariantName(e.target.value.split("&")[1]);
  };

  const onAddProductClick = () => {
    // if (!isCreateSuccess) {
    //   setAlertProps({
    //     variant: "info",
    //     title: "Tambah Produk Gagal",
    //     content: "Harap simpan data Internal Use terlebih dahulu.",
    //   });
    //   setShowAlert(true);
    // } else {
    handleShow();
    // }
  };

  const onSaveProductClick = () => {
    setInternalUseDetail((prevState) => [
      ...prevState,
      {
        id: "",
        productId: productId.split("&")[0],
        variationId: productVariantId.split("&")[0],
        qty: productQty,
        note: productNote,
        productCode: productCode,
        productName: productName,
        variationName: variantName,
      },
    ]);

    // dispatch(
    //   createInternalUseProduct({
    //     // internalUseId: internalUseIdState,
    //     productId: productId,
    //     variationId: productVariantId,
    //     qty: productQty,
    //     note: productNote,
    //   })
    // );
    handleClose();
  };

  const onCancelClick = () => {
    setInternalUseNumber("");
    setWarehouseId("");
    setNote("");
    setInternalUseDate(new Date().toISOString().substr(0, 10));
    dispatch(resetInternalUseProductItem());
    dispatch(resetSingle());
    dispatch(resetStatus());

    history.goBack();
  };

  const onApproveClick = () => {
    dispatch(
      changeStatusInternalUse({
        id: id,
        status: 1,
        approveBy: userId,
      } as ChangeStatusModel)
    );
  };

  const onRejectClick = () => {
    dispatch(
      changeStatusInternalUse({
        id: id,
        status: 2,
        approveBy: userId,
      } as ChangeStatusModel)
    );
  };

  const onSaveInternalUse = () => {
    dispatch(
      createInternalUseWithDetail({
        internalUseDate: internalUseDate,
        note: note,
        warehouseId: warehouseId,
        detail: internalUseDetail,
      })
    );
  };

  const onEditItemClicked = () => {};

  const onItemDelete = (internalUseProductId: string, index: number) => {
    //dispatch(deleteInternalUseProduct(internalUseProductId));
  };

  // useEffect(() => {
  //   if (generatedInternalUseNumberState !== "") {
  //     setInternalUseNumber(generatedInternalUseNumberState);
  //   }
  // }, [generatedInternalUseNumberState]);

  // useEffect(() => {
  //   if (warehouseId !== "") {
  //     dispatch(getProductListByWarehouse(warehouseId));
  //     setInternalUseDetail([]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [warehouseId]);

  useEffect(() => {
    if (!isEdit) {
      // dispatch(getGeneratedInternalUseNumber());
      dispatch(getWarehouseList());
      setInternalUseDetail([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setInternalUseNumber(data.internalUserNumber);
      setInternalUseDate(data.internalUseDate.substr(0, 10));
      setWarehouseId(data.warehouseId);
      setNote(data.note);
      setStatus(data.status);
      dispatch(getListInternalUseProductByInternalUse(id));
      dispatch(getProductListByWarehouse(warehouseId));
      //dispatch(getProductListByWarehouse(data.warehouseId));
    } else {
      // setInternalUseNumber("")
      // setWarehouseId("")
      // setNote("");
      // setInternalUseDate(new Date().toISOString().substr(0, 10))
    }
  }, [data]);

  useEffect(() => {
    if (internalUseItemList.length != 0) {
      setInternalUseDetail(internalUseItemList);
    }
  }, [internalUseItemList]);

  useEffect(() => {
    if (createInternalUseStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Data Internal Use sudah tersimpan.",
        content: " ",
      });
      setShowAlert(true);
    } else if (
      createInternalUseStatus === "success-add-product" ||
      createInternalUseStatus === "success-delete-product"
    ) {
      dispatch(getListInternalUseProductByInternalUse(internalUseIdState));
    } else if (createInternalUseStatus === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "Gagal menyimpan data Internal Use.",
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInternalUseStatus]);

  useEffect(() => {
    if (productByWarehouseState.length !== 0) {
      setProductList([]);
      productByWarehouseState.map((product) => {
        setProductList((prevState) => [
          ...prevState,
          {
            id: product.id,
            sku: product.code,
            productName: product.name,
            label: product.code + " - " + product.name,
            uom: product.uom!,
          },
        ]);
      });
    }
  }, [productByWarehouseState]);

  const columns = [
    {
      name: "SKU",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "PRODUCT NAME",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIANT",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: "qty",
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id?: any }, index: number) => (
        <>
          {/* <img src={edit} onClick={() => onEditItemClicked(  )}></img> */}
          {"    "}
          <span
            onClick={() => {
              if (!isEdit) {
                if (
                  window.confirm("Apakah kamu yakin akan menghapus item ini?")
                )
                  onItemDelete(d.id ?? "", index);
              }
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  const promiseOptions = (inputValue: string) =>
      new Promise<ProductOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getProductOptions(inputValue));
            }, 1000)
        );
      });

  return (
    <div>
      <Button
        className="float-right"
        style={{ width: 160, marginLeft: 10 }}
        variant="info"
        // type="submit"
        // form="internalUseForm"
        onClick={onSaveInternalUse}
        disabled={isLoading}
      >
        {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Save"}
      </Button>{" "}
      {isEdit &&
      status === 0 &&
      (userRole === "Admin" || userRole === "SUPER ADMIN") ? (
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="success"
          onClick={onApproveClick}
        >
          Approve
        </Button>
      ) : (
        ""
      )}
      {isEdit &&
      status === 0 &&
      (userRole === "Admin" || userRole === "SUPER ADMIN") ? (
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="danger"
          onClick={onRejectClick}
        >
          Reject
        </Button>
      ) : (
        ""
      )}
      <Button
        className="float-right"
        style={{ width: 130, marginLeft: 10 }}
        variant="outline-info"
        onClick={onCancelClick}
      >
        Back
      </Button>{" "}
      <Form.Group>
        <Form.Label className="title">Internal Use</Form.Label>
      </Form.Group>
      <Grid textAlign="center">
        <Card>
          <Card.Body>
            <b>{isEdit ? "Edit Data Internal Use" : "Add Data Internal Use"}</b>

            <Form
            // id="internalUseForm"
            // noValidate
            // validated={validated}
            // onSubmit={(e) => {
            //   const form = e.currentTarget;
            //   if (form.checkValidity() === false) {
            //     e.preventDefault();
            //     e.stopPropagation();
            //   } else {
            //     e.preventDefault();
            //     props.onSubmit(
            //       internalUseDate,
            //       0,
            //       internalUseNumber,
            //       note,
            //       warehouseId,
            //       isActive
            //     );
            //   }
            //   setValidated(true);
            // }}
            >
              {/* <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Internal Use Number
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    placeholder="-"
                    required
                    disabled
                    // onChange={onInternalUseNumberChange}
                    value={internalUseNumber}
                  ></Form.Control>
                </Col>
              </Form.Group> */}

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Date
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="Date"
                    required
                    disabled
                    // defaultValue={internalUseDate.toISOString().substr(0, 10)}
                    value={internalUseDate.substr(0, 10)}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    onChange={onWarehouseIDChange}
                    value={warehouseId}
                  >
                    <option value={""}>Pilih Warehouse</option>
                    {warehouseState.map((Item) => {
                      return (
                        <option value={Item.id}>
                          {Item.code} - {Item.name} - {Item.city}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    onChange={onNoteChange}
                    value={note}
                  />
                </Col>
              </Form.Group>

              {isEdit ? (
                <>
                  <hr />

                  {status === 0 ? (
                    <>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                          Status
                        </Form.Label>
                        <Col sm={5}>
                          <Form.Control
                            as="select"
                            value={status}
                            disabled
                            onChange={(e) => {
                              setStatus(Number(e.target.value));
                            }}
                          >
                            <option value={0}>Request</option>
                            <option value={1}>Approved</option>
                            <option value={2}>Rejected</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                          Status
                        </Form.Label>
                        <Col sm={5}>
                          <Form.Control
                            as="select"
                            value={status}
                            disabled
                            onChange={(e) => {
                              setStatus(Number(e.target.value));
                            }}
                          >
                            <option value={0}>Request</option>
                            <option value={1}>Approved</option>
                            <option value={2}>Rejected</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </>
                  )}

                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active
                    </Form.Label>
                    <Col>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}

              {!isEdit ? (
                <>
                  <hr />

                  <div className="btn-footer-group">
                    <Button
                      variant="info"
                      style={{ width: 130, height: 45 }}
                      onClick={onAddProductClick}
                    >
                      Add Product
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Grid>
      <Grid style={{ marginTop: "20px" }}>
        <Card>
          <Card.Body>
            <DataTable
              columns={columns}
              data={internalUseDetail}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              noHeader
              // selectableRows
              customStyles={TableCustomStyles}
              paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
            />

            {/* <InternalUseItemTable isEdit={isEdit} internalUseId={data.id} warehouseId={data.warehouseId}/> */}
          </Card.Body>
        </Card>
      </Grid>
      {/* Modal Add / Edit Product Data */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Add Product</div>
        </Modal.Header>
        <Modal.Body>
          <Form
          // id="internalUseProductForm"
          // onSubmit={(e) => {
          //   const form = e.currentTarget;
          //   if (form.checkValidity() === false) {
          //     e.preventDefault();
          //     e.stopPropagation();
          //   } else {
          //     e.preventDefault();
          //     // if (PaymentMethodSingleDataState.id === undefined) {
          //     //     props.onSubmit(name, roleId, isActive);
          //     //     setName("");
          //     //     setRoleId([])
          //     // } else {
          //     //     onEdit();
          //     //     setName("");
          //     //     setRoleId([])
          //     // }
          //   }
          //   // setValidated(true);
          // }}
          >
            <Form.Group className="input-padding">
              <Form.Label>Product</Form.Label>
              {/* <Form.Control
                as="select"
                onChange={onProductChange}
                value={productId}
              >
                <option>Pilih Product</option>
                {productByWarehouseState.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name + "&" + Item.code}>
                      {Item.code} - {Item.name}{" "}
                    </option>
                  );
                })}

              
              </Form.Control> */}

              {/*<Select*/}
              {/*    // defaultValue={selectedPartnerId}*/}
              {/*    onChange={onSelectedProductChange}*/}
              {/*    options={productList}*/}
              {/*  />*/}

              <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                           onChange={onSelectedProductChange} />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Varian</Form.Label>
              <Form.Control
                as="select"
                onChange={onProductVariantChange}
                value={productVariantId}
              >
                <option>Pilih Varian</option>
                {selectedProductVariantList.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name}>
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Quantity</Form.Label>

              {/* <Form.Control
                type="number"
                onChange={onQtyChange}
                value={productQty}
              /> */}

              <NumberFormat
                placeholder="1"
                thousandSeparator={true}
                className="bootstrap-input"
                inputmode="numeric"
                value={productQty}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setProductQty(val);
                }}
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Note</Form.Label>

              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Note"
                value={productNote}
                onChange={onProductNoteChange}
              />
            </Form.Group>

            <div className="btn-footer-group float-right">
              <Button
                variant="info"
                style={{ width: 130, height: 45 }}
                onClick={onSaveProductClick}
              >
                Add
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export interface InternalUseFormProps {
  onSubmit: (
    internalUseDate: string,
    status: number,
    internalUseNumber: string,
    note: string,
    warehouseId: string,
    isActive: boolean
  ) => void;
  data: InternalUseModel;
  isEdit: boolean;
}

export default InternalUseCreate;
