import {
  KeepBelumLunasDetailModel,
  KeepBelumLunasModel,
  SalesOrderModel,
} from "./keepBelumLunas.model";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../../..";
import Api from "./keepBelumLunas.api";

export interface KeepBelumLunasSlice {
  name?: string;
  single?: SalesOrderModel;
  list?: KeepBelumLunasModel[];
  detailList?: KeepBelumLunasDetailModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const updateOrderDetail = createAsyncThunk(
  "KeepBelumLunasState/updateSalesOrder",
  async (model: SalesOrderModel, { rejectWithValue }) => {
    try {
      return await Api.updateOrderDetail(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getKeepBelumLunasList = createAsyncThunk(
  "KeepBelumLunasState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getKeepBelumLunasList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getKeepBelumLunasDetailList = createAsyncThunk(
  "KeepBelumLunasDetailState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getKeepBelumLunasDetailList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleOrderDetail = createAsyncThunk(
  "KeepBelumLunasDetailState/getSingle",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getOrderDetailSingle(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const KeepBelumLunasSlice = createSlice({
  name: "KeepBelumLunasState",
  initialState: {} as KeepBelumLunasSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetStatusKeepBelumLunas: (state) => {
      state.status = "";
    },
    resetSingleKeepBelumLunas: (state) => {
      state.single = {} as SalesOrderModel;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateOrderDetail.pending, (state) => {
      state.status = "";
      state.isLoading = true;
    });
    builder.addCase(updateOrderDetail.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.isLoading = false;
    });
    builder.addCase(updateOrderDetail.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });

    builder.addCase(getKeepBelumLunasList.pending, (state) => {
      state.list = undefined;
      state.isLoading = true;
    });
    builder.addCase(getKeepBelumLunasList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getKeepBelumLunasList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getKeepBelumLunasDetailList.pending, (state) => {
      state.detailList = undefined;
      state.isLoading = true;
    });
    builder.addCase(getKeepBelumLunasDetailList.fulfilled, (state, { payload }) => {
      state.detailList = payload;
      state.isLoading = false;
    });
    builder.addCase(getKeepBelumLunasDetailList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getSingleOrderDetail.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleOrderDetail.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleOrderDetail.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setFilter, resetSingleKeepBelumLunas, resetStatusKeepBelumLunas } =
  KeepBelumLunasSlice.actions;
export default KeepBelumLunasSlice.reducer;
