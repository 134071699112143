import axios from "axios";
import { endpoint } from "../../../constants";

import {
  ItemReceiveModel,
  ItemReceiveDetailModel,
  CreateItemReceiveDetailModel,
  FilterModel
} from "./penerimaanInventory.model";

export default class Api {
  static createData(model: ItemReceiveModel) {
    return axios.post<any>(endpoint + "/api/ItemReceive/Create", model).then((res) => res.data);
  }

  static createDetailData(model: CreateItemReceiveDetailModel) {
    return axios
      .post<any>(endpoint + "/api/ItemReceive/CreateDetail", model)
      .then((res) => res.data.data);
  }

  static getList() {
    return axios.get<any>(endpoint + "/api/ItemReceive/GetList").then((res) => res.data.data);
  }

  static getListWithPaging(model: FilterModel) {
    return axios.post<any>(endpoint + "/api/ItemReceive/GetListWithPaging", model).then((res) => res.data.data);
  }


  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/ItemReceive/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: ItemReceiveModel) {
    return axios.post<any>(endpoint + "/api/ItemReceive/update", model).then((res) => res.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/ItemReceive/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static changeVoidStatus(id: string) {
    return axios
      .post<any>(endpoint + "/api/ItemReceive/ChangeVoidStatus?itemReceiveId=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static getItemReceiveDetailList(id: string) {
    return axios
      .get<any>(endpoint + "/api/ItemReceive/GetListByMaster?ItemReceiveId=" + id)
      .then((res) => res.data.data);
  }

  static getListVariationByProduct(id: string) {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
      .then((res) => res.data.data);
  }
}
