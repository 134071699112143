import { endpoint } from "../../constants";
import { SubscriberRequestModel} from "./subscriber.model";
import axios from "axios";

export default class SubscriberApi {
  static createData(model: SubscriberRequestModel) {
    return axios.post<any>(endpoint + "/api/Subscribe/Create", model).then((res) => res.data.data);
  }

  static getList() {
    return axios.get<any>(endpoint + "/api/Subscribe/GetList").then((res) => res.data.data);
  }

  static updateData(model: SubscriberRequestModel) {
    return axios.post<any>(endpoint + "/api/Subscribe/Update", model).then((res) => res.data.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/Subscribe/Delete", null,{
        params: {
            Id: id
        }
      })
      .then((res) => [{ response: res.data.data }]);
  }
}
