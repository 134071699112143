import React, { useEffect, useState, useRef, Fragment } from "react";
import { Card, Form, Col, Row, Button, Modal } from "react-bootstrap";
import { Grid, Item } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  exportToExcel,
  getExpeditionReport,
  updateExpeditionReport,
} from "../laporanEkspedisi.reducer";
import { DatePicker, Space } from "antd";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { FaFileExport } from "react-icons/fa";
import { FiPrinter } from "react-icons/fi";
import { Link } from "react-router-dom";
import { UpdateExpeditionReportRequestModel } from "../laporanEkspedisi.model";

const LaporanEkspedisiTable = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const tableRef = useRef(null);

  const userState = useSelector((state: RootState) => state.user ?? {});

  const ExpeditionReportState = useSelector(
    (state: RootState) => state.expeditionReport.list ?? []
  );

  const TotalRowState = useSelector(
    (state: RootState) => state.expeditionReport.totalRow ?? 0
  );

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //filter
  const [filterName, setFilterName] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterReportType, setFilterReportType] = useState(2);
  const [filterMonthly, setFilterMonthly] = useState(new Date().toString());

  const [totalExpedition, setTotalExpedition] = useState(0);
  const [totalExpeditionFee, setTotalExpeditionFee] = useState(0);

  const [selectedId, setSelectedId] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState(0);
  const [updatedNote, setUpdatedNote] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const handleRangePicker = (event: any, picker: any) => {
    setFilterStartDate(picker[0]);
    setFilterEndDate(picker[1]);
  };

  const handleChange = (e: any) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    setFilterReportType(parseInt(e.target.value));
  };

  const handleMonthly = (e: any) => {
    setFilterMonthly(e.target.value);
  };

  const handleExportToExcel = () => {
    dispatch(
      exportToExcel({
        reportType: filterReportType,
        filterMonthly: filterMonthly,
        startFilterDate: filterStartDate,
        endFilterDate: filterEndDate,
        name: filterName,
        skip: currentPage - 1,
        take: perPage,
      })
    );
  };

  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp " + result + ",-";
    }
    return value;
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterReportType(2);
    setFilterMonthly(new Date().toString());
    setCurrentPage(1);

    dispatch(
      getExpeditionReport({
        reportType: 2,
        filterMonthly: "",
        startFilterDate: "",
        endFilterDate: "",
        name: "",
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
    setCurrentPage(page);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getExpeditionReport({
        reportType: filterReportType,
        filterMonthly: filterMonthly,
        startFilterDate: filterStartDate,
        endFilterDate: filterEndDate,
        name: filterName,
        skip: page,
        take: perPage,
      })
    );
  };

  const onUpdateData = (
    salesOrderId: string,
    paymentStatus: number,
    expeditionNote: string
  ) => {
    dispatch(
      updateExpeditionReport({
        salesOrderId: salesOrderId,
        paymentStatus: paymentStatus,
        expeditionNote: expeditionNote,
      } as UpdateExpeditionReportRequestModel)
    );
  };

  const onSaveClick = () => {
    dispatch(
      updateExpeditionReport({
        salesOrderId: selectedId,
        paymentStatus: updatedStatus,
        expeditionNote: updatedNote,
      } as UpdateExpeditionReportRequestModel)
    );
    setShowModal(false);
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    setLoading(false);

    if (ExpeditionReportState.length !== 0) {
      setTotalExpedition(
        ExpeditionReportState.reduce((prev, current) => {
          return prev + current.deliveryCount;
        }, 0)
      );

      setTotalExpeditionFee(
        ExpeditionReportState.reduce((prev, current) => {
          return prev + current.shipmentCost;
        }, 0)
      );
    }
  }, [ExpeditionReportState]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  const dummy = [
    {
      name: "-",
    },
    {
      name: "-",
    },
  ];

  const columns = [
    {
      name: "TANGGAL",
      selector: (d: { orderDate: string }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {moment(new Date(d.orderDate)).format("DD/MM/YYYY")}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "SO",
      cell: (row: any) => (
        <Fragment>
          <Link
            to={{
              pathname: `/customer-so-detail/${row.id}`,
            }}
          >
            {row.soNumber}
          </Link>
        </Fragment>
      ),
      sortable: true,
    },
    {
      name: "EKSPEDISI",
      selector: "expeditionName",
      sortable: true,
    },
    {
      name: "JUMLAH",
      selector: "totalItem",
      sortable: true,
    },
    {
      name: "BERAT",
      selector: "totalWeight",
      sortable: true,
    },
    {
      name: "BIAYA",
      selector: (d: { shipmentCost: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>{moneyParser(d.shipmentCost)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { paymentStatus: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.paymentStatus === 0 && "Unpaid"}
            {d.paymentStatus === 1 && "Paid"}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "CATATAN",
      selector: "expeditionNote",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: {
        id: string;
        paymentStatus: number;
        expeditionNote: string;
      }) => (
        <>
          {
            // d.paymentStatus === 1 &&
            userState.role === "Finance" || userState.role === "Admin" ? (
              <>
                <button
                  style={{
                    margin: 3,
                    width: 70,
                    height: 23,
                    background: "#F52B3D",
                    borderRadius: 5,
                    color: "white",
                  }}
                  // disabled={d.paymentStatus !== 0 && true}
                  onClick={() => {
                    // handleChangeStatus(2, d.id);
                    setSelectedId(d.id);
                    setUpdatedStatus(0);
                    setUpdatedNote(d.expeditionNote);
                    setShowConfirmationModal(true);
                  }}
                >
                  UNPAID
                </button>
                <br />
                <button
                  style={{
                    margin: 3,
                    width: 70,
                    height: 23,
                    background: "#2ECC71",
                    borderRadius: 5,
                    color: "white",
                  }}
                  // disabled={d.paymentStatus !== 0 && true}
                  onClick={() => {
                    // handleChangeStatus(1, d.id);
                    setSelectedId(d.id);
                    setUpdatedStatus(1);
                    setUpdatedNote(d.expeditionNote);
                    setShowConfirmationModal(true);
                  }}
                >
                  PAID
                </button>
                <br />
                <button
                  style={{
                    margin: 3,
                    width: 70,
                    height: 23,
                    background: "#0000FF",
                    borderRadius: 5,
                    color: "white",
                  }}
                  // disabled={d.paymentStatus !== 0 && true}
                  onClick={() => {
                    // handleChangeStatus(1, d.id);
                    setSelectedId(d.id);
                    setUpdatedStatus(d.paymentStatus);
                    setUpdatedNote(d.expeditionNote);
                    setShowModal(true);
                  }}
                >
                  Edit Note
                </button>
              </>
            ) : (
              <> </>
            )
          }
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="page-title mb-4 d-flex align-items-center justify-content-between">
        Laporan Ekspedisi
        <div>
          <Button className="addor" onClick={handleExportToExcel}>
            <FaFileExport /> Export to Excel
          </Button>
          {/* <ReactToPrint
            trigger={() => (
              <Button className="addor">
                <FiPrinter /> Print
              </Button>
            )}
            content={() => tableRef.current}
          /> */}
          <a
            href={window.location.origin + "/report-expedition/print"}
            target="_blank"
          >
            <Button
              style={{ fontSize: 14, width: 202, height: 40 }}
              variant="outline-info"
              onClick={() => {
                // dispatch(countSOPrint(salesOrderID));
              }}
            >
              Print
            </Button>
          </a>{" "}
        </div>
      </div>
      {/* <Button
        variant="outline-info"
        style={{ width: 130, height: 45, marginLeft: 10 }}
        type="submit"
        // onClick={onFilterResetClick}
      >
        Print
      </Button>
      <Button
        variant="info"
        style={{ width: 130, height: 45, marginLeft: 10 }}
        type="submit"
        // onClick={onFilterClick}
      >
        Eksport Excel
      </Button> */}
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Tanggal
                  </Form.Label>
                  <Col>
                    <div className="">
                      <div className="d-flex align-items-center mb-3 justify-content-between">
                        <Form.Check
                          className="radiocustom m-0 "
                          style={{ marginBottom: 13 }}
                          inline
                          type="radio"
                          id="user4"
                          label="All"
                          name="reportType"
                          checked={filterReportType === 2}
                          value={2}
                          onClick={handleChange}
                        />
                      </div>
                      <div className="d-flex align-items-center mb-3 justify-content-between">
                        <Form.Check
                          className="radiocustom m-0 "
                          style={{ marginBottom: 13 }}
                          inline
                          type="radio"
                          id="user4"
                          label="Harian"
                          name="reportType"
                          checked={filterReportType === 0}
                          value={0}
                          onClick={handleChange}
                        />
                        <Space direction="vertical" size={12}>
                          <RangePicker
                            className="custext"
                            onChange={handleRangePicker}
                            value={
                              filterStartDate !== "" && filterEndDate !== ""
                                ? [
                                    moment(filterStartDate, "YYYY-MM-DD"),
                                    moment(filterEndDate, "YYYY-MM-DD"),
                                  ]
                                : undefined
                            }
                            format="YYYY-MM-DD"
                          />
                        </Space>
                      </div>
                      <div className="d-flex align-items-center mb-3 justify-content-between">
                        <Form.Check
                          className="radiocustom m-0 "
                          style={{ marginBottom: 13 }}
                          inline
                          type="radio"
                          id="user4"
                          label="Bulanan"
                          name="reportType"
                          checked={filterReportType === 1}
                          value={1}
                          onClick={handleChange}
                        />
                        <input
                          className="custext"
                          type="month"
                          name="filterMonthly"
                          value={filterMonthly}
                          onChange={handleMonthly}
                        />
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Status
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      // value={filterTempPaymentStatus}
                      // onChange={(e) => {
                      //   if (e.target.value.toString() === "2") {
                      //     setfilterPaymentStatus(null);
                      //     setFilterTempPaymentStatus(parseInt(e.target.value));
                      //   } else {
                      //     setFilterTempPaymentStatus(parseInt(e.target.value));
                      //     setfilterPaymentStatus(parseInt(e.target.value));
                      //   }
                      // }}
                    >
                      <option value={2}>All</option>
                      <option value={0}>UNPAID</option>
                      <option value={1}>PAID</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>

              {/* <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Tanggal
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="date"

                      // onChange={(e) =>
                      //   setFilterStartDate(
                      //     new Date(e.target.value).toISOString()
                      //   )
                      // }
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="date"

                      // onChange={(e) =>
                      //   setFilterEndDate(new Date(e.target.value).toISOString())
                      // }
                    />
                  </Col>
                </Form.Group>
              </Col> */}
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Ekspedisi
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterName}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Catatan
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterName}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Col md={4}>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
            <Row></Row>
          </Card.Body>
        </Card>

        <hr />

        <Card>
          <Card.Body>
            {/* <Row>
              <b>TOTAL EKSPEDISI : {totalExpedition}</b>
            </Row> */}
            <Row>
              <b>TOTAL BIAYA : Rp. {totalExpeditionFee}</b>
            </Row>
          </Card.Body>
        </Card>

        <hr />

        <Card>
          <DataTable
            columns={columns}
            data={ExpeditionReportState}
            pagination
            paginationServer
            noHeader
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            paginationTotalRows={TotalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows
          />
        </Card>
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Update Data Ekspedisi</div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="input-padding">
            <Form.Label>Catatan</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="-"
              value={updatedNote}
              onChange={(e) => setUpdatedNote(e.target.value)}
            />
          </Form.Group>
          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onSaveClick}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirmationModal}
        onHide={handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Konfirmasi Update Data</div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="input-padding">
            <Form.Label>
              Yakin akan merubah Status Pembayaran menjadi{" "}
              {updatedStatus === 0 ? <>UNPAID</> : <>PAID</>} ?
            </Form.Label>
          </Form.Group>
          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onSaveClick}
            >
              CONFIRM
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LaporanEkspedisiTable;
