import axios from "axios";
import { endpoint } from "../../constants";
import { MembershipModel, UploadImageModel } from "./settingMembership.model";

export default class SettingMembershipApi {
  static getMembershipList() {
    return axios
      .get<any>(endpoint + "/api/Membership/getList")
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/Membership/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static deleteMembershipSetting(id: string) {
   return axios
     .post<any>(endpoint + "/api/Membership/Delete?Id="+ id)
     .then((res) => res);
 }

  static uploadImage(model: UploadImageModel) {
    return axios
      .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
      .then((res) => res);
  }

  static create(model: MembershipModel) {
    return axios
      .post<any>(endpoint + "/api/Membership/Create", model)
      .then((res) => res.data.data);
  }

  static update(model: MembershipModel) {
    return axios
      .post<any>(endpoint + "/api/Membership/Update", model)
      .then((res) => res.data.data);
  }
}
