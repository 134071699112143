import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrintListDetail from "../components/PrintListDetail";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import { SalesOrderModel } from "../../customer/customer.model";
import { getSingleSalesOrder } from "../../customer/customer.reducer";

const PrintListDetailPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const salesOrderState = useSelector(
    (state: RootState) =>
      state.customer.singleSalesOrder ?? ({} as SalesOrderModel)
  );

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleSalesOrder(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      salesOrderState &&
      Object.keys(salesOrderState).length !== 0 &&
      salesOrderState.constructor === Object
    ) {
      // dispatch(getShipmentBySalesOrder(salesOrderState.id))
      // dispatch(getSalesOrderDetailList(salesOrderState.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOrderState]);

  return (
    <div className="m-3">
      <PrintListDetail SOData={salesOrderState} />
    </div>
  );
};

export default PrintListDetailPage;
