import React, { Component, HTMLAttributes } from 'react';

export interface ContentProps extends HTMLAttributes<HTMLDivElement> {}

export default class Content extends Component<ContentProps> {
  render() {
    return (
      <div className={`admin-content ${this.props.className || ''}`} style={this.props.style || {}}>
        <div className="admin-content__inner">{this.props.children}</div>
      </div>
    );
  }
}
