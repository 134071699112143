import axios from "axios";
import { endpoint } from "../../../../constants";
import { FilterModel, UpdateStatusModel } from "./jatuhTempoDropship.model";

export default class Api {
  static getJatuhTempoDropshipList() {
    return axios
      .get<any>(endpoint + "​​/api/SalesOrder/GetListDueDate?statusOrder=4")
      .then((res) => res.data.data);
  }

  static getListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/getListDueDateDropship",model)
      .then((res) => res.data.data);
  }

  static getJatuhTempoRekapList() {
    return axios
      .get<any>(endpoint + "​​/api/SalesOrder/GetListDueDate?statusOrder=1")
      .then((res) => res.data.data);
  }

  static getJatuhTempoDropshipSingle(id: string) {
    return axios
      .get<any>(endpoint + "/api/Shipment/GetSingleBySalesOrder?salesOrderId=" + id)
      .then((res) => res.data.data);
  }

  static getSalesOrderDetail(id: string) {
    return axios
      .get<any>(endpoint + "​/api/SalesOrder/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static getSinglePaymentConfirmation(id: string) {
    return axios
      .get<any>(endpoint + "/api/PaymentConfirmation/GetBySalesOrder?salesOrderId=" + id)
      .then((res) => res.data.data);
  }

  static updatePayment(model: UpdateStatusModel) {
    return axios
      .post<any>(endpoint + "/api/PaymentConfirmation/UpdateStatusBySalesOrder", model)
      .then((res) => res.data.data);
  }

  static getPartnerTypeEnum() {
    return axios
      .get<any>(endpoint + "/api/Partner/GetPartnerTypeEnumList")
      .then((res) => res.data.data);
  }
}
