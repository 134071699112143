import "../../pesananDalamProses.style.css";
import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import { getListVariationByProduct } from "../../../salesOrder/salesOrder.reducer";
import { Button, Card, Container, Form, Modal } from "react-bootstrap";
import { KeepBelumLunasDetailModel } from "../keepBelumLunas.model";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { endpoint } from "../../../../../constants";
import { Link, useParams } from "react-router-dom";
import edit from "./../../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import axios from "axios";

const DataSalesOrderDetail = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const productState = useSelector(
    (state: RootState) => state.global.listProduct ?? []
  );

  const variationState = useSelector(
    (state: RootState) => state.salesOrder.listVariationByProduct ?? []
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState<KeepBelumLunasDetailModel[]>([]);
  //add sales order detail
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [variationId, setVariationId] = useState("");
  const [variationName, setVariationName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [note, setNote] = useState("");
  const [price, setPrice] = useState(0);
  //modal
  const [show, setShow] = useState(false);

  const columns = [
    {
      name: "Kode Produk",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "Nama Produk",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Tanggal Pesanan",
      selector: (d: { orderDate: string }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.orderDate.substring(0, 10)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Varian",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "HARGA",
      selector: (d: { grandTotal: any; price: any; subTotal: any }) => (
        <>
          <NumberFormat
            value={d.grandTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "CATATAN",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { salesOrderId: string }) => (
        <>
          <Link
            to={{
              pathname: `/order-detail/${d.salesOrderId}`,
            }}
          >
            <img src={edit}></img>
          </Link>
        </>
      ),
    },
  ];
  // end of datatable components

  useEffect(() => {
    axios
      .get<any>(
        endpoint +
          "/api/SalesOrderDetail/GetListBySalesOrder?salesOrderId=" +
          id
      )
      .then((res) => setList(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductId(e.target.value.split("&")[0]);
    setProductName(e.target.value.split("&")[1]);
    dispatch(getListVariationByProduct(e.target.value.split("&")[0]));
  };

  const onVariationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVariationId(e.target.value.split("&")[0]);
    setVariationName(e.target.value.split("&")[1]);
    setPrice(parseInt(e.target.value.split("&")[2]));
  };

  const addSalesOrderDetail = () => {
    const model = {
      salesOrderId: id,
      variationId: variationId,
      productId: productId,
      quantity: quantity,
      note: note,
    };
    axios
      .post<any>(endpoint + "/api/salesOrderDetail/Create", model)
      .then((res) => res.data);
    handleClose();
  };

  const handleShow = () => setShow(true);

  const handleClose = () => {
    window.location.reload();
    setShow(false);
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Button onClick={handleShow} variant="info">
            + tambah
          </Button>
          <DataTable
            columns={columns}
            data={list}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          />
        </Card.Body>
      </Card>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Product</Form.Label>
              <Form.Control as="select" onChange={onProductChange}>
                <option>select</option>
                {productState.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name}>
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Variant</Form.Label>
              <Form.Control as="select" onChange={onVariationChange}>
                <option>select</option>
                {variationState.map((Item) => {
                  return (
                    <option
                      value={Item.id + "&" + Item.name + "&" + Item.salePrice}
                    >
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Qty</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                onChange={(e) => setQuantity(parseInt(e.target.value))}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Catatan</Form.Label>
              <Form.Control
                type="text"
                name="note"
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={addSalesOrderDetail}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataSalesOrderDetail;
