import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel, WarehouseModel } from "./warehouse.model";

export default class WarehouseApi {
    static createWarehouse(warehouse: WarehouseModel) {
        return axios
        .post<any>(
            endpoint + "/api/Warehouse/Create",
            warehouse
        )
        .then((res) => res.data.data);
    }

   static getWarehouseList() {
        return axios
        .get<any>(endpoint + "/api/Warehouse/GetList")
        .then((res) => res.data.data);
   }

   static getWarehouseListWithPaging(model: FilterModel) {
    return axios
    .post<any>(
        endpoint + "/api/Warehouse/GetListWithPaging",
        model
    )
    .then((res) => res.data.data);
}

    static getSingleItem(warehouseId: string) {
        return axios
        .get<any>(
            endpoint +
            "/api/Warehouse/GetSingleItem?id=" +
            warehouseId
        )
        .then((res) => res.data.data);
    }

   static updateWarehouse(warehouse: WarehouseModel) {
        return axios
        .post<any>(endpoint + "/api/Warehouse/update", warehouse)
        .then((res) => res.data.data);
   }

    static deleteWarehouse(warehouseId: string) {
        return axios
        .post<any>(endpoint + "/api/Warehouse/Delete?Id=" + warehouseId)
        .then((res) => [
            { response: res.data.data }
        ]);
    }

    static getProvince() {
        return axios
        .get<any>(endpoint + "/api/Address/GetProvinceByCountry?Id=76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7")
        .then((res) => res.data.data);
    }

    static getCity(provinceId: string) {
        return axios
        .get<any>(endpoint + "/api/Address/GetCityByProvince?Id=" + provinceId)
        .then((res) => res.data.data);
    }

    static getDistrict(cityId: string) {
        return axios
        .get<any>(endpoint + "/api/Address/GetDistrictByCity?Id=" + cityId)
        .then((res) => res.data.data);
    }
}
