import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel } from "./pesananNonCashless.model";

export default class PesananNonCashlessApi {
  static getList(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/GetListSalesOrderCashlessWithPaging", model)
      .then((res) => res.data.data);
  }
}
