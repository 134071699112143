import React from 'react'
import ReturTable from '../components/ReturTable'

const ReturTablePage = () => {
    return (
        <div>
            <ReturTable/>
        </div>
    )
}

export default ReturTablePage
