import {
  Card,
  Container,
  Form,
  Col,
  Row,
  Button,
  Modal, Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  PartnerOptionModel,
  SalesOrderDetailModel,
} from "../salesOrder/salesOrder.model";
import Select from "react-select";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import hapus from "./../../../assets/vector/delete.png";
import { RootState } from "../../../app/store";
import {
  createKeep,
  getListPartner,
  getListVariationByProductAndUser,
  resetStatus,
} from "./KeepBarangForm.reducer";
import {
  getListActiveProduct,
  getListProduct,
} from "../../../global/global.reducer";
import {
  CartDetail,
  CartDetailTemp,
  ProductOptionModel,
  VariantOptionModel,
} from "./KeepBarangForm.model";
import AlertMessage from "../../../components/alertMessage";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import useAsyncProductOption from "../../../hooks/product/useAsyncProductOption";
import {FaMinusCircle, FaPlusCircle} from "react-icons/fa";
import useAsyncPartnerOption from "../../../hooks/partner/useAsyncPartnerOption";
import useAsyncPartnerWithTypeOption from "../../../hooks/partner/useAsyncPartnerWithTypeOption";


const KeepBarangForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const partnerListState = useSelector(
      (state: RootState) => state.keepBarangForm.partnerList ?? []
  );

  const productState = useSelector(
      (state: RootState) => state.global.listActiveProduct ?? []
  );

  const variationState = useSelector(
      (state: RootState) => state.keepBarangForm.listVariationByProduct ?? []
  );

  const keepBarangStatusState = useSelector(
      (state: RootState) => state.keepBarangForm.status ?? ""
  )

  const errorMessage = useSelector(
      (state: RootState) => state.keepBarangForm.error ?? ({} as any)
  );

  const isLoading = useSelector(
      (state: RootState) => state.keepBarangForm.isLoading
  );

  const userState = useSelector((state: RootState) => state.user ?? "");
  const {getPartnerWithTypeOptions} = useAsyncPartnerWithTypeOption();

  const [partnerType, setPartnerType] = useState(0);
  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);
  const [productList, setProductList] = useState<ProductOptionModel[]>([]);
  const [variationList, setVariationList] = useState<VariantOptionModel[]>([]);
  const initialVariant = {
    id: "",
    label: "",
    systemStock: 0,
  } as VariantOptionModel;
  const [selectedVariant, setSelectedVariant] =
      useState<VariantOptionModel>(initialVariant);

  const [cartDetailList, setCartDetailList] = useState<
      CartDetailTemp[]
  >([]);
  const [cartDetailListTemp, setCartDetailListTemp] = useState<CartDetailTemp[]>([]);

  const [keepDetail, setKeepDetail] = useState<SalesOrderDetailModel[]>([]);

  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [selectedPartnerName, setSelectedPartnerName] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedProductSku, setSelectedProductSku] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [selectedVariantPrice, setSelectedVariantPrice] = useState(0);
  const [selectedVariantStock, setSelectedVariantStock] = useState(0);
  const [selectedProductNote, setSelectedProductNote] = useState("");
  const [selectedQty, setSelectedQty] = useState(1);

  const [isSerianContainZeroQty, setIsSerianContainZeroQty] = useState(false);
  const [serianQty, setSerianQty] = useState(0);

  const [show, setShow] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [timer, setTimer] = useState(setTimeout(() => {
  }, 1000));

  const {getProductOptions} = useAsyncProductOption();

  const handleShow = () => {
    setShow(true);
    setCartDetailListTemp([]);
    setSelectedProductId("");
    setSelectedVariantId("");
    setSelectedVariant(initialVariant);
    setSelectedQty(0);
    setSelectedVariantStock(0);
    setSelectedProductNote("");
  };

  const handleClose = () => {
    setShow(false);
    setCartDetailListTemp([]);
    // setSalesOrderDetailTemp([]);
  };

  const onSelectedPartnerChange = (data: any) => {
    setSelectedPartnerId(data.value);
    setSelectedPartnerName(data.label);
  };

  const onSelectedProductChange = (product: any) => {
    setSelectedProductId(product.id);
    setSelectedProductName(product.productName);
    setSelectedProductSku(product.sku);

    setSelectedVariantId("");
    setSelectedVariant(initialVariant);
    setSelectedQty(0);
    setSelectedVariantStock(0);
    setSelectedProductNote("");

    dispatch(
        getListVariationByProductAndUser({
          productId: product.id,
          partnerType: 3,
          userId: userState.id === undefined ? "" : userState.id,
        })
    );
  };

  const onSelectedVariantChange = (variant: any) => {
    setSelectedVariant(variant);
    setSelectedVariantId(variant.id);
    setSelectedVariantStock(variant.systemStock);
  };

  const onNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedProductNote(e.target.value);
  };

  const onSaveProductClick = () => {
    // setCartDetailList((prevState) => [
    //   ...prevState,
    //   {
    //     productId: selectedProductId,
    //     variantId: selectedVariantId,
    //     qty: selectedQty,
    //     note: selectedProductNote,
    //     productName: selectedProductName,
    //     variantName: selectedVariant.label,
    //     price: selectedVariant.price,
    //
    //   },
    // ]);
    cartDetailListTemp.map((item) => {
      if (item.qty !== 0) {
        setCartDetailList((prevState) => [
          ...prevState,
          {
                productId: item.productId,
                variantId: item.variantId,
                qty: item.qty,
                note: item.note,
                productName: item.productName,
                variantName: item.variantName,
                price: item.price * item.qty,
                stockQty: item.stockQty,
          },
        ]);
      }
    });

    handleClose();
  };

  const onSaveKeepClick = () => {
    dispatch(
        createKeep({
          partnerId: selectedPartnerId,
          cartDetailList: cartDetailList,
        })
    );
  };

  const onAlertClose = () => {
    if (keepBarangStatusState === "success") {
      setShowAlert(false);
      dispatch(resetStatus());
      history.replace("/keep-barang/index");
    } else {
      setShowAlert(false);
    }
  };

  useEffect(() => {
    dispatch(getListPartner());
    dispatch(getListActiveProduct());
  }, []);

  useEffect(() => {
    if (productState.length !== 0) {
      setProductList([]);
      productState.map((product) => {
        setProductList((prevState) => [
          ...prevState,
          {
            id: product.id,
            sku: product.inventorySKU,
            productName: product.name,
            label: product.inventorySKU + " - " + product.name,
            uom: product.uom,
          },
        ]);
      });
    }
  }, [productState]);

  useEffect(() => {
    console.log(partnerListState);

    if (partnerListState.length !== 0) {
      setPartnerList([]);

      partnerListState.map((item) => {
        if (item.type === partnerType && item.isActive) {
          setPartnerList((prevState) => [
            ...prevState,
            {
              value: item.id,
              label: item.fullName,
              type: item.type,
            },
          ]);
        }
      });
    }
  }, [partnerListState, partnerType]);

  useEffect(() => {
    if (variationState.length !== 0) {
      // setVariationList([]);
      // variationState.map((variant) => {
      //   setVariationList((prevState) => [
      //     ...prevState,
      //     {
      //       id: variant.id,
      //       label: variant.name,
      //       systemStock: variant.stockQty,
      //       price:
      //           partnerType === 0
      //               ? variant.regulerPriceEndCustomer
      //               : partnerType === 2
      //                   ? variant.regulerPriceReseller
      //                   : variant.regulerPriceStockist,
      //     },
      //   ]);
      // });
      let isContainZeroQty = variationState.some((item) => item.stockQty === 0);
      setIsSerianContainZeroQty(isContainZeroQty);
      setSerianQty(0);
      setCartDetailListTemp([]);
      variationState.map((item) => {
        let variationName = "";
        if (item.customProductAttributeList?.length === 1) {
          variationName += item.customProductAttributeList[0]?.name;
        } else if (item.customProductAttributeList?.length === 2) {
          variationName +=
              item.customProductAttributeList[0]?.name +
              "-" +
              item.customProductAttributeList[1]?.name;
        } else if (item.customProductAttributeList?.length === 3) {
          variationName +=
              item.customProductAttributeList[0]?.name +
              "-" +
              item.customProductAttributeList[1]?.name +
              "-" +
              item.customProductAttributeList[2]?.name;
        }

        setCartDetailListTemp((prevState) => [
          ...prevState,
          {
                productId: item.productId,
                variantId: item.id,
                qty: 0,
                note: "",
                productName: selectedProductSku + " - " + selectedProductName,
                variantName: variationName,
                price: partnerType === 0
                    ? item.regulerPriceEndCustomer
                    : partnerType === 2
                        ? item.regulerPriceReseller
                        : item.regulerPriceStockist,
                stockQty: item.stockQty,
          },
        ]);
      });
    }
  }, [variationState]);

  useEffect(() => {
    if(keepBarangStatusState === "success"){
      setAlertProps({
        variant: "success",
        title: "Berhasil Membuat Data Keep Barang Baru",
        content: "",
      });
      setShowAlert(true);
    } else if( keepBarangStatusState === "failed"){
      setAlertProps({
        variant: "failed",
        title: "Gagal Membuat Data Keep Barang Baru",
        content: errorMessage.data.message === undefined
            ? "Terjadi kesalahan pada sistem."
            : errorMessage.data.message.toString(),
      });
      setShowAlert(true);
    }
  }, [keepBarangStatusState])


  const columns = [
    {
      name: "PRODUCT",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIAN",
      selector: "variantName",
      sortable: true,
    },
    {
      name: "QUANTITY",
      selector: "qty",
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "PRICE",
      selector: (d: { price: number }) => (
          <NumberFormat
              value={d.price}
              displayType="text"
              thousandSeparator={true}
              prefix="Rp. "
          />
      ),
      sortable: true,
    },
    {
      name: "SUBTOTAL",
      selector: (d: { price: number; qty: number }) => (
          <NumberFormat
              value={d.price * d.qty}
              displayType="text"
              thousandSeparator={true}
              prefix="Rp. "
          />
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: {}, index: number) => (
          <>
          <span
              onClick={() => {
                let newList = [...cartDetailList];
                newList.splice(index, 1);
                setCartDetailList(newList);
              }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
          </>
      ),
    },
  ];

  const promiseOptions = (inputValue: string) =>
      new Promise<ProductOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getProductOptions(inputValue));
            }, 1000)
        );
      });

  const onAddQuantityClick = (variation: any) => {
    if (variation.qty < variation.stockQty) {
      let updatedSalesOrderDetailList = cartDetailListTemp.map((item) => {
        if (item === variation) {
          return {
            ...item,
            qty: item.qty + 1,
          };
        } else {
          return item;
        }
      });
      setCartDetailListTemp(updatedSalesOrderDetailList);
    }
  };

  const onSubQuantityClick = (variation: any) => {
    if (variation.qty > 0) {
      let updatedSalesOrderDetailList = cartDetailListTemp.map((item) => {
        if (item === variation) {
          return {
            ...item,
            qty: item.qty - 1,
          };
        } else {
          return item;
        }
      });
      setCartDetailListTemp(updatedSalesOrderDetailList);
    }
  };

  const onVariantNoteChange = (
      e: ChangeEvent<HTMLTextAreaElement>,
      variation: any
  ) => {
    let updatedSalesOrderDetailList = cartDetailListTemp.map((item) => {
      if (item === variation && item.qty !== 0) {
        return {
          ...item,
          note: e.target.value,
        };
      } else {
        return item;
      }
    });
    setCartDetailListTemp(updatedSalesOrderDetailList);
  };

  const onAddSerianClick = () => {
    let isContainZeroQty = cartDetailListTemp.some((item) => item.stockQty === 0);
    let maxStockQty = Math.min(...cartDetailListTemp.map((item) => item.stockQty));

    if (!isContainZeroQty && serianQty < maxStockQty) {
      let updatedSalesOrderDetailList = cartDetailListTemp.map((item) => {
        return {
          ...item,
          qty: serianQty + 1,
        };
      });
      setCartDetailListTemp(updatedSalesOrderDetailList);
      setSerianQty(prevState => prevState + 1);
    }
  }

  const onSubSerianClick = () => {
    let isContainZeroQty = cartDetailListTemp.some((item) => item.stockQty === 0);
    if (!isContainZeroQty) {
      if (serianQty > 0) {
        let updatedSalesOrderDetailList = cartDetailListTemp.map((item) => {
          return {
            ...item,
            qty: serianQty - 1,
          };
        });
        setCartDetailListTemp(updatedSalesOrderDetailList);
        setSerianQty(prevState => prevState - 1);
      }
    }
  };

  const partnerPromiseOptions = (inputValue: string) =>
      new Promise<PartnerOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getPartnerWithTypeOptions(inputValue, partnerType));
            }, 1000)
        );
      });


  console.log(cartDetailListTemp);
  return (
      <Container className="content-body" fluid>
        <Form.Group>
          <Form.Label className="page-title">Keep Barang Form</Form.Label>
        </Form.Group>

        <div>
          <Grid textAlign="center" style={{ height: "100vh" }}>
            <Card className="pt-3">
              <Card.Body>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Tipe Partner
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Check
                        className="radio-button"
                        label="Stockist"
                        type="radio"
                        name="partnerType"
                        checked={partnerType === 1}
                        value={1}
                        onChange={() => {
                          setPartnerType(1);
                        }}
                    />
                    <Form.Check
                        className="radio-button"
                        label="Reseller"
                        type="radio"
                        name="partnerType"
                        checked={partnerType === 2}
                        value={2}
                        onChange={() => {
                          setPartnerType(2);
                          setSelectedPartnerId("");
                          setSelectedPartnerName("");
                          // getPartnerWithTypeOptions('a', 2);
                          // partnerPromiseOptions('a');
                        }}
                    />
                    <Form.Check
                        className="radio-button"
                        label="End Customer"
                        type="radio"
                        name="partnerType"
                        checked={partnerType === 0}
                        value={0}
                        onChange={() => {
                          setPartnerType(0);
                          // getPartnerWithTypeOptions('a', 0);
                        }}
                    />
                  </Col>
                </Form.Group>

                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Pelanggan
                  </Form.Label>
                  <Col sm={4}>
                    {/*<Select*/}
                    {/*    onChange={onSelectedPartnerChange}*/}
                    {/*    options={partnerList}*/}
                    {/*/>*/}
                    <AsyncSelect cacheOptions defaultOptions loadOptions={partnerPromiseOptions} value={selectedPartnerId !== null ?{
                      value: selectedPartnerId,
                      label: selectedPartnerName,
                    } : null} onChange={onSelectedPartnerChange} key={partnerType} />
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>

            <br />

            <Card>
              <Button
                  style={{ height: 40, margin: 10, borderRadius: 5 }}
                  variant="outline-info"
                  type="button"
                  onClick={handleShow}
              >
                Tambah Produk
              </Button>

              <DataTable columns={columns} data={cartDetailList} noHeader />

              <br />

              {cartDetailList.length !== 0 && selectedPartnerId !== "" ? (
                  <>
                    <hr />
                    <Button
                        style={{ height: 40, margin: 10, borderRadius: 5 }}
                        variant="info"
                        type="button"
                        onClick={onSaveKeepClick}
                        disabled={isLoading}
                    >
                      {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Save Keep Barang"}
                    </Button>
                  </>
              ) : (
                  <></>
              )}
            </Card>
          </Grid>
        </div>

        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Product</Form.Label>
                {/*<Select*/}
                {/*  onChange={onSelectedProductChange}*/}
                {/*  options={productList}*/}
                {/*/>*/}
                <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                             onChange={onSelectedProductChange} />
              </Form.Group>
              <Form.Group>
                {cartDetailListTemp.length !== 0 ? (
                    <Form.Label>Variant</Form.Label>
                ) : (
                    <></>
                )}
              </Form.Group>
              <Row>
              {selectedProductId !== "" ? (
                  <>
                    {/*<Form.Group>*/}
                    {/*  <Form.Label>Variant</Form.Label>*/}
                    {/*  <Select*/}
                    {/*      onChange={onSelectedVariantChange}*/}
                    {/*      options={variationList}*/}
                    {/*      value={selectedVariant}*/}
                    {/*  />*/}
                    {/*</Form.Group>*/}
                    {cartDetailListTemp.map((item) => {
                      return (
                          <Col sm={6}>
                            <Card style={{ marginBottom: 12 }}>
                              <Card.Body style={{ paddingBottom: 4 }}>
                                <Row>
                                  <Col style={{ textAlign: "center", marginBottom: 8 }}>
                                    <b>{item.variantName}</b>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                      sm={4}
                                      onClick={() => {
                                        onSubQuantityClick(item);
                                      }}
                                  >
                                    <FaMinusCircle />
                                  </Col>
                                  <Col sm={4} style={{ textAlign: "center" }}>
                                    {item.qty}
                                  </Col>
                                  <Col
                                      sm={4}
                                      onClick={() => {
                                        onAddQuantityClick(item);
                                      }}
                                  >
                                    <FaPlusCircle />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ textAlign: "center" }}>
                                    <Form.Label>Stok : {item.stockQty}</Form.Label>
                                  </Col>
                                </Row>
                                {/* <br /> */}
                                <Row>
                                  <Form.Control
                                      as="textarea"
                                      placeholder="Note.."
                                      rows={1}
                                      onChange={(e: any) => onVariantNoteChange(e, item)}
                                      // value={note}
                                  />
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                      );
                    })}

                    {cartDetailListTemp.length !== 0 && (
                        <Col sm={12}>
                          <Card style={{ marginBottom: 12 }}>
                            <Card.Body style={{ paddingBottom: 4 }}>
                              <Row>
                                <Col style={{ textAlign: "center", marginBottom: 8 }}>
                                  <b>Serian</b>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col
                                    sm={4}
                                    onClick={() => {
                                      onSubSerianClick();
                                    }}
                                >
                                  <FaMinusCircle />
                                </Col>
                                <Col sm={4} style={{ textAlign: "center" }}>
                                  {serianQty}
                                </Col>
                                <Col
                                    sm={4}
                                    className={"text-right"}
                                    onClick={() => {
                                      onAddSerianClick();
                                    }}
                                >
                                  <FaPlusCircle />
                                </Col>
                              </Row>
                              <Row>
                                <Col style={{ textAlign: "center" }}>
                                  {
                                      isSerianContainZeroQty && (
                                          <Form.Label><small className="text-danger">Tidak dapat menambah serian, terdapat varian dengan kuantitas 0</small></Form.Label>
                                      )
                                  }
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                    )}

                    {/*  <Form.Group className="input-padding">*/}
                    {/*    <Form.Label>Stock</Form.Label>*/}

                    {/*    <Form.Control*/}
                    {/*      type="text"*/}
                    {/*      disabled*/}
                    {/*      value={selectedVariantStock}*/}
                    {/*    />*/}
                    {/*  </Form.Group>*/}

                    {/*  <Form.Group className="input-padding">*/}
                    {/*    <Form.Label>Qty</Form.Label>*/}

                    {/*    /!* <Form.Control*/}
                    {/*  type="number"*/}
                    {/*  onChange={onQtyChange}*/}
                    {/*  value={productQty}*/}
                    {/*/> *!/*/}

                    {/*    <NumberFormat*/}
                    {/*      placeholder="1"*/}
                    {/*      thousandSeparator={true}*/}
                    {/*      className="bootstrap-input"*/}
                    {/*      inputmode="numeric"*/}
                    {/*      value={selectedQty}*/}
                    {/*      onValueChange={({ floatValue }) => {*/}
                    {/*        let val = floatValue ?? 0;*/}
                    {/*        setSelectedQty(val);*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </Form.Group>*/}

                    {/*  <Form.Group className="input-padding">*/}
                    {/*    <Form.Label>Note</Form.Label>*/}

                    {/*    <Form.Control*/}
                    {/*      as="textarea"*/}
                    {/*      rows={3}*/}
                    {/*      placeholder="Note"*/}
                    {/*      onChange={onNoteChange}*/}
                    {/*      value={selectedProductNote}*/}
                    {/*    />*/}
                    {/*  </Form.Group>*/}

                  </>
              ) : (
                  <></>
              )}
              </Row>
              <div className="btn-footer-group float-right">
                <Button
                    variant="info"
                    style={{ width: 130, height: 45 }}
                    onClick={onSaveProductClick}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <AlertMessage
            {...alertProps}
            show={showAlert}
            onAlertCloseAction={onAlertClose}
        />
      </Container>
  );
};

export default KeepBarangForm;
