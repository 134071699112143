import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../constants'

export const getAllWarehouse = createAsyncThunk('Warehouse/GetList', async () => {
    try {
        const response = await axios.get(`${endpoint}/api/Warehouse/GetList`)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getReturList = createAsyncThunk('/Retur/GetListWithPaging', async (data: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Retur/GetListWithPaging`, data)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getReturDetail = createAsyncThunk('/Retur/GetSingleItem', async (id: any) => {
    try {
        const response = await axios.get(`${endpoint}/api/Retur/GetSingleItem?id=` + id)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const updateRetur = createAsyncThunk('/Retur/Update', async (data: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Retur/Update`, data)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const deleteRetur = createAsyncThunk('/Retur/Delete', async (id: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Retur/Delete?id=` + id)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getListProduct = createAsyncThunk('Product/GetList', async () => {
    try {
        const response = await axios.get(`${endpoint}/api/Product/GetList`)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getListVariationByProduct = createAsyncThunk('/Variation/GetListByProduct', async (id: any) => {
    try {
        const response = await axios.get(`${endpoint}/api/Variation/GetListByProduct?productId=` + id)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})