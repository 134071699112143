import axios from "axios";
import { endpoint } from "../../../constants";
import {
  Delivery,
  DeliveryReceiptFilter,
  PickUpDateBatchUpdateRequest,
} from "./receiptDelivery.model";

export default class ReceiptDeliveryApi {
  static getListWithPagination(model: DeliveryReceiptFilter) {
    return axios
      .post<any>(endpoint + "/api/DeliveryReceipt/GetListWithPaging", model)
      .then((res) => res.data.data);
  }

  static updateDeliveryReceipt(model: Delivery) {
    return axios
      .post<any>(endpoint + "/api/DeliveryReceipt/update", model)
      .then((res) => res.data.data);
  }

  static getUserByRole(roleId: string) {
    return axios
      .get<any>(endpoint + "/api/User/GetUserByRole?roleId=" + roleId)
      .then((res) => res.data.data);
  }

  static updatePickupDateBatch(model: PickUpDateBatchUpdateRequest) {
    return axios
      .post<any>(endpoint + "/api/DeliveryReceipt/UpdatePickupDate", model)
      .then((res) => res.data.data);
  }
}
