import axios from "axios";
import {
    ChangeStatusModel,
    CreateInternalUseModel, CreateInternalUseProductModel, CreateInternalUseWithDetailModel, FilterModel, UpdateInternalUseModel
} from "./internalUse.model";
import { endpoint } from "../../constants";

export default class InternalUseApi {

    static getInternalUse() {
        return axios
            .get<any>(endpoint + "​/api/InternalUse/GetList")
            .then((res) => res.data.data);
    }

    static getListWithPaging(model: FilterModel) {
        return axios
            .post<any>(
                endpoint + "/api/InternalUse/GetListWithPaging",
                model
            )
            .then((res) => res.data.data);
    }

    static createInternalUse(InternalUse: CreateInternalUseModel) {
        return axios
            .post<any>(
                endpoint + "/api/InternalUse/Create",
                InternalUse
            )
            .then((res) => res.data.data);
    }

    static createInternalUseWithDetail(InternalUse: CreateInternalUseWithDetailModel) {
        return axios
            .post<any>(
                endpoint + "/api/InternalUse/CreateInternalUseWithProduct",
                InternalUse
            )
            .then((res) => res.data.data);
    }

    static updateInternalUse(InternalUse: CreateInternalUseModel) {
        return axios
            .post<any>(
                endpoint + "/api/InternalUse/Update",
                InternalUse
            )
            .then((res) => res.data.data);
    }

    static changeStatusInternalUse(ChangeStatus: ChangeStatusModel) {
        return axios
            .post<any>(
                endpoint + "/api/InternalUse/ChangeStatus",
                ChangeStatus
            )
            .then((res) => res.data.data);
    }

    static getSingleItem(internalUseId: string) {
        return axios
            .get<any>(endpoint + "/api/InternalUse/GetSingleItem?id=" + internalUseId
            )
            .then((res) => res.data.data);
    }

    static deleteInternalUse(internalUseId: string) {
        return axios
            .post<any>(endpoint + "/api/InternalUse/Delete?Id=" + internalUseId)
            .then((res) => [
                { response: res.data.data },
            ]);
    }

    static generateInternalUseNumber() {
        return axios
            .get<any>(endpoint + "​/api/InternalUse/GenerateInternalUseNumber")
            .then((res) => res.data.data);
    }

    static getWarehouseList() {
        return axios
            .get<any>(endpoint + "​/api/Warehouse/GetList")
            .then((res) => res.data.data);
    }

    static createInternalUseProduct(createInternalUseProduct: CreateInternalUseProductModel) {
        return axios
            .post<any>(
                endpoint + "/api/InternalUse/CreateInternalUseProduct",
                createInternalUseProduct
            )
            .then((res) => res.data.data);
    }

    static deleteInternalUseProduct(internalUseProductId: string) {
        return axios
            .post<any>(
                endpoint + "/api/InternalUse/DeleteInternalUseProduct?Id=" + internalUseProductId,
            )
            .then((res) => res.data.data);
    }

    static getListInternalUseProductByInternalUse(internalUseId: string){
        return axios
            .get<any>(endpoint + "/api/InternalUse/GetListInternalUseProductByInternalUse?internalUseId=" + internalUseId)
            .then((res) => res.data.data);
    }

    static getProductListByWarehouse(warehouseId: string) {
        return axios
            .get<any>(endpoint + "/api/Product/GetListByWarehouse?warehouseId=" + warehouseId)
            .then((res) => res.data.data);
    }

    static getProductListByInternalUse(intenalUseId: string) {
        return axios
            .get<any>(endpoint + "/api/InternalUse/GetProductListByInternalUse?internalUseId=" + intenalUseId)
            .then((res) => res.data.data);
    }
}