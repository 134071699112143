import ReturApproval from '../components/ReturApproval'

const ReturApprovalPage = () => {
    return (
        <div>
            <ReturApproval />
        </div>
    )
}

export default ReturApprovalPage
