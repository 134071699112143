import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import VoucherTable from "../component/voucherTable";
import { getList, resetDiscountId, resetSingle } from "../voucher.reducer";

const VoucherIndex = () => {
  const dispatch = useDispatch();

  const modifiedDateState = useSelector(
    (state: RootState) => state.voucher.modifiedDate
  );

  useEffect(() => {
    dispatch(resetSingle());
    dispatch(resetDiscountId());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDateState]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <VoucherTable />
        </Col>
      </Row>
    </Container>
  );
};

export default VoucherIndex;
