import React, { ChangeEvent, useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { DatePicker } from "antd";
import { RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { PartnerOptionModel, PaymentInModel } from "../paymentIn.model";
import Select from "react-select";
import {
  getListPartner,
  getListPaymentMethod,
} from "../../../../global/global.reducer";
import moment from "moment";
import {
  createData,
  getSingleItem,
  resetSingle,
  resetStatus,
  updateData,
} from "../paymentIn.reducer";
import AlertMessage from "../../../../components/alertMessage";
import DeleteMessage from "../../../../components/DeleteMessage";
import NumberFormat from "react-number-format";

const PaymentInForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { id } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);

  const paymentMethodListState = useSelector(
    (state: RootState) => state.global.listPaymentMethod ?? []
  );

  const partnerListState = useSelector(
    (state: RootState) => state.global.listPartner ?? []
  );

  const paymentInStatusState = useSelector(
    (state: RootState) => state.paymentIn.status ?? ""
  );

  const paymentInDataState = useSelector(
    (state: RootState) => state.paymentIn.single ?? ({} as PaymentInModel)
  );

  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);
  const [partnerType, setPartnerType] = useState(0);

  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState(0);
  const [note, setNote] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onSelectedPartnerChange = (data: any) => {
    setSelectedPartnerId(data.value);
  };

  const onDateChange = (value: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setPaymentDate(date);
    }
  };

  const onPaymentMethodChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethodId(e.target.value);
  };

  const onSave = () => {
    if (!isEdit) {
      dispatch(
        createData({
          paymentDate: paymentDate.toISOString(),
          customerId: selectedPartnerId,
          paymentMethodId: selectedPaymentMethodId,
          paymentAmount: totalAmount,
          status: selectedDocumentStatus,
          note: note,
        })
      );
    } else {
      dispatch(
        updateData({
          id: id,
          paymentDate: paymentDate.toISOString(),
          customerId: selectedPartnerId,
          paymentMethodId: selectedPaymentMethodId,
          paymentAmount: totalAmount,
          status: selectedDocumentStatus,
          note: note,
        })
      );
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);

    if (!paymentInStatusState.includes("failed")) {
      setSelectedDocumentStatus(0);
      setSelectedPartnerId("");
      setNote("");
      setTotalAmount(0);
      dispatch(resetStatus());
      dispatch(resetSingle());
      setIsEdit(false);
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(getListPartner());
    dispatch(getListPaymentMethod());
    dispatch(resetSingle());
    setSelectedDocumentStatus(0);
    setSelectedPartnerId("");
    setNote("");
    setTotalAmount(0);
  }, []);

  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      setIsEdit(true);
      dispatch(getSingleItem(id));
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      paymentInDataState &&
      Object.keys(paymentInDataState).length !== 0 &&
      paymentInDataState.constructor === Object
    ) {
      console.log(paymentInDataState);
      setSelectedPartnerId(paymentInDataState.customerId);
      setSelectedPaymentMethodId(paymentInDataState.paymentMethodId);
      setSelectedDocumentStatus(paymentInDataState.status);
      setNote(paymentInDataState.note);
      setTotalAmount(paymentInDataState.paymentAmount);
      setPaymentDate(new Date(paymentInDataState.paymentDate));

      if (paymentInDataState.partnerType !== undefined) {
        setPartnerType(paymentInDataState.partnerType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInDataState]);

  useEffect(() => {
    if (partnerListState.length !== 0) {
      setPartnerList([]);

      partnerListState.map((item) => {
        // var partnerTypeString =
        //   item.type == 0
        //     ? "Customer"
        //     : item.type == 1
        //     ? "Stockist"
        //     : "Reseller";

        if (item.type === partnerType) {
          setPartnerList((prevState) => [
            ...prevState,
            {
              value: item.id,
              label: item.fullName + " - " + item.email,
              type: item.type,
            },
          ]);
        }
      });
    }
  }, [partnerListState, partnerType]);

  useEffect(() => {
    if (paymentInStatusState == "success-create") {
      setAlertProps({
        variant: "success",
        title: "Berhasil membuat data Deposit Baru",
        content: " ",
      });
      setShowAlert(true);
    } else if (paymentInStatusState == "success-update") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "Berhasil update data Deposit",
      });
      setShowAlert(true);
    } else if (paymentInStatusState == "failed-create") {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "Gagal menambah data Deposit",
      });
      setShowAlert(true);
    }
  }, [paymentInStatusState]);

  return (
    <div>
      <Grid textAlign="center">
        <Form.Group>
          <Form.Label>
            <h4>{isEdit ? "Deposit Data" : "New Deposit"}</h4>
          </Form.Label>
          <div className="btn-footer-group float-right">
            <Link to={{ pathname: `/payment-in/index` }}>
              <Button className="btn-cancel" variant="light">
                Cancel
              </Button>
              {"  "}
            </Link>

            <Button
              style={{ width: 115, height: 46 }}
              form="inventoryMove"
              className="btn"
              variant="info"
              type="submit"
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </Form.Group>

        <br />

        <Card>
          <Card.Body>
            {isEdit ? (
              <>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={3}>
                    Payment No
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      value={paymentInDataState.paymentNumber}
                      disabled
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <hr />
              </>
            ) : (
              <></>
            )}

            <Form.Group as={Row} className="input-padding m-1">
              <Form.Label column sm={3}>
                Tanggal
              </Form.Label>
              <Col sm={4}>
                {!isEdit ? (
                  <>
                    <DatePicker
                      defaultValue={moment(paymentDate, "DD-MM-YYYY")}
                      style={{ width: "100%" }}
                      // showTime={{ format: "HH:mm" }}
                      format="DD-MM-YYYY"
                      onChange={onDateChange}
                    />
                  </>
                ) : (
                  <>
                    <DatePicker
                      defaultValue={moment(paymentDate, "DD-MM-YYYY")}
                      style={{ width: "100%" }}
                      // showTime={{ format: "HH:mm" }}
                      format="DD-MM-YYYY"
                      disabled
                    />
                  </>
                )}
              </Col>
            </Form.Group>
            <hr />

            <Form.Group as={Row} className="input-padding m-1">
              <Form.Label column sm={3}>
                Tipe Partner
              </Form.Label>
              <Col sm={4}>
                {!isEdit ? (
                  <>
                    <Form.Check
                      className="radio-button"
                      label="Stockist"
                      type="radio"
                      name="partnerType"
                      checked={partnerType === 1}
                      value={1}
                      onChange={() => setPartnerType(1)}
                    />
                    <Form.Check
                      className="radio-button"
                      label="Reseller"
                      type="radio"
                      name="partnerType"
                      checked={partnerType === 2}
                      value={2}
                      onChange={() => setPartnerType(2)}
                    />
                    <Form.Check
                      className="radio-button"
                      label="End Customer"
                      type="radio"
                      name="partnerType"
                      checked={partnerType === 0}
                      value={0}
                      onChange={() => setPartnerType(0)}
                    />
                  </>
                ) : (
                  <>
                    <Form.Check
                      className="radio-button"
                      label="Stockist"
                      type="radio"
                      name="partnerType"
                      checked={partnerType === 1}
                      value={1}
                      disabled
                    />
                    <Form.Check
                      className="radio-button"
                      label="Reseller"
                      type="radio"
                      name="partnerType"
                      checked={partnerType === 2}
                      value={2}
                      disabled
                    />
                    <Form.Check
                      className="radio-button"
                      label="End Customer"
                      type="radio"
                      name="partnerType"
                      checked={partnerType === 0}
                      value={0}
                      disabled
                    />
                  </>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="input-padding m-1">
              <Form.Label column sm={3}>
                Partner
              </Form.Label>
              <Col sm={4}>
                {!isEdit ? (
                  <>
                    <Select
                      // defaultValue={selectedPartnerId}
                      onChange={onSelectedPartnerChange}
                      options={partnerList}
                    />
                  </>
                ) : (
                  <>
                    <Form.Control
                      as="select"
                      value={selectedPartnerId}
                      disabled
                    >
                      {partnerListState.map((Item) => {
                        return (
                          <option value={Item.id}>
                            {Item.fullName} - {Item.email}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </>
                )}
              </Col>
            </Form.Group>

            <hr />
            <Form.Group as={Row} className="input-padding m-1">
              <Form.Label column sm={3}>
                Bank
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  onChange={onPaymentMethodChange}
                  value={selectedPaymentMethodId}
                >
                  <option value="">select</option>
                  {paymentMethodListState.map((Item) => {
                    return <option value={Item.id}>{Item.name}</option>;
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding m-1">
              <Form.Label column sm={3}>
                Total Pembayaran
              </Form.Label>
              <Col sm={4}>
                {/* <Form.Control
                  type="number"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(parseInt(e.target.value))}
                ></Form.Control> */}
                <NumberFormat
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  className="form-control"
                  inputMode="numeric"
                  prefix="Rp. "
                  value={totalAmount}
                  onValueChange={({ floatValue }) => {
                    let val = floatValue ?? 0;
                    setTotalAmount(val);
                  }}
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding m-1">
              <Form.Label column sm={3}>
                Note
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Note"
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding m-1">
              <Form.Label column sm={3}>
                Status
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setSelectedDocumentStatus(parseInt(e.target.value));
                  }}
                  value={selectedDocumentStatus}
                >
                  {/* <option value="">Select</option> */}
                  <option value="0">DRAFT</option>
                  <option value="1">COMPLETE</option>
                  <option value="2">VOID</option>
                  <option value="3">CLOSE</option>
                  <option value="4">INVALID</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>

        <AlertMessage
          {...alertProps}
          show={showAlert}
          onAlertCloseAction={onAlertClose}
        />
      </Grid>
    </div>
  );
};

export default PaymentInForm;
