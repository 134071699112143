export interface StockistDataPesananRequest
{
    partnerId: string | null;
    groupId: string | null;
    skip: number;
    take: number;
}

export interface PartnerByTypeRequest {
    type: PartnerTypeEnum;
}

export enum PartnerTypeEnum
{
    Customer,
    Stockist,
    Reseller,
    Admin,
    AdminStockist,
    StockistReseller=101
}

export interface PartnerModel {
    id: string;
    fullName: string;
}

export interface Option { value: string; label: string; }

export interface StockistOrderDataReportModel {
    orderDate: string;
    statusOrder: number;
    soNumber: string;
    expeditionName: string | null;
    type: number;
    transactionStatus: number;
    subTotal: number;
    paymentStatus: number;
    senderName: string | null;
    customerName: string;
    groupId: string | null;
    groupName: string;
    variationId: string;
    partnerId: string;
    variationName: string;
    productId: string;
    productName: string;
    productCode: string;
    note: string;
    quantity: number;
    isActive: boolean;
    createdDate: string;
}