import "../../pesananDalamProses.style.css";
import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { KeepBelumLunasModel } from "../keepBelumLunas.model";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../../../constants";
import { Link, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import edit from "./../../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import axios from "axios";

const KeepBelumLunasDetail = () => {
  let { id } = useParams<{ id: string }>();

  let { customerName } = useParams<{ customerName: string }>();

  let history = useHistory();

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [list, setList] = useState<KeepBelumLunasModel[]>([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [listTotal, setListTotal] = useState<number>(0);
  const [listPerPage, setListPerPage] = useState<number>(10);

  const [listHistory, setListHistory] = useState<KeepBelumLunasModel[]>([]);
  const [isListHistoryLoading, setIsListHistoryLoading] = useState(false);
  const [listHistoryTotal, setListHistoryTotal] = useState<number>(0);
  const [listHistoryPerPage, setListHistoryPerPage] = useState<number>(10);

  const columns = [
    {
      name: "Kode Produk",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "Nama Produk",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Tanggal Pesanan",
      selector: (d: { orderDate: string }) => (
        <span>{d.orderDate.substr(0, 10)}</span>
      ),
      sortable: true,
    },
    {
      name: "Varian",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "HARGA",
      selector: (d: { price: number }) => (
        <NumberFormat
          value={d.price}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "SUBTOTAL",
      selector: (d: { subTotal: number }) => (
        <NumberFormat
          value={d.subTotal}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "CATATAN",
      selector: "note",
      sortable: true,
    },
  ];
  // end of datatable components

  const columns2 = [
    {
      name: "Kode Produk",
      selector: "productId",
      sortable: true,
    },
    {
      name: "Nama Produk",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Tanggal Pesanan",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "Varian",
      selector: "productName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "HARGA",
      selector: "varian",
      sortable: true,
    },
    {
      name: "SUBTOTAL",
      selector: (d: { subTotal: number }) => (
        <NumberFormat
          value={d.subTotal}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "CATATAN",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { salesOrderId: any }) => (
        <>
          <Link
            to={{
              pathname: `/order-detail/${d.salesOrderId}`,
            }}>
            <img src={edit}></img>
          </Link>
        </>
      ),
    },
  ];
  // end of datatable components

  const SOHistoryColumns = [
    {
      name: "SO NUMBER",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "Tanggal Pesanan",
      selector: (d: { orderDate: string }) => (
        <span>{d.orderDate.substr(0, 10)}</span>
      ),
      sortable: true,
    },
    {
      name: "SUBTOTAL",
      selector: (d: { subTotal: number }) => (
        <NumberFormat
          value={d.subTotal}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "DISCOUNT",
      selector: (d: { discount: number }) => (
        <NumberFormat
          value={d.discount}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "TAX",
      selector: (d: { totalTax: number }) => (
        <NumberFormat
          value={d.totalTax}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "GRAND TOTAL",
      selector: (d: { grandTotal: number }) => (
        <NumberFormat
          value={d.grandTotal}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { salesOrderId: any }) => (
        <>
          {/* <Link
            to={{
              pathname: `/order-detail/${d.salesOrderId}`,
            }}>
            <img src={edit}></img>
          </Link> */}
          <Link
            to={{
              pathname: `/customer-so-detail/${d.salesOrderId}`,
            }}>
            <img src={edit}></img>
          </Link>
        </>
      ),
    },

  ]

  const getListUnpaidByPartner = (id: string, skip: number, take: number) => {
    setIsListLoading(true);
    axios
        .get<any>(
            endpoint + "/api/SalesOrder/GetListUnpaidByPartner",
            {
              params: {
                partnerId: id,
                skip: skip,
                take: take,
              },
            }
        )
        .then((res) => {
          setList(res.data.data.list)
          setListTotal(res.data.data.total)
          setIsListLoading(false)
        }).catch((err) => {
      setIsListLoading(false)
    });
  }

  const getListSalesOrderByPartner = (id: string, skip: number, take: number) => {
    setIsListHistoryLoading(true);
    axios
        .get<any>(
            endpoint + "/api/SalesOrder/GetListSalesOrderByPartner",
            {
              params: {
                partnerId: id,
                skip: skip,
                take: take,
              },
            }
        )
        .then((res) => {
          setListHistory(res.data.data.list)
          setListHistoryTotal(res.data.data.total)
          setIsListHistoryLoading(false)
        }).catch((err) => {
      setIsListHistoryLoading(false)
    });
  }

  useEffect(() => {

    getListUnpaidByPartner(id, 0, 10);
    getListSalesOrderByPartner(id, 0, 10);
    // axios
    //   .get<any>(
    //     endpoint + "/api/SalesOrder/GetListUnpaidByPartner?partnerId=" + id
    //   )
    //   .then((res) => {
    //     setList(res.data.data)
    //     setListTotal(res.data.total)
    //     setIsListLoading(false)
    //   }).catch((err) => {
    //     setIsListLoading(false)
    // });

    // axios
    //   .get<any>(endpoint + "​/api/SalesOrder/GetListByPartner?partnerId=" + id)
    //   .then((res) => setListHistory(res.data.data));

    // axios
    //   .get<any>(endpoint + "/api/SalesOrder/GetListSalesOrderByPartner?partnerId=" + id)
    //   .then((res) => setListHistory(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickTambah = () => {
    history.replace("/sales-order/index");
  };

  const handleListPerRowsChange = (newPerPage: number, page: number) => {
    setIsListLoading(true);
    setListPerPage(newPerPage);
    getListUnpaidByPartner(id, page, newPerPage);
  };

  const handleListPageChange = (page: any) => {
    // fetchData((page - 1) * perPage, perPage);
    getListUnpaidByPartner(id, (page - 1) * listPerPage, listPerPage);
  };

  const handleListHistoryPerRowsChange = (newPerPage: number, page: number) => {
    setIsListHistoryLoading(true);
    setListHistoryPerPage(newPerPage);
    getListSalesOrderByPartner(id, page, newPerPage);
  };

  const handleListHistoryPageChange = (page: any) => {
    // fetchData((page - 1) * perPage, perPage);
    getListSalesOrderByPartner(id, (page - 1) * listHistoryPerPage, listHistoryPerPage);
  };

  return (
    <Container className="content-body" fluid>
      <div onClick={() => history.goBack()} className="form-title">
        <FaChevronLeft />
        {"   "}
        Pesanan Keep (Belum Lunas)
      </div>
      <Card>
        <br />
        <h5 style={{ paddingLeft: 20 }}>
          Semua Pesanan Keep (Belum Lunas) dari Customer ID{" "}
          {id.slice(0, 5).toUpperCase()} ({customerName})
        </h5>
        <hr />
        <Row>
          <Col md={6}>
            {/* <Button className="button-export" variant="primary">
              Nota keep
            </Button>{" "}
            <Button className="button-export" variant="success">
              Nota Dropship
            </Button>{" "} */}
            <Button
              className="button-export"
              variant="outline-info"
              onClick={onClickTambah}>
              + Tambah
            </Button>{" "}
          </Col>
          {/* <Col md={{ span: 4, offset: 2 }}>
            <Button
              style={{ float: "right", marginRight: 20 }}
              className="button-export"
              variant="outline-info">
              Export to excel
            </Button>{" "}
            <Button
              style={{ float: "right" }}
              className="button-export"
              variant="outline-info">
              Print
            </Button>{" "}
          </Col> */}
        </Row>
        <hr />
        <DataTable
          columns={columns}
          data={list}
          // pagination
          // paginationResetDefaultPage={resetPaginationToggle}
          // noHeader
          customStyles={TableCustomStyles}
          pagination
          paginationServer
          progressPending={isListLoading}
          paginationResetDefaultPage={resetPaginationToggle}
          noHeader
          paginationTotalRows={listTotal}
          onChangeRowsPerPage={handleListPerRowsChange}
          onChangePage={handleListPageChange}
          paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
        />


      </Card>
      <br />
      <Card>
        <Card.Body>
          <h5>Data Pesanan</h5>
          <hr />
          <DataTable
            columns={SOHistoryColumns}
            data={listHistory}
            // pagination
            // paginationResetDefaultPage={resetPaginationToggle}
            // noHeader
            customStyles={TableCustomStyles}
            pagination
            paginationServer
            progressPending={isListHistoryLoading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={listHistoryTotal}
            onChangeRowsPerPage={handleListHistoryPerRowsChange}
            onChangePage={handleListHistoryPageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default KeepBelumLunasDetail;
