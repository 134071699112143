import firebase from "firebase/compat/app";
import { initializeApp } from 'firebase/app';
import "firebase/compat/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// live server
const firebaseConfig = {
  apiKey: "AIzaSyDoE5TzrcFP1awvxjPpYQmUJaJaLXrcOQ4",
  authDomain: "emory-cc186.firebaseapp.com",
  projectId: "emory-cc186",
  storageBucket: "emory-cc186.appspot.com",
  messagingSenderId: "684780913420",
  appId: "1:684780913420:web:2ed7e5124ebb8a8cc2ff29",
  measurementId: "G-MZXJE0VGNS"
};

// firebase init
const base = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase;

// const firebaseApp = initializeApp(firebaseConfig); 

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
// const messaging = getMessaging(firebaseApp);

// export const fetchToken = (setTokenFound:any) => {
//   return getToken(messaging, {vapidKey: 'BBMl1j3MvX4gMeHWL8do9ZlMEduouD_o8ItC-PgA1QRZTfX93xeMPsPfW51ddivu2tqYO3Ik5UOvTYCcfU1rVXg'}).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       setTokenFound(true);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false);
//       // shows on the UI that permission is required 
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });
// }

// const _messaging = firebase.messaging();

// _messaging.onBackgroundMessage((payload)=>{
//   console.log(payload)
// })

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload:any) => {

//       console.log(payload)

//       resolve(payload);
//     });
// });

// export const onBackgroundMessageListener = () => 
//   new Promise((resolve:any) => {
//     _messaging.onBackgroundMessage((payload:any)=>{
//         console.log(payload)
//         resolve(payload)
//     }
//     )
//   })






export default base.firestore();