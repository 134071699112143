  import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  Tabs,
  Tab,
  TabContent,
  Modal,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import "react-phone-number-input/style.css";
import { endpoint } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  ResellerModel,
  PartnerStore,
  PartnerRegisterModel,
  RejectionModel,
} from "../registerReseller.model";
import PhoneInput from "react-phone-number-input";
import AlertMessage from "../../../components/alertMessage";
import DataTable from "react-data-table-component";
import edit from "./../../../assets/vector/edit.png";
import hapus from "./../../../assets/vector/delete.png";
import upload from "./../../../assets/vector/upload.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { convertBase64 } from "../../../components/Library/Base64";
import {
  registerReseller,
  getCity,
  getDistrict,
  getProvince,
  uploadPhoto,
  getStoreTypeEnumList,
  getStoreProvince,
  getStoreCity,
  getStoreDistrict,
  uploadPhotoSelfKtp,
  uploadPhotoNpwp,
  resetStatus,
  resetSingle,
  getSocialMediaEnumList,
  approveReseller,
  rejectReseller,
  updatePartner,
  updatePartnerStore,
  deletePartnerStore,
  getPartnerStoreListByPartner,
  resetStoreAddress,
  uploadPhotoSelf,
  createPartnerStore,
} from "../registerReseller.reducer";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import Dropzone from "react-dropzone";
import { listeners } from "process";
import DeleteMessage from "../../../components/DeleteMessage";
import { deleteAdminStockist } from "../../adminStockist/adminStockist.reducer";
import { NumberFormatProps } from "react-number-format";

const RegisterResellerCreate = ({
  data = {} as PartnerRegisterModel,
  isEdit = false,
  ...props
}) => {
  let { id } = useParams<{ id: string }>();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  //partner
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState(0);
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [cityId, setCityId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [status, setStatus] = useState(0);
  const [isActive, setIsActive] = useState(true)


  //user
  const [userName, setUserName] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //attachment
  const [imageSelf, setImageSelf] = useState("");
  const [imageKtp, setImageKtp] = useState("");
  const [imageSelfKtp, setImageSelfKtp] = useState("");
  const [imageNpwp, setImageNpwp] = useState("");

  //store
  const [storeType, setStoreType] = useState(0);
  const [storeName, setStoreName] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [storeProvinceId, setStoreProvinceId] = useState("");
  const [storeCityId, setStoreCityId] = useState("");
  const [storeDistrictId, setStoreDistrictId] = useState("");
  const [storePostalCode, setStorePostalCode] = useState("");
  const [storeSocialMedia, setStoreSocialMedia] = useState("");
  const [storeSocialMediaName, setStoreSocialMediaName] = useState("");
  const [storeLink, setStoreLink] = useState("");

  //list store
  const [storeList, setStoreList] = useState<PartnerStore[]>([]);
  const [offlineStoreList, setOfflineStoreList] = useState<PartnerStore[]>([]);
  const [onlineStoreList, setOnlineStoreList] = useState<PartnerStore[]>([]);

  const [selectedStoreIndex, setSelectedStoreIndex] = useState(0);
  const [selectedOnlineStoreIndex, setSelectedOnlineStoreIndex] = useState(0);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [selectedStoreType, setSelectedStoreType] = useState(0);
  const [isEditingStore, setIsEditingStore] = useState(false);

  //Approval
  const [approvalNote, setApprovalNote] = useState("");
  const [showModal, setShowModal] = useState(false);

  //utils
  const [passwordShown, setPasswordShown] = useState(false);
  const [fileFormat, setFileFormat] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleterAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  // const offlineStoreList = storeList.filter((item) => item.type === 0);
  // const socialMediaStoreList = storeList.filter((item) => item.type === 1);

  const provinceState = useSelector(
    (state: RootState) => state.registerReseller.listProvince ?? []
  );

  const cityState = useSelector(
    (state: RootState) => state.registerReseller.listCity ?? []
  );

  const districtState = useSelector(
    (state: RootState) => state.registerReseller.listDistrict ?? []
  );

  const imageKtpPathState = useSelector(
    (state: RootState) => state.registerReseller.imageKtpPath ?? ""
  );

  const imageSelfPathState = useSelector(
    (state: RootState) => state.registerReseller.imageSelfPath ?? ""
  );

  const imageSelfKtpPathState = useSelector(
    (state: RootState) => state.registerReseller.imageSelfKtpPath ?? ""
  );

  const imageNpwpPathState = useSelector(
    (state: RootState) => state.registerReseller.imageNpwpPath ?? ""
  );

  const storeProvinceState = useSelector(
    (state: RootState) => state.registerReseller.listStoreProvince ?? []
  );

  const storeCityState = useSelector(
    (state: RootState) => state.registerReseller.listStoreCity ?? []
  );

  const storeDistrictState = useSelector(
    (state: RootState) => state.registerReseller.listStoreDistrict ?? []
  );

  const socialMediaEnumState = useSelector(
    (state: RootState) => state.registerReseller.socialMediaList ?? []
  );

  const statusState = useSelector(
    (state: RootState) => state.registerReseller.status ?? ""
  );

  const storeListState = useSelector(
    (state: RootState) => state.registerReseller.storeList ?? []
  );

  const onFullNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const onProvinceChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setProvinceId(e.target.value);
    dispatch(getCity(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCityId(e.target.value);
    dispatch(getDistrict(e.target.value));
  };

  const onDistrictChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDistrictId(e.target.value);
  };

  const onPostalcodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPostalCode(e.target.value);
  };

  const onUserNameChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserName(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  const onPincodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPinCode(e.target.value);
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordInvisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onStoreNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreName(e.target.value);
  };

  const onStoreSocialMediaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreSocialMedia(e.target.value);
  };

  const onStoreSocialMediaNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreSocialMediaName(e.target.value);
  };

  const onStoreAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreAddress(e.target.value);
  };

  const onStoreProvinceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreProvinceId(e.target.value);
    dispatch(getStoreCity(e.target.value));
  };

  const onStoreCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreCityId(e.target.value);
    dispatch(getStoreDistrict(e.target.value));
  };

  const onStoreDistrictChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreDistrictId(e.target.value);
  };

  const onStorePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStorePostalCode(e.target.value);
  };

  const onStoreTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreType(Number(e.target.value));
    if (e.target.value === "1") {
      setStoreProvinceId("00000000-0000-0000-0000-000000000000&");
      setStoreDistrictId("00000000-0000-0000-0000-000000000000&");
      setStoreCityId("00000000-0000-0000-0000-000000000000&");
    }
  };

  const onStoreLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreLink(e.target.value);
  };

  const onApprovalNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setApprovalNote(e.target.value);
  };

  const onCancelClick = () => {
    dispatch(resetStatus);
    dispatch(resetSingle);
    setStoreList([]);
    history.replace("/register-reseller/index");
  };

  const onStoreDeleteClick = (index: number, id: string) => {
    if (!isEdit) {
      const list = [...storeList];
      list.splice(index, 1);
      setStoreList(list);
    } else {
      dispatch(deletePartnerStore(id));
    }
  };

  const onDeleteDataStore = () => {
    if (!isEdit) {
      if (selectedStoreType === 0) {
        const list = [...offlineStoreList];
        list.splice(selectedStoreIndex, 1);
        setOfflineStoreList(list);
      } else {
        const list = [...onlineStoreList];
        list.splice(selectedStoreIndex, 1);
        setOnlineStoreList(list);
      }
    } else {
      dispatch(deletePartnerStore(selectedStoreId));
    }
    setShowDeleteAlert(false);
  };

  const onStoreEditClick = (index: number, storeId: string) => {
    dispatch(resetStoreAddress());

    setIsEditingStore(true);
    setSelectedStoreIndex(index);
    setSelectedStoreId(storeId);

    // const list = [...storeList];
    const list = [...offlineStoreList];
    const selectedData = list[selectedStoreIndex];

    setStoreType(selectedData.type);
    setStoreName(selectedData.name);
    setStoreAddress(selectedData.address);
    setStoreProvinceId(selectedData.province + "&" + selectedData.provinceName);
    setStoreDistrictId(selectedData.district + "&" + selectedData.districtName);
    setStoreCityId(selectedData.city + "&" + selectedData.cityName);
    setStorePostalCode(selectedData.postCode);

    dispatch(getStoreProvince());
  };

  const onMediaStoreEditClick = (index: number, storeId: string) => {
    setIsEditingStore(true);
    setSelectedOnlineStoreIndex(index);
    setSelectedStoreId(storeId);

    const list = [...onlineStoreList];
    const selectedData = list[selectedOnlineStoreIndex];

    setStoreType(selectedData.type);
    setStoreName(selectedData.name);
    setStoreLink(selectedData.link);
    setStoreSocialMedia(
      selectedData.socialMedia.toString() + "&" + selectedData.socialMediaName
    );
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const resetStoreForm = () => {
    setIsEditingStore(false);
    setStoreType(0);
    setStoreName("");
    setStoreLink("");
    setStoreAddress("");
    setStoreProvinceId("");
    setStoreDistrictId("");
    setStoreCityId("");
    setStorePostalCode("");
  };

  const onSave = () => {
    if (!isEdit) {
      dispatch(
        registerReseller({
          user: {
            name: fullName,
            userName,
            email,
            pinCode:"12345",
            password,
            confirmPassword,
            note: "",
          },
          partner: {
            id: "00000000-0000-0000-0000-000000000000",
            type: 2,
            fullName,
            email,
            phoneNumber,
            gender,
            address,
            postalCode,
            selfPhoto: imageSelfPathState.toString(),
            ktpPhoto: imageKtpPathState.toString(),
            ktpSelfPhoto: imageSelfKtpPathState.toString(),
            npwpPhoto: imageNpwpPathState.toString(),
            userId: "",
            provinceId,
            cityId,
            districtId,
            countryId: "76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7",
            status: status,
            isActive: isActive,
          },
          partnerStore: storeList,
          offlineStore: offlineStoreList,
          socialMedia: onlineStoreList,
        })
      );
    } else {
      dispatch(
        updatePartner({
          id: data.partner.id,
          type: 2,
          fullName,
          email,
          phoneNumber,
          gender,
          address,
          postalCode,
          selfPhoto: imageSelfPathState.toString() === "" ? data.partner.selfPhoto : imageSelfPathState.toString(),
          ktpPhoto: imageKtpPathState.toString() === "" ? data.partner.ktpPhoto : imageKtpPathState.toString(),
          ktpSelfPhoto: imageSelfKtpPathState.toString() === "" ? data.partner.ktpSelfPhoto : imageSelfKtpPathState.toString(),
          npwpPhoto: imageNpwpPathState.toString() === "" ? data.partner.npwpPhoto : imageNpwpPathState.toString(),
          userId: "",
          provinceId,
          cityId,
          districtId,
          countryId: "76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7",
          status: status,
          isActive: isActive,
        })
      );
    }
  };

  const onAddStoreClick = () => {
    let storeIdTemp = "00000000-0000-0000-0000-000000000000";
    let partnerId = "00000000-0000-0000-0000-000000000000";

    if (id !== undefined) {
      partnerId = data.partner.id;
    }

    if (isEdit) {
      storeIdTemp = selectedStoreId;
    }

    let storeTemp = {
      id: storeIdTemp,
      type: storeType,
      name: storeName,
      // socialMedia: parseInt(storeSocialMedia.split("&")[0]),
      socialMedia: storeSocialMediaName,
      // socialMediaName: storeSocialMedia.split("&")[1],
      socialMediaName: storeSocialMediaName,
      link: storeLink,
      address: storeAddress,
      province: storeProvinceId.split("&")[0],
      city: storeCityId.split("&")[0],
      district: storeDistrictId.split("&")[0],
      postCode: storePostalCode,
      provinceName: storeProvinceId.split("&")[1],
      cityName: storeCityId.split("&")[1],
      districtName: storeDistrictId.split("&")[1],
      partnerId: partnerId,
    } as PartnerStore;

    if (!isEdit) {
      if (isEditingStore) {
        storeList[selectedStoreIndex] = storeTemp;
      } else {
        setStoreList([...storeList, storeTemp]);
        if (storeTemp.type === 0) {
          setOfflineStoreList([...offlineStoreList, storeTemp]);
        } else {
          setOnlineStoreList([...onlineStoreList, storeTemp]);
        }
      }
    } else {
      if (isEditingStore) {
        dispatch(updatePartnerStore(storeTemp));
      } else {
        dispatch(createPartnerStore(storeTemp));
      }
    }

    resetStoreForm();
  };

  const onCancelEditClick = () => {
    resetStoreForm();
    setIsEditingStore(false);
  };

  const onApproveClick = () => {
    dispatch(approveReseller(id));
  };

  const onRejectClick = () => {
    dispatch(
      rejectReseller({
        id: id,
        reason: approvalNote,
      } as RejectionModel)
    );
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (
      statusState === "success" ||
      statusState === "success-reject" ||
      statusState === "success-approve"
    ) {
      dispatch(resetStatus());
      dispatch(resetSingle());
      history.replace("/register-reseller/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    dispatch(resetStatus());
  };

  const handleImageSelf = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImageSelf(result);
      });
    } else {
      console.log("error");
    }
  };

  const handleImageKtp = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImageKtp(result);
      });
    } else {
      console.log("error");
    }
  };

  const handleImageSelfKtp = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImageSelfKtp(result);
      });
    } else {
      console.log("error");
    }
  };

  const handleImageNpwp = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImageNpwp(result);
      });
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    dispatch(getProvince());
    dispatch(getStoreProvince());
    dispatch(getStoreTypeEnumList());
    dispatch(getSocialMediaEnumList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (imageSelf != null && imageSelf != "") {
      dispatch(
        uploadPhotoSelf({
          file_name: email + "." + fileFormat,
          base64url: imageSelf.split(",")[1],
          imagepath: "partnerPhoto",
          location: "partnerPhoto/Self",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSelf, fileFormat]);

  useEffect(() => {
    if (imageKtp != null && imageKtp != "") {
      dispatch(
        uploadPhoto({
          file_name: email + "." + fileFormat,
          base64url: imageKtp.split(",")[1],
          imagepath: "partnerPhoto",
          location: "partnerPhoto/KTP",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageKtp, fileFormat]);

  useEffect(() => {
    if (imageSelfKtp != null && imageSelfKtp != "") {
      dispatch(
        uploadPhotoSelfKtp({
          file_name: email + "." + fileFormat,
          base64url: imageSelfKtp.split(",")[1],
          imagepath: "partnerPhoto",
          location: "partnerPhoto/SelfKtp",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSelfKtp, fileFormat]);

  useEffect(() => {
    if (imageNpwp != null && imageNpwp != "") {
      dispatch(
        uploadPhotoNpwp({
          file_name: email + "." + fileFormat,
          base64url: imageNpwp.split(",")[1],
          imagepath: "partnerPhoto",
          location: "partnerPhoto/NPWP",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageNpwp, fileFormat]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      console.log(data);
      setIsActive(data.partner.isActive)
      setStatus(data.partner.status);
      setFullName(data.partner.fullName);
      setEmail(data.partner.email);
      setPhoneNumber(data.partner.phoneNumber);
      setGender(data.partner.gender);
      setAddress(data.partner.address);
      setProvinceId(data.partner.provinceId);
      setCityId(data.partner.cityId);
      setDistrictId(data.partner.districtId);
      setPostalCode(data.partner.postalCode);

      if (data.partner.provinceId !== null) {
        dispatch(getCity(data.partner.provinceId));
      }
      if (data.partner.cityId !== null) {
        dispatch(getDistrict(data.partner.cityId));
      }
      // setUserName(data.user.userName);
      //setPinCode(data.user.pinCode);

      setStoreList(data.partnerStore ?? []);
      setStoreList((prevState) => [...data.offlineStore ?? [...prevState]]);
      setStoreList((prevState) =>[...data.socialMedia ?? [...prevState]]);

      setOfflineStoreList(data.offlineStore ?? []);
      setOnlineStoreList(data.socialMedia ?? []);
    } else {
    }
  }, [data]);

  useEffect(() => {
    if (statusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil Menambahkan Reseller Baru",
        content: "",
      });
      setShowAlert(true);
    } else if (statusState === "failed") {
      setAlertProps({
        variant: "Failed",
        title: "Gagal Menambahkan Reseller Baru",
        content: "",
      });
      setShowAlert(true);
    } else if (statusState === "success-approve") {
      setAlertProps({
        variant: "success",
        title: "Data Reseller telah di-Approve.",
        content: "",
      });
      setShowAlert(true);
    } else if (statusState === "success-update") {
      setAlertProps({
        variant: "success",
        title: "Data Reseller telah di-Update.",
        content: "",
      });
      setShowAlert(true);
    } else if (statusState === "success-approve") {
      setAlertProps({
        variant: "success",
        title: "Data Reseller di-Approve.",
        content: "",
      });
      setShowAlert(true);
    } else if (statusState === "success-reject") {
      setAlertProps({
        variant: "success",
        title: "Data Reseller di-Reject.",
        content: "",
      });
      setShowAlert(true);
    } else if (statusState === "success-update-store") {
      setAlertProps({
        variant: "success",
        title: "Data Toko sudah diUpdate",
        content: "",
      });
      setShowAlert(true);
      dispatch(getPartnerStoreListByPartner(data.partner.id));
    } else if (statusState === "success-delete-store") {
      setAlertProps({
        variant: "success",
        title: "Data Toko berhasil dihapus",
        content: "",
      });
      setShowAlert(true);
      dispatch(getPartnerStoreListByPartner(data.partner.id));
    } else if (statusState === "success-add-store") {
      setAlertProps({
        variant: "success",
        title: "Data Toko berhasil ditambah",
        content: "",
      });
      setShowAlert(true);
      dispatch(getPartnerStoreListByPartner(data.partner.id));
    }
  }, [statusState]);

  useEffect(() => {
    if (storeListState.length !== 0) {
      console.log(storeListState);

      // setStoreList(storeListState);

      setOfflineStoreList(
        storeListState.filter((item) => item.type === 0) as PartnerStore[]
      );

      setOnlineStoreList(
        storeListState.filter((item) => item.type === 1) as PartnerStore[]
      );

      // history.replace("/edit-register-reseller/"+data.partner.id)
    }
  }, [storeListState]);

  // useEffect(() => {
  //   if (isEdit) {
  //     dispatch(resetStatus);
  //     dispatch(resetSingle);
  //     resetStoreForm()
  //     setStoreList([]);
  //   }
  // }, []);

  useEffect(() => {
    if (storeProvinceState.length != 0) {
      dispatch(getStoreCity(storeProvinceId.split("&")[0]));
    }
  }, [storeProvinceState]);

  useEffect(() => {
    if (storeCityState.length != 0) {
      dispatch(getStoreDistrict(storeCityId.split("&")[0]));
    }
  }, [storeCityState]);

  console.log("Social Media : " + storeSocialMedia);
  console.log("Province : " + storeProvinceId);

  const columns = [
    {
      name: "NAME",
      selector: "name",
      sortable: true,
    },
    {
      name: "ADDRESS",
      selector: "address",
      sortable: true,
    },
    {
      name: "PROVINCE",
      selector: "provinceName",
      sortable: true,
    },
    {
      name: "CITY",
      selector: "cityName",
      sortable: true,
    },
    {
      name: "DISTRICT",
      selector: "districtName",
      sortable: true,
    },
    {
      name: "POSTAL CODE",
      selector: "postCode",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any; type: number }, index: number) => (
        <>
          <span
            onClick={() => {
              setStoreProvinceId("00000000-0000-0000-0000-000000000000&");
              setStoreDistrictId("00000000-0000-0000-0000-000000000000&");
              setStoreCityId("00000000-0000-0000-0000-000000000000&");
              onStoreEditClick(index, d.id);
            }}>
            <img src={edit}></img>
          </span>
          {"  "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onStoreDeleteClick(index, d.id);

              setSelectedStoreIndex(index);
              setSelectedStoreId(d.id);
              setSelectedStoreType(d.type);

              setDeleterAlertProps({
                variant: "info",
                title: "Delete Data Toko",
                content: "Yakin akan menghapus data Toko ini ?",
              });

              setShowDeleteAlert(true);
            }}>
            <img src={hapus}></img>;
          </span>
        </>
      ),
    },
  ];

  const columns2 = [
    {
      name: "SOSIAL MEDIA",
      selector: "socialMedia",
      sortable: true,
    },
    {
      name: "NAMA TOKO",
      selector: "name",
      sortable: true,
    },
    {
      name: "LINK TOKO",
      selector: "link",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (
        d: {
          id: any;
          type: number;
          socialMedia: string;
          socialMediaName: string;
        },
        index: number
      ) => (
        <>
          <span
            onClick={() => {
              setSelectedStoreId(d.id);
              setSelectedStoreType(d.type);
              setStoreProvinceId("00000000-0000-0000-0000-000000000000&");
              setStoreDistrictId("00000000-0000-0000-0000-000000000000&");
              setStoreCityId("00000000-0000-0000-0000-000000000000&");
              // setStoreSocialMedia(
              //   d.socialMedia.toString() + "&" + d.socialMediaName
              // );
              setStoreSocialMediaName(d.socialMediaName)

              onMediaStoreEditClick(index, d.id);
            }}>
            <img src={edit}></img>
          </span>
          {"    "}
          <span
            onClick={() => {
              setSelectedStoreIndex(index);
              setSelectedStoreId(d.id);

              setDeleterAlertProps({
                variant: "info",
                title: "Delete Data Toko",
                content: "Yakin akan menghapus data Toko ini ?",
              });

              setShowDeleteAlert(true);
            }}>
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <Button
        className="float-right"
        style={{ width: 160, marginLeft: 10 }}
        variant="info"
        type="submit"
        form="internalUseForm"
        onClick={onSave}>
        Save
      </Button>
   
      {status !== undefined && status === 0 && isEdit ? (
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="success"
          onClick={() => {
            if (window.confirm("Approve data Reseller ini?")) onApproveClick();
          }}>
          Approve
        </Button>
      ) : (
        ""
      )}
      {status !== undefined && isEdit && status === 0 ? (
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="danger"
          onClick={() => {
            // if (window.confirm("Reject data Reseller ini?")) onRejectClick();
            setShowModal(true);
          }}>
          Reject
        </Button>
      ) : (
        ""
      )}
      <Button
        className="float-right"
        style={{ width: 130, marginLeft: 10 }}
        variant="outline-info"
        onClick={() => onCancelClick()}>
        Cancel
      </Button>{" "}
      <Form.Group>
        <Form.Label className="title">Registrasi Reseller</Form.Label>
      </Form.Group>
      <Grid textAlign="center">
        <Card>
          <Card.Body>
            <b>{isEdit ? "Edit Data Reseller" : "Add Data Reseller"}</b>

            <hr />

            <Row>
              <Col sm={7}>
                <b>Data Diri</b>
                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Nama Lengkap
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Nama Lengkap"
                      type="text"
                      onChange={onFullNameChange}
                      value={fullName}
                      required
                    />
                  </Col>
                </Form.Group>
                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Email
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={onEmailChange}
                      value={email}
                      required
                    />
                  </Col>
                </Form.Group>
                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    No. Telephone / HP
                  </Form.Label>
                  <Col>
                    <PhoneInput
                      international
                      defaultCountry="ID"
                      onChange={setPhoneNumber}
                      value={phoneNumber}
                      required
                    />
                    {/* <Form.Control
                                            placeholder="No. Telephone / HP"
                                            type="text"

                                        /> */}
                  </Col>
                </Form.Group>
                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Gender
                  </Form.Label>
                  <Col sm={4}>
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Check
                            label="Male"
                            type="radio"
                            checked={gender === 0}
                            value={0}
                            onClick={() => setGender(0)}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="radio"
                            checked={gender === 1}
                            value={1}
                            onClick={() => setGender(1)}
                            label="Female"
                          />
                        </Col>
                      </Form.Row>
                    </Form>
                  </Col>
                </Form.Group>

                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Alamat
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="alamat"
                      onChange={onAddressChange}
                      value={address}
                    />
                  </Col>
                </Form.Group>
                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Provinsi
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      onChange={onProvinceChange}
                      value={provinceId}>
                      <option>select</option>
                      {provinceState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Kota
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      onChange={onCityChange}
                      value={cityId}>
                      <option>select</option>
                      {cityState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Kecamatan
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      onChange={onDistrictChange}
                      value={districtId}>
                      <option>select</option>
                      {districtState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Kode Pos
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      placeholder="Postal Code"
                      onChange={onPostalcodeChange}
                      value={postalCode}
                      required
                    />
                  </Col>
                </Form.Group>

                <hr />

                {/* <RegisterResellerUser /> */}

                {!isEdit ? (
                  <div className="mt-1">
                    <b>Data Akun</b>
                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={4}>
                        Username
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          type="text"
                          onChange={onUserNameChange}
                          value={userName}
                          required
                          placeholder="username"
                        />
                      </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={4}>
                        Password
                      </Form.Label>
                      <Col sm={4}>
                        <InputGroup>
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            onChange={onPasswordChange}
                            value={password}
                            required
                            placeholder="Password"
                          />
                          <InputGroup.Prepend className="toggle-eye-icon">
                            <InputGroup.Text
                              style={{
                                backgroundColor: "#fff",
                                color: "#F5B95D",
                                border: 0,
                              }}
                              onClick={togglePasswordInvisibility}>
                              {passwordShown ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={4}>
                        Confirm Password
                      </Form.Label>
                      <Col sm={4}>
                        <InputGroup>
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            onChange={onConfirmPasswordChange}
                            value={confirmPassword}
                            required
                            placeholder="Confirm Password"
                          />
                          <InputGroup.Prepend className="toggle-eye-icon">
                            <InputGroup.Text
                              style={{
                                backgroundColor: "#fff",
                                color: "#F5B95D",
                                border: 0,
                              }}
                              onClick={togglePasswordInvisibility}>
                              {passwordShown ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                        {password !== confirmPassword ? (
                          <Form.Label className="card-subtitle">
                            Both password must match
                          </Form.Label>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Form.Group>

                    <hr />

                    {/* <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={4}>
                        User PIN code
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          type="text"
                          onChange={onPincodeChange}
                          value={pinCode}
                          required
                          placeholder="PIN"
                        />
                      </Col>
                    </Form.Group>

                    <hr /> */}
                  </div>
                ) : (
                  ""
                )}
              </Col>

              <Col sm={5}>
                <b>Foto</b>

                <hr/>

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Foto Diri
                  </Form.Label>
                  <Col>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleImageSelf(acceptedFiles)
                      }>
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone file-upload-frame w-100">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="text-center file-upload-label">
                              <div>
                                {(imageSelf !== "" ||
                                  Object.keys(data).length !== 0) && (
                                  <img
                                    src={
                                      imageSelf !== ""
                                        ? imageSelf
                                        : endpoint + data.partner.selfPhoto
                                    }
                                    className="file-upload-image mb3"
                                  />
                                )}

                                {imageSelf === "" &&
                                  Object.keys(data).length === 0 && (
                                    <>
                                      <div
                                        className="file-upload-image mb3"
                                        style={{ paddingTop: 50 }}>
                                        <img src={upload} alt="upload" />
                                        <br />
                                        <span style={{ color: "black" }}>
                                          Select File
                                        </span>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Form.Group>

                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    KTP
                  </Form.Label>
                  <Col>
                    <Dropzone
                      onDrop={(acceptedFiles) => handleImageKtp(acceptedFiles)}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone file-upload-frame w-100">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="text-center file-upload-label">
                              <div>
                                {/* {console.log(imageKtp)} */}

                                {(imageKtp !== "" ||
                                  Object.keys(data).length !== 0) && (
                                  <img
                                    src={
                                      imageKtp !== ""
                                        ? imageKtp
                                        : endpoint + data.partner.ktpPhoto
                                    }
                                    className="file-upload-image mb3"
                                  />
                                )}

                                {imageKtp === "" &&
                                  Object.keys(data).length === 0 && (
                                    <>
                                      <div
                                        className="file-upload-image mb3"
                                        style={{ paddingTop: 50 }}>
                                        <img src={upload} alt="upload" />
                                        <br />
                                        <span style={{ color: "black" }}>
                                          Select File
                                        </span>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Form.Group>

                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Foto Diri + KTP
                  </Form.Label>
                  <Col>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleImageSelfKtp(acceptedFiles)
                      }>
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone file-upload-frame w-100">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="text-center file-upload-label">
                              <div>
                                {(imageSelfKtp !== "" ||
                                  Object.keys(data).length !== 0) && (
                                  <img
                                    src={
                                      imageSelfKtp !== ""
                                        ? imageSelfKtp
                                        : endpoint + data.partner.ktpSelfPhoto
                                    }
                                    className="file-upload-image mb3"
                                  />
                                )}

                                {imageSelfKtp === "" &&
                                  Object.keys(data).length === 0 && (
                                    <>
                                      <div
                                        className="file-upload-image mb3"
                                        style={{ paddingTop: 50 }}>
                                        <img src={upload} alt="upload" />
                                        <br />
                                        <span style={{ color: "black" }}>
                                          Select File
                                        </span>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Form.Group>
                <hr />

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    NPWP (Opsional)
                  </Form.Label>
                  <Col>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleImageNpwp(acceptedFiles)
                      }>
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone file-upload-frame w-100">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="text-center file-upload-label">
                              <div>
                                {(imageNpwp !== "" ||
                                  Object.keys(data).length !== 0) && (
                                  <img
                                    src={
                                      imageNpwp !== ""
                                        ? imageNpwp
                                        : endpoint + data.partner.npwpPhoto
                                    }
                                    className="file-upload-image mb3"
                                  />
                                )}

                                {imageNpwp === "" &&
                                  Object.keys(data).length === 0 && (
                                    <>
                                      <div
                                        className="file-upload-image mb3"
                                        style={{ paddingTop: 50 }}>
                                        <img src={upload} alt="upload" />
                                        <br />
                                        <span style={{ color: "black" }}>
                                          Select File
                                        </span>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Form.Group>
                <hr />
              </Col>
            </Row>

            {/* <RegisterResellerStore /> */}

            <div>
              <Row>
                <Col sm={7} className="mt-5">
                  <b>Data Toko</b>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Jenis Toko
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={onStoreTypeChange}
                        value={storeType}>
                        <option value={0}>Offline</option>
                        <option value={1}>Social Media</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  {storeType === 1 ? (
                    <>
                      {/* <hr />
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Sosial Media
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            onChange={onStoreSocialMediaChange}
                            value={storeSocialMedia}>
                            {socialMediaEnumState.map((Item) => {
                              return (
                                <option value={Item.id + "&" + Item.name}>
                                  {Item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group> */}

                      <hr />

                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Sosial Media
                        </Form.Label>
                        <Col>
                          <Form.Control
                            placeholder="Sosial Media"
                            type="text"
                            onChange={onStoreSocialMediaNameChange}
                            value={storeSocialMediaName}
                            required
                          />
                        </Col>
                      </Form.Group>

                    </>
                  ) : (
                    <></>
                  )}

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Nama Toko
                    </Form.Label>
                    <Col>
                      <Form.Control
                        placeholder="Nama Toko"
                        type="text"
                        onChange={onStoreNameChange}
                        value={storeName}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <hr />

                  {storeType === 0 ? (
                    <>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Alamat
                        </Form.Label>
                        <Col>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="alamat"
                            onChange={onStoreAddressChange}
                            value={storeAddress}
                          />
                        </Col>
                      </Form.Group>
                      <hr />

                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Provinsi
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            onChange={onStoreProvinceChange}
                            value={storeProvinceId}>
                            <option>select</option>
                            {storeProvinceState.map((Item) => {
                              return (
                                <option value={Item.id + "&" + Item.name}>
                                  {Item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <hr />

                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Kota
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            onChange={onStoreCityChange}
                            value={storeCityId}>
                            <option>select</option>
                            {storeCityState.map((Item) => {
                              return (
                                <option value={Item.id + "&" + Item.name}>
                                  {Item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      <hr />

                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Kecamatan
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            onChange={onStoreDistrictChange}
                            value={storeDistrictId}>
                            <option>select</option>
                            {storeDistrictState.map((Item) => {
                              return (
                                <option value={Item.id + "&" + Item.name}>
                                  {Item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <hr />

                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Kode Pos
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            type="text"
                            placeholder="Postal Code"
                            onChange={onStorePostalCodeChange}
                            value={storePostalCode}
                            required
                          />
                        </Col>
                      </Form.Group>

                      <hr />
                    </>
                  ) : (
                    <>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Link Toko
                        </Form.Label>
                        <Col>
                          <Form.Control
                            placeholder="Link Toko"
                            type="text"
                            onChange={onStoreLinkChange}
                            value={storeLink}
                          />
                        </Col>
                      </Form.Group>
                    </>
                  )}

                  <div className="btn-footer-group">
                    {/* <Link to={{ pathname: `/InternalUse/index` }}>
        <Button className="btn-cancel" variant="light">Cancel</Button>{'  '}
    </Link> */}
                    <Button
                      variant="info"
                      style={{ width: 130, height: 45 }}
                      type="submit"
                      onClick={onAddStoreClick}>
                      {!isEditingStore ? <>Tambah Toko</> : <>Edit Data Toko</>}
                    </Button>
                    {"    "}

                    {isEditingStore ? (
                      <>
                        <Button
                          variant="outline-info"
                          style={{ width: 130, height: 45 }}
                          type="submit"
                          onClick={onCancelEditClick}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="m-4">
                    <Tab eventKey="home" title="Toko Offline">
                      <TabContent>
                        <Grid>
                          <Card>
                            <DataTable
                              columns={columns}
                              data={offlineStoreList}
                              pagination
                              paginationResetDefaultPage={resetPaginationToggle}
                              noHeader
                              customStyles={TableCustomStyles}
                            />
                          </Card>
                        </Grid>
                      </TabContent>
                    </Tab>
                    <Tab eventKey="profile" title="Social Media">
                      <TabContent>
                        <Grid>
                          <Card>
                            <DataTable
                              columns={columns2}
                              data={onlineStoreList}
                              pagination
                              paginationResetDefaultPage={resetPaginationToggle}
                              noHeader
                              customStyles={TableCustomStyles}
                            />
                          </Card>
                        </Grid>
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Grid>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Reject Data</div>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Yakin akan tolak data reseller ini ?</Form.Label>

          <Form.Group className="input-padding">
            <Form.Label>Alasan</Form.Label>

            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Note"
              onChange={onApprovalNoteChange}
              value={approvalNote}
            />
          </Form.Group>

          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onRejectClick}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDeleteDataStore}></DeleteMessage>
    </div>
  );
};

export default RegisterResellerCreate;
