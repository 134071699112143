import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SupplierModel } from "./supplier.model";
import { ApiErrorResponse } from "../../..";
import SupplierApi from "./suppiler.api";
import { BankModel } from "../bank/bank.model";

export interface SupplierSlice {
  name?: string;
  list?: SupplierModel[];
  single?: SupplierModel;
  bankList?: BankModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const createData = createAsyncThunk(
  "createSupplierState/create",
  async (Model: SupplierModel, { rejectWithValue }) => {
    try {
      return await SupplierApi.createData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "getListState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await SupplierApi.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "SingleItemState/SingleItem",
  async (customerId: string, { rejectWithValue }) => {
    try {
      return await SupplierApi.getSingleItem(customerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "SupplierState/update",
  async (model: SupplierModel, { rejectWithValue }) => {
    try {
      return await SupplierApi.updateData(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "attributeDeleteState/Attributedelete",
  async (id: string, { rejectWithValue }) => {
    try {
      return await SupplierApi.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getBankList = createAsyncThunk(
  'getSupplierBankListState/getBankList',
  async ( _:string | undefined = undefined, {rejectWithValue}) => {
      try {
          return await SupplierApi.getBankList();
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
);

const SupplierSlice = createSlice({
  name: "SupplierState",
  initialState: {} as SupplierSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as SupplierModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
      state.status = "update";
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-delete"
      state.name = "";
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(getBankList.pending, (state) => {
      state.isLoading = true;
  });
  builder.addCase(getBankList.fulfilled, (state, {payload})=> {
      state.bankList = payload;
      state.isLoading = false;
  });
  builder.addCase(getBankList.rejected, (state, {payload}) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
  });
  },
});

export const { setFilter, resetSingle, resetStatus } = SupplierSlice.actions;
export default SupplierSlice.reducer;
