import axios from "axios";
import { endpoint } from "../../constants";
import { ResetPasswordModel } from "./models";

export default class UserApi {
  static sendEmail(email: string) {
    return axios
      .post<any>(endpoint + "/api/User/SendResetPasswordAdminEmail?email=" + email)
      .then((res) => res.data.data);
  }
}
