import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { postAutoCancelReport } from "../AutoCancelReport.api"
import { unwrapResult } from '@reduxjs/toolkit';

export default function PrintAutoCancelReport() {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        reportType: new URLSearchParams(window.location.search).get('reportType'),
        filterMonthly: new URLSearchParams(window.location.search).get('filterMonthly'),
        startFilterDate: new URLSearchParams(window.location.search).get('startFilterDate'),
        endFilterDate: new URLSearchParams(window.location.search).get('endFilterDate'),
        customerName: new URLSearchParams(window.location.search).get('customerName'),
        skip: new URLSearchParams(window.location.search).get('skip'),
        take: new URLSearchParams(window.location.search).get('take'),
    })

    const [list, setList] = useState<any>([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        const data = {
            "reportType": parseInt(values.reportType),
            "filterMonthly": values.filterMonthly.length !== 0 ? new Date(values.filterMonthly).toISOString() : new Date().toISOString(),
            "startFilterDate": values.startFilterDate === "" ? new Date().toISOString() : new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? new Date().toISOString() : new Date(values.endFilterDate).toISOString(),
            "customerName": values.customerName,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(postAutoCancelReport(data))
            .then(unwrapResult)
            .then((res: any) => {
                setList(res.data.list)
                setTimeout(() => {
                    window.print()
                }, 1000)
            })
            .catch((err: any) => console.log(err))
    }

    return (
        <table className="table">
            <thead className="">
                <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Jenis Customer</th>
                    <th scope="col">Total Keep</th>
                    <th scope="col">Jumlah Cancel</th>
                    <th scope="col">Persen Cancel</th>
                    <th scope="col">Jumlah Lunas</th>
                    <th scope="col">Persen Lunas</th>
                    <th scope="col">Kontribusi Keep</th>
                    <th scope="col">Kontribusi Lunas</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map((res: any, i: number) => (
                        <tr key={i}>
                            <td>{res.customerName}</td>
                            <td>{res.customerType}</td>
                            <td>{res.totalKeep}</td>
                            <td style={{ color: 'red' }}>{res.totalCancel}</td>
                            <td>{res.persenCancel}</td>
                            <td style={{ color: 'green' }}>{res.totalLunas}</td>
                            <td>{res.persenLunas}</td>
                            <td>{res.kontribusiKeep}</td>
                            <td>{res.kontribusiLunas}</td>
                        </tr>
                    ))
                }

            </tbody>
        </table>
    )
}