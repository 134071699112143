import React from "react";
import { Container, Form } from "react-bootstrap";
import PrintListTable from "../components/PrintListTable";

const PrintListTablePage = () => {
  return (
    <Container className="content-body" fluid>
        <Form.Group>
            <Form.Label className="page-title">Print List</Form.Label>
        </Form.Group>
      <PrintListTable/>
    </Container>
  );
};

export default PrintListTablePage;
