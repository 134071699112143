import React, { useEffect, useState, useRef } from 'react'
import '../membership.scss'
import { Container, Form, Button } from "react-bootstrap";
import { FiEdit, FiPlus, FiPrinter, FiTrash } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaFileExport } from 'react-icons/fa';
import { BsFillEyeFill } from 'react-icons/bs';
import { postChangeStatusMembership, postDeleteMembership, postExportMembershipTransaction, postMembershipWithPaging } from '../membership.api';
import { RootState } from '../../../app/store';
import moment from 'moment';
import Switch from 'react-switch';
import { DatePicker, Space } from 'antd';
import ReactToPrint from 'react-to-print';
import Pagination from "react-js-pagination";



export default function Membership() {
    const history = useHistory()
    const { RangePicker } = DatePicker;
    const tableRef = useRef(null);

    // const history = useHistory()
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const { loading } = useSelector((state: RootState) => state.membership)


    const initialValues = {
        "customerName": "",
        "title": "",
        "isActive": 'null',
        "periodType": 'null',
        "periodStart": "",
        "periodEnd": "",
        "registerStartDate": "",
        "registerEndDate": "",
        "skip": 0,
        "take": '10',
        ///
        // "partnerType": 'null',
        // "reportType": '1',
        // "sort": '0',
        // "filterMonthly": moment(new Date()).format('YYYY-MM'),
        // "startFilterDate":'',
        // "endFilterDate":'',
        page: 1,
    }

    const [values, setValues] = useState({
        "customerName": "",
        "title": "",
        "isActive": 'null',
        "periodType": 'null',
        "periodStart": "",
        "periodEnd": "",
        "registerStartDate": "",
        "registerEndDate": "",
        "skip": 0,
        "take": '10',
        page: 1,
    })

    useEffect(() => {
        getData()
    }, [values.page, values.take, values.skip])

    const handlePerPage = (e: any) => {
        setValues({
            ...values,
            take: e.target.value,
            skip: 0,
            page: 1
        })
    }

    const handleRangePickerPeriod = (event: any, picker: any) => {
        setValues({
            ...values,
            periodStart: picker[0],
            periodEnd: picker[1],
        })
    }

    const handleRangePickerRegister = (event: any, picker: any) => {
        setValues({
            ...values,
            registerStartDate: picker[0],
            registerEndDate: picker[1],
        })
    }

    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
        console.log(e.target.name)
        console.log(e.target.value)
    }

    const handleReset = () => {
        setValues(initialValues)
    }

    // const nameFile = ()=>{
    //     if (values.reportType === '0') {
    //         return `${moment(new Date(values.startFilterDate)).format('DD-MMM-YYYY')} - ${moment(new Date(values.endFilterDate)).format('DD-MMM-YYYY')}`
    //     }else{
    //         return `${moment(new Date(values.filterMonthly)).format('DD-MMM-YYYY')}`
    //     }
    // }

    const handlePagination = (page: any) => {
        setValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        })
    }

    const handleExportExcel = (e: any) => {
        const data = {
            "customerName": values.customerName,
            "title": values.title,
            "isActive": values.isActive === 'null' ? null : values.isActive === 'true' ? true : false,
            "periodType": values.periodType === 'null' ? null : 0,
            "periodStart": values.periodStart === "" ? "" : new Date(values.periodStart).toISOString(),
            "periodEnd": values.periodEnd === "" ? "" : new Date(values.periodEnd).toISOString(),
            "registerStartDate": values.registerStartDate === "" ? "" : new Date(values.registerStartDate).toISOString(),
            "registerEndDate": values.registerEndDate === "" ? "" : new Date(values.registerEndDate).toISOString(),
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        console.log(data)
        dispatch<any>(postExportMembershipTransaction(data))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res)
                const url = URL.createObjectURL(new Blob([res]));
                console.log(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Data Register Membership.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err: any) => console.log(err))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setValues({
            ...values,
            "skip": 0,
            page: 1,
        })
        getData()
    }

    const getData = () => {
        const data = {
            "customerName": values.customerName,
            "title": values.title,
            "isActive": values.isActive === 'null' ? null : values.isActive === 'true' ? true : false,
            "periodType": values.periodType === 'null' ? null : 0,
            "periodStart": values.periodStart === "" ? null : new Date(values.periodStart).toISOString(),
            "periodEnd": values.periodEnd === "" ? null : new Date(values.periodEnd).toISOString(),
            "registerStartDate": values.registerStartDate === "" ? null : new Date(values.registerStartDate).toISOString(),
            "registerEndDate": values.registerEndDate === "" ? null : new Date(values.registerEndDate).toISOString(),
            "skip": values.skip,
            "take": parseInt(values.take)
        }

        console.log(data)
        dispatch<any>(postMembershipWithPaging(data))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res.data)
                setList(res.data.list)
                setTotal(res.data.total)
            })
            .catch((err: any) => console.log(err))
    }

    const handleDelete = (id: any) => {
        Swal.fire({
            title: 'Yakin hapus data?',
            // text: "data yang di hapus tidak bisa di kembalikan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result: any) => {
            if (result.isConfirmed) {
                const data = {
                    id: id
                }
                dispatch<any>(postDeleteMembership(data))
                    .then(unwrapResult)
                    .then((res: any) => {
                        console.log(res, 'delete log')
                        if (res.status) {
                            getData()
                            Swal.fire(
                                'Berhasil!',
                                'Status berhasil di non aktifkan.',
                                'success'
                            )
                        } else {
                            Swal.fire(
                                'Error!',
                                'Data gagal di hapus!',
                                'error'
                            )
                        }
                    })
            }
        })
    }

    const handleChangeStatus = (id: any, status: any) => {
        // Swal.fire({
        //     title: 'Yakin Ganti  data?',
        //     // text: "data yang di hapus tidak bisa di kembalikan!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#dc3545',
        //     // cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes, delete it!'
        // }).then((result: any) => {
        //     if (result.isConfirmed) {
        //

        //     }
        // })
        const data = {
            id: id,
            status: status
        }
        dispatch<any>(postChangeStatusMembership(data))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res, 'delete log')
                if (res.status) {
                    getData()
                    Swal.fire(
                        'Status berhasil di ubah!',
                        '',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error!',
                        'Data gagal di hapus!',
                        'error'
                    )
                }
            })
    }




    return (
        <Container className="content-body membership" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                Data Register Membership
                <div>
                    <Button className='addor' onClick={handleExportExcel}><FaFileExport /> Export to Excel</Button>
                    <ReactToPrint
                        trigger={() => <Button className='addor'><FiPrinter /> Print</Button>}
                        content={() => tableRef.current}
                    />
                </div>
            </div>
            <div className="box">
                <div className="head w-100">
                    <form onSubmit={handleSubmit} >
                        <div className="row w-100">
                            <div className="col-md-5">
                                <div className="wrap-inp">
                                    <label htmlFor="title">Nama Customer</label>
                                    <input type='text' className="custext" placeholder='Nama Customer' name='customerName' onChange={handleChange} value={values.customerName} />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Judul Membership</label>
                                    <input type='text' className="custext" placeholder='Judul Membership' name='title' onChange={handleChange} value={values.title} />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Status</label>
                                    <select className=" custom-select cus-sel " placeholder='Select' onChange={handleChange} name='isActive' value={values.isActive}>
                                        <option value="null">All</option>
                                        <option value="true">Active</option>
                                        <option value="false">Deactive</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="wrap-inp">
                                    <label htmlFor="title mr-5">Tanggal Berlaku Membership</label>
                                    <div className="wrapsel">
                                        {/* <select className=" custom-select cus-sel mr-2 left" placeholder='Select'>
                                            <option value="1">Forever</option>
                                            <option value="2">Range Date</option>
                                        </select>      */}
                                        <Space direction="vertical" size={12}>
                                            <RangePicker
                                                className='custext'
                                                onChange={handleRangePickerPeriod}
                                                value={values.periodStart !== "" && values.periodEnd !== "" ? [moment(values.periodStart, 'YYYY-MM-DD'), moment(values.periodEnd, 'YYYY-MM-DD')] : undefined}
                                                format="YYYY-MM-DD"
                                            />
                                        </Space>
                                    </div>
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Tanggal Register</label>
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            className='custext'
                                            onChange={handleRangePickerRegister}
                                            value={values.registerStartDate !== "" && values.registerEndDate !== "" ? [moment(values.registerStartDate, 'YYYY-MM-DD'), moment(values.registerEndDate, 'YYYY-MM-DD')] : undefined}
                                            format="YYYY-MM-DD"
                                        />
                                    </Space>
                                </div>
                            </div>
                            <div className="wrapbutfil col-md-7">
                                <Button className='addor' onClick={handleReset}>Reset</Button>
                                <Button className='addorrev' type='submit'>Filter</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="con">
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing 3 of 3 results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span>
                            <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>

                    </div>
                    <div style={{ overflowX: 'scroll' }}>
                        <table className="table" ref={tableRef}>
                            <thead className="">
                                <tr>
                                    {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                    <th scope="col">ID Membership</th>
                                    <th scope="col">Nama Customer</th>
                                    <th scope="col">Judul Membership</th>
                                    <th scope="col">Tanggal Register</th>
                                    <th scope="col">Tanggal Berlaku Membership</th>
                                    <th scope="col">Status Proses</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">
                                        <div className="text-center">
                                            action
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((res: any, i: number) => (
                                        <tr key={i}>
                                            {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                            <td>{res.membershipId}</td>
                                            <td>{res.partnerName}</td>
                                            <td>{res.membershipTitle}</td>
                                            <td>{res.confirmationPaymentDate === null ? "-" : moment(res.confirmationPaymentDate).format('DD/MM/YYYY')}</td>
                                            <td>{res.periodStart === null ? "-" : moment(res.periodStart).format('DD/MM/YYYY')}</td>

                                            <td>
                                                {/* <Switch onChange={() => { }} readOnly checked={res.status === 1 ? true : false} /> */}
                                                {
                                                    res.status === 0 ? 'Waiting for Payment'
                                                        :
                                                        res.status === 1 ? 'Waiting for Confirmation'
                                                            :
                                                            res.status === 2 ? 'Approved'
                                                                :
                                                                res.status === 3 ? 'Rejected'
                                                                    :
                                                                    res.status === 4 ? 'Expired' : null
                                                }
                                            </td>
                                            <td>{res.isActive ? 'Aktif' : 'Tidak Aktif'}</td>
                                            <td className='text-center '>
                                                <div className="action d-flex align-items-center justify-content-center flex-column">
                                                    <Link to={`/membership/${res.id}`}>
                                                        {/* <BsFillEyeFill
                                                            style={{ zIndex: 2 }}
                                                            className='text-info'
                                                        /> */}
                                                        <Button className='mb-1 bg-seccondary ' style={{ width: 90, height: 30, fontSize: 12 }}>Detail</Button>
                                                    </Link>
                                                    <br />
                                                    <Button className='mb-1 bg-dark ' style={{ width: 90, height: 30, fontSize: 12 }} onClick={() => handleDelete(res.id)}>Delete</Button>
                                                    {
                                                        res.status === 2 || res.status === 3 ?
                                                            null :
                                                            <>
                                                                <Button className='mb-1 bg-success btn-small ' style={{ width: 90, height: 30, fontSize: 12 }} onClick={() => handleChangeStatus(res.id, 2)} >Approve</Button>
                                                                <Button className='mb-1 bg-danger ' style={{ width: 90, height: 30, fontSize: 12 }} onClick={() => handleChangeStatus(res.id, 3)}>Reject</Button>
                                                            </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Pagination
                            activePage={values.page}
                            itemClass='item'
                            itemsCountPerPage={parseInt(values.take)}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePagination}
                            prevPageText='⟨ PREVIOUS'
                            itemClassPrev='prev'
                            itemClassNext='next'
                            nextPageText='NEXT ⟩'
                            hideFirstLastPages
                        />
                    </div>

                </div>
            </div>
        </Container>
    )
}
