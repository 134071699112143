import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileModel } from "./profile.model";
import { ApiErrorResponse } from "../../";
import ProfileApi from "./profile.api";

export interface ProfileSlice {
  name?: string;
  list?: ProfileModel[];
  single?: ProfileModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getProfileSingleItem = createAsyncThunk(
  "profileState/profileSingleItem",
  async (userId: string, { rejectWithValue }) => {
    try {
      return await ProfileApi.getSingleItem(userId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "userState/updateUser",
  async (ProfileModel: ProfileModel, { rejectWithValue }) => {
    try {
      return await ProfileApi.updateProfile(ProfileModel);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const profileSlice = createSlice({
  name: "profileState",
  initialState: {} as ProfileSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as ProfileModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getProfileSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getProfileSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(updateProfile.pending, (state) => {
      state.isLoading = true;
      state.status = "update";
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = profileSlice.actions;
export default profileSlice.reducer;
