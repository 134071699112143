import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Badge,
  ListGroup,
  Table,
  FormControl,
  InputGroup,
  Button,
} from "react-bootstrap";
import { FaExternalLinkAlt, FaSearch } from "react-icons/fa";

import note from "./../../../assets/vector/note.png";

import "./styles.css";
import { SalesOrderCountModel, SalesOrderModel } from "../customer.model";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import edit from "./../../../assets/vector/edit.png";
import { RootState } from "../../../app/store";


const CustomerOrderDetail = ({
  data = {} as SalesOrderCountModel,
  SOList = [] as SalesOrderModel[],
}) => {

  const [paidKeep, setPaidKeep] = useState(0);
  const [paidDropship, setPaidDropship] = useState(0);
  const [unpaidKeep, setUnpaidKeep] = useState(0);
  const [unpaidDropship, setUnpaidDropship] = useState(0);
  const [totalUnpaid, setTotalUnpaid] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const [filterText, setFilterText] = useState("");

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = SOList.filter(
    (item) =>
      item.soNumber?.toLowerCase().includes(filterText.toLowerCase()) 
      ||
      item.orderDate?.toLowerCase().includes(filterText.toLowerCase())
      ||
      item.deliveryName?.toLowerCase().includes(filterText.toLowerCase())
      ||
      item.recipientName?.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setPaidKeep(data.paidKeep);
      setPaidDropship(data.paidDropship);
      setUnpaidKeep(data.unPaidKeep);
      setUnpaidDropship(data.unPaidDropship);
      setTotalPaid(data.totalPaid);
      setTotalUnpaid(data.totalUnPaid);

      console.log(SOList);
    }
  }, [data]);

  const columns = [
    {
      name: "SO NUMBER",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "CUSTOMER ID",
      selector: "customerId",
      sortable: true,
    },
    {
      name: "ORDER DATE",
      selector: (d: { orderDate: string }) => (
        <span>{d.orderDate.substr(0, 10)}</span>
      ),
      sortable: true,
    },
    {
      name: "ORDER TYPE",
      selector: (d: { statusOrder: number }) => (
        <p style={{ margin: 10 }}>
          {d.statusOrder === 0 && <span> Dalam Proses</span>}
          {d.statusOrder === 1 && <span> Bayar Ditempat</span>}
          {d.statusOrder === 2 && <span> Belum Lunas</span>}
          {d.statusOrder === 3 && <span> Bayar Cash</span>}
          {d.statusOrder === 4 && <span> Dropship Belum Lunas</span>}
          {d.statusOrder === 5 && <span> Dropship Lunas</span>}
          {d.statusOrder === 6 && <span> Piutang</span>}
          {d.statusOrder === 7 && <span> Tanpa Konfirmasi Pembayaran</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "FROM",
      selector: "deliveryName",
      sortable: true,
    },
    {
      name: "TO",
      selector: "recipientName",
      sortable: true,
    },
    {
      name: "TOTAL",
      selector: (d: { grandTotal: number }) => (
        <p style={{ margin: 10 }}>
          <span>Rp. {d.grandTotal},-</span>
        </p>
      ),
      sortable: true,
    },
    {
      name: "PAYMENT STATUS",
      selector: (d: { paymentStatus: number }) => (
        <p style={{ margin: 10 }}>
          {d.paymentStatus === 0 && (
            <span className={"custom-badge-danger"}>Unpaid</span>
          )}
          {d.paymentStatus === 1 && (
            <span className={"custom-badge-success"}>Paid</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "PAYMENT DATE",
      selector: (d: { paymentDate: string }) => (
        <p>
          {d.paymentDate.substr(0, 10) !== "0001-01-01" && (
            <span>{d.paymentDate.substr(0, 10)}</span>
          )}
          {d.paymentDate.substr(0, 10) === "0001-01-01" && (
            <span>-</span>
          )}

        </p>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (d: { salesOrderId: any }) => (
        <>
          <Link
            to={{
              pathname: `/customer-so-detail/${d.salesOrderId}`,
            }}>
            <img src={edit}></img>
          </Link>
          {"    "}
          {/* <span
            onClick={() => {
              if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                onDelete(d.id);
            }}>
            <img src={hapus}></img>
          </span> */}
        </>
      ),
    },
  ];

  return (
    <div>
      <Form.Group>
        <Form.Label className="title"> Customer Order Details</Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Card.Title style={{ margin: 0 }}>
                  <a href="#">
                    {" "}
                    {/* <b>Myr (12)</b> <FaExternalLinkAlt style={{ marginBottom: 5 }} />{" "} */}
                  </a>
                </Card.Title>
              </Col>
              <Col>
                <Card.Title style={{ margin: 0 }} className="float-right">
                  <b>Total order: {totalPaid + totalUnpaid}</b>
                </Card.Title>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <Card>
                  <Card.Title className="card-title">Unpaid Order</Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Row>
                        <Col md={2}>
                          <div className="order-detail-card-circle">
                            <img
                              src={note}
                              alt="Logo"
                              style={{ marginLeft: 10, marginTop: 5 }}
                            />
                          </div>{" "}
                        </Col>
                        <Col md={8}>keep</Col>
                        <Col md={2}>
                          <b>{unpaidKeep}</b>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col md={2}>
                          <div className="order-detail-card-circle">
                            <img
                              src={note}
                              alt="Logo"
                              style={{ marginLeft: 10, marginTop: 5 }}
                            />
                          </div>{" "}
                        </Col>
                        <Col md={8}>Dropship</Col>
                        <Col md={2}>
                          <b>{unpaidDropship}</b>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col md={10}>total Unpaid Order</Col>{" "}
                        <Col md={2}>
                          <b>{totalUnpaid}</b>
                        </Col>
                      </Row>{" "}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <Card.Title className="card-title">Paid Order</Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Row>
                        <Col md={2}>
                          <div className="order-detail-card-circle">
                            <img
                              src={note}
                              alt="Logo"
                              style={{ marginLeft: 10, marginTop: 5 }}
                            />
                          </div>{" "}
                        </Col>
                        <Col md={8}>keep</Col>
                        <Col md={2}>
                          <b>{paidKeep}</b>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col md={2}>
                          <div className="order-detail-card-circle">
                            <img
                              src={note}
                              alt="Logo"
                              style={{ marginLeft: 10, marginTop: 5 }}
                            />
                          </div>{" "}
                        </Col>
                        <Col md={8}>Dropship</Col>
                        <Col md={2}>
                          <b>{paidDropship}</b>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col md={10}>total Paid Order</Col>{" "}
                        <Col md={2}>
                          <b>{totalPaid}</b>
                        </Col>
                      </Row>{" "}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>

            <div className="customer-order-detail-note">
              <span>
                * Calculation of the order amount based on the total order
                quantity
              </span>
              <br />
              <span>
                * If you want to see details of the order quantity, please click
                the available order quantity
              </span>
            </div>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 40 }}>
          <Card.Title className="card-title input-padding">
            All data orders
          </Card.Title>
          <hr />

          <Row style={{ margin: 0 }}>
            <Col sm={8}>
              <InputGroup className="mb-3" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search for Data Orders (ID, From, etc)"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </InputGroup>
            </Col>
            {/* <Col>
              <Button
                className="float-right"
                variant="outline-info"
                style={{ width: 161, height: 40, marginLeft: 20 }}>
                Export to Excel
              </Button>{" "}
              <Button
                className="float-right"
                variant="outline-info"
                style={{ width: 96, height: 40 }}>
                Print
              </Button>{" "}
            </Col> */}
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            noHeader
            paginationResetDefaultPage={resetPaginationToggle}
            // subHeaderComponent={DefaultSubHeader(
            //   filterText,
            //   resetPaginationToggle,
            //   onSetterFilterText,
            //   elementProps
            // )}
            customStyles={TableCustomStyles}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default CustomerOrderDetail;
