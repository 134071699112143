import { Container } from "react-bootstrap";
import DropshipTable from "../components/dropshipTable";

/*
 * created by : dikembe mutombo
 * created date : 14/oct/2021
 */

const DropshipIndex = () => {
  return (
    <Container className="content-body" fluid>
      <DropshipTable />
    </Container>
  );
};

export default DropshipIndex;
