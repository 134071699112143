import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import AdminStockistApi from "./adminStockist.api";
import {
    AdminStockistModel,
    CreateAdminStockistModel,
    StockistList,
    CountSalesOrder,
    ListSalesOrder,
    DetailSalesOrder,
    GetListSalesOrderByPartnerRequest
} from "./adminStockist.model";

export interface AdminStockistSlice {
    name? : string,
    single? : AdminStockistModel,
    list?: AdminStockistModel[],
    stockistList?: StockistList[],
    countSalesOrder?: CountSalesOrder,
    listSalesOrder?: ListSalesOrder[],
    // totalSalesOrder?: number,
    detailSalesOrder?: DetailSalesOrder,
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getAdminStockistList = createAsyncThunk(
    'getAdminStockistListState/getAdminStockistList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.getAdminStockistList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getStockistList = createAsyncThunk(
    'getStockistListState/getStockistList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.getStockistList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getAdminStockistSingleItem = createAsyncThunk(
    'getAdminStockistSingleItemState/getAdminStockistSingleItem',
    async (id: string , { rejectWithValue }) => {
        try {
            return await AdminStockistApi.getAdminStockistSingleItem(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const createAdminStockist = createAsyncThunk(
    'createAdminStockistState/createAdminStockist',
    async (model: CreateAdminStockistModel, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.createAdminStockist(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updateAdminStockist = createAsyncThunk(
    'updateAdminStockistState/updateAdminStockist',
    async (model: CreateAdminStockistModel, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.updateAdminStockist(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteAdminStockist = createAsyncThunk(
    'deleteAdminStockistState/deleteAdminStockist',
    async (id: string, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.deleteAdminStockist(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getCountSalesOrder = createAsyncThunk(
    'getCountSalesOrderState/getCountSalesOrder',
    async (id: string, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.getCountSalesOrder(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getListSalesOrderByPartner = createAsyncThunk(
    'getListSalesOrderByPartnerState/getListSalesOrderByPartner',
    async (id: string, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.getListSalesOrderByPartner(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

// export const getListSalesOrderByPartner = createAsyncThunk(
//     'getListSalesOrderByPartnerState/getListSalesOrderByPartner',
//     async (args: GetListSalesOrderByPartnerRequest, { rejectWithValue }) => {
//         try {
//             return await AdminStockistApi.getListSalesOrderByPartner(args);
//         } catch (e) {
//             return rejectWithValue(e as ApiErrorResponse<any>);
//         }
//     }
// )

export const getDetailSalesOrder = createAsyncThunk(
    'getDetailSalesOrderState/getDetailSalesOrder',
    async (id: string, { rejectWithValue }) => {
        try {
            return await AdminStockistApi.getDetailSalesOrder(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const AdminStockistSlice = createSlice({
    name: 'AdminStockistState',
    initialState: {} as AdminStockistSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = ""
        },
        resetSingle: (state) => {
            state.single = {} as AdminStockistModel
            state.status = ""
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAdminStockistList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAdminStockistList.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.status = "success-get-list";
            state.isLoading = false;
        });
        builder.addCase(getAdminStockistList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getStockistList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStockistList.fulfilled, (state, { payload }) => {
            state.stockistList = payload;
            state.isLoading = false;
        });
        builder.addCase(getStockistList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getAdminStockistSingleItem.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAdminStockistSingleItem.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.isLoading = false;
        });
        builder.addCase(getAdminStockistSingleItem.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(createAdminStockist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createAdminStockist.fulfilled, (state, { payload }) => {
            state.status = "success"
            state.isLoading = false;
        });
        builder.addCase(createAdminStockist.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateAdminStockist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateAdminStockist.fulfilled, (state, { payload }) => {
            state.status = "success-update"
            state.isLoading = false;
        });
        builder.addCase(updateAdminStockist.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(deleteAdminStockist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteAdminStockist.fulfilled, (state, { payload }) => {
            state.status = "success-delete"
            state.isLoading = false;
        });
        builder.addCase(deleteAdminStockist.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getCountSalesOrder.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCountSalesOrder.fulfilled, (state, { payload }) => {
            state.countSalesOrder = payload;
            state.status = "success"
            state.isLoading = false;
        });
        builder.addCase(getCountSalesOrder.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getListSalesOrderByPartner.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListSalesOrderByPartner.fulfilled, (state, { payload }) => {
            state.listSalesOrder = payload.list;
            // state.totalSalesOrder = payload.total;
            state.status = "success"
            state.isLoading = false;
        });
        builder.addCase(getListSalesOrderByPartner.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getDetailSalesOrder.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDetailSalesOrder.fulfilled, (state, { payload }) => {
            state.detailSalesOrder = payload;
            state.status = "success"
            state.isLoading = false;
        });
        builder.addCase(getDetailSalesOrder.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        
    }
})

export const { resetStatus, resetSingle } = AdminStockistSlice.actions;
export default AdminStockistSlice.reducer;