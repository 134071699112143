import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { ProductModel } from "../../../global/global.model";
import { variationModel } from "../../master/product/product.model";
import Api from "./inventoryMove.api";
import {
  CreateInventoryMoveDetailModel,
  CreateProductHistoryModel,
  FilterModel,
  InventoryMoveDetailModel,
  InventoryMoveModel,
  InventoryReceiveHistoryModel,
} from "./inventoryMove.model";

export interface InventoryMoveSlice {
  name?: string;
  list?: InventoryMoveModel[];
  single?: InventoryMoveModel;
  listInventoryMoveDetail?: InventoryMoveDetailModel[];
  selectedWarehouseId?: string;
  productListByWarehouse?: ProductModel[];
  listVariationByProduct?: variationModel[];
  totalRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const createData = createAsyncThunk(
  "InventoryMoveState/createInventoryMove",
  async (Model: CreateProductHistoryModel, { rejectWithValue }) => {
    try {
      const response = await Api.createData(Model.inventoryMove);
      if (response.status === true) {
        let inventoryMoveDetailData = Object.assign(
          {},
          Model.inventoryMoveDetail
        );
        inventoryMoveDetailData.inventoryMoveId = response.data.id;
        return await Api.createDetailData(inventoryMoveDetailData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createDetailData = createAsyncThunk(
  "InventoryMoveCreateDetailDataState/InventoryMoveCreateDetailData",
  async (Model: CreateInventoryMoveDetailModel, { rejectWithValue }) => {
    try {
      return await Api.createDetailData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "InventoryMoveState/getInventoryMoveList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWithPaging = createAsyncThunk(
  "InventoryMoveGetListWithPagingState/GetListWithPaging",
  async (model : FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);


export const getSingleItem = createAsyncThunk(
  "InventoryMoveState/InventoryMoveSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "InventoryMoveState/updateInventoryMove",
  async (Model: CreateProductHistoryModel, { rejectWithValue }) => {
    try {
      const response = await Api.updateData(Model.inventoryMove);
      if (response.status === true) {
        let inventoryMoveDetailData = Object.assign(
          {},
          Model.inventoryMoveDetail
        );
        inventoryMoveDetailData.inventoryMoveId = response.data.id;
        return await Api.createDetailData(inventoryMoveDetailData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const approvalInventoryMove = createAsyncThunk(
  "InventoryMoveState/approvalInventoryMove",
  async (Model: InventoryMoveModel, { rejectWithValue }) => {
    try {
      return await Api.updateData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "InventoryMoveState/deleteInventoryMove",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getInventoryMoveDetailList = createAsyncThunk(
  "InventoryMoveState/getInventoryMoveDetailList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getInventoryMoveDetailList(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProductListByWarehouse = createAsyncThunk(
  "getProductListByWarehouseState/getProductListByWarehouse",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getProductListByWarehouse(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

//inventory receive history
export const createInventoryHistoryReceive = createAsyncThunk(
  "InventoryMoveState/createInventoryMove",
  async (Model: InventoryReceiveHistoryModel, { rejectWithValue }) => {
    try {
      return await Api.createInventoryHistoryReceive(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationByProduct = createAsyncThunk(
  "InventoryMoveGetListVariationState/InventoryMoveGetListVariation",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getListVariationByProduct(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteInventoryMoveDetail = createAsyncThunk(
  "DeleteInventoryMoveDetailState/deleteInventoryMoveDetail",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteInventoryMoveDetail(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const InventoryMoveSlice = createSlice({
  name: "InventoryMoveState",
  initialState: {} as InventoryMoveSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as InventoryMoveModel;
      state.listInventoryMoveDetail = [];
      state.selectedWarehouseId = "";
      state.productListByWarehouse = [];
    },
    resetStatus: (state) => {
      state.status = "";
      state.productListByWarehouse = [];
      state.selectedWarehouseId = "";
    },
    setInventoryMoveDetail: (state, action) => {
      state.listInventoryMoveDetail = action.payload;
    },
    setSelectedWarehouseId: (state, action) => {
      state.selectedWarehouseId = action.payload;
    },
    resetListVariationByProduct: (state) => {
      state.listVariationByProduct = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(approvalInventoryMove.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(approvalInventoryMove.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(approvalInventoryMove.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(getProductListByWarehouse.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getProductListByWarehouse.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.productListByWarehouse = payload;
      }
    );
    builder.addCase(
      getProductListByWarehouse.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as ApiErrorResponse<any>;
      }
    );

    builder.addCase(getInventoryMoveDetailList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getInventoryMoveDetailList.fulfilled,
      (state, { payload }) => {
        state.listInventoryMoveDetail = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getInventoryMoveDetailList.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );

    builder.addCase(getListVariationByProduct.pending, (state) => {
      state.listVariationByProduct = [];
      state.isLoading = true;
    });
    builder.addCase(getListVariationByProduct.fulfilled, (state, { payload }) => {
      state.listVariationByProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(deleteInventoryMoveDetail.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(deleteInventoryMoveDetail.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-delete-detail"
    });
    builder.addCase(deleteInventoryMoveDetail.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = "falied-delete-detail"
    });

    builder.addCase(createDetailData.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(createDetailData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-create-detail"
    });
    builder.addCase(createDetailData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = "falied-create-detail"
    });
  },
});

export const { setFilter, resetSingle, resetStatus, setInventoryMoveDetail,setSelectedWarehouseId, resetListVariationByProduct } =
  InventoryMoveSlice.actions;
export default InventoryMoveSlice.reducer;
