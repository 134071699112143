import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {Card, Form, Row, Col, Button, Spinner} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { DatePicker } from "antd";
import { InventoryReceiveHistoryModel } from "../inventoryReceive.model";
import {
  approvalInventoryReceiveHistory,
  resetSingle,
  resetStatus,
} from "../inventoryReceive.reducer";
import ProductTableComponent from "./productTableComponent";
import moment from "moment";

const InventoryReceiveApprovalPage = ({
  data = {} as InventoryReceiveHistoryModel,
  isEdit = false,
  ...props
}: InventoryReceiveProps) => {
  let history = useHistory();

  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const createStatus = useSelector(
    (state: RootState) => state.inventoryReceive.status ?? ""
  );

  const userState = useSelector((state: RootState) => state.user ?? "");

  const errorMessage = useSelector(
    (state: RootState) => state.inventoryReceive.error ?? ""
  );

  const isLoading = useSelector(
      (state: RootState) => state.inventoryReceive.isLoading
  );

  const [receiveName, setReceiveName] = useState(userState.username);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");
  const [inventoryNumber, setInventoryNumber] = useState("");
  const [receiveDate, setReceiveDate] = useState(new Date());
  const [note, setNote] = useState("");
  const [approvalNote, setApprovalNote] = useState("");
  const [reason, setReason] = useState("")

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = "";

      if (message?.message === "error") {
        xhr = message.data[0].description;
      } else {
        xhr = parse.data.title;
      }
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message?.message ?? "-",
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/inventory-receive/index");
    } else {
      dispatch(resetStatus());
    }
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setInventoryNumber(data.inventoryNumber);
      setFromWarehouse(data.fromWarehouseName);
      setToWarehouse(data.toWarehouseName);
      setNote(data.inventoryMoveNote);
      setApprovalNote(data.approvalInventoryReceiveNote??"");
    } else {
      setFromWarehouse("");
      setToWarehouse("");
      setNote("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onClickApproveBtn = () => {
    dispatch(
      approvalInventoryReceiveHistory({
        receiveName: userState.username,
        id: data.id,
        userId: userState.id,
        inventoryNumber,
        approvalInventoryReceiveNote : approvalNote,
        inventoryMoveId: data.inventoryMoveId,
        approvedBy: userState.username,
        rejectedBy: "",
        status: 1,
      } as InventoryReceiveHistoryModel)
    );
  };

  const onClickRejectBtn = () => {
    dispatch(
      approvalInventoryReceiveHistory({
        receiveName: userState.username,
        id: data.id,
        userId: userState.id,
        inventoryNumber,
        approvalInventoryReceiveNote : approvalNote,
        inventoryMoveId: data.inventoryMoveId,
        approvedBy: "",
        rejectedBy: userState.username,
        status: 2,
      } as InventoryReceiveHistoryModel)
    );
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Form.Group>
          <Form.Label>
            <h4>Approve Inventory Receive</h4>
          </Form.Label>
        </Form.Group>

        <Card>
          <Card.Body>
            <div className="profile-setting-subtitle">Inventory Receive</div>
            <Form>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Nomor Penerimaan
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={inventoryNumber}
                    required
                    disabled></Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Receive Date
                </Form.Label>
                <Col sm={4}>
                  <DatePicker
                    defaultValue={moment(receiveDate, "YYYY-MM-DD HH:mm")}
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Nama Pemohon
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={receiveName}
                    disabled></Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={fromWarehouse}
                    disabled></Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse Tujuan
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={toWarehouse}
                    disabled></Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={4}>
                  <Form.Control as="textarea" rows={3} value={note} disabled />
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <br />
        <section>
          <ProductTableComponent />
        </section>
        <br />
        <section>
          <Card>
            <Card.Body>
              <Form>
                {data.status === 0 ? (
                  <>
                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        Note
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={approvalNote}
                          onChange={(e) => setApprovalNote(e.target.value)}
                          as="textarea"
                          rows={3}
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  <>
                  <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Note
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={approvalNote}
                      onChange={(e) => setApprovalNote(e.target.value)}
                      as="textarea"
                      rows={3}
                      disabled
                    />
                  </Col>
                </Form.Group>

                  </>
                )}

                {data.status === 0 ? (
                  <>
                    <div className="btn-footer-group">
                      <Button
                        style={{ width: 115, height: 46 }}
                        className="btn"
                        variant="success"
                        disabled={isLoading}
                        onClick={onClickApproveBtn}>
                        {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Approve"}
                      </Button>
                      {"  "}
                      <Button
                        style={{ width: 115, height: 46 }}
                        form="inventoryReceive"
                        className="btn"
                        variant="danger"
                        type="submit"
                        disabled={isLoading}
                        onClick={onClickRejectBtn}>
                        {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Reject"}
                      </Button>
                    </div>
                  </>
                ) : <></>}
              </Form>
            </Card.Body>
          </Card>
        </section>

        <AlertMessage
          {...alertProps}
          show={showAlert}
          onAlertCloseAction={onAlertClose}
        />
      </Grid>
    </div>
  );
};

export interface InventoryReceiveProps {
  data: InventoryReceiveHistoryModel;
  isEdit: boolean;
}

export default InventoryReceiveApprovalPage;
