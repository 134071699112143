import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Badge,
  ListGroup,
  Table,
  Modal,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import "./styles.css";
import {
  SalesOrderModel,
  ShipmentModel,
  SalesOrderDetailModel,
  PartnerType,
  CheckOutDetail,
  ServiceModel,
  CheckOutAdminRequestModel,
  SalesOrderWithDetailModel,
  SOShipmentModel,
} from "../customer.model";
import {
  getListExpedition,
  getListProduct,
  getProvince,
  getCityByProvince,
  getDistrictByCity, getPendingNotification,
} from "../../../global/global.reducer";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import hapus from "./../../../assets/vector/delete.png";
import DeleteMessage from "../../../components/DeleteMessage";
import AlertMessage from "../../../components/alertMessage";

import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {
  checkCostAdmin,
  countExpeditionPrint,
  countSOPrint,
  countBackupPrint,
  getListVariationByProduct,
  getSingleSalesOrderWithDetail,
  updateCheckOutAdmin,
  updateShipment,
  resetStatus,
  countSuratJalanPrint,
} from "../customer.reducer";
import SalesOrder from "../../salesOrder/salesOrder/containers/salesOrder";
import { cancelOrder } from "../../salesOrder/salesOrder/salesOrder.reducer";
import Swal from "sweetalert2";
import { resetStatus as resetStatusSO } from "../../salesOrder/salesOrder/salesOrder.reducer";
import {updateStatus, resetStatus as resetStatusPaymentConfirm} from "../../konfirmasi&resi/paymentConfirm/paymentConfirm.reducer";
import {ApiErrorResponse} from "../../../index";

const ListOrderDetail = ({
  SOData = {} as SalesOrderModel,
  // ShipmentData = {} as ShipmentModel,
  // SalesOrderDetailList = [] as SalesOrderDetailModel[],
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userState = useSelector(
      (state: RootState) => state.user.username ?? ""
  );

  const SalesOrderWithDetailState = useSelector(
    (state: RootState) =>
      state.customer.singleSalesOrderWithDetail ?? ({} as SalesOrderWithDetailModel)
  );

  const salesOrderStatusState = useSelector(
    (state: RootState) => state.customer.status ?? ""
  );

  const SingleSalesOrderState = useSelector(
    (state: RootState) =>
      state.customer.singleSalesOrder ?? ({} as SalesOrderModel)
  );

  const productState = useSelector(
    (state: RootState) => state.global.listProduct ?? []
  );

  const variationState = useSelector(
    (state: RootState) => state.customer.listVariationByProduct ?? []
  );

  const provinceState = useSelector(
    (state: RootState) => state.global.listProvince ?? []
  );

  const cityState = useSelector(
    (state: RootState) => state.global.listCityByProvince ?? []
  );

  const districtState = useSelector(
    (state: RootState) => state.global.listDistrictByCity ?? []
  );

  const expeditionState = useSelector(
    (state: RootState) => state.customer.expeditionList ?? []
  );

  const cancelOrderStatus = useSelector(
    (state: RootState) => state.salesOrder.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.customer.error ?? ({} as any)
  );

  const statusState = useSelector(
      (state: RootState) => state.paymentConfirm.status ?? ""
  );

  const error = useSelector(
      (state: RootState) => state.paymentConfirm.error ?? {} as ApiErrorResponse<any>
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [salesOrderDetail, setSalesOrderDetail] = useState<
    SalesOrderDetailModel[]
  >([]);

  const [invoicePrintCount, setInvoicePrintCount] = useState(0);
  const [expeditionPrintCount, setExpeditionPrintCount] = useState(0);
  const [backupPrintCount, setBackupPrintCount] = useState(0);
  const [suratJalanPrintCount, setsuratJalanPrintCount] = useState(0);

  const [invoicePrintDate, setInvoicePrintDate] = useState("");
  const [expeditionPrintDate, setExpeditionPrintDate] = useState("");
  const [cadanganPrintDate, setCadanganPrintDate] = useState("");
  const [deliveryDocPrintDate, setDeliveryDocPrintDate] = useState("");

  const [checkOutDetail, setCheckOutDetail] = useState<CheckOutDetail[]>([]);

  const [salesOrderID, setSalesOrderID] = useState("");
  const [soNumber, setSoNumber] = useState("-");
  const [customerName, setCustomerName] = useState("-");
  const [email, setEmail] = useState("-");
  const [orderDate, setOrderDate] = useState("-");
  const [orderType, setOrderType] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [paymentMethodName, setPaymentMethodName] = useState("-");
  const [paymentTypeName, setPaymentTypeName] = useState("-");
  const [returNumber, setReturNumber] = useState("-");
  const [thirdParty, setThirdParty] = useState("");
  const [note, setNote] = useState("");
  const [expedition, setExpedition] = useState("");
  const [resi, setResi] = useState("");

  const [selectedPartnerType, setSelectedPartnerType] = useState(0);

  const [senderName, setSenderName] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientPostalCode, setRecipientPostalCode] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [recipientProvince, setRecipientProvince] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [recipientCity, setRecipientCity] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [recipientSubDistrict, setRecipientSubDistrict] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  const [cashlessExpedition, setCashlessExpedition] = useState("");

  const [selectedExpedition, setSelectedExpedition] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedExpeditionTemp, setSelectedExpeditionTemp] = useState("");

  const [selectedExpeditionService, setSelectedExpeditionService] =
    useState("");

  const [selectedServiceTypeTemp, setSelectedServiceTypeTemp] = useState("");

  const [selectedExpeditionCost, setSelectedExpeditionCost] = useState(0);
  const [expeditionServiceList, setExpeditionServiceList] = useState<
    ServiceModel[]
  >([]);

  const [administrationPrice, setAdministrationPrice] = useState(0);
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const [isFreeShippingCost, setIsFreeShippingCost] = useState(false);
  const [isManualShippingCost, setIsManualShippingCost] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxStatus, setTaxStatus] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [shipmentStatus, setShipmentStatus] = useState(0);
  const [partnerType, setPartnerType] = useState(0);
  const [transactionStatus, setTransactionStatus] = useState(0);

  const [isPrintA, setIsPrintA] = useState<boolean | null>(null);

  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductSKU, setSelectedProductSKU] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(0);
  const [selectedProductNote, setSelectedProductNote] = useState("");
  const [selectedProductStock, setSelectedProductStock] = useState(0);
  const [selectedProductWeight, setSelectedProductWeight] = useState(0);
  const [selectedProductPrice, setSelectedProductPrice] = useState(0);

  const [selectedVariationId, setSelectedVariationId] = useState("");
  const [selectedVariationName, setSelectedVariationName] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [show, setShow] = useState(false);
  const [showShipping, setShowShipping] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleteAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [cancelAlertProps, setCancelAlertProps] = useState({
    variant: "delete",
    title: "Cancel Order",
    content: "Yakin akan membatalkan Sales Order ini ?",
  });

  const onAlertClose = () => {
    if (cancelOrderStatus === "success-cancel") {
      dispatch(resetStatusSO());
      setShowAlert(false);
      history.goBack();
    }

    dispatch(resetStatus());
    setShowAlert(false);
    setShowShipping(false);
  };

  const onDeleteAlertClose = () => setShowDeleteAlert(false);
  const onCancelAlertClose = () => setShowCancelAlert(false);

  const handleShow = () => setShow(true);
  const handleShowShiping = () => setShowShipping(true);

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseShipment = () => {
    setShowShipping(false);
  };

  const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedProductId(e.target.value.split("&")[0]);
    setSelectedProductName(e.target.value.split("&")[1]);
    setSelectedProductSKU(e.target.value.split("&")[2]);
    dispatch(getListVariationByProduct(e.target.value.split("&")[0]));
  };

  const onProvinceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientProvince(e.target.value);
    dispatch(getCityByProvince(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientCity(e.target.value);
    dispatch(getDistrictByCity(e.target.value));
  };

  const onDistrictChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientSubDistrict(e.target.value);
  };

  const countTotalPrice = (data: SalesOrderDetailModel[]) => {
    let total = 0;
    if (data.length !== 0) {
      data.map((item) => {
        total = total + item.price;
      });
    }
    return total;
  };

  const countTotalQty = (data: SalesOrderDetailModel[]) => {
    let total = 0;
    if (data.length !== 0) {
      data.map((item) => {
        total = total + item.quantity;
      });
    }
    return total;
  };

  const countGrandTotal = () => {
    let total =
      subTotal -
      discount +
      (totalTax + totalShippingCost) +
      administrationPrice;
    return total;
  };

  const countTotalWeight = (data: SalesOrderDetailModel[]) => {
    let total = 0;

    if (data.length !== 0) {
      data.map((item) => {
        total = total + item.weight;
      });
    }
    return total;
  };

  const onExpeditionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedExpedition(e.target.value);

    let filteredService = expeditionState.filter(
      (item) => item.expeditionId === e.target.value
    );

    setExpeditionServiceList(filteredService[0].costs);

    setSelectedExpeditionService(filteredService[0].costs[0].service);
    setSelectedExpeditionCost(filteredService[0].costs[0].cost[0].value);
    setTotalShippingCost(filteredService[0].costs[0].cost[0].value);

    setSelectedServiceTypeTemp(
      filteredService[0].costs[0].service +
        "&" +
        filteredService[0].costs[0].cost[0].value
    );

    setTotalShippingCost(filteredService[0].costs[0].cost[0].value);
  };

  const onExpeditionServiceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedExpeditionService(e.target.value.split("&")[0]);
    setSelectedExpeditionCost(parseInt(e.target.value.split("&")[1]));
    setSelectedServiceTypeTemp(
      e.target.value.split("&")[0] + "&" + e.target.value.split("&")[1]
    );
    // setTotalShippingCost(parseInt(e.target.value.split("&")[1]) * totalWeight);
    setTotalShippingCost(parseInt(e.target.value.split("&")[1]));
  };

  const onEditShipmentClick = () => {
    handleShowShiping();
    // history.replace("/customer-edit-shipment/" + SOData.id);
  };

  const onVariationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedVariationId(e.target.value.split("&")[0]);

    setSelectedVariationName(e.target.value.split("&")[1]);

    setSelectedProductStock(parseInt(e.target.value.split("&")[5]));

    setSelectedProductWeight(parseInt(e.target.value.split("&")[6]));

    if (selectedPartnerType === PartnerType.Customer) {
      setSelectedProductPrice(parseInt(e.target.value.split("&")[2]));
    } else if (selectedPartnerType === PartnerType.Stockist) {
      setSelectedProductPrice(parseInt(e.target.value.split("&")[3]));
    } else if (selectedPartnerType === PartnerType.Reseller) {
      setSelectedProductPrice(parseInt(e.target.value.split("&")[4]));
    }
  };

  const onDelete = () => {
    let newList = [...salesOrderDetail];
    newList.splice(selectedIndex, 1);
    setSalesOrderDetail(newList);
    setShowDeleteAlert(false);
  };

  const addSalesOrderDetail = () => {
    setSalesOrderDetail([
      ...salesOrderDetail,
      {
        id: "",
        salesOrderId: "",
        productId: selectedProductId,
        productName: selectedProductName,
        variationId: selectedVariationId,
        variationName: selectedVariationName,
        price: selectedProductPrice * selectedProductQuantity,
        quantity: selectedProductQuantity,
        priceSatuan: selectedProductPrice,
        note: selectedProductNote,
        stockQty: selectedProductStock,
        weight: selectedProductWeight * selectedProductQuantity,
        warehouseName: "",
        inventorySKU: selectedProductSKU,
      },
    ]);

    setCheckOutDetail([
      ...checkOutDetail,
      {
        variationId: selectedVariationId,
        quantity: selectedProductQuantity,
      },
    ]);

    handleClose();
  };

  const onCancel = () => {
    setShowDeleteAlert(false);
    dispatch(cancelOrder(SOData.id));
  };

  const onSaveDeliveryData = () => {
    dispatch(
      updateShipment({
        salesOrderId: SOData.id,
        senderName: senderName,
        recipientName: recipientName,
        recipientAddress: recipientAddress,
        recipientPhone: recipientPhone,
        recipientPostalCode: recipientPostalCode,
        cashlessExpedition: cashlessExpedition,
        resiNumber: receiptNumber,
        totalShippingCost: totalShippingCost,
        provinceId: recipientProvince,
        cityId: recipientCity,
        districtId: recipientSubDistrict,
        expeditionId: selectedExpedition,
        tarifType: selectedExpeditionService.split("&")[0],
      })
    );
  };

  const onSave = () => {
    let shipmentData = {
      deliveryName: SalesOrderWithDetailState.shipment.deliveryName,
      shippingAddress: SalesOrderWithDetailState.shipment.shippingAddress,
      recipientName: recipientName,
      recipientAddress: recipientAddress,
      recipientPostalCode: recipientPostalCode,
      recipientPhone: recipientPhone,
      recipientProvinceId: recipientProvince,
      recipientCityId: recipientCity,
      recipientDistrictId: recipientSubDistrict,
      recipientEmail: SalesOrderWithDetailState.shipment.recipientEmail,
      resiNumber: receiptNumber,
      totalWeight: totalWeight,
      totalShippingCost: totalShippingCost,
      expeditionId: selectedExpedition,
      tarifType: selectedExpeditionService,
      deliveryDate: SalesOrderWithDetailState.shipment.deliveryDate,
      salesOrderId: SalesOrderWithDetailState.shipment.salesOrderId,
      administrationPrice:
        SalesOrderWithDetailState.shipment.administrationPrice,
      note: SalesOrderWithDetailState.shipment.note,
      service: SalesOrderWithDetailState.shipment.service,
      cost: SalesOrderWithDetailState.shipment.cost,
      senderName: senderName,
    } as SOShipmentModel;

    dispatch(
      updateCheckOutAdmin({
        salesOrder: SalesOrderWithDetailState.salesOrder,
        salesOrderDetail: salesOrderDetail,
        shipment: shipmentData,
      } as SalesOrderWithDetailModel)
    );
  };

  useEffect(() => {
    if (salesOrderStatusState === "success-update-shipment") {
      setAlertProps({
        variant: "success",
        title: "Data pengiriman berhasil di-update.",
        content: "",
      });
      setShowAlert(true);
      setShowShipping(false);
      dispatch(getSingleSalesOrderWithDetail(SOData.id));
    } else if (salesOrderStatusState === "failed-update-shipment") {
      setAlertProps({
        variant: "failed",
        title: "Gagal update data pengiriman",
        content:
          errorMessage?.data?.message?.toString() === undefined
            ? "Terjadi kesalahan pada sistem."
            : errorMessage?.data?.message.toString(),
      });
      setShowAlert(true);
      console.log(errorMessage);
    }
  }, [salesOrderStatusState]);

  useEffect(() => {
    if (
      SingleSalesOrderState &&
      Object.keys(SingleSalesOrderState).length !== 0 &&
      SingleSalesOrderState.constructor === Object
    ) {
      setInvoicePrintCount(SingleSalesOrderState.countNota);
      setExpeditionPrintCount(SingleSalesOrderState.countExpedition);
      setBackupPrintCount(SingleSalesOrderState.countCadangan);
      setsuratJalanPrintCount(SingleSalesOrderState.countSuratJalan);
    }
  }, [SingleSalesOrderState]);

  useEffect(() => {
    if (
      SOData &&
      Object.keys(SOData).length !== 0 &&
      SOData.constructor == Object
    ) {
      setSoNumber(SOData.soNumber);
      setCustomerName(SOData.partnerName);
      setEmail(SOData.partnerEmail);
      setOrderDate(SOData.orderDate.substr(0, 10));
      SOData.statusOrder ?? setOrderType(SOData.statusOrder);
      setPaymentStatus(SOData.paymentStatus);
      setPaymentMethodName(SOData.paymentMethodName);
      // setReturNumber(
      //   SOData.returName === null ||
      //     SOData.returName === "" ||
      //     SOData.returName === undefined
      //     ? "-"
      //     : SOData.returName
      // );

      if (SOData.paymentType === 0) {
        setPaymentTypeName("Cashless");
      } else if (SOData.paymentType === 1) {
        setPaymentTypeName("Non Cashless");
      } else if (SOData.paymentType === 2) {
        setPaymentTypeName("Piutang");
      } else if (SOData.paymentType === 3) {
        setPaymentTypeName("Cash");
      } else {
        setPaymentTypeName("Non");
      }

      setSalesOrderID(SOData.id);
      dispatch(getSingleSalesOrderWithDetail(SOData.id));

      setPartnerType(SOData.partnerType ?? 0);

      setTransactionStatus(SOData.transactionStatus);
    }
  }, [SOData]);

  useEffect(() => {
    if (
      SalesOrderWithDetailState &&
      Object.keys(SalesOrderWithDetailState).length !== 0 &&
      SalesOrderWithDetailState.constructor === Object
    ) {
      // if (SalesOrderWithDetailState?.shipment?.senderName === null) {
      //   setSenderName("The Socialite Dropship");
      // } else if (SalesOrderWithDetailState?.shipment?.senderName === ""){
      //   setSenderName("The Socialite Dropship");
      // } else {
      //   setSenderName(SalesOrderWithDetailState?.shipment?.senderName);
      // }

      setOrderType(SalesOrderWithDetailState.salesOrder.statusOrder);

      SalesOrderWithDetailState?.shipment?.senderName !== null
        ? setSenderName(SalesOrderWithDetailState?.shipment?.senderName)
        : // : setSenderName("The Socialite Dropship");
          setSenderName("");
      SalesOrderWithDetailState?.shipment?.recipientName !== null
        ? setRecipientName(SalesOrderWithDetailState.shipment.recipientName)
        : setRecipientName("");

      // setRecipientName(SalesOrderWithDetailState.shipment.recipientName);
      setRecipientPhone(SalesOrderWithDetailState.shipment.recipientPhone);
      setRecipientAddress(SalesOrderWithDetailState.shipment.recipientAddress);
      setRecipientPostalCode(
        SalesOrderWithDetailState.shipment.recipientPostalCode
      );

      setRecipientProvince(
        SalesOrderWithDetailState.shipment.recipientProvinceId
      );
      setRecipientCity(SalesOrderWithDetailState.shipment.recipientCityId);
      setRecipientSubDistrict(
        SalesOrderWithDetailState.shipment.recipientDistrictId
      );

      setSelectedExpeditionTemp(
        SalesOrderWithDetailState.shipment.expeditionId
      );

      setSelectedExpeditionService(
        SalesOrderWithDetailState.shipment.service +
          "&" +
          SalesOrderWithDetailState.shipment.originalShippingCost
      );

      setTotalShippingCost(
        SalesOrderWithDetailState.shipment.totalShippingCost
      );

      setSelectedExpeditionCost(SalesOrderWithDetailState.shipment.cost);

      setSalesOrderDetail(SalesOrderWithDetailState.salesOrderDetail);

      setSubTotal(countTotalPrice(SalesOrderWithDetailState.salesOrderDetail));
      setTotalWeight(
        countTotalWeight(SalesOrderWithDetailState.salesOrderDetail)
      );
      setQtyTotal(countTotalQty(SalesOrderWithDetailState.salesOrderDetail));
      setGrandTotal(countGrandTotal());

      setIsPrintA(SalesOrderWithDetailState.shipment.isPrintA);

      setReceiptNumber(SalesOrderWithDetailState.shipment.resiNumber);

      setIsFreeShippingCost(SalesOrderWithDetailState.shipment.isFree);

      setShipmentStatus(SalesOrderWithDetailState.shipment.status);

      setReturNumber(
        SalesOrderWithDetailState.salesOrder.returName === "" ||
          SalesOrderWithDetailState.salesOrder.returName === null
          ? "-"
          : SalesOrderWithDetailState.salesOrder.returName
      );

      setThirdParty(SalesOrderWithDetailState.salesOrder.thirdPartyName);
      setNote(SalesOrderWithDetailState.salesOrder.note);

      if (SalesOrderWithDetailState.salesOrder.paymentType === 0) {
        setExpedition(
          SalesOrderWithDetailState.shipment.cashlessExpedition!
        );
      } else if (SalesOrderWithDetailState.salesOrder.paymentType === 1) {
        setExpedition(
          SalesOrderWithDetailState.shipment.expeditionName 
        );
      } else {
        setExpedition(" - ");
      }

      setResi(
        SalesOrderWithDetailState.shipment.resiNumber === "" ||
          SalesOrderWithDetailState.shipment.resiNumber === null
          ? "-"
          : SalesOrderWithDetailState.shipment.resiNumber
      );

      if (
        !SalesOrderWithDetailState.shipment.isFree &&
        SalesOrderWithDetailState.shipment.shippingCost !== 0 &&
        SalesOrderWithDetailState.shipment.shippingCost !==
          SalesOrderWithDetailState.shipment.originalShippingCost
      ) {
        setIsManualShippingCost(true);
      }

      setCashlessExpedition(
        SalesOrderWithDetailState.shipment.cashlessExpedition
      );

      dispatch(getProvince());
      dispatch(
        getCityByProvince(
          SalesOrderWithDetailState.shipment.recipientProvinceId
        )
      );
      dispatch(
        getDistrictByCity(SalesOrderWithDetailState.shipment.recipientCityId)
      );

      dispatch(
        checkCostAdmin({
          senderCityId: null,
          destinationDistrictId:
            SalesOrderWithDetailState?.shipment?.recipientDistrictId,
          salesOrderDetailList: SalesOrderWithDetailState.salesOrderDetail,
        } as CheckOutAdminRequestModel)
      );

      setInvoicePrintDate(
        SalesOrderWithDetailState.salesOrder.printNotaDate !== null
          ? 
          SalesOrderWithDetailState.salesOrder.printNotaDate.substring(8,10) +"/"+
          SalesOrderWithDetailState.salesOrder.printNotaDate.substring(5,7) +"/"+
          SalesOrderWithDetailState.salesOrder.printNotaDate.substring(0,4) +
              " " +
              SalesOrderWithDetailState.salesOrder.printNotaDate.substring(
                11,16
              )
          : ""
      );

      setExpeditionPrintDate(
        SalesOrderWithDetailState.salesOrder.printExpeditionDate !== null
          ? 
          SalesOrderWithDetailState.salesOrder.printExpeditionDate.substring(8,10) +"/"+
          SalesOrderWithDetailState.salesOrder.printExpeditionDate.substring(5,7) +"/"+
          SalesOrderWithDetailState.salesOrder.printExpeditionDate.substring(0,4) +
              " " +
              SalesOrderWithDetailState.salesOrder.printExpeditionDate.substring(
                11,16
              )
          : ""
      );

      setCadanganPrintDate(
        SalesOrderWithDetailState.salesOrder.printCadanganDate !== null
          ? 
          SalesOrderWithDetailState.salesOrder.printCadanganDate.substring(8,10) +"/"+
          SalesOrderWithDetailState.salesOrder.printCadanganDate.substring(5,7) +"/"+
          SalesOrderWithDetailState.salesOrder.printCadanganDate.substring(0,4) +
              " " +
              SalesOrderWithDetailState.salesOrder.printCadanganDate.substring(
                11,16
              )
          : ""
      );

      setDeliveryDocPrintDate(
        SalesOrderWithDetailState.salesOrder.printSuratJalanDate !== null
          ? 
          SalesOrderWithDetailState.salesOrder.printSuratJalanDate.substring(8,10) +"/"+
          SalesOrderWithDetailState.salesOrder.printSuratJalanDate.substring(5,7) +"/"+
          SalesOrderWithDetailState.salesOrder.printSuratJalanDate.substring(0,4) +
              " " +
              SalesOrderWithDetailState.salesOrder.printSuratJalanDate.substring(
                11,16
              )
          : ""
      );
    }
  }, [SalesOrderWithDetailState]);

  useEffect(() => {
    if (
      SalesOrderWithDetailState &&
      Object.keys(SalesOrderWithDetailState).length !== 0 &&
      SalesOrderWithDetailState.constructor === Object &&
      expeditionState.length !== 0
    ) {
      setSelectedExpedition(selectedExpeditionTemp);

      let filteredService = expeditionState.filter(
        (item) => item.expeditionId === selectedExpeditionTemp
      );

      try {
        setSelectedServiceTypeTemp(
          SalesOrderWithDetailState?.shipment?.tarifType +
            "&" +
            filteredService[0].costs.filter((item) =>
              item.service.includes(
                SalesOrderWithDetailState?.shipment?.tarifType
              )
            )[0].cost[0].value
        );
      } catch (error) {
        setSelectedServiceTypeTemp("");
      }

      try {
        setExpeditionServiceList(filteredService[0].costs);
      } catch (error: any) {
        console.log(error);
      }

      if (filteredService.length === 0) {
      } else {
        // if (isFreeShippingCost) {
        //   setTotalShippingCost(0);
        // } else {
        //   setTotalShippingCost(
        //     // filteredService[0].costs[0].cost[0].value * totalWeight
        //     filteredService[0].costs[0].cost[0].value
        //   );
        // }
      }
    }
  }, [expeditionState]);

  useEffect(() => {
    dispatch(
      checkCostAdmin({
        senderCityId: null,
        destinationDistrictId: recipientSubDistrict,
        salesOrderDetailList: SalesOrderWithDetailState.salesOrderDetail,
      } as CheckOutAdminRequestModel)
    );
  }, [recipientSubDistrict]);

  useEffect(() => {
    // dispatch(getListPartner());
    // dispatch(getListPaymentMethod());
    dispatch(getListProduct());
  }, []);

  useEffect(() => {
    if (cancelOrderStatus) {
      setAlertProps({
        variant: "success",
        title: "Berhasil membatalkan order",
        content: "",
      });
      setShowAlert(true);
      setShowCancelAlert(false);
    }
  }, [cancelOrderStatus]);

  useEffect(() => {
    console.log("statusState : " + statusState);
    if (
        statusState === "success-update" ||
        statusState === "success-update-paid-status"
    ) {
      dispatch(getSingleSalesOrderWithDetail(SOData.id));
      // console.log("UPDATE SUCCESS !!!")

      setAlertProps({
        variant: "success",
        title: "Berhasil Mengupdate Data",
        content: " ",
      });
      setShowAlert(true);
      dispatch(getPendingNotification());
      dispatch(resetStatusPaymentConfirm());
    } else if (statusState === "failed-update") {
      setAlertProps({
        variant: "failed",
        title: "Gagal Mengupdate Data",
        content: error?.data?.message,
      });
      setShowAlert(true);
      dispatch(resetStatusPaymentConfirm());
    }
  }, [statusState, error]);

  // useEffect(() => {
  //   if (
  //     ShipmentData &&
  //     Object.keys(ShipmentData).length !== 0 &&
  //     ShipmentData.constructor == Object
  //   ) {
  //     setDeliverName(ShipmentData.deliveryName);
  //     setRecipientName(ShipmentData.recipientName);
  //     setRecipientPhone(ShipmentData.recipientPhone);
  //     setRecipientAddress(ShipmentData.recipientAddress);
  //     setRecipientPostalCode(ShipmentData.recipientPostalCode);
  //   }
  // }, [ShipmentData]);

  // useEffect(() => {
  //   if (SalesOrderDetailList.length !== 0) {
  //     // console.log(SalesOrderDetailList);
  //   }
  // }, [SalesOrderDetailList]);

  const columns = [
    {
      name: "SKU",
      selector: "inventorySKU",
      sortable: true,
    },
    {
      name: "PRODUCT",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIAN",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "PRICE",
      selector: (d: { price: number }) => (
        <NumberFormat
          value={d.price}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    // {
    //   name: "WEIGHT",
    //   selector: "weight",
    //   sortable: true,
    // },
    {
      name: "TOTAL PRICE",
      selector: (d: { price: number; quantity: number }) => (
        <NumberFormat
          value={d.price * d.quantity}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }, index: number) => (
        <>
          <span
            onClick={() => {
              if (salesOrderDetail.length === 1) {
                setAlertProps({
                  variant: "failed",
                  title: "Tidak bisa menghapus Produk.",
                  content: "",
                });
                setShowAlert(true);
              } else {
                setSelectedIndex(index);
                setDeleteAlertProps({
                  variant: "delete",
                  title: "Menghapus Data",
                  content: "Apakah kamu yakin akan menghapus data ini?",
                });
                setShowDeleteAlert(true);
              }
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  const onSaveUpdateClick = (printRequestStatus: number) => {
    Swal.fire({
      title: 'Apakah kamu yakin?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#f5b95d',
      icon: 'warning'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            updateStatus({
              status: 1,
              salesOrderId: SOData.id,
              approveBy: userState,
              approveDate: new Date().toISOString(),
              printRequest: printRequestStatus,
            })
        );
      }
    });
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">Order Detail</Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card style={{ border: 0 }}>
          <Card.Body>
            <div
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 16,
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              Order Data
            </div>
            <hr style={{ margin: 0 }} />
            <Row>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    SO Number
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {soNumber}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Order Type
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      :{" "}
                      {/* <span>
                        {orderType === 0 && <span> Dalam Proses</span>}
                        {orderType === 1 && <span> Bayar Ditempat</span>}
                        {orderType === 2 && <span> Belum Lunas</span>}
                        {orderType === 3 && <span> Bayar Cash</span>}
                        {orderType === 4 && <span> Dropship Belum Lunas</span>}
                        {orderType === 5 && <span> Dropship Lunas</span>}
                        {orderType === 6 && <span> Piutang</span>}
                        {orderType === 7 && (
                          <span> Tanpa Konfirmasi Pembayaran</span>
                        )}
                      </span> */}
                      <span>
                        {orderType === 0 && <span> Dalam Proses (KEEP)</span>}
                        {orderType === 1 && <span> Bayar Ditempat</span>}
                        {orderType === 2 && <span> Belum Lunas</span>}
                        {orderType === 3 && <span> Bayar Cash</span>}
                        {orderType === 4 && <span> Dropship</span>}
                        {orderType === 5 && <span> Dropship Lunas</span>}
                        {orderType === 6 && <span> Piutang</span>}
                        {orderType === 7 && (
                          <span> Tanpa Konfirmasi Pembayaran</span>
                        )}
                        {orderType === 8 && <span> Walk In</span>}
                        {orderType > 8 && <span> - </span>}
                      </span>
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr style={{ margin: 0 }} />

            <Row>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Customer
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {customerName}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Payment Status
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Label column sm={12}>
                      :
                      <span>
                        {paymentStatus === 0 && <span> Unpaid</span>}
                        {paymentStatus === 1 && <span> Paid</span>}
                      </span>
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr style={{ margin: 0 }} />

            <Row>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Email
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {email}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Payment Method
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {paymentMethodName}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr style={{ margin: 0 }} />

            <Row>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Order Date
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {orderDate}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Payment Type
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {paymentTypeName}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <hr style={{ margin: 0 }} />

            <Row>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Return Number
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {returNumber}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>

              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Third Party
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {thirdParty}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
              {/* <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Payment Type
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {paymentTypeName}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col> */}
            </Row>

            <hr style={{ margin: 0 }} />
            <Row>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Expedition
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {expedition}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Note
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {note}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <hr style={{ margin: 0 }} />
            <Row>
              <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Resi
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Label column sm={12}>
                      : {resi}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 40 }}>
          <Card.Body>
            <div
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 16,
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              Order Data
            </div>
            <hr />
            <Row>
              <Col md={7}>
                {SalesOrderWithDetailState?.salesOrder?.barcodeImage !== "" &&
                SalesOrderWithDetailState?.salesOrder?.barcodeImage !== null ? (
                  <>
                    <a
                      href={
                        window.location.origin +
                        "/barcode-image/print/" +
                        salesOrderID
                      }
                      target="_blank"
                    >
                      <Button
                        style={{ fontSize: 14, width: 202, height: 40 }}
                        variant="outline-info"
                        className={"mb-2"}
                        onClick={() => {}}
                      >
                        Barcode Image
                      </Button>
                    </a>{" "}
                  </>
                ) : (
                  <></>
                )}
                {shipmentStatus === 6 || shipmentStatus === 7 ? (
                  <>
                    {shipmentStatus === 6 ? (
                      <>
                        <a
                          href={
                            window.location.origin +
                            "/invoice/print/" +
                            salesOrderID
                          }
                          target="_blank"
                        >
                          <Button
                            style={{ fontSize: 14, width: 202, height: 40 }}
                            variant="outline-info"
                            className={"mb-2"}
                            onClick={() => {
                              dispatch(countSOPrint(salesOrderID));
                            }}
                          >
                            Print FORM A
                          </Button>
                        </a>{" "}
                      </>
                    ) : (
                      <></>
                    )}



                    {shipmentStatus === 7 ? (
                      <>
                        <a
                          href={
                            window.location.origin +
                            "/travel-document/print/" +
                            salesOrderID
                          }
                          target="blank"
                        >
                          <Button
                            style={{ fontSize: 14, width: 210, height: 40 }}
                            variant="outline-info"
                            className={"mb-2"}
                            onClick={() => {
                              dispatch(countSuratJalanPrint(salesOrderID));
                            }}
                          >
                            Surat Jalan
                          </Button>
                        </a>{" "}
                        {/* <a
                          href={
                            window.location.origin +
                            "/shipment/print/" +
                            salesOrderID
                          }
                          target="blank"
                        >
                          <Button
                            style={{ fontSize: 14, width: 210, height: 40 }}
                            variant="outline-info"
                            onClick={() => {
                              dispatch(countExpeditionPrint(salesOrderID));
                            }}
                          >
                            Print FORM B - DROPSHIPER
                          </Button>
                        </a>{" "} */}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {isPrintA !== null ? (
                      <>
                        {isPrintA ? (
                          <>
                            <a
                              href={
                                window.location.origin +
                                "/invoice/print/" +
                                salesOrderID
                              }
                              target="_blank"
                            >
                              <Button
                                style={{ fontSize: 14, width: 220, height: 40 }}
                                variant="outline-info"
                                className={"mb-2"}
                                onClick={() => {
                                  dispatch(countBackupPrint(salesOrderID));
                                }}
                              >
                                PRINT CADANGAN - FORM A
                              </Button>
                            </a>{" "}
                          </>
                        ) : (
                          <>
                            <a
                              href={
                                window.location.origin +
                                "/travel-document/print/" +
                                salesOrderID
                              }
                              target="blank"
                            >
                              <Button
                                style={{ fontSize: 14, width: 210, height: 40 }}
                                variant="outline-info"
                                className={"mb-2"}
                                onClick={() => {
                                  dispatch(countSuratJalanPrint(salesOrderID));
                                }}
                              >
                                Surat Jalan
                              </Button>
                            </a>{" "}
                            {/* <a
                              href={
                                window.location.origin +
                                "/shipment/print/" +
                                salesOrderID
                              }
                              target="blank"
                            >
                              <Button
                                style={{ fontSize: 14, width: 220, height: 40 }}
                                variant="outline-info"
                                onClick={() => {
                                  dispatch(countBackupPrint(salesOrderID));
                                }}
                              >
                                Print CADANGAN - FORM B
                              </Button>
                            </a>{" "} */}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {
                    SOData.paymentType === 0 && (
                        <>
                          <a
                              href={
                                  window.location.origin +
                                  "/invoice-cashless/print/" +
                                  salesOrderID
                              }
                              target="_blank"
                          >
                            <Button
                                style={{ fontSize: 14, width: 202, height: 40 }}
                                variant="outline-info"
                                className={"mb-2"}
                                onClick={() => {
                                  dispatch(countSOPrint(salesOrderID));
                                }}
                            >
                              Print Pesanan Cashless
                            </Button>
                          </a>{" "}
                        </>
                    )
                }
                <a
                  href={
                    window.location.origin +
                    "/warehouse-format/print/" +
                    salesOrderID
                  }
                  target="_blank"
                >
                  <Button
                    style={{ fontSize: 14, width: 202, height: 40 }}
                    variant="outline-info"
                    className={"mb-2"}
                    onClick={() => {
                      // dispatch(countSOPrint(salesOrderID));
                    }}
                  >
                    Warehouse Format
                  </Button>
                </a>{" "}
                {/* {partnerType === 1? (
                  <>
                    <a
                      href={
                        window.location.origin +
                        "/travel-document/print/" +
                        salesOrderID
                      }
                      target="blank"
                    >
                      <Button
                        style={{ fontSize: 14, width: 210, height: 40 }}
                        variant="outline-info"
                        // onClick={() => {
                        //   dispatch(countExpeditionPrint(salesOrderID));
                        // }}
                      >
                        Surat Jalan
                      </Button>
                    </a>{" "}
                  </>
                 ) : (
                  <></>
                )}  */}
                <Row>
                  <Col md={2}>
                    {invoicePrintCount > 0 || expeditionPrintCount > 0 ? (
                      <>
                        <Card.Subtitle
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 12,
                            lineHeight: 5,
                            width: 110,
                          }}
                          className="mb-3 text-muted "
                        >
                          Action Description :{" "}
                        </Card.Subtitle>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>

                  <Col style={{ marginTop: 15, marginLeft: 7 }}>
                    {invoicePrintCount >= 1 ? (
                      <>
                        <div
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 12,
                            color: "#00A8CC",
                          }}
                          className=""
                        >
                          * Already printed Invoice {invoicePrintCount} times.
                          (Last printed : {invoicePrintDate})
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {expeditionPrintCount >= 1 ? (
                      <>
                        <div
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 12,
                            color: "#00A8CC",
                          }}
                          className=""
                        >
                          * Already printed Shipping Data {expeditionPrintCount}{" "}
                          times.
                          (Last printed : {expeditionPrintDate})
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {backupPrintCount >= 1 ? (
                      <>
                        <div
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 12,
                            color: "#00A8CC",
                          }}
                          className=""
                        >
                          * Already printed CADANGAN Print {backupPrintCount}{" "}
                          times.
                          (Last printed : {cadanganPrintDate})
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {suratJalanPrintCount >= 1 ? (
                      <>
                        <div
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 12,
                            color: "#00A8CC",
                          }}
                          className=""
                        >
                          * Already printed SURAT JALAN Print{" "}
                          {suratJalanPrintCount} times.
                          (Last printed : {deliveryDocPrintDate})
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <div className="d-flex justify-content-end">
              {shipmentStatus <= 2 && transactionStatus !== 12 ? (
                <>
                    <Button
                      className="px-2 mx-1 mb-2"
                      style={{ fontSize: 14, width: 154, height: 40 }}
                      variant="danger"
                      onClick={() => {
                        setShowCancelAlert(true);
                      }}
                    >
                      Cancel Order
                    </Button>{" "}
                </>
              ) : (
                <></>
              )}
                  <>
                    {
                      SalesOrderWithDetailState.salesOrder?.status === 1 &&
                        <>
                          <Button
                              variant="info"
                              className="px-2 mx-1 mb-2"
                              style={{ fontSize: 14, width: 100, height: 40 }}
                              onClick={() => onSaveUpdateClick(6)}
                          >
                            Print A
                          </Button>
                          {" "}
                          <Button
                              variant="info"
                              className="px-2 mx-1 mb-2"
                              style={{ fontSize: 14, width: 130, height: 40 }}
                              onClick={() => onSaveUpdateClick(7)}
                          >
                            Print Surat Jalan
                          </Button>
                        </>
                    }
                  </>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 40 }}>
          <Row>
            <Col md={9}>
              <Form.Group>
                <Card.Title className="card-title input-padding">
                  Order Detail
                </Card.Title>
              </Form.Group>
            </Col>

            {/* <Col md={3}>
              <Button
                className="btn"
                style={{ marginTop: 20 }}
                variant="info"
                onClick={handleShow}
              >
                Add Produk
              </Button>
            </Col> */}
          </Row>

          <DataTable
            columns={columns}
            data={salesOrderDetail}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          />

          <hr />

          <Row className="m-2">
            <Col md={9}>
              <b>Discount</b>
            </Col>
            <Col className="float-right">
              <NumberFormat
                value={SalesOrderWithDetailState?.salesOrder?.discount}
                thousandSeparator={true}
                displayType="text"
                prefix="Rp. "
              />
            </Col>
          </Row>

          <Row className="m-2">
            <Col md={9}>
              <b>Voucher Discount</b>
            </Col>
            <Col className="float-right">
              <NumberFormat
                value={SalesOrderWithDetailState?.salesOrder?.shippingDiscount}
                thousandSeparator={true}
                displayType="text"
                prefix="Rp. "
              />
            </Col>
          </Row>

          <Row className="m-2">
            <Col md={9}>
              <b>Discount Membership</b>
            </Col>
            <Col className="float-right">
              <NumberFormat
                value={
                  SalesOrderWithDetailState?.salesOrder?.membershipDiscount
                }
                thousandSeparator={true}
                displayType="text"
                prefix="Rp. "
              />
            </Col>
          </Row>

          <Row className="m-2">
            <Col md={9}>
              <b>Discount Kontrak Stockist</b>
            </Col>
            <Col className="float-right">
              <NumberFormat
                value={
                  SalesOrderWithDetailState?.salesOrder
                    ?.contractStockistDiscount
                }
                thousandSeparator={true}
                displayType="text"
                prefix="Rp. "
              />
            </Col>
          </Row>

          <Row className="m-2">
            <Col md={9}>
              <b>Discount Stockist Ongkir</b>
            </Col>
            <Col className="float-right">
              <NumberFormat
                value={
                  SalesOrderWithDetailState?.salesOrder
                    ?.contractStockistFreeShippingDiscount
                }
                thousandSeparator={true}
                displayType="text"
                prefix="Rp. "
              />
            </Col>
          </Row>

          <Row className="m-2">
            <Col md={9}>
              <b>Discount Satuan / Serian</b>
            </Col>
            <Col className="float-right">
              <NumberFormat
                value={SalesOrderWithDetailState?.salesOrder?.contractDiscount}
                thousandSeparator={true}
                displayType="text"
                prefix="Rp. "
              />
            </Col>
          </Row>
        </Card>

        <Card style={{ marginTop: 40 }}>
          <Row>
            <Col md={9}>
              <Form.Group>
                <Card.Title className="card-title input-padding">
                  Shipping
                </Card.Title>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Button
                className="btn"
                style={{ marginTop: 20 }}
                variant="info"
                onClick={onEditShipmentClick}
              >
                Edit Shipment Detail
              </Button>
            </Col>
          </Row>
          <Table responsive="sm">
            <thead>
              <tr>
                <th>FROM</th>
                <th>TO</th>
                <th>RECIPENT'S PHONE</th>
                <th>ADDRESS</th>
                <th>POSTAL CODE</th>
                <th>EXPEDITION</th>
                <th>COST</th>
                <th>RESI</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{senderName}</td>
                <td>{recipientName}</td>
                <td>{recipientPhone}</td>
                <td>
                  {SalesOrderWithDetailState?.salesOrder?.statusOrder === 0 &&
                  (recipientAddress === null || recipientAddress === "")
                    ? SalesOrderWithDetailState?.shipment?.cashlessAddress
                    : recipientAddress}
                </td>
                <td>{recipientPostalCode}</td>
                <td>{expedition}</td>
                <td>
                  <NumberFormat
                    value={totalShippingCost}
                    thousandSeparator={true}
                    displayType="text"
                    prefix="Rp. "
                  />{" "}
                </td>
                <td>{resi}</td>
              </tr>
            </tbody>
          </Table>
        </Card>

        {/* <Button style={{ marginTop: 20 }} variant="info" onClick={onSave}>
          Save
        </Button> */}
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Product</Form.Label>
              <Form.Control as="select" onChange={onProductChange}>
                <option>select</option>
                {productState.map((Item) => {
                  return (
                    <option
                      value={
                        Item.id + "&" + Item.name + "&" + Item.inventorySKU
                      }
                    >
                      {Item.inventorySKU} - {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Variant</Form.Label>
              <Form.Control as="select" onChange={onVariationChange}>
                <option>select</option>
                {variationState.map((Item) => {
                  console.log(variationState);

                  let variationName = "";

                  if (Item.customProductAttributeList.length === 1) {
                    variationName += Item.customProductAttributeList[0].name;
                  } else if (Item.customProductAttributeList.length === 2) {
                    variationName +=
                      Item.customProductAttributeList[0].name +
                      "-" +
                      Item.customProductAttributeList[1].name;
                  } else if (Item.customProductAttributeList.length === 3) {
                    variationName +=
                      Item.customProductAttributeList[0].name +
                      "-" +
                      Item.customProductAttributeList[1].name +
                      "-" +
                      Item.customProductAttributeList[2].name;
                  }

                  return (
                    <option
                      value={
                        Item.id +
                        "&" +
                        variationName +
                        "&" +
                        Item.regulerPriceEndCustomer +
                        "&" +
                        Item.regulerPriceStockist +
                        "&" +
                        Item.regulerPriceReseller +
                        "&" +
                        Item.stockQty +
                        "&" +
                        Item.weight
                      }
                    >
                      {Item.customProductAttributeList.length >= 1
                        ? Item.customProductAttributeList[0].name
                        : ""}
                      {Item.customProductAttributeList.length >= 2
                        ? " - " + Item.customProductAttributeList[1].name
                        : ""}
                      {Item.customProductAttributeList.length == 3
                        ? " - " + Item.customProductAttributeList[2].name
                        : ""}

                      {" (" + Item.stockQty + ")"}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Qty</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                value={selectedProductQuantity}
                onChange={(e) => {
                  if (parseInt(e.target.value) > selectedProductStock) {
                    setSelectedProductQuantity(selectedProductStock);
                  } else {
                    setSelectedProductQuantity(parseInt(e.target.value));
                  }
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Catatan</Form.Label>
              <Form.Control
                type="text"
                name="note"
                onChange={(e) => setSelectedProductNote(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={addSalesOrderDetail}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showShipping}
        onHide={handleCloseShipment}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pengiriman</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Dari</Form.Label>
              <Form.Control
                type="text"
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Kepada</Form.Label>
              <Form.Control
                type="text"
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Alamat Penerima</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                rows={5}
                value={
                  SalesOrderWithDetailState?.salesOrder?.statusOrder === 0 &&
                  (recipientAddress === null || recipientAddress === "")
                    ? SalesOrderWithDetailState?.shipment?.cashlessAddress
                    : recipientAddress
                }
                onChange={(e) => setRecipientAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Kode Pos</Form.Label>
              <Form.Control
                type="text"
                value={recipientPostalCode}
                onChange={(e) => setRecipientPostalCode(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Telepon Penerima</Form.Label>
              <Form.Control
                type="text"
                value={recipientPhone}
                onChange={(e) => setRecipientPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {paymentTypeName === "Cashless" ? (
              <>
                <Form.Group>
                  <Form.Label>Ekspedisi</Form.Label>
                  <Form.Control
                    type="text"
                    value={cashlessExpedition}
                    onChange={(e) => setCashlessExpedition(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group>
                  <Form.Label>Provinsi</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={onProvinceChange}
                    value={recipientProvince}
                  >
                    <option>select</option>
                    {provinceState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Kota</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={onCityChange}
                    value={recipientCity}
                  >
                    <option>select</option>
                    {cityState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Kecamatan</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={onDistrictChange}
                    value={recipientSubDistrict}
                  >
                    <option>select</option>
                    {districtState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Ekspedisi</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={onExpeditionChange}
                    value={selectedExpedition}
                  >
                    <option>Pilih Ekspedisi</option>
                    {expeditionState.map((Item) => {
                      return (
                        <option value={Item.expeditionId}>{Item.name}</option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Tipe Tarif</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={onExpeditionServiceChange}
                    value={selectedServiceTypeTemp}
                  >
                    {expeditionServiceList.map((Item) => {
                      return (
                        <option value={Item.service + "&" + Item.cost[0].value}>
                          {Item.service} - {Item.description} - Rp.
                          {Item.cost[0].value}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </>
            )}

            <Form.Group>
              <Form.Label>Nomor Resi</Form.Label>
              <Form.Control
                type="text"
                value={receiptNumber}
                onChange={(e) => setReceiptNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Total Ongkos Kirim</Form.Label>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={totalShippingCost}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setTotalShippingCost(val);
                }}
              />
            </Form.Group>

            {/* /////////////////////////////////////////////////////////////// */}

            {/* <Form.Group>
              <Form.Label>Total Berat</Form.Label>
              <Form.Control
                type="text"
                value={totalWeight}
                disabled
                // onChange={(e) => setRecipientName(e.target.value)}
              ></Form.Control>
            </Form.Group> */}

            {/* <Form.Group>
              {isManualShippingCost ? (
                <>
                  <Form.Label>Total Ongkos Kirim</Form.Label>
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    className="form-control"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={totalShippingCost}
                    onValueChange={({ floatValue }) => {
                      let val = floatValue ?? 0;
                      setTotalShippingCost(val);
                    }}
                  
                  />
                </>
              ) : (
                <>
                  <Form.Label>Total Ongkos Kirim</Form.Label>
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    className="form-control"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={totalShippingCost}
                    disabled
                  />
                </>
              )}

              <Col>
                <Form.Check
                  className="radio-button"
                  label="Gratis Ongkir"
                  name="isFreeShipment"
                  checked={isFreeShippingCost}
                  onChange={(e) => {
                    let filteredService = expeditionState.filter(
                      (item) => item.expeditionId === selectedExpedition
                    );
                    if (e.target.checked) {
                      setTotalShippingCost(0);
                      setIsManualShippingCost(false);
                    } else {
                      setTotalShippingCost(
                       
                        filteredService[0].costs[0].cost[0].value
                      );
                    }
                    setIsFreeShippingCost(e.target.checked);
                  }}
                />
              </Col>
              <Col>
                <Form.Check
                  className="radio-button"
                  label="Harga Manual"
                  name="isManualShipment"
                  checked={isManualShippingCost}
                  onChange={(e) => {
                    let filteredService = expeditionState.filter(
                      (item) => item.expeditionId === selectedExpedition
                    );
                    if (e.target.checked) {
                      setIsFreeShippingCost(false);
                    } else {
                      setTotalShippingCost(
                        filteredService[0].costs[0].cost[0].value
                      );
                    }
                    setIsManualShippingCost(e.target.checked);
                  }}
                />
              </Col>
            </Form.Group> */}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={handleCloseShipment}
          >
            Close
          </Button>

          <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={onSaveDeliveryData}
          >
            Save
          </Button>

          {/* <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={addSalesOrderDetail}>
            Save
          </Button> */}
        </Modal.Footer>
      </Modal>

      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDelete}
      />

      <DeleteMessage
        {...cancelAlertProps}
        show={showCancelAlert}
        onAlertCloseAction={onCancelAlertClose}
        onDeleteAction={onCancel}
      />

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </div>
  );
};

export default ListOrderDetail;
