import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PaymentAllocationTable from "../components/paymentAllocationTable";
const PaymentAllocationPage = () => {

  

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Payment Allocation</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/payment-allocation/create",
            }}
          >
            <Button
              className="float-right"
              style={{ width: 230 }}
              variant="info"
            >
              + Add Payment Allocation
            </Button>
          </Link>
        </Col>
      </Row>

      <PaymentAllocationTable />
    </Container>
  )
}

export default PaymentAllocationPage