import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import SettingFaqForm from "../components/settingFaqForm";
import { RootState } from "../../../../app/store";
import { FaqModel } from "../settingFaq.model";
import {
  createFaq,
  getFaqList,
  resetSingle,
  updateFaq,
} from "../settingFaq.reducer";
import AlertMessage from "../../../../components/alertMessage";

const SettingFaqPage = () => {
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const FaqStatusState = useSelector(
    (state: RootState) => state.settingFaq.status ?? ""
  );

  const FaqListState = useSelector(
    (state: RootState) => state.settingFaq.faqList ?? []
  );

  const onAlertClose = () => {
    setShowAlert(false);
    if (FaqStatusState === "success" || FaqStatusState === "success-update") {
      dispatch(getFaqList());
      dispatch(resetSingle());
    }
  };

  useEffect(() => {
    if (FaqStatusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil Menambahkan FAQ Baru",
        content: "",
      });
      setShowAlert(true);
    } else if (FaqStatusState === "success-update") {
      setAlertProps({
        variant: "success",
        title: "Berhasil Update Data FAQ",
        content: "",
      });
      setShowAlert(true);
    }
  }, [FaqStatusState]);

  useEffect(() => {
    dispatch(getFaqList());
  }, []);

  const onSubmit = (
    title: string,
    contentFAQ: string,
    type: number,
    isEdit: boolean,
    id: string
  ) => {
    if (!isEdit) {
      dispatch(
        createFaq({
          title,
          contentFAQ,
          type,
        } as FaqModel)
      );
    } else {
      dispatch(
        updateFaq({
          id,
          title,
          contentFAQ,
          type,
        } as FaqModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label className="title">FAQ</Form.Label>
              </Form.Group>

              <Card>
                <Card.Body>
                  <Tabs>
                    <Tab eventKey="reseller" title="Reseller">
                      <TabContent>
                        <SettingFaqForm onSubmit={onSubmit} partnerType={2} />
                      </TabContent>
                    </Tab>

                    <Tab eventKey="stockist" title="Stockist">
                      <TabContent>
                        <SettingFaqForm onSubmit={onSubmit} partnerType={1} />
                      </TabContent>
                    </Tab>

                    <Tab eventKey="customer" title="End Customer">
                      <TabContent>
                        <SettingFaqForm onSubmit={onSubmit} partnerType={0} />
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    </Container>
  );
};

export default SettingFaqPage;
