import { getProfileSingleItem, updateProfile } from "../profile.reducer";
import SettingProfileForm from "../components/SettingProfileForm";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";
import { ProfileModel } from "../profile.model";
import { RootState } from "../../../app/store";
import { useEffect } from "react";

const SettingProfilePage = () => {
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.user.id);

  const singleDataState = useSelector(
    (state: RootState) => state.profile.single ?? ({} as ProfileModel)
  );

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getProfileSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    name: string,
    username: string,
    email: string,
    pincode: string,
    password: string,
    confirmPassword: string
  ) => {
    dispatch(
      updateProfile({
        id,
        name,
        username,
        email,
        pincode,
        password,
        confirmPassword,
      } as ProfileModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="menu-title">Profile</Form.Label>
      </Form.Group>

      <SettingProfileForm onSubmit={onSubmit} data={singleDataState} />
    </Container>
  );
};

export default SettingProfilePage;
