import React, { useEffect, useState, useRef } from 'react'
import '../laporanPerubahanStock.scss'
import { Container, Form, Button } from "react-bootstrap";
import { FiEdit, FiPlus, FiPrinter, FiTrash } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { deleteSlideShow, getSlideShow } from '../slideShow.api';
// import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaFileExport, FaFilePdf } from 'react-icons/fa';
import { BsFillEyeFill } from 'react-icons/bs';
import { RootState } from '../../../../app/store';
import { getAllUserFilterPerubahanStock, postExportPerubahanStock, postLaporanPerubahanStock } from '../laporanPerubahanStock.api';
import moment from 'moment';
import { DatePicker, Space } from 'antd';
import Pagination from "react-js-pagination";
import ReactToPrint from 'react-to-print';


export default function LaporanPerubahanStock() {
    // const history = useHistory()
    const dispatch = useDispatch()
    const tableRef = useRef(null);

    const {listUserFilterPerubahanStock} = useSelector((state:RootState) => state.laporanPerubahanStock)
    useEffect(() => {
        dispatch(getAllUserFilterPerubahanStock())
    }, [])

    const { RangePicker } = DatePicker;
    // const history = useHistory()
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)


    const initialValues = {
        "reportType": '2',
        "filterMonthly": moment(new Date()).format('YYYY-MM'),
        "startFilterDate":'',
        "endFilterDate":'',
        "user": "",
        "skip": 0,
        "take": '10',
        page : 1,
    }

    const [values, setValues] = useState({
        "reportType": '2',
        "filterMonthly": moment(new Date()).format('YYYY-MM'),
        "startFilterDate":'',
        "endFilterDate":'',
        "user": "",
        "skip": 0,
        "take": '10',
        page : 1,
    })

    useEffect(() => {
        getData()
        console.log(list)
    }, [values.page, values.take, values.skip])

    const handlePerPage = (e:any)=>{
        setValues({
            ...values,
            take : e.target.value,
            skip: 0,
            page : 1
        })
    }

    const handleRangePicker = (event:any, picker:any)=>{
        setValues({
            ...values,
            startFilterDate : picker[0],
            endFilterDate : picker[1],
        })
    }

    const handleChange = (e:any)=>{
        setValues({
            ...values,
            [e.target.name] : e.target.value,
        })
        console.log(e.target.name)
        console.log(e.target.value)
    }

    const handleReset = ()=>{
        setValues(initialValues)
    }

    const nameFile = ()=>{
        if (values.reportType === '0') {
            return `${moment(new Date(values.startFilterDate)).format('DD-MMM-YYYY')} - ${moment(new Date(values.endFilterDate)).format('DD-MMM-YYYY')}`
        }else{
            return `${moment(new Date(values.filterMonthly)).format('DD-MMM-YYYY')}`
        }
    }

    const handlePagination = (page:any) => {
        setValues({
            ...values,
           skip : parseInt(values.take) * (page - 1),
           page : page
        })
    }

    const handleExportExcel = (e:any)=>{
        const data = {
            "reportType": values.reportType === 'null'? null : parseInt(values.reportType),
            "filterMonthly":values.filterMonthly.length !== 0 ? new Date(values.filterMonthly).toISOString(): new Date().toISOString(),
            "startFilterDate": values.startFilterDate === "" ? new Date().toISOString()  :  new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? new Date().toISOString() :  new Date(values.endFilterDate).toISOString(),
            "user" : values.user,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        console.log(data)
        dispatch<any>(postExportPerubahanStock(data))
        .then(unwrapResult)
        .then((res:any)=>{
            console.log(res)
            const url = URL.createObjectURL(new Blob([res]));
            console.log(url)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Laporan Perubahan Stock - ${nameFile()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((err:any)=> console.log(err))
    }

    const handleSubmit = (e:any)=>{
        e.preventDefault()
        setValues({
            ...values,
            "skip": 0,
            page : 1,
        })
        getData()
        // console.log(values)
    }

    const getData = ()=>{
        const data = {
            "reportType": values.reportType === 'null'? null : parseInt(values.reportType),
            "filterMonthly":values.filterMonthly.length !== 0 ? new Date(values.filterMonthly).toISOString(): new Date().toISOString(),
            "startFilterDate": values.startFilterDate === "" ? new Date().toISOString()  :  new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? new Date().toISOString() :  new Date(values.endFilterDate).toISOString(),
            "user" : values.user,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        console.log(data)
        dispatch<any>(postLaporanPerubahanStock(data))
        .then(unwrapResult)
        .then((res:any)=>{
            console.log(res.data)
            setList(res.data.list)
            setTotal(res.data.total)
        })
        .catch((err:any)=> console.log(err))
    }

    return (
        <Container className="content-body laporanPerubahanStock" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
            Laporan Perubahan Stock
                <div>
                    <Button className='addor' onClick={handleExportExcel}><FaFileExport/> Export to Excel</Button>
                    <ReactToPrint
                        trigger={() =>  <Button className='addor'><FiPrinter/> Print</Button>}
                        content={() => tableRef.current}
                    />
                     <ReactToPrint
                        trigger={() =>  <Button className='addor'><FaFilePdf/> Export to PDF</Button>}
                        content={() => tableRef.current}
                    />
                </div>
            </div>
            <div className="box">
                <div className="head w-100">
                    <form onSubmit={handleSubmit}>
                        <div className="row w-100">
                            <div className="col-md-2 ">
                                <label htmlFor="title">Waktu</label>
                            </div>
                            <div className="col-md-4 ">
                                <div className="d-flex align-items-center mb-3 justify-content-between">
                                    <Form.Check 
                                        className='radiocustom m-0 '
                                        style={{marginBottom : 13}}
                                        inline
                                        type='radio'
                                        id='user4'
                                        label='Semua'
                                        name='reportType'
                                        checked={values.reportType === '2'}
                                        value={'2'}
                                        onClick={handleChange}
                                    />
                                </div>
                                <div className="d-flex align-items-center mb-3 justify-content-between">
                                    <Form.Check 
                                        className='radiocustom m-0 '
                                        style={{marginBottom : 13}}
                                        inline
                                        type='radio'
                                        id='user4'
                                        label='Tanggal'
                                        name='reportType'
                                        checked={values.reportType === '0'}
                                        value={'0'}
                                        onClick={handleChange}
                                    />
                                    <Space direction="vertical" size={12}>
                                        <RangePicker 
                                        className='custext'
                                        onChange={handleRangePicker}
                                        value={ values.startFilterDate !== "" && values.endFilterDate !== "" ?[moment(values.startFilterDate, 'YYYY-MM-DD'), moment(values.endFilterDate, 'YYYY-MM-DD')] : undefined}
                                        format="YYYY-MM-DD"
                                        />
                                    </Space>
                                </div>
                                <div className="d-flex align-items-center mb-3 justify-content-between">
                                    <Form.Check 
                                        className='radiocustom m-0 '
                                        style={{marginBottom : 13}}
                                        inline
                                        type='radio'
                                        id='user4'
                                        label='Bulan'
                                        name='reportType'
                                        checked={values.reportType === '1'}
                                        value={'1'}
                                        onClick={handleChange}
                                    />
                                    <input className='custext' type="month" name="filterMonthly" value={values.filterMonthly} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6"></div>

                            {/* <div className="col-md-2 mb-3 ">
                                <label htmlFor="title">Customer</label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <select className="custom-select w-100 cus-sel left" placeholder='Select'>
                                    <option value="">Select</option>
                                    <option value="1">Forever</option>
                                    <option value="2">Range Date</option>
                                </select>
                            </div> */}
                            {/* <div className="col-md-6"></div> */}

                            <div className="col-md-2 mb-3 ">
                                <label htmlFor="title">User</label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <select className="custom-select w-100 cus-sel left" placeholder='Select' name='user' value={values.user} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {
                                        listUserFilterPerubahanStock.map((res:any,i:number)=>
                                        <option value={res.id}>{res.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-md-6"></div>                            


                            {/* <div className="col-md-2 mb-3 ">
                                <label htmlFor="title">User Approve</label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <select className="custom-select w-100 cus-sel left" placeholder='Select'>
                                    <option value="">Select</option>
                                    <option value="1">Forever</option>
                                    <option value="2">Range Date</option>
                                </select>
                            </div> */}

                            <div className="wrapbutfil col-md-12">
                                <Button className='addor' type='button' onClick={handleReset}>Reset</Button>
                                <Button className='addorrev' type='submit'>Filter</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="con">
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing {list.length} of {total} results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span> 
                            <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                    <div style={{overflowX : 'auto'}} >
                        <table className="table" ref={tableRef}>
                            <thead className="">
                                <tr>
                                    {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                    <th scope="col">No</th>
                                    <th scope="col">Waktu Perubahan</th>
                                    <th scope="col">Kode Produk</th>
                                    <th scope="col">Produk</th>
                                    <th scope="col">Varian</th>
                                    <th scope="col">QTY In</th>
                                    <th scope="col">QTY Out</th>
                                    <th scope="col">QTY Akhir</th>
                                    <th scope="col">User</th>
                                    <th scope="col">Catatan</th>
                                    {/* <th scope="col">User Approve</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((res:any, i:number)=>(
                                        <tr key={i}>
                                            {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                            <td>
                                            {
                                                values.skip === 0?
                                                i + 1 
                                                :
                                                i + 1 + (values.page -1) * parseInt(values.take)
                                            }
                                            </td>
                                            <td>{moment(res.createdDate).format('DD/MM/YYYY HH:MM:SS')}</td>
                                            <td>{res.productCode}</td>
                                            <td>{res.productName}</td>
                                            <td>{res.variantName}</td>
                                            <td>{res.startQty}</td>
                                            <td>{res.updateQty}</td>
                                            <td>{res.endQty}</td>
                                            <td>{res.user}</td>
                                            <td>{res.note}</td>
                                            {/* <td>-</td> */}

                                        </tr>
                                    ))
                                }
                                
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-end">
                    <Pagination
                        activePage={values.page}
                        itemClass='item'
                        itemsCountPerPage={parseInt(values.take)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={handlePagination}
                        prevPageText='⟨ PREVIOUS'
                        itemClassPrev='prev'
                        itemClassNext='next'
                        nextPageText='NEXT ⟩'
                        hideFirstLastPages
                    />
                    </div>

                </div>
            </div>
        </Container>
    )
}
