import axios from "axios";
import { endpoint } from "../../../constants";
import { ContractApprovalRequestModel, ContractCategoryModel, ContractModel, FilterModel, formValuesModel } from "./contract.model";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

export default class Api {
  static createData(model: ContractModel) {
    return axios
      .post<any>(endpoint + "/api/ContractStockist/Create", model)
      .then((res) => res.data);
  }

  static getList() {
    return axios.get<any>(endpoint + "/api/ContractStockist/GetList").then((res) => res.data.data);
  }

  static getListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/ContractStockist/getListWithPaging",model)
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/ContractStockist/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: ContractModel) {
    return axios
      .post<any>(endpoint + "/api/ContractStockist/update", model)
      .then((res) => res.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/ContractStockist/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static createContractDiscout(model: formValuesModel[]) {
    return axios
      .post<any>(endpoint + "/api/ContractDiscount/Create", model)
      .then((res) => res.data);
  }

  static getListContractDiscountSatuan(id: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/ContractDiscount/GetListByContractStockist?contractStockistId=" +
          id +
          "&nominalDiscount=" +
          0
      )
      .then((res) => res.data.data);
  }

  static getListContractDiscountSerian(id: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/ContractDiscount/GetListByContractStockist?contractStockistId=" +
          id +
          "&nominalDiscount=" +
          1
      )
      .then((res) => res.data.data);
  }

  static createContractCategory(model: ContractCategoryModel) {
    return axios
      .post<any>(endpoint + "/api/ContractCategory/Create", model)
      .then((res) => res.data);
  }

  static getListContractCategory(id: string) {
    return axios
      .get<any>(endpoint + "/api/ContractCategory/GetListByStockist?contractStockistId=" + id)
      .then((res) => res.data.data);
  }

  static getAdminListByStockist(partnerId: string) {
    return axios
      .get<any>(endpoint + "/api/AdminStockist/GetListByStockist?partnerId=" + partnerId)
      .then((res) => res.data.data);
  }

  
  static getAdminList() {
    return axios
      .get<any>(endpoint + "/api/User/GetUserAdmin")
      .then((res) => res.data.data);
  }

  static contractApprovalRequset(model: ContractApprovalRequestModel) {
    return axios
      .post<any>(endpoint + "/api/ContractStockist/ChangeStatus", model)
      .then((res) => res.data.data);
  }
}
