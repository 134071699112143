import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../constants'

export const postMembershipWithPaging = createAsyncThunk('post/MembershipTransaction/withPaging', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/MembershipTransaction/GetListWithPaging`, body)
        console.log(response)
        return response.data

    }
    catch (error: any) {
        return error.response
    }
})

export const getMembershipTransDetail = createAsyncThunk('get/getMembershipTransDetail', async (id: string) => {
    try {
        const response = await axios.get(`${endpoint}/api/MembershipTransaction/GetSingleItem`, {
            params: {
                id: id
            }
        })
        // console.log(response.data.data, 'detail')
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postDeleteMembership = createAsyncThunk('post/postDeleteMembership', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/MembershipTransaction/Delete`, body, {
            params: {
                id: body.id
            }
        })
        // console.log(response.data.data, 'detail')
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postChangeStatusMembership = createAsyncThunk('post/postChangeStatusMembership', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/MembershipTransaction/ChangeStatus`, body)
        // console.log(response.data.data, 'detail')
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})



export const postExportMembershipTransaction = createAsyncThunk('post/MembershipTransactionExportToExcel', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/MembershipTransaction/MembershipTransactionExportToExcel`,
            data: body,
            method: 'post',
            responseType: 'blob',
        })
        console.log(response)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})
