import "../contract.style.css";
import { Button, Card, Modal, Form, Col, Row } from "react-bootstrap";
import DeleteMessage from "../../../../components/DeleteMessage";
import hapus from "./../../../../assets/vector/delete.png";
import { formValuesModel } from "../contract.model";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

const ContractSerianTable = ({
  isEdit = false,
  ...props
}: ContractSerianProps) => {
  const contractDiscountState = useSelector(
    (state: RootState) => state.contract.listContractDiscountSerian ?? []
  );

  //modal state
  const [show, setShow] = useState(false);
  //form value
  const [minNumber, setMinNumber] = useState(0);
  const [idr, setIdr] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [upto, setUpto] = useState(0);
  const [type, setType] = useState(0);
  const [serian, setSerian] = useState<formValuesModel[]>([]);
  //alert props
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const columns = [
    {
      name: "SERIAN",
      selector: (d: { minNumber: number }) => (
        <NumberFormat
          value={d.minNumber}
          displayType="text"
          // thousandSeparator={true}
          // prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "IDR",
      selector: (d: { idr: number }) => (
        <NumberFormat
          value={d.idr}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "PERCENTAGE",
      selector: (d: { percentage: number }) => (
        <NumberFormat value={d.percentage} displayType="text" suffix=" %" />
      ),
      sortable: true,
    },
    {
      name: "UP TO",
      selector: (d: { upto: number }) => (
        <NumberFormat
          value={d.upto}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }, index: number) => (
        <>
          <span
            onClick={() => {
              setSelectedIndex(index);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}>
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    if(contractDiscountState.length !== 0){
      setSerian(contractDiscountState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractDiscountState]);

  useEffect(() => {
    props.onSubmitData(serian);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serian]);

  const onSaveBtnClick = () => {
    setSerian((prevState) => [
      ...prevState,
      {
        contractStockistId: "",
        minNumber,
        nominalDiscount: 1,
        idr,
        percentage,
        upto,
        type,
      } as formValuesModel,
    ]);

    setMinNumber(0);
    setIdr(0);
    setPercentage(0);
    setUpto(0);
    setType(0);
    setShow(false);
  };

  const onDelete = () => {
    let newList = [...serian];
    newList.splice(selectedIndex, 1);
    setSerian(newList);
    setShowAlert(false);
  };

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const onAlertClose = () => setShowAlert(false);

  return (
    <div>
      <Card>
        <Button
          style={{ width: 125, height: 40, margin: 10, borderRadius: 5 }}
          variant="info"
          type="button"
          onClick={handleShow}>
          Add Serian
        </Button>

        <DataTable
          columns={columns}
          data={serian}
          pagination
          noHeader
        />
      </Card>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-custom-style"
        centered
        show={show}
        onHide={handleClose}>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Serian
              </Form.Label>
              <Col sm={8}>
                <NumberFormat
                  // thousandSeparator={"."}
                  // decimalSeparator={","}
                  className="form-control"
                  inputMode="numeric"
                  // prefix="Rp. "
                  value={minNumber}
                  onValueChange={({ floatValue }) => {
                    let val = floatValue ?? 0;
                    setMinNumber(val);
                  }}
                />

                {/* <Form.Control
                  type="number"
                  onChange={(e) => setMinNumber(parseInt(e.target.value))}
                  value={minNumber}
                /> */}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Nominal Discount
              </Form.Label>
              <Col sm={8} style={{ paddingTop: 8 }}>
                <Form.Check
                  inline
                  label="IDR"
                  name="type"
                  type="radio"
                  checked={type === 0}
                  value={0}
                  onChange={() => {
                    setType(0);
                    setPercentage(0);
                    setUpto(0);
                  }}
                />
                <Form.Check
                  inline
                  label="Percentage"
                  name="type"
                  type="radio"
                  checked={type === 1}
                  value={1}
                  onChange={() => {
                    setType(1);
                    setIdr(0);
                  }}
                />
              </Col>
            </Form.Group>
            {type === 0 ? (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4} />
                <Col sm={8}>
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    className="form-control"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={idr}
                    onValueChange={({ floatValue }) => {
                      let val = floatValue ?? 0;
                      setIdr(val);
                    }}
                  />
                </Col>
              </Form.Group>
            ) : (
              <>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={4} />
                  <Col sm={8}>
                    <NumberFormat
                      decimalSeparator={","}
                      className="form-control"
                      inputMode="numeric"
                      suffix=" %"
                      value={percentage}
                      onValueChange={({ floatValue }) => {
                        let val = floatValue ?? 0;
                        setPercentage(val);
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={4} />
                  <Col sm={8}>
                    <NumberFormat
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      className="form-control"
                      inputMode="numeric"
                      prefix="Rp. "
                      value={upto}
                      onValueChange={({ floatValue }) => {
                        let val = floatValue ?? 0;
                        setUpto(val);
                      }}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 125, height: 40 }}
            onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="info"
            type="submit"
            style={{ width: 125, height: 40 }}
            onClick={onSaveBtnClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      />
    </div>
  );
};

export interface ContractSerianProps {
  onSubmitData: (inventoryMoveDetail: formValuesModel[]) => void;
  isEdit: boolean;
}

export default ContractSerianTable;
