import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getFormatNota = createAsyncThunk('get/getFormatNota', async () => {
    const response = await axios.get(`${endpoint}/api/NoteFormatSetting/GetList`)
    console.log(response)
    return response.data
})

export const getFormatNotaDetail = createAsyncThunk('get/getFormatNotaDetail', async (id: string) => {
    try {
        const response = await axios.get(`${endpoint}/api/NoteFormatSetting/GetSingleItem`, {
            params: {
                id: id
            }
        })
        // console.log(response.data.data, 'detail')
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})


export const postImageUpload = createAsyncThunk('upload/imagesConvert', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/web/api/Upload/UploadImage`, body)
        return response
    }
    catch (error: any) {
        return error.response
    }
})

export const updateFormatNota = createAsyncThunk('api/update/updateFormatNota', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/NoteFormatSetting/Update`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

