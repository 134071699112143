import React from "react";
import { Card, Form, Col, Row, Button, Modal } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { Grid } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  changeWalletStatus,
  createWalletRequest,
  getWalletRequestListWithPaging,
  resetStatus,
} from "../wallet.reducer";
import { PartnerOptionModel } from "../../salesOrder/salesOrder/salesOrder.model";
import { getListPartner } from "../../salesOrder/salesOrder/salesOrder.reducer";
import AlertMessage from "../../../components/alertMessage";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const WalletTable = () => {
  const dispatch = useDispatch();

  const WalletListState = useSelector(
    (state: RootState) => state.wallet.list ?? []
  );

  const TotalRowState = useSelector(
    (state: RootState) => state.wallet.totalRow ?? 0
  );

  const WalletStatusState = useSelector(
    (state: RootState) => state.wallet.status ?? ""
  );

  const partnerListState = useSelector(
    (state: RootState) => state.salesOrder.partnerList ?? []
  );

  const userState = useSelector((state: RootState) => state.user ?? {});

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //filter
  const [filterStartCreateDate, setFilterStartCreateDate] = useState("");
  const [filterEndCreateDate, setFilterEndCreateDate] = useState("");
  const [filterStartApprovalDate, setFilterStartApprovalDate] = useState("");
  const [filterEndApprovalDate, setFilterEndApprovalDate] = useState("");
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const [filterPartnerId, setFilterPartnerId] = useState<string | null>(null);
  const [filterPartnerType, setFilterPartnerType] = useState<number | null>(
    null
  );
  const [filterTempPartnerType, setFilterTempPartnerType] = useState(3);
  const [filterTempStatus, setFilterTempStatus] = useState(3);

  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);
  const [selected, setSelected] = useState<PartnerOptionModel[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<PartnerOptionModel[]>(
    []
  );
  const [partnerType, setPartnerType] = useState(0);

  const [partnerId, setPartnerId] = useState("");
  const [note, setNote] = useState("");
  const [nominal, setNominal] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    // dispatch(resetStatus());
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleChangeStatus = (status: number, id: string) => {
    dispatch(
      changeWalletStatus({
        status: status,
        id: id,
      })
    );
  };

  const onSaveClick = () => {
    setShowModal(false);
    dispatch(
      createWalletRequest({
        partnerId: partnerId,
        status: 0,
        nominal: nominal,
        note: note,
      })
    );
  };

  const onSelectedPartnerFilterChange = (data: any) => {
    setFilterPartnerId(data.value);
    setSelected(data);
  };

  const onSelectedPartnerNewWalletChange = (data: any) => {
    setSelectedPartner(data);
    setPartnerId(data.value);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterStartCreateDate("");
    setFilterEndCreateDate("");
    setFilterStartApprovalDate("");
    setFilterEndApprovalDate("");
    setFilterStatus(null);
    setFilterPartnerId(null);
    setFilterPartnerType(null);
    setFilterTempPartnerType(3);
    setFilterTempStatus(3);

    // setPartnerList((currentOptions) => currentOptions.filter((currentOption) => !selected.includes(currentOption)));
    setSelected([]);
    setSelectedPartner([]);

    setNominal(0);
    setNote("");

    dispatch(
      getWalletRequestListWithPaging({
        startCreatedDate: "",
        endCreatedDate: "",
        startApprovalDate: "",
        endApprovalDate: "",
        status: null,
        partnerId: null,
        partnerType: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);
    dispatch(
      getWalletRequestListWithPaging({
        startCreatedDate: filterStartCreateDate,
        endCreatedDate: filterEndCreateDate,
        startApprovalDate: filterStartApprovalDate,
        endApprovalDate: filterEndApprovalDate,
        status: filterStatus,
        partnerId: filterPartnerId,
        partnerType: filterPartnerType,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [WalletListState]);

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getListPartner());
  }, []);

  useEffect(() => {
    if (partnerListState.length !== 0) {
      setPartnerList([]);
      partnerListState.map((item) => {

        if(item.type === partnerType){
          setPartnerList((prevState) => [
            ...prevState,
            {
              value: item.id,
              label: item.fullName,
              type: item.type,
            },
          ]);
        }

        
      });
    }
  }, [partnerListState, partnerType]);

  useEffect(() => {
    if (WalletStatusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil Menambahkan Data",
        content: " ",
      });
      setShowAlert(true);
      dispatch(resetStatus());
      onFilterResetClick();
      fetchData(0, perPage);
    } else if (WalletStatusState === "success-change-status") {
      setAlertProps({
        variant: "success",
        title: "Berhasil Merubah Status",
        content: " ",
      });
      setShowAlert(true);
      dispatch(resetStatus());
      fetchData(0, perPage);
    } else if (WalletStatusState === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal Menambahkan Data",
        content: " ",
      });
      setShowAlert(true);
      dispatch(resetStatus());
      onFilterResetClick();
    } else if (WalletStatusState === "failed-change-status") {
      setAlertProps({
        variant: "failed",
        title: "Gagal Merubah Status",
        content: " ",
      });
      setShowAlert(true);
      dispatch(resetStatus());
    }
    fetchData(0, perPage);
  }, [WalletStatusState]);

  const columns = [
    {
      name: "CUSTOMER",
      selector: "partnerName",
      sortable: true,
    },
    {
      name: "TIPE CUSTOMER",
      selector: (d: { partnerType: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.partnerType === 0 && "End Customer"}
            {d.partnerType === 1 && "Stockist"}
            {d.partnerType === 2 && "Reseller"}
            {d.partnerType === 4 && "Admin Stockist"}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "NOMINAL",
      selector: (d: { nominal: number }) => (
        <>
          <NumberFormat
            value={d.nominal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { status: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.status === 0 && "Request"}
            {d.status === 1 && "Approve"}
            {d.status === 2 && "Reject"}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "TANGGAL REQUEST",
      selector: (d: { createdDate: string }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.createdDate !== undefined && d.createdDate.substring(0, 10)}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "TANGGAL PROSES",
      selector: (d: { approveDate: string }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.approveDate.substring(0, 10) !== "0001-01-01" ? (
              <>{d.approveDate.substring(0, 10)}</>
            ) : (
              <>-</>
            )}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: string; status: number }) => (
        <>
          {d.status === 0 && ( userState.role?.includes("Finance") || userState.role?.includes("SUPER")) ? (
            <>
              <button
                style={{
                  margin: 3,
                  width: 70,
                  height: 23,
                  background: "#F52B3D",
                  borderRadius: 5,
                  color: "white",
                }}
                disabled={d.status !== 0 && true}
                onClick={() => {
                  handleChangeStatus(2, d.id);
                }}
              >
                Reject
              </button>
              <br />
              <button
                style={{
                  margin: 3,
                  width: 70,
                  height: 23,
                  background: "#2ECC71",
                  borderRadius: 5,
                  color: "white",
                }}
                disabled={d.status !== 0 && true}
                onClick={() => {
                  handleChangeStatus(1, d.id);
                }}
              >
                Approve
              </button>
            </>
          ) : (
            <> </>
          )}

          {/* <br />
         <button
           style={{
             margin: 3,
             width: 70,
             height: 23,
             background: "#12A1FD",
             borderRadius: 5,
             color: "white",
           }}
           disabled={d.paymentStatus !== 0 && true}
           onClick={() => handleChangeStatus(0, d.salesOrderId)}
         >
           Lunas
         </button> */}
        </>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Wallet</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="float-right"
            style={{ width: 200, marginLeft: 10 }}
            variant="outline-info"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Create New Request
          </Button>{" "}
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>

            <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Partner Type
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempPartnerType}
                      onChange={(e) => {
                        if (e.target.value.toString() === "3") {
                          setFilterPartnerType(null);
                          setFilterTempPartnerType(parseInt(e.target.value));
                        } else {
                          setFilterTempPartnerType(parseInt(e.target.value));
                          setFilterPartnerType(parseInt(e.target.value));
                          setPartnerType(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={3}>All</option>
                      <option value={0}>End Customer</option>
                      <option value={1}>Stockist</option>
                      <option value={2}>Reseller</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Tanggal Request
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="date"
                      // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                      onChange={(e) =>
                        setFilterStartCreateDate(
                          new Date(e.target.value).toISOString()
                        )
                      }
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="date"
                      // value={filterEndDate.substr(0, 10)}
                      onChange={(e) =>
                        setFilterEndCreateDate(
                          new Date(e.target.value).toISOString()
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>

            <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Partner
                  </Form.Label>
                  <Col>
                    <Select
                      // defaultValue={selectedPartnerId}
                      // value={selected}
                      onChange={onSelectedPartnerFilterChange}
                      options={partnerList}
                    />
                  </Col>
                </Form.Group>
              </Col>
             
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Tanggal Proses
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="date"
                      // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                      onChange={(e) =>
                        setFilterStartApprovalDate(
                          new Date(e.target.value).toISOString()
                        )
                      }
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="date"
                      // value={filterEndDate.substr(0, 10)}
                      onChange={(e) =>
                        setFilterEndApprovalDate(
                          new Date(e.target.value).toISOString()
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Status
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempStatus}
                      onChange={(e) => {
                        if (e.target.value.toString() === "3") {
                          setFilterStatus(null);
                          setFilterTempStatus(parseInt(e.target.value));
                        } else {
                          setFilterTempStatus(parseInt(e.target.value));
                          setFilterStatus(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={3}>All</option>
                      <option value={0}>REQUEST</option>
                      <option value={1}>APPROVE</option>
                      <option value={2}>REJECT</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={4}>
                <Button
                  variant="outline-info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterResetClick}
                >
                  Reset Filter
                </Button>
                <Button
                  variant="info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterClick}
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Card.Body>

          <hr />

          <DataTable
            columns={columns}
            data={WalletListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={TotalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleModalClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Create Wallet Request</div>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="input-padding">
              <Form.Label>Tipe Partner</Form.Label>
              <Row className="m-2">
                <Form.Check
                  className="radio-button"
                  label="Stockist"
                  type="radio"
                  name="partnerType"
                  checked={partnerType === 1}
                  value={1}
                  onChange={() => setPartnerType(1)}
                />
              </Row>
              <Row className="m-2">
                <Form.Check
                  className="radio-button"
                  label="Reseller"
                  type="radio"
                  name="partnerType"
                  checked={partnerType === 2}
                  value={2}
                  onChange={() => setPartnerType(2)}
                />
              </Row>

              <Row className="m-2">
                <Form.Check
                  className="radio-button"
                  label="End Customer"
                  type="radio"
                  name="partnerType"
                  checked={partnerType === 0}
                  value={0}
                  onChange={() => setPartnerType(0)}
                />
              </Row>
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Partner</Form.Label>
              <Select
                // defaultValue={selectedPartnerId}
                value={selectedPartner}
                onChange={onSelectedPartnerNewWalletChange}
                options={partnerList}
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Nominal</Form.Label>
              <Form.Control
                type="number"
                placeholder="-"
                value={nominal}
                onChange={(e) => setNominal(parseInt(e.target.value))}
              />
            </Form.Group>

            <Form.Group className="input-padding">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <div className="btn-footer-group float-right">
              <Button
                variant="info"
                style={{ width: 130, height: 45 }}
                onClick={onSaveClick}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </div>
  );
};

export default WalletTable;
