import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import SettingMembershipCreate from "../components/SettingMembershipCreate";
import { MembershipModel } from "../settingMembership.model";
import { createMembership, getSingleMembershipData, updateMembership } from "../settingMembership.reducer";

const SettingMembershipPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.settingMembership.single ?? ({} as MembershipModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleMembershipData(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    title: string,
    fee: number,
    periodType: number,
    feeIDR: number,
    minimumPurchase: number,
    feePercentage: number,
    feeUpTo: number,
    description: string,
    image: string,
    isStatus: boolean,
    isActive: boolean,
    code: string,
    price: number
  ) => {
    if (!isEdit) {
      dispatch(
        createMembership({
          id: "",
          title,
          fee,
          periodType,
          feeIDR,
          minimumPurchase,
          feePercentage,
          feeUpTo,
          description,
          image,
          isStatus,
          isActive,
          code,
          price
        } as MembershipModel)
      );
    } else {
      dispatch(
        updateMembership({
          id,
          title,
          fee,
          periodType,
          feeIDR,
          minimumPurchase,
          feePercentage,
          feeUpTo,
          description,
          image,
          isStatus,
          isActive,
          code,
          price
        } as MembershipModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <SettingMembershipCreate
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingMembershipPage;
