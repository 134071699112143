import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import SettingMembershipApi from "./SettingMembership.api";
import { MembershipModel, UploadImageModel } from "./settingMembership.model";

export interface SettingMembershipSlice {
  name?: string;
  list?: MembershipModel[];
  single?: MembershipModel;
  imagePath?: string;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getMembershipList = createAsyncThunk(
  "getMembershipListState/getMembershipList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await SettingMembershipApi.getMembershipList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleMembershipData = createAsyncThunk(
   "getSingleMembershipDataState/getSingleMembershipData",
   async (id:string, { rejectWithValue }) => {
     try {
       return await SettingMembershipApi.getSingleItem(id);
     } catch (e) {
       return rejectWithValue(e as ApiErrorResponse<any>);
     }
   }
 );

export const createMembership = createAsyncThunk(
  "createMembershipState/createMembership",
  async (model: MembershipModel, { rejectWithValue }) => {
    try {
      return await SettingMembershipApi.create(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateMembership = createAsyncThunk(
  "updateMembershipState/updateMembership ",
  async (model: MembershipModel, { rejectWithValue }) => {
    try {
      return await SettingMembershipApi.update(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteMembership = createAsyncThunk(
   "deleteMembershipState/deleteMembership ",
   async (id: string, { rejectWithValue }) => {
     try {
       return await SettingMembershipApi.deleteMembershipSetting(id);
     } catch (e) {
       return rejectWithValue(e as ApiErrorResponse<any>);
     }
   }
 );

export const uploadPhoto = createAsyncThunk(
  "UploadPhotoState/UploadPhoto",
  async (model: UploadImageModel, { rejectWithValue }) => {
    try {
      return await SettingMembershipApi.uploadImage(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const SettingMembershipSlice = createSlice({
  name: "SettingMembershipState",
  initialState: {} as SettingMembershipSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as MembershipModel;
      state.status = "";
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMembershipList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMembershipList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getMembershipList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getSingleMembershipData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleMembershipData.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleMembershipData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(uploadPhoto.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadPhoto.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.imagePath = payload.data.imagepath;
    });
    builder.addCase(uploadPhoto.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(createMembership.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMembership.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
      state.status = "success"
    });
    builder.addCase(createMembership.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed"
    });
    builder.addCase(updateMembership.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMembership.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
      state.status = "success-update"
    });
    builder.addCase(updateMembership.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed-update"
    });
    builder.addCase(deleteMembership.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMembership.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-delete"
    });
    builder.addCase(deleteMembership.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed-delete"
    });
    
  },
});

export const { setFilter, resetSingle, resetStatus } =
  SettingMembershipSlice.actions;
export default SettingMembershipSlice.reducer;
