import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { AddToCartRequestModel, GetVariationByProductAndUserRequest, PartnerModel } from "./KeepBarangForm.model";
import Api from "./KeepBarangForm.api";
import { variationModel } from "../../master/product/product.model";
import { StaticRouter } from "react-router-dom";


export interface KeepBarangFormSlice {
  partnerList?: PartnerModel[];
  listVariationByProduct?: variationModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getListPartner = createAsyncThunk(
  "GetPartnerListForKeepState/GetPartnerList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getListPartner();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationByProductAndUser = createAsyncThunk(
  "KeepBarangStateState/getVariationListByproductAndUser",
  async (model : GetVariationByProductAndUserRequest, { rejectWithValue }) => {
    try {
      return await Api.getListVariationByProductAndUser(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createKeep = createAsyncThunk(
  "KeepBarangStateState/createKeep",
  async (model : AddToCartRequestModel, { rejectWithValue }) => {
    try {
      return await Api.createKeepFromAdmin(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const KeepBarangFormSlice = createSlice({
  name: "KeepBarangFormState",
  initialState: {} as KeepBarangFormSlice,
  reducers : {
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListPartner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListPartner.fulfilled, (state, { payload }) => {
      state.partnerList = payload;
      state.isLoading = false;
    });
    builder.addCase(getListPartner.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProductAndUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListVariationByProductAndUser.fulfilled, (state, { payload }) => {
      state.listVariationByProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProductAndUser.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(createKeep.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createKeep.fulfilled, (state, { payload }) => {
      state.partnerList = payload;
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createKeep.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";

    });

  }
});

export const {resetStatus} = KeepBarangFormSlice.actions;
export default KeepBarangFormSlice.reducer;
