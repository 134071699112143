import { ApiErrorResponse } from "../../..";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ImageSettingModel } from "./SettingImage.model";
import SettingImageApi from "./SettingImage.api";


export interface SettingImageSlice{
    singleImageSetting : ImageSettingModel;
    imageSettingList : ImageSettingModel[];
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getImageSettingList = createAsyncThunk(
    'ImageSettingListState/ImageSettingList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await SettingImageApi.getImageSettingList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateImageSetting = createAsyncThunk(
    'UpdateImageSettingState/UpdateImageSetting',
    async (model: ImageSettingModel, { rejectWithValue }) => {
        try {
            return await SettingImageApi.updateImageSetting(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const ImageSettingSlice = createSlice({
    name: 'ImageSetting',
    initialState: {} as SettingImageSlice,
    reducers : {
        resetSingle: (state) => {
            state.singleImageSetting = {} as ImageSettingModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getImageSettingList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getImageSettingList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageSettingList = payload.data;
        });
        builder.addCase(getImageSettingList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateImageSetting.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(updateImageSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(updateImageSetting.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
    }
})

export const { resetSingle, resetStatus } = ImageSettingSlice.actions;
export default ImageSettingSlice.reducer;

