import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { getListWarehouse } from "../../../../global/global.reducer";
import InventoryMoveApprovalPage from "../components/inventoryMoveApprovalPage";
import { InventoryMoveModel } from "../inventoryMove.model";
import {
  getInventoryMoveDetailList,
  getSingleItem,
} from "../inventoryMove.reducer";

const InventoryMoveApproval = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.inventoryMove.single ?? ({} as InventoryMoveModel)
  );

  const listInventoryMoveDetailState = useSelector(
    (state: RootState) => state.inventoryMove.listInventoryMoveDetail ?? []
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getListWarehouse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
      dispatch(getInventoryMoveDetailList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <InventoryMoveApprovalPage data={singleDataState} isEdit={isEdit} />
        </Col>
      </Row>
    </Container>
  );
};

export default InventoryMoveApproval;
