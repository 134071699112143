import React from 'react'
import AdminStockistTable from '../components/AdminStockistTable'
import { Col, Container, Row } from 'react-bootstrap';

const AdminStockistTablePage = () => {
    return (
        <div>
            <Col md={12}>
                <AdminStockistTable />
            </Col>
        </div>
    )
}

export default AdminStockistTablePage
