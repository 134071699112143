import {configureStore, Action} from "@reduxjs/toolkit";
import {ThunkAction} from "redux-thunk";
import {LSK_USER_SLICE} from "../constants";
import changePasswordReducer, {
    ChangePasswordSlice,
} from "../features/confirmPassword/confirmPassword.reducer";
import customerReducer, {CustomerSlice} from "../features/customer/customer.reducer";
import forgotPasswordReducer, {
    forgotPasswordSlice,
} from "../features/forgotPassword/forgotPassword.reducer";
import userLoginSliceReducer, {UserSlice} from "../features/login/user.reducer";
import attributeReducer, {AttributeSlice} from "../features/master/attribute/attribute.reducer";
import bankReducer, {BankSlice} from "../features/master/bank/bank.reducer";
import cityReducer, {CitySlice} from "../features/master/city/city.reducer";
import collectionTagReducer, {
    CollectionTagSlice,
} from "../features/master/collectionTag/collectionTag.reducer";
import districtReducer, {DistrictSlice} from "../features/master/district/district.reducer";
import paymentConfirmationReducer, {
    PaymentConfirmationSlice,
} from "../features/master/paymentConfirmation/paymentConfirmation.reducer";
import paymentMethodReducer, {
    PaymentMethodSlice,
} from "../features/master/paymentMethod/paymentMethod.reducer";
import productReducer, {ProductSlice} from "../features/master/product/product.reducer";
import productCategoryReducer, {
    ProductCategorySlice,
} from "../features/master/productCategory/productCategory.reducer";
import provinceReducer, {ProvinceSlice} from "../features/master/province/province.reducer";
import supplierReducer, {SupplierSlice} from "../features/master/supplier/supplier.reducer";
import UOMReducer, {UOMSlice} from "../features/master/UOM/UOM.reducer";
import warehouseReducer, {WarehouseSlice} from "../features/master/warehouse/warehouse.reducer";
import roleReducer, {RoleSlice} from "../features/role/role.reducer";
import profileReducer, {ProfileSlice} from "../features/settingProfile/profile.reducer";
import accountCreateReducer, {CreateUserSlice} from "../features/user/user.reducers";
import internalUseReducer, {InternalUseSlice} from "../features/internalUse/internalUse.reducer";
import registerStockistReducer, {
    RegisterStockistSlice,
} from "../features/registerStockist/registerStockist.reducer";
import registerResellerReducer, {
    RegisterResellerSlice,
} from "../features/registerReseller/registerReseller.reducer";
import voucherReducer, {VoucherSlice} from "../features/master/voucher/voucher.reducer";
import inventoryMoveReducer, {
    InventoryMoveSlice,
} from "../features/Inventory/InventoryMove/inventoryMove.reducer";
import inventoryReceiveReducer, {
    InventoryReceiveSlice,
} from "../features/Inventory/inventoryReceive/inventoryReceive.reducer";
import globalReducer, {GlobalSlice} from "../global/global.reducer";
import contractReducer, {ContractSlice} from "../features/setting/contract/contract.reducer";
import penerimaanInventoryReducer, {
    ItemReceiveSlice,
} from "../features/Inventory/penerimaanInventory/penerimaanInventory.reducer";
import salesOrderReducer, {
    SalesOrderSlice,
} from "../features/salesOrder/salesOrder/salesOrder.reducer";
import settingMembershipReducer, {
    SettingMembershipSlice,
} from "../features/settingMembership/settingMembership.reducer";
import jatuhTempoDropshipReducer, {
    JatuhTempoDropshipSlice,
} from "../features/salesOrder/pesananDalamProses/jatuhTempoDropship/jatuhTempoDropship.reducer";
import keepBelumLunasReducer, {
    KeepBelumLunasSlice,
} from "../features/salesOrder/pesananDalamProses/keepBelumLunas/keepBelumLunas.reducer";
import adminStockistReducer, {
    AdminStockistSlice,
} from "../features/adminStockist/adminStockist.reducer";
import complainReducer, {
    ComplainSlice,
} from "../features/complain/complain.reducer";
import keepPerProductReducer, {
    KeepByProductSlice,
} from "../features/salesOrder/pesananDalamProses/keepPerProduct/keepPerProduct.reducer";
import pesananDalamProsesReducer, {
    PesananDalamProsesSlice,
} from "../features/salesOrder/pesananDalamProses/pesananDalamProses.reducer";
import pesananDropshipReducer, {
    PesananDropshipSlice,
} from "../features/salesOrder/pesananDropship/pesananDropship.reducer";
import pesananLunasReducer, {
    PesananLunasSlice,
} from "../features/salesOrder/pesananLunas/pesananLunas.reducer";
import pesananPiutangReducer, {
    PesananPiutangSlice,
} from "../features/salesOrder/pesananPiutang/pesananPiutang.reducer";
import pesananRekapReducer, {
    PesananRekapSlice,
} from "../features/salesOrder/pesananRekap/pesananRekap.reducer";
import paymentConfirmReducer, {
    PaymentConfirmSlice,
} from "../features/konfirmasi&resi/paymentConfirm/paymentConfirm.reducer";
import stockOpnameReducer, {
    StockOpnameSlice,
} from "../features/Inventory/stockOpname/stockOpname.reducer";
import receiptDeliveryReducer, {
    ReceiptDeliverySlice,
} from "../features/confirmationReceipt/receiptDelivery/receiptDelivery.reducer";
import settingFaqReducer, {SettingFaqSlice} from "../features/setting/faq/settingFaq.reducer";
import ExpeditionReducer, {ExpeditionSlice} from "../features/master/expedition/Expedition.reducer";
import SettingExpeditionReducer, {
    SettingExpeditionSlice
} from "../features/setting/expedition/SettingExpedition.reducer";
import SettingStockReducer, {SettingStockSlice} from "../features/setting/stock/SettingStock.reducer";
import SettingImageReducer, {SettingImageSlice} from "../features/setting/image/SettingImage.reducer";
import SettingApplicationLinkReducer, {
    SettingApplicationLinkSlice
} from "../features/setting/linkAplikasi/SettingApplicationLink.reducer";

import templateBroadcastReducer, {
    TemplateBroadcastState
} from "../features/master/templateBroadcast/templateBroadcast.reducer";
import informationStoreReducer, {
    InformationStoreState
} from "../features/setting/informationStore/informationStore.reducer";
import howToOrderReducer, {HowToOrderState} from "../features/setting/howToOrder/howToOrder.reducer";
import slideShowReducer, {SlideShowState} from "../features/setting/slideShow/slideShow.reducer";
import aplicationReducer, {AplicationState} from "../features/setting/aplication/aplication.reducer";
import SettingPointRewardReducer, {
    SettingPointRewardSlice
} from "../features/setting/pointReward/SettingPointReward.reducer";
import membershipReducer, {MembershipState} from "../features/membersip/membership.reducer";
import laporanOmsetReducer, {LaporanOmsetState} from "../features/report/laporanOmset/laporanOmset.reducer";
import formatNotaReducer, {FormatNotaState} from "../features/setting/formatNota/formatNota.reducer";
import InvoiceReducer, {InvoiceSlice} from "../features/invoice/Invoice.reducer";
import ReturReducer, {ReturState} from "../features/retur/Retur.reducer";

import chatPageReducer, {ChatPageState} from "../features/chatMenu/chat/chatPage.reducer";
import chatProductReducer, {ChatProductState} from "../features/chatMenu/chatProduct/chatProduct.reducer";
import orderDateReportReducer, {OrderDataReportState} from "../features/report/orderDataReport/orderDateReport.reducer";
import dailyMonthlyOrderReducer, {
    DailyMonthlyOrderState
} from "../features/report/dailyMonthlyOrderReport/dailyMonthlyOrder.reducer";
import laporanPenjualanReducer, {
    LaporanPenjualanState
} from "../features/report/laporanPenjualan/laporanPenjualan.reducer";
import laporanPelunasanReducer, {
    LaporanPelunasanState
} from "../features/report/laporanPelunasan/laporanPelunasan.reducer";
import laporanPerubahanStockReducer, {
    LaporanPerubahanStockState
} from "../features/report/laporanPerubahanStock/laporanPerubahanStock.reducer";
import autoCancelReportReducer, {
    AutoCancelReportState
} from "../features/report/autoCancelReport/AutoCancelReport.reducer";
import autoCancelMasterReportReducer, {
    AutoCancelMasterReportState
} from "../features/report/autoCancelMasterReport/AutoCancelMasterReport.reducer";
import accountReceivableReportReducer, {
    AccountReceivableReportState
} from "../features/report/accountReceivableReport/AccountReceivableReport.reducer";
import assetReportReducer, {AssetReportState} from "../features/report/assetReport/AssetReport.reducer";
import expeditionReportReducer, {
    ExpeditionReportSlice
} from "../features/report/laporanEkspedisi/laporanEkspedisi.reducer";
import walletReducer, {WalletSlice} from "../features/wallet/wallet.reducer"
import laporanKartuStockReducer, {
    LaporanKartuStockSlice
} from "../features/report/laporanKartuStock/laporanKartuStock.reducer";
import settingWarehouseReducer, {SettingWarehouseSlice} from "../features/setting/warehouse/settingWarehouse.reducer";
import settingAddressReducer, {AddressSlice} from "../features/setting/address/address.reducer";
import notificationReducer, {NotificationSlice} from "../features/adminPanel/notification.reducer";
import printListReducer, {PrintListSlice} from "../features/printList/printList.reducer";
import paymentInReducer, {PaymentInSlice} from "../features/payment/paymentIn/paymentIn.reducer";
import paymentAllocationReducer, {
    PaymentAllocationSlice
} from "../features/payment/paymentAllocation/paymentAllocation.reducer";
import keepBarangReducer, {KeepBarangSlice} from "../features/salesOrder/keepBarang/keepBarang.reducer";
import autoCancelKeepReducer, {AutoCancelKeepState} from "../features/report/autoCancelKeep/autoCancelKeep.reducer";
import reportStaffPerformanceReducer, {
    staffPerformance,
    StaffPerformanceState
} from "../features/report/laporanPerformaStaff/reportStaffPerformance.reducer";
import KeepBarangFormReducer, {KeepBarangFormSlice} from "../features/salesOrder/keepBarangForm/KeepBarangForm.reducer";
import pesananCashlessReducer, {
    PesananCashlessSlice
} from "../features/salesOrder/pesananCashless/pesananCashless.reducer";
import pesananNonCashlessReducer, {
    PesananNonCashlessSlice
} from "../features/salesOrder/pesananNonCashless/pesananNonCashless.reducer";
import pesananEndCustomerReducer, {
    PesananEndCustomerSlice
} from "../features/salesOrder/pesananEndCustomer/pesananEndCustomer.reducer";
import temporaryShipmentReducer, {
    TemporaryShipmentSlice
} from "../features/salesOrder/temporaryShipment/temporaryShipment.reducer";
import reportTotalPerformanceReducer, {
    totalStaffPerformance, TotalStaffPerformanceState
} from "../features/report/laporanTotalPerformaStaff/reportTotalPerformance.reducer";
import stockistOrderDateReportReducer, {
    StockistOrderDataReportSlice
} from "../features/report/stockistOrderDataReport/stockistOrderDateReport.reducer";
import FooterSettingReducer, {FooterSettingSlice} from "../features/webSetting/footerSetting/footerSetting.reducer";
import pointReducer, {PointSlice} from "../features/point/point.reducer";
import subscriberReducer, {SubscriberSlice} from "../features/subscriber/subscriber.reducer";
import recommendedSlideReducer, {RecommendedSlideSlice} from "../features/setting/recommended/recommendedSlide.reducer";


export const store = configureStore<RootState>({
    reducer: {
        global: globalReducer,
        user: userLoginSliceReducer,
        account: accountCreateReducer,
        forgotPassword: forgotPasswordReducer,
        changePassword: changePasswordReducer,
        //master
        customer: customerReducer,
        attribute: attributeReducer,
        profile: profileReducer,
        warehouse: warehouseReducer,
        supplier: supplierReducer,
        province: provinceReducer,
        city: cityReducer,
        district: districtReducer,
        bank: bankReducer,
        UOM: UOMReducer,
        productCategory: productCategoryReducer,
        paymentMethod: paymentMethodReducer,
        paymentConfirmation: paymentConfirmationReducer,
        role: roleReducer,
        collectionTag: collectionTagReducer,
        product: productReducer,
        internalUse: internalUseReducer,
        voucher: voucherReducer,
        //inventory
        penerimaanInventory: penerimaanInventoryReducer,
        inventoryMove: inventoryMoveReducer,
        inventoryReceive: inventoryReceiveReducer,
        //
        registerStockist: registerStockistReducer,
        registerReseller: registerResellerReducer,
        //setting
        contract: contractReducer,
        howToOrder: howToOrderReducer,
        slideShow: slideShowReducer,
        recommendedSlide: recommendedSlideReducer,
        //sales order
        salesOrder: salesOrderReducer,
        keepBelumalunas: keepBelumLunasReducer,
        keepBarang: keepBarangReducer,
        keepByProduct: keepPerProductReducer,
        temporaryShipment: temporaryShipmentReducer,
        jatuhTempoDropship: jatuhTempoDropshipReducer,
        pesananDalamProses: pesananDalamProsesReducer,
        pesananDroship: pesananDropshipReducer,
        pesananLunas: pesananLunasReducer,
        pesananPiutang: pesananPiutangReducer,
        pesananCashless: pesananCashlessReducer,
        pesananNonCashless: pesananNonCashlessReducer,
        pesananRekap: pesananRekapReducer,
        pesananEndCustomer: pesananEndCustomerReducer,
        //
        settingMembership: settingMembershipReducer,
        adminStockist: adminStockistReducer,
        complain: complainReducer,
        //konfirmasi & resi
        paymentConfirm: paymentConfirmReducer,
        stockOpname: stockOpnameReducer,
        receiptDelivery: receiptDeliveryReducer,
        settingFaq: settingFaqReducer,
        expedition: ExpeditionReducer,
        settingExpedition: SettingExpeditionReducer,
        settingStock: SettingStockReducer,
        settingImage: SettingImageReducer,
        settingPointReward: SettingPointRewardReducer,
        settingAddress: settingAddressReducer,
        //by kemal
        templateBroadcast: templateBroadcastReducer,
        informationStore: informationStoreReducer,
        aplication: aplicationReducer,
        membership: membershipReducer,
        laporanOmset: laporanOmsetReducer,
        formatNota: formatNotaReducer,
        settingApplicationLink: SettingApplicationLinkReducer,
        invoice: InvoiceReducer,
        retur: ReturReducer,
        chatPage: chatPageReducer,
        chatProduct: chatProductReducer,
        orderDataReport: orderDateReportReducer,
        stockistOrderDataReport: stockistOrderDateReportReducer,
        dailyMonthlyOrder: dailyMonthlyOrderReducer,
        laporanPenjualan: laporanPenjualanReducer,
        laporanPelunasan: laporanPelunasanReducer,
        laporanPerubahanStock: laporanPerubahanStockReducer,
        autoCancelKeep: autoCancelKeepReducer,
        staffPerformance: reportStaffPerformanceReducer,
        totalStaffPerformance: reportTotalPerformanceReducer,
        autoCancelReport: autoCancelReportReducer,
        autoCancelMasterReport: autoCancelMasterReportReducer,
        accountReceivableReport: accountReceivableReportReducer,
        assetReport: assetReportReducer,
        expeditionReport: expeditionReportReducer,
        wallet: walletReducer,
        point: pointReducer,
        laporanKartuStock: laporanKartuStockReducer,
        settingWarehouse: settingWarehouseReducer,
        notification: notificationReducer,
        printList: printListReducer,
        paymentIn: paymentInReducer,
        paymentAllocation: paymentAllocationReducer,
        keepBarangForm: KeepBarangFormReducer,
        footerSetting: FooterSettingReducer,
        // subscriber
        subscriber: subscriberReducer,
    },
});

export type RootState = {
    global: GlobalSlice;
    user: UserSlice;
    account: CreateUserSlice;
    forgotPassword: forgotPasswordSlice;
    changePassword: ChangePasswordSlice;
    customer: CustomerSlice;
    attribute: AttributeSlice;
    profile: ProfileSlice;
    warehouse: WarehouseSlice;
    supplier: SupplierSlice;
    province: ProvinceSlice;
    city: CitySlice;
    district: DistrictSlice;
    bank: BankSlice;
    UOM: UOMSlice;
    productCategory: ProductCategorySlice;
    paymentMethod: PaymentMethodSlice;
    paymentConfirmation: PaymentConfirmationSlice;
    role: RoleSlice;
    collectionTag: CollectionTagSlice;
    product: ProductSlice;
    internalUse: InternalUseSlice;
    registerStockist: RegisterStockistSlice;
    registerReseller: RegisterResellerSlice;
    voucher: VoucherSlice;
    // penerimaanInventory: PenerimaanInventorySlice;
    inventoryMove: InventoryMoveSlice;
    inventoryReceive: InventoryReceiveSlice;
    contract: ContractSlice;
    penerimaanInventory: ItemReceiveSlice;
    //sales order
    salesOrder: SalesOrderSlice;
    keepBelumalunas: KeepBelumLunasSlice;
    keepBarang: KeepBarangSlice;
    keepBarangForm: KeepBarangFormSlice;
    temporaryShipment: TemporaryShipmentSlice;
    jatuhTempoDropship: JatuhTempoDropshipSlice;
    pesananDalamProses: PesananDalamProsesSlice;
    pesananDroship: PesananDropshipSlice;
    pesananPiutang: PesananPiutangSlice;
    pesananCashless: PesananCashlessSlice;
    pesananNonCashless: PesananNonCashlessSlice;
    pesananLunas: PesananLunasSlice;
    pesananRekap: PesananRekapSlice;
    pesananEndCustomer: PesananEndCustomerSlice;
    //setting
    settingMembership: SettingMembershipSlice;
    howToOrder: HowToOrderState,
    slideShow: SlideShowState,
    recommendedSlide: RecommendedSlideSlice,
    adminStockist: AdminStockistSlice;
    complain: ComplainSlice;
    keepByProduct: KeepByProductSlice;
    //konfirmasi & resi
    paymentConfirm: PaymentConfirmSlice;
    stockOpname: StockOpnameSlice;
    receiptDelivery: ReceiptDeliverySlice;
    settingFaq: SettingFaqSlice;
    expedition: ExpeditionSlice;
    settingExpedition: SettingExpeditionSlice;
    settingStock: SettingStockSlice;
    settingImage: SettingImageSlice;
    settingPointReward: SettingPointRewardSlice;
    templateBroadcast: TemplateBroadcastState;
    informationStore: InformationStoreState;
    aplication: AplicationState;
    membership: MembershipState;
    laporanOmset: LaporanOmsetState;
    formatNota: FormatNotaState;
    settingApplicationLink: SettingApplicationLinkSlice;
    invoice: InvoiceSlice;
    retur: ReturState;
    chatPage: ChatPageState;
    chatProduct: ChatProductState;
    orderDataReport: OrderDataReportState;
    stockistOrderDataReport: StockistOrderDataReportSlice;
    dailyMonthlyOrder: DailyMonthlyOrderState;
    laporanPenjualan: LaporanPenjualanState;
    laporanPelunasan: LaporanPelunasanState;
    laporanPerubahanStock: LaporanPerubahanStockState;
    autoCancelKeep: AutoCancelKeepState;
    staffPerformance: StaffPerformanceState;
    totalStaffPerformance: TotalStaffPerformanceState;
    autoCancelReport: AutoCancelReportState;
    autoCancelMasterReport: AutoCancelMasterReportState;
    accountReceivableReport: AccountReceivableReportState;
    assetReport: AssetReportState;
    expeditionReport: ExpeditionReportSlice;
    wallet: WalletSlice;
    point: PointSlice;
    laporanKartuStock: LaporanKartuStockSlice;
    settingWarehouse: SettingWarehouseSlice;
    settingAddress: AddressSlice;
    notification: NotificationSlice;
    printList: PrintListSlice;
    paymentIn: PaymentInSlice;
    paymentAllocation: PaymentAllocationSlice;
    footerSetting: FooterSettingSlice;
    subscriber: SubscriberSlice;
};

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

store.subscribe(() => {
    const state = store.getState();

    localStorage.setItem(LSK_USER_SLICE, JSON.stringify(state.user));
});
