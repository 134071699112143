import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ResetPasswordModel } from "./models";
import forgotPasswordApi from "./forgotPassword.api";

export interface forgotPasswordSlice {
    id: string;
    email?: ResetPasswordModel;
    error?: ApiErrorResponse<any>;
    validity?: ApiErrorResponse<any>;
    isLoading?: boolean;
}

export const sendEmail = createAsyncThunk(
  "SendEmailState/sendEmail",
  async (email: string, { rejectWithValue }) => {
    try {
      return await forgotPasswordApi.sendEmail(email);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: "userLoginState",
  initialState: {} as forgotPasswordSlice,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    clearError: (state) => {
      state.error = {} as ApiErrorResponse<any>;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(sendEmail.fulfilled, (state, { payload }) => {
      state.email = payload.email;
      state.id = payload.id;
      state.isLoading = false;
    });

    builder.addCase(sendEmail.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const {
  setId,
  setEmail,
  clearError,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
