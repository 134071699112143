import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import ComplainApi from "./complain.api";
import { ComplainListModel, ComplainModel, SalesOrderModal, ItemDetailModal, WarehouseModel, ProductModel, VariantModel } from "./complain.model";

export interface ComplainSlice {
    name?: string,
    single?: ComplainModel,
    list?: ComplainListModel,
    salesOrderList?: SalesOrderModal[],
    warehouseList?: WarehouseModel[],
    requestedSONumberList?: string[],
    listProduct?: ProductModel[],
    listVariationByProduct?: VariantModel[],
    itemDetail?: ItemDetailModal,
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getComplainList = createAsyncThunk(
    'getComplainState/getComplainList',
    async (data: any, { rejectWithValue }) => {
        try {
            return await ComplainApi.getComplainList(data);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getComplainDetail = createAsyncThunk(
    'getComplainState/getComplainDetail',
    async (id: string, { rejectWithValue }) => {
        try {
            return await ComplainApi.getComplainDetail(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateComplain = createAsyncThunk(
    'getComplainState/updateComplain',
    async (model: any, { rejectWithValue }) => {
        try {
            return await ComplainApi.updateComplain(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getSalesOrderList = createAsyncThunk(
    'getSalesOrderListState/getSalesOrderList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await ComplainApi.getSalesOrderList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getItemDetailBySONumber = createAsyncThunk(
    'getItemDetailBySONumberState/getItemDetailBySONumber',
    async (soNumber: string, { rejectWithValue }) => {
        try {
            return await ComplainApi.getItemDetailBySONumber(soNumber);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const createComplain = createAsyncThunk(
    'createComplainState/createComplain',
    async (model: any, { rejectWithValue }) => {
        try {
            return await ComplainApi.createComplain(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getWarehouseList = createAsyncThunk(
    'getWarehouseListState/getWarehouseList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await ComplainApi.getWarehouseList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getListProduct = createAsyncThunk(
    'getListProductState/getListProduct',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await ComplainApi.getListProduct();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getListVariationByProduct = createAsyncThunk(
    'getListVariationByProductState/getListVariationByProduct',
    async (id: string, { rejectWithValue }) => {
        try {
            return await ComplainApi.getListVariationByProduct(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getRequestedSONumber = createAsyncThunk(
    'getRequestedSONumberState/getRequestedSONumber',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await ComplainApi.getRequestedSONumber();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const ComplainSlice = createSlice({
    name: 'ComplainState',
    initialState: {} as ComplainSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = ""
        },
        resetSingle: (state) => {
            state.single = {} as ComplainModel
            state.status = ""
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getComplainList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getComplainList.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.status = "success-get-list";
            state.isLoading = false;
        });
        builder.addCase(getComplainList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        //
        builder.addCase(getComplainDetail.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getComplainDetail.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.isLoading = false;
        });
        builder.addCase(getComplainDetail.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        //
        builder.addCase(updateComplain.pending, (state) => {
            state.isLoading = true;
            state.status = "loading";

        });
        builder.addCase(updateComplain.fulfilled, (state, { payload }) => {
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(updateComplain.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed"
        });
        //
        builder.addCase(getSalesOrderList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSalesOrderList.fulfilled, (state, { payload }) => {
            state.salesOrderList = payload;
            state.status = "success-get-list";
            state.isLoading = false;
        });
        builder.addCase(getSalesOrderList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        //
        builder.addCase(getItemDetailBySONumber.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getItemDetailBySONumber.fulfilled, (state, { payload }) => {
            state.itemDetail = payload;
            state.status = "success-get";
            state.isLoading = false;
        });
        builder.addCase(getItemDetailBySONumber.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        //
        builder.addCase(createComplain.pending, (state) => {
            state.isLoading = true;
            state.status = "loading";

        });
        builder.addCase(createComplain.fulfilled, (state, { payload }) => {
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(createComplain.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed"
        });
        //
        builder.addCase(getWarehouseList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWarehouseList.fulfilled, (state, { payload }) => {
            state.warehouseList = payload;
            state.status = "success-get-list";
            state.isLoading = false;
        });
        builder.addCase(getWarehouseList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        //
        builder.addCase(getListProduct.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListProduct.fulfilled, (state, { payload }) => {
            state.listProduct = payload;
            state.status = "success-get-list";
            state.isLoading = false;
        });
        builder.addCase(getListProduct.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        //
        builder.addCase(getListVariationByProduct.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListVariationByProduct.fulfilled, (state, { payload }) => {
            state.listVariationByProduct = payload;
            state.status = "success-get-list";
            state.isLoading = false;
        });
        builder.addCase(getListVariationByProduct.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        //
        builder.addCase(getRequestedSONumber.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRequestedSONumber.fulfilled, (state, { payload }) => {
            state.requestedSONumberList = payload;
            state.status = "success-get-list";
            state.isLoading = false;
        });
        builder.addCase(getRequestedSONumber.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    }
})

export const { resetStatus, resetSingle } = ComplainSlice.actions;
export default ComplainSlice.reducer;