import {ApiErrorResponse} from "../../../index";
import {TemporaryShipmentModel, TemporaryShipmentRequest} from "./temporaryShipment.model";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import TemporaryShipmentApi from "./temporaryShipment.api";

export interface TemporaryShipmentSlice {
    list?: TemporaryShipmentModel[];
    totalRow?: number;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getTemporaryShipment = createAsyncThunk(
    "temporaryShipmentState/getTemporaryShipment",
    async (args: TemporaryShipmentRequest, { rejectWithValue }) => {
        try {
            return await TemporaryShipmentApi.getTemporaryShipment(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const removeTemporaryShipment = createAsyncThunk(
    "temporaryShipmentState/removeTemporaryShipment",
    async (cartDetailId: string, { rejectWithValue }) => {
        try {
            return await TemporaryShipmentApi.removeTemporaryShipment(cartDetailId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const temporaryShipmentSlice = createSlice({
    name: "TemporaryShipmentState",
    initialState: {} as TemporaryShipmentSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(removeTemporaryShipment.pending, (state) => {
            state.status = "";
            state.isLoading = true;
        });
        builder.addCase(removeTemporaryShipment.fulfilled, (state) => {
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(removeTemporaryShipment.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });

        builder.addCase(getTemporaryShipment.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getTemporaryShipment.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getTemporaryShipment.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    }
});

export const { resetStatus } = temporaryShipmentSlice.actions;
export default temporaryShipmentSlice.reducer;
