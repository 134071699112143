import { SupplierModel } from "./supplier.model";
import { endpoint } from "../../../constants";
import axios from "axios";

export default class SupplierApi {
  static createData(model: SupplierModel) {
    return axios.post<any>(endpoint + "/api/supplier/Create", model).then((res) => res.data.data);
  }

  static getList() {
    return axios.get<any>(endpoint + "/api/supplier/GetList").then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/supplier/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: SupplierModel) {
    return axios.post<any>(endpoint + "/api/supplier/update", model).then((res) => res.data.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/supplier/Delete?id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static getBankList() {
    return axios
    .get<any>(endpoint + "/api/bank/GetList")
    .then((res) => res.data.data);
}
}
