import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import DeleteMessage from "../../../../components/DeleteMessage";
import {
  ItemReceiveDetailModel,
  ProductCategoryOptionModel,
  ProductOptionModel,
} from "../penerimaanInventory.model";
import { getListVariationByProduct } from "../penerimaanInventory.reducer";
import Select from "react-select";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { InventoryMoveDetailModel } from "../../InventoryMove/inventoryMove.model";
import AsyncSelect from "react-select/async";
import useAsyncProductOption from "../../../../hooks/product/useAsyncProductOption";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const ProductTableComponent = ({
  isEdit = false,
  ...props
}: InventoryMoveDetailProps) => {
  const dispatch = useDispatch();

  const ProductCategoryState = useSelector(
    (state: RootState) => state.global.listProductCategory ?? []
  );

  const productListState = useSelector(
    (state: RootState) => state.global.listActiveProduct ?? []
  );

  const variationState = useSelector(
    (state: RootState) => state.penerimaanInventory.listVariationByProduct ?? []
  );

  const UOMListState = useSelector(
    (state: RootState) => state.global.listUOM ?? []
  );

  const listInventoryMoveDetailState = useSelector(
    (state: RootState) => state.penerimaanInventory.listItemReceiveDetail ?? []
  );

  const [productList, setProductList] = useState<ProductOptionModel[]>([]);
  const [productCategoryList, setProductCategoryList] = useState<
    ProductCategoryOptionModel[]
  >([]);

  // datatable components
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [inventoryMoveDetail, setInventoryMove] = useState<
    ItemReceiveDetailModel[]
  >([]);
  const [inventoryMoveDetailTemp, setInventoryMoveDetailTemp] = useState<
    ItemReceiveDetailModel[]
  >([]);
  const [uomId, setUomId] = useState("");
  const [productSku, setProductSku] = useState("");
  const [productId, setProduct] = useState("");
  const [productVariantId, setProductVariantId] = useState("");
  const [productVariantName, setProductVariantName] = useState("");
  const [productName, setProductName] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [note, setNote] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [timer, setTimer] = useState(setTimeout(() => {
  }, 1000));

  const {getProductOptions} = useAsyncProductOption();

  const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProduct(e.target.value);
    setProductName(e.target.value.split("&")[1]);
    dispatch(getListVariationByProduct(e.target.value.split("&")[0]));

    let uomEnum = e.target.value.split("&")[2];

    if (uomEnum === "0") {
      setUomId("PCS");
    } else {
      setUomId("Serian");
    }
  };

  const onSelectedProductCategoryChange = (category: any) => {
    console.log(category);
    setSelectedCategory(category.id);
    setSelectedCategoryName(
      category.parentName !== ""
        ? category.parentName + " - " + category.name
        : category.name
    );
  };

  const onSelectedProductChange = (product: any) => {
    setProduct(product.id);
    setProductSku(product.sku);
    setProductName(product.productName);
    dispatch(getListVariationByProduct(product.id));

    let uomEnum = product.uom;
    if (uomEnum === 0) {
      setUomId("PCS");
    } else {
      setUomId("Serian");
    }
  };

  const onVariantChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductVariantId(e.target.value.split("&")[0]);
    setProductVariantName(e.target.value.split("&")[1]);
  };

  const onUOMChange = (e: ChangeEvent<HTMLInputElement>) => {};

  const onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const onQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(e.target.value));
  };

  const onAddQuantityClick = (variation: ItemReceiveDetailModel) => {
    let updatedInventoryMoveDetailList = inventoryMoveDetailTemp.map((item) => {
      if (item === variation) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      } else {
        return item;
      }
    });
    setInventoryMoveDetailTemp(updatedInventoryMoveDetailList);
  };

  const onSubQuantityClick = (variation: ItemReceiveDetailModel) => {
    let updatedInventoryMoveDetailList = inventoryMoveDetailTemp.map((item) => {
      if (item === variation && item.quantity !== 0) {
        return {
          ...item,
          quantity: item.quantity - 1,
        };
      } else {
        return item;
      }
    });
    setInventoryMoveDetailTemp(updatedInventoryMoveDetailList);
  };

  const onVariantNoteChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    variation: ItemReceiveDetailModel
  ) => {
    let updatedInventoryMoveDetailList = inventoryMoveDetailTemp.map((item) => {
      if (item === variation && item.quantity !== 0) {
        return {
          ...item,
          note: e.target.value,
        };
      } else {
        return item;
      }
    });
    setInventoryMoveDetailTemp(updatedInventoryMoveDetailList);
  };

  const columns = [
    {
      name: "SKU",
      selector: "inventorySKU",
      sortable: true,
    },
    {
      name: "PRODUCT NAME",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIANT",
      selector: "variationName",
      sortable: true,
    },
    // {
    //   name: "CATEGORY",
    //   selector: "categoryName",
    //   sortable: true,
    // },
    {
      name: "QUANTITY",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "UOM",
      selector: () => (
        <>
          <span>PCS</span>
        </>
      ),
    },
    {
      name: "Note",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }, index: number) => (
        <>
          {isEdit ? (
            <></>
          ) : (
            <>
              <span
                onClick={() => {
                  setSelectedIndex(index);
                  setAlertProps({
                    variant: "delete",
                    title: "Menghapus Data",
                    content: "Apakah kamu yakin akan menghapus data ini?",
                  });
                  setShowAlert(true);
                }}
              >
                <img src={hapus} style={{ paddingRight: 10 }}></img>
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    console.log(listInventoryMoveDetailState);
    setInventoryMove(listInventoryMoveDetailState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInventoryMoveDetailState]);

  useEffect(() => {
    props.onSubmitData(inventoryMoveDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryMoveDetail]);

  useEffect(() => {
    if (productListState.length !== 0) {
      setProductList([]);
      productListState.map((product) => {
        setProductList((prevState) => [
          ...prevState,
          {
            id: product.id,
            sku: product.inventorySKU,
            productName: product.name,
            label: product.inventorySKU + " - " + product.name,
            uom: product.uom,
          },
        ]);
      });
    }
  }, [productListState]);

  useEffect(() => {
    if (variationState.length !== 0) {
      setInventoryMoveDetailTemp([]);
      variationState.map((item) => {
        let variationName = "";
        if (item.customProductAttributeList !== null) {
          if (item.customProductAttributeList.length === 1) {
            variationName += item.customProductAttributeList[0].name;
          } else if (item.customProductAttributeList.length === 2) {
            variationName +=
                item.customProductAttributeList[0].name +
                "-" +
                item.customProductAttributeList[1].name;
          } else if (item.customProductAttributeList.length === 3) {
            variationName +=
                item.customProductAttributeList[0].name +
                "-" +
                item.customProductAttributeList[1].name +
                "-" +
                item.customProductAttributeList[2].name;
          }
        }

        setInventoryMoveDetailTemp((prevState) => [
          ...prevState,
          {
            inventorySKU: productSku,
            productId: productId,
            variantId: item.id,
            variationName: variationName,
            productName: productName,
            quantity: 0,
            uomId: uomId,
            note: note,
            categoryName: selectedCategoryName,
          } as ItemReceiveDetailModel,
        ]);
      });
    }
  }, [variationState]);

  useEffect(() => {
    console.log("Category changed");
    if (productListState.length !== 0 && selectedCategory !== "") {
      setProductList([]);

      productListState.map((product) => {
        if (product.productCategoryList.includes(selectedCategory)) {
          setProductList((prevState) => [
            ...prevState,
            {
              id: product.id,
              sku: product.inventorySKU,
              productName: product.name,
              label: product.inventorySKU + " - " + product.name,
              uom: product.uom,
            },
          ]);
        }
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (ProductCategoryState.length !== 0) {
      setProductCategoryList([]);
      ProductCategoryState.map((category) => {
        setProductCategoryList((prevState) => [
          ...prevState,
          {
            id: category.id,
            name: category.name,
            code: category.code,
            isActive: category.isActive,
            level: category.level,
            parent: category.parent,
            parentName: category.parentName,
            label:
              category.parentName !== ""
                ? category.parentName + " - " + category.name
                : category.name,
          },
        ]);
      });
    }
  }, [ProductCategoryState]);

  const onSaveBtnClick = () => {
    inventoryMoveDetailTemp.map((item) => {
      if (item.quantity !== 0) {
        setInventoryMove((prevState) => [
          ...prevState,
          {
            inventorySKU: item.inventorySKU,
            productId: item.productId,
            variantId: item.variantId,
            variationName: item.variationName,
            productName: item.productName,
            quantity: item.quantity,
            uomId: item.uomId,
            note: item.note,
            categoryName: item.categoryName,
          } as ItemReceiveDetailModel,
        ]);
      }
    });

    // setInventoryMove((prevState) => [
    //   ...prevState,
    //   {
    //     inventorySKU: productSku,
    //     productId: productId.split("&")[0],
    //     variantId: productVariantId,
    //     variationName: productVariantName,
    //     productName,
    //     quantity,
    //     uom: uomId,
    //     note,
    //     categoryName: selectedCategoryName,
    //   } as ItemReceiveDetailModel,
    // ]);

    setShow(false);

    setQuantity(0);
    setNote("");
    setUomId("");
    setProduct("");
    setProductVariantId("");
    setProductVariantName("");
  };

  const handleShow = () => {
    setInventoryMoveDetailTemp([]);
    setShow(true);
  };

  const handleClose = () => {
    setQuantity(0);
    setNote("");
    setUomId("");
    setProduct("");
    setProductVariantId("");
    setProductVariantName("");
    setShow(false);
  };

  const onAlertClose = () => setShowAlert(false);

  const onDelete = () => {
    let newList = [...inventoryMoveDetail];
    newList.splice(selectedIndex, 1);
    setInventoryMove(newList);
    setShowAlert(false);
  };

  const promiseOptions = (inputValue: string) =>
      new Promise<ProductOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getProductOptions(inputValue));
            }, 1000)
        );
  });

  return (
    <div>
      <Grid textAlign="center">
        <Card>
          {isEdit ? (
            <></>
          ) : (
            <>
              <Row>
                <Col>
                  <Button
                    style={{ width: 135, height: 46, margin: 10 }}
                    variant="info"
                    type="button"
                    onClick={handleShow}
                  >
                    Add product
                  </Button>
                </Col>
              </Row>
            </>
          )}

          <DataTable
            columns={columns}
            data={inventoryMoveDetail}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>

        <div>
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="input-padding">
                  <Form.Label>Category</Form.Label>

                  <Select
                    // defaultValue={selectedPartnerId}
                    onChange={onSelectedProductCategoryChange}
                    options={productCategoryList}
                  />
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>Product</Form.Label>
                  {/* <Form.Control
                  as="select"
                  onChange={onProductChange}
                  value={productId}>
                  <option>select</option>
                  {productListState.map((Item) => {
                    return (
                      <option
                        value={Item.id + "&" + Item.name + "&" + Item.uom}>
                        {Item.inventorySKU} - {Item.name}
                      </option>
                    );
                  })}
                </Form.Control> */}

                  {/*<Select*/}
                  {/*  // defaultValue={selectedPartnerId}*/}
                  {/*  onChange={onSelectedProductChange}*/}
                  {/*  options={productList}*/}
                  {/*/>*/}

                  <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                               onChange={onSelectedProductChange} />
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>Varian</Form.Label>

                  {/* <Form.Control
                    as="select"
                    onChange={onVariantChange}
                    // value={productVariantId}
                  >
                    <option>select</option>
                    {variationState.map((Item) => {
                      let variationName = "";

                      if (Item.customProductAttributeList.length === 1) {
                        variationName +=
                          Item.customProductAttributeList[0].name;
                      } else if (Item.customProductAttributeList.length === 2) {
                        variationName +=
                          Item.customProductAttributeList[0].name +
                          "-" +
                          Item.customProductAttributeList[1].name;
                      } else if (Item.customProductAttributeList.length === 3) {
                        variationName +=
                          Item.customProductAttributeList[0].name +
                          "-" +
                          Item.customProductAttributeList[1].name +
                          "-" +
                          Item.customProductAttributeList[2].name;
                      }

                      return (
                        <option
                          value={
                            Item.id +
                            "&" +
                            variationName +
                            "&" +
                            Item.regulerPriceEndCustomer +
                            "&" +
                            Item.regulerPriceStockist +
                            "&" +
                            Item.regulerPriceReseller +
                            "&" +
                            Item.stockQty +
                            "&" +
                            Item.weight
                          }
                        >
                          {Item.customProductAttributeList.length >= 1
                            ? Item.customProductAttributeList[0].name
                            : ""}
                          {Item.customProductAttributeList.length >= 2
                            ? " - " + Item.customProductAttributeList[1].name
                            : ""}
                          {Item.customProductAttributeList.length == 3
                            ? " - " + Item.customProductAttributeList[2].name
                            : ""}
                        </option>
                      );
                    })}
                  </Form.Control> */}
                </Form.Group>

                <Row>
                  {inventoryMoveDetailTemp.map((item) => {
                    return (
                      <Col sm={6}>
                        <Card style={{ marginBottom: 12 }}>
                          <Card.Body style={{ paddingBottom: 4 }}>
                            <Row>
                              <Col
                                style={{ textAlign: "center", marginBottom: 8 }}
                              >
                                <b>{item.variationName}</b>
                              </Col>
                            </Row>

                            <Row>
                              {/* <Col
                                sm={2}
                                onClick={() => onSubQuantityClick(item)}
                              >
                                <FaMinusCircle />
                              </Col> */}
                              <Col sm={12} style={{ textAlign: "center" }}>
                                <Form.Control
                                  type="number"
                                  value={item.quantity}
                                  onChange={(e)=>{
                                    let updatedInventoryMoveDetailList = inventoryMoveDetailTemp.map((itemDetail) => {
                                      if (item === itemDetail) {
                                        return {
                                          ...itemDetail,
                                          quantity: parseInt(e.target.value),
                                        };
                                      } else {
                                        return itemDetail;
                                      }
                                    });
                                    setInventoryMoveDetailTemp(updatedInventoryMoveDetailList);
                                  }}
                                />
                                {/* {item.quantity} */}
                              </Col>
                              {/* <Col
                                sm={2}
                                onClick={() => onAddQuantityClick(item)}
                              >
                                <FaPlusCircle />
                              </Col> */}
                            </Row>
                            <br />
                            <Row>
                              <Form.Control
                                as="textarea"
                                placeholder="Note.."
                                rows={1}
                                onChange={(e: any) =>
                                  onVariantNoteChange(e, item)
                                }
                                // value={note}
                              />
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>

                <Form.Group className="input-padding">
                  <Form.Label>UOM</Form.Label>
                  <Form.Control type="text" disabled value={uomId} />
                </Form.Group>

                {/* <Form.Group className="input-padding">
                <Form.Label>Quantity</Form.Label>

                <Form.Control
                  type="number"
                  onChange={onQuantityChange}
                  value={quantity}
                />
              </Form.Group> */}

                {/* <Form.Group className="input-padding">
                  <Form.Label>Note</Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={onNoteChange}
                    value={note}
                  />
                </Form.Group> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-info"
                style={{ width: 100 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="info"
                type="submit"
                style={{ width: 100 }}
                onClick={onSaveBtnClick}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      />
    </div>
  );
};

export interface InventoryMoveDetailProps {
  onSubmitData: (inventoryMoveDetail: ItemReceiveDetailModel[]) => void;
  isEdit: boolean;
}

export default ProductTableComponent;
