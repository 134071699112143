import { endpoint } from "../../../constants";
import { BankModel } from "./bank.model";
import axios from "axios";

export default class BankApi {
  static createData(model: BankModel) {
    return axios.post<any>(endpoint + "/api/bank/Create", model).then((res) => res.data.data);
  }

  static getList() {
    return axios.get<any>(endpoint + "/api/bank/GetList").then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/bank/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: BankModel) {
    return axios.post<any>(endpoint + "/api/bank/update", model).then((res) => res.data.data);
  }

  static deleteData(model: BankModel) {
    return axios
      .post<any>(endpoint + "/api/bank/Delete?Id="+model.id)
      .then((res) => [{ response: res.data.data }, { id: model.id }]);
  }
}
