import React from "react";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Grid } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  getPointRequestListWithPaging,
} from "../point.reducer";
import { PartnerOptionModel } from "../../salesOrder/salesOrder/salesOrder.model";
import { getListPartner } from "../../salesOrder/salesOrder/salesOrder.reducer";
import {MAX_PAGING_OPTIONS} from "../../../constants";
import {TemporaryShipmentModel} from "../../salesOrder/temporaryShipment/temporaryShipment.model";
import {PointModel} from "../point.model";

const PointTable = () => {
  const dispatch = useDispatch();

  const PointListState = useSelector(
    (state: RootState) => state.point.list ?? []
  );
  const TotalRowState = useSelector(
    (state: RootState) => state.point.totalRow ?? 0
  );
  const partnerListState = useSelector(
    (state: RootState) => state.salesOrder.partnerList ?? []
  );

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle] = useState(false);

  //filter
  const [filterPartnerId, setFilterPartnerId] = useState<string | null>(null);
  const [filterPartnerType, setFilterPartnerType] = useState<number | null>(
    null
  );
  const [filterTempPartnerType, setFilterTempPartnerType] = useState(3);
  const [filterTempStatus, setFilterTempStatus] = useState(3);

  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);
  const [partnerType, setPartnerType] = useState(0);

  const onSelectedPartnerFilterChange = (data: any) => {
    setFilterPartnerId(data.value);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterPartnerId(null);
    setFilterPartnerType(null);
    setFilterTempPartnerType(3);
    setFilterTempStatus(3);

    dispatch(
        getPointRequestListWithPaging({
        partnerId: null,
        partnerType: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);
    dispatch(
        getPointRequestListWithPaging({
        partnerId: filterPartnerId,
        partnerType: filterPartnerType,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [PointListState]);

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getListPartner());
  }, []);

  useEffect(() => {
    if (partnerListState.length !== 0) {
      setPartnerList([]);
      partnerListState.map((item) => {

        if(item.type === partnerType){
          setPartnerList((prevState) => [
            ...prevState,
            {
              value: item.id,
              label: item.fullName,
              type: item.type,
            },
          ]);
        }
      });
    }
  }, [partnerListState, partnerType]);

  const columns = [
    {
      name: "CUSTOMER",
      selector: "partnerName",
      sortable: true,
    },
    {
      name: "TIPE CUSTOMER",
      selector: (d: { partnerType: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.partnerType === 0 && "End Customer"}
            {d.partnerType === 1 && "Stockist"}
            {d.partnerType === 2 && "Reseller"}
            {d.partnerType === 4 && "Admin Stockist"}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "NOMINAL",
      selector: (d: { nominal: number }) => (
        <>
          <NumberFormat
            value={d.nominal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
  ];

  const ExpandedComponent = ({ data }: {data: PointModel}) => {
    const detailColumns = [
       {
        name: "TANGGAL BUAT",
        selector: (d: { createdDate: string }) => (
            <>
              <p style={{ paddingTop: 15 }}>
                {d.createdDate !== undefined && d.createdDate.substring(0, 10)}
              </p>
            </>
        ),
        sortable: true,
      },
      {
        name: "NOMINAL",
        selector: (d: { nominal: number }) => (
            <>
              <NumberFormat
                  value={d.nominal}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="Rp. "
              />
            </>
        ),
        sortable: true,
      },
      {
        name: "NOTE",
        selector: "note",
        sortable: true,
      },
    ];

    return (
        <div style={{padding: "10px 50px"}}>
          <DataTable noHeader columns={detailColumns} data={data.details} pagination/>
        </div>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Point</Form.Label>
          </Form.Group>
        </Col>
        <Col>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>

            <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Partner Type
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempPartnerType}
                      onChange={(e) => {
                        if (e.target.value.toString() === "3") {
                          setFilterPartnerType(null);
                          setFilterTempPartnerType(parseInt(e.target.value));
                        } else {
                          setFilterTempPartnerType(parseInt(e.target.value));
                          setFilterPartnerType(parseInt(e.target.value));
                          setPartnerType(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={3}>All</option>
                      <option value={0}>End Customer</option>
                      <option value={1}>Stockist</option>
                      <option value={2}>Reseller</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Partner
                  </Form.Label>
                  <Col>
                    <Select
                        // defaultValue={selectedPartnerId}
                        // value={selected}
                        onChange={onSelectedPartnerFilterChange}
                        options={partnerList}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={4}>
                <Button
                  variant="outline-info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterResetClick}
                >
                  Reset Filter
                </Button>
                <Button
                  variant="info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterClick}
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Card.Body>

          <hr />

          <DataTable
            columns={columns}
            data={PointListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={TotalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
            expandableRows
            expandableRowsComponent={<ExpandedComponent data={{} as PointModel} />}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default PointTable;
