import React from 'react';
import ComplainDetail from '../components/ComplainDetail';

const ComplainDetailPage = ({readOnly = false}:ComplainDetailPageProps) => {
    console.log(readOnly);
  return (<div>
      <ComplainDetail readOnly={readOnly}/>
  </div>);
};

export interface ComplainDetailPageProps {
    readOnly?: boolean;
}

export default ComplainDetailPage;
