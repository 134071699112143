import { createSlice } from '@reduxjs/toolkit'
import { getPartnerSelect, getProductCategorySelect, postExportOmset, postLaporanOmset } from './laporanOmset.api'


export interface LaporanOmsetState {
  activeTab : string,
  loading : boolean,
  listCategory : any,
  listPartner : any,
}
const initialState: LaporanOmsetState = {
  activeTab : 'laporan',
  loading : false,
  listCategory : [],
  listPartner : [],
}

export const laporanOmsetSlice = createSlice({
    name: 'laporan omset',
    initialState,
    reducers: {
      handleActiveTab: (state, action) => {
        state.activeTab = action.payload
      }
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(getProductCategorySelect.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getProductCategorySelect.fulfilled, (state, action) => {
            state.loading = false
            // console.log(action.payload.data)
            state.listCategory = action.payload.data
        })
        builder.addCase(getProductCategorySelect.rejected, (state, action) => {
            state.loading = false
        })
        // Get
        builder.addCase(getPartnerSelect.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getPartnerSelect.fulfilled, (state, action) => {
            state.loading = false
            // console.log(action.payload.data)
            state.listPartner = action.payload.data
        })
        builder.addCase(getPartnerSelect.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(postExportOmset.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postExportOmset.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postExportOmset.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(postLaporanOmset.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postLaporanOmset.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postLaporanOmset.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
export const { handleActiveTab } = laporanOmsetSlice.actions

export default laporanOmsetSlice.reducer