import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RootState } from "../../../app/store";
import { useParams } from "react-router-dom";
import RegisterResellerCreate from "../components/RegisterResellerCreate";
import { PartnerRegisterModel } from "../registerReseller.model";
import { getSingleResellerData, registerReseller } from "../registerReseller.reducer";

const RegisterResellerPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);

  const singleDataState = useSelector(
    (state: RootState) => state.registerReseller.single ?? ({} as PartnerRegisterModel)
  );

  useEffect(() => {
    console.log(singleDataState);
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object && 
      singleDataState.partner.id !== undefined
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
   console.log(id)
    if (id !== undefined) {
      dispatch(getSingleResellerData(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <RegisterResellerCreate data={singleDataState} isEdit={isEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterResellerPage;
