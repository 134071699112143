import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { RootState } from "../../../app/store";
import RegisterStockistCreate from '../components/RegisterStockistCreate';
import { useParams } from 'react-router-dom';
import { PartnerRegisterModel } from "../registerStockist.model";
import { getSingleStockistData } from "../registerStockist.reducer";

const RegisterStockistPage = () => {

  const dispatch = useDispatch();
    
  let { id } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);

  const singleDataState = useSelector(
      (state: RootState) => state.registerStockist.single ?? ({} as PartnerRegisterModel)
  )

  useEffect(() => {
      console.log(singleDataState)
      if (
          singleDataState &&
          Object.keys(singleDataState).length !== 0 &&
          singleDataState.constructor === Object
      ) {
          setIsEdit(true);
      } else {
          setIsEdit(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
      console.log(id)
      if (id !== undefined) {
          dispatch(getSingleStockistData(id));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

    return (
        <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <RegisterStockistCreate
              data={singleDataState} 
              isEdit={isEdit}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    )
}

export default RegisterStockistPage
