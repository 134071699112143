import { Card, Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import hapus from "./../../../assets/vector/delete.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteData, getList } from "../role.reducer";
import edit from "./../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import { RootState } from "../../../app/store";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DeleteMessage from "../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const RoleTable = () => {
  const dispatch = useDispatch();

  const RoleState = useSelector((state: RootState) => state.role.list ?? []);

  const modifiedDateState = useSelector((state: RootState) => state.role.modifiedDate ?? "");

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedId, setSelectedId] = useState("")

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const filteredItems = RoleState.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.roleName && item.roleName.toLowerCase().includes(filterText.toLowerCase()))
  );

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const columns = [
    {
      name: "Code",
      selector: "code",
      sortable: true,
    },
    {
      name: "NAMA USER LEVEL",
      selector: "roleName",
      sortable: true,
    },
    // {
    //   name: "STATUS",
    //   selector: (d: { isActive: boolean }) => (
    //     <p style={{margin:10}}>
    //     {d.isActive === true && (
    //      <span className={"custom-badge-success"}>Active</span>
    //     )}
    //     {d.isActive === false && (
    //       <span  className={"custom-badge-secondary"} >Inactive</span>
    //     )}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "ACTION",
      selector: (d: { roleId: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-role/${d.roleId}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?")){
              //   onDelete(d.roleId);
              // } 

              setSelectedId(d.roleId);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDateState]);

  const onDelete = (id: string) => {
    
    if (id !== undefined) {

      dispatch(deleteData(id));
    }
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
      setShowAlert(false);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Role</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/Role/create",
            }}
          >
            <Button className="float-right" variant="info">
              + Add Role
            </Button>
          </Link>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text style={{ backgroundColor: "#fff", color: "#F5B95D", border: 0 }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default RoleTable;
