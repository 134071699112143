import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { ProductModel, variationModel } from "../product/product.model";
import VoucherApi from "./voucher.api";
import {
  AvailableDayModel,
  RoleModel,
  VoucherModel,
  getAvailableDayModel,
  PartnerTypeEnumModel,
  UploadImageModel,
} from "./voucher.model";

export interface VoucherSlice {
  modifiedDate?: string;
  voucherId?: string;
  list?: VoucherModel[];
  availableDay?: getAvailableDayModel[];
  single?: VoucherModel;
  enableStore?: boolean;
  isLoading?: boolean;
  selectedRoleList?: string;
  variantListByProduct? : variationModel[];
  imagePath? : string;
  partnerTypeEnum: PartnerTypeEnumModel[];
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const createData = createAsyncThunk(
  "createVoucherState/create",
  async (Model: VoucherModel, { rejectWithValue }) => {
    try {
      return await VoucherApi.create(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "getVoucherListState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await VoucherApi.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const uploadPhoto = createAsyncThunk(
  'UploadPhotoVoucherState/UploadPhotoVoucher',
  async (model: UploadImageModel, { rejectWithValue }) => {
      try {
          return await VoucherApi.uploadImage(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
)

export const getSingleItem = createAsyncThunk(
  "VoucherSingleItemState/SingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await VoucherApi.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "voucherState/update",
  async (model: VoucherModel, { rejectWithValue }) => {
    try {
      return await VoucherApi.update(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "voucherState/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      return await VoucherApi.delete(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createAvailableDayData = createAsyncThunk(
  "createAvailableDayState/create",
  async (Model: AvailableDayModel, { rejectWithValue }) => {
    try {
      return await VoucherApi.createAvailableDay(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAvailableDayData = createAsyncThunk(
  "getAvailableDayState/list",
  async (id: string, { rejectWithValue }) => {
    try {
      return await VoucherApi.getAvailableDay(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAUserRoleType = createAsyncThunk(
  "getUserRoleType/list",
  async (id: string, { rejectWithValue }) => {
    try {
      return await VoucherApi.getListUserRoleType();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPartnerTypeEnum = createAsyncThunk(
  "getPartnerTypeEnum/list",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await VoucherApi.getPartnerTypeEnum();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationByProduct = createAsyncThunk(
  "voucherState/GetListVariationByProduct",
  async (id: string, { rejectWithValue }) => {
    try {
      return await VoucherApi.getListVariationByProduct(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const VoucherSlice = createSlice({
  name: "VoucherState",
  initialState: {} as VoucherSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as VoucherModel;
      state.availableDay = [] as getAvailableDayModel[];
    },
    resetDiscountId: (state) => {
      state.voucherId = "";
    },
    resetStatus: (state) => {
      state.status = "";
    },
    setSelectedRoleList: (state, action) => {
      state.selectedRoleList = action.payload;
    },
    setEnableStore: (state, action) => {
      state.enableStore = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.modifiedDate = new Date().toISOString();
      state.isLoading = false;
      state.voucherId = payload.id;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
      state.voucherId = "";
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.voucherId = payload.id;
      state.status = "success";
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(createAvailableDayData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAvailableDayData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(createAvailableDayData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getAvailableDayData.pending, (state) => {
      state.availableDay = undefined;
      state.isLoading = true;
    });
    builder.addCase(getAvailableDayData.fulfilled, (state, { payload }) => {
      state.availableDay = payload;
      state.isLoading = false;
    });
    builder.addCase(getAvailableDayData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListVariationByProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListVariationByProduct.fulfilled, (state, { payload }) => {
      state.variantListByProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });


    builder.addCase(getPartnerTypeEnum.pending, (state) => {
      state.availableDay = undefined;
      state.isLoading = true;
    });
    builder.addCase(getPartnerTypeEnum.fulfilled, (state, { payload }) => {
      state.partnerTypeEnum = payload;
      state.isLoading = false;
    });
    builder.addCase(getPartnerTypeEnum.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(uploadPhoto.pending, (state) => {
      state.isLoading = true;
  })
  builder.addCase(uploadPhoto.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.imagePath = payload.imagepath;
  })
  builder.addCase(uploadPhoto.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
  })
  },
});

export const {
  setFilter,
  resetSingle,
  resetStatus,
  setSelectedRoleList,
  setEnableStore,
  resetDiscountId,
} = VoucherSlice.actions;
export default VoucherSlice.reducer;
