import { createSlice } from '@reduxjs/toolkit'
import {StaffPerformanceModel} from "./models";
import {getLaporanTotalPerfomaStaff} from "./reportTotalPerformance.api";

export interface TotalStaffPerformanceState {
    loading: boolean,
    list: StaffPerformanceModel[],
    totalDenda: number,
    total: number
}
const initialState: TotalStaffPerformanceState = {
    loading: false,
    list: [],
    totalDenda: 0,
    total: 0
}

export const totalStaffPerformance = createSlice({
    name: 'totalPerformanceSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // post data filter
        builder.addCase(getLaporanTotalPerfomaStaff.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getLaporanTotalPerfomaStaff.fulfilled, (state, { payload }) => {
            state.list = payload?.list ?? []
            state.total = payload?.total ?? 0
            state.loading = false
        })
        builder.addCase(getLaporanTotalPerfomaStaff.rejected, (state) => {
            state.loading = false
        })
    },
})

export default totalStaffPerformance.reducer