import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import PointApi from "./point.api";
import { FilterModel, PointModel } from "./point.model";

export interface PointSlice {
 list? : PointModel[];
 single? : PointModel;
 totalRow? : number;
 isLoading?: boolean;
 error?: ApiErrorResponse<any>;
 status?: string;
}

export const getPointRequestListWithPaging = createAsyncThunk(
 "getPointRequestListWithPagingState/getPointRequestListWithPaging",
 async (model: FilterModel, { rejectWithValue }) => {
   try {
     return await PointApi.getPointRequestListWithPaging(model);
   } catch (e) {
     return rejectWithValue(e as ApiErrorResponse<any>);
   }
 }
);

const PointSlice = createSlice({
  name: "PointState",
  initialState: {} as PointSlice,
  reducers : {
    resetSingle: (state) => {
      state.single = {} as PointModel;
      state.status = "";
    },
    resetStatus: (state) => {
      state.status = "";
    }
  },
extraReducers: (builder) => {
  builder.addCase(getPointRequestListWithPaging.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getPointRequestListWithPaging.fulfilled, (state, {payload})=> {
    state.list = payload.list;
    state.totalRow = payload.total;
    state.isLoading = false;
  });
  builder.addCase(getPointRequestListWithPaging.rejected, (state, {payload}) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
  });
},
})

export const {resetSingle,resetStatus} = PointSlice.actions;
export default PointSlice.reducer;