import React, { Component, CSSProperties, HTMLAttributes } from 'react';
import './SidebarMenu.css';

export interface SidebarMenuProps extends HTMLAttributes<HTMLUListElement> {
  menuTitle?: string;
  menuTitleStyle?: CSSProperties;
  menuTitleClass?: string;
}

export default class SidebarMenu extends Component<SidebarMenuProps> {
  render() {
    return (
      <ul className="sidebar-menu">
        {this.props.menuTitle && (
          <li
            className={`sidebar-menu__title ${this.props.menuTitleClass || ''}`}
            style={this.props.menuTitleStyle || {}}
          >
            {this.props.menuTitle}
          </li>
        )}
        {this.props.children}
      </ul>
    );
  }
}
