import OrderDetail from "../components/orderDetail";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { useEffect, useState } from "react";
import { getSingleOrderDetail } from "../keepBelumLunas.reducer";
import { getListPaymentMethod, getListProduct } from "../../../../../global/global.reducer";
import { SalesOrderModel } from "../keepBelumLunas.model";
import DataShipment from "../components/DataShipment";
import DataSalesOrderDetail from "../components/DataSalesOrderDetail";

/*
 * created by : dikembe mutombo
 * created date : 26/oct/2021
 */

const OrderDetailPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  let history = useHistory();

  const singleDataState = useSelector(
    (state: RootState) => state.keepBelumalunas.single ?? ({} as SalesOrderModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getSingleOrderDetail(id));
    dispatch(getListPaymentMethod());
    dispatch(getListProduct());
    setIsEdit(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <div onClick={() => history.goBack()} className="form-title">
          <FaChevronLeft />
          {"            "}
          Order Detail
        </div>
      </Form.Group>
      <OrderDetail data={singleDataState} isEdit={isEdit} />
      <br />
      <DataShipment />
      <br />
      <DataSalesOrderDetail />
    </Container>
  );
};

export default OrderDetailPage;
