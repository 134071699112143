import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react'
import { Card, Form, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import edit from './../../../../assets/vector/edit.png';
import hapus from './../../../../assets/vector/delete.png';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { TableCustomStyles } from '../../../../components/DataTable/TableCustomStyles';
import { RootState } from '../../../../app/store';
import { deleteData, getList } from '../collectionTag.reducer';
import { CollectionTagModel } from '../collectionTag.model';
import noImage from '../../../../assets/no-image.png';
import DeleteMessage from "../../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";


const CollectionTagTable = () => {
  const dispatch = useDispatch();
  const CollectionTagState = useSelector(
    (state: RootState) => state.collectionTag.list ?? []
  );
  
  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedId, setSelectedId] = useState("")

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });


  const filteredItems =CollectionTagState.filter(
    (item) =>
    item.name && 
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
    item.keyword && 
      item.keyword.toLowerCase().includes(filterText.toLowerCase()) ||
    item.description && 
      item.description.toLowerCase().includes(filterText.toLowerCase())
  );

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const columns = [
    // {
    //   name: "IMAGE",
    //   selector: (d: { banner: string }) => (
    //     <p style={{margin:5}}>
    //       {d.banner !== "" ? 
    //         <img src={d.banner !== "" ? "https://sandbox-emory.ultimosolution.com/" + d.banner : "none"} style={{width:50}}/>
    //         : <img src={noImage} style={{width:50}}/>
    //       }
    //     </p>
    //   ),      
    //   sortable: true,
    // },
    {
      name: "NAME",
      selector: "name",
      sortable: true,
    },
    {
      name: "Keyword",
      selector: "keyword",     
      sortable: true,
    },
    {
        name: "DESCTIPTION",
        selector: "description",     
        sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{margin:10}}>
        {d.isActive === true && (
         <span className={"custom-badge-success"}>Active</span>
        )}
        {d.isActive === false && (
          <span  className={"custom-badge-secondary"} >Inactive</span>
        )}
        </p>
      ),      
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any, name: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-collection-tag/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {'    '}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onDelete(d.id);

              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
    
    dispatch(getList());
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
      setShowAlert(false);
      dispatch(getList());
    }
  };


  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Collection Tag</Form.Label>
          </Form.Group> 
        </Col>
        <Col>
        <Link
            to={{
              pathname: "/collection-tag/create",
            }}
          >
            <Button className="float-right" variant="info">+ Add Collection Tag</Button>
          </Link>
        </Col>
      </Row>

      <Grid textAlign='center' style={{ height: '100vh' }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{width:330}}>
                  <InputGroup.Prepend className="search-input">
                  <InputGroup.Text style={{backgroundColor: '#fff', color: '#F5B95D', border:0}}><FaSearch /></InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    style={{borderLeft: 0}}
                    placeholder="Search"
                    onChange={(e) => {
                      setFilterText(e.target.value)
                    }}
                    value={filterText}
                  />
              </InputGroup>
            </Col>
            {/* <Col md={5}>
              <Form.Group as={Row} className="padding-filter mb-3 ">
                <Form.Label column sm={3} style={{fontStyle: "normal", fontWeight: "normal", fontSize: 14}}>
                  User level
                </Form.Label>
                <Col sm={6}>
                  <Form.Control as="select" style={{height: 35}}>
                    <option>select</option>
                  </Form.Control>
                </Col>
                <Col sm={2}>
                    <Button style={{width: 69, height: 35, fontSize: 14 }}  variant="outline-info">Reset</Button>
                </Col>
              </Form.Group>
            </Col> */}
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
      
      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  )
};

export default CollectionTagTable;
