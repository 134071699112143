import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../../app/store";
import { endpoint } from "../../../../constants";
import { SalesOrderModel } from "../../pesananDalamProses/keepBelumLunas/keepBelumLunas.model";
import { getSingleOrderDetail } from "../../pesananDalamProses/keepBelumLunas/keepBelumLunas.reducer";
import DataSalesOrderDetail from "./DataSalesOrderDetail";
import DataShipment from "./DataShipment";

/*
 * created by : dikembe mutombo
 * created date : 26/oct/2021
 */

const ScanPesananForm = () => {
  const dispatch = useDispatch();

  const paymentMethodState = useSelector(
    (state: RootState) => state.global.listPaymentMethod ?? []
  );

  const singleDataState = useSelector(
    (state: RootState) => state.keepBelumalunas.single ?? ({} as SalesOrderModel)
  );

  const [id, setId] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [SOnumber, setSOnumber] = useState("");
  const [statusOrder, setStatusOrder] = useState(0);
  const [orderType, setOrderType] = useState(0);
  const [dateFormater, setDateFormater] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  const [partnerName, setPartnerName] = useState("");

  useEffect(() => {
    dispatch(getSingleOrderDetail(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor == Object
    ) {
      setStatusOrder(singleDataState.statusOrder);
      setPaymentMethodId(singleDataState.paymentMethodId);
      setOrderType(singleDataState.orderType);
      setDateFormater(changeDateFormat(singleDataState.orderDate));
      setPartnerEmail(singleDataState.partnerEmail);
      setPartnerName(singleDataState.partnerName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  const changeDateFormat = (date: Date) => {
    var hari = ["Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu", "Minggu"];
    var strArray = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    let asd = new Date(date);
    let d = asd.getDate();
    let day = hari[asd.getDay()];
    let m = strArray[asd.getMonth()];
    let y = asd.getFullYear();
    console.log(day);
    return day + "," + (d <= 9 ? "0" + d : d) + " " + m + " " + y;
  };

  const onClickBtn = () => {
    axios
      .get<any>(endpoint + "/api/SalesOrder/GetSingleBySONumber?soNumber=" + SOnumber)
      .then((res) => setId(res.data.data.salesOrder.id));
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <h5>Scan Pesanan</h5>
          <hr />
          <Form>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={2}>
                Masukan Nomor SO 
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  value={SOnumber}
                  onChange={(e) => setSOnumber(e.target.value)}
                ></Form.Control>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  size="sm"
                  style={{ height: "calc(1.5em + .75rem + 2px)" }}
                  onClick={onClickBtn}
                >
                  Search
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>

      <br />

      {id !== "" && (
        <Grid textAlign="center">
          <Card>
            <Card.Body>
              <h5>Order Data</h5>
              <hr />
              <Row>
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      SO Number
                    </Form.Label>
                    <Form.Label column sm={1}>
                      :
                    </Form.Label>
                    <Form.Label column sm={4}>
                      {/* {id !== undefined && id.slice(0, 6).toUpperCase()} */}
                      {SOnumber}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Order Type
                    </Form.Label>
                    <Form.Label column sm={1}>
                      :
                    </Form.Label>
                    <Form.Label column sm={4}>
                      {orderType === 0 && "Pesanan Dalam Proses"}
                      {orderType === 1 && "Pesanan Bayar Di tempat (Lunas)"}
                      {orderType === 2 && "Pesanan Belum Lunas (Rekap / COD)"}
                      {orderType === 3 && "Pesanan Bayar Cash (Lunas)"}
                      {orderType === 4 && "Pesanan Dropship (Belum Lunas)"}
                      {orderType === 5 && "Pesanan Dropship (Lunas)"}
                      {orderType === 6 && "Pesanan Piutang (Belum Lunas)"}
                      {orderType === 7 && "Pesanan Tanpa Konfirmasi Pembayarans"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <hr style={{ margin: 0 }} />

              <Row>
                <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Customer
                    </Form.Label>
                    <Form.Label column sm={1}>
                      :
                    </Form.Label>
                    <Form.Label column sm={4}>
                      {partnerName}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Payment Status
                    </Form.Label>
                    <Form.Label column sm={1}>
                      :
                    </Form.Label>
                    <Form.Label column sm={6}>
                      {statusOrder === 1 ? "Paid" : "Unpaid"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <hr style={{ margin: 0 }} />

              <Row>
                <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Email
                    </Form.Label>
                    <Form.Label column sm={1}>
                      :
                    </Form.Label>
                    <Form.Label column sm={6}>
                      {partnerEmail}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Payment Method
                    </Form.Label>
                    <Col sm={1}>:</Col>
                    <Col sm={6}>
                      <Form.Control
                        as="select"
                        value={paymentMethodId}
                        onChange={(e) => {
                          setPaymentMethodId(e.target.value);
                        }}
                        disabled
                      >
                        <option>select</option>
                        {paymentMethodState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <hr style={{ margin: 0 }} />

              <Row>
                <Col md={6} style={{ marginTop: 15, marginBottom: 0 }}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Order Date
                    </Form.Label>
                    <Form.Label column sm={1}>
                      :
                    </Form.Label>
                    <Form.Label column sm={6}>
                      {dateFormater}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <h5>Action</h5>
              <hr />
              <Row>
                <Col md={2}>
                  <Form.Check
                    label="Paid"
                    name="group1"
                    type="radio"
                    disabled
                    style={{ paddingBottom: 10 }}
                    checked={statusOrder === 1}
                    value={1}
                    onChange={() => setStatusOrder(1)}
                  />
                  <br />
                  <Form.Check
                    label="Unpaid"
                    name="group1"
                    type="radio"
                    disabled
                    style={{ paddingBottom: 10 }}
                    checked={statusOrder === 0}
                    value={0}
                    onChange={() => setStatusOrder(0)}
                  />
                  <br />
                </Col>
                <Col md={7}>
                  <Button style={{ fontSize: 14, width: 202, height: 40 }} variant="outline-info">
                    Print Purhcase Order
                  </Button>{" "}
                  <Button style={{ fontSize: 14, width: 191, height: 40 }} variant="outline-info">
                    Print Shipping Data
                  </Button>{" "}
                  <Button style={{ fontSize: 14, width: 154, height: 40 }} variant="outline-info">
                    Export To pdf
                  </Button>{" "}
                  <Row>
                    <Col md={2}>
                      <Card.Subtitle
                        style={{
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: 12,
                          lineHeight: 5,
                          width: 110,
                        }}
                        className="mb-3 text-muted "
                      >
                        Action Description :{" "}
                      </Card.Subtitle>
                    </Col>
                    <Col style={{ marginTop: 15, marginLeft: 7 }}>
                      <div
                        style={{
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: 12,
                          color: "#00A8CC",
                        }}
                        className=""
                      >
                        * Already printed purchase order
                      </div>
                      <div
                        style={{
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: 12,
                          color: "#00A8CC",
                        }}
                        className=""
                      >
                        * Already printed shipping data
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <DataShipment id={id} />
          <br />
          <DataSalesOrderDetail id={id} />
        </Grid>
      )}
    </div>
  );
};

export default ScanPesananForm;
