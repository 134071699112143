import "./styles.css";
import { Card, Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { getUserRole, resetSingle, resetStatus } from "../user.reducers";
import { Link, useHistory, useParams } from "react-router-dom";
import AlertMessage from "../../../components/alertMessage";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ChangeEvent, useEffect } from "react";
import { RootState } from "../../../app/store";
import { DragSwitch } from "react-dragswitch";
import { Grid } from "semantic-ui-react";
import { UserModel } from "../models";
import { useState } from "react";
import {Select} from "antd";

const UserCreate = ({ data = {} as UserModel, isEdit = false, ...props }: UserFormProps) => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  let history = useHistory();

  const createStatus = useSelector((state: RootState) => state.account.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.account.error ?? "");

  const RoleListState = useSelector((state: RootState) => state.account.roleList ?? []);

  useEffect(() => {
    dispatch(getUserRole());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [userRole, setUserRole] = useState("");
  const [validated, setValidated] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [multipleRole, setMultipleRole] = useState<Array<string>>([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onEmailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  const onPincodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPincode(e.target.value);
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setConfirmPassword(e.target.value);
  };

  const onUserRoleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserRole(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = "";

      if (message.message === "error") {
        xhr = message.data[0].description;
      } else {
        xhr = message.message;
      }

      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: xhr,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor === Object) {
      setName(data.name);
      setEmail(data.email);
      setUsername(data.username);
      setPincode(data.pinCode);
      setPassword(data.passwordLog);
      setConfirmPassword(data.passwordLog);
      setIsActive(data.isActive);
      setUserRole(data.roleName);
      setMultipleRole(data.roleName?.split(","));
    } else {
      setName("");
      setEmail("");
      setUsername("");
      setPincode("");
      setPassword("");
      setConfirmPassword("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/user/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const togglePasswordInvisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleChange = (e: any) => {
    setMultipleRole(e);
  }
console.log(multipleRole.toString());
  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div>{isEdit ? "Edit Data User" : "Add Data User"}</div>
            <Form
              autoComplete="off"
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    name,
                    username,
                    email,
                    pincode,
                    password,
                    confirmPassword,
                    multipleRole.toString(),
                    isActive,
                    data.id
                  );
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Username
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    onChange={onUsernameChange}
                    value={username}
                    required
                    placeholder="name"
                  />
                </Col>
              </Form.Group>
              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  User Full Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    onChange={onNameChange}
                    value={name}
                    required
                    placeholder="Full Name"
                  />
                </Col>
              </Form.Group>
              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Email
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={onEmailChange}
                    value={email}
                    required
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Password
                </Form.Label>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Control
                      type={passwordShown ? "text" : "password"}
                      onChange={onPasswordChange}
                      value={password}
                      required
                      placeholder="Password"
                      autoComplete="new-password"
                    />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text
                        style={{
                          backgroundColor: "#fff",
                          color: "#F5B95D",
                          border: 0,
                        }}
                        onClick={togglePasswordInvisibility}
                      >
                        {passwordShown ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                  {/* {
                    password.length < 8 ? 
                    <Form.Label className="card-subtitle"> Must be at least 8 Character</Form.Label> : <></>
                  }
                  {
                    password.toLowerCase() ? <></> :
                    <Form.Label className="card-subtitle"> Must obtain 1 uppercase letter and numeric</Form.Label> 
                  } */}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Confirm Password
                </Form.Label>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Control
                      type={passwordShown ? "text" : "password"}
                      onChange={onConfirmPasswordChange}
                      value={confirmPassword}
                      required
                      placeholder="Confirm Password"
                    />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text
                        style={{
                          backgroundColor: "#fff",
                          color: "#F5B95D",
                          border: 0,
                        }}
                        onClick={togglePasswordInvisibility}
                      >
                        {passwordShown ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                  {password !== confirmPassword ? (
                    <Form.Label className="card-subtitle">Both password must match</Form.Label>
                  ) : (
                    <></>
                  )}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  User PIN code
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    onChange={onPincodeChange}
                    value={pincode}
                    required
                    placeholder="PIN"
                  />
                </Col>
              </Form.Group>
              <hr />
              {/*<Form.Group as={Row} className="input-padding">*/}
              {/*  <Form.Label column sm={3}>*/}
              {/*    User Level*/}
              {/*  </Form.Label>*/}
              {/*  <Col sm={4}>*/}
              {/*    <Form.Control as="select" onChange={onUserRoleChange} value={userRole}>*/}
              {/*      <option>Select</option>*/}
              {/*      {RoleListState.map((Item) => {*/}
              {/*        return <option value={Item.roleName}>{Item.roleName}</option>;*/}
              {/*      })}*/}
              {/*    </Form.Control>*/}
              {/*  </Col>*/}
              {/*</Form.Group>*/}
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  User Level
                </Form.Label>
                <Col sm={4}>
                  <Select
                      mode="multiple"
                      placeholder="Role"
                      value={multipleRole}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                  >
                    {RoleListState.map((item) => (
                        <Select.Option key={item.roleName} value={item.id}>
                          {item.roleName}
                        </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Form.Group>
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}
              <Row>
                <Col sm={6}>
                  <Link to={{ pathname: `/user/index` }}>
                    <Button className="btn-cancel" variant="light">
                      Cancel
                    </Button>
                    {"  "}
                  </Link>{" "}
                  <Button style={{ width: 130, height: 46 }} variant="info" type="submit">
                    Save
                  </Button>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
    </div>
  );
};

export interface UserFormProps {
  onSubmit: (
    name: string,
    username: string,
    email: string,
    pincode: string,
    password: string,
    confirmPassword: string,
    roleName: string,
    isActive: boolean,
    userId: string
  ) => void;
  data: UserModel;
  isEdit: boolean;
}

export default UserCreate;
