import { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../app/store";
import InventoryMoveTable from "../components/inventoryMoveTable";
import { getList, resetSingle } from "../inventoryMove.reducer";

const InventoryMoveIndex = () => {
  const dispatch = useDispatch();

  const modifiedDateState = useSelector(
    (state: RootState) => state.inventoryMove.modifiedDate ?? ""
  );


  useEffect(() => {
    // dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDateState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="menu-title">Inventory Move</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/inventory-move/create",
            }}
          >
            <Button
              className="float-right"
              style={{ width: 230 }}
              variant="info"
            >
              + Add Inventory Move
            </Button>
          </Link>
        </Col>
      </Row>

      <InventoryMoveTable />
    </Container>
  );
};

export default InventoryMoveIndex;
