import { createConfigureTerm, getParentList } from "../attribute.reducer";
import ConfigureTermsForm from "../components/configureTermsForm";
import { ConfigureTermsModel } from "../attribute.model";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const ConfigureTermsCreatePage = () => {
  const dispatch = useDispatch();

  let { attributeId } = useParams<{ attributeId: string }>();

  useEffect(() => {
    dispatch(getParentList(attributeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (
    name: string,
    slug: string,
    parentId: string,
    productAttributeId: string,
    description: string,
    color: string
  ) => {
    dispatch(
      createConfigureTerm({
        name,
        slug,
        parentId : parentId,
        productAttributeId: attributeId,
        description,
        color
      } as ConfigureTermsModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <ConfigureTermsForm onSubmit={onSubmit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfigureTermsCreatePage;
