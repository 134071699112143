import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CityModel, ProvinceModel } from "./city.model";
import { ApiErrorResponse } from "../../..";
import CityApi from "./city.api";

export interface CitySlice {
  name?: string;
  list?: CityModel[];
  single?: CityModel;
  listProvince?: ProvinceModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const createData = createAsyncThunk(
  "createState/create",
  async (Model: CityModel, { rejectWithValue }) => {
    try {
      return await CityApi.createData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "getListState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await CityApi.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "SingleItemState/SingleItem",
  async (customerId: string, { rejectWithValue }) => {
    try {
      return await CityApi.getSingleItem(customerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "State/update",
  async (model: CityModel, { rejectWithValue }) => {
    try {
      return await CityApi.updateData(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "DeleteCityState/deleteCity",
  async (id: string, { rejectWithValue }) => {
    try {
      return await CityApi.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProvince = createAsyncThunk(
  "ProvinceState/Province",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await CityApi.getProvince();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const CitySlice = createSlice({
  name: "CityState",
  initialState: {} as CitySlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as CityModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = "";
      state.status = "success";
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getProvince.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProvince.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.listProvince = payload;
    });
    builder.addCase(getProvince.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = CitySlice.actions;
export default CitySlice.reducer;
