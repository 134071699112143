import { ChangeEvent, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap';

import { Link, useHistory, useParams } from 'react-router-dom';
import { PaymentConfirmationModel } from '../paymentConfirmation.model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { resetSingle, resetStatus } from '../paymentConfirmation.reducer';
import AlertMessage from '../../../../components/alertMessage';
import { DragSwitch } from 'react-dragswitch';

const StockistForm = ({
  data = {} as PaymentConfirmationModel,
  isEdit = false,
  ...props
}: StockistFormProps) => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const createStatus = useSelector(
    (state: RootState) => state.supplier.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.supplier.error ?? ""
  );

  let history = useHistory();
  const [type, setType] = useState(2);
  const [isEnable , setIsEnable] = useState(true);
  const [isEnableEmailSeller , setIsEnableEmailSeller ] = useState(true);
  const [emailSeller, setEmailSeller] = useState("");
  const [emailSubjectSeller , setEmailSubjectSeller ] = useState("");
  const [emailContentSeller , setEmailContentSeller ] = useState("");
  const [isEnableEmailBuyer, setIsEnableEmailBuyer] = useState(true);
  const [emailBuyer , setEmailBuyer] = useState("");
  const [emailSubjectBuyer , setEmailSubjectBuyer] = useState("");
  const [emailContentBuyer , setEmailContentBuyer] = useState("");
  const [textLinkOrderNotification , setTextLinkOrderNotification] = useState("");
  const [urlOrderNotification , setUrlOrderNotification] = useState("");
  const [notificationOrder , setNotificationOrder] = useState("");
  const [isEnableTransferWarningBox , setIsEnableTransferWarningBox] = useState(true);
  const [transferAlertFormNotification , setTransferAlertFormNotification] = useState("");
  const [isEnableRedirect , setIsEnableRedirect] = useState(true);
  const [redirectURL , setRedirectURL] = useState("");
  const [successNotification , setSuccessNotification] = useState("");
  const [errorInvoiceIDNotification , setErrorInvoiceIDNotification] = useState("");
  const [notificationEmailErrorForm , setNotificationEmailErrorForm] = useState("");
  const [notificationFormFileTypeError , setNotificationFormFileTypeError] = useState("");
  const [captchaErrorNotification , setCaptchaErrorNotification] = useState("");
  const [isEnableCaptcha, setIsEnableCaptcha] = useState(true);
  const [reCaptchaSiteKey, setReCaptchaSiteKey ] = useState("");
  const [reCaptchaSecretKey, setReCaptchaSecretKey ] = useState("");
  const [bankOptionsFormSetting, setBankOptionsFormSetting ] = useState("");
  const [orderIDFormLabel, setOrderIDFormLabel] = useState("");
  const [nameLabel , setNameLabel ] = useState("");
  const [emailLabel , setEmailLabel] = useState("");
  const [bankLabel , setBankLabel] = useState("");
  const [labelPaymentDate , setLabelPaymentDate] = useState("");
  const [valueTransferLabel, setValueTransferLabel] = useState("");
  const [proffOfPaymentRequired, setProffOfPaymentRequired] = useState(true);
  const [recordLabels, setRecordLabels] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] =useState(false);
  const [alertProps, setAlertProps] = useState({
    variant:"",
    title:"",
    content:"",
  })

  const onEmailSellerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailSeller(e.target.value);
  };

  const onEmailSubjectSellerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailSubjectSeller(e.target.value);
  };

  const onEmailContentSellerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmailContentSeller(e.target.value);
  };

  const onEmailBuyerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmailBuyer(e.target.value);
  };

  const onEmailSubjectBuyerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmailSubjectBuyer(e.target.value);
  };

  const onEmailContentBuyerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmailContentBuyer(e.target.value);
  };

  const onTextLinkOrderNotificationChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextLinkOrderNotification(e.target.value);
  };

  const onUrlOrderNotificationChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUrlOrderNotification(e.target.value);
  };

  const onNotificationOrderChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotificationOrder(e.target.value);
  };

  const onTransferAlertFormNotificationChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTransferAlertFormNotification(e.target.value);
  };

  const onRedirectURLChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRedirectURL(e.target.value);
  };

  const onSuccessNotificationChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSuccessNotification(e.target.value);
  };

  const onErrorInvoiceIDNotificationChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setErrorInvoiceIDNotification(e.target.value);
  };

  const onNotificationEmailErrorFormChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotificationEmailErrorForm(e.target.value);
  };

  const onNotificationFormFileTypeErrorChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotificationFormFileTypeError(e.target.value);
  };

  const onCaptchaErrorNotificationChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCaptchaErrorNotification(e.target.value);
  };

  const onReCaptchaSiteKeyChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReCaptchaSiteKey(e.target.value);
  };

  const onReCaptchaSecretKeyChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReCaptchaSecretKey(e.target.value);
  };

  const onBankOptionsFormSettingChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBankOptionsFormSetting(e.target.value);
  };

  const onOrderIDFormLabelChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOrderIDFormLabel(e.target.value);
  };

  const onNameLabelChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNameLabel(e.target.value);
  };

  const onEmailLabelChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmailLabel(e.target.value);
  };

  const ontBankLabelChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBankLabel(e.target.value);
  };

  const onLabelPaymentDateChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setLabelPaymentDate(e.target.value);
  };

  const onValueTransferLabelChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValueTransferLabel(e.target.value);
  };

  const onRecordLabelsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRecordLabels(e.target.value);
  };

  useEffect(() => {
    if (createStatus === "success"){
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content:" "
      });
      setShowAlert(true);
    } 
    else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message
      })
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus])

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object ) {
      setIsEnableEmailSeller(data.isEnableEmailSeller)
      setEmailSeller(data.emailSeller)
      setEmailSubjectSeller(data.emailSubjectSeller)
      setEmailContentSeller(data.emailContentSeller) 
      setIsEnableEmailBuyer(data.isEnableEmailBuyer)
      setEmailBuyer(data.emailBuyer)
      setEmailSubjectBuyer(data.emailSubjectBuyer)
      setEmailContentBuyer(data.emailContentBuyer)
      setTextLinkOrderNotification(data.textLinkOrderNotification)
      setUrlOrderNotification(data.urlOrderNotification)
      setNotificationOrder(data.notificationOrder)
      setIsEnableTransferWarningBox(data.isEnableTransferWarningBox)
      setTransferAlertFormNotification(data.transferAlertFormNotification)
      setIsEnableRedirect(data.isEnableRedirect)
      setRedirectURL(data.redirectURL)
      setSuccessNotification(data.successNotification)
      setErrorInvoiceIDNotification(data.errorInvoiceIDNotification)
      setNotificationEmailErrorForm(data.notificationEmailErrorForm)
      setNotificationFormFileTypeError(data.notificationFormFileTypeError)
      setCaptchaErrorNotification(data.captchaErrorNotification)
      setIsEnableCaptcha(data.isEnableCaptcha)
      setReCaptchaSiteKey(data.reCaptchaSiteKey)
      setReCaptchaSecretKey(data.reCaptchaSecretKey)
      setBankOptionsFormSetting(data.bankOptionsFormSetting) 
      setOrderIDFormLabel(data.orderIDFormLabel)
      setNameLabel(data.nameLabel)
      setEmailLabel(data.emailLabel)
      setBankLabel(data.bankLabel)
      setLabelPaymentDate(data.labelPaymentDate)
      setValueTransferLabel(data.valueTransferLabel)
      setProffOfPaymentRequired(data.proffOfPaymentRequired)
      setRecordLabels(data.recordLabels)
    } else{
      setIsEnableEmailSeller(true)
      setEmailSeller("")
      setEmailSubjectSeller("")
      setEmailContentSeller("")
      setIsEnableEmailBuyer(true)
      setEmailBuyer("")
      setEmailSubjectBuyer("")
      setEmailContentBuyer("")
      setTextLinkOrderNotification("")
      setUrlOrderNotification("")
      setNotificationOrder("")
      setIsEnableTransferWarningBox(true)
      setTransferAlertFormNotification("")
      setIsEnableRedirect(true)
      setRedirectURL("")
      setSuccessNotification("")
      setErrorInvoiceIDNotification("")
      setNotificationEmailErrorForm("")
      setNotificationFormFileTypeError("")
      setCaptchaErrorNotification("")
      setIsEnableCaptcha(true)
      setReCaptchaSiteKey("")
      setReCaptchaSecretKey("")
      setBankOptionsFormSetting("")
      setOrderIDFormLabel("")
      setNameLabel("")
      setEmailLabel("")
      setBankLabel("")
      setLabelPaymentDate("")
      setValueTransferLabel("")
      setProffOfPaymentRequired(true)
      setRecordLabels("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onAlertClose = () => {
    setShowAlert(false);
    if(createStatus === "success"){
      dispatch(resetStatus());
      history.replace("/payment-confirmation/index");
    }
  }

  return (
    <div style={{paddingTop:10}}>
      <Grid textAlign='center'>
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => {
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
              e.preventDefault();
              e.stopPropagation();
            } else {
              e.preventDefault();
              props.onSubmit(
                type,
                isEnableEmailSeller,
                emailSeller,
                emailSubjectSeller,
                emailContentSeller,
                isEnableEmailBuyer,
                emailBuyer,
                emailSubjectBuyer,
                emailContentBuyer,
                textLinkOrderNotification,
                urlOrderNotification,
                notificationOrder,
                //isEnableTransferWarningBox: boolean,
                transferAlertFormNotification,
                isEnableRedirect,
                redirectURL,
                successNotification,
                errorInvoiceIDNotification,
                notificationEmailErrorForm,
                notificationFormFileTypeError,
                captchaErrorNotification,
                isEnableCaptcha,
                reCaptchaSiteKey,
                reCaptchaSecretKey,
                bankOptionsFormSetting,
                orderIDFormLabel,
                nameLabel,
                emailLabel,
                bankLabel,
                labelPaymentDate,
                valueTransferLabel,
                proffOfPaymentRequired,
                recordLabels
              );
            }
            setValidated(true);
          }}
        >
          <Card style={{padding:20}}>
            <h6>GENERAL</h6> 
            <section>
              <hr/>
              <h6>Email Seller Setting</h6>
              <span className="card-subtitle" style={{fontSize:14}}>Customization Email for send to seller's mail</span>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Enable/Disable
                </Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label= "Enable notification seller. (Default: Active)"
                    name="group1"
                    type="checkbox"
                    onChange={(e) =>{setIsEnableEmailSeller(e.target.checked)}}
                    defaultChecked
                    />
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Email</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onEmailSellerChange}
                    value={emailSeller}
                    />
                  <Form.Label className="card-subtitle"> Email Seller (Multiple email separate with comma)</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Email Subject</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onEmailSubjectSellerChange}
                    value={emailSubjectSeller}
                    />
                  <Form.Label className="card-subtitle"> Email Subject For seller</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Email Content</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    required
                    onChange={onEmailContentSellerChange}
                    value={emailContentSeller}
                    />
                  <Form.Label className="card-subtitle">Content Email for Seller</Form.Label>
                </Col>
              </Form.Group>
            </section>

            <section>
            <hr/>
              <h6>Email Buyer Setting</h6>
              <span className="card-subtitle" style={{fontSize:14}}>Customization Email for send to buyer’s mail</span>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Enable/Disable
                </Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label= "Enable notification seller. (Default: active)"
                    name="group1"
                    type="checkbox"
                    onChange={(e) =>{setIsEnableEmailBuyer(e.target.checked)}}
                    defaultChecked
                    />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Email Subject</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onEmailSubjectBuyerChange}
                    value={emailSubjectBuyer}
                    />
                  <Form.Label className="card-subtitle"> Email Subject For Buyer</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Email Content</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    required
                    onChange={onEmailContentBuyerChange}
                    value={emailContentBuyer}
                    />
                  <Form.Label className="card-subtitle">Content Email for Buyer</Form.Label>
                </Col>
              </Form.Group>
            </section>
          </Card>

          <br/>

          <Card style={{padding:20}}>
            <h6>NOTIFICATION</h6> 
            <section>
              <hr/>
              <h6 style={{paddingTop:10, paddingBottom:10}}>Notification Order Settings</h6>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Notifikasi Order Text Link
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text" 
                    required
                    onChange={onTextLinkOrderNotificationChange}
                    value={textLinkOrderNotification}
                  />
                  <Form.Label className="card-subtitle"> Masukkan teks untuk title tombol konfirmasi di email</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Notifikasi Order URL
                </Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onUrlOrderNotificationChange}
                    value={urlOrderNotification}
                    />
                  <Form.Label className="card-subtitle"> Masukkan url untuk title tombol konfirmasi di email</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Notifikasi Order</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    required
                    onChange={onNotificationOrderChange}
                    value={notificationOrder}
                  />
                  <Form.Label className="card-subtitle">Enter the text for the notification message on the page</Form.Label>
                </Col>
              </Form.Group>
            </section>

            <section>
              <hr/>
              <h6 style={{paddingTop:10, paddingBottom:10}}>Notification Form Settings</h6>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Enable/Disable
                </Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label= "Enable notification seller. (Default: active)"
                    name="group1"
                    type="checkbox"
                    defaultChecked
                    />
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Notifikasi Form Peringatan Transfer
                </Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    required
                    onChange={onTransferAlertFormNotificationChange}
                    value={transferAlertFormNotification}
                  />
                  <Form.Label className="card-subtitle">Teks ini akan muncul ketika pembeli membuka form konfirmasi</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Enable/Disable</Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label= "Enable Redirect"
                    name="group1"
                    type="checkbox"
                    defaultChecked
                    onChange={(e) =>{setIsEnableRedirect(e.target.checked)}}
                    />
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Redirect URL</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onRedirectURLChange}
                    value={redirectURL}
                  />
                  <br/>
                  <Form.Label className="card-subtitle">URL untuk diredirect setelah pembeli sukses mengisi form konfirmasi pembayaran. Contoh: http://agenwebsite.com</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Success Notification</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                     as="textarea" 
                     rows={3}
                     required
                     onChange={onSuccessNotificationChange}
                    value={successNotification}
                  />
                  <br/>
                  <Form.Label className="card-subtitle">This text will displayed if a buyer is successful completion of payment confirmation</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Error Invoice ID Notification</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                     as="textarea" 
                     rows={3}
                     required
                     onChange={onErrorInvoiceIDNotificationChange}
                     value={errorInvoiceIDNotification}
                  />
                  <br/>
                  <Form.Label className="card-subtitle"> This text will displayed if a buyer is failed to fill out the form because No payment confirmation Invoice not found</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Notifikasi Form Email Error</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    required
                    onChange={onNotificationEmailErrorFormChange}
                    value={notificationEmailErrorForm}
                    />
                    <br/>
                  <Form.Label className="card-subtitle">Teks ini akan muncul jika pembeli gagal mengisi form konfirmasi pembayaran karena No Invoice dan Email tidak cocok</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Notifikasi Form File Type Error</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    required
                    onChange={onNotificationFormFileTypeErrorChange}
                    value={notificationFormFileTypeError}
                  />
                  <br/>
                  <Form.Label className="card-subtitle"> 
                    Teks ini akan muncul jika pembeli gagal mengisi form konfirmasi 
                    pembayaran karena tipe file bukti pembayaran tidak diperbolehkan.
                    Tipe file yang diperbolehkan jpg, jpeg, jpe, png, pdf</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Notifikasi Captcha Error</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    required
                    onChange={onCaptchaErrorNotificationChange}
                    value={captchaErrorNotification}
                  />
                  <br/>
                  <Form.Label className="card-subtitle"> 
                  Teks ini akan muncul jika pembeli gagal mengisi form konfirmasi 
                  pembayaran karena captcha salah</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
            </section>
          </Card>
          
          <br/>

          <Card style={{padding:20}}>
            <h6>FORM</h6> 
            <section>
              <hr/>
              <h6>reCaptcha Settings</h6>
              <span className="card-subtitle" style={{fontSize:14}}>
                Sebelum menggunakan reCaptcha, Anda harus daftar disini
                Setelah Anda mendaftar, masukkan site key dan secret key</span>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Enable/Disable
                </Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label= "Enable capcha. (Default: Active)"
                    name="group1"
                    type="checkbox"
                    defaultChecked
                    onChange={(e) => {setIsEnableCaptcha(e.target.checked)}}
                    />
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Site Key</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onReCaptchaSiteKeyChange}
                    value={reCaptchaSiteKey}
                    />
                  <Form.Label className="card-subtitle">Masukkan reCaptcha SiteKey</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Secret Key</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onReCaptchaSecretKeyChange}
                    value={reCaptchaSecretKey}
                    />
                  <Form.Label className="card-subtitle">Masukkan reCaptcha SecretKey</Form.Label>
                </Col>
              </Form.Group>
            </section>

            <section>
              <hr/>
              <h6 style={{paddingTop:10, paddingBottom:10}}>Form Settings</h6>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Bank Options
                </Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onBankOptionsFormSettingChange}
                    value={bankOptionsFormSetting}
                    />
                  <Form.Label className="card-subtitle"> Add Bank Option for show to payment confirmation page</Form.Label>
                </Col>
              </Form.Group>
            </section>
            <section>
              <hr/>
              <h6 style={{paddingTop:10, paddingBottom:10}}>Form Label Settings</h6>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Order ID
                </Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onOrderIDFormLabelChange}
                    value={orderIDFormLabel}
                    />
                  <Form.Label className="card-subtitle"> Label of Order ID</Form.Label>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Label Name</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onNameLabelChange}
                    value={nameLabel}
                    />
                  <Form.Label className="card-subtitle">Label form name</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Email Label</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onEmailLabelChange}
                    value={emailLabel}
                    />
                  <Form.Label className="card-subtitle">Label form email</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Bank Label</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={ontBankLabelChange}
                    value={bankLabel}
                    />
                  <Form.Label className="card-subtitle">Label form Bank</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Label Payment Date</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onLabelPaymentDateChange}
                    value={labelPaymentDate}
                    />
                  <Form.Label className="card-subtitle">Label form Payment Date</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Value Transfer Labels</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onValueTransferLabelChange}
                    value={valueTransferLabel}
                    />
                  <Form.Label className="card-subtitle">Payment Of Transfer Label</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Bukti Pembayaran Label</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    // onChange={onpro}
                    // value={labelPaymentDate}
                    />
                  <Form.Label className="card-subtitle">Label bukti pembayaran</Form.Label>
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Bukti Pembayaran Requred?</Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label= "Required Bukti Pembayaran"
                    name="group1"
                    type="checkbox"
                    onChange={(e) =>{setProffOfPaymentRequired(e.target.checked)}}
                    defaultChecked
                  />
                </Col>
              </Form.Group>
              <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>Record labels</Form.Label>
                <Col sm={4}>
                  <Form.Control 
                    type="text" 
                    required
                    onChange={onRecordLabelsChange}
                    value={recordLabels}
                    />
                  <Form.Label className="card-subtitle">Label form notes</Form.Label>
                </Col>
              </Form.Group>
            </section>
          
          { isEdit ?
            <>
            <hr/>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Active Status
                </Form.Label>
                <Col sm={4}>
                  <DragSwitch 
                    checked={isActive} 
                    onChange={(e) => {
                      setIsActive(e)
                    }}/>
                  <br/>
                </Col>
              </Form.Group> 
            </>: <></>
          }

          <hr/> 
          <div className='btn-footer-group'>
            <Button variant="info" style={{width:130, height:45}} type="submit">Save</Button>
          </div>
        </Card>
        </Form>
      </Grid>
      
      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
      
    </div>
  )
};

export interface StockistFormProps {
  onSubmit: (
    type:number,
    //isEnable: boolean,
    isEnableEmailSeller: boolean,
    emailSeller: string,
    emailSubjectSeller: string,
    emailContentSeller: string,
    isEnableEmailBuyer: boolean,
    emailBuyer: string,
    emailSubjectBuyer: string,
    emailContentBuyer: string,
    textLinkOrderNotification: string,
    urlOrderNotification: string,
    notificationOrder: string,
    //isEnableTransferWarningBox: boolean,
    transferAlertFormNotification: string,
    isEnableRedirect: boolean,
    redirectURL: string,
    successNotification: string,
    errorInvoiceIDNotification: string,
    notificationEmailErrorForm: string,
    notificationFormFileTypeError: string,
    captchaErrorNotification: string,
    isEnableCaptcha: boolean,
    reCaptchaSiteKey: string,
    reCaptchaSecretKey: string,
    bankOptionsFormSetting: string,
    orderIDFormLabel: string,
    nameLabel: string,
    emailLabel: string,
    bankLabel: string,
    labelPaymentDate: string,
    valueTransferLabel: string,
    proffOfPaymentRequired: boolean,
    recordLabels: string
    ) => void;
  data: PaymentConfirmationModel;
  isEdit: boolean;
}

export default StockistForm;
