import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, InputGroup, FormControl, Row, Col, Button, Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../assets/vector/edit.png";
import hapus from "./../../../assets/vector/delete.png";
import note from "./../../../assets/vector/note.png";

import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { Link } from "react-router-dom";
import { deleteCustomer, getCustomerListWithPaging, resetStatus } from "../customer.reducer";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import DeleteMessage from "../../../components/DeleteMessage";
import { FiFrown } from "react-icons/fi";
import {MAX_PAGING_OPTIONS} from "../../../constants";
import NumberFormat from "react-number-format";

const CustomerPage = () => {
  const dispatch = useDispatch();

  const customerState = useSelector(
    (state: RootState) => state.customer.list ?? []
  );
  const totalRowState = useSelector(
    (state: RootState) => state.customer.totalRow ?? 0
  );

  const customerStatusState = useSelector(
    (state:RootState) => state.customer.status ?? ""
  )

  const [filterText, setFilterText] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterPartnerType, setFilterPartnerType] = useState<number|null>(null)
  const [filterTempPartnerType, setFilterTempPartnerType] = useState(3)

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const filteredItems = customerState.filter(
    (item) =>
      (item.fullName &&
        item.fullName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.phoneNumber &&
        item.phoneNumber.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.isActive &&
        item.isActive.toString().includes(filterText.toLowerCase()))
  );

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("")
    setFilterEmail("")
    setFilterPhone("")
    setFilterId("")
    setFilterPartnerType(null)
    setFilterTempPartnerType(3)
    setFilterStartDate("");
    setFilterEndDate("");

    dispatch(
      getCustomerListWithPaging({
        id: "",
        name: "",
        email: "",
        phone: "",
        startDate: "",
        endDate: "",
        partnerType: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getCustomerListWithPaging({
        id: filterId,
        name: filterName,
        email: filterEmail,
        phone: filterPhone,
        startDate: filterStartDate,
        endDate: filterEndDate,
        partnerType: filterPartnerType,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    fetchData(0,perPage)
  }, [])

  useEffect(()=>{
    setLoading(false) 
  },[customerState])
  
  useEffect(() => {
    if(customerStatusState==="success-delete"){
      fetchData(0,perPage)
      dispatch(resetStatus())
    }
  }, [customerStatusState])
  

  const columns = [
    {
      name: "ID",
      selector: (d: { code: string }) => <span>{d.code}</span>,
      sortable: true,
    },
    {
      name: "REGISTRATION DATE",
      selector: (d: { createdDate: string }) => (
        <span>{d.createdDate.substr(0, 10)}</span>
      ),
      sortable: true,
    },
    {
      name: "NAME",
      selector: "fullName",
      sortable: true,
    },
    {
      name: "CUSTOMER TYPE",
      selector: (d: { type: number }) => (
        <p style={{ margin: 10 }}>
          {d.type === 0 && <span>End Customer</span>}
          {d.type === 1 && <span>Stockist</span>}
          {d.type === 2 && <span>Reseller</span>}
          {d.type === 4 && <span>Admin Stockist</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: "email",
      sortable: true,
    },
    {
      name: "PHONE NUMBER",
      selector: "phoneNumber",
      sortable: true,
    },
    {
      name: "TOTAL ORDER",
      selector: "totalTransaction",
      sortable: true,
    },
    {
      name: "WALLET",
      selector: (d: { wallet: number }) => (
        <NumberFormat
          value={d.wallet}
          displayType="text"
          thousandSeparator={true}
          prefix=""
        />
      ),
      sortable: true,
    },
    {
      name: "POINT",
      selector: (d: { point: number }) => (
          <NumberFormat
              value={d.point}
              displayType="text"
              thousandSeparator={true}
              prefix=""
          />
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && (
            <span className={"custom-badge-success"}>Active</span>
          )}
          {d.isActive === false && (
            <span className={"custom-badge-secondary"}>Inactive</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-customer/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <Link
            to={{
              pathname: `/customer-order-detail/${d.id}`,
            }}
          >
            <img src={note}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components

  const onDelete = () => {
    dispatch(deleteCustomer(selectedId));
    setShowAlert(false);
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>

          {/* <InputGroup
            className="mb-3 padding-filter float-right"
            style={{ width: 330 }}
          >
            <InputGroup.Prepend className="search-input">
              <InputGroup.Text
                style={{
                  backgroundColor: "#fff",
                  color: "#F5B95D",
                  border: 0,
                }}
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              style={{ borderLeft: 0 }}
              placeholder="Search"
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              value={filterText}
            />
          </InputGroup> */}

          {/* <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Tanggal Register
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Email
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterEmail}
                    onChange={(e) => {
                      setFilterEmail(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

           
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Phone
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterPhone}
                    onChange={(e) => {
                      setFilterPhone(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
         
          </Row>

          <Row>

          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Customer ID
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterId}
                    onChange={(e) => {
                      setFilterId(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>    

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Type
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterTempPartnerType}
                    onChange={(e) => {
                      if (e.target.value.toString() === "3") {
                        setFilterPartnerType(null);
                        setFilterTempPartnerType(parseInt(e.target.value));
                      } else {
                        setFilterTempPartnerType(parseInt(e.target.value));
                        setFilterPartnerType(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={3}>All</option>
                    <option value={0}>End Customer</option>
                    <option value={1}>Stockist</option>
                    <option value={2}>Reseller</option>
                    <option value={4}>Admin Stockist</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>    

          </Row>

          <Row>
            <Col md={4}>
            <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>

            
            </Col>
          </Row>

          <hr/>

          <DataTable
            columns={columns}
            data={customerState}
            progressPending={loading}
            pagination
            paginationServer
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default CustomerPage;
