import { Card, Form, Row, Col, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {  useEffect, useState } from "react";
import AlertMessage from "../../../components/alertMessage";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../app/store";
import { SubscriberModel } from "../subscriber.model";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import {
  deleteData,
  getList,
  resetStatus,
  updateData,
} from "../subscriber.reducer";
import {MAX_PAGING_OPTIONS} from "../../../constants";
import hapus from "../../../assets/vector/delete.png";

const SubscriberTable = ({ isEdit = false, ...props }: CityFormProps) => {
  const dispatch = useDispatch();

  const subscribers = useSelector((state: RootState) => state.subscriber.list ?? []);

  const statusState = useSelector((state: RootState) => state.subscriber.status ?? "");

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onCloseAlert = () => setShowAlert(false);
  const handleShow = () => {
    setShow(true);
    setPhoneNumber("");
  };
  const handleClose = () => {
    setShow(false);
  };
  const onAlertClose = () => setShowAlert(false);

  const filteredItems = subscribers.filter(
    (item) =>
      (item.phoneNumber && item.phoneNumber.toLowerCase().includes(filterText.toLowerCase()))
  );

  // useEffect(() => {
  //   if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
  //     setPhoneNumber(data.name);
  //     setProvince(data.provinceId);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  const columns = [
    // {
    //   name: "No",
    //   selector: "no",
    //   sortable: true,
    //   cell: (_d: any, index: number) => index + 1,
    // },
    {
      name: "Phone Number",
      selector: "phoneNumber",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: string }) => (
        <>
          {/*<span*/}
          {/*  onClick={() => {*/}
          {/*    setShow(true);*/}
          {/*    onClickEditbutton(d.id);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <img src={edit}></img>*/}
          {/*</span>*/}
          {/*{"    "}*/}
          <span
              style={{cursor: "pointer"}}
              onClick={() => {
                if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
              }}
          >
              <img src={hapus} style={{paddingRight: 10}}></img>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusState !== "pending" && statusState !== "") {
      setAlertProps({
        variant: statusState,
        title: statusState === "success" ? "Berhasil" : "Gagal",
        content: statusState === "success" ? "Berhasil menghapus nomor whatsapp" : "Terjadi kesalahan saat memproses data",
      });
      setShowAlert(true);
      dispatch(getList());
    }
    return () => {
      dispatch(resetStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  const onEdit = () => {
    dispatch(
      updateData({
        id: "",
        phoneNumber: phoneNumber,
      } as SubscriberModel)
    );
  };

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Subscriber</Form.Label>
          </Form.Group>
        </Col>
        {/* <Col>
          <Button
            className="float-right"
            variant="info"
            onClick={handleShow}
            style={{ width: 150 }}
          >
            + Add City
          </Button>
        </Col> */}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text style={{ backgroundColor: "#fff", color: "#F5B95D", border: 0 }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add City</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                id="cityForm"
                onSubmit={(e) => {
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                    return null;
                  }

                  e.preventDefault();

                    props.onSubmit(phoneNumber);
                    setPhoneNumber("");

                  setValidated(true);
                }}
              >

                <Form.Group className="input-padding">
                  <Form.Label>City Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    value={phoneNumber}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-info" style={{ width: 100 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                form="cityForm"
                variant="info"
                type="submit"
                style={{ width: 100 }}
                onClick={handleClose}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </Grid>

      <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
    </div>
  );
};

export interface CityFormProps {
  onSubmit: (phoneNumber: string) => void;
  isEdit: boolean;
}

export default SubscriberTable;
