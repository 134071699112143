import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import logo from "../../../assets/logo.png";
import moment from "moment";
import { FilterModel } from "../laporanEkspedisi.model";
import { getExpeditionReport } from "../laporanEkspedisi.reducer";

const ExpeditionPrint = () => {

  const dispatch = useDispatch();

  const [currentFilterData, setCurrentFilterData] = useState({} as FilterModel)

  const ExpeditionReportState = useSelector(
    (state: RootState) => state.expeditionReport.list ?? []
  );

  useEffect(() => {
    dispatch(
      getExpeditionReport({
        reportType: 2,
        filterMonthly: "",
        startFilterDate: "",
        endFilterDate: "",
        name: "",
        skip: 0,
        take: 10,
      })
    );

    setTimeout(() => {
      window.print();
    }, 2000);
  }, [])
  

  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp " + result + ",-";
    }
    return value;
  };

  return (
    <div>
      <br />
      <h3 className="m-3">Laporan Ekspedisi</h3>
      <Table className="m-3" size="sm" bordered>
        <thead>
          <tr>
            <th>
              <b>TANGGAL</b>
            </th>
            <th>
              <b>SO</b>
            </th>
            <th>
              <b>EKSPEDISI</b>
            </th>
            <th>
              <b>JUMLAH ITEM</b>
            </th>
            <th>
              <b>BERAT</b>
            </th>
            <th>
              <b>BIAYA</b>
            </th>
            <th>
              <b>STATUS</b>
            </th>
            <th>
              <b>CATATAN</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {ExpeditionReportState.map((item: any) => (
            <tr>
              <td>{moment(new Date(item.orderDate)).format("DD/MM/YYYY")}</td>
              <td>{item.soNumber}</td>
              <td>{item.expeditionName}</td>
              <td>{item.totalItem}</td>
              <td>{item.totalWeight} Kg </td>
              <td>{moneyParser(item.shipmentCost)}</td>
              <td>{item.status === 0 ? "UNPAID" : "PAID"} </td>
              <td>{item.expeditionNote}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ExpeditionPrint;
