import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import PrintListAPI from "./printList.api";
import { FilterModel, PrintListModel } from "./printList.model";

export interface PrintListSlice {
  list?: PrintListModel[];
  totalRow?: number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getListWithPaging = createAsyncThunk(
  "PrintListGetListWithPagingState/PrintListGetListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await PrintListAPI.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const PrintListSlice = createSlice({
  name: "PrintListState",
  initialState: {} as PrintListSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { resetStatus } = PrintListSlice.actions;
export default PrintListSlice.reducer;
