
import { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import {
  getListCity,
  getListCollectionTag,
  getListProduct,
  getListRole,
} from "../../../../global/global.reducer";
import RoleForm from "../component/roleForm";
import StoreForm from "../component/storeForm";
import VoucherForm from "../component/voucherForm";
import { VoucherModel } from "../voucher.model";
import {
  createData,
  getAvailableDayData,
  getSingleItem,
  updateData,
} from "../voucher.reducer";

const VoucherPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.voucher.single ?? ({} as VoucherModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getListRole());
    dispatch(getListProduct());
    dispatch(getListCollectionTag());
    dispatch(getListCity());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
      dispatch(getAvailableDayData(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    id: string,
    name: string,
    code: string,
    type: number,
    appliesMultiple: boolean,
    isMembership: boolean,
    roleIdList: string,
    isEnableStore: boolean,
    startDate: Date,
    endDate: Date,
    discountValue: number,
    maximumAmount: number,
    isEntireOrder: boolean,
    isSpecificCollection: boolean,
    collectionListId: string,
    isSpecificProduct: boolean,
    productListId: string,
    isNoneMinimumRequirement: boolean,
    isMinimumPurchaseAmount: boolean,
    minimumPurchaseAmount: number,
    isMinimumQuantityOfItems: boolean,
    isNumberOfUsage: boolean,
    numberOfUsage: number,
    isLimitUsePerCustomer: boolean,
    isAllCity: boolean,
    isSelectedCity: boolean,
    selectedCity: string,
    isExcludeShipping: boolean,
    excludeShipping: number,
    customerGetAmountQty: number,
    anyItemFrom: number,
    isFree: boolean,
    isNominalCashback: boolean,
    nominalCashback: number,
    isMaximumAmountPercentage: boolean,
    maximumAmountPercentage: number,
    isUpto: boolean,
    upto: number,
    quota: number,
    maximumEstimatedStoreExpenses: number,
    discountMinimumRequirementType : number,
    image: string,
    isActive: boolean,
    isExpired: boolean,
    freeItemId: string|null,
    nominalCashbackType: number,
    minimumQuantityOfItems: number,
    valueType: number,
    productId: string | null,
    isHidden: boolean
  ) => {
    

    if (isEdit) {

      console.log(discountMinimumRequirementType)
      dispatch(
        updateData({
          id,
          name,
          code,
          type,
          appliesMultiple,
          isMembership,
          roleIdList,
          isEnableStore,
          startDate,
          endDate,
          discountValue,
          maximumAmount,
          isEntireOrder,
          isSpecificCollection,
          collectionListId,
          isSpecificProduct,
          productListId,
          isNoneMinimumRequirement,
          isMinimumPurchaseAmount,
          minimumPurchaseAmount,
          isMinimumQuantityOfItems,
          isNumberOfUsage,
          numberOfUsage,
          isLimitUsePerCustomer,
          isAllCity,
          isSelectedCity,
          selectedCity,
          isExcludeShipping,
          excludeShipping,
          customerGetAmountQty,
          anyItemFrom,
          isFree,
          isNominalCashback,
          // nominalCashback,
          isMaximumAmountPercentage,
          maximumAmountPercentage,
          isUpto,
          upto,
          quota,
          maximumEstimatedStoreExpenses,
          discountMinimumRequirementType,
          image,
          partnerType : roleIdList,
          isActive,
          isExpired,
          freeItemId,
          nominalCashbackType,
          // minimumQuantityOfItems,
          valueType,
          productId,
          isHidden
        } as VoucherModel)
      );
    } else {
      dispatch(
        createData({
          id:"",
          name,
          code,
          type,
          appliesMultiple,
          isMembership,
          roleIdList,
          isEnableStore,
          startDate,
          endDate,
          discountValue,
          maximumAmount,
          isEntireOrder,
          isSpecificCollection,
          collectionListId,
          isSpecificProduct,
          productListId,
          isNoneMinimumRequirement,
          isMinimumPurchaseAmount,
          minimumPurchaseAmount,
          isMinimumQuantityOfItems,
          isNumberOfUsage,
          numberOfUsage,
          isLimitUsePerCustomer,
          isAllCity,
          isSelectedCity,
          selectedCity,
          isExcludeShipping,
          excludeShipping,
          customerGetAmountQty,
          anyItemFrom,
          isFree,
          isNominalCashback,
          isMaximumAmountPercentage,
          maximumAmountPercentage,
          isUpto,
          upto,
          quota,
          maximumEstimatedStoreExpenses,
          discountMinimumRequirementType,
          image,
          partnerType : roleIdList,
          isActive,
          isExpired,
          freeItemId,
          nominalCashbackType,
          // minimumQuantityOfItems,
          valueType,
          productId,
          isHidden
        } as VoucherModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Form.Group style={{ paddingTop: 15 }}>
        <Form.Label>
          <h4 style={{ fontWeight: "bolder" }}>
            {isEdit ? "Edit Discount" : "Add Discount"}
          </h4>
        </Form.Label>
      </Form.Group>

      <Row>
        <Col md={8}>
          <VoucherForm
            onSubmit={onSubmit}
            data={singleDataState}
            isEdit={isEdit}
          />
        </Col>
        <Col md={4}>
          <RoleForm />
          <br />
          {/* <StoreForm /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default VoucherPage;
