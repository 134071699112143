import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import {
  getListExpedition,
  getListProduct,
  getProvince,
  getCityByProvince,
  getDistrictByCity,
  getListActiveProduct,
} from "../../../../global/global.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  AddressModel,
  CheckOutAdminRequestModel,
  CheckOutDetail,
  ComplainDetailItem,
  PartnerModel,
  PartnerOptionModel,
  PartnerType,
  ReturModel,
  SalesOrderData,
  ServiceModel,
  ThirdPartyTypeEnum,
} from "../salesOrder.model";
import {
  checkCostAdmin,
  createSalesOrderAdmin,
  getAddressListByPartner,
  getListPartner,
  getListPaymentMethod,
  getListVariationByProduct,
  getListVariationByProductAndUser,
  getProductListFromComplaint,
  getReturData,
  resetBarcodeImagePath,
  resetStatus,
  uploadBarcodeImage,
} from "../salesOrder.reducer";
import Select from "react-select";
import NumberFormat from "react-number-format";
import hapus from "./../../../../assets/vector/delete.png";
import DataTable from "react-data-table-component";
import { SalesOrderDetailModel } from "../salesOrder.model";
import DeleteMessage from "../../../../components/DeleteMessage";
import AlertMessage from "../../../../components/alertMessage";
import { useHistory } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { ProductOptionModel } from "../../../Inventory/penerimaanInventory/penerimaanInventory.model";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import Dropzone from "react-dropzone";
import upload from "./../../../../assets/vector/upload.png";
import { convertBase64 } from "../../../../components/Library/Base64";
import AsyncSelect from "react-select/async";
import useAsyncProductOption from "../../../../hooks/product/useAsyncProductOption";
import useAsyncPartnerOption from "../../../../hooks/partner/useAsyncPartnerOption";

const SalesOrderForm = ({ returnId = "" }) => {
  const dispatch = useDispatch();

  const userState = useSelector((state: RootState) => state.user ?? "");

  const SalesOrderStatusState = useSelector(
    (state: RootState) => state.salesOrder.status ?? ""
  );

  const provinceState = useSelector(
    (state: RootState) => state.global.listProvince ?? []
  );

  const cityState = useSelector(
    (state: RootState) => state.global.listCityByProvince ?? []
  );

  const districtState = useSelector(
    (state: RootState) => state.global.listDistrictByCity ?? []
  );

  const partnerListState = useSelector(
    (state: RootState) => state.salesOrder.partnerList ?? []
  );

  const addressListState = useSelector(
    (state: RootState) => state.salesOrder.addressList ?? []
  );

  const paymentMethodListState = useSelector(
    (state: RootState) => state.salesOrder.paymentMethodList ?? []
  );

  const productState = useSelector(
    (state: RootState) => state.global.listActiveProduct ?? []
  );

  const variationState = useSelector(
    (state: RootState) => state.salesOrder.listVariationByProduct ?? []
  );

  const expeditionState = useSelector(
    (state: RootState) => state.salesOrder.expeditionList ?? []
  );

  const returDataState = useSelector(
    (state: RootState) => state.salesOrder.returData ?? ({} as ReturModel)
  );

  const complaintDetailState = useSelector(
    (state: RootState) =>
      state.salesOrder.productFromComplaint ?? ([] as ComplainDetailItem[])
  );

  const errorMessage = useSelector(
    (state: RootState) => state.salesOrder.error ?? ({} as any)
  );

  const barcodeImagePath = useSelector(
    (state: RootState) => state.salesOrder.barcodeImagePath ?? ""
  );

  //SO FROM RETUR
  const [returNumber, setReturNumber] = useState("");
  const [soNumber, setSoNumber] = useState("");

  const [loading, setLoading] = useState(false);

  const [fileFormat, setFileFormat] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);

  const [productList, setProductList] = useState<ProductOptionModel[]>([]);
  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);
  const [customerList, setCustomerList] = useState<PartnerOptionModel[]>([]);
  const [thirdParty, setThirdParty] = useState(ThirdPartyTypeEnum.None);
  const [note, setNote] = useState("");
  const [selectedPartnerId, setSelectedPartnerId] = useState<null | string>(
    null
  );
  const [selectedPartnerName, setSelectedPartnerName] = useState<null | string>(
      null
  );
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [statusOrder, setStatusOrder] = useState(0);
  const [statusPayment, setStatusPayment] = useState(0);
  const [selectedPartnerType, setSelectedPartnerType] = useState(0);

  const [barcodeImage, setBarcodeImage] = useState("");

  const [partnerDefaultAddress, setPartnerDefaultAddress] = useState(
    {} as AddressModel
  );

  const [partnerType, setPartnerType] = useState(0);

  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedProductSku, setSelectedProductSku] = useState("");
  const [selectedVariationId, setSelectedVariationId] = useState("");
  const [selectedVariationName, setSelectedVariationName] = useState("");
  const [selectedProductPrice, setSelectedProductPrice] = useState(0);
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(0);
  const [selectedProductStock, setSelectedProductStock] = useState(0);
  const [selectedProductNote, setSelectedProductNote] = useState("");
  const [selectedProductWeight, setSelectedProductWeight] = useState(0);
  const [isSerianContainZeroQty, setIsSerianContainZeroQty] = useState(false);
  const [serianQty, setSerianQty] = useState(0);

  const [salesOrderDetail, setSalesOrderDetail] = useState<
    SalesOrderDetailModel[]
  >([]);
  const [salesOrderDetailTemp, setSalesOrderDetailTemp] = useState<
    SalesOrderDetailModel[]
  >([]);

  const [checkOutDetail, setCheckOutDetail] = useState<CheckOutDetail[]>([]);

  const [expeditionServiceList, setExpeditionServiceList] = useState<
    ServiceModel[]
  >([]);

  const [selectedExpedition, setSelectedExpedition] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedExpeditionService, setSelectedExpeditionService] =
    useState("");
  const [selectedExpeditionCost, setSelectedExpeditionCost] = useState(0);
  const [selectedServiceTypeTemp, setSelectedServiceTypeTemp] = useState("");

  const [selectedCashlessExpedition, setSelectedCashlessExpedition] =
    useState(0);

  const [senderType, setSenderType] = useState(0);
  const [senderName, setSenderName] = useState("The Socialite Dropship");

  const [administrationPrice, setAdministrationPrice] = useState(0);
  const [shipmentCost, setShipmentCost] = useState(0);
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const [isFreeShippingCost, setIsFreeShippingCost] = useState(true);
  const [isManualShippingCost, setIsManualShippingCost] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxStatus, setTaxStatus] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState("");

  const [recipientName, setRecipientName] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientPostalCode, setRecipientPostalCode] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [recipientProvince, setRecipientProvince] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [recipientCity, setRecipientCity] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [recipientSubDistrict, setRecipientSubDistrict] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  const [cashlessExpeditionName, setCashlessExpeditionName] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleteAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(setTimeout(() => {
  }, 1000));

  const {getProductOptions} = useAsyncProductOption();
  const {getPartnerOptions} = useAsyncPartnerOption();


  const history = useHistory();

  const onAlertClose = () => {
    if (SalesOrderStatusState === "success") {
      setShowAlert(false);
      dispatch(resetStatus());
      history.replace("/pesanan-dalam-proses/index");
    } else {
      setShowAlert(false);
    }
  };

  const onDeleteAlertClose = () => setShowDeleteAlert(false);

  const handleShow = () => {
    setShow(true);
    setSalesOrderDetailTemp([]);
  };

  const handleClose = () => {
    setShow(false);
    setSalesOrderDetailTemp([]);
  };

  const onSelectedPartnerChange = (data: any) => {
    setPartnerDefaultAddress({} as AddressModel);
    setSelectedPartnerId(data.value);
    setSelectedPartnerType(data.type);
    setSelectedPartnerName(data.label);

    setSenderName("");
    setRecipientName("");
    setRecipientAddress("");
    setRecipientPostalCode("");
    setRecipientPhone("");
    setRecipientProvince("");
    setRecipientCity("");
    setRecipientSubDistrict("");
  };

  const onSelectedCustomerChange = (data: any) => {
    setSelectedCustomerId(data.value);
  };

  const onSenderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSenderType(parseInt(e.target.value));

    if (e.target.value === "1") {
      setSenderName("");
      setRecipientName("");
      setRecipientAddress("");
      setRecipientPostalCode("");
      setRecipientPhone("");
      setRecipientProvince("");
      setRecipientCity("");
      setRecipientSubDistrict("");
    } else {
      if (partnerDefaultAddress.sender === "") {
        setSenderName("The Socialite Dropship");
      } else {
        setSenderName(partnerDefaultAddress.sender);
      }

      setRecipientName(partnerDefaultAddress.name);
      setRecipientAddress(partnerDefaultAddress.fullAddress);
      setRecipientPostalCode(partnerDefaultAddress.postalCode);
      setRecipientPhone(partnerDefaultAddress.phone);
      setRecipientProvince(partnerDefaultAddress.provinceId);
      setRecipientCity(partnerDefaultAddress.cityId);
      setRecipientSubDistrict(partnerDefaultAddress.districtId);
    }
  };

  const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setSelectedProductId(e.target.value.split("&")[0]);
    setSelectedProductName(e.target.value.split("&")[1]);
    // dispatch(getListVariationByProduct(e.target.value.split("&")[0]));

    dispatch(
      getListVariationByProductAndUser({
        productId: e.target.value.split("&")[0],
        partnerType: 3,
        userId: userState.id === undefined ? "" : userState.id,
      })
    );
  };

  const onSelectedProductChange = (product: any) => {
    // setProduct(product.id);
    // setProductSku(product.sku);
    // setProductName(product.productName);

    console.log(product);
    setSelectedProductId(product.id);
    setSelectedProductName(product.productName);
    setSelectedProductSku(product.sku);

    dispatch(
      getListVariationByProductAndUser({
        productId: product.id,
        partnerType: 3,
        userId: userState.id === undefined ? "" : userState.id,
      })
    );

    // dispatch(getListVariationByProduct(product.id));
  };

  const onVariationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedVariationId(e.target.value.split("&")[0]);

    setSelectedVariationName(e.target.value.split("&")[1]);

    setSelectedProductStock(parseInt(e.target.value.split("&")[5]));

    setSelectedProductWeight(parseInt(e.target.value.split("&")[6]));

    if (selectedPartnerType === PartnerType.Customer) {
      if (parseInt(e.target.value.split("&")[7]) === 0) {
        setSelectedProductPrice(parseInt(e.target.value.split("&")[2]));
      } else {
        if (
          isDateInRange(
            e.target.value.split("&")[10],
            e.target.value.split("&")[11]
          )
        ) {
          setSelectedProductPrice(parseInt(e.target.value.split("&")[7]));
        } else {
          setSelectedProductPrice(parseInt(e.target.value.split("&")[2]));
        }
      }
    } else if (
      selectedPartnerType === PartnerType.Stockist ||
      selectedPartnerType === PartnerType.AdminStockist
    ) {
      if (parseInt(e.target.value.split("&")[8]) === 0) {
        setSelectedProductPrice(parseInt(e.target.value.split("&")[3]));
      } else {
        if (
          isDateInRange(
            e.target.value.split("&")[12],
            e.target.value.split("&")[13]
          )
        ) {
          setSelectedProductPrice(parseInt(e.target.value.split("&")[8]));
        } else {
          setSelectedProductPrice(parseInt(e.target.value.split("&")[3]));
        }
      }
    } else if (selectedPartnerType === PartnerType.Reseller) {
      if (parseInt(e.target.value.split("&")[9]) === 0) {
        setSelectedProductPrice(parseInt(e.target.value.split("&")[4]));
      } else {
        if (
          isDateInRange(
            e.target.value.split("&")[14],
            e.target.value.split("&")[15]
          )
        ) {
          setSelectedProductPrice(parseInt(e.target.value.split("&")[9]));
        } else {
          setSelectedProductPrice(parseInt(e.target.value.split("&")[4]));
        }
      }
    }
  };

  const isDateInRange = (date1: string, date2: string) => {
    let startDate = new Date(date1);
    let endDate = new Date(date2);
    let now = new Date();

    if (now > startDate && now < endDate) {
      return true;
    } else {
      return false;
    }
  };

  const countTotalPrice = (data: SalesOrderDetailModel[]) => {
    let total = 0;
    if (data.length !== 0) {
      data.map((item) => {
        total = total + item.price;
      });
    }
    return total;
  };

  const countTotalQty = (data: SalesOrderDetailModel[]) => {
    let total = 0;
    if (data.length !== 0) {
      data.map((item) => {
        total = total + item.quantity;
      });
    }
    return total;
  };

  const countGrandTotal = () => {
    let total =
      subTotal -
      discount +
      (totalTax + totalShippingCost) +
      administrationPrice;
    return total;
  };

  const countTotalWeight = (data: SalesOrderDetailModel[]) => {
    let total = 0;
    console.log(data);
    if (data.length !== 0) {
      data.map((item) => {
        total = total + item.weight;
      });
    }
    return total;
  };

  const addSalesOrderDetail = () => {
    salesOrderDetailTemp.map((item) => {
      if (item.quantity !== 0) {
        setSalesOrderDetail((prevState) => [
          ...prevState,
          {
            id: "",
            salesOrderId: "",
            productId: item.productId,
            productName: item.productName,
            variationId: item.variationId,
            variationName: item.variationName,
            price: item.price * item.quantity,
            quantity: item.quantity,
            priceSatuan: item.price,
            note: item.note,
            stockQty: item.stockQty,
            weight: item.weight * item.quantity,
          },
        ]);
      }
    });

    // setSalesOrderDetail([
    //   ...salesOrderDetail,
    //   {
    //     id: "",
    //     salesOrderId: "",
    //     productId: selectedProductId,
    //     productName: selectedProductName,
    //     variationId: selectedVariationId,
    //     variationName: selectedVariationName,
    //     price: selectedProductPrice * selectedProductQuantity,
    //     quantity: selectedProductQuantity,
    //     priceSatuan: selectedProductPrice,
    //     note: selectedProductNote,
    //     stockQty: selectedProductStock,
    //     weight: selectedProductWeight * selectedProductQuantity,
    //   },
    // ]);

    // setCheckOutDetail([
    //   ...checkOutDetail,
    //   {
    //     variationId: selectedVariationId,
    //     quantity: selectedProductQuantity,
    //   },
    // ]);

    handleClose();
  };

  const onDelete = () => {
    let newList = [...salesOrderDetail];
    newList.splice(selectedIndex, 1);
    setSalesOrderDetail(newList);
    setShowDeleteAlert(false);
  };

  const onProvinceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientProvince(e.target.value);
    dispatch(getCityByProvince(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientCity(e.target.value);
    dispatch(getDistrictByCity(e.target.value));
  };

  const onDistrictChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientSubDistrict(e.target.value);
  };

  const onExpeditionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedExpedition(e.target.value);

    let filteredService = expeditionState.filter(
      (item) => item.expeditionId === e.target.value
    );

    setExpeditionServiceList(filteredService[0].costs);

    setSelectedExpeditionService(filteredService[0].costs[0].service);
    setSelectedExpeditionCost(filteredService[0].costs[0].cost[0].value);

    setSelectedServiceTypeTemp(
      filteredService[0].costs[0].service +
        "&" +
        filteredService[0].costs[0].cost[0].value
    );

    console.log(
      filteredService[0].costs[0].service +
        "&" +
        filteredService[0].costs[0].cost[0].value
    );

    setTotalShippingCost(
      // filteredService[0].costs[0].cost[0].value * totalWeight
      filteredService[0].costs[0].cost[0].value
    );
  };

  const onCashlessExpeditionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCashlessExpedition(parseInt(e.target.value));
  };

  const onExpeditionServiceChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setSelectedExpeditionService(e.target.value.split("&")[0]);
    setSelectedExpeditionCost(parseInt(e.target.value.split("&")[1]));
    setSelectedServiceTypeTemp(
      e.target.value.split("&")[0] + "&" + e.target.value.split("&")[1]
    );
    // setTotalShippingCost(parseInt(e.target.value.split("&")[1]) * totalWeight);
    setTotalShippingCost(parseInt(e.target.value.split("&")[1]));
  };

  const onAddQuantityClick = (variation: any) => {
    if (variation.quantity < variation.stockQty) {
      let updatedSalesOrderDetailList = salesOrderDetailTemp.map((item) => {
        if (item === variation) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        } else {
          return item;
        }
      });
      setSalesOrderDetailTemp(updatedSalesOrderDetailList);
    }
  };

  console.log('salesOrderDetailTemp', salesOrderDetailTemp);

  const onSubQuantityClick = (variation: any) => {
    if (variation.quantity > 0) {
      let updatedSalesOrderDetailList = salesOrderDetailTemp.map((item) => {
        if (item === variation) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        } else {
          return item;
        }
      });
      setSalesOrderDetailTemp(updatedSalesOrderDetailList);
    }
  };

  const onVariantNoteChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    variation: any
  ) => {
    let updatedSalesOrderDetailList = salesOrderDetailTemp.map((item) => {
      if (item === variation && item.quantity !== 0) {
        return {
          ...item,
          note: e.target.value,
        };
      } else {
        return item;
      }
    });
    setSalesOrderDetailTemp(updatedSalesOrderDetailList);
  };

  const handleImage = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setBarcodeImage(result);
      });
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    if (barcodeImage != null && barcodeImage != "") {
      dispatch(
        uploadBarcodeImage({
          file_name: "so-barcode." + fileFormat,
          base64url: barcodeImage.split(",")[1],
          imagepath: "salesOrderBarcode",
          location: "salesOrder/barcode",
        })
      );
    }
  }, [barcodeImage, fileFormat]);

  const onSave = () => {
    let salesOrderData = {
      customerId: selectedPartnerId,
      thirdParty: thirdParty,
      statusOrder: statusOrder,
      paymentType: statusPayment,
      paymentMethodId:
        paymentMethodId === ""
          ? "00000000-0000-0000-0000-000000000000"
          : paymentMethodId,
      discount: discount,
      discountPercentage: discountPercentage,
      taxAmount: totalTax,
      taxPercentage: taxPercentage,
      note: note,
      adminId: userState.id,
      returId: returnId === "" ? null : returnId,
      barcodeImage: barcodeImagePath,
    } as SalesOrderData;

    let shipmentData = {
      senderName: senderName,
      senderAddress: "",
      // recipientName: partnerDefaultAddress.name,
      // recipientAddress: partnerDefaultAddress.fullAddress,
      // recipientPostalCode: partnerDefaultAddress.postalCode,
      // recipientPhone: partnerDefaultAddress.phone,
      // recipientProvinceId: partnerDefaultAddress.provinceId,
      // recipientCityId: partnerDefaultAddress.cityId,
      // recipientDistrictId: partnerDefaultAddress.districtId,
      recipientName: recipientName,
      recipientAddress: recipientAddress,
      recipientPostalCode: recipientPostalCode,
      recipientProvinceId:
        recipientProvince === ""
          ? "00000000-0000-0000-0000-000000000000"
          : recipientProvince,
      recipientCityId:
        recipientCity === ""
          ? "00000000-0000-0000-0000-000000000000"
          : recipientCity,
      recipientDistrictId:
        recipientSubDistrict === ""
          ? "00000000-0000-0000-0000-000000000000"
          : recipientSubDistrict,
      recipientPhone: recipientPhone,
      expeditionId: statusPayment === 0 || statusOrder === 8 ? null : selectedExpedition,
      service: statusPayment === 0 ? "" : selectedExpeditionService + "",
      resi: receiptNumber,
      resiNumber: receiptNumber,
      isFree: statusPayment === 1 ? false : true,
      isManualShippingCost: isManualShippingCost,
      totalWeight: totalWeight,
      cashlessAddress: statusOrder === 0 ? senderName : "",
      cashlessCourier: selectedCashlessExpedition,
      cashlessExpedition: cashlessExpeditionName,
      totalShippingCost: totalShippingCost,
    };

    dispatch(
      createSalesOrderAdmin({
        salesOrder: salesOrderData,
        salesOrderDetail: salesOrderDetail,
        shipment: shipmentData,
      })
    );
    setLoading(true);
  };

  useEffect(() => {
    dispatch(getListPartner());
    dispatch(getListActiveProduct());
    dispatch(getListPaymentMethod(0));
    dispatch(resetBarcodeImagePath())

    if (returnId !== "" && returnId !== null && returnId !== undefined) {
      dispatch(getReturData(returnId));
    }
  }, []);

  useEffect(() => {
    if (
      returDataState.soNumber !== undefined &&
      returDataState.soNumber !== "" &&
      returDataState.soNumber !== null
    ) {
      setSoNumber(returDataState.soNumber);
      setReturNumber(returDataState.number);
      setPartnerType(returDataState.partnerType);
      setSelectedPartnerId(returDataState.partnerId);
      dispatch(getProductListFromComplaint(returDataState.soNumber));
    }
  }, [returDataState]);

  useEffect(() => {
    console.log(complaintDetailState);
    if (complaintDetailState.length > 0) {
      setSalesOrderDetail([]);

      complaintDetailState.map((item) => {
        setSalesOrderDetail((prevState) => [
          ...prevState,
          {
            id: "",
            salesOrderId: "",
            productId: item.id,
            productName: item.productName,
            variationId: item.variationId,
            variationName: item.variantName,
            price: item.price * item.quantity,
            quantity: item.quantity,
            priceSatuan: item.price,
            note: "",
            stockQty: 0,
            weight: item.weight * item.quantity,
          },
        ]);
      });
    }
  }, [complaintDetailState]);

  useEffect(() => {
    if (
      selectedPartnerId !== undefined &&
      selectedPartnerId !== "" &&
      selectedPartnerId !== null
    ) {
      dispatch(getAddressListByPartner(selectedPartnerId));
    }
  }, [selectedPartnerId]);

  useEffect(() => {
    if (addressListState.length > 0) {
      let defaultAddress = addressListState.filter(
        (item) => item.isDefault === true
      )[0];

      console.log(defaultAddress);

      setPartnerDefaultAddress(defaultAddress);
      if (senderType === 0) {
        if (defaultAddress?.sender === undefined || defaultAddress?.sender === "") {
          setSenderName("The Socialite Dropship");
        } else {
          setSenderName(defaultAddress?.sender);
        }
        setRecipientName(defaultAddress?.name);
        setRecipientAddress(defaultAddress?.fullAddress);
        setRecipientPostalCode(defaultAddress?.postalCode);
        setRecipientPhone(defaultAddress?.phone);
        setRecipientProvince(defaultAddress?.provinceId);
        setRecipientCity(defaultAddress?.cityId);
        setRecipientSubDistrict(defaultAddress?.districtId);

        dispatch(getCityByProvince(defaultAddress?.provinceId));
        dispatch(getDistrictByCity(defaultAddress?.cityId));
      }
    }
  }, [addressListState]);

  useEffect(() => {
    if (variationState.length !== 0) {
      let isContainZeroQty = variationState.some((item) => item.stockQty === 0);
      setIsSerianContainZeroQty(isContainZeroQty);
      setSerianQty(0);
      setSalesOrderDetailTemp([]);
      variationState.map((item) => {
        let variationName = "";
        if (item.customProductAttributeList?.length === 1) {
          variationName += item.customProductAttributeList[0].name;
        } else if (item.customProductAttributeList?.length === 2) {
          variationName +=
            item.customProductAttributeList[0].name +
            "-" +
            item.customProductAttributeList[1].name;
        } else if (item.customProductAttributeList?.length === 3) {
          variationName +=
            item.customProductAttributeList[0].name +
            "-" +
            item.customProductAttributeList[1].name +
            "-" +
            item.customProductAttributeList[2].name;
        }

        setSalesOrderDetailTemp((prevState) => [
          ...prevState,
          {
            id: "",
            salesOrderId: "",
            productId: item.productId,
            productName: selectedProductSku + " - " + selectedProductName,
            variationId: item.id,
            variationName: variationName,
            price:
              partnerType === 0
                ? item.regulerPriceEndCustomer
                : partnerType === 2
                ? item.regulerPriceReseller
                : item.regulerPriceStockist,
            quantity: 0,
            priceSatuan: selectedProductPrice,
            note: "",
            stockQty: item.stockQty,
            weight: item.weight,
          },
        ]);
      });
    }
  }, [variationState]);

  useEffect(() => {
    if (salesOrderDetail.length > 0) {
      setSelectedExpedition("");
      setSelectedExpeditionService("");
      setSelectedExpeditionCost(0);
      setSelectedServiceTypeTemp("");
      setTotalShippingCost(0);
      setSubTotal(countTotalPrice(salesOrderDetail));
      setQtyTotal(countTotalQty(salesOrderDetail));
      setTotalWeight(countTotalWeight(salesOrderDetail));
      setGrandTotal(countGrandTotal());

      dispatch(
        checkCostAdmin({
          senderCityId:
            partnerDefaultAddress?.cityId === "" ||
            partnerDefaultAddress?.cityId === null
              ? recipientCity
              : partnerDefaultAddress?.cityId,
          destinationDistrictId:
            partnerDefaultAddress?.districtId === "" ||
            partnerDefaultAddress?.districtId === null
              ? recipientSubDistrict
              : partnerDefaultAddress?.districtId,
          salesOrderDetailList: salesOrderDetail,
        } as CheckOutAdminRequestModel)
      );
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOrderDetail]);

  useEffect(() => {

    if(recipientSubDistrict !== "00000000-0000-0000-0000-000000000000"){
      dispatch(
        checkCostAdmin({
          senderCityId:
            partnerDefaultAddress?.cityId === "" ||
            partnerDefaultAddress?.cityId === null
              ? recipientCity
              : partnerDefaultAddress?.cityId,
          destinationDistrictId:
            partnerDefaultAddress?.districtId === "" ||
            partnerDefaultAddress?.districtId === null
              ? recipientSubDistrict
              : partnerDefaultAddress?.districtId,
          salesOrderDetailList: salesOrderDetail,
        } as CheckOutAdminRequestModel)
      );
    }
    
  }, [partnerDefaultAddress]);

  useEffect(() => {

    if(recipientSubDistrict !== "00000000-0000-0000-0000-000000000000"){
      dispatch(
        checkCostAdmin({
          senderCityId: recipientCity,
          destinationDistrictId: recipientSubDistrict,
          salesOrderDetailList: salesOrderDetail,
        } as CheckOutAdminRequestModel)
      );
    }
    
  }, [recipientSubDistrict]);

  useEffect(() => {
    setGrandTotal(countGrandTotal());
  }, [selectedExpeditionCost]);

  useEffect(() => {
    setGrandTotal(countGrandTotal());
  }, [discount]);

  useEffect(() => {
    setGrandTotal(countGrandTotal());
  }, [totalTax]);

  useEffect(() => {
    setGrandTotal(countGrandTotal());
  }, [administrationPrice]);

  useEffect(() => {
    setGrandTotal(countGrandTotal());
  }, [totalShippingCost]);

  useEffect(() => {
    setGrandTotal(countGrandTotal());
  }, [subTotal]);

  useEffect(() => {
    if (partnerListState.length !== 0) {
      setPartnerList([]);

      partnerListState.map((item) => {
        // if (item.type !== 0) {

        if (item.type === partnerType && item.isActive) {
          setPartnerList((prevState) => [
            ...prevState,
            {
              value: item.id,
              label: item.fullName,
              type: item.type,
            },
          ]);
        }
      });
    }
  }, [partnerListState, partnerType]);

  useEffect(() => {
    if (SalesOrderStatusState === "loading") {
      setLoading(true);
    } else if (SalesOrderStatusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil Membuat Sales Order Baru",
        content: "",
      });
      setShowAlert(true);
      setLoading(false);
    } else if (SalesOrderStatusState === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal Membuat Sales Order",
        content:
          errorMessage.data.message === undefined
            ? "Terjadi kesalahan pada sistem."
            : errorMessage.data.message.toString(),
      });
      setShowAlert(true);
      setLoading(false);
      console.log(errorMessage);
    }
  }, [SalesOrderStatusState]);

  useEffect(() => {
    if (productState.length !== 0) {
      setProductList([]);
      productState.map((product) => {
        setProductList((prevState) => [
          ...prevState,
          {
            id: product.id,
            sku: product.inventorySKU,
            productName: product.name,
            label: product.inventorySKU + " - " + product.name,
            uom: product.uom,
          },
        ]);
      });
    }
  }, [productState]);

  const columns = [
    {
      name: "PRODUCT",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIAN",
      selector: "variationName",

      sortable: true,
    },
    {
      name: "QUANTITY",
      selector: "quantity",

      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "PRICE",
      selector: (d: { priceSatuan: number }) => (
        <NumberFormat
          value={d.priceSatuan}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "TOTAL PRICE",
      selector: (d: { price: number }) => (
        <NumberFormat
          value={d.price}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp. "
        />
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }, index: number) => (
        <>
          <span
            onClick={() => {
              setSelectedIndex(index);
              setDeleteAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowDeleteAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  const promiseOptions = (inputValue: string) =>
      new Promise<ProductOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getProductOptions(inputValue));
            }, 1000)
        );
      });

  const onAddSerianClick = () => {
    let isContainZeroQty = salesOrderDetailTemp.some((item) => item.stockQty === 0);
    let maxStockQty = Math.min(...salesOrderDetailTemp.map((item) => item.stockQty));

    if (!isContainZeroQty && serianQty < maxStockQty) {
        let updatedSalesOrderDetailList = salesOrderDetailTemp.map((item) => {
            return {
              ...item,
              quantity: serianQty + 1,
            };
        });
        setSalesOrderDetailTemp(updatedSalesOrderDetailList);
        setSerianQty(prevState => prevState + 1);
    }
  }

  const onSubSerianClick = () => {
    let isContainZeroQty = salesOrderDetailTemp.some((item) => item.stockQty === 0);
    if (!isContainZeroQty) {
      if (serianQty > 0) {
        let updatedSalesOrderDetailList = salesOrderDetailTemp.map((item) => {
          return {
            ...item,
            quantity: serianQty - 1,
          };
        });
        setSalesOrderDetailTemp(updatedSalesOrderDetailList);
        setSerianQty(prevState => prevState - 1);
      }
    }
  };

  const partnerPromiseOptions = (inputValue: string) =>
      new Promise<PartnerOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getPartnerOptions(inputValue));
            }, 1000)
        );
      });

  return (
    <div>
      <Card>
        <Card.Body>
          {returNumber !== "" ? (
            <>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  No. Retur
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={returNumber}
                    disabled
                  ></Form.Control>
                </Col>
              </Form.Group>
            </>
          ) : (
            <></>
          )}

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Tipe Partner
            </Form.Label>
            <Col sm={4}>
              <Form.Check
                className="radio-button"
                label="Stockist"
                type="radio"
                name="partnerType"
                checked={partnerType === 1}
                value={1}
                onChange={() => {
                  dispatch(getListPaymentMethod(1));
                  setPartnerType(1);
                  setSelectedPartnerType(1);
                  setStatusPayment(0);
                }}
              />
              <Form.Check
                className="radio-button"
                label="Reseller"
                type="radio"
                name="partnerType"
                checked={partnerType === 2}
                value={2}
                onChange={() => {
                  dispatch(getListPaymentMethod(2));
                  setPartnerType(2);
                  setSelectedPartnerType(2);
                  setStatusPayment(1);
                }}
              />
              <Form.Check
                className="radio-button"
                label="End Customer"
                type="radio"
                name="partnerType"
                checked={partnerType === 0}
                value={0}
                onChange={() => {
                  dispatch(getListPaymentMethod(0));
                  setPartnerType(0);
                  setSelectedPartnerType(0);
                  setStatusPayment(3);
                }}
              />
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Pelanggan
            </Form.Label>
            <Col sm={4}>
              {returnId !== "" ? (
                <>
                  <Form.Control
                    type="text"
                    value={returDataState.customerName}
                    disabled
                  ></Form.Control>
                </>
              ) : (
                <>
                  {/*<Select*/}
                  {/*  // defaultValue={{value : selectedPartnerId , }}*/}
                  {/*  onChange={onSelectedPartnerChange}*/}
                  {/*  options={partnerList}*/}
                  {/*/>*/}
                  <AsyncSelect cacheOptions defaultOptions loadOptions={partnerPromiseOptions} value={selectedPartnerId !== null ?{
                    value: selectedPartnerId,
                    label: selectedPartnerName,
                  } : null} onChange={onSelectedPartnerChange} />
                </>
              )}
            </Col>
          </Form.Group>

          <hr />

          {/* <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Customer
            </Form.Label>
            <Col sm={4}>
              <Select
                defaultValue={selectedCustomerId}
                onChange={onSelectedCustomerChange}
                options={customerList}
              />
            </Col>
          </Form.Group>

          <hr /> */}

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Pihak Ke-Tiga
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setThirdParty(parseInt(e.target.value));
                }}
                value={thirdParty}
              >
                <option value={ThirdPartyTypeEnum.None}>None</option>
                <option value={ThirdPartyTypeEnum.Lazada}>Lazada</option>
                <option value={ThirdPartyTypeEnum.Tokopedia}>Tokopedia</option>
                <option value={ThirdPartyTypeEnum.Shopee}>Shopee</option>
                <option value={ThirdPartyTypeEnum.Blibli}>Blibli</option>
                <option value={ThirdPartyTypeEnum.BukaLapak}>Bukalapak</option>
                <option value={ThirdPartyTypeEnum.JDid}>JDid</option>
                <option value={ThirdPartyTypeEnum.Orami}>Orami</option>
                <option value={ThirdPartyTypeEnum.TikTok}>TikTok</option>
                <option value={ThirdPartyTypeEnum.Other}>Other</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <hr />

          <Row className="m-1">
            <Col md={6}>
              <Row>
                {" "}
                <h5>Status Pesanan</h5>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Check
                    className="radio-button"
                    label="Pesanan Dalam Proses"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 0}
                    value={0}
                    onChange={() => setStatusOrder(0)}
                  />
                  {/* <Form.Check
                    className="radio-button"
                    label="Pesanan Bayar Di tempat (Lunas)"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 1}
                    value={1}
                    onChange={() => setStatusOrder(1)}
                  />
                  <Form.Check
                    className="radio-button"
                    label="Pesanan Belum Lunas (Rekap / COD)"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 2}
                    value={2}
                    onChange={() => setStatusOrder(2)}
                  />
                  <Form.Check
                    className="radio-button"
                    label="Pesanan Bayar Cash (Lunas)"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 3}
                    value={3}
                    onChange={() => setStatusOrder(3)}
                  /> */}

                  <Form.Check
                    className="radio-button"
                    label="Pesanan Dropship"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 4}
                    value={4}
                    onChange={() => setStatusOrder(4)}
                  />
                  {/* <Form.Check
                    className="radio-button"
                    label="Pesanan Dropship (Lunas)"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 5}
                    value={5}
                    onChange={() => setStatusOrder(5)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className="radio-button"
                    label="Pesanan Piutang (Belum Lunas)"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 6}
                    value={6}
                    onChange={() => setStatusOrder(6)}
                  />
                  <Form.Check
                    className="radio-button"
                    label="Pesanan Tanpa Konfirmasi Pembayaran"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 7}
                    value={7}
                    onChange={() => setStatusOrder(7)}
                  /> */}

                  <Form.Check
                    className="radio-button"
                    label="Pesanan Walk In / Dropping"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 8}
                    value={1}
                    onChange={() => setStatusOrder(8)}
                  />
                  {/* <Form.Check
                    className="radio-button"
                    label="Non"
                    type="radio"
                    name="statusPesanan"
                    checked={statusOrder === 9}
                    value={2}
                    onChange={() => setStatusOrder(9)}
                  /> */}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                {" "}
                <h5>Status Pembayaran</h5>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Check
                    className="radio-button"
                    label="Cashless"
                    type="radio"
                    name="statusPembayaran"
                    checked={statusPayment === 0}
                    value={0}
                    onChange={() => setStatusPayment(0)}
                  />
                  <Form.Check
                    className="radio-button"
                    label="Non Cashless"
                    type="radio"
                    name="statusPembayaran"
                    checked={statusPayment === 1}
                    value={1}
                    onChange={() => {
                      dispatch(resetBarcodeImagePath())
                      setStatusPayment(1)}
                    } 
                  />

                  {/* <Form.Check
                    className="radio-button"
                    label="Piutang"
                    type="radio"
                    name="statusPembayaran"
                    checked={statusPayment === 2}
                    value={2}
                    onChange={() => setStatusPayment(2)}
                  /> */}

                  {selectedPartnerType === 0 ? (
                    <>
                      <Form.Check
                        className="radio-button"
                        label="Cash"
                        type="radio"
                        name="statusPembayaran"
                        checked={statusPayment === 3}
                        value={3}
                        onChange={() => {
                          dispatch(resetBarcodeImagePath())
                          setStatusPayment(3)}
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {/* <Form.Check
                    className="radio-button"
                    label="Non"
                    type="radio"
                    name="statusPembayaran"
                    checked={statusPayment === 4}
                    value={4}
                    onChange={() => setStatusPayment(4)}
                  /> */}
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Form.Label column sm={3}>
            <h5>Status Pesanan</h5>
          </Form.Label> */}

          <Row className="mb-3">
            <Col>
              {/* <Form.Check
                className="radio-button"
                label="Pesanan Keep"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 0}
                value={0}
                onChange={() => setStatusOrder(0)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Walk In"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 1}
                value={1}
                onChange={() => setStatusOrder(1)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Dropship"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 2}
                value={2}
                onChange={() => setStatusOrder(2)}
              /> */}

              {/* <Form.Check
                className="radio-button"
                label="Pesanan Dalam Proses"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 0}
                value={0}
                onChange={() => setStatusOrder(0)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Bayar Di tempat (Lunas)"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 1}
                value={1}
                onChange={() => setStatusOrder(1)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Belum Lunas (Rekap / COD)"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 2}
                value={2}
                onChange={() => setStatusOrder(2)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Bayar Cash (Lunas)"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 3}
                value={3}
                onChange={() => setStatusOrder(3)}
              />
            </Col>
            <Col>
              <Form.Check
                className="radio-button"
                label="Pesanan Dropship (Belum Lunas)"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 4}
                value={4}
                onChange={() => setStatusOrder(4)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Dropship (Lunas)"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 5}
                value={5}
                onChange={() => setStatusOrder(5)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Piutang (Belum Lunas)"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 6}
                value={6}
                onChange={() => setStatusOrder(6)}
              />
              <Form.Check
                className="radio-button"
                label="Pesanan Tanpa Konfirmasi Pembayaran"
                type="radio"
                name="statusPesanan"
                checked={statusOrder === 7}
                value={7}
                onChange={() => setStatusOrder(7)}
              /> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <br />

      <Card>
        <Card.Body>
          <Button
            style={{ height: 40, margin: 10, borderRadius: 5 }}
            variant="info"
            type="button"
            onClick={handleShow}
          >
            Tambah Produk
          </Button>

          <DataTable columns={columns} data={salesOrderDetail} noHeader />
        </Card.Body>
      </Card>

      <br />

      {statusOrder !== 8 ? (
        <>
          <Card>
            <Card.Body>
              <h5>Pengiriman</h5>

              <br />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Dikirim Dari
                </Form.Label>
                <Col sm={4}>
                  <Form.Control as="select" onChange={onSenderChange}>
                    <option value={0}>Alamat Sendiri</option>
                    <option value={1}>Alamat Lain</option>
                  </Form.Control>{" "}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}></Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    type="text"
                    value={senderName}
                    onChange={(e) => setSenderName(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Kepada
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={recipientName}
                    onChange={(e) => setRecipientName(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Alamat Penerima
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    type="text"
                    value={recipientAddress}
                    onChange={(e) => setRecipientAddress(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Kode Pos
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={recipientPostalCode}
                    onChange={(e) => setRecipientPostalCode(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Telepon Penerima
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={recipientPhone}
                    onChange={(e) => setRecipientPhone(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>

              {statusPayment !== 0 ? (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Provinsi
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={onProvinceChange}
                        value={recipientProvince}
                      >
                        <option>select</option>
                        {provinceState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                      {statusPayment === 1 && statusOrder !== 8 ? (
                        <Label className="card-subtitle m-1" color="red">
                          Provinsi wajib diisi.
                        </Label>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Kota
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={onCityChange}
                        value={recipientCity}
                      >
                        <option>select</option>
                        {cityState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                      {statusPayment === 1 && statusOrder !== 8 ? (
                        <Label className="card-subtitle m-1" color="red">
                          Kota wajib diisi.
                        </Label>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Kecamatan
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        onChange={onDistrictChange}
                        value={recipientSubDistrict}
                      >
                        <option>select</option>
                        {districtState.map((Item) => {
                          return <option value={Item.id}>{Item.name}</option>;
                        })}
                      </Form.Control>
                      {statusPayment === 1 && statusOrder !== 8 ? (
                        <Label className="card-subtitle m-1" color="red">
                          Kecamatan wajib diisi.
                        </Label>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}

              <hr />

              {statusOrder !== 8 ? (
                <>
                  {statusPayment === 0 ? (
                    <>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                          Ekspedisi
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            type="text"
                            value={cashlessExpeditionName}
                            onChange={(e) =>
                              setCashlessExpeditionName(e.target.value)
                            }
                          ></Form.Control>

                          {/* <Form.Control
                        as="select"
                        onChange={onCashlessExpeditionChange}
                        value={selectedCashlessExpedition}
                      >
                        <option>Pilih Ekspedisi</option>
                        <option value="0">POS</option>
                        <option value="1">LION</option>
                        <option value="2">NINJA</option>
                        <option value="3">IDE</option>
                        <option value="4">SICEPAT</option>
                        <option value="5">SAP</option>
                        <option value="6">NCS</option>
                        <option value="7">ANTERAJA</option>
                        <option value="8">REX</option>
                        <option value="9">SENTRAL</option>
                        <option value="10">JNE</option>
                        <option value="11">TIKI</option>
                        <option value="12">RPX</option>
                        <option value="13">PANDU</option>
                        <option value="14">WAHANA</option>
                        <option value="15">JNT</option>
                        <option value="16">PAHALA</option>
                        <option value="17">JET</option>
                        <option value="18">SLIS</option>
                        <option value="19">EXPEDITO</option>
                        <option value="20">DSE</option>
                        <option value="21">FIRST</option>
                        <option value="22">STAR</option>
                        <option value="23">IDL</option>
                      </Form.Control> */}
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                          Ekspedisi
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            as="select"
                            onChange={onExpeditionChange}
                            value={selectedExpedition}
                          >
                            <option>Pilih Ekspedisi</option>
                            {expeditionState.map((Item) => {
                              return (
                                <option value={Item.expeditionId}>
                                  {Item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      {selectedExpedition !== "" ? (
                        <>
                          <Form.Group as={Row} className="input-padding">
                            <Form.Label column sm={3}>
                              Tipe Tarif
                            </Form.Label>
                            <Col sm={4}>
                              <Form.Control
                                as="select"
                                onChange={onExpeditionServiceChange}
                                value={
                                  // selectedExpeditionService +
                                  // "&" +
                                  // selectedExpeditionCost
                                  selectedServiceTypeTemp
                                }
                              >
                                {expeditionServiceList.map((Item) => {
                                  return (
                                    <option
                                      value={
                                        Item.service + "&" + Item.cost[0].value
                                      }
                                    >
                                      {Item.service} - {Item.description} - Rp.
                                      {Item.cost[0].value}
                                    </option>
                                  );
                                })}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}

              {statusOrder !== 8 ? (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Nomor Resi
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        value={receiptNumber}
                        onChange={(e) => setReceiptNumber(e.target.value)}
                      ></Form.Control>
                      {statusPayment === 0 ? (
                        <Label className="card-subtitle m-1" color="red">
                          Nomor Resi wajib diisi.
                        </Label>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Total Berat
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        value={totalWeight + " Kg"}
                        disabled
                        // onChange={(e) => setRecipientName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="input-padding">
                    {isManualShippingCost ? (
                      <>
                        <Form.Label column sm={3}>
                          Total Ongkos Kirim
                        </Form.Label>
                        <Col sm={4}>
                          <NumberFormat
                            placeholder="Rp."
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="bootstrap-input"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={totalShippingCost}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              setTotalShippingCost(val);
                            }}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Form.Label column sm={3}>
                          Total Ongkos Kirim
                        </Form.Label>
                        <Col sm={4}>
                          <NumberFormat
                            placeholder="Rp."
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="bootstrap-input"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={totalShippingCost}
                            disabled
                          />
                        </Col>
                      </>
                    )}
                  </Form.Group>
                </>
              ) : (
                <></>
              )}

              {statusPayment !== 1 ? (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}></Form.Label>
                    <Col sm={4}>
                      <Form.Check
                        className="radio-button"
                        label="Gratis Ongkir"
                        name="isFreeShipment"
                        checked={isFreeShippingCost}
                        onChange={(e) => {
                          let filteredService = expeditionState.filter(
                            (item) => item.expeditionId === selectedExpedition
                          );

                          if (e.target.checked) {
                            setTotalShippingCost(0);
                            setIsManualShippingCost(false);
                          } else {
                            setTotalShippingCost(
                              filteredService[0].costs[0].cost[0].value ??
                                0 * totalWeight
                            );
                          }

                          setIsFreeShippingCost(e.target.checked);
                        }}
                      />

                      <Form.Check
                        className="radio-button"
                        label="Harga Manual"
                        name="isManualShipment"
                        checked={isManualShippingCost}
                        onChange={(e) => {
                          let filteredService = expeditionState.filter(
                            (item) => item.expeditionId === selectedExpedition
                          );
                          if (e.target.checked) {
                            setIsFreeShippingCost(false);
                          } else {
                            setIsFreeShippingCost(true);

                            if (filteredService[0].costs[0] !== undefined) {
                              setTotalShippingCost(
                                filteredService[0].costs[0].cost[0].value ??
                                  0 * totalWeight
                              );
                            }
                          }
                          setIsManualShippingCost(e.target.checked);
                        }}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}

              {statusPayment === 0 ? (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Barcode Image
                    </Form.Label>
                    <Col sm={4}>
                      <Dropzone
                        onDrop={(acceptedFiles) => handleImage(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone file-upload-frame w-100">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="text-center file-upload-label">
                                <div>
                                  {(barcodeImage !== "" ) && (
                                  <img
                                    src={
                                      barcodeImage 
                                    }
                                    className="file-upload-image mb3"
                                  />
                                )}

                                  {/* {imageSelf === "" &&
                                  Object.keys(data).length === 0 && ( */}
                                  <>
                                  { barcodeImage === "" && (
                                    <div
                                      className="file-upload-image mb3"
                                      style={{ paddingTop: 50 }}
                                    >
                                      <img src={upload} alt="upload" />
                                      <br />
                                      <span style={{ color: "black" }}>
                                        Select File
                                      </span>
                                    </div>
                                      )}
                                  </>
                                  {/* ) */}
                                  {/* } */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {
                          barcodeImagePath === "" && <Label className="card-subtitle m-1" color="red">
                            Wajib melampirkan Barcode Image.
                          </Label>
                      }

                      {/* {statusPayment === 0 && thirdParty === ThirdPartyTypeEnum.Shopee? (
                        <Label className="card-subtitle m-1" color="red">
                          Wajib melampirkan Barcode Image.
                        </Label>
                      ) : (
                        <></>
                      )} */}
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <></>
      )}

      <br />

      <Card>
        <Card.Body>
          <h5>Biaya</h5>
          <br />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Metode Pembayaran
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setPaymentMethodId(e.target.value);
                }}
              >
                <option>select</option>
                {paymentMethodListState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Subtotal
            </Form.Label>
            <Col sm={4}>
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={subTotal}
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Qty Total
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                value={qtyTotal}
                type="number"
                disabled
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Discount Total
            </Form.Label>
            <Col sm={4}>
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={discount}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setDiscount(val);
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Discount Percentage
            </Form.Label>
            <Col sm={4}>
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={(e) =>
                    setDiscountPercentage(parseInt(e.target.value))
                  }
                  type="number"
                ></Form.Control>
                <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Total tax
            </Form.Label>
            <Col sm={4}>
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={totalTax}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setTotalTax(val);
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Tax Percentage
            </Form.Label>
            <Col sm={4}>
              <InputGroup className="mb-3">
                <Form.Control
                  value={taxPercentage}
                  onChange={(e) => setTaxPercentage(parseInt(e.target.value))}
                  type="number"
                ></Form.Control>
                <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Tax Status
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setTaxStatus(parseInt(e.target.value));
                }}
              >
                <option value={0}>No Tax</option>
                <option value={1}>Tax</option>
                <option value={2}>Tax Include</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Biaya Administrasi
            </Form.Label>
            <Col sm={4}>
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={administrationPrice}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setAdministrationPrice(val);
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Grand Total
            </Form.Label>
            <Col sm={4}>
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={grandTotal}
                disabled
              />
            </Col>
          </Form.Group>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <h5>Catatan</h5>

          <br />

          <Form.Group as={Row} className="input-padding">
            {/* <Form.Label column sm={3}>
              Note
            </Form.Label> */}
            <Col>
              <Form.Control
                as="textarea"
                type="text"
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></Form.Control>
            </Col>
          </Form.Group>
        </Card.Body>
      </Card>

      <br />

      {salesOrderDetail.length !== 0 &&
      (statusPayment === 0 ? receiptNumber !== "" : true) 
      
      // && 
      // (statusPayment === 0 && thirdParty === ThirdPartyTypeEnum.Shopee ? barcodeImagePath !== "" : true) 
      
      ? (
        <>
          <div className="btn-footer-group">
            {loading ? (
              <>
                {" "}
                <Button
                  className="btn"
                  style={{ width: 135, height: 46 }}
                  variant="info"
                  disabled
                >
                  Loading...
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="btn"
                  style={{ width: 135, height: 46 }}
                  variant="info"
                  onClick={onSave}
                  disabled={statusPayment === 0 && barcodeImagePath === ""}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      <br />

      <br />

      <br />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Product</Form.Label>
              {/* <Form.Control as="select" onChange={onProductChange}>
                <option>select</option>
                {productState.map((Item) => {
                  return (
                    <option value={Item.id + "&" + Item.name}>
                      {Item.inventorySKU} - {Item.name}
                    </option>
                  );
                })}
              </Form.Control> */}

              {/*<Select*/}
              {/*  // defaultValue={selectedPartnerId}*/}
              {/*  onChange={onSelectedProductChange}*/}
              {/*  options={productList}*/}
              {/*/>*/}
              <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                           onChange={onSelectedProductChange} />
            </Form.Group>

            <Form.Group>
              {salesOrderDetailTemp.length !== 0 ? (
                <Form.Label>Variant</Form.Label>
              ) : (
                <></>
              )}

              {/* <Form.Control as="select" onChange={onVariationChange}>
                <option>select</option>
                {variationState.map((Item) => {
                  console.log(variationState);

                  let variationName = "";

                  if (Item.customProductAttributeList.length === 1) {
                    variationName += Item.customProductAttributeList[0].name;
                  } else if (Item.customProductAttributeList.length === 2) {
                    variationName +=
                      Item.customProductAttributeList[0].name +
                      "-" +
                      Item.customProductAttributeList[1].name;
                  } else if (Item.customProductAttributeList.length === 3) {
                    variationName +=
                      Item.customProductAttributeList[0].name +
                      "-" +
                      Item.customProductAttributeList[1].name +
                      "-" +
                      Item.customProductAttributeList[2].name;
                  }

                  return (
                    <option
                      value={
                        Item.id +
                        "&" +
                        variationName +
                        "&" +
                        Item.regulerPriceEndCustomer +
                        "&" +
                        Item.regulerPriceStockist +
                        "&" +
                        Item.regulerPriceReseller +
                        "&" +
                        Item.stockQty +
                        "&" +
                        Item.weight +
                        //SalePrice -> Array Index start from 7
                        "&" +
                        Item.salePriceEndCustomer +
                        "&" +
                        Item.salePriceStockist +
                        "&" +
                        Item.salePriceReseller +
                        //SaleDate -> Array Index start from 10
                        "&" +
                        Item.startSaleCustomer +
                        "&" +
                        Item.endSaleCustomer +
                        "&" +
                        Item.startSaleStockist +
                        "&" +
                        Item.endSaleStockist +
                        "&" +
                        Item.startSaleReseller +
                        "&" +
                        Item.endSaleStockist
                      }
                    >
                      {Item.customProductAttributeList.length >= 1
                        ? Item.customProductAttributeList[0].name
                        : ""}
                      {Item.customProductAttributeList.length >= 2
                        ? " - " + Item.customProductAttributeList[1].name
                        : ""}
                      {Item.customProductAttributeList.length == 3
                        ? " - " + Item.customProductAttributeList[2].name
                        : ""}

                      {" (" + Item.stockQty + ")"}
                    </option>
                  );
                })}
              </Form.Control> */}
            </Form.Group>

            <Row>
              {salesOrderDetailTemp.map((item) => {
                return (
                  <Col sm={6}>
                    <Card style={{ marginBottom: 12 }}>
                      <Card.Body style={{ paddingBottom: 4 }}>
                        <Row>
                          <Col style={{ textAlign: "center", marginBottom: 8 }}>
                            <b>{item.variationName}</b>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            sm={4}
                            onClick={() => {
                              onSubQuantityClick(item);
                            }}
                          >
                            <FaMinusCircle />
                          </Col>
                          <Col sm={4} style={{ textAlign: "center" }}>
                            {item.quantity}
                          </Col>
                          <Col
                            sm={4}
                            onClick={() => {
                              onAddQuantityClick(item);
                            }}
                          >
                            <FaPlusCircle />
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ textAlign: "center" }}>
                            <Form.Label>Stok : {item.stockQty}</Form.Label>
                          </Col>
                        </Row>
                        {/* <br /> */}
                        <Row>
                          <Form.Control
                            as="textarea"
                            placeholder="Note.."
                            rows={1}
                            onChange={(e: any) => onVariantNoteChange(e, item)}
                            // value={note}
                          />
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
              {salesOrderDetailTemp.length !== 0 && (
                  <Col sm={12}>
                    <Card style={{ marginBottom: 12 }}>
                      <Card.Body style={{ paddingBottom: 4 }}>
                        <Row>
                          <Col style={{ textAlign: "center", marginBottom: 8 }}>
                            <b>Serian</b>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col
                              sm={4}
                              onClick={() => {
                                onSubSerianClick();
                              }}
                          >
                            <FaMinusCircle />
                          </Col>
                          <Col sm={4} style={{ textAlign: "center" }}>
                            {serianQty}
                          </Col>
                          <Col
                              sm={4}
                              className={"text-right"}
                              onClick={() => {
                                onAddSerianClick();
                              }}
                          >
                            <FaPlusCircle />
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ textAlign: "center" }}>
                            {
                              isSerianContainZeroQty && (
                                    <Form.Label><small className="text-danger">Tidak dapat menambah serian, terdapat varian dengan kuantitas 0</small></Form.Label>
                                )
                            }
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
              )}
            </Row>

            {/* <Form.Group>
              <Form.Label>Qty</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                value={selectedProductQuantity}
                onChange={(e) => {
                  if (parseInt(e.target.value) > selectedProductStock) {
                    setSelectedProductQuantity(selectedProductStock);
                  } else {
                    setSelectedProductQuantity(parseInt(e.target.value));
                  }
                }}
              />
            </Form.Group> */}

            {/* <Form.Group>
              <Form.Label>Catatan</Form.Label>
              <Form.Control
                type="text"
                name="note"
                onChange={(e) => setSelectedProductNote(e.target.value)}
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={addSalesOrderDetail}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDelete}
      />

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </div>
  );
};

export default SalesOrderForm;
