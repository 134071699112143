import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

// export const getMembership = createAsyncThunk('get/Membership', async () => {
//     const response = await axios.get(`${endpoint}/api/Membership/GetList`)
//     console.log(response)
//     return response.data
// })

export const postExportTotalPenjualan = createAsyncThunk('post/postExportTotalPenjualan', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Report/ReportTotalSaleExportToExcel`,
            data: body,
            method: 'post',
            responseType: 'blob',
        })
        console.log(response)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})


export const postLaporanPenjualan = createAsyncThunk('post/postLaporanPenjualan', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportTotalSale`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})
