import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../../app/store";
import { deleteData, getList } from "../UOM.reducer";
import { UOMModel } from "../UOM.model";

const UOMTable = () => {
  const dispatch = useDispatch();
  const UOMState = useSelector((state: RootState) => state.UOM.list ?? []);

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = UOMState.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.unit && item.unit.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.symbol && item.symbol.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.note && item.note.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.code && item.code.toLowerCase().includes(filterText.toLowerCase()))
  );

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const columns = [
    {
      name: "UOM CODE",
      selector: "code",
      sortable: true,
    },
    {
      name: "UNIT",
      selector: "unit",
      sortable: true,
    },
    {
      name: "SYMBOL",
      selector: "symbol",
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && <span className={"custom-badge-success"}>Active</span>}
          {d.isActive === false && <span className={"custom-badge-secondary"}>Inactive</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-UOM/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master UOM</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/UOM/create",
            }}
          >
            <Button className="float-right" variant="info">
              + Add Data UOM
            </Button>
          </Link>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text style={{ backgroundColor: "#fff", color: "#F5B95D", border: 0 }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
            {/* <Col md={5}>
              <Form.Group as={Row} className="padding-filter mb-3 ">
                <Form.Label column sm={3} style={{fontStyle: "normal", fontWeight: "normal", fontSize: 14}}>
                  User level
                </Form.Label>
                <Col sm={6}>
                  <Form.Control as="select" style={{height: 35}}>
                    <option>select</option>
                  </Form.Control>
                </Col>
                <Col sm={2}>
                    <Button style={{width: 69, height: 35, fontSize: 14 }}  variant="outline-info">Reset</Button>
                </Col>
              </Form.Group>
            </Col> */}
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            selectableRows
            customStyles={TableCustomStyles}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default UOMTable;
