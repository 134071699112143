import React, { useEffect } from 'react'
import '../formatNota.scss'
import { Container, Form, Button } from "react-bootstrap";
import { FiEdit, FiPlus, FiPrinter, FiTrash } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaFileExport } from 'react-icons/fa';
import { getFormatNota } from '../formatNota.api';

export default function FormatNota() {
    const history = useHistory()
    const dispatch = useDispatch()
    const {listFormatNota, loading} = useSelector((state:RootState) => state.formatNota)

    useEffect(() => {
        dispatch(getFormatNota())
    }, [])
    useEffect(() => {
        console.log(listFormatNota)
    }, [])

    const handleDelete=(id:any)=>{
        // Swal.fire({
        //     title: 'Apakah anda yakin?',
        //     text: "Data yang sudah di hapus tidak akan bisa di kembalikan!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Ya, Hapus!',
        //     cancelButtonText: 'Batalkan'
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         dispatch<any>(deleteSlideShow(id))
        //         .then(unwrapResult)
        //         .then((res:any)=>{
        //             console.debug(res, 'saya resssssss')
        //             if (res.status) {
        //                 Swal.fire({
        //                     icon: 'success',
        //                     title: 'Slide show deleted',
        //                     showConfirmButton: false,
        //                     timer: 1500
        //                 })
        //                 .then(()=>{
        //                     dispatch(getSlideShow())
        //                 })
        //             }else{
        //                 Swal.fire(
        //                     'Delete slide show failed!',
        //                     ``,
        //                     'error'
        //                 ) 
        //             }
        //         })

        //     }
        // })
    }

    const handleType = (num : any)=>{
        if (num === 0) {
            return "End Customer"
        }
        if (num === 1) {
            return "Stockist"
        }
        if (num === 2) {
            return "Reseller"
        }
        if (num === 3) {
            return "Admin"
        }
        return ""
    }

    return (
        <Container className="content-body formatNota" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
            Nota Format Setting
                <div>
                    {/* <Link to='/setting-format-nota/form' >
                        <Button className='addor bg-info'><FiPlus/> Add Nota Format</Button>
                    </Link> */}
                    <Button className='addor-light ml-3'><FaFileExport/> Export to Excel</Button>
                    <Button className='addor-light ml-3'><FiPrinter/> Print</Button>
                </div>
            </div>
            <div className="box">
                <div className="head">
                    <form>
                        <div className="wrap-inp">
                            <label htmlFor="title">Nota Format Name</label>
                            <input type="text" id='title' placeholder='Search' />
                        </div>
                        <div className="wrap-inp">
                            <label htmlFor="title">User Name</label>
                            <input type="text" id='Image' placeholder='Search' />
                        </div>
                        <Button>Reset</Button>
                    </form>
                </div>
                <div className="con">
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing 3 of 3 results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span> 
                            <select className="custom-select sel">
                                <option value="1">10</option>
                                <option value="2">20</option>
                                <option value="3">30</option>
                            </select>
                        </div>
                    </div>

                    <table className="table">
                        <thead className="">
                            <tr>
                                {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                <th scope="col">Nota Format name</th>
                                <th scope="col">User level</th>
                                {/* <th scope="col">Description</th> */}
                                <th scope="col">delivery estimation</th>
                                <th scope="col">
                                    <div className="text-center">
                                    action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listFormatNota.map((res:any, i:number)=>(
                                    <tr key={i}>
                                        {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                        <td>
                                            {/* <Link to={`/setting-fomat-nota/view/${res.id}`} className='text-dark'> */}
                                            {res.noteFormatName}
                                            {/* </Link> */}
                                        </td>
                                        <td>{handleType(res.type)}</td>
                                        {/* <td>lorem ipsum</td> */}
                                        <td>{res.isShowEstimatedShipping ? <i>Active</i>: <i className="text-danger">Inactive</i>}</td>
                                        <td className='text-center'>
                                            <div className="action">
                                                <Link to={`/setting-format-nota/form/${res.id}`}>
                                                <FiEdit className='text-info' style={{zIndex : 2}} />
                                                </Link>
                                                {/* <FiTrash style={{zIndex : 2}} onClick={()=> handleDelete(res.id)} className='text-danger'/> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                               
                        </tbody>
                    </table>

                </div>
            </div>
        </Container>
    )
}
