import { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getInformationStoreByType, postInformationStore, updateInformationStore } from "../informationStore.api";
import { unwrapResult } from '@reduxjs/toolkit'
import Swal from "sweetalert2";

import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import { RootState } from "../../../../app/store";

const StoreInfoForm = () => {
    const mdParser = new MarkdownIt(/* Markdown-it options */);
    const {activeTab, loading} = useSelector((state:RootState) => state.informationStore)
    const dispatch = useDispatch()

    const [isEdit, setIsEdit] = useState(false)

    const [partnerType, setPartnerType] = useState(activeTab ===  'Reseller' ? 2 :activeTab ===  'Stockist' ? 1 : 0)
    const [values, setValues] = useState<any>({
        "infoStore": "",
        "contactInfoStore": "",
        "accountInfoStore": "",
        "whatsappNumber": ""
    })
    useEffect(() => {
        getDetailInformation()
    }, [activeTab])
    
    const getDetailInformation = ()=>{
        const id = activeTab ===  'Reseller' ? 2 : activeTab ===  'Stockist' ? 1 : 0
        dispatch<any>(getInformationStoreByType(id))
        .then(unwrapResult)
        .then((res:any)=>{
            console.log(res)
            if (res.data === null) {
                setIsEdit(false)
                setValues({
                    ...values,
                    "infoStore": "",
                    "contactInfoStore": "",
                    "accountInfoStore": "",
                    "whatsappNumber": ""
                })
            }else{
                setIsEdit(true)
                setValues(res.data)
            }
        }).catch((err:any)=>{
            console.log(err)
            Swal.fire({
                icon: 'error',
                text: 'Store information cannot loaded!',
            })
        })
    }
    

    const handleInfoStore = (value:any) => {
        setValues({
            ...values,
            infoStore : encodeURIComponent(value.text)
        })
    }

    const handleContact = (value:any) => {
        setValues({
            ...values,
            contactInfoStore : encodeURIComponent(value.text)
        })
    }

    const handleAccount = (value:any) => {
        setValues({
            ...values,
            accountInfoStore : encodeURIComponent(value.text)
        })
    }
    const handleWhatsApp = (value:any) => {
        setValues({
            ...values,
            whatsappNumber : encodeURIComponent(value.text)
        })
    }

    

    const handleSubmit = (e:any)=>{
        e.preventDefault()
        if (isEdit) {
            dispatch<any>(updateInformationStore(values))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Store information updated',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    getDetailInformation()
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'Store information cannot updated!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Store information cannot updated!',
                })
            })

        }else{
            delete values.id
            values.partnerType = activeTab ===  'Reseller' ? 2 :activeTab ===  'Stockist' ? 1 : 0
            console.log(values)
            dispatch<any>(postInformationStore(values))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Store information saved',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    getDetailInformation()
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'Store information cannot saved!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Store information cannot saved!',
                })
            })
            
        }

    }

    return(
        <div className="tab_reseller">
            <h5>Store Information Setting</h5>
            <form onSubmit={handleSubmit}>
                <Row className='forms'>
                    <Col md={3}><p className='mb-3'>Info</p> </Col>
                    {/* <Col md={9}>
                    <input type="text" name="infoStore" placeholder='Info' onChange={handleChange} value={values.infoStore} />
                    </Col> */}
                    <MdEditor 
                        className='mx-3'
                        value={decodeURIComponent(values.infoStore)}
                        style={{ height: '175px' }}
                        renderHTML={text => mdParser.render(text)} 
                        onChange={handleInfoStore} 
                    />
                </Row>
                <Row className='forms'>
                    <Col md={3}><p className='mb-3'>Contact Info</p> </Col>
                    <MdEditor 
                        className='mx-3'
                        value={decodeURIComponent(values.contactInfoStore)}
                        style={{ height: '175px' }}
                        renderHTML={text => mdParser.render(text)} 
                        onChange={handleContact} 
                    />
                </Row>
                <Row className='forms'>
                    <Col md={3}><p className='mb-3'>Account Info</p> </Col>
                    <MdEditor 
                        className='mx-3'
                        value={decodeURIComponent(values.accountInfoStore)}
                        style={{ height: '175px' }}
                        renderHTML={text => mdParser.render(text)} 
                        onChange={handleAccount} 
                    />
                </Row>
                <Row className='forms'>
                    <Col md={3}><p className='mb-3'>Whatsapp Number</p> </Col>
                    <MdEditor 
                        className='mx-3'
                        value={decodeURIComponent(values.whatsappNumber)}
                        style={{ height: '175px' }}
                        renderHTML={text => mdParser.render(text)} 
                        onChange={handleWhatsApp} 
                    />
                </Row>
                <div className="wrap-buttons">
                    {/* <Button type='button' disabled={loading}>Cancel</Button> */}
                    <Button type='submit' disabled={loading}>Save</Button>
                </div>
            </form>
        </div>
    )
}

export default StoreInfoForm;