export enum PartnerTypeEnum {
    Customer,
    Stockist,
    Reseller,
    Admin,
    AdminStockist,
    All
}
export interface AutoCancelKeepModel {
    autoCancelDate: string;
    keepDate: string;
    type: PartnerTypeEnum | null;
    partnerName: string;
    variationId: string;
    variationName: string;
    activity: number;
    productId: string;
    productName: string;
    productCode: string;
    note: string | null;
    quantity: number;
    isActive: boolean;
}