import axios from "axios";
import { endpoint } from "../../constants";
import { MenuAccessModel, RoleModel } from "./role.model";

export default class UOMApi {
    static createData(model: RoleModel) {
        return axios
        .post<any>(endpoint + "/api/user/createRole", model)
        .then((res) => res.data);
    }

    static getList() {
        return axios
        .get<any>(endpoint + "/api/User/GetAllRoleList")
        .then((res) => res.data);
    }

    static getSingleItem(id: string) {
        return axios
        .get<any>(endpoint +"/api/User/GetSingleRole?id=" + id)
        .then((res) => res.data.data);
    }

    static updateData(model: RoleModel) {
        return axios
        .post<any>(endpoint + "/api/User/EditRole", model)
        .then((res) => res.data);
    }

    static deleteData(id: string) {
        return axios
        .post<any>(endpoint + "/api/User/DeleteRole?id=" + id)
        .then((res) => [
          { response: res.data.data },
        ]);
    }

    static getMenuList() {
        return axios
        .get<any>(endpoint + "/api/Menu/GetList")
        .then((res) => res.data.data);
    }

    static getMenuAccsessByRole(id: string) {
        return axios
        .get<any>(endpoint +"/api/Menu/GetListMenuAccessByRole?roleId=" + id)
        .then((res) => res.data.data);
    }

    static createMenuAccess(model: MenuAccessModel) {
        return axios
        .post<any>(endpoint + "/api/Menu/CreateMenuAccess", model)
        .then((res) => res.data.data);
    }
}
