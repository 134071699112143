import { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
// import StoreInfoAdmin from "../components/StoreInfoAdmin";
// import StoreInfoReseller from "../components/StoreInfoReseller";
import { handleTab } from "../aplication.reducer";
import '../aplication.scss'
import AplicationEndCustomer from "../components/AplicationEndCustomer";
import AplicationReseller from "../components/AplicationReseller";
import AplicationStockist from "../components/AplicationStockist";

export default function Aplication() {
    const {activeTab} = useSelector((state:RootState) => state.aplication)
    const dispatch = useDispatch()
    const [tabs] = useState<any>([
        // 'Admin',
        'Reseller',
        'Stockist',
        'End Customer',
    ])
 
    return (
        <Container className="content-body aplication" fluid>
            <div className="page-title mb-4">Application</div>
            <div className="box">
                <div className="tabs">
                    {
                        tabs.map((res:any,i:number)=>(
                            <div key={i} className={activeTab === res ? 'childtab active' : 'childtab'} onClick={()=>dispatch(handleTab(res))}>{res}</div>
                        ))
                    }
                </div>
                {
                     activeTab ===  'Reseller'?
                    <AplicationReseller />
                    : activeTab ===  'Stockist'?
                    <AplicationStockist />
                    : activeTab ===  'End Customer'?
                    <AplicationEndCustomer />
                    :null
                }
               
            </div>

        </Container>
    );
}
