import React, { ChangeEvent, useEffect, useState } from "react";
import { Card, Form, Col, Row, Button, Table } from "react-bootstrap";
import { FaFileExport } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { DatePicker, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { getLaporanKartuStock, resetList } from "../laporanKartuStock.reducer";
import {AppDispatch, RootState} from "../../../../app/store";
import moment from "moment";
import {
  AllStockReportModel,
  KartuStockModel, ProductOptionModel,
  SummaryDataModel,
  VariantListModel,
} from "../laporanKartuStock.model";
import {
  PartnerModel,
  ProductModel,
  WarehouseModel,
} from "../../../../global/global.model";
import {
    getListPartner,
    getListProduct,
    getListWarehouse, getProductDropdown,
    setFilterStockReport,
} from "../../../../global/global.reducer";
import { Link } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import {AddressModel} from "../../../salesOrder/salesOrder/salesOrder.model";
import {unwrapResult} from "@reduxjs/toolkit";
import useAsyncProductOption from "../../../../hooks/product/useAsyncProductOption";

const LaporanKartuStock = () => {
  const dispatch : AppDispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const AllStockReportState = useSelector(
    (state: RootState) =>
      state.laporanKartuStock.allStockReport ?? ([] as AllStockReportModel[])
  );

    const isLoading = useSelector(
        (state: RootState) =>
            state.laporanKartuStock.isLoading
    );

  const LaporanKartuStockState = useSelector(
    (state: RootState) =>
      state.laporanKartuStock.list ?? ([] as KartuStockModel[])
  );

  const totalRow = useSelector(
      (state: RootState) =>
          state.laporanKartuStock.totalRow ?? 0
  );

  const SummaryDataState = useSelector(
    (state: RootState) =>
      state.laporanKartuStock.summaryData ?? ({} as SummaryDataModel)
  );

  const ProductListState = useSelector(
    (state: RootState) => state.global.listProduct ?? ([] as ProductModel[])
  );

  const WarehouseListState = useSelector(
    (state: RootState) => state.global.listWarehouse ?? ([] as WarehouseModel[])
  );

  const PartnerListState = useSelector(
    (state: RootState) => state.global.listPartner ?? ([] as PartnerModel[])
  );

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [productOptionList, setProductOptionList] = useState<ProductOptionModel[]>([]);
  const [productName, setProductName] = useState<string | null>(null);
  const [filterProductId, setFilterProductId] = useState<string | null>(null);
  const [filterWarehouseId, setFilterWarehouseId] = useState<string | null>(
    null
  );
  const [filterCustomerId, setFilterCustomerId] = useState<string | null>(null);
  const [filterSONumber, setFilterSONumber] = useState("");
  const [filterSKU, setFilterSKU] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [activePage, setActivePage] = useState(1);
  const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

  const {getProductOptions} = useAsyncProductOption();

  const handleExportToExcel = () => {
    // dispatch(
    //   exportToExcel({
    //     reportType: filterReportType,
    //     filterMonthly: filterMonthly,
    //     startFilterDate: filterStartDate,
    //     endFilterDate: filterEndDate,
    //     name: filterName,
    //     skip: currentPage - 1,
    //     take: perPage,
    //   })
    // );
  };
  console.log("total row", totalRow);
  const handlePagination = (page: any) => {
    setActivePage(page);
    dispatch(
        getLaporanKartuStock({
          inventorySKU: filterSKU,
          warehouseId: filterWarehouseId,
          productId: filterProductId,
          customerId: filterCustomerId,
          startDate: filterStartDate,
          endDate: filterEndDate,
          soNumber: filterSONumber,
          skip: rowsPerPage * (page - 1),
          take: rowsPerPage,
        }));
  };

  const handlePerPage = (e: any) => {
      let _take = e.target.value;
      setRowsPerPage(_take);
      dispatch(
          getLaporanKartuStock({
              inventorySKU: filterSKU,
              warehouseId: filterWarehouseId,
              productId: filterProductId,
              customerId: filterCustomerId,
              startDate: filterStartDate,
              endDate: filterEndDate,
              soNumber: filterSONumber,
              skip: 0,
              take: parseInt(_take),
          }));
    }

  useEffect(() => {
    if (ProductListState.length !== 0) {
      let _option = ProductListState.map((item) => {return {
        value: item.id,
        label: item.inventorySKU + " - " + item.name,
      }});
      // setProductOptionList(_option);
    }
  }, [ProductListState]);

  // const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setFilterProductId(e.target.value);
  // };

  const onWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterWarehouseId(e.target.value);
  };

  const onPartnerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterCustomerId(e.target.value);
  };

  const handleRangePicker = (event: any, picker: any) => {
    setFilterStartDate(picker[0]);
    setFilterEndDate(picker[1]);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onSelectedProductChange = (data: any) => {
    setFilterProductId(data.value);
    setProductName(data.label);
  };

  const onFilterResetClick = () => {
    setFilterSKU("");
    setFilterWarehouseId(null);
    setFilterProductId(null);
    setFilterCustomerId(null);
    setProductName(null);
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterSONumber("");

    dispatch(resetList());

    dispatch(
      getLaporanKartuStock({
        inventorySKU: "",
        warehouseId: null,
        productId: null,
        customerId: null,
        startDate: "",
        endDate: "",
        soNumber: "",
        skip: 0,
        take: perPage,
      })
    );
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getLaporanKartuStock({
        inventorySKU: filterSKU,
        warehouseId: filterWarehouseId,
        productId: filterProductId,
        customerId: filterCustomerId,
        startDate: filterStartDate,
        endDate: filterEndDate,
        soNumber: filterSONumber,
        skip: page,
        take: perPage,
      })
    );

    dispatch(
      setFilterStockReport({
        inventorySKU: filterSKU,
        warehouseId: filterWarehouseId,
        productId: filterProductId,
        customerId: filterCustomerId,
        startDate: filterStartDate,
        endDate: filterEndDate,
        soNumber: filterSONumber,
        skip: 0,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    dispatch(getListProduct());
    dispatch(getListWarehouse());
    dispatch(getListPartner());
    // fetchData(0, perPage);
  }, []);

  useEffect(() => {
    if (AllStockReportState.length !== 0) {
      console.log(AllStockReportState);
    }
  }, [AllStockReportState]);
  
  const getIndexNumber = (index: number) => {
     return rowsPerPage * (activePage - 1) === 0 ?
          index + 1
          :
          index + 1 + (activePage - 1) * rowsPerPage
  }

    // const filterProduct = async (inputValue: string) => {
    //     const resultAction = await dispatch(getProductDropdown({
    //         name: inputValue.toLowerCase() === "" ? "e" : inputValue.toLowerCase(),
    //         skip: 0,
    //         take: 150,
    //     }));
    //
    //     const originalPromiseResult = unwrapResult(resultAction);
    //     let _option = originalPromiseResult?.map((item: any) => {
    //         return {
    //             value: item.id,
    //             label: item.inventorySKU + " - " + item.name,
    //         }
    //     });
    //     return _option as ProductOptionModel[];
    // };

    const promiseOptions = (inputValue: string) =>
        new Promise<ProductOptionModel[]>((resolve) => {
            clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    resolve(getProductOptions(inputValue));
                }, 1000)
            );
        });

  return (
    <div>
      <div className="page-title mb-4 d-flex align-items-center justify-content-between">
        Laporan Kartu Stock
        <div>
          {/* <Button className="addor" onClick={handleExportToExcel}>
            <FaFileExport /> Export to Excel
          </Button> */}
          <Link
            to={{
              pathname: `/report-kartu-stock/print`,
            }}
          >

            <Button
              style={{ fontSize: 14, width: 202, height: 40 }}
              variant="outline-info"
              onClick={() => {
                dispatch(
                  setFilterStockReport({
                    inventorySKU: filterSKU,
                    warehouseId: filterWarehouseId,
                    productId: filterProductId,
                    customerId: filterCustomerId,
                    startDate: filterStartDate,
                    endDate: filterEndDate,
                    soNumber: filterSONumber,
                    skip: 0,
                    take: perPage,
                  })
                );
                // dispatch(countSOPrint(salesOrderID));
              }}
            >Print
            </Button>
          </Link>
          {/* <a
            href={window.location.origin + "/report-kartu-stock/print"}
            target="_blank"
          >
            <Button
              style={{ fontSize: 14, width: 202, height: 40 }}
              variant="outline-info"
              onClick={() => {
                dispatch(
                  setFilterStockReport({
                    inventorySKU: filterSKU,
                    warehouseId: filterWarehouseId,
                    productId: filterProductId,
                    customerId: filterCustomerId,
                    startDate: filterStartDate,
                    endDate: filterEndDate,
                    soNumber: filterSONumber,
                    skip: 0,
                    take: perPage,
                  })
                );
                // dispatch(countSOPrint(salesOrderID));
              }}
            >
              Print
            </Button>
          </a>{" "} */}
        </div>
      </div>

      <Grid>
        <Card>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Produk
                  </Form.Label>
                  <Col>
                    {/*<Form.Control*/}
                    {/*  as="select"*/}
                    {/*  onChange={onProductChange}*/}
                    {/*  value={filterProductId !== null ? filterProductId : ""}*/}
                    {/*>*/}
                    {/*  <option>select</option>*/}
                    {/*  {ProductListState.map((Item) => {*/}
                    {/*    return (*/}
                    {/*      <option value={Item.id}>*/}
                    {/*        {Item.inventorySKU} - {Item.name}*/}
                    {/*      </option>*/}
                    {/*    );*/}
                    {/*  })}*/}
                    {/*</Form.Control>*/}
                    {/*<Select*/}
                    {/*    key={`my_unique_select_key__${filterProductId}`}*/}
                    {/*    value={filterProductId !== null ?{*/}
                    {/*      value: filterProductId,*/}
                    {/*      label: productName,*/}
                    {/*    } : null}*/}
                    {/*    onChange={onSelectedProductChange}*/}
                    {/*    options={productOptionList}*/}
                    {/*/>*/}
                      <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions} value={filterProductId !== null ?{
                          value: filterProductId,
                          label: productName,
                      } : null}
                       onChange={onSelectedProductChange} />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Tanggal
                  </Form.Label>
                  <Col>
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        className="custext"
                        onChange={handleRangePicker}
                        value={
                          filterStartDate !== "" && filterEndDate !== ""
                            ? [
                                moment(filterStartDate, "YYYY-MM-DD"),
                                moment(filterEndDate, "YYYY-MM-DD"),
                              ]
                            : undefined
                        }
                        format="YYYY-MM-DD"
                      />
                    </Space>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    SO Number
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterSONumber}
                      onChange={(e) => {
                        setFilterSONumber(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
                {/*<Form.Group as={Row} className="input-padding m-1">*/}
                {/*  <Form.Label column sm={4}>*/}
                {/*    SKU*/}
                {/*  </Form.Label>*/}
                {/*  <Col>*/}
                {/*    <Form.Control*/}
                {/*      placeholder="Search"*/}
                {/*      type="text"*/}
                {/*      value={filterSKU}*/}
                {/*      onChange={(e) => {*/}
                {/*        setFilterSKU(e.target.value);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </Col>*/}
                {/*</Form.Group>*/}
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Warehouse
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      onChange={onWarehouseChange}
                      value={
                        filterWarehouseId !== null ? filterWarehouseId : ""
                      }
                    >
                      <option>select</option>
                      {WarehouseListState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Customer
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      onChange={onPartnerChange}
                      value={filterCustomerId !== null ? filterCustomerId : ""}
                    >
                      <option>select</option>
                      {PartnerListState.map((Item) => {
                        return <option value={Item.id}>{Item.fullName}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}></Col>
            </Row>

            <Row>
              <Col md={4}>
                <Button
                  variant="outline-info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterResetClick}
                >
                  Reset Filter
                </Button>
                <Button
                  variant="info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterClick}
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <br />

        <Card>
          <div style={{ overflowX: "scroll", scrollbarWidth: "none" }}>
            {/* <h5 className="m-3">
              {" "}
              Produk :  {SummaryDataState?.stockStart[0].inventorySKU} - {SummaryDataState?.stockStart[0].productName}
            </h5> */}

            <h5 className="m-3 p-1">
              {" "}
              Produk :{" "}
              {
                AllStockReportState[0]?.list[0]?.variantList[0].inventorySKU
              } - {AllStockReportState[0]?.list[0]?.variantList[0].productName}
            </h5>

            {AllStockReportState.map((reportStockItem) => (
              <Card
                className="m-3"
                style={{ overflowX: "scroll", scrollbarWidth: "none" }}
              >
                  <div>
                      <h6 className="m-2">
                          Warehouse : {reportStockItem.list[0]?.warehouseName}
                      </h6>
                  </div>
                  <div className="mx-2">
                      <div className="filter d-flex justify-content-between align-items-center">
                          Showing {reportStockItem.list.length} of {reportStockItem.total} results
                      </div>
                  </div>
                <Table className="m-2 table" size="sm" bordered>
                  <thead>
                    <tr>
                      <th rowSpan={2}>
                        <h6>NO</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>TANGGAL</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>NOMOR BUKTI</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>NAMA CUSTOMER</h6>
                      </th>
                      <th
                        colSpan={
                          reportStockItem.list[0]?.variantList.length !==
                          undefined
                            ? reportStockItem.list[0]?.variantList.length
                            : reportStockItem.summary?.stockStart?.length
                        }
                      >
                        <h6>Varian</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>SO</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>PRINT</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>GD</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>QC</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>PK</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>EXP</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>STATUS</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>KETERANGAN</h6>
                      </th>
                    </tr>
                    <tr>
                      {reportStockItem.list[0]?.variantList.length ===
                        undefined &&
                        reportStockItem.summary?.stockStart?.map(
                          (variantItem: VariantListModel) => (
                            <td>{variantItem.variantName}</td>
                          )
                        )}

                      {reportStockItem.list[0]?.variantList.length !== 0 &&
                        reportStockItem.list[0]?.variantList.map(
                          (variantItem: VariantListModel) => (
                            <td>{variantItem.variantName}</td>
                          )
                        )}
                    </tr>
                  </thead>
                    {
                        isLoading
                        ?
                            <tbody>
                            <tr>
                                <td colSpan={14}>
                                    <h4 className="text-center">Loading...</h4>
                                </td>
                            </tr>
                            </tbody>
                        :
                  <tbody>
                    {reportStockItem.list.map(
                      (item: KartuStockModel, index: number) => (
                        <tr>
                          <td>
                            {!item.isRetur && <p>                                                     {getIndexNumber(index)}
                            </p>}
                            {item.isRetur && (
                              <p style={{ color: "red" }}>{getIndexNumber(index)}</p>
                            )}
                          </td>
                          <td>
                            {!item.isRetur && (
                              <p>
                                {" "}
                                {moment(new Date(item.orderDate)).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </p>
                            )}
                            {item.isRetur && (
                              <p style={{ color: "red" }}>
                                {" "}
                                {moment(new Date(item.orderDate)).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </p>
                            )}
                          </td>

                          <td>
                            {!item.isRetur && <p> {item.soNumber}</p>}
                            {item.isRetur && (
                              <p style={{ color: "red" }}> {item.soNumber}</p>
                            )}
                          </td>

                          <td>
                            {!item.isRetur && <p> {item.customerName}</p>}
                            {item.isRetur && (
                              <p style={{ color: "red" }}>
                                {" "}
                                {item.customerName}
                              </p>
                            )}
                          </td>

                          {item.variantList.length !== 0 &&
                            item.variantList.map(
                              (variantItem: VariantListModel) => (
                                <td>
                                  {!item.isRetur && <p> {variantItem.qty}</p>}
                                  {item.isRetur && (
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {variantItem.qty}
                                    </p>
                                  )}
                                </td>
                              )
                            )}

                          {item.variantList.length === 0 &&
                            reportStockItem.summary.stockStart.map(
                              (variantItem: VariantListModel) => (
                                <td>
                                  {!item.isRetur && <p> </p>}
                                  {item.isRetur && (
                                    <p style={{ color: "red" }}> </p>
                                  )}
                                </td>
                              )
                            )}

                          <td>-</td>
                          <td>-</td>
                          <td>{item.adminGudang}</td>
                          <td>{item.adminQC}</td>
                          <td>{item.adminPacking}</td>
                          <td>{item.adminEkspedisi}</td>
                          <td>{item.transactionStatus}</td>
                          <td>-</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td
                          colSpan={
                              reportStockItem.list[0]?.variantList.length + 12
                          }
                          height={30}
                      >
                          <div className={"d-flex"}>
                              <div >
                                  <div className="result d-flex align-items-center">
                                      <span style={{width: "250px"}}>Results per page:</span>
                                      <select className="custom-select sel" value={rowsPerPage} name='take' onChange={handlePerPage} >
                                          <option value="10">10</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                          <option value="200">200</option>
                                          <option value="500">500</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="d-flex align-items-center">
                                  {/* <Pagination current={values.page}  onChange={handlePagination} total={total} /> */}
                                  <div className="custom-paging-layout ml-3">
                                      <Pagination
                                          activePage={activePage}
                                          itemClass='item'
                                          itemsCountPerPage={rowsPerPage}
                                          totalItemsCount={reportStockItem.total}
                                          pageRangeDisplayed={5}
                                          onChange={handlePagination}
                                          prevPageText='⟨ PREVIOUS'
                                          itemClassPrev='prev'
                                          itemClassNext='next'
                                          nextPageText='NEXT ⟩'
                                          hideFirstLastPages
                                      />
                                  </div>
                              </div>
                          </div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={
                          reportStockItem.list[0]?.variantList.length + 12
                        }
                        height={30}
                      ></td>
                    </tr>

                    <tr>
                      <td colSpan={4}>
                        <h6>STOCK AWAL</h6>
                      </td>

                      {reportStockItem.summary?.stockStart?.map(
                        (variantItem: VariantListModel) => (
                          <td>{variantItem.qty}</td>
                        )
                      )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>RETUR</h6>
                        </td>

                        {reportStockItem.summary?.retur?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>COMPLAINT</h6>
                        </td>

                        {reportStockItem.summary?.complaint?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>KEEP</h6>
                        </td>

                        {reportStockItem.summary?.keep?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>STOCK OPNAME</h6>
                        </td>

                        {reportStockItem.summary?.stockOpname?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>INTERNAL USE</h6>
                        </td>

                        {reportStockItem.summary?.internalUse?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>INVENTORY MOVE</h6>
                        </td>

                        {reportStockItem.summary?.inventoryMove?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>ITEM RECEIVE</h6>
                        </td>

                        {reportStockItem.summary?.itemReceive?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>SO</h6>
                        </td>

                        {reportStockItem.summary?.salesOrder?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>STOCK AKHIR</h6>
                        </td>

                        {reportStockItem.summary?.stockEnd?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>CUSTOMER KEEP</h6>
                        </td>

                        {reportStockItem.summary?.keepLive?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>RETUR</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.returStock?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>STOCK IN</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.updateStockIn?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>STOCK OUT</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.updateStockOut?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>KEEP IN</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.keepIn?.map(*/}
                    {/*      (variantItem: VariantListModel) => (*/}
                    {/*          <td>{variantItem.qty}</td>*/}
                    {/*      )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>KEEP OUT</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.keepOut?.map(*/}
                    {/*      (variantItem: VariantListModel) => (*/}
                    {/*          <td>{variantItem.qty}</td>*/}
                    {/*      )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>SISA STOCK</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.stockEnd?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}
                  </tbody>
                    }
                </Table>
              </Card>
            ))}
          </div>

          <div className="d-flex justify-content-end">
            {/* <Pagination
              activePage={5}
              itemClass="item"
              itemsCountPerPage={10}
              totalItemsCount={50}
              pageRangeDisplayed={5}
              onChange={handlePagination}
              prevPageText="⟨ PREVIOUS"
              itemClassPrev="prev"
              itemClassNext="next"
              nextPageText="NEXT ⟩"
              hideFirstLastPages
            /> */}
          </div>
        </Card>
      </Grid>
    </div>
  );
};

export default LaporanKartuStock;
