import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import ProductForm from "../components/productForm";
import { ProductModel } from "../product.model";
import {
  getListCollectionTag,
  getListProductCategories,
  getProductGroupingPerProduct,
  getSingleImageGalleryData,
  getSingleItem,
  resetImageGallery,
} from "../product.reducer";

const ProductPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const [isEdit, setIsEdit] = useState(false);

  const singleDataState = useSelector(
    (state: RootState) => state.product.single ?? ({} as ProductModel)
  );

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
      dispatch(getSingleImageGalleryData(id));
      dispatch(getProductGroupingPerProduct(id));
      dispatch(resetImageGallery())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getListProductCategories());

    dispatch(getListCollectionTag());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <ProductForm data={singleDataState} isEdit={isEdit} />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductPage;
