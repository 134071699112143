import "./alert.css";
import warningIcon from "../../assets/icons/alert/warning.svg";
import successIcon from "../../assets/vector/success.png";
import infoIcon from "../../assets/icons/alert/info.svg";
import failedIcon from "../../assets/vector/failed.png";
import trashIcon from "../../assets/vector/cross.png";
import cross from "../../assets/vector/cross.png";
import { Modal, Button } from "react-bootstrap";

const AlertMessage = ({
  show = false,
  variant = "",
  title = "",
  content = "",
  ...props
}: AlertMessageProps) => {
  return (
    <>
      <Modal show={show} dialogClassName="my-modal" backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <img
            className="float-right"
            style={{ width: 25 }}
            src={cross}
            onClick={props.onAlertCloseAction}
          />
          <div style={{ paddingTop: 30 }} className="text-center">
            {variant === "success" && <img src={successIcon} alt="logo" width="64" />}
            {variant === "failed" && <img src={failedIcon} alt="logo" width="64" />}
            {variant === "info" && <img src={infoIcon} alt="logo" width="64" />}
            {variant === "warning" && <img src={warningIcon} alt="logo" width="64" />}

            <p className="text">{title}</p>
            <p>{content}</p>
          </div>
        </Modal.Body>
        <div className="text-center" style={{ paddingBottom: 30, paddingTop: 20 }}>
          {variant === "delete" ? (
            <>
              <Button className="btn-secondary" onClick={props.onAlertCloseAction}>
                Tidak
              </Button>
              <Button className="btn-info" onClick={props.onAlertCloseAction}>
                Hapus
              </Button>
            </>
          ) : (
            <Button className="btn-info" onClick={props.onAlertCloseAction}>
              Close
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export interface AlertMessageProps {
  show: boolean;
  variant?: string;
  title?: string;
  content?: string;
  onAlertCloseAction: () => void;
}

export default AlertMessage;
