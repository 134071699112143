import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";

import { CollectionTagModel, CreateCollectionModel } from "../collectionTag.model";
import { createData, getSingleItem, updateData, updateImage } from "../collectionTag.reducer";
import CollectionTagForm from "../components/CollectionTagForm";

const CollectionTagPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.collectionTag.single ?? ({} as CollectionTagModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
     if (id !== undefined) {
       dispatch(getSingleItem(id));
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

  const onSubmit = (
    name: string,
    description: string,
    keyword: string,
    banner: string,
    fileFormat: string,
    imagePath: string,
    isActive: boolean
    ) => {
    if (isEdit) {
      if (banner === ""){
        dispatch(
          updateData({
            id, name, description, keyword, banner:imagePath, isActive
          } as CollectionTagModel)
        );
      } else {
        dispatch(
          updateImage({
            CollectionTagModel: {
              id,
              name, 
              description, 
              keyword, 
              banner,
              isActive
            },
            UploadImageModel: {
              file_name: "file_name." + fileFormat,
              base64url: banner.split(",")[1],
              imagepath: "CollectionTag",
              location: "CollectionTag"
            }
            //code, name, slug, description, parent, image
          } as CreateCollectionModel))
      }
    } else {
      dispatch(
        createData({
          CollectionTagModel: {
            id,
            name, 
            banner,
            description, 
            keyword, 
          },
          UploadImageModel: {
            file_name: "file_name." + fileFormat,
            base64url: banner.split(",")[1],
            imagepath: "CollectionTag",
            location: "CollectionTag"
          }
          //code, name, slug, description, parent, image
        } as CreateCollectionModel)
      );
    }
  };
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <CollectionTagForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
               />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CollectionTagPage;
