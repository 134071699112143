import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import MDEditor from "@uiw/react-md-editor";
import SettingFaqTable from "./settingFaqTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getFaqList, resetStatus, resetSingle } from "../settingFaq.reducer";

const SettingFaqForm = ({ partnerType = 0, ...props }) => {
  const dispatch = useDispatch();

  const [id, setId] = useState("")
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [validated, setValidated] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const formId = "faqForm_"+partnerType.toString();

  const FaqStatusState = useSelector(
    (state: RootState) => state.settingFaq.status ?? ""
  );

  const onTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
  };

  const onContentChange = (e: any) => {
    setContent(e.toString());
  };

  useEffect(() => {
    if (FaqStatusState === "success") {
      setTitle("");
      setContent("");
    } else if (FaqStatusState === "success-update"){
      setIsEdit(false)
      setTitle("");
      setContent("");
    } else if (FaqStatusState === "success-delete"){
      setIsEdit(false)
      setTitle("");
      setContent("");
    }
  }, [FaqStatusState]);

  const editFaq = (id: string, title: string, contentFAQ : string, type: number) => {
    setIsEdit(true);
    setId(id)
    setTitle(title)
    setContent(contentFAQ)
  }

  return (
    <div>
      <Form
        id={formId}
        noValidate
        // validated={validated}
        onSubmit={(e) => {

          console.log(partnerType)
          
          const form = e.currentTarget;
          // if (form.checkValidity() === false) {
          //   e.preventDefault();
          //   e.stopPropagation();
          // } else {
          //   e.preventDefault();
          //   props.onSubmit(title, content, partnerType, isEdit, id);
          // }

          e.preventDefault();

          props.onSubmit(title, content, partnerType, isEdit, id);
          setValidated(true);
        }}>
        <Grid>
          <hr />
          <br />
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Title
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                placeholder="-"
                required
                onChange={onTitleChange}
                value={title}></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Content
            </Form.Label>
            <Col sm={9}>
              <MDEditor value={content} onChange={onContentChange} />
              {/* <MDEditor.Markdown source={content} /> */}
            </Col>
          </Form.Group>

          <hr />

          <div className="btn-footer-group">
            <Button
              variant="outline-info"
              style={{ width: 130, height: 45 }}
              // onClick={onAddProductClick}
            >
              Cancel
            </Button>
            {"   "}
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              type="submit"
              form={formId}
              // onClick={onAddProductClick}
            >
              Save
            </Button>
          </div>

          <hr />

          <SettingFaqTable partnerType={partnerType} onEdit={editFaq} />
        </Grid>
      </Form>
    </div>
  );
};

export interface FaqFormProps {
  onSubmit: (title: string, content: string, partnerType: number,isEdit : boolean, id:string) => void;
  partnerType: number;
}

export default SettingFaqForm;
