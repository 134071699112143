import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { createData, getList } from "../subscriber.reducer";
import { RootState } from "../../../app/store";
import { useEffect, useState } from "react";
import { SubscriberModel } from "../subscriber.model";
import SubscriberTable from "../components/SubscriberTable";

const SubscriberPage = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const State = useSelector((state: RootState) => state.city.name);

  const singleDataState = useSelector((state: RootState) => state.city.single ?? ({} as SubscriberModel));

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  const onSubmit = (phoneNumber: string) => {
    dispatch(
      createData({
        phoneNumber,
      } as SubscriberModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <SubscriberTable onSubmit={onSubmit} isEdit={isEdit} />
    </Container>
  );
};

export default SubscriberPage;
