import { createData, getList } from "../district.reducer";
import DistrictTable from "../components/districtTable";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { RootState } from "../../../../app/store";
import { DistrictModel } from "../district.model";

const DistrictTablePage = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const State = useSelector((state: RootState) => state.district.dateChanges);

  const singleDataState = useSelector(
    (state: RootState) => state.district.single ?? ({} as DistrictModel)
  );

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  const onSubmit = (no: number, name: string, cityId: string) => {
    dispatch(
      createData({
        no,
        name,
        cityId,
      } as DistrictModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <DistrictTable data={singleDataState} onSubmit={onSubmit} isEdit={isEdit} />
    </Container>
  );
};

export default DistrictTablePage;
