import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { getListProduct, getListUOM, getListWarehouse } from "../../../../global/global.reducer";
import InventoryMoveForm from "../components/inventoryMoveForm";
import ProductTableComponent from "../components/productTableComponent";
import {
  CreateProductHistoryModel,
  InventoryMoveDetailModel,
  InventoryMoveModel,
} from "../inventoryMove.model";
import {
  createData,
  createDetailData,
  getInventoryMoveDetailList,
  getSingleItem,
  resetStatus,
  updateData,
} from "../inventoryMove.reducer";

const InventoryMovePage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.inventoryMove.single ?? ({} as InventoryMoveModel)
  );

  const inventoryMoveStatusState = useSelector(
    (state: RootState) => state.inventoryMove.status ?? ""
  )

  const [inventoryMoveDetail, setInventoryMove] = useState<InventoryMoveDetailModel[]>([]);
  const [inventoryMoveDetailData, setInventoryMoveDetailData] = useState({
    inventoryMoveId: "",
    listDetail: inventoryMoveDetail,
  });

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getListWarehouse());
    dispatch(getListProduct());
    dispatch(getListUOM());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
      dispatch(getInventoryMoveDetailList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if(inventoryMoveStatusState==="success-delete-detail" ||
        inventoryMoveStatusState==="success-create-detail" ){
      dispatch(getInventoryMoveDetailList(id));
      dispatch(resetStatus)
    }
    
  }, [inventoryMoveStatusState])
  

  const onInventoryMoveDetailSubmit = (inventoryMoveDetail: InventoryMoveDetailModel[]) => {
    setInventoryMove(inventoryMoveDetail);
    var temp = {
      inventoryMoveId: "",
      listDetail: inventoryMoveDetail,
    };
    setInventoryMoveDetailData(temp);
  };

  const onSubmit = (
    inventoryNumber: string,
    inventoryMoveDate: Date,
    status: number,
    userId: string,
    fromWarehouse: string,
    toWarehouse: string,
    note: string,
    inventoryReceiveId: string,
    approveBy: string,
    rejectedBy: string,
    approvedDate: Date,
    rejectedDate: Date
  ) => {
    if (isEdit) {
      dispatch(
        updateData({
          inventoryMove: {
            id,
            inventoryNumber,
            inventoryMoveDate,
            status,
            userId,
            fromWarehouse,
            toWarehouse,
            note,
            inventoryReceiveId,
            approveBy,
            rejectedBy,
            approvedDate,
            rejectedDate,
          },
          inventoryMoveDetail: inventoryMoveDetailData,
        } as CreateProductHistoryModel)
      );
    } else {
      dispatch(
        createData({
          inventoryMove: {
            inventoryNumber,
            inventoryMoveDate,
            status,
            userId,
            fromWarehouse,
            toWarehouse,
            note,
            inventoryReceiveId,
            approveBy,
            rejectedBy,
            approvedDate,
            rejectedDate,
          },
          inventoryMoveDetail: inventoryMoveDetailData,
        } as CreateProductHistoryModel)
      );
    }
  };

  // const onCreateDetail = (
  // productId: string,
  // variantId : string,
  // productCode: string,
  // productName: string,
  // quantity: number,
  // note: string,
  // variationName: string,
  // productSku: string,
  // uomId?: string,
  // uomName?: string,) => {
  //   dispatch(
  //     createDetailData({
  //       inventoryMoveId: id,
  //       listDetail: [
  //         {
  //           productId: productId,
  //           variantId: variantId,
  //           productCode,
  //           productName,
  //           quantity,
  //           uomId: null,
  //           uomName,
  //           note,
  //           variationName,
  //           productSku,
  //         } as unknown as InventoryMoveDetailModel,
  //       ]
      
  //     })
  //   )
  // }

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <InventoryMoveForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
          <section>
            <ProductTableComponent onSubmitData={onInventoryMoveDetailSubmit} inventoryMoveId={id} isEdit={isEdit} isApproved={singleDataState.status===1} />
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default InventoryMovePage;
