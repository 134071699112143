import { ChangeEvent, useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ResetPasswordModel } from '../models';
import logo from './../../../assets/logo.png';
import loginPanel from './../../../assets/login-panel.png';

import './style.css';

const ForgoPasswordForm = ({
  data = {} as ResetPasswordModel,
  ...props
}: SendEmailFormProps) => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const onEmailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    setEmail(data.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Form 
    style={{overflow: "hidden"}}
    noValidate
    validated={validated}
    onSubmit={(e) => {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        e.preventDefault();
        props.onSubmit(email);
        setEmail("");
        history.replace("/email-confirmation");
      }
      setValidated(true);
    }}>
      <Row style={{marginRight: 0}}>
        <Col className="background-login">
          <div className="center-image" >
            <img src={loginPanel} alt="image" style={{ width:"60vh" }}></img>   
          </div> 
        </Col>
        <Col>
          <div className="vertical-center">
            <img src={logo} alt="Logo" className="logo-img" />

            <Form.Group>
              <Form.Label className="title-text">Forgot Password</Form.Label>
            </Form.Group> 

            <Form.Group>
              <Form.Label className="subtitle-text">Enter your email registered with your account and we’ll send an email instructions to reset your password.</Form.Label>
            </Form.Group>  

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control 
                className="label-margin" 
                type="email" 
                placeholder="Enter email" 
                onChange={onEmailChange}
                value={email}
                autoComplete="off"/>
            </Form.Group> 

            <Button className="btn-login primary-btn" type="submit" block>
              Send Instructions
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export interface SendEmailFormProps {
  onSubmit: ( email: string) => void;
  data: ResetPasswordModel;
}

export default ForgoPasswordForm;
