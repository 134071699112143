import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ResellerSocialMediaModel } from "../registerReseller/registerReseller.model";

import RegisterStockistApi from "./registerStockist.api";
import { StockistModel,StockistSocialMediaModel, PartnerStore, PartnerRegisterModel, ProvinceModel, CityModel, DistrictModel, StoreTypeEnum, UploadImageModel, PartnerRequest, RejectionModel, FilterModel } from "./registerStockist.model";

export interface RegisterStockistSlice {
    name?: string;
    list?: StockistModel[];
    single?: PartnerRegisterModel;
    userId?: string;
    partnerId?: string;
    listProvince?: ProvinceModel[];
    listCity?: CityModel[];
    listDistrict?: DistrictModel[];
    listStoreProvince?: ProvinceModel[];
    listStoreCity?: CityModel[];
    listStoreDistrict?: DistrictModel[];
    imageSelfPath?: string;
    imageKtpPath?: string;
    imageSelfKtpPath?: string;
    imageNpwpPath?: string;
    storeList?: PartnerStore[];
    storeTypeEnumList?: StoreTypeEnum[];
    socialMediaList?: ResellerSocialMediaModel[];
    totalRow? : number;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getSingleStockistData = createAsyncThunk(
    'GetSingleStockistDataState/GetSingleStockistData',
    async (partnerId: string, {rejectWithValue}) => {
        try {
            return await RegisterStockistApi.getSingleStockistData(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const registerStockist = createAsyncThunk(
    'registerStockistState/registerStockist',
    async (model: PartnerRegisterModel, {rejectWithValue}) => {
        try {
            return await RegisterStockistApi.registerReseller(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>)
        }
    }
)

export const getStockistList = createAsyncThunk(
    'getStockistListState/getStockistList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getStockistList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStockistListWithPaging = createAsyncThunk(
    'getStockistListWithPagingState/getStockistListWithPaging',
    async (model : FilterModel, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getCustomerListWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getProvince = createAsyncThunk(
    'ProvinceState/Province',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getProvince();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getCity = createAsyncThunk(
    'CityState/City',
    async (provinceId: string, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getCity(provinceId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getDistrict = createAsyncThunk(
    'DistrictState/District',
    async (cityId: string, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getDistrict(cityId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreProvince = createAsyncThunk(
    'StoreProvinceState/Province',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getProvince();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreCity = createAsyncThunk(
    'StoreCityState/City',
    async (provinceId: string, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getCity(provinceId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreDistrict = createAsyncThunk(
    'StoreDistrictState/District',
    async (cityId: string, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getDistrict(cityId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getStoreTypeEnumList = createAsyncThunk(
    'StoreTypeEnumState/StoreTypeEnum',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getStoreTypeEnumList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getSocialMediaEnumList = createAsyncThunk(
    'SocialMediaEnumState/SocialMediaEnum',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getSocialMediaEnumList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const uploadPhoto = createAsyncThunk(
    'UploadPhotoKtpState/UploadPhotoKtp',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const uploadPhotoSelf = createAsyncThunk(
    'UploadPhotoSelfState/UploadPhotoSelf',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const uploadPhotoSelfKtp = createAsyncThunk(
    'UploadPhotoSelfKtpState/UploadPhotoSelfKtp',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)


export const uploadPhotoNpwp = createAsyncThunk(
    'UploadPhotoNpwpState/UploadPhotoNpwp',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getPartnerStore = createAsyncThunk(
    'PartnerStoreState/PartnerStore',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterStockistApi.getPartnerStoreList(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const approveStockist = createAsyncThunk(
    'ApproveStockistState/ApproveStockist',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterStockistApi.approveStockist(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const rejectStockist = createAsyncThunk(
    'RejectStockistState/RejectStockist',
    async (model:RejectionModel, { rejectWithValue }) => {
        try {
            const response = await RegisterStockistApi.rejectStockist(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteStockist = createAsyncThunk(
    'DeleteStockistState/DeleteStockist',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterStockistApi.deleteStockist(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updatePartner = createAsyncThunk(
    'UpdatePartnerState/UpdatePartner',
    async (model: PartnerRequest, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.updatePartner(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deletePartnerStore = createAsyncThunk(
    'DeletePartnerStoreState/DeletePartnerStore',
    async (storeId: string, { rejectWithValue }) => {
        try {
            const response = await RegisterStockistApi.deletePartnerStore(storeId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updatePartnerStore = createAsyncThunk(
    'UpdatePartnerStoreState/UpdatePartnerStore',
    async (model: PartnerStore, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.updatePartnerStore(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const createPartnerStore = createAsyncThunk(
    'CreatePartnerStoreState/CreatePartnerStore',
    async (model: PartnerStore, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.createPartnerStore(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getPartnerStoreListByPartner = createAsyncThunk(
    'GetPartnerStoreListByPartnerState/GetPartnerStoreListByPartner',
    async (partnerId: string, { rejectWithValue }) => {
        try {
            return await RegisterStockistApi.getPartnerStoreByPartnerId(partnerId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const RegisterStockistSlice = createSlice({
    name: 'RegisterStockistState',
    initialState: {} as RegisterStockistSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        // setRegisterUser: (state, action) => {
        //     state.registerUserModel = action.payload;
        // },
        resetSingle: (state) => {
            state.single = {} as PartnerRegisterModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        },
        setStoreList: (state, action) => {
            state.storeList = action.payload;
        },
        resetStoreAddress: (state) => {
            state.listStoreProvince = [] 
            state.listStoreCity = []
            state.listStoreDistrict = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(registerStockist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(registerStockist.fulfilled, (state, { payload }) => {
            // state.partnerId = payload.data.id;
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(registerStockist.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(getStockistList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStockistList.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getStockistList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getStockistListWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStockistListWithPaging.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getStockistListWithPaging.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSingleStockistData.pending, (state) => {
            state.single = undefined;
            state.isLoading = true;
        });
        builder.addCase(getSingleStockistData.fulfilled, (state, { payload }) => {
            // state.single = payload;
            state.isLoading = false;
            state.single = payload.data;
        });
        builder.addCase(getSingleStockistData.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getProvince.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getProvince.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listProvince = payload;
        })
        builder.addCase(getProvince.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getCity.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getCity.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listCity = payload;
        })
        builder.addCase(getCity.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getDistrict.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getDistrict.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listDistrict = payload;
        })
        builder.addCase(getDistrict.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreProvince.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreProvince.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listStoreProvince = payload;
        })
        builder.addCase(getStoreProvince.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreCity.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreCity.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listStoreCity = payload;
        })
        builder.addCase(getStoreCity.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreDistrict.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreDistrict.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.listStoreDistrict = payload;
        })
        builder.addCase(getStoreDistrict.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhoto.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhoto.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageKtpPath = payload.imagepath;
            console.log(payload);
        })
        builder.addCase(uploadPhoto.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhotoSelf.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhotoSelf.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageSelfPath = payload.imagepath;
        })
        builder.addCase(uploadPhotoSelf.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhotoSelfKtp.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhotoSelfKtp.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageSelfKtpPath = payload.imagepath;
        })
        builder.addCase(uploadPhotoSelfKtp.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(uploadPhotoNpwp.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhotoNpwp.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imageNpwpPath = payload.imagepath;
        })
        builder.addCase(uploadPhotoNpwp.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getStoreTypeEnumList.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getStoreTypeEnumList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.storeTypeEnumList = payload;
        })
        builder.addCase(getStoreTypeEnumList.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getSocialMediaEnumList.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getSocialMediaEnumList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.socialMediaList = payload;
        })
        builder.addCase(getSocialMediaEnumList.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(approveStockist.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(approveStockist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-approve";
        })
        builder.addCase(approveStockist.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(rejectStockist.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(rejectStockist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-reject";
        })
        builder.addCase(rejectStockist.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteStockist.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteStockist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-delete";
        })
        builder.addCase(deleteStockist.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(updatePartner.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updatePartner.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-update";
        })
        builder.addCase(updatePartner.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(updatePartnerStore.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updatePartnerStore.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-update-store";
        })
        builder.addCase(updatePartnerStore.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(createPartnerStore.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(createPartnerStore.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-add-store";
        })
        builder.addCase(createPartnerStore.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(deletePartnerStore.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deletePartnerStore.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-delete-store";
        })
        builder.addCase(deletePartnerStore.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(getPartnerStoreListByPartner.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPartnerStoreListByPartner.fulfilled, (state, { payload }) => {
            state.storeList = payload;
            state.isLoading = false;
        });
        builder.addCase(getPartnerStoreListByPartner.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

    }
})

export const { setFilter, resetSingle, resetStatus, setStoreList,resetStoreAddress } = RegisterStockistSlice.actions;
export default RegisterStockistSlice.reducer;
