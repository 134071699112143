import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import Api from "./inventoryReceive.api";
import {
  FilterModel,
  InventoryMoveModel,
  InventoryReceiveDetailModel,
  InventoryReceiveHistoryModel,
} from "./inventoryReceive.model";

export interface InventoryReceiveSlice {
  name?: string;
  list?: InventoryReceiveHistoryModel[];
  single?: InventoryReceiveHistoryModel;
  listInventoryReceiveHistoryDetail?: InventoryReceiveDetailModel[];
  totalRow?: number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const getList = createAsyncThunk(
  "InventoryReceiveHistoryStatee/getInventoryReceiveList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWithPaging = createAsyncThunk(
  "InventoryReceiveGetListWithPagingState/GetListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "InventoryReceiveHistoryState/InventoryReceiveSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "InventoryReceiveHistoryState/deleteInventoryReceive",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const approvalInventoryReceiveHistory = createAsyncThunk(
  "InventoryReceiveHistoryState/approval",
  async (Model: InventoryReceiveHistoryModel, { rejectWithValue }) => {
    try {
      return await Api.updateData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getInventoryMoveDetailList = createAsyncThunk(
  "InventoryMoveState/getInventoryMoveDetailList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getInventoryMoveDetailList(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const InventoryReceiveSlice = createSlice({
  name: "InventoryReceiveState",
  initialState: {} as InventoryReceiveSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as InventoryReceiveHistoryModel;
      state.listInventoryReceiveHistoryDetail = [];
    },
    resetStatus: (state) => {
      state.status = "";
    },
    setInventoryReceiveDetail: (state, action) => {
      state.listInventoryReceiveHistoryDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(approvalInventoryReceiveHistory.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(
      approvalInventoryReceiveHistory.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.name = new Date().toISOString();
      }
    );
    builder.addCase(
      approvalInventoryReceiveHistory.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = payload as ApiErrorResponse<any>;
      }
    );
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getInventoryMoveDetailList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getInventoryMoveDetailList.fulfilled,
      (state, { payload }) => {
        state.listInventoryReceiveHistoryDetail = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getInventoryMoveDetailList.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
  },
});

export const {
  setFilter,
  resetSingle,
  resetStatus,
  setInventoryReceiveDetail,
} = InventoryReceiveSlice.actions;
export default InventoryReceiveSlice.reducer;
