import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import edit from "./../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import axios from "axios";
import { KeepBelumLunasDetailModel } from "../../pesananDalamProses/keepBelumLunas/keepBelumLunas.model";
import { endpoint } from "../../../../constants";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import NumberFormat from "react-number-format";


/*
 * created by : dikembe mutombo
 * created date : 26/oct/2021
 */

const DataSalesOrderDetail = ({ id = "", ...props }: DataSalesOrderDetailProps) => {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState<KeepBelumLunasDetailModel[]>([]);

  const columns = [
    {
      name: "SKU",
      selector: "inventorySKU",
      sortable: true,
    },
    // {
    //   name: "Tanggal Pesanan",
    //   selector: "productId",
    //   sortable: true,
    // },
    {
      name: "Produk",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Varian",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "HARGA",
      selector: (d: {price: number }) => (
        <>
          <NumberFormat
            value={d.price}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "CATATAN",
      selector: "note",
      sortable: true,
    },
    // {
    //   name: "ACTION",
    //   selector: (d: { salesOrderId: any }) => (
    //     <>
    //       <Link
    //         to={{
    //           pathname: `/order-detail/${d.salesOrderId}`,
    //         }}
    //       >
    //         <img src={edit}></img>
    //       </Link>
    //     </>
    //   ),
    // },
  ];
  // end of datatable components

  useEffect(() => {
    axios
      .get<any>(endpoint + "/api/SalesOrderDetail/GetListBySalesOrder?salesOrderId=" + id)
      .then((res) => setList(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Card>
        <Card.Body>
          <DataTable
            columns={columns}
            data={list}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export interface DataSalesOrderDetailProps {
  id: string;
}

export default DataSalesOrderDetail;
