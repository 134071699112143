import ConfiguretermsTable from "../components/configureTermsTable";
import { getConfiguretermsList } from "../attribute.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const ConfigureTermsIndex = () => {
  let { attributeId } = useParams<{ attributeId: string }>();

  const dispatch = useDispatch();
  const statusState = useSelector((state: RootState) => state.attribute.name);

  useEffect(() => {
    dispatch(getConfiguretermsList(attributeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  return (
    <div>
      <ConfiguretermsTable />
    </div>
  );
};

export default ConfigureTermsIndex;
