import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import PaymentMethodTable from "../components/PaymentMethodTable";
import { PaymentMethodModel } from "../paymentMethod.model";
import { createData, getList, getRoleList } from "../paymentMethod.reducer";

const PaymentMethodTablePage = () => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);

  const State = useSelector((state: RootState) => state.paymentMethod.name);

  const singleDataState = useSelector(
    (state: RootState) => state.paymentMethod.single ?? ({} as PaymentMethodModel)
  );

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  useEffect(() => {
    dispatch(getRoleList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  const onSubmit = (name: string, noRek: string, ownerName:string, type: string[], isActive:boolean, bankId : string, image : string) => {
    dispatch(
      createData({
        name,
        noRek,
        ownerName,
        type,
        isActive,
        bankId,
        image
      } as PaymentMethodModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <PaymentMethodTable data={singleDataState} onSubmit={onSubmit} isEdit={isEdit} />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentMethodTablePage;
