import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../../app/store";
import {
  deleteData,
  getListWithPaging,
  importDataProduct,
  resetStatus,
} from "../product.reducer";
import DeleteMessage from "../../../../components/DeleteMessage";
import Dropzone from "react-dropzone";
import AlertMessage from "../../../../components/alertMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";


const ProductTable = () => {
  const dispatch = useDispatch();
  const ProductState = useSelector(
    (state: RootState) => state.product.list ?? []
  );
  const totalRowState = useSelector(
    (state: RootState) => state.product.totalRow ?? 0
  );

  const productStatusState = useSelector(
    (state: RootState) => state.product.status ?? ""
  )

  const [filterName, setFilterName] = useState("");
  const [filterInventorySKU, setFilterInventorySKU] = useState("");
  const [filterProdutcType, setFilterProductType] = useState<number | null>(
    null
  );
  const [filterTempProductType, setFilterTempProductType] = useState(2);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [importFile, setImportFile] = useState<any | null>(null);

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [filterIsActive, setFilterIsActive] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  
  const [showAlert2, setShowAlert2] = useState(false);
  const [alertProps2, setAlertProps2] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const filteredItems = ProductState.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.code && item.code.toLowerCase().includes(filterText.toLowerCase()))
  );

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("");
    setFilterInventorySKU("");
    setFilterProductType(null);
    setFilterTempProductType(2);
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterIsActive(true);

    dispatch(
      getListWithPaging({
        name: "",
        inventorySKU: "",
        productType: null,
        startDate: "",
        endDate: "",
        isActive: true,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        name: filterName,
        inventorySKU: filterInventorySKU,
        productType: filterProdutcType,
        startDate: filterStartDate,
        endDate: filterEndDate,
        isActive : filterIsActive,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [ProductState]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  useEffect(() => {
    if(productStatusState === "success-import-data"){
      setAlertProps2({
        variant: "success",
        title: "Berhasil meng-import Data Produk",
        content: "",
      });
      setShowAlert2(true);
      fetchData(0, perPage);
      
    } else  if(productStatusState === "failed-import-data") {
      setAlertProps2({
        variant: "failed",
        title: "Gagal meng-import file.",
        content: "",
      });
      setShowAlert2(true);
    } else if (productStatusState === "success-delete-product"){
      setAlertProps2({
        variant: "success",
        title: "Berhasil non-aktifkan produk.",
        content: "",
      });
      setShowAlert2(true);
      fetchData(0, perPage);
    }


    setShowModal(false);
    dispatch(resetStatus());
  }, [productStatusState])
  

  const columns = [
    {
      name: "SKU",
      selector: "inventorySKU",
      sortable: true,
    },
    {
      name: "PRODUCT NAME",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "PRODUCT ID",
    //   selector: "id",
    //   sortable: true,
    // },
    {
      name: "CATEGORY NAME",
      selector: "productCategoryName",
      sortable: true,
    },

    {
      name: "PRODUCT TYPE",
      selector: (d: { productType: number }) => (
        <p style={{ margin: 10 }}>
          {d.productType === 0 && <span>Ready</span>}
          {d.productType === 1 && <span>Pre Order</span>}
        </p>
      ),
      sortable: true,
    },

    {
      name: "STOCKIST",
      selector: (d: { isPublishStockist: boolean }) => (
        <p style={{ margin: 10 }}>
           { d.isPublishStockist === false && (
            <span className={"custom-badge-danger"}>NO</span>
          )}
          {d.isPublishStockist === true && (
            <span className={"custom-badge-success"}>YES</span>
          )}
         
        </p>
      ),
      sortable: true,
    },

    {
      name: "RESELLER",
      selector: (d: { isPublishReseller: boolean }) => (
        <p style={{ margin: 10 }}>
           {d.isPublishReseller === false && (
            <span className={"custom-badge-danger"}>NO</span>
          )}
          {d.isPublishReseller === true && (
            <span className={"custom-badge-success"}>YES</span>
          )}
         
        </p>
      ),
      sortable: true,
    },

    {
      name: "END CUST.",
      selector: (d: { isPublishEndCustomer: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isPublishEndCustomer === false && (
            <span className={"custom-badge-danger"}>NO</span>
          )}
          {d.isPublishEndCustomer === true && (
            <span className={"custom-badge-success"}>YES</span>
          )}
          
        </p>
      ),
      sortable: true,
    },
    {
      name: "STOCK",
      selector: (d: { stockStatusInventory: number }) => (
        <p style={{ margin: 10 }}>
          {d.stockStatusInventory === 0 && <span>Instock</span>}
          {d.stockStatusInventory === 1 && <span>Out Of Stock</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "CREATE DATE",
      selector: (d: { createdDate: string }) => (
        <span>
          {d.createdDate !== undefined && d.createdDate.substr(0, 10)}
        </span>
      ),
      sortable: true,
    },

    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
           { d.isActive === false && (
            <span className={"custom-badge-danger"}>INACTIVE</span>
          )}
          {d.isActive === true && (
            <span className={"custom-badge-success"}>ACTIVE</span>
          )}
         
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-product/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  const onDelete = () => {
    dispatch(deleteData(selectedId));
    setShowAlert(false);
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onAlertClose2 = () => {
    setShowAlert2(false);
  };

  const handleFile = (files: any) => {
    setImportFile(files[0]);
    console.log(files[0]);
  };

  const onConfirmImportData = () => {
    dispatch(importDataProduct(importFile));
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Product</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/product/create",
            }}
          >
            <Button className="float-right" variant="info">
              + Add Data Product
            </Button>
          </Link>

          <Button
            className="float-right"
            variant="outline-info"
            style={{ marginRight: 10 }}
            onClick={() => {
              setShowModal(true);
            }}
          >
            Import Data Produk
          </Button>

          {"    "}
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            {/* <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col> */}

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Product Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Type
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterTempProductType}
                    onChange={(e) => {
                      if (e.target.value.toString() === "2") {
                        setFilterProductType(null);
                        setFilterTempProductType(parseInt(e.target.value));
                      } else {
                        setFilterTempProductType(parseInt(e.target.value));
                        setFilterProductType(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={2}>All</option>
                    <option value={0}>Ready</option>
                    <option value={1}>Pre Order</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  SKU
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterInventorySKU}
                    onChange={(e) => {
                      setFilterInventorySKU(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>

          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterIsActive === true ? 0 : 1}
                    onChange={(e) => {
                      if (e.target.value.toString() === "0") {
                        setFilterIsActive(true)
                        // setFilterProductType(null);
                        // setFilterTempProductType(parseInt(e.target.value));
                      } else {
                        setFilterIsActive(false)
                        // setFilterTempProductType(parseInt(e.target.value));
                        // setFilterProductType(parseInt(e.target.value));
                      }
                    }}
                  >
                   
                    <option value={0}>Active</option>
                    <option value={1}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              </Col>
           </Row> 

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>
          <hr />

          <DataTable
            columns={columns}
            data={ProductState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Upload CSV file</div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="input-padding">
            <Dropzone onDrop={(acceptedFiles) => handleFile(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone file-upload-frame w-100">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="text-center file-upload-label">
                      <div>
                        {importFile === null ? (
                          <span style={{ color: "black" }}>Select File</span>
                        ) : (
                          <>
                            <span style={{ color: "blue" }}>
                              <b>{importFile.name.toString()}</b>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </Form.Group>

          <div className="btn-footer-group">
            <Row>
              <Col className="text-center">
                <Button
                  variant="outline-info"
                  style={{ width: 140, height: 45 }}
                  onClick={() => {
                    setShowModal(false);
                    setImportFile(null);
                  }}
                >
                  Cancel
                </Button>
              </Col>

              {/* {importFile !== null ?? ( */}
                <Col className="text-center">
                  <Button
                    variant="info"
                    style={{ width: 140, height: 45 }}
                    onClick={() => onConfirmImportData()}
                  >
                    Import
                  </Button>
                </Col>
              {/* )} */}
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <AlertMessage
        {...alertProps2}
        show={showAlert2}
        onAlertCloseAction={onAlertClose2}
      ></AlertMessage>
      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default ProductTable;
