import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../../..";
import Api from "./keepPerProduct.api";
import { KeepByProductModel, KeepProductFilterModel, KeepProductModel } from "./keepPerProduct.model";

export interface KeepByProductSlice {
  name?: string;
  list?: KeepByProductModel[];
  keepPerProductList? : KeepProductModel[];
  totalRowKeepProduct? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getListKeepByProduct = createAsyncThunk(
  "KeepByProductState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getListKeepByProduct();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getKeepPerProduct = createAsyncThunk(
  "getKeepPerProductState/getKeepPerProduct",
  async (model : KeepProductFilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListKeepPerProductWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const KeepByProductSlice = createSlice({
  name: "KeepByProductState",
  initialState: {} as KeepByProductSlice,
  reducers: {
    resetStatusKeepByProduct: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListKeepByProduct.pending, (state) => {
      state.list = undefined;
      state.isLoading = true;
    });
    builder.addCase(getListKeepByProduct.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getListKeepByProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getKeepPerProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getKeepPerProduct.fulfilled, (state, { payload }) => {
      state.keepPerProductList = payload.data.list;
      state.totalRowKeepProduct = payload.data.total;
      state.isLoading = false;
    });
    builder.addCase(getKeepPerProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { resetStatusKeepByProduct } = KeepByProductSlice.actions;
export default KeepByProductSlice.reducer;
