import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { variationModel } from "../../master/product/product.model";
import paymentAllocationReducer from "../../payment/paymentAllocation/paymentAllocation.reducer";
import { UnpaidDropshipModel } from "../pesananDalamProses/droshipBelumLunas/dropship.model";
import Api from "./salesOrder.api";
import { AddressModel, CheckOutAdminRequestModel, ComplainDetailItem, CreateSalesOrderAdminModel, CreateSalesOrderModel, ExpeditionServiceCostModel, GetVariationByProductAndUserRequest, PartnerModel, PaymentMethodModel, ReturModel, SalesOrderModel, UnpaidDropshipFilterModel, UploadImageModel } from "./salesOrder.model";
import {RootState} from "../../../app/store";

export interface SalesOrderSlice {
  name?: string;
  list?: SalesOrderModel[];
  single?: SalesOrderModel;
  partnerList?: PartnerModel[];
  paymentMethodList? : PaymentMethodModel[];
  listVariationByProduct?: variationModel[];
  addressList? : AddressModel[];
  expeditionList? : ExpeditionServiceCostModel[];
  unpaidDroposhipList? : UnpaidDropshipModel[];
  returData? : ReturModel;
  productFromComplaint? : ComplainDetailItem[];
  totalRowUnpaidDropship? : number;
  barcodeImagePath? : string;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const createDataSalesOrder = createAsyncThunk(
  "SalesOrderState/createSalesOrder",
  async (Model: CreateSalesOrderModel, { rejectWithValue }) => {
    try {
      const response = await Api.createData(Model.salesOrder);
      if (response.status === true) {
        //post shipment
        let shipmentData = Object.assign({}, Model.shipment);
        shipmentData.salesOrderId = response.data.id;
        await Api.createShipment(shipmentData);

        //post sales order detail
        let salesOrderDetailListData = Model.salesOrderDetail;
        salesOrderDetailListData.map(async (item) => {
          item.salesOrderId = response.data.id;
          await Api.createSalesOrderDetail(item);
        });
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);


export const getUnpaidDropship = createAsyncThunk(
  "getUnpaidDropshipState/getUnpaidDropship",
  async (model : UnpaidDropshipFilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListUnpaidDropship(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createSalesOrderAdmin = createAsyncThunk(
  "createSalesOrderAdminState/createSalesOrderAdmin",
  async (model: CreateSalesOrderAdminModel, { rejectWithValue }) => {
    try {
      return await Api.CreateSalesOrderAdmin(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "SalesOrderState/getSalesOrderList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationByProduct = createAsyncThunk(
  "SalesOrderState/getVariationByproductList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getListVariationByProduct(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListVariationByProductAndUser = createAsyncThunk(
  "SalesOrderState/getVariationListByproductAndUser",
  async (model : GetVariationByProductAndUserRequest, { rejectWithValue }) => {
    try {
      return await Api.getListVariationByProductAndUser(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListPartner = createAsyncThunk(
  "GetPartnerListState/GetPartnerList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getListPartner();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAddressListByPartner = createAsyncThunk(
  "GetAddressListByPartnerState/GetAddressListByPartner",
  async (id: string | null, { rejectWithValue }) => {
    try {
      return await Api.getAddressListByPartner(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);


export const getListPaymentMethod = createAsyncThunk(
  "getListPaymentMethodState/getListPaymentMethod",
  async (type: number , { rejectWithValue }) => {
    try {
      return await Api.getListPaymentMethodByType(type);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "SalesOrderState/SalesOrderSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "SalesOrderState/updateSalesOrder",
  async (Model: SalesOrderModel, { rejectWithValue }) => {
    try {
      return await Api.updateData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const checkCostAdmin = createAsyncThunk(
  "CheckCostAdminState/CheckCostAdmin",
  async (Model: CheckOutAdminRequestModel, { rejectWithValue }) => {
    try {
      return await Api.checkCostAdmin(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "SalesOrderState/deleteSalesOrder",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "SalesOrderState/cancelSalesOrder",
  async (id: string, { getState, rejectWithValue }) => {
    try {
      const { id: adminId } = (getState() as RootState).user;
      return await Api.cancelOrder(id, adminId as string);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getReturData = createAsyncThunk(
  "SalesOrderState/getReturData",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getReturById(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProductListFromComplaint = createAsyncThunk(
  "SalesOrderState/productListFromComplaint",
  async (soNumber: string, { rejectWithValue }) => {
    try {
      return await Api.getComplaintDetailItemBySONumber(soNumber);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const uploadBarcodeImage = createAsyncThunk(
  'SalesOrderState/uploadBarcodeImage',
  async (model: UploadImageModel, { rejectWithValue }) => {
      try {
          return await Api.uploadImage(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
)

const SalesOrderSlice = createSlice({
  name: "SalesOrderState",
  initialState: {} as SalesOrderSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as SalesOrderModel;
      state.barcodeImagePath = "";
    },
    resetStatus: (state) => {
      state.status = "";
    },
    resetBarcodeImagePath: (state) => {
      state.barcodeImagePath = "";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createDataSalesOrder.pending, (state) => {
      state.isLoading = true;
      state.status = "loading";
    });
    builder.addCase(createDataSalesOrder.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createDataSalesOrder.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(createSalesOrderAdmin.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createSalesOrderAdmin.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createSalesOrderAdmin.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListPartner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListPartner.fulfilled, (state, { payload }) => {
      state.partnerList = payload;
      state.isLoading = false;
    });
    builder.addCase(getListPartner.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListPaymentMethod.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListPaymentMethod.fulfilled, (state, { payload }) => {
      state.paymentMethodList = payload;
      state.isLoading = false;
    });
    builder.addCase(getListPaymentMethod.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getAddressListByPartner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAddressListByPartner.fulfilled, (state, { payload }) => {
      state.addressList = payload;
      state.isLoading = false;
    });
    builder.addCase(getAddressListByPartner.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListVariationByProduct.fulfilled, (state, { payload }) => {
      state.listVariationByProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    
    builder.addCase(getListVariationByProductAndUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListVariationByProductAndUser.fulfilled, (state, { payload }) => {
      state.listVariationByProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getListVariationByProductAndUser.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(cancelOrder.pending, (state) => {
      state.isLoading = true;
      state.modifiedDate = "";
    });
    builder.addCase(cancelOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-cancel"
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(cancelOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(checkCostAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkCostAdmin.fulfilled, (state, { payload }) => {
      state.expeditionList = payload.data;
      state.isLoading = false;
    });
    builder.addCase(checkCostAdmin.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getUnpaidDropship.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUnpaidDropship.fulfilled, (state, { payload }) => {
      state.unpaidDroposhipList = payload.data.list;
      state.totalRowUnpaidDropship = payload.data.total;
      state.isLoading = false;
    });
    builder.addCase(getUnpaidDropship.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getReturData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReturData.fulfilled, (state, { payload }) => {
      state.returData = payload;
      state.isLoading = false;
    });
    builder.addCase(getReturData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getProductListFromComplaint.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductListFromComplaint.fulfilled, (state, { payload }) => {
      state.productFromComplaint = payload;
      state.isLoading = false;
    });
    builder.addCase(getProductListFromComplaint.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(uploadBarcodeImage.pending, (state) => {
      state.isLoading = true;
  })
  builder.addCase(uploadBarcodeImage.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.barcodeImagePath = payload.data.imagepath;
  })
  builder.addCase(uploadBarcodeImage.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
  })
  },
});

export const { setFilter, resetSingle, resetStatus, resetBarcodeImagePath } = SalesOrderSlice.actions;
export default SalesOrderSlice.reducer;
