import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Container } from "react-bootstrap";
import uploadImage from '../../../../assets/vector/upload.png'
import { Link, useParams, useHistory } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getSlideShowDetail, postImageUpload, postSlideShow, updateSlideShow } from '../slideShow.api';
import Swal from 'sweetalert2';
import { convertBase64 } from '../../../../components/Library/Base64';
import {endpoint} from '../../../../constants'
import { RootState } from '../../../../app/store';

export default function FormSlide(props: any) {
    const history = useHistory();
    const { id } = useParams<any>();
    const isEdit = id
    const dispatch = useDispatch()
    const {loading} = useSelector((state:RootState) => state.slideShow)

    const [values, setValues] = useState<any>({
        "title": "",
        "link": "",
        "image": ""
    })


    useEffect(() => {
        if (isEdit) {
            dispatch<any>(getSlideShowDetail(isEdit))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                setValues({
                    ...values,
                    ...res
                })
            })
        }
    }, [])
    
    const handleChange = (e:any)=>{
        setValues({
            ...values,
            [e.target.name] : e.target.value
        })
    }

    const handleImage = (e:any)=>{
        convertBase64(e.target.files[0], (result: any) => {
            const imageData = result.split(',')
            const base64Final = imageData[1]
            const data = {
                "file_name": e.target.files[0].name,
                "base64url": base64Final,
                "imagepath": "",
                "location": ""
            }
            dispatch<any>(postImageUpload(data))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status === 200) {
                    setValues({
                        ...values,
                        "image" : endpoint+res.data.imagepath
                    })
                    Swal.fire({
                        icon: 'success',
                        text: 'Image uploaded',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'Error uploading image!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Error uploading image!',
                })
            })
        });
    }

    const handleSubmit = (e:any)=>{
        e.preventDefault()
        if (values.image.length === 0) {
            return Swal.fire({
                icon: 'warning',
                text: 'Image is required!',
            })
        }
        if (isEdit) {
            dispatch<any>(updateSlideShow(values))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Slide show updated',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    .then(()=>{
                        history.push('/setting-slide')
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'Slide show cannot updated!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Slide show cannot cannot updated!',
                })
            })
        }else{
            dispatch<any>(postSlideShow(values))
            .then(unwrapResult)
            .then((res:any)=>{
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Slide show saved',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    .then(()=>{
                        history.push('/setting-slide')
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: 'Slide show cannot saved!',
                    })
                }
            }).catch((err:any)=>{
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Slide show cannot cannot saved!',
                })
            })
        }
    }
    return (
        <Container className="content-body formSlide" fluid>
            <div className="page-title mb-4 title-link">
                <Link to='/setting-slide'>
               <FiChevronLeft /> 
               </Link>
               {isEdit? 'Edit':'Add'} Slide Show
            </div>
            <div className="box">
                <div className="titbox">{isEdit? 'Edit':'Add'} Slide Show</div>
                <form onSubmit={handleSubmit}>
                    <Row className='forms'>
                        <Col md={3}><p>Title</p> </Col>
                        <Col md={9}>
                        <input type="text" name="title" required onChange={handleChange} value={values.title} placeholder='Title' />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Image</p> </Col>
                        <Col md={9}>
                            <input type="file" name="" style={{display : 'none'}} id="bgImage" onChange={handleImage} />
                            {
                                loading ?
                                'Loading...'
                                :
                                <label htmlFor='bgImage' className="uploadfilebox overflow-hidden">
                                    {
                                        values.image.length !== 0 ?
                                        <img src={values.image} alt="upload" className='img-fluid' />
                                        :
                                        <>
                                        <img src={uploadImage} alt="upload" />
                                        Select File
                                        </>
                                    }
                                </label>
                            }
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Link</p> </Col>
                        <Col md={9}>
                        <input type="text" placeholder='Link' required  name="link" value={values.link} onChange={handleChange} />
                        </Col>
                    </Row>
                    <div className="wrap-buttons">
                        <Button type='button' onClick={()=> history.push('/setting-slide')}>Cancel</Button>
                        <Button type='submit'>Save</Button>
                    </div>
                </form>
            </div>
        </Container>
    )
}
