import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import RoleTable from "../components/RoleTable";
import { getList, resetMenuAccessList } from "../role.reducer";

const RoleTablePage = () => {
  const dispatch = useDispatch();
  const State = useSelector((state: RootState) => state.UOM.name);

  useEffect(() => {
    dispatch(getList());
    dispatch(resetMenuAccessList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <RoleTable />
        </Col>
      </Row>
    </Container>
  );
};

export default RoleTablePage;
