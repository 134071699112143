import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { endpoint } from "../../../../constants";
import { ShipmentModel } from "../../pesananDalamProses/model";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

const DataShipment = ({ id = "", ...props }: DataSalesOrderDetailProps) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState<ShipmentModel[]>([]);
  const [selectedId, setSelectedId] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const columns = [
    {
      name: "Nomor Resi",
      selector: "resiNumber",
      sortable: true,
    },
    {
      name: "Nama Pengirim",
      selector: "senderName",
      sortable: true,
    },
    {
      name: "Nama Penerima",
      selector: "recipientName",
      sortable: true,
    },
    {
      name: "Alamat Penerima",
      selector: "recipientAddress",
      sortable: true,
    },
  ];
  // end of datatable components

  useEffect(() => {
    axios
      .get<any>(endpoint + "/api/Shipment/GetSingleBySalesOrder?salesOrderId=" + id)
      .then((res) => {
        let list = [];
        list.push(res.data.data);
        setList(list);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid textAlign="center">
        <Card>
          <Card.Body>
            <h5>Data Shipping</h5>
            <hr />
            <DataTable
              columns={columns}
              data={list}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              noHeader
              customStyles={TableCustomStyles}
            />
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export interface DataSalesOrderDetailProps {
  id: string;
}

export default DataShipment;
