import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../../app/store";
import { deleteData, getList, getListParentForDropDown, getListWithPaging } from "../productCategory.reducer";
import { message } from "antd";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../../constants";
import noImage from "../../../../assets/no-image.png";
import DeleteMessage from "../../../../components/DeleteMessage";

const ProductCategoryTable = () => {
  const dispatch = useDispatch();
  const ProductCategoryState = useSelector((state: RootState) => state.productCategory.list ?? []);
  const totalRowState = useSelector((state: RootState) => state.productCategory.totalRow ?? 0);
  const ProductCategoryParentState = useSelector((state: RootState) => state.productCategory.parentList ?? []);


  const status = useSelector((state: RootState) => state.productCategory.status ?? []);
  const error = useSelector((state: RootState) => state.productCategory.error ?? []);

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [isActive, setIsactive] = useState("true");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filterName, setFilterName] = useState("")
  const [filterParentId, setFilterParentId] = useState<string|null>(null)
  const [filterTempParentId, setFilterTempParentId] = useState("")
  const [filterIsActive, setFilterIsActive] = useState<boolean|null>(null)
  const [filterTempIsActive, setFilterTempIsActive] = useState(2)

  const [selectedId, setSelectedId] = useState("")

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const filteredItems = ProductCategoryState.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.slug && item.slug.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.parent && item.parent.toLowerCase().includes(filterText.toLowerCase()))
  );

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onParentIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === ""){
      setFilterParentId(null)
      setFilterTempParentId("")
    }else{
      setFilterParentId(e.target.value)
      setFilterTempParentId(e.target.value)
    }
  };

  const onIsActiveChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "0") {
      setFilterIsActive(true);
      setFilterTempIsActive(0);
    } else if (e.target.value === "1") {
      setFilterIsActive(false);
      setFilterTempIsActive(1);
    } else {
      setFilterIsActive(null);
      setFilterTempIsActive(2);
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("")
    setFilterParentId(null)
    setFilterTempParentId("")
    setFilterIsActive(null)
    setFilterTempIsActive(2)

    dispatch(
      getListWithPaging({
        name: "",
        parent: null,
        isActive: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        name: filterName,
        parent: filterParentId,
        isActive: filterIsActive,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [ProductCategoryState]);

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getListParentForDropDown())
  }, []);

  const columns = [
    // {
    //   name: "IMAGE",
    //   selector: (d: { image: string }) => (
    //     <p style={{ margin: 5 }}>
    //       {d.image !== "" ? (
    //         <img
    //           src={d.image !== "" ? endpoint + d.image : "none"}
    //           style={{ width: 50 }}
    //         />
    //       ) : (
    //         <img src={noImage} style={{ width: 50 }} />
    //       )}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "NAME",
      selector: "name",
      sortable: true,
    },
    {
      name: "SLUG",
      selector: "slug",
      sortable: true,
    },
    {
      name: "PARENT",
      selector: "parentName",
      sortable: true,
    },
    {
      name: "DESCTIPTION",
      selector: "description",
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && <span className={"custom-badge-success"}>Active</span>}
          {d.isActive === false && <span className={"custom-badge-secondary"}>Inactive</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any; name: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-product-category/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
            
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (status === "failed") {
      let arr = JSON.stringify(error);
      let parse = JSON.parse(arr);
      let pesan = parse.data.message.toString();
      message.error(pesan);
    }
    console.log(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // useEffect(() => {
  //   if (isActive === "true") {
  //     dispatch(getList(true));
  //   } else {
  //     dispatch(getList(false));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isActive]);

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }

    // if (isActive === "true") {
    //   dispatch(getList(true));
    // } else {
    //   dispatch(getList(false));
    // }

    fetchData(0,perPage)
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
      setShowAlert(false);
      fetchData(0,perPage)
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Product Category</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/product-category/create",
            }}
          >
            <Button className="float-right" variant="info">
              + Add Product Category
            </Button>
          </Link>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Parent
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onParentIdChange}
                    value={filterTempParentId}
                  >
                    <option value="">Select</option>
                    {ProductCategoryParentState.map((Item) => {
                      return (
                        <option value={Item.value}>
                          {Item.title}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onIsActiveChange}
                    value={filterTempIsActive}
                  >
                    <option value={2}>All</option>
                    <option value={0}>Active</option>
                    <option value={1}>Inactive</option>
                                       
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

        <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Group className="padding-filter ">
                <Col md={4}>
                  <Form.Control
                    as="select"
                    style={{ height: 35 }}
                    onChange={(e) => {
                      setIsactive(e.target.value);
                    }}
                  >
                    <option value={"true"}>Active</option>
                    <option value={"false"}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text style={{ backgroundColor: "#fff", color: "#F5B95D", border: 0 }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row> */}

<hr />

          <DataTable
            columns={columns}
            data={ProductCategoryState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
{/* 
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default ProductCategoryTable;
