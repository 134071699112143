import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import ProductCategoryTable from '../components/ProductCategoryTable';
import { getList } from '../productCategory.reducer';

const ProductCategoryTablePage = () => {
  const dispatch = useDispatch();
  const State = useSelector(
    (state: RootState) => state.productCategory.name
  );
  const listDataState = useSelector(
    (state: RootState) => state.productCategory.list
  );

  const dateModifiedState = useSelector(
    (state: RootState) => state.productCategory.dateModified ?? ""
  );

  useEffect(() => {
    // dispatch(getList(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateModifiedState]);

  return (
   <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <ProductCategoryTable />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductCategoryTablePage;
