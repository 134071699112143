import { Card, Form, Row, Col, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { ChangeEvent, useEffect, useState } from "react";
import AlertMessage from "../../../../components/alertMessage";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { CityModel } from "../city.model";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import {
  deleteData,
  getList,
  getProvince,
  getSingleItem,
  resetStatus,
  updateData,
} from "../city.reducer";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const CityTable = ({ data = {} as CityModel, isEdit = false, ...props }: CityFormProps) => {
  const dispatch = useDispatch();

  const CityState = useSelector((state: RootState) => state.city.list ?? []);

  const provinceState = useSelector((state: RootState) => state.city.listProvince ?? []);

  const CitySingleData = useSelector((state: RootState) => state.city.single ?? ({} as CityModel));

  const statusState = useSelector((state: RootState) => state.city.status ?? "");

  useEffect(() => {
    dispatch(getProvince());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [provinceId, setProvince] = useState("");
  const [no, setNo] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onCloseAlert = () => setShowAlert(false);
  const handleShow = () => {
    setShow(true);
    setProvince("");
    setName("");
  };
  const handleClose = () => {
    setShow(false);
  };
  const onAlertClose = () => setShowAlert(false);

  const filteredItems = CityState.filter(
    (item) =>
      (item.provinceName && item.provinceName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
  );

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setName(data.name);
      setProvince(data.provinceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = [
    {
      name: "PROVINCE",
      selector: "provinceName",
      sortable: true,
    },
    {
      name: "CITY",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "ACTION",
    //   selector: (d: { id: any }) => (
    //     <>
    //       <span
    //         onClick={() => {
    //           setShow(true);
    //           onClickEditbutton(d.id);
    //         }}
    //       >
    //         <img src={edit}></img>
    //       </span>
    //       {"    "}
    //       <span
    //         onClick={() => {
    //           if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
    //         }}
    //       >
    //         <img src={hapus}></img>
    //       </span>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusState === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "City digunakan di menu lain",
      });
      setShowAlert(true);
    }
    return () => {
      dispatch(resetStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  const onEdit = () => {
    dispatch(
      updateData({
        id: CitySingleData.id,
        name: name,
        no: no,
        provinceId: provinceId,
        isActive,
      } as CityModel)
    );
  };

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  const onClickEditbutton = (id: string) => {
    dispatch(getSingleItem(id));
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master City</Form.Label>
          </Form.Group>
        </Col>
        {/* <Col>
          <Button
            className="float-right"
            variant="info"
            onClick={handleShow}
            style={{ width: 150 }}
          >
            + Add City
          </Button>
        </Col> */}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text style={{ backgroundColor: "#fff", color: "#F5B95D", border: 0 }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add City</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                id="cityForm"
                onSubmit={(e) => {
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                    e.preventDefault();
                    if (CitySingleData.id === undefined) {
                      props.onSubmit(no, name, provinceId, isActive);
                      setName("");
                      setProvince("");
                    } else {
                      onEdit();
                      setName("");
                      setProvince("");
                    }
                  } else {
                    e.preventDefault();
                    if (CitySingleData.id === undefined) {
                      props.onSubmit(no, name, provinceId, isActive);
                      setName("");
                      setProvince("");
                    } else {
                      onEdit();
                      setName("");
                      setProvince("");
                    }
                  }
                  setValidated(true);
                }}
              >
                <Form.Group className="input-padding">
                  <Form.Label>Province</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => setProvince(e.target.value)}
                    value={provinceId}
                  >
                    <option>select</option>
                    {provinceState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>City Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                      console.log(name);
                    }}
                    value={name}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-info" style={{ width: 100 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                form="cityForm"
                variant="info"
                type="submit"
                style={{ width: 100 }}
                onClick={handleClose}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </Grid>

      <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
    </div>
  );
};

export interface CityFormProps {
  onSubmit: (no: number, name: string, provinceId: string, isActive: boolean) => void;
  data: CityModel;
  isEdit: boolean;
}

export default CityTable;
