import axios from "axios";
import { endpoint } from "../../../constants";
import {
  FilterModel,
  ImageGalleryModel,
  ListOfCustomProductAttribute,
  ProductCrossSellModel,
  ProductGroupingModel,
  ProductModel,
  ProductPublishModel,
  ProductUpSellModel, UpdateIndexModel,
  UploadImageModel,
  variationModel,
} from "./product.model";

export default class ProductApi {
  static createData(model: ProductModel) {
    return axios
      .post<any>(endpoint + "/api/Product/Create", model)
      .then((res) => res.data);
  }

  static getList() {
    return axios
      .get<any>(endpoint + "/api/product/GetList")
      .then((res) => res.data.data);
  }

  static getProductListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/product/MasterProductGetListWithPaging", model)
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/product/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static getProductGroupingPerProduct(id: string) {
    return axios
      .get<any>(endpoint + "/api/product/GetListAllProductGrouping?productId=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: ProductModel) {
    return axios
      .post<any>(endpoint + "/api/product/update", model)
      .then((res) => res.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/product/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static getWarehouseList() {
    return axios
      .get<any>(endpoint + "/api/Warehouse/GetList")
      .then((res) => res.data.data);
  }

  static getListCollectionTag() {
    return axios
      .get<any>(endpoint + "/api/ProductCollectionTag/GetList")
      .then((res) => res.data.data);
  }

  static getListProductCategories() {
    return axios
      .get<any>(endpoint + "/api/ProductCategory/GetListForDropDown2")
      .then((res) => res.data.data);
  }

  static getListAttribute() {
    return axios
      .get<any>(endpoint + "/api/ProductAttribute/GetList?isActive=true")
      .then((res) => res.data.data);
  }

  static createProductPublishData(model: ProductPublishModel) {
    return axios
      .post<any>(endpoint + "/api/ProductPublish/Create", model)
      .then((res) => res.data.data);
  }

  static uploadImage(model: UploadImageModel) {
    return axios
      .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
      .then((res) => res.data);
  }

  static uploadMultipleImage(model: UploadImageModel[]) {
    return axios
      .post<any>(endpoint + "/web/api/Upload/UploadMultiImage", model)
      .then((res) => res.data);
  }

  static createCrossSell(model: ProductCrossSellModel) {
    return axios
      .post<any>(endpoint + "/api/LinkProductCrossSell/Create", model)
      .then((res) => res.data.data);
  }

  static createUpSell(model: ProductUpSellModel) {
    return axios
      .post<any>(endpoint + "/api/LinkProductUpSell/Create", model)
      .then((res) => res.data.data);
  }

  static createGrouping(model: ProductGroupingModel) {
    return axios
      .post<any>(endpoint + "/api/LinkProductGrouping/Create", model)
      .then((res) => res.data.data);
  }

  static createImageGallery(model: ImageGalleryModel) {
    return axios
      .post<any>(endpoint + "/api/ProductGallery/Create", model)
      .then((res) => res.data.data);
  }

  static getSingleCrossSell(id: string) {
    return axios
      .get<any>(endpoint + "/api/product/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static getSingleUpSell(id: string) {
    return axios
      .get<any>(endpoint + "/api/product/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static getSingleGroup(id: string) {
    return axios
      .get<any>(endpoint + "/api/LinkProductGrouping/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static getSingleImageGallery(id: string) {
    return axios
      .get<any>(
        endpoint + "/api/ProductGallery/GetListByProduct?productId=" + id
      )
      .then((res) => res.data.data);
  }

  static createVariation(model: variationModel[]) {
    return axios
      .post<any>(endpoint + "/api/Variation/Create", model)
      .then((res) => res.data.data);
  }

  static updateVariation(model: variationModel[]) {
    return axios
      .post<any>(endpoint + "/api/Variation/Update", model)
      .then((res) => res.data.data);
  }

  static createSingleVariation(model: variationModel) {
    return axios
      .post<any>(endpoint + "/api/Variation/CreateSingle", model)
      .then((res) => res.data.data);
  }

  //combination for variation
  static generateCustomProductAttribute(model: ListOfCustomProductAttribute[]) {
    return axios
      .post<any>(
        endpoint + "/api/CustomProductAttribute/GenerateCustomProductAttribute",
        model
      )
      .then((res) => res.data.data);
  }

  static getConfigurationListByProductAttribute(id: string) {
    return axios
      .get<any>(endpoint + "/api/ConfigurationAttribute/GetListByProductAttribute?productAttributeId=" + id)
      .then((res) => res.data.data);
  }


  static importDataProduct(file:any) {

    // const FormData = global.FormData;
    const formData = new FormData();
    formData.append("file",file);

    return axios
      .post<any>(
        endpoint + "/web/api/Upload/ImportDataProductCSV",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((res) => res.data.data);
  }

  static updateIndex(args: UpdateIndexModel) {
    return axios
        .post<any>(endpoint + "/api/Variation/UpdateIndex", args)
        .then((res) => res.data);
  }
}
