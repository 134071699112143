import { createSlice } from '@reduxjs/toolkit'
import { getMembershipTransDetail, postChangeStatusMembership, postDeleteMembership, postExportMembershipTransaction, postMembershipWithPaging } from './membership.api'


export interface MembershipState {
    loading: boolean,
    totalPage: any
}
const initialState: MembershipState = {
    loading: false,
    totalPage: null
}

export const membershipSlice = createSlice({
    name: 'membership',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers: (builder) => {
        // post list
        builder.addCase(postMembershipWithPaging.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(postMembershipWithPaging.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postMembershipWithPaging.rejected, (state, action) => {
            state.loading = false
        })
        //export excel
        builder.addCase(postExportMembershipTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(postExportMembershipTransaction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postExportMembershipTransaction.rejected, (state, action) => {
            state.loading = false
        })
        //delete
        builder.addCase(postDeleteMembership.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(postDeleteMembership.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postDeleteMembership.rejected, (state, action) => {
            state.loading = false
        })
        //change stat
        builder.addCase(postChangeStatusMembership.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(postChangeStatusMembership.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postChangeStatusMembership.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default membershipSlice.reducer