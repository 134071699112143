import { createSlice } from '@reduxjs/toolkit'
import { postDataPesanan, postExportOrderData } from './orderDataReport.api'
// import { getMembership } from './orderDataReport'


export interface OrderDataReportState {
  loading: boolean,
  list: any,
  total: number
}
const initialState: OrderDataReportState = {
  loading: false,
  list: [],
  total: 0
}

export const orderDataReportSlice = createSlice({
  name: 'order data report',
  initialState,
  reducers: {
    // increment: (state) => {
    // state.value += 1
  },
  extraReducers: (builder) => {
    // post data filter
    builder.addCase(postDataPesanan.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(postDataPesanan.fulfilled, (state, { payload }) => {
      // console.log(payload)
      // const { list, total } = payload
      // state.list = list
      // state.total = total
      state.loading = false
    })
    builder.addCase(postDataPesanan.rejected, (state, action) => {
      state.loading = false
    })
    //post
    builder.addCase(postExportOrderData.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(postExportOrderData.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(postExportOrderData.rejected, (state, action) => {
      state.loading = false
    })
    // //post
    // builder.addCase(postImageUpload.pending, (state, action) => {
    //   state.loading = true
    // })
    // builder.addCase(postImageUpload.fulfilled, (state, action) => {
    //     state.loading = false
    // })
    // builder.addCase(postImageUpload.rejected, (state, action) => {
    //     state.loading = false
    // })
    // //delete
    // builder.addCase(deleteSlideShow.pending, (state, action) => {
    //   state.loading = true
    // })
    // builder.addCase(deleteSlideShow.fulfilled, (state, action) => {
    //     state.loading = false
    // })
    // builder.addCase(deleteSlideShow.rejected, (state, action) => {
    //     state.loading = false
    // })
    // //detail
    // builder.addCase(getSlideShowDetail.pending, (state, action) => {
    //     state.loading = true
    // })
    // builder.addCase(getSlideShowDetail.fulfilled, (state, action) => {
    //     state.loading = false
    // })
    // builder.addCase(getSlideShowDetail.rejected, (state, action) => {
    //     state.loading = false
    // })
    // //update
    // builder.addCase(updateSlideShow.pending, (state, action) => {
    //     state.loading = true
    // })
    // builder.addCase(updateSlideShow.fulfilled, (state, action) => {
    //     state.loading = false
    // })
    // builder.addCase(updateSlideShow.rejected, (state, action) => {
    //     state.loading = false
    // })
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default orderDataReportSlice.reducer