import {
  AccordionCollapse,
  Button,
  Card,
  Col,
  Form,
  Row,
  Modal, useAccordionToggle, Toast,
} from "react-bootstrap";
// import { Select } from "antd";
import Select from "react-select";

import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import upload from "./../../../../../assets/vector/upload.png";
import { RootState } from "../../../../../app/store";
import {
  createSingleVariant, resetIndexStatus,
  resetStatus,
  setVariableProductAttributeTempForm,
  setVariableProductVariationTempForm, updateIndex,
} from "../../product.reducer";
import axios from "axios";
import { endpoint } from "../../../../../constants";
import Dropzone from "react-dropzone";
import { convertBase64 } from "../../../../../components/Library/Base64";
import NumberFormat from "react-number-format";
import {
  CustomProductAttibuteModel,
  ProductModel, VariantUpdateIndexTypeEnum,
  variationModel,
  VariationWarehouseStock,
} from "../../product.model";
import { useParams } from "react-router-dom";
import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import DataTable from "react-data-table-component";
import { HexColorPicker } from "react-colorful";
import hapus from "./../../../../../assets/vector/delete.png";
import { getListWarehouse } from "../../../../../global/global.reducer";
import AlertMessage from "../../../../../components/alertMessage";
import Accordion from "react-bootstrap/Accordion";
import styled from "styled-components";
import {BiDownArrowCircle, BiUpArrowCircle} from "react-icons/bi";
import CircleLoader from "../../../../../components/Loading/CircleLoader";

export interface ConfigureTermsModel {
  id: string;
  name: string;
  slug: string;
  parent: string;
  description: string;
  productAttributeId: string;
}

export interface generatedModel {
  configurationAttributeId1: string;
  configurationAttributeId2: string;
  configurationAttributeId3: string;

  configurationAttributeName1: string;
  configurationAttributeName2: string;
  configurationAttributeName3: string;

  variationState: variationModel;
}

export interface inputConfigureTermsModel {
  [key: string]: any;
  // attributeId: string;
  isVisible: boolean;
  isForVariation: boolean;
  arrayOfConfigureTermsId: string[];
}

export interface AttributeOptionModel {
  id: string;
  label: string;
}

interface ListConfigurationTermModel {
  [key: string]: ConfigureTermsModel[];
}

const VariationVariableProductForm = ({
  inventorySKU = "",
  isEdit = false,
  data = {} as ProductModel,
  ...props
}: VariationProps) => {
  const dispatch = useDispatch();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  let { id } = useParams<{ id: string }>();

  const variationDataState = useSelector(
    (state: RootState) => state.product.listGenerate ?? []
  );

  const warehouseListState = useSelector(
    (state: RootState) => state.global.listWarehouse ?? []
  );

  const attributeState = useSelector(
    (state: RootState) => state.product.listAttribute ?? []
  );

  const statusState = useSelector(
    (state: RootState) => state.product.status ?? []
  );

  const isIndexLoading = useSelector(
      (state: RootState) => state.product.isIndexLoading
  );


  const SimpleProductShippingTempFormState = useSelector(
    (state: RootState) =>
      state.product.setSimpleProductShippingTempForm ?? ({} as ProductModel)
  );

  const [configureTermsDataSet, setConfigureTermsDataSet] = useState<
    generatedModel[]
  >([]);

  const [variationFormInputTemp, setVariationFormInputTemp] = useState<
    variationModel[]
  >([]);

  const [image, setImage] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [thumbnail, setThumbnail] = useState("");
  const [fileFormat, setFileFormat] = useState("");
  const [tempIndex, setTempIndex] = useState(0);

  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [variantWarehouse, setVariantWarehouse] = useState("");
  const [variantWarehouseName, setVariantWarehouseName] = useState("");
  const [variantStock, setVariantStock] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [color, setColor] = useState("#b32aa9");

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [isVarianDataDispatched, setIsVarianDataDispatched] = useState(true);

  const [tag, setTag] = useState<Array<string>>([]);
  const [productAttributeName, setAttributeName] = useState<Array<string>>([]);
  const [listOfConfigurationTerms, setListOfConfigurationTerms] =
    useState<ListConfigurationTermModel>({} as ListConfigurationTermModel);
  const [attributePropList, setAttributePropList] = useState<
    inputConfigureTermsModel[]
  >([]);
  const [isLoadingAttributeData, setisLoadingAttributeData] = useState(false);
  const [attributeOptionsData, setAttributeOptionsData] = useState<
    AttributeOptionModel[][]
  >([]);
  const [tempAttributeData, setTempAttributeData] = useState<
    AttributeOptionModel[]
  >([]);

  const [newVariantDataTemp, setNewVariantDataTemp] = useState<variationModel>(
    {} as variationModel
  );



  useEffect(() => {
    if (variationDataState.length !== 0) {
      setVariationFormInputTemp([]);
      setConfigureTermsDataSet(variationDataState);
      console.log("SET CONFIGURE TERM");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variationDataState]);

  useEffect(() => {
    dispatch(getListWarehouse());

    // if(isEdit){
    axios
      // .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
      .get<any>(
        endpoint + "/api/Variation/GetListByProductAdmin?productId=" + id
      )
      .then((res) => setVariationFormInputTemp(res.data.data));

    console.log("SET VARIATION FORM INPUT TEMP");
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let _listOfConfigurationTerms: ListConfigurationTermModel =
    {} as ListConfigurationTermModel;

  let _attributePropList: inputConfigureTermsModel[] = [];

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor === Object) {
      console.log(data);
      setisLoadingAttributeData(true);
      data.productAttributeWithDetail.map(
        async (attributeItem, attributeIndex) => {
          let configureTermUpdate = attributeItem.productConfiguration.map(
            (termItem) => {
              return termItem.id;
            }
          );

          _attributePropList.push({
            isVisible: true,
            isForVariation: true,
            arrayOfConfigureTermsId: configureTermUpdate,
          });

          const attributeResponse = await getListByProductAttributeApi(
            attributeItem.productAttributeId
          );

          if (attributeResponse.data.status) {
            _listOfConfigurationTerms["property" + attributeIndex] =
              attributeResponse.data.data;

            console.log(_listOfConfigurationTerms);
            setTempAttributeData([]);

            let temp = [] as AttributeOptionModel[];
            attributeResponse.data.data.map((item: { id: any; name: any }) => {
              // setTempAttributeData((prevState) => [
              //   ...prevState,
              //   {
              //     id: item.id,
              //     label: item.name,
              //   },
              // ]);
              temp.push({
                id: item.id,
                label: item.name,
              });
            });

            setAttributeOptionsData((prevState) => [...prevState, temp]);
          }

          if (attributeIndex === data.productAttributeWithDetail.length - 1) {
            setisLoadingAttributeData(false);
          }
        }
      );

      setListOfConfigurationTerms(_listOfConfigurationTerms);

      setAttributePropList(_attributePropList);
      setTag(data.productAttributeList);
    }
  }, [data]);

  useEffect(() => {
    if (tag.length !== 0) {
      const array: string[] = [];
      tag.map((item) => {
        const found = attributeState.find((element) => element.id === item);
        if (found !== undefined) {
          array.push(found.name);
        }
      });
      setAttributeName(array);
    } else {
      setAttributeName([]);
    }

    console.log(tag);
    console.log(attributeState);
    dispatch(setVariableProductAttributeTempForm(tag));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    if (configureTermsDataSet.length !== 0) {
      configureTermsDataSet.map((item, index) => {
        let arrayTemp: string[] = [];
        let configurationAttributeName1: string;
        let configurationAttributeName2: string;
        let configurationAttributeName3: string;

        let variationAttributeListTemp: CustomProductAttibuteModel[] = [];

        if (item.configurationAttributeId1 !== undefined) {
          arrayTemp.push(item.configurationAttributeId1);
          variationAttributeListTemp.push({
            id: item.configurationAttributeId1,
            name: item.configurationAttributeName1,
          });
        }

        if (item.configurationAttributeId2 !== undefined) {
          arrayTemp.push(item.configurationAttributeId2);
          variationAttributeListTemp.push({
            id: item.configurationAttributeId2,
            name: item.configurationAttributeName2,
          });
        }

        if (item.configurationAttributeId3 !== undefined) {
          arrayTemp.push(item.configurationAttributeId3);
          variationAttributeListTemp.push({
            id: item.configurationAttributeId3,
            name: item.configurationAttributeName3,
          });
        }

        let varianName = "";

        if (item.configurationAttributeName1 !== undefined) {
          configurationAttributeName1 = item.configurationAttributeName1;
          varianName = item.configurationAttributeName1;
        }

        if (item.configurationAttributeName2 !== undefined) {
          configurationAttributeName2 = item.configurationAttributeName2;
          varianName =
            item.configurationAttributeName1 +
            "-" +
            item.configurationAttributeName2;
        }

        if (item.configurationAttributeName3 !== undefined) {
          configurationAttributeName3 = item.configurationAttributeName3;
          varianName =
            item.configurationAttributeName1 +
            "-" +
            item.configurationAttributeName2 +
            "-" +
            item.configurationAttributeName3;
        }

        console.log("SET VARIATION INPUT TEMP");

        setVariationFormInputTemp((prevState) => [
          ...prevState,
          {
            customProductAttributeList: variationAttributeListTemp,
            configurationAttributeName1: configurationAttributeName1,
            configurationAttributeName2: configurationAttributeName2,
            configurationAttributeName3: configurationAttributeName3,
            productId: "00000000-0000-0000-0000-000000000000",
            sku: inventorySKU,
            name: varianName,
            isEnable: false,
            isDownloadable: false,
            isVirtual: false,
            isManageStock: false,
            regulerPriceEndCustomer: 0,
            salePriceEndCustomer: 0,
            regulerPriceReseller: 0,
            salePriceReseller: 0,
            regulerPriceStockist: 0,
            salePriceStockist: 0,
            sharePriceReseller: 0,
            sharePriceStockist: 0,
            startSaleCustomer: "2021-01-01T00:00:00.000Z",
            endSaleCustomer: "2021-01-01T00:00:00.000Z",
            startSaleReseller: "2021-01-01T00:00:00.000Z",
            endSaleReseller: "2021-01-01T00:00:00.000Z",
            startSaleStockist: "2021-01-01T00:00:00.000Z",
            endSaleStockist: "2021-01-01T00:00:00.000Z",
            stockQty: 0,
            stockStatus: 0,
            allowBackOrder: 0,
            shippingClass: 0,
            weight: SimpleProductShippingTempFormState.shippingWeight,
            fileFormat: "",
            dimensionsLength:
              SimpleProductShippingTempFormState.shippingDimensionsLength,
            dimensionsWidth:
              SimpleProductShippingTempFormState.shippingDimensionsWidth,
            dimensionsHeight:
              SimpleProductShippingTempFormState.shippingDimensionsHeight,
            variationDescription: "",
            image: "",
            index: index,
            iconColor: "#b32aa9",
            warehouseStockList: [],
            maxResellerQty: 0,
            maxStockistQty: 0,
          } as variationModel,
        ]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configureTermsDataSet]);

  useEffect(() => {
    if (statusState === "success-create-single-variant" || statusState === "success-update-index") {
      dispatch(resetStatus());
      dispatch(resetIndexStatus());
      axios
        .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
        .then((res) => {
          setVariationFormInputTemp(res.data.data);
          setShowModal(false);
        });
    }
  }, [statusState]);

  useEffect(() => {
    if (variationFormInputTemp.length !== 0) {
      dispatch(setVariableProductVariationTempForm(variationFormInputTemp));
      console.log("DISPATCH VARIABLE PRODUCT VARIATION");
    }
  }, [dispatch, variationFormInputTemp]);

  const handleImage = (files: any, index: number) => {
    setFileFormat("");
    setThumbnail("");
    setTempIndex(index);
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        // const list = [...variationFormInputTemp];
        // list[index]["image"] = result;
        // setVariationFormInputTemp(list);

        const updatedVariationInput = variationFormInputTemp.map(
          (item, itemIndex) => {
            if (itemIndex === index) {
              return {
                ...item,
                ["image"]: result,
              };
            }
            return item;
          }
        );
        setVariationFormInputTemp(updatedVariationInput);

        setImage(result);
      });
    } else {
      console.log("error");
    }
  };

  const getListByProductAttributeApi = async (productAttributeId: string) => {
    return axios.get<any>(
      endpoint +
        "/api/ConfigurationAttribute/GetListByProductAttribute?productAttributeId=" +
        productAttributeId
    );
  };

  const onSelectedAttributeChange = (attribute: any) => {
    let temp = newVariantDataTemp;
    let tempCustomProductAttributeList =
      newVariantDataTemp.customProductAttributeList;
    tempCustomProductAttributeList.push({
      id: attribute.id,
      name: attribute.label,
    });

    temp.name = temp.name + attribute.label + " ";
    temp.customProductAttributeList = tempCustomProductAttributeList;
  };

  const handleInputConfigureTermsChange = (
    eventName: any,
    eventVal: any,
    index: number
  ) => {
    // let list = [...attributePropList];
    // list[index][eventName] = eventVal;
    // setAttributePropList(list);

    const updatedAttributeTerm = attributePropList.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          [eventName]: eventVal,
        };
      }
      return item;
    });

    setAttributePropList(updatedAttributeTerm);
    console.log(attributePropList);
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //   if (!showAlert) {
  //     setTimeout(function () {
  //       window.scrollTo({
  //         top: 0,
  //         behavior: "smooth",
  //       });
  //     }, 300);
  //   }
  // }, [showAlert]);

  const onInputSKUChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["sku"] = value;
    // setVariationFormInputTemp(list);
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["sku"]: value,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputIsActiveChange = (index: number, value: number) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["isActive"]: value === 1 ? true : false,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputNameChange = (index: number, value: string) => {
    const list = [...variationFormInputTemp];
    list[index]["name"] = value;
    setVariationFormInputTemp(list);
  };

  const onInputRegularPriceEndCustomerChange = (
    index: number,
    value: string
  ) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["regulerPriceEndCustomer"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputSalePriceEndCustomerChange = (index: number, value: string) => {
    let isSalePriceValidated = true;

    variationFormInputTemp.map((item, itemIndex) => {
      if (
        index === itemIndex &&
        parseInt(value) > item.regulerPriceEndCustomer
      ) {
        isSalePriceValidated = false;
      }
    });

    if (isSalePriceValidated) {
      const updatedVariationInput = variationFormInputTemp.map(
        (item, itemIndex) => {
          if (itemIndex === index) {
            return {
              ...item,
              ["salePriceEndCustomer"]: parseInt(value),
            };
          }
          return item;
        }
      );
      setVariationFormInputTemp(updatedVariationInput);
    } else {
      setAlertProps({
        variant: "Failed",
        title: "Sale Price harus lebih rendah dari Reguler Price !",
        content: "",
      });
      setShowAlert(true);
    }
  };

  const onInputRegularPriceResellerChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["regulerPriceReseller"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputSalePriceResellerChange = (index: number, value: string) => {
    // const updatedVariationInput = variationFormInputTemp.map((item, itemIndex) => {
    //   if(itemIndex===index){
    //     return {
    //       ...item,
    //       ["salePriceReseller"] : parseInt(value)
    //     }
    //   } return item
    // })
    // setVariationFormInputTemp(updatedVariationInput)

    let isSalePriceValidated = true;

    variationFormInputTemp.map((item, itemIndex) => {
      if (index === itemIndex && parseInt(value) > item.regulerPriceReseller) {
        isSalePriceValidated = false;
      }
    });

    if (isSalePriceValidated) {
      const updatedVariationInput = variationFormInputTemp.map(
        (item, itemIndex) => {
          if (itemIndex === index) {
            return {
              ...item,
              ["salePriceReseller"]: parseInt(value),
            };
          }
          return item;
        }
      );
      setVariationFormInputTemp(updatedVariationInput);
    } else {
      setAlertProps({
        variant: "Failed",
        title: "Sale Price harus lebih rendah dari Reguler Price !",
        content: "",
      });
      setShowAlert(true);
    }
  };

  const onInputRegularPriceStockistChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["regulerPriceStockist"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputSalePriceStockistChange = (index: number, value: string) => {
    // const updatedVariationInput = variationFormInputTemp.map((item, itemIndex) => {
    //   if(itemIndex===index){
    //     return {
    //       ...item,
    //       ["salePriceStockist"] : parseInt(value)
    //     }
    //   } return item
    // })
    // setVariationFormInputTemp(updatedVariationInput)

    let isSalePriceValidated = true;

    variationFormInputTemp.map((item, itemIndex) => {
      if (index === itemIndex && parseInt(value) > item.regulerPriceStockist) {
        isSalePriceValidated = false;
      }
    });

    if (isSalePriceValidated) {
      const updatedVariationInput = variationFormInputTemp.map(
        (item, itemIndex) => {
          if (itemIndex === index) {
            return {
              ...item,
              ["salePriceStockist"]: parseInt(value),
            };
          }
          return item;
        }
      );
      setVariationFormInputTemp(updatedVariationInput);
    } else {
      setAlertProps({
        variant: "Failed",
        title: "Sale Price harus lebih rendah dari Reguler Price !",
        content: "",
      });
      setShowAlert(true);
    }
  };

  const onInputStockQuantity = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["stockQty"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputSharePriceReseller = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["sharePriceReseller"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputSharePriceStockist = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["sharePriceStockist"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputAllowBackOrder = (index: number, value: string) => {
    const list = [...variationFormInputTemp];
    list[index]["allowBackOrder"] = parseInt(value);
    setVariationFormInputTemp(list);
  };

  const onInputStockStatusChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["stockStatus"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputWeightChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["weight"]: parseFloat(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputDimensionsLengthChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["dimensionsLength"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputDimensionsWidthChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["dimensionsWidth"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputDimensionsHeightChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["dimensionsHeight"] = parseInt(value);
    // setVariationFormInputTemp(list);

    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["dimensionsHeight"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputShippingClassChange = (index: number, value: string) => {
    const list = [...variationFormInputTemp];
    list[index]["shippingClass"] = parseInt(value);
    setVariationFormInputTemp(list);
  };

  const onInputVariationDescriptionChange = (index: number, value: string) => {
    const list = [...variationFormInputTemp];
    list[index]["variationDescription"] = value;
    setVariationFormInputTemp(list);
  };

  const onInputIsEnableChange = (index: number, value: boolean) => {
    const list = [...variationFormInputTemp];
    list[index]["isEnable"] = value;
    setVariationFormInputTemp(list);
  };

  const onInputIsDownloadableChange = (index: number, value: boolean) => {
    const list = [...variationFormInputTemp];
    list[index]["isDownloadable"] = value;
    setVariationFormInputTemp(list);
  };

  const onInputIsVirtualChange = (index: number, value: boolean) => {
    const list = [...variationFormInputTemp];
    list[index]["isVirtual"] = value;
    setVariationFormInputTemp(list);
  };

  const onInputIsManageStockChange = (index: number, value: boolean) => {
    const list = [...variationFormInputTemp];
    list[index]["isManageStock"] = value;
    setVariationFormInputTemp(list);
  };

  const onInputMaxQuantityResellerChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["maxResellerQty"] = parseInt(value);
    // setVariationFormInputTemp(list);

    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["maxResellerQty"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onInputMaxQuantityStockistChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["maxStockistQty"]: parseInt(value),
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onClickDispatchBtn = () => {
    let isSalePriceValidated = true;
    variationFormInputTemp.map((item) => {
      if (item.salePriceEndCustomer > item.regulerPriceEndCustomer) {
        isSalePriceValidated = false;
      }

      if (item.salePriceReseller > item.regulerPriceReseller) {
        isSalePriceValidated = false;
      }

      if (item.salePriceStockist > item.regulerPriceStockist) {
        isSalePriceValidated = false;
      }
    });

    if (isSalePriceValidated) {
      console.log(variationFormInputTemp);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      dispatch(setVariableProductVariationTempForm(variationFormInputTemp));

      setAlertProps({
        variant: "Info",
        title: "Data Varian sudah disimpan",
        content: "",
      });
      setShowAlert(true);
    } else {
      setAlertProps({
        variant: "Failed",
        title: "Sale Price harus lebih rendah dari Reguler Price !",
        content: "",
      });
      setShowAlert(true);
    }

    // dispatch(setVariableProductVariationTempForm(variationFormInputTemp));
  };

  const onStartSaleCustomerChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["startSaleCustomer"] = value;
    // setVariationFormInputTemp(list);

    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["startSaleCustomer"]: value,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onEndSaleCustomerChange = (index: number, value: string) => {
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["endSaleCustomer"]: value,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onStartSaleResellerChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["startSaleReseller"] = value;
    // setVariationFormInputTemp(list);
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["startSaleReseller"]: value,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onEndSaleResellerChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["endSaleReseller"] = value;
    // setVariationFormInputTemp(list);
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["endSaleReseller"]: value,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onStartSaleStockistChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["startSaleStockist"] = value;
    // setVariationFormInputTemp(list);
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["startSaleStockist"]: value,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onEndSaleStockistChange = (index: number, value: string) => {
    // const list = [...variationFormInputTemp];
    // list[index]["endSaleStockist"] = value;
    // setVariationFormInputTemp(list);
    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["endSaleStockist"]: value,
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onColorChange = (index: number, value: string) => {
    const list = [...variationFormInputTemp];
    list[index]["iconColor"] = value;
    setVariationFormInputTemp(list);
    console.log(value);
  };

  const onVariantWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedWarehouse(e.target.value);
    setVariantWarehouse(e.target.value.split(";")[0]);
    setVariantWarehouseName(e.target.value.split(";")[1]);
    console.log(e.target.value);
  };

  const onVariantStockChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVariantStock(parseInt(e.target.value));
  };

  const onAddVariantWarehouseStock = (index: number) => {
    const list = [...variationFormInputTemp];

    let variantWarehouseTemp = {
      warehouseId: variantWarehouse,
      warehouseName: variantWarehouseName,
      quantity: variantStock,
    } as VariationWarehouseStock;

    //check same warehouse id & zero qty
    if (
      list[index].warehouseStockList.find(
        (i) => i.warehouseId === variantWarehouse
      ) === undefined && variantStock !== 0
    ) {
      const variantWarehouseList = [
        ...list[index].warehouseStockList,
        variantWarehouseTemp,
      ];

      const updatedVariationInput = variationFormInputTemp.map(
        (item, itemIndex) => {
          if (itemIndex === index) {
            return {
              ...item,
              ["warehouseStockList"]: variantWarehouseList,
            };
          }
          return item;
        }
      );
      setVariationFormInputTemp(updatedVariationInput);
      setVariantStock(0);
    } else {
      console.log("SAME ID !");
    }
  };

  const onVariantWarehouseDelete = (index: number, id: string) => {
    const list = [...variationFormInputTemp];
    const variantWarehouseList = list[index].variationWarehouse;
    setVariationFormInputTemp(list);
  };

  const onClearVariantWarehouseStock = (index: number) => {
    // const list = [...variationFormInputTemp];
    // list[index].warehouseStockList = [];
    // setVariationFormInputTemp(list);

    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ["warehouseStockList"]: [],
          };
        }
        return item;
      }
    );
    setVariationFormInputTemp(updatedVariationInput);
  };

  const onAddVariantClick = () => {
    setShowModal(true);
    setNewVariantDataTemp({
      customProductAttributeList: [],
      configurationAttributeName1: "",
      configurationAttributeName2: "",
      configurationAttributeName3: "",
      productId: data.id,
      sku: data.inventorySKU,
      name: "",
      isEnable: false,
      isDownloadable: false,
      isVirtual: false,
      isManageStock: false,
      regulerPriceEndCustomer: 0,
      salePriceEndCustomer: 0,
      regulerPriceReseller: 0,
      salePriceReseller: 0,
      regulerPriceStockist: 0,
      salePriceStockist: 0,
      sharePriceReseller: 0,
      sharePriceStockist: 0,
      startSaleCustomer: "2021-01-01T00:00:00.000Z",
      endSaleCustomer: "2021-01-01T00:00:00.000Z",
      startSaleReseller: "2021-01-01T00:00:00.000Z",
      endSaleReseller: "2021-01-01T00:00:00.000Z",
      startSaleStockist: "2021-01-01T00:00:00.000Z",
      endSaleStockist: "2021-01-01T00:00:00.000Z",
      stockQty: 0,
      stockStatus: 0,
      allowBackOrder: 0,
      shippingClass: 0,
      weight: SimpleProductShippingTempFormState.shippingWeight,
      fileFormat: "",
      dimensionsLength:
        SimpleProductShippingTempFormState.shippingDimensionsLength,
      dimensionsWidth:
        SimpleProductShippingTempFormState.shippingDimensionsWidth,
      dimensionsHeight:
        SimpleProductShippingTempFormState.shippingDimensionsHeight,
      variationDescription: "",
      image: "",
      index: 0,
      iconColor: "#b32aa9",
      warehouseStockList: [],
      maxResellerQty: 0,
      maxStockistQty: 0,
    } as variationModel);
  };

  const onSaveNewVariantClick = () => {
    console.log(newVariantDataTemp);
    let lastIndex = variationFormInputTemp.length === 0 ? 0 : (Math.max(...variationFormInputTemp.map(o => o.index))+1);
    dispatch(createSingleVariant({...newVariantDataTemp, index: lastIndex}));
  };

  const copyFirstVariantInEditMode = () => {
    let firstVariant = variationFormInputTemp[0];

    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        return {
          ...item,
          // ["customProductAttributeList"]: item.customProductAttributeList,
          // ["configurationAttributeName1"]: item.configurationAttributeName1,
          // ["configurationAttributeName2"]: item.configurationAttributeName2,
          // ["configurationAttributeName3"]: item.configurationAttributeName3,
          // ["productId"]: firstVariant.productId,
          // ["sku"]: firstVariant.sku,
          // ["name"]: item.name,
          // ["isEnable"]: firstVariant.isEnable,
          // ["isDownloadable"]: firstVariant.isDownloadabl,
          // ["isVirtual"]: firstVariant.isVirtual,
          // ["isManageStock"]: firstVariant.isManageStock,
          ["regulerPriceEndCustomer"]: firstVariant.regulerPriceEndCustomer,
          ["salePriceEndCustomer"]: firstVariant.salePriceEndCustomer,
          ["regulerPriceReseller"]: firstVariant.regulerPriceReseller,
          ["salePriceReseller"]: firstVariant.salePriceReseller,
          ["regulerPriceStockist"]: firstVariant.regulerPriceStockist,
          ["salePriceStockist"]: firstVariant.salePriceStockist,

          ["sharePriceReseller"]: firstVariant.sharePriceReseller,
          ["sharePriceStockist"]: firstVariant.sharePriceStockist,

          ["startSaleCustomer"]: firstVariant.startSaleCustomer,
          ["endSaleCustomer"]: firstVariant.endSaleCustomer,
          ["startSaleReseller"]: firstVariant.startSaleReseller,
          ["endSaleReseller"]: firstVariant.endSaleReseller,
          ["startSaleStockist"]: firstVariant.startSaleStockist,
          ["endSaleStockist"]: firstVariant.endSaleStockist,

          // ["stockQty"]: firstVariant.stockQty,
          // ["stockStatus"]: firstVariant.stockStatus,
          // ["allowBackOrder"]: firstVariant.allowBackOrder,
          ["shippingClass"]: firstVariant.shippingClass,
          ["weight"]: firstVariant.weight,
          ["dimensionsLength"]: firstVariant.dimensionsLength,
          ["dimensionsWidth"]: firstVariant.dimensionsWidth,
          ["dimensionsHeight"]: firstVariant.dimensionsHeight,
          ["maxResellerQty"]: firstVariant.maxResellerQty,
          ["maxStockistQty"]: firstVariant.maxStockistQty,
          // ["variationDescription"]: firstVariant.variationDescription,
          // ["image"]: firstVariant.image,

          // ["iconColor"]: firstVariant.iconColor,
          // ["warehouseStockList"]: firstVariant.warehouseStockList,
        };
      }
    );
    setVariationFormInputTemp(updatedVariationInput);

    setAlertProps({
      variant: "Info",
      title: "Data Varian sudah dicopy",
      content: "",
    });
    setShowAlert(true);
  };

  const copyFirstVariantToAll = () => {
    let firstVariant = variationFormInputTemp[0];
    // const tempList = [...variationFormInputTemp];

    // tempList.map((item) => {
    //   item.productId = firstVariant.productId;
    //   item.sku = firstVariant.sku;
    //   item.name = firstVariant.name;
    //   item.isEnable = firstVariant.isEnable;
    //   item.isDownloadable = firstVariant.isDownloadable;
    //   item.isVirtual = firstVariant.isVirtual;
    //   item.isManageStock = firstVariant.isManageStock;
    //   item.regulerPriceEndCustomer = firstVariant.regulerPriceEndCustomer;
    //   item.salePriceEndCustomer = firstVariant.salePriceEndCustomer;
    //   item.regulerPriceReseller = firstVariant.regulerPriceReseller;
    //   item.salePriceReseller = firstVariant.salePriceReseller;
    //   item.regulerPriceStockist = firstVariant.regulerPriceStockist;
    //   item.salePriceStockist = firstVariant.salePriceStockist;

    //   item.sharePriceReseller = firstVariant.sharePriceReseller;
    //   item.sharePriceStockist = firstVariant.sharePriceStockist;

    //   item.startSaleCustomer = firstVariant.startSaleCustomer;
    //   item.endSaleCustomer = firstVariant.endSaleCustomer;
    //   item.startSaleReseller = firstVariant.startSaleReseller;
    //   item.endSaleReseller = firstVariant.endSaleReseller;
    //   item.startSaleStockist = firstVariant.startSaleStockist;
    //   item.endSaleStockist = firstVariant.endSaleStockist;

    //   item.stockQty = firstVariant.stockQty;
    //   item.stockStatus = firstVariant.stockStatus;
    //   item.allowBackOrder = firstVariant.allowBackOrder;
    //   item.shippingClass = firstVariant.shippingClass;
    //   item.weight = firstVariant.weight;
    //   item.dimensionsLength = firstVariant.dimensionsLength;
    //   item.dimensionsWidth = firstVariant.dimensionsWidth;
    //   item.dimensionsHeight = firstVariant.dimensionsHeight;
    //   item.variationDescription = firstVariant.variationDescription;
    //   item.image = firstVariant.image;

    //   item.iconColor = firstVariant.iconColor;
    //   item.warehouseStockList = firstVariant.warehouseStockList;
    // });

    // console.log(tempList);

    // setVariationFormInputTemp(tempList);

    const updatedVariationInput = variationFormInputTemp.map(
      (item, itemIndex) => {
        return {
          ...item,
          ["customProductAttributeList"]: item.customProductAttributeList,
          ["configurationAttributeName1"]: item.configurationAttributeName1,
          ["configurationAttributeName2"]: item.configurationAttributeName2,
          ["configurationAttributeName3"]: item.configurationAttributeName3,
          ["productId"]: firstVariant.productId,
          ["sku"]: firstVariant.sku,
          ["name"]: item.name,
          ["isEnable"]: firstVariant.isEnable,
          ["isDownloadable"]: firstVariant.isDownloadabl,
          ["isVirtual"]: firstVariant.isVirtual,
          ["isManageStock"]: firstVariant.isManageStock,
          ["regulerPriceEndCustomer"]: firstVariant.regulerPriceEndCustomer,
          ["salePriceEndCustomer"]: firstVariant.salePriceEndCustomer,
          ["regulerPriceReseller"]: firstVariant.regulerPriceReseller,
          ["salePriceReseller"]: firstVariant.salePriceReseller,
          ["regulerPriceStockist"]: firstVariant.regulerPriceStockist,
          ["salePriceStockist"]: firstVariant.salePriceStockist,

          ["sharePriceReseller"]: firstVariant.sharePriceReseller,
          ["sharePriceStockist"]: firstVariant.sharePriceStockist,

          ["startSaleCustomer"]: firstVariant.startSaleCustomer,
          ["endSaleCustomer"]: firstVariant.endSaleCustomer,
          ["startSaleReseller"]: firstVariant.startSaleReseller,
          ["endSaleReseller"]: firstVariant.endSaleReseller,
          ["startSaleStockist"]: firstVariant.startSaleStockist,
          ["endSaleStockist"]: firstVariant.endSaleStockist,

          ["stockQty"]: firstVariant.stockQty,
          ["stockStatus"]: firstVariant.stockStatus,
          ["allowBackOrder"]: firstVariant.allowBackOrder,
          ["shippingClass"]: firstVariant.shippingClass,
          ["weight"]: firstVariant.weight,
          ["dimensionsLength"]: firstVariant.dimensionsLength,
          ["dimensionsWidth"]: firstVariant.dimensionsWidth,
          ["dimensionsHeight"]: firstVariant.dimensionsHeight,
          ["variationDescription"]: firstVariant.variationDescription,
          ["image"]: firstVariant.image,

          ["iconColor"]: firstVariant.iconColor,
          ["warehouseStockList"]: firstVariant.warehouseStockList,
          ["maxResellerQty"]: firstVariant.maxResellerQty,
          ["maxStockistQty"]: firstVariant.maxStockistQty,
        };
      }
    );
    setVariationFormInputTemp(updatedVariationInput);

    setAlertProps({
      variant: "Info",
      title: "Data Varian sudah dicopy",
      content: "",
    });
    setShowAlert(true);
  };

  const columns = [
    {
      name: "Warehouse",
      selector: "warehouseName",
      sortable: true,
    },
    {
      name: "Stock",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { warehouseId: any }, index: number) => (
        <>
          {/* <span
            onClick={() => {
              onStoreEditClick(index, d.id);
            }}>
            <img src={edit}></img>
          </span>
          {"  "} */}
          {!isEdit ?? (
            <>
              <span
                onClick={() => {
                  onVariantWarehouseDelete(index, d.warehouseId);
                }}
              >
                <img src={hapus}></img>;
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  function CustomToggle({ children, eventKey }: any) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});
    return (
        <Button
            variant="link"
            onClick={decoratedOnClick}
            style={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
            }}
        >
          {children}
        </Button>
    );
  }

  const onUpIndexClicked = (id: string) => {
    dispatch(updateIndex({
      variantId: id,
      type: VariantUpdateIndexTypeEnum.Down
    }));
  }

  const onDownIndexClicked = (id: string) => {
    dispatch(updateIndex({
      variantId: id,
      type: VariantUpdateIndexTypeEnum.Up
    }));
  }

  return (
    <div style={{ paddingBottom: 100 }}>
      <hr />

      {/* <Button variant="info" onClick={onClickDispatchBtn}>
        Save Data Varian
      </Button> */}

      {"    "}

      {!isEdit ? (
        <>
          <Button variant="outline-info" onClick={copyFirstVariantToAll}>
            Copy Data Varian
          </Button>
        </>
      ) : (
        <>
          {" "}
          <Button variant="outline-info" onClick={copyFirstVariantInEditMode}>
            Copy Data Harga Varian
          </Button>{" "}
          <Button variant="outline-info" onClick={onAddVariantClick}>
            Tambah Varian
          </Button>
        </>
      )}

      <hr />
      <CircleLoader loading={isIndexLoading}>
      <Accordion>
        <Form>
          {variationFormInputTemp.map((item, index) => (
            <>
              <Card>
                <Card.Header className="p-0">
                  {/*<Accordion.Toggle*/}
                  {/*  as={Button}*/}
                  {/*  variant="link"*/}
                  {/*  eventKey={index.toString()}*/}
                  {/*  style={{*/}
                  {/*    width: "100%",*/}
                  {/*    justifyContent: "flex-start",*/}
                  {/*    textAlign: "left",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <b>*/}
                  {/*    #{index + 1}. {item.name}*/}
                  {/*  </b>*/}
                  {/*</Accordion.Toggle>*/}
                  <div className='d-flex'>
                    <div className='d-flex flex-grow-1'>
                      <CustomToggle eventKey={index.toString()}>
                        <b>
                          #{index + 1}. {item.name}
                        </b>
                      </CustomToggle>
                    </div>
                    <div className='d-flex align-items-center mx-3'>
                      {
                          item.index > 0 && (
                              <button type='button' onClick={() => onUpIndexClicked(item.id)} className='button-icon ml-1'>
                                <BiUpArrowCircle size={28} color='#00a8cc' />
                              </button>
                          )
                      }
                      {
                        item.index < (Math.max(...variationFormInputTemp.map(o => o.index))) && (
                              <button type='button' onClick={() => onDownIndexClicked(item.id)} className='button-icon ml-1'>
                                <BiDownArrowCircle size={28} color='#f52b3d' />
                              </button>
                          )
                      }

                    </div>
                  </div>
                </Card.Header>

                <Accordion.Collapse eventKey={index.toString()}>
                  <Card.Body>
                    <Form.Group as={Row} className="input-padding">
                      <Col sm={4}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleImage(acceptedFiles, index)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone file-upload-frame w-100">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="text-center file-upload-label">
                                  <div>
                                    <>
                                      {item.image !== "" ? (
                                        <img
                                          src={
                                            variationFormInputTemp[index][
                                              "image"
                                            ] !== "" && !isEdit
                                              ? variationFormInputTemp[index][
                                                  "image"
                                                ]
                                              : endpoint + item.image

                                            //  endpoint + item.image
                                          }
                                          className="file-upload-image mb3"
                                        />
                                      ) : (
                                        <div
                                          className="file-upload-image mb3"
                                          style={{ paddingTop: 50 }}
                                        >
                                          <img src={upload} alt="upload" />
                                          <br />
                                          <span style={{ color: "black" }}>
                                            Select File
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Col>

                      <Col sm={8}>
                        <Form.Group className="input-padding p-0">
                          <Form.Label>SKU</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) =>
                              onInputSKUChange(index, e.target.value)
                            }
                            value={inventorySKU}
                            disabled
                          />
                        </Form.Group>

                        <Form.Group className="input-padding p-0">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            value={item.isActive === true ? 1 : 0}
                            onChange={(e) =>
                              onInputIsActiveChange(
                                index,
                                parseInt(e.target.value)
                              )
                            }
                          >
                            <option value={1}>Aktif</option>
                            <option value={0}>Non Aktif</option>
                          </Form.Control>
                        </Form.Group>

                        {/* {isEdit && item.customProductAttributeList !== null ? (
                        <>
                          {item.customProductAttributeList[0] !== undefined && (
                            <Col sm={3} >
                              <Form.Control
                                defaultValue={
                                  item.customProductAttributeList[0].name
                                }
                                placeholder="Attribute"
                                style={{ width: "100%" }}
                                disabled
                              />
                            </Col>
                          )}

                          {item.customProductAttributeList[1] !== undefined && (
                            <Col sm={3}>
                              <Form.Control
                                defaultValue={
                                  item.customProductAttributeList[1].name
                                }
                                placeholder="Attribute"
                                style={{ width: "100%" }}
                                disabled
                              />
                            </Col>
                          )}

                          {item.customProductAttributeList[2] !== undefined && (
                            <Col sm={3}>
                              <Form.Control
                                defaultValue={
                                  item.customProductAttributeList[2].name
                                }
                                placeholder="Attribute"
                                style={{ width: "100%" }}
                                disabled
                              />
                            </Col>
                          )}
                        </>
                      ) : (
                        <>
                          {item.configurationAttributeName1 !== undefined && (
                            <Col sm={3}>
                              <Form.Control
                                defaultValue={item.configurationAttributeName1}
                                placeholder="Attribute"
                                style={{ width: "100%" }}
                                disabled
                              />
                            </Col>
                          )}

                          {item.configurationAttributeName2 !== undefined && (
                            <Col sm={3}>
                              <Form.Control
                                defaultValue={item.configurationAttributeName2}
                                placeholder="Attribute"
                                style={{ width: "100%" }}
                                disabled
                              />
                            </Col>
                          )}

                          {item.configurationAttributeName3 !== undefined && (
                            <Col sm={3}>
                              <Form.Control
                                defaultValue={item.configurationAttributeName3}
                                placeholder="Attribute"
                                style={{ width: "100%" }}
                                disabled
                              />
                            </Col>
                          )}
                        </>
                      )} */}
                      </Col>

                      {/* {item.customProductAttributeList[0] !== undefined && (
                <>
                  <Col sm={3}>
                    <Form.Control
                      defaultValue={item.customProductAttributeList[0].name}
                      placeholder="Attribute"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Col>
                </>
              )}
              {item.customProductAttributeList[1] !== undefined && (
                <>
                  <Col sm={3}>
                    <Form.Control
                      defaultValue={item.customProductAttributeList[1].name}
                      placeholder="Attribute"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Col>
                </>
              )}
              {item.customProductAttributeList[2] !== undefined && (
                <>
                  <Col sm={3}>
                    <Form.Control
                      defaultValue={item.customProductAttributeList[2].name}
                      placeholder="Attribute"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Col>
                </>
              )} */}
                    </Form.Group>

                    <Form>
                      <hr />

                      {/* <Form.Group className="input-padding">
                            <Form.Label>SKU</Form.Label>
                            <Form.Control
                              type="text"
                              onChange={(e) =>
                                onInputSKUChange(index, e.target.value)
                              }
                              value={item.sku}
                              disabled
                            />
                          </Form.Group> */}

                      {/* <Form.Group className="input-padding">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => onInputNameChange(index, e.target.value)}
                      value={item.name}
                    />
                  </Form.Group> */}

                      {/* <Form.Check
                      inline
                      label="Enabled"
                      name="group1"
                      type="checkbox"
                      checked={item.isEnable}
                      onChange={(e) =>
                        onInputIsEnableChange(index, e.target.checked)
                      }
                    />
                    <Form.Check
                      inline
                      label="Downloadable"
                      name="group1"
                      type="checkbox"
                      checked={item.isDownloadable}
                      onChange={(e) =>
                        onInputIsDownloadableChange(index, e.target.checked)
                      }
                    /> */}
                      {/* <Form.Check
                      inline
                      label="Virtual"
                      name="group1"
                      type="checkbox"
                      checked={item.isVirtual}
                      onChange={(e) => onInputIsVirtualChange(index, e.target.checked)}
                    />
                    <Form.Check
                      inline
                      label="Manage Stock"
                      name="group1"
                      type="checkbox"
                      checked={item.isManageStock}
                      onChange={(e) => onInputIsManageStockChange(index, e.target.checked)}
                    /> */}

                      {/* <Form.Group as={Row} className="input-padding">
                <Col sm={6}>
                  <Form.Label>Regular Price</Form.Label>
                  <Form.Control
                    type="number"
                    value={item.regulerPrice}
                    onChange={(e) => onInputRegularPriceChange(index, e.target.value)}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Sales Price</Form.Label>
                  <Form.Control
                    type="number"
                    value={item.salePrice}
                    onChange={(e) => onInputSalesPriceChange(index, e.target.value)}
                  />
                </Col>
              </Form.Group> */}

                      <Form.Group as={Row} className="input-padding">
                        <Col sm={6}>
                          <Form.Label>Regular Price End Cust.</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.regulerPriceEndCustomer}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputRegularPriceEndCustomerChange(
                                index,
                                val.toString()
                              );
                            }}
                          />
                          {/* <Form.Control
                            type="number"
                            value={item.regulerPriceEndCustomer}
                            onChange={(e) =>
                              onInputRegularPriceEndCustomerChange(
                                index,
                                e.target.value
                              )
                            }
                          /> */}
                        </Col>
                        <Col sm={6}>
                          <Form.Label>Sale Price End Cust.</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.salePriceEndCustomer}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputSalePriceEndCustomerChange(
                                index,
                                val.toString()
                              );
                            }}
                          />
                          {/* <Form.Control
                              type="number"
                              value={item.salePriceEndCustomer}
                              onChange={(e) => {
                                onInputSalePriceEndCustomerChange(
                                  index,
                                  e.target.value
                                );
                              }}
                            /> */}

                          {item.salePriceEndCustomer > 0 ? (
                            <>
                              <Row>
                                <Col>
                                  <Form.Label>Sale Date </Form.Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={6}>
                                  <Form.Control
                                    type="date"
                                    value={item.startSaleCustomer.substr(0, 10)}
                                    onChange={(e) =>
                                      onStartSaleCustomerChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Control
                                    type="date"
                                    value={item.endSaleCustomer.substr(0, 10)}
                                    onChange={(e) =>
                                      onEndSaleCustomerChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="input-padding">
                        <Col sm={6}>
                          <Form.Label>Regular Price Reseller</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.regulerPriceReseller}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputRegularPriceResellerChange(
                                index,
                                val.toString()
                              );
                            }}
                          />
                          {/* <Form.Control
                            type="number"
                            value={item.regulerPriceReseller}
                            onChange={(e) =>
                              onInputRegularPriceResellerChange(
                                index,
                                e.target.value
                              )
                            }
                          /> */}

                          <Row>
                            <Col>
                              <Form.Label>Max Quantity </Form.Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Control
                                type="number"
                                value={item.maxResellerQty}
                                onChange={(e) =>
                                  onInputMaxQuantityResellerChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={6}>
                          <Form.Label>Sale Price Reseller</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.salePriceReseller}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputSalePriceResellerChange(
                                index,
                                val.toString()
                              );
                            }}
                          />
                          {/* <Form.Control
                            type="number"
                            value={item.salePriceReseller}
                            onChange={(e) =>
                              onInputSalePriceResellerChange(
                                index,
                                e.target.value
                              )
                            }
                          /> */}

                          {item.salePriceReseller > 0 ? (
                            <>
                              <Row>
                                <Col>
                                  <Form.Label>Sale Date </Form.Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={6}>
                                  <Form.Control
                                    type="date"
                                    value={item.startSaleReseller.substr(0, 10)}
                                    onChange={(e) =>
                                      onStartSaleResellerChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Control
                                    type="date"
                                    value={item.endSaleReseller.substr(0, 10)}
                                    onChange={(e) =>
                                      onEndSaleResellerChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="input-padding">
                        <Col sm={6}>
                          <Form.Label>Regular Price Stockist</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.regulerPriceStockist}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputRegularPriceStockistChange(
                                index,
                                val.toString()
                              );
                            }}
                          />
                          {/* <Form.Control
                            type="number"
                            value={item.regulerPriceStockist}
                            onChange={(e) =>
                              onInputRegularPriceStockistChange(
                                index,
                                e.target.value
                              )
                            }
                          /> */}

                          <Row>
                            <Col>
                              <Form.Label>Max Quantity </Form.Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Control
                                type="number"
                                value={item.maxStockistQty}
                                onChange={(e) =>
                                  onInputMaxQuantityStockistChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={6}>
                          <Form.Label>Sale Price Stockist</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.salePriceStockist}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputSalePriceStockistChange(
                                index,
                                val.toString()
                              );
                            }}
                          />
                          {/* <Form.Control
                            type="number"
                            value={item.salePriceStockist}
                            onChange={(e) =>
                              onInputSalePriceStockistChange(
                                index,
                                e.target.value
                              )
                            }
                          /> */}

                          {item.salePriceStockist > 0 ? (
                            <>
                              <Row>
                                <Col>
                                  <Form.Label>Sale Date </Form.Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={6}>
                                  <Form.Control
                                    type="date"
                                    value={item.startSaleStockist.substr(0, 10)}
                                    onChange={(e) =>
                                      onStartSaleStockistChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Control
                                    type="date"
                                    value={item.endSaleStockist.substr(0, 10)}
                                    onChange={(e) =>
                                      onEndSaleStockistChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="input-padding">
                        <Col sm={6}>
                          <Form.Label>Reseller Share Price</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.sharePriceReseller}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputSharePriceReseller(index, val.toString());
                            }}
                          />
                          {/* <Form.Control
                            type="number"
                            value={item.sharePriceReseller}
                            onChange={(e) =>
                              onInputSharePriceReseller(index, e.target.value)
                            }
                          ></Form.Control> */}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="input-padding">
                        <Col sm={6}>
                          <Form.Label>Stockist Share Price</Form.Label>
                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={item.sharePriceStockist}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputSharePriceStockist(index, val.toString());
                            }}
                          />
                          {/* <Form.Control
                            type="number"
                            value={item.sharePriceStockist}
                            onChange={(e) =>
                              onInputSharePriceStockist(index, e.target.value)
                            }
                          ></Form.Control> */}
                        </Col>
                      </Form.Group>

                      {/* <Form.Group as={Row} className="input-padding"> */}
                      {/* <Col sm={6}>
                  <Form.Label>Stock Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    value={item.stockQty}
                    onChange={(e) => onInputStockQuantity(index, e.target.value)}
                  />
                </Col> */}
                      {/* <Col sm={6}>
                  <Form.Label>Allow Backorders?</Form.Label>
                  <Form.Control
                    as="select"
                    value={item.allowBackOrder}
                    onChange={(e) =>
                      onInputAllowBackOrder(index, e.target.value)
                    }
                  >
                    <option value={0}>Allow</option>
                    <option value={1}>Not Allow</option>
                  </Form.Control>
                </Col>
              </Form.Group> */}

                      <Form.Group as={Row} className="input-padding">
                        <Col sm={6}>
                          <Form.Label>Weight</Form.Label>

                          <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix=" Kg"
                            className="form-control"
                            inputMode="text"
                            value={item.weight}
                            onValueChange={({ floatValue }) => {
                              let val = floatValue ?? 0;
                              onInputWeightChange(index, val.toString());
                            }}
                          />

                          {/* <Form.Control
                            type="number"
                            value={item.weight}
                            onChange={(e) =>
                              onInputWeightChange(index, e.target.value)
                            }
                          /> */}
                        </Col>
                        <Col sm={6}>
                          <Row>
                            <Col sm={4}>
                              <Form.Label>Length</Form.Label>
                              <Form.Control
                                type="number"
                                value={item.dimensionsLength}
                                onChange={(e) =>
                                  onInputDimensionsLengthChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col sm={4}>
                              <Form.Label>Height</Form.Label>
                              <Form.Control
                                type="number"
                                value={item.dimensionsHeight}
                                onChange={(e) =>
                                  onInputDimensionsHeightChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col sm={4}>
                              <Form.Label>Width</Form.Label>
                              <Form.Control
                                type="number"
                                value={item.dimensionsWidth}
                                onChange={(e) =>
                                  onInputDimensionsWidthChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>

                      {/* <Form.Group className="input-padding">
                <Form.Label>shipping class</Form.Label>
                <Form.Control
                  as="select"
                  value={item.shippingClass}
                  onChange={(e) =>
                    onInputShippingClassChange(index, e.target.value)
                  }>
                  <option value={0}>Same as Parent</option>
                  <option value={1}>Flat Rate</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="input-padding">
                <Form.Label>Variation Description</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={3}
                  value={item.variationDescription}
                  onChange={(e) =>
                    onInputVariationDescriptionChange(index, e.target.value)
                  }
                />
              </Form.Group> */}

                      {/* <Form.Group className="input-padding">
                <Form.Label>Stock Status</Form.Label>
                <Form.Control
                  as="select"
                  value={item.stockStatus}
                  onChange={(e) =>
                    onInputStockStatusChange(index, e.target.value)
                  }
                >
                  <option value={0}>Instock</option>
                  <option value={1}>Out of Stock</option>
                </Form.Control>
              </Form.Group> */}

                      <br />

                      <Form.Group className="input-padding">
                        <Form.Label>Variation Stock</Form.Label>

                        {!isEdit ? (
                          <>
                            <Row>
                              <Col sm={4}>
                                <Form.Control
                                  as="select"
                                  value={selectedWarehouse}
                                  onChange={onVariantWarehouseChange}
                                >
                                  <option value={"-;-"}>Pilih Warehouse</option>
                                  {warehouseListState.map((Item) => {
                                    if (Item.isActive) {
                                      return (
                                        <option
                                          value={Item.id + ";" + Item.name}
                                        >
                                          {Item.code} - {Item.name}
                                        </option>
                                      );
                                    }
                                  })}
                                </Form.Control>
                              </Col>
                              <Col sm={2}>
                                <Form.Control
                                  type="number"
                                  value={variantStock}
                                  onChange={onVariantStockChange}
                                />
                              </Col>
                              <Col sm={2}>
                                <>
                                  <Button
                                    style={{ width: 120, marginLeft: 10 }}
                                    variant="info"
                                    onClick={() =>
                                      onAddVariantWarehouseStock(index)
                                    }
                                  >
                                    Add Stock
                                  </Button>
                                </>
                              </Col>
                              <Col>
                                <>
                                  <Button
                                    style={{ width: 90, marginLeft: 10 }}
                                    variant="outline-info"
                                    onClick={() =>
                                      onClearVariantWarehouseStock(index)
                                    }
                                  >
                                    Clear
                                  </Button>
                                </>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <></>
                        )}
                      </Form.Group>

                      <DataTable
                        columns={columns}
                        data={item.warehouseStockList}
                        paginationResetDefaultPage={resetPaginationToggle}
                        noHeader
                        customStyles={TableCustomStyles}
                      />

                      <br />

                      {/* <Form.Group className="input-padding">
                        <Form.Label>Variant Color</Form.Label>
                        <HexColorPicker
                          color={item.iconColor}
                          onChange={(e) => {
                            onColorChange(index, e);
                          }}
                        />
                        <div
                          className="value"
                          style={{ borderLeftColor: color }}
                        >
                          Current color is {color}
                        </div>

                        <Form.Control
                          type="text"
                          onChange={(e) => onColorChange(index, e.target.value)}
                          value={item.iconColor}
                        />
                      </Form.Group>

                      <hr /> */}

                      {/* <br /> */}
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <br />
            </>
          ))}
        </Form>
      </Accordion>
      </CircleLoader>
      {/* <Button variant="info" onClick={onClickDispatchBtn}>
        Save Data Varian
      </Button> */}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Create New Variant</div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="input-padding">
            {/* <Form.Label>Select Attribute</Form.Label> */}
            {/* <Select
              mode="multiple"
              placeholder="Attribute"
              value={tag}
              // onChange={handleChange}
              style={{ width: "100%" }}
              optionFilterProp="children"
              disabled={isEdit}
            >
            {attributeState.map((item) => (
                <Select.Option key={item.name} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select> */}

            {productAttributeName.map((item, index) => {
              return (
                <div>
                  <Form.Group as={Row} className="input-padding">
                    <Col sm={3}>
                      <Form.Label>
                        <h6> {item}</h6>
                      </Form.Label>
                    </Col>
                    <Col sm={9}>
                      <Select
                        defaultValue={null}
                        onChange={onSelectedAttributeChange}
                        options={attributeOptionsData[index]}
                      />

                      {/* <Form.Control
                        as="select"
                        // value={selectedShippingStatusEnum}
                        // disabled
                        onChange={(e) => {
                          // setSelectedShippingStatusEnum(parseInt(e.target.value));
                        }}
                      >
                        {listOfConfigurationTerms["property" + index] !==
                        undefined
                          ? listOfConfigurationTerms["property" + index].map(
                              (item) => (
                                <option value={item.id}> {item.name}</option>
                                // <Select.Option
                                //   value={item.id}
                                //   label={item.name}
                                //   key={item.name}
                                //   // disabled={isEdit}
                                // >
                                //   {item.name}
                                // </Select.Option>
                              )
                            )
                          : null}
                      </Form.Control> */}
                    </Col>
                  </Form.Group>
                </div>
              );
            })}
          </Form.Group>

          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onSaveNewVariantClick}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export interface VariationProps {
  inventorySKU: string;
  isEdit: boolean;
  data: ProductModel;
}

export default VariationVariableProductForm;
