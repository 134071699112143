import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import approve from "./../../../../assets/vector/approve.png";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { deleteData, getListWithPaging } from "../inventoryMove.reducer";
import { getListWarehouse } from "../../../../global/global.reducer";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const InventoryMoveTable = () => {
  const dispatch = useDispatch();

  const inventoryMoveListState = useSelector(
    (state: RootState) => state.inventoryMove.list ?? []
  );

  const warehouseListState = useSelector(
    (state: RootState) => state.global.listWarehouse ?? []
  );

  const totalRowState = useSelector(
    (state: RootState) => state.inventoryMove.totalRow ?? 0
  );

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filterInventoryNumber, setFilterInventoryNumber] = useState("");
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const [filterTempStatus, setFilterTempStatus] = useState(3);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterFromWarehouse, setFilterFromWarehouse] = useState("");
  const [filterToWarehouse, setFilterToWarehouse] = useState("");

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const filteredItems = inventoryMoveListState.filter(
    (item) => item.inventoryNumber && item.inventoryNumber
  );

  const onFromWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterFromWarehouse(e.target.value);
  };

  const onToWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterToWarehouse(e.target.value);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterInventoryNumber("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterFromWarehouse("");
    setFilterToWarehouse("");
    setFilterStatus(null);
    setFilterTempStatus(3);

    dispatch(
      getListWithPaging({
        inventoryNumber: "",
        startDate: "",
        endDate: "",
        fromWarehouse: null,
        toWarehouse: null,
        status: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        inventoryNumber: filterInventoryNumber,
        startDate: filterStartDate,
        endDate: filterEndDate,
        fromWarehouse: filterFromWarehouse === "" ? null : filterFromWarehouse,
        toWarehouse: filterToWarehouse === "" ? null : filterToWarehouse,
        status: filterStatus,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [inventoryMoveListState]);

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getListWarehouse())
  }, []);

  const columns = [
    {
      name: "INVENTORY MOVE NUMBER",
      selector: "inventoryNumber",
      sortable: true,
    },
    {
      name: "DATE",
      selector: (d: { inventoryMoveDate: Date }) => (
        <p style={{ margin: 10 }}>
          {d.inventoryMoveDate.toString().slice(0, 10)}
        </p>
      ),
      sortable: true,
    },
    {
      name: "WAREHOUSE",
      selector: "fromWarehouseName",
      sortable: true,
    },
    {
      name: "DESTINATION",
      selector: "toWarehouseName",
      sortable: true,
    },
    {
      name: "USER CREATE",
      selector: "username",
      sortable: true,
    },
    {
      name: "STATUS APPROVAL IM",
      selector: (d: { status: number }) => (
        <p style={{ margin: 10 }}>
          {d.status === 0 && (
            <span className={"custom-badge-secondary"}>
              waiting for approval
            </span>
          )}
          {d.status === 1 && (
            <span className={"custom-badge-primary"}>Approved</span>
          )}
          {d.status === 2 && (
            <span className={"custom-badge-danger"}>Rejected</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "PROCESS BY",
      selector: (d: {
        status: number;
        approveBy: string;
        rejectedBy: string;
      }) => (
        <p style={{ margin: 10 }}>
          {d.status === 0 && <span>-</span>}
          {d.status === 1 && <span>{d.approveBy}</span>}
          {d.status === 2 && <span>{d.rejectedBy}</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "NOTE PENERIMAAN",
      selector: (d: { approvalInventoryReceiveNote: string|null }) => (
        <p style={{ margin: 10 }}>
          {d.approvalInventoryReceiveNote != null && (
            <span>
              {d.approvalInventoryReceiveNote}
            </span>
          )}
          {d.approvalInventoryReceiveNote === null && (
            <span>-</span>
          )}
          
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any; status: number }) => (
        <>
          <Link
            to={{
              pathname: `/edit-inventory-move/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"        "}
          <Link
            to={{
              pathname: `/approval-inventory-move/${d.id}`,
            }}
          >
            <img src={approve}></img>
          </Link>
          {"        "}

          {d.status === 0 && (
            <span
              onClick={() => {
                if (
                  window.confirm("Apakah kamu yakin akan menghapus item ini?")
                )
                  onDelete(d.id);
              }}
            >
              <img src={hapus} style={{ paddingRight: 10 }}></img>
            </span>
          )}
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Inventory Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterInventoryNumber}
                    onChange={(e) => {
                      setFilterInventoryNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  From
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onFromWarehouseChange}
                    value={filterFromWarehouse}
                  >
                    <option value="">Select</option>
                    {warehouseListState.map((Item) => {
                      return (
                        <option value={Item.id}>
                          {Item.code} - {Item.name} - {Item.city}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  To
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onToWarehouseChange}
                    value={filterToWarehouse}
                  >
                    <option value="">Select</option>
                    {warehouseListState.map((Item) => {
                      return (
                        <option value={Item.id}>
                          {Item.code} - {Item.name} - {Item.city}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterTempStatus}
                    onChange={(e) => {
                      if (e.target.value.toString() === "3") {
                        setFilterStatus(null);
                        setFilterTempStatus(parseInt(e.target.value));
                      } else {
                        setFilterTempStatus(parseInt(e.target.value));
                        setFilterStatus(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={3}>All</option>
                    <option value={0}>waiting for approval</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <InputGroup
                className="mb-3 padding-filter"
                style={{ width: 330 }}
              >
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
            <Form.Group
              as={Row}
              className="padding-filter float-right"
              style={{ width: 400 }}
            >
              <Form.Label column sm={4}>
                Warehouse
              </Form.Label>
              <Col sm={8}>
                <Form.Control as="select">
                  <option>select</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Row> */}

          <hr />

          <DataTable
            columns={columns}
            data={inventoryMoveListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default InventoryMoveTable;
