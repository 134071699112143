import React from 'react'
import { Container, Form } from "react-bootstrap";
import PaymentConfirmEndCustomerTable from '../components/PaymentConfirmEndCustomerTable';


const PaymentConfirmEndCustomerPage = () => {
    return (
        <Container className="content-body" fluid>
          <Form.Group>
            <Form.Label className="page-title">Payment Confirmation End Customer</Form.Label>
          </Form.Group>
          <PaymentConfirmEndCustomerTable />
        </Container>
      );
}

export default PaymentConfirmEndCustomerPage