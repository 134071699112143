import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Grid } from "semantic-ui-react";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import DeleteMessage from "../../../../components/DeleteMessage";
import { deleteFaq, getFaqList, resetStatus } from "../settingFaq.reducer";


const SettingFaqTable =  ({ partnerType = 0, ...props }) => {

  const dispatch = useDispatch()

  const [id, setId] = useState("")

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleterAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const FaqListState = useSelector(
    (state: RootState) => state.settingFaq.faqList ?? []
  );

  const FaqStatusState = useSelector(
    (state: RootState) => state.settingFaq.status ?? []
  );

  const filteredFaq = FaqListState.filter(
    (item) =>
    item.type === partnerType
  )

  const onDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    dispatch(resetStatus());
  };

  const onDeleteData = () => {
    dispatch(deleteFaq(id))
    setShowDeleteAlert(false)
  };

  useEffect(() => {
    if (FaqStatusState==="success-delete"){
      dispatch(getFaqList())
      dispatch(resetStatus());
    }
  }, [FaqStatusState])


  useEffect(() => {
    console.log(FaqListState)
  }, [FaqListState])

    
  const columns = [
    {
      name: "TITLE",
      selector: "title",
      sortable: true,
    },
    {
      name: "CONTENT",
      selector: "contentFAQ",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any, title:string, contentFAQ:string }) => (
        <>
          <span
          onClick={() => {

            setId(d.id)
            
            props.onEdit(d.id,d.title,d.contentFAQ)

           }}>
            <img src={edit}></img>
          </span>
          {"    "}
          <span
            onClick={() => {

              setId(d.id)

              setDeleterAlertProps({
                variant: "info",
                title: "Delete Data FAQ",
                content: "Yakin akan menghapus data FAQ ini ?",
              });

              setShowDeleteAlert(true);
             }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },

  ];

  return (
    <div>
      <Grid style={{ marginTop: "20px" }}>
        <Card>
          <Card.Body>

            <DataTable
              columns={columns}
              data={filteredFaq}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              noHeader
              customStyles={TableCustomStyles}
            />

            {/* <InternalUseItemTable isEdit={isEdit} internalUseId={data.id} warehouseId={data.warehouseId}/> */}
          </Card.Body>
        </Card>
      </Grid>
      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDeleteData}></DeleteMessage>
    </div>
  );
};

export interface FaqTableProps {
  onEdit: (id:string,title: string, content: string) => void;
}

export default SettingFaqTable;
