import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

// export const getCompany = createAsyncThunk('account/getCompany', async () => {
//     const response = await axios.get(`https://api.utilityfielding.com/api/Company/GetList`)
//     console.log(response)
//     return response.data
// })
//post
export const postApplicationSetting = createAsyncThunk('api/postApplication', async (body:any) => {
    try {
        const response = await axios.post(`${endpoint}/api/ApplicationSetting/Create`, body)
        console.log(response.data)
        return response.data
    } 
    catch (error:any) {
       console.log(error.response)
    }
})

//update
export const updateApplicationSetting = createAsyncThunk('api/updateApplicationSetting', async (body:any) => {
    try {
        const response = await axios.post(`${endpoint}/api/ApplicationSetting/Update`, body)
        console.log(response.data)
        return response.data
    } 
    catch (error:any) {
       console.log(error.response)
    }
})



// export const deleteCompany = createAsyncThunk('account/deleteCompany', async (id:string) => {
//     try {
//         const resCompany = await axios.delete(`https://api.utilityfielding.com/api/Company/Delete`, {
//             params : {
//                 targetCompanyId : id
//             }
//         })
//         return resCompany
//     } 
//     catch (error:any) {
//         return error.response
//     }
// })

export const getApplicationSettingByType = createAsyncThunk('api/getApplicationSettingByType', async (body:any) => {
    try {
        const response = await axios.get(`${endpoint}/api/ApplicationSetting/GetListByPartnerType`, {
            params : {
                type : body
            }
        })
        // console.log(response.data, 'infotypoe')
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})