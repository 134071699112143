import { ApiErrorResponse } from "../../..";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PointRewardSettingModel } from "./SettingPointReward.model";
import SettingPointRewardApi from "./SettingPointReward.api";

export interface SettingPointRewardSlice {
    singlePointRewardSetting : PointRewardSettingModel;
    pointRewardSettingList : PointRewardSettingModel[];
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getPointRewardSettingList = createAsyncThunk(
    'PointRewardSettingListState/PointSettingList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await SettingPointRewardApi.getPointRewardSettingList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updatePointRewardSetting = createAsyncThunk(
    'UpdatePointRewardSettingState/UpdatePointRewardSetting',
    async (model: PointRewardSettingModel, { rejectWithValue }) => {
        try {
            return await SettingPointRewardApi.updatePointRewardSetting(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const PointRewardSettingSlice = createSlice({
    name: 'PointRewardSetting',
    initialState: {} as SettingPointRewardSlice,
    reducers : {
        resetSingle: (state) => {
            state.singlePointRewardSetting = {} as PointRewardSettingModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPointRewardSettingList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPointRewardSettingList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.pointRewardSettingList = payload.data;
        });
        builder.addCase(getPointRewardSettingList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updatePointRewardSetting.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(updatePointRewardSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(updatePointRewardSetting.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
    }
})

export const { resetSingle, resetStatus } = PointRewardSettingSlice.actions;
export default PointRewardSettingSlice.reducer;

